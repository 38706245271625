export class SidebarModalActionSettings {
	events: SidebarModalEvent[] = [new SidebarModalEvent()];

	constructor(dto?: SidebarModalActionSettingsDto) {
		this.fromDto(dto);
	}

	fromDto(dto?: SidebarModalActionSettingsDto): void {
		dto && (Object.assign(this, dto));
	}

	toServer(): SidebarModalActionSettingsDto {
		const events = this.events.filter(e => e.action && e.eventType);
		return { events };
	}

	addEvent(): void {
		this.events.push(new SidebarModalEvent());
	}

	removeEvent(index: number): void {
		this.events.splice(index, 1);
	}
}

export class SidebarModalActionSettingsDto {
	events: SidebarModalEvent[];
}

export class SidebarModalEvent {
	eventType: string = '';
	action: string;
}

export enum SidebarModalAction {
	open = 'Open',
	close = 'Close',
	toggle = 'Toggle',
}
