import { Pipe, PipeTransform } from '@angular/core';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Constants } from '@shared/constants/constants';
import { LinkMethods } from '../../methods/link.methods';
import { NewArtifactType } from '../../types/artifact-type.types';
import { NewArtifact } from '../../types/artifact.types';
import { NewLink } from '../../types/link.types';

@Pipe({ name: 'linkPrimaryAttributesToString' })
export class LinkPrimaryAttributesToStringPipe implements PipeTransform {
  transform(link: NewLink, direction: LinkDirection, artifactsMap: Record<string, NewArtifact>, artifactTypesMap: Record<string, NewArtifactType>): string {
    if (!artifactsMap || !Object.keys(artifactsMap).length) {
      return '';
    }

    const { sourceArtifactId, destinationArtifactId } = link;
    const artifactId = LinkMethods.isOutgoing(direction) ? destinationArtifactId : sourceArtifactId;
    const artifact = artifactsMap[artifactId];

    if (!artifact) {
      console.log('LinkPrimaryAttributesToStringPipe: artifact not found', link, artifact);
      // It may happen because data are rendered before getting links. Normally, the user shouldn't notice any issue as after
      // link reading the data will be re-rendered.
      return artifactId;
    }
    const artifactType = artifactTypesMap[artifact.artifactTypeId];
    const linkAttributes = artifactType.primaryAttributes
      .map(id => {
        switch (id) {
          case Constants.primaryAttributesDefaultId:
            return artifact.id;
          case Constants.primaryAttributesArtifactTypeId:
            return artifactType.name;
          default:
            return artifact.attributes[id].value;
        }
      })
      .join(', ');
    return linkAttributes;
  }
}
