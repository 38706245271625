<div *ngIf="formatSettings" [@fadeAnimation] class="grid format-options">
  <p-accordion class="col-12 mt-1">
    <p-accordionTab header="{{ 'Label styles' | translate }}">
      <app-attribute-format-label-styles-form
        (labelPositionChange)="onLabelPositionChange($event, formatSettings)"
        (labelStyleChange)="changeLabelStyleReference(formatSettings)"
        [labelFormatSettings]="formatSettings.label"
        [enableVerticalAlignment]="false"
      >
      </app-attribute-format-label-styles-form>
    </p-accordionTab>

    <p-accordionTab header="{{ 'Value styles' | translate }}">
      <app-attribute-format-value-styles-form
        (editDisabled)="onEditDisabled()"
        (editEnabled)="onEditEnabled()"
        (styleChange)="changeFieldStyleReference()"
        (valueAlignmentChange)="changeLabelStyleReference(formatSettings)"
        [attribute]="attribute | getAttributeFromClientAttribute : options.attributes"
        [dataType]="dataType"
        [areEditableStylesDisabled]="dataType?.kind | isCounter"
        [formatSettings]="formatSettings"
        [showPrefixSuffixSettings]="(dataType?.baseDataType | isInteger) || (dataType?.baseDataType | isDecimal)"
        [showDecimalPlacesSettings]="(dataType?.baseDataType | isDecimal)"
      >
      </app-attribute-format-value-styles-form>
    </p-accordionTab>
    <p-accordionTab *ngIf="folderSettings && isFolderPath()" header="{{ 'Modal Window Settings' | translate }}">
      <app-folder-widget-settings [settings]="folderSettings" [showUrlChoose]="false"></app-folder-widget-settings>
    </p-accordionTab>

    <p-accordionTab header="{{ 'Shared styles (label and value)' | translate }}">
      <app-artifact-widget-item-format-settings
        [formatSettings]="formatSettings"></app-artifact-widget-item-format-settings>
    </p-accordionTab>

    <p-accordionTab *ngIf="attributeDisplayVariantOptions && formatSettings.value.displayMetadata"
                    [header]="'Display variant' | translate">
      <p-dropdown
        id="displayVariant"
        [(ngModel)]="formatSettings.value.displayMetadata.selectedVariantCode"
        [autoDisplayFirst]="false"
        [options]="attributeDisplayVariantOptions"
        (onChange)="onSelectedItemChange($event.value)"
      >
        <ng-template let-item pTemplate="item">
          <ng-container *ngTemplateOutlet="displayVariant; context: { $implicit: item }"></ng-container>
        </ng-template>
        <ng-template let-item pTemplate="selectedItem">
          <ng-container *ngTemplateOutlet="displayVariant; context: { $implicit: item }"></ng-container>
        </ng-template>

        <ng-template #displayVariant let-item>
          <div>{{ item.label | translate }}</div>
        </ng-template>
      </p-dropdown>
    </p-accordionTab>

    <p-accordionTab *ngIf="isShowEditVariant" header="{{ 'Edit variant' | translate }}">
      <p-dropdown
              id="editVariant"
              [(ngModel)]="formatSettings.value.displayMetadata.editVariantCode"
              [autoDisplayFirst]="false"
              [options]="attributeEditVariantOptions"
      >
        <ng-template let-item pTemplate="item">
          <ng-container *ngTemplateOutlet="editVariant; context: { $implicit: item }"></ng-container>
        </ng-template>
        <ng-template let-item pTemplate="selectedItem">
          <ng-container *ngTemplateOutlet="editVariant; context: { $implicit: item }"></ng-container>
        </ng-template>

        <ng-template #editVariant let-item>
          <div>{{ item.label | translate }}</div>
        </ng-template>
      </p-dropdown>
      <p-dropdown [(ngModel)]="formatSettings.value.displayMetadata.horizontalAlign"
                  [options]="alignOptions"
                  class="mt-2"
                  appendTo="body"
                  id="horizontalAlign">
      </p-dropdown>

      <div *ngIf="isEnumSingle" class="card flex justify-content-center gap-3">
        <div class="flex align-items-center">
          <p-checkbox
            [(ngModel)]="formatSettings.value.displayMetadata.showClear"
            [label]="'Allow empty' | translate"
            binary="true"
          ></p-checkbox>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>
