<div *ngIf="clientAttribute"
     [attr.style]="formatSettings?.value.fieldStyles | fieldStyles"
     (focusin)="onFocus()"
     [ngClass]="{'hidden': !visible, 'reserve-space': formatSettings?.reserveSpaceWhenHidden}"
     class="attribute-form-field">
  <!-- SIMPLE -->
  @if (visible && dataType.isSimple) {
    <span *ngIf="(dataType.baseDataType | isInteger) || (dataType.baseDataType | isDecimal)" class="dynamic-label p-float-label">
      <app-artifact-attribute-form-field-numeric
        [attr]="clientAttribute"
        [index]="index"
        [attribute]="attribute"
        [dataType]="dataType"
        [formatSettings]="formatSettings"
        [labelBehaviour]="labelBehaviour"
        [label]="label"
        [placeholder]="placeholder"
        [onChange]="onChange.bind(this)"
        [onBlur]="onBlur.bind(this)"
        class="form-field-numeric"
      >
      </app-artifact-attribute-form-field-numeric>
    </span>

    <span *ngIf="(dataType.baseDataType | isText) || (dataType.baseDataType | isHtml)" class="dynamic-label p-float-label">
      <app-artifact-attribute-form-field-text
        [attr]="clientAttribute"
        [index]="index"
        [attribute]="attribute"
        [dataType]="dataType"
        [formatSettings]="formatSettings"
        [labelBehaviour]="labelBehaviour"
        [label]="label"
        [placeholder]="placeholder"
        [onChange]="onChange.bind(this)"
        [folderId]="folderId"
        [onBlur]="onBlur.bind(this)"
      >
      </app-artifact-attribute-form-field-text>
    </span>

    <span *ngIf="dataType.baseDataType | isDateOrTime" class="dynamic-label p-float-label">
      <app-artifact-attribute-form-field-date
        [attr]="clientAttribute"
        [index]="index"
        [attribute]="attribute"
        [dataType]="dataType"
        [formatSettings]="formatSettings"
        [labelBehaviour]="labelBehaviour"
        [label]="label"
        [placeholder]="placeholder"
        [onChange]="onChange.bind(this)"
        [onBlur]="onBlur.bind(this)"
      >
      </app-artifact-attribute-form-field-date>
    </span>

    <span *ngIf="dataType.baseDataType | isBoolean" class="dynamic-label p-float-label">
      <app-artifact-attribute-form-field-boolean
        [attr]="clientAttribute"
        [index]="index"
        [attribute]="attribute"
        [formatSettings]="formatSettings"
        [label]="label"
        [onChange]="onChange.bind(this)"
      >
      </app-artifact-attribute-form-field-boolean>
    </span>

    <span *ngIf="(dataType.baseDataType | isUser) && users.loaded" class="dynamic-label p-float-label">
      <app-artifact-attribute-form-field-user
        [attr]="clientAttribute"
        [index]="index"
        [formatSettings]="formatSettings"
        [labelBehaviour]="labelBehaviour"
        [label]="label"
        [attribute]="attribute"
        [users]="users"
        [placeholder]="placeholder"
        [onChange]="onChange.bind(this)"
        [onBlur]="onBlur.bind(this)"
      >
      </app-artifact-attribute-form-field-user>
    </span>

    <span *ngIf="dataType.baseDataType | isHyperlink" class="dynamic-label p-float-label">
      <app-artifact-attribute-form-field-hyperlink
        [attr]="clientAttribute"
        [index]="index"
        [attribute]="attribute"
        [dataType]="dataType"
        [formatSettings]="formatSettings"
        [labelBehaviour]="labelBehaviour"
        [label]="label"
        [placeholder]="placeholder"
        [onChange]="onChange.bind(this)"
        [onBlur]="onBlur.bind(this)"
      >
      </app-artifact-attribute-form-field-hyperlink>
    </span>
  }

  <!-- ENUMERATED -->
  @if (visible && (dataType.kind | isEnumerated)) {
    <span class="dynamic-label p-float-label">
    <app-artifact-attribute-form-field-enumerated
      [attr]="clientAttribute"
      [index]="index"
      [attribute]="attribute"
      [dataType]="dataType"
      [options]="options"
      [formatSettings]="formatSettings"
      [labelBehaviour]="labelBehaviour"
      [label]="label"
      [placeholder]="placeholder"
      (valueChange)="onChange($event)"
      (fieldBlur)="onBlur()"
    >
    </app-artifact-attribute-form-field-enumerated>
    </span>
  }

  <!-- BOUNDED -->
  @if (visible && (dataType.kind | isBounded)) {
    <span *ngIf="(dataType.baseDataType | isInteger) || (dataType.baseDataType | isDecimal)" class="dynamic-label p-float-label">
      <app-artifact-attribute-form-field-numeric-bounded
        [attr]="clientAttribute"
        [index]="index"
        [attribute]="attribute"
        [dataType]="dataType"
        [formatSettings]="formatSettings"
        [label]="label"
        [onChange]="onChange.bind(this)"
        [onBlur]="onBlur.bind(this)"
      >
      </app-artifact-attribute-form-field-numeric-bounded>
    </span>

    <span *ngIf="dataType.baseDataType | isDateOrTime" class="dynamic-label p-float-label">
      <app-artifact-attribute-form-field-date-bounded
        [attr]="clientAttribute"
        [index]="index"
        [attribute]="attribute"
        [dataType]="dataType"
        [formatSettings]="formatSettings"
        [labelBehaviour]="labelBehaviour"
        [label]="label"
        [placeholder]="placeholder"
        [onChange]="onChange.bind(this)"
        [onBlur]="onBlur.bind(this)"
      >
      </app-artifact-attribute-form-field-date-bounded>
    </span>
  }
</div>
