import { Component, Input, OnInit } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { ArtifactFormItemSingleDateInitialValueType, ArtifactFormItemSingleUserInitialValueType } from '@widgets/artifact-widget/types/artifact-widget.types';
import { EDITOR_KEY } from '@shared/constants/constants';

@Component({
  selector: 'app-attribute-initial-value-form',
  templateUrl: './attribute-initial-value-form.component.html',
  styleUrls: ['./attribute-initial-value-form.component.scss'],
})
export class AttributeInitialValueFormComponent implements OnInit {
  @Input() formatSettings: AttributeFormatSettings;
  @Input() dataType: NewDataType;
  @Input() attribute: NewAttribute;

  fieldId = SharedMethods.getUniqueId();
  settings: any;
  editorKey = EDITOR_KEY;

  constructor(private readonly tinyMceHelper: TinyMceHelper) {}

  get options(): SelectOption<string, string>[] {
    const { date, dateTime, user } = BaseDataType;

    if ([date, dateTime].includes(this.dataType.baseDataType as BaseDataType)) return this.initDateInitialValueOptions();
    if (this.dataType.baseDataType === user) return this.initUserInitialValueOptions();

    return [];
  }

  ngOnInit(): void {
    this.settings = this.tinyMceHelper.getTinyMceAdvancedSettings();
    this.settings.inline = false;
    this.settings.height = '400px';
  }

  private initDateInitialValueOptions(): SelectOption<string, string>[] {
    return [new SelectOption('Today', ArtifactFormItemSingleDateInitialValueType.today)];
  }

  private initUserInitialValueOptions(): SelectOption<string, string>[] {
    return [new SelectOption('[Current user]', ArtifactFormItemSingleUserInitialValueType.currentUser)];
  }
}
