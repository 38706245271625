import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomEventSettings } from '@widgets/shared/types/custom-event';

@Component({
  selector: 'app-custom-event-settings-form',
  templateUrl: './custom-event-settings-form.component.html',
  styleUrl: './custom-event-settings-form.component.scss'
})
export class CustomEventSettingsFormComponent {
  @Input() customEvent: CustomEventSettings;
  @Input() showAccordeon = true;
  @Output() customEventChange = new EventEmitter<any>();
  @Output() onChangeValue = new EventEmitter<void>();


  onAddType(): void {
    this.customEvent.types.push('');
  }

  onChange(event: any, index: number): void {
    const newValue = (event.target as HTMLInputElement).value;
    this.customEvent.types[index] = newValue;
    this.customEventChange.emit(this.customEvent);
    this.onChangeValue.emit();
  }
}
