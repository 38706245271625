// close overlay
export interface AutocompleteBaseEvent {
  type: AutocompleteEventType;
  ownerId: string;
}

// open overlay
export interface AutocompleteInitEvent<T> extends AutocompleteBaseEvent {
  event: Event;
  target?: HTMLElement;
  caretPositionInQuery: number;
  doFocusFirstItem?: boolean;
  variableNamePart: string;
  options: T[];
}

// select in overlay
export interface AutocompleteSelectionEvent<T> extends AutocompleteBaseEvent {
  initEvent: AutocompleteBaseEvent;
  selectedOption: T;
}

export enum AutocompleteEventType {
  attribute = 'attribute',
  formula = 'formula',
}
