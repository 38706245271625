import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ApiConfiguration } from '@api/api-configuration';
import { HandleValidationErrorsService } from '@private/services/handle-validation-errors.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Constants } from '@shared/constants/constants';
import { PageHelper } from '@shared/helpers/page-helper';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { PushNotificationService } from '@shared/services/push-notification.service';
import { StateKey } from '@shared/types/local-storage.types';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '../services/local-storage.service';
import { ServerSideEventService } from '../services/server-side-event.service';

@Injectable({ providedIn: 'root' })
export class ApiInterceptor implements HttpInterceptor {
  private authorizationService: AuthorizationService;
  private pushNotificationService: PushNotificationService;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly apiConfiguration: ApiConfiguration,
    private readonly handleValidationErrorsService: HandleValidationErrorsService,
    private readonly serverSideEventService: ServerSideEventService,
    private readonly cache: NewCacheService,
    private readonly pageHelper: PageHelper,
    private readonly injector: Injector,
  ) {
    setTimeout(() => {
      this.authorizationService = this.injector.get(AuthorizationService);
      this.pushNotificationService = this.injector.get(PushNotificationService);
    });
  }

  get token(): string {
    return this.localStorageService.get(StateKey.session) ? this.localStorageService.getFromState(StateKey.session, Constants.token) : '';
  }

  get anonymousToken(): string {
    return this.localStorageService.get(StateKey.master) ? this.localStorageService.getFromState(StateKey.master, Constants.token) : '';
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url === this.handleValidationErrorsService.errors.lastUrl) this.handleValidationErrorsService.reset();

    const headers: any = { 'ngsw-bypass': 'true' };

    if (req.url.includes(this.apiConfiguration.rootUrl)) {
      headers['Access-Control-Allow-Origin'] = '*';
      headers['Authorization'] = 'Bearer ' + (this.authorizationService?.getToken || this.token || this.anonymousToken);
      headers['TAB-SESSION'] = this.serverSideEventService.hash;
    }

    if (this.pageHelper.usePublicToken) {
      headers['Authorization'] = 'Bearer ' + this.anonymousToken;
    }

    req = req.clone({ setHeaders: headers });

    return next.handle(req).pipe(
      tap(
        x => x,
        async (err: HttpErrorResponse) => {
          if (err.status === 400) {
            await this.handleValidationErrorsService.handle(err);
          } else if (err.status === 401 && !this.pageHelper.isOnPage) {
            this.localStorageService.clear();
            this.authorizationService.resetToken();
            this.cache.destroy();
            this.pushNotificationService.destroy();
            await this.router.navigateByUrl('/login');
          }
        },
      ),
    );
  }
}
