import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { Environment } from '@environments/environment';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  APPLICATION_ID_KEY,
  APPLICATION_LABEL,
  DATA_TYPE_KEY,
  DATA_TYPE_KIND_LABEL,
  DATA_TYPE_LABEL,
  DESCRIPTION_KEY,
  DESCRIPTION_LABEL,
  ID_KEY,
  NAME_KEY,
  NAME_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { TableColumn } from '@shared/types/table.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { Table } from 'primeng/table';
import { DataTypeListModel } from '../types/data-type-list.types';

@Injectable({ providedIn: 'root' })
export class DataTypeListService extends CoreService<any, DataTypeListModel> {
  constructor(
    private readonly elvisUtil: ElvisUtil,
    private readonly cache: NewCacheService,
  ) {
    super();
  }

  init(context: any, model: DataTypeListModel): void {
    super.init(context, model);
    this.initOptions();
    this.initSubscriptions();
  }

  doFilter(table: Table): void {
    table && table.filter(null, null as any, null as any);
  }

  private initOptions(): void {
    this.m.columns = [
      new TableColumn(NAME_LABEL, NAME_KEY),
      new TableColumn(DESCRIPTION_LABEL, DESCRIPTION_KEY),
      new TableColumn(DATA_TYPE_LABEL, DATA_TYPE_KEY),
      new TableColumn(DATA_TYPE_KIND_LABEL, DATA_TYPE_KIND_LABEL),
      new TableColumn(APPLICATION_LABEL, APPLICATION_ID_KEY),
    ];
    this.m.rowsPerPage = Environment.tableConfig.rowsPerPage;
    this.m.rowsPerPageOptions = Environment.tableConfig.rowsPerPageOptions;
    this.m.kindOptions = this.elvisUtil.getKindOptions();
    this.m.baseDataTypeOptions = this.elvisUtil.getBaseDataTypeOptions();
  }

  private initSubscriptions(): void {
    this.c.registerSubscriptions([
      this.cache.data.applications.subscribe(applications => applications && this.m.applications.setList(applications as ApplicationResponseDto[], ID_KEY)),
    ]);
  }
}
