/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FolderAclRequestDto } from '../../models/folder-acl-request-dto';
import { FolderAclResponseDto } from '../../models/folder-acl-response-dto';

export interface FolderControllerAclRemove$Params {
      body: FolderAclRequestDto

}

export function folderControllerAclRemove(http: HttpClient, rootUrl: string, params: FolderControllerAclRemove$Params, context?: HttpContext): Observable<StrictHttpResponse<FolderAclResponseDto>> {
  const rb = new RequestBuilder(rootUrl, folderControllerAclRemove.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  rb.header('ngsw-bypass', 'true', {});

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FolderAclResponseDto>;
    })
  );
}

folderControllerAclRemove.PATH = '/api/tenant/folder/acl/remove';
