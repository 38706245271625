import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { PageBlockPartComponent } from '@private/pages/page-management/page-builder-graphical/components/page-block-part/page-block-part.component';
import { PageBlockComponent } from '@private/pages/page-management/page-builder-graphical/components/page-block/page-block.component';
import { PageRowComponent } from '@private/pages/page-management/page-builder-graphical/components/page-row/page-row.component';
import { PageSectionComponent } from '@private/pages/page-management/page-builder-graphical/components/page-section/page-section.component';
import { PageSettingsComponent } from '@private/pages/page-management/page-builder-graphical/components/page-settings/page-settings.component';
import { AttributeAutocompleteOverlayComponent } from '@shared/components/context-autocomplete/components';
import { FormulaAutocompleteOverlayComponent } from '@shared/components/context-autocomplete/components/formulajs-autocomplete-overlay/formula-autocomplete-overlay.component';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { TemplatesComponent } from '@shared/components/templates/templates.component';
import { WidgetContainerModule } from '@shared/components/widget-container/widget-container.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { ImagePipesModule } from '@shared/pipes/internal-image/image-pipes.module';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { ListWidgetModule } from '@widgets/list-widget-new/list-widget.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { SidebarModalButtonFormComponent } from '@widgets/sidebar-modal-widget/components/sidebar-modal-button-form/sidebar-modal-button-form.component';
import { SidebarModalWidgetSettingsComponent } from '@widgets/sidebar-modal-widget/components/sidebar-modal-widget-settings/sidebar-modal-widget-settings.component';
import { StateHeaderComponent } from '@widgets/sidebar-modal-widget/components/state-header/state-header.component';
import { SidebarModalWidgetComponent } from '@widgets/sidebar-modal-widget/sidebar-modal-widget.component';
import { SidebarWidgetModule } from '@widgets/sidebar-widget/sidebar-widget.module';
import { WidgetsModule } from '@widgets/widgets.module';

import { WorkflowsModule } from '@workflows/workflows.module';
// PrimeNG
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
// Components
import { PageBlockPartSettingsComponent } from './page-builder-graphical/components/page-block-part-settings/page-block-part-settings.component';
import { PageRowSettingsComponent } from './page-builder-graphical/components/page-row-settings/page-row-settings.component';
import { PageSectionSettingsComponent } from './page-builder-graphical/components/page-section-settings/page-section-settings.component';
import { PageBuilderGraphicalComponent } from './page-builder-graphical/page-builder-graphical.component';
import { IsElementOfTypePipe } from './page-builder-graphical/pipes/is-element-of-type.pipe';
import { AddButtonSectionComponent } from './page-builder-schematic/components/add-button-section/add-button-section.component';
import { RowItemComponent } from './page-builder-schematic/components/row-item/row-item.component';
import { PageBuilderSchematicComponent } from './page-builder-schematic/page-builder-schematic.component';
import { PagesTableComponent } from './pages/components/pages-table/pages-table.component';
import { PagesComponent } from './pages/pages.component';
import { PrintPageToTablePipe } from './pages/pipes/print-page-to-table.pipe';
import { ImageFilterStylesAdmin } from './page-builder-graphical/pipes/image-filter-styles-admin.pipe';
import { ImageFilterStylesPipe } from '@shared/pipes/internal-image/image-filter-styles.pipe';
import { ResponsiveDesignSettingsComponent } from './page-builder-graphical/components/responsive-design-settings/responsive-design-settings.component';

const components = [
  PageBuilderGraphicalComponent,
  PagesComponent,
  PagesTableComponent,
  PageBuilderSchematicComponent,
  RowItemComponent,
  AddButtonSectionComponent,
  TemplatesComponent,
  PageSectionComponent,
  PageRowComponent,
  PageBlockComponent,
  PageBlockPartComponent,
  PageSettingsComponent,
  PageSectionSettingsComponent,
  PageRowSettingsComponent,
  PageBlockPartSettingsComponent,
  SidebarModalWidgetComponent,
  SidebarModalWidgetSettingsComponent,
  SidebarModalButtonFormComponent,
  StateHeaderComponent,
  ResponsiveDesignSettingsComponent,
];

@NgModule({
  declarations: [...components, PrintPageToTablePipe, IsElementOfTypePipe, ImageFilterStylesAdmin],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    // PrimeNG
    AccordionModule,
    DragDropModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    RippleModule,
    SidebarModule,
    OverlayPanelModule,
    SharedModule,
    TableModule,
    MessageModule,
    DialogModule,
    InputTextareaModule,
    DropdownModule,
    DynamicDialogModule,
    FileUploadModule,
    // Custom modules
    WidgetsModule,
    SidebarWidgetModule,
    PortalModule,
    LoaderModule,
    MultiSelectModule,
    ElvisSharedModule,
    ListWidgetModule,
    WidgetContainerModule,
    StyleFormModule,
    FloatLabelModule,
    CheckboxModule,
    WorkflowsModule,
    CommonPipesModule,
    AttributeAutocompleteOverlayComponent,
    FormulaAutocompleteOverlayComponent,
    ImagePipesModule,
  ],
  exports: [...components],
  providers: [ArtifactWidgetHelper, ImageFilterStylesPipe],
})
export class PageManagementModule {}
