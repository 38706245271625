import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  static readonly DELAY = 200;

  constructor(private host: ElementRef) {}

  ngAfterViewInit() {
    setTimeout(() => this.host.nativeElement.focus(), AutofocusDirective.DELAY);
  }
}
