import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SetAlignmentClassToChildrenPipe } from '@shared/pipes/common/set-alignment-class-to-children.pipe';
import { DisplayAtSystemDateComponent } from './display-at-system-date.component';

@NgModule({
  declarations: [DisplayAtSystemDateComponent],
  imports: [CommonModule, SetAlignmentClassToChildrenPipe],
  exports: [DisplayAtSystemDateComponent],
})
export class DisplayAtSystemDateModule {}
