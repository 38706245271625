import { Component, Input } from '@angular/core';
import { AuthWidgetModel } from '@widgets/auth-widget/types/auth-widget.types';
import { AuthWidgetSettings } from '@widgets/auth-widget/types/auth-widget-settings.types';
import { UserIconVariantType } from '@widgets/auth-widget/types/auth-widget-options.types';

type SettingsKeyType = keyof Pick<AuthWidgetSettings, 'showLogoutButton' | 'showName' | 'showEmail' | 'showIcon'>;
const allowedKeys = ['showLogoutButton', 'showName', 'showEmail', 'showIcon'];

@Component({
  selector: 'app-auth-widget-settings',
  templateUrl: './auth-widget-settings.component.html',
  styleUrls: ['./auth-widget-settings.component.scss'],
})
export class AuthWidgetSettingsComponent {
  @Input() m: AuthWidgetModel;

  settingKeys: Record<SettingsKeyType, SettingsKeyType>;

  ngOnInit() {
    this.settingKeys = (Object.keys(this.m.settings) as SettingsKeyType[])
      .filter(key => allowedKeys.includes(key))
      .reduce<Record<SettingsKeyType, SettingsKeyType>>((res, key) => {
        res[key] = key;
        return res;
      }, {} as Record<SettingsKeyType, SettingsKeyType>);
  }

  get isShowAlternative(): boolean {
    return this.m.settings.userDisplayVariant === UserIconVariantType.photo;
  }

  checkRestriction(key: SettingsKeyType): void {
    let counter = 0;

    (Object.keys(this.settingKeys) as SettingsKeyType[]).forEach(key => {
      this.m.settings[key] && counter++;
    });

    counter < 1 &&
      setTimeout(() => {
        this.m.settings[key] = true;
      });
  }
}
