import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { GlobalConstants } from '@shared/constants/global.constants';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ArtifactValueExtractor } from '@widgets/card-widget/types/artifact-value-extractor';
import { AttributeInfo } from '@widgets/card-widget/types/attribute-info';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';

export class RecordAuthorExtractor implements ArtifactValueExtractor {
  static readonly createdByAttributeId = NonAttributeKeys.CREATED_BY_ID;
  static readonly updatedByAttributeId = NonAttributeKeys.UPDATED_BY_ID;

  constructor(private readonly options: CardWidgetOptions) {}

  static isRecordAuthorAttribute(attributeId: string): boolean {
    return [RecordAuthorExtractor.createdByAttributeId, RecordAuthorExtractor.updatedByAttributeId].some((id: string) => id === attributeId);
  }

  getValue(artifactDto: ArtifactResponseDto, { attributeId }: AttributeInfo): string {
    const authorId = this.getAuthorId(artifactDto, attributeId);

    return this.options.users.listMap[authorId]?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string;
  }

  private getAuthorId({ created, updated }: ArtifactResponseDto, attributeId: string): string {
    const records: { [attributeId: string]: RecordDto } = {
      [RecordAuthorExtractor.createdByAttributeId]: created,
      [RecordAuthorExtractor.updatedByAttributeId]: updated,
    };

    return records[attributeId].by;
  }
}
