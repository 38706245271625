import { ChangeDetectorRef, Component, ElementRef, Input, NgZone, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AUTOCOMPLETE_CONTAINER_CLASS } from '@shared/constants/constants';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ActionCalculateExpressionComponent } from '@workflows/components/rule';
import { AbstractWorkflowRuleItemComponent } from '@workflows/components/rule/abstract';
import { WorkflowActionFormula } from '@workflows/types/actions/action-formula';
import { fromEvent, merge, Observable, Subject, Subscription, tap } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-action-formula',
  templateUrl: './action-formula.component.html',
  styleUrls: ['./action-formula.component.scss', '../../../workflow-common.scss'],
})
export class ActionFormulaComponent extends AbstractWorkflowRuleItemComponent implements OnDestroy {
  @Input() action: WorkflowActionFormula;

  @ViewChild('formulaInput') set formulaInput(content: ElementRef) {
    if (!content) return;

    const nativeElement = content.nativeElement;
    this.zone.runOutsideAngular(() => {
      const focus$ = fromEvent(nativeElement, 'focus').pipe(tap(() => this.cdr.detach()));

      const blur$ = fromEvent(nativeElement, 'blur').pipe(
        delay(10),
        tap(() =>
          this.zone.run(() => {
            const activeElement = document.activeElement;
            if (activeElement && !activeElement.closest('.' + AUTOCOMPLETE_CONTAINER_CLASS)) {
              this.cdr.reattach();
              this.checkValidityOfExpression(nativeElement.value);
            }
          }),
        ),
      );
      this.inputSubscription = merge(focus$, blur$).subscribe();
    });
  }

  actionInputOwner: string;
  inputSubscription: Subscription;
  errorMessage$: Observable<string | undefined>;
  private errorMessageSubject: Subject<string | undefined>;
  private readonly WAIT_TIME_BEFORE_INPUT_CHANGES = 150;

  constructor(
    translateService: TranslateService,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(translateService);
    this.errorMessageSubject = new Subject();
    this.errorMessage$ = this.errorMessageSubject.asObservable();
    this.actionInputOwner = ElvisUtil.makeHash(10);
  }

  ngOnDestroy(): void {
    this.inputSubscription.unsubscribe();
  }

  onAttributeChange(attributeId: string) {
    this.action.attributeId = attributeId;
  }

  checkValidityOfExpression(formulaValue: string): void {
    if (!formulaValue) return;

    const variableNames = WorkflowActionFormula.extractExpressionVariables(formulaValue);
    const variableNamesValid = this.areVariableNamesValid(variableNames);

    if (!variableNamesValid) {
      this.handleInvalidExpression(ActionCalculateExpressionComponent.ERROR_VARIABLE_NAMES_KEY);
      return;
    }

    this.errorMessageSubject.next(undefined);
  }

  areVariableNamesValid(variableNames: string[]): boolean {
    return variableNames.every(name => this.selectedAttributes.find(attr => attr.alias === name));
  }

  onFormulaChange(event: any) {
    setTimeout(() => (this.action.actionSettings.formula.value = event.target.value), this.WAIT_TIME_BEFORE_INPUT_CHANGES);
  }

  private handleInvalidExpression(errMsg: string) {
    this.errorMessageSubject.next(errMsg);
  }
}
