import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '@api/services/service.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { Subscription } from 'rxjs';
import { PageBuilderHelper } from '../../helpers/page-builder.helper';
import { BreadcrumbService } from '../../services/app.breadcrumb.service';

@Component({
  selector: 'app-development',
  templateUrl: './development.component.html',
  styleUrls: ['./development.component.scss'],
})
export class DevelopmentComponent implements OnInit, OnDestroy {
  private attributes = new ListContainer<NewAttribute>();
  private dataTypes = new ListContainer<NewDataType>();
  private artifactTypes = new ListContainer<NewArtifactType>();
  private linkTypes = new ListContainer<LinkType>();
  private attributesSubscription$: Subscription;
  private dataTypesSubscription$: Subscription;
  private artifactTypesSubscription$: Subscription;
  private linkTypesSubscription$: Subscription;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly serviceService: ServiceService,
    private readonly localStorageService: LocalStorageService,
    private readonly pageBuilderHelper: PageBuilderHelper,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.breadcrumbService.setItems([{ label: 'Development' }]);
  }

  ngOnDestroy(): void {
    this.attributesSubscription$ && this.attributesSubscription$.unsubscribe();
    this.dataTypesSubscription$ && this.dataTypesSubscription$.unsubscribe();
    this.artifactTypesSubscription$ && this.artifactTypesSubscription$.unsubscribe();
    this.linkTypesSubscription$ && this.linkTypesSubscription$.unsubscribe();
  }

  reset(): void {
    this.serviceService.serviceControllerReset().subscribe(async () => {
      this.localStorageService.clear();
      await this.router.navigateByUrl('/login');
    });
  }

  setup(): void {
    this.serviceService.serviceControllerSetup({}).subscribe({
      error: err => {
        console.log(err);
      },
      next: (async () => {
        this.localStorageService.clear();
        await this.router.navigateByUrl('/login');
      }).bind(this),
    });
  }

  async createDefaultPages(): Promise<any> {
    await this.pageBuilderHelper.createAndAssignDefaultsToAllArtifactTypes(this.attributes, this.dataTypes, this.artifactTypes, this.linkTypes);
  }
}
