import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { SharedMethods } from '@shared/methods/shared.methods';
import { ToSystemUserKeyPipe } from '@shared/pipes/data-type-pipes/to-system-user-key.pipe';
import { UserProfileService } from '@shared/services/user-profile/user-profile.service';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { DATUserLayoutVariant } from '@shared/types/display-at-types';
import { UserProfileAndPictureData, UserProfileData } from '@shared/types/user-profile.types';
import { FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { Observable } from 'rxjs';
import { DisplayAtControlService } from '../../services';
import { AbstractDisplayAtComponent } from '../abstract-display-at.component';

@Component({
  selector: 'app-display-at-user',
  templateUrl: './display-at-user.component.html',
  styleUrls: ['display-at-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtUserComponent extends AbstractDisplayAtComponent implements OnChanges {
  /** offset representing aggregated margon from top and bottom, that will be reduced from picture container size  */
  static readonly PIC_OFFSET = 4;

  @Input() tooltipEnabled: boolean;
  @Input() defaultIconSize: number;
  @Input() usersMap: Record<string, ArtifactLinkResponseDto>;
  @Input() valueStyles: FormatStyles;

  @ViewChild('datUserContainer') datUserContainer: ElementRef;

  profileDataItems$: Observable<UserProfileData>[];
  profileAndPictureDataItems$: Observable<UserProfileAndPictureData>[];
  iconSize: number;

  constructor(
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    protected readonly displayAtControlService: DisplayAtControlService,
    protected readonly dataTypeHelper: DataTypeHelper,
    protected readonly cdr: ChangeDetectorRef,
    private readonly userProfileService: UserProfileService,
  ) {
    super(isPreviewMode, displayAtControlService, dataTypeHelper, cdr);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.selectedVariant || changes.artifact?.currentValue) && this.shouldGetProfileDataItems()) {
      const userIds = this.getUserIds();
      this.profileDataItems$ = this.userProfileService.getProfilesData$(userIds);
      const enumKey = this.selectedVariant as keyof typeof DATUserLayoutVariant;
      if (DATUserLayoutVariant[enumKey] === DATUserLayoutVariant.ICON_AND_NAME || DATUserLayoutVariant[enumKey] === DATUserLayoutVariant.ICON_ONLY) {
        this.profileAndPictureDataItems$ = this.userProfileService.getProfilesAndPicturesData$(userIds);
        this.iconSize = this.defaultIconSize || this.getIconSize();
      }
    }
  }

  private shouldGetProfileDataItems(): boolean {
    return NonAttributeKeys.isUserSpecificAttributeKeyOrId(this.attributeId) || !!this.artifact?.attributes[this.attributeId]?.value?.length;
  }

  private getIconSize(): number {
    return (this.datUserContainer.nativeElement as HTMLElement).offsetHeight - DisplayAtUserComponent.PIC_OFFSET;
  }

  private getUserIds(): string[] {
    if (NonAttributeKeys.isUserSpecificAttributeKeyOrId(this.attributeId)) {
      const toSystemUserKey = new ToSystemUserKeyPipe();
      const systemUserKey = toSystemUserKey.transform(this.attributeId);
      return [SharedMethods.getValueFromPath(systemUserKey, this.artifact) as string];
    }
    const userValues = this.artifact.attributes[this.attributeId].value;
    const userValuesArray = Array.isArray(userValues) ? userValues : [userValues];
    return userValuesArray.map(userValue => (typeof userValue === 'string' ? userValue : userValue.value));
  }
}
