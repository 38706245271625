import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, DoCheck, ElementRef, Input, ViewChild } from '@angular/core';
import { PageBuilderGraphicalCopyPasterService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-copy-paster.service';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { PageBuilderGraphicalEventsService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-events.service';
import { CommonToolbarActions } from '@private/pages/page-management/page-builder-graphical/types/common-toolbar-actions';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBuilderEventType } from '@private/pages/page-management/page-builder-graphical/types/page-builder-event-type';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';
import { RedesignColumnsEvent } from '@private/pages/page-management/page-builder-graphical/types/redesign-columns-event';
import { RowDropEvent } from '@private/pages/page-management/page-builder-graphical/types/row-drop-event';
import { SaveAsTemplateEvent } from '@private/pages/page-management/page-builder-graphical/types/save-as-template-event';
import { SectionLocation } from '@private/pages/page-management/page-builder-graphical/types/section-location';
import { SetHtmlIdEvent } from '@private/pages/page-management/page-builder-graphical/types/set-html-id-event';
import { StyleElementEvent } from '@private/pages/page-management/page-builder-graphical/types/style-element-event';
import { TemplateType } from '@shared/components/templates/types/templates.types';
import { HideClasses } from '@shared/types/screen.types';

@Component({
  selector: 'app-page-section',
  templateUrl: './page-section.component.html',
  styleUrls: ['../../../../../../shared/styles/toolbar.scss', './page-section.component.scss'],
})
export class PageSectionComponent implements DoCheck, SectionLocation, CommonToolbarActions {
  @Input() section: PageSection;
  @Input() sectionIndex: number;
  @Input() modalId: string;
  @Input() page: Page;

  @ViewChild('toolbarRef', { read: ElementRef, static: true }) toolbarRef: ElementRef<HTMLDivElement>;

  toolbarShiftInPx: number;

  constructor(
    public readonly dragDropService: PageBuilderGraphicalDragDropService,
    public readonly copyPasterService: PageBuilderGraphicalCopyPasterService,
    private readonly eventsService: PageBuilderGraphicalEventsService,
  ) { }

  private get location(): SectionLocation {
    const filteredSections = this.dragDropService.currentSections.filter(section => section.modalId === this.modalId && section.index === this.sectionIndex);
    const sectionIndex = filteredSections[0].innerIndex;
    const { modalId } = this;

    return { sectionIndex, modalId };
  }

  ngDoCheck(): void {
    this.toolbarShiftInPx = this.toolbarRef.nativeElement.getBoundingClientRect().right;
  }

  redesignColumns($event: Event): void {
    if (this.modalId) return;
    const event = $event;
    const { sectionIndex } = this.location;
    this.eventsService.publish<RedesignColumnsEvent>(PageBuilderEventType.redesignColumns, { event, sectionIndex });
  }

  saveAsTemplate(): void {
    const templateType = TemplateType.section;
    const { location } = this;
    this.eventsService.publish<SaveAsTemplateEvent>(PageBuilderEventType.saveAsTemplate, { templateType, location });
  }

  style(domElements: HTMLDivElement[]): void {
    const { section: pageElement } = this;
    this.eventsService.publish<StyleElementEvent>(PageBuilderEventType.styleElement, { pageElement, domElements });
  }

  copy(): void {
    this.eventsService.publish<SectionLocation>(PageBuilderEventType.copy, this.location);
  }

  paste(): void {
    this.eventsService.publish<SectionLocation>(PageBuilderEventType.paste, this.location);
  }

  delete(): void {
    this.eventsService.publish<SectionLocation>(PageBuilderEventType.delete, this.location);
  }

  export(): void {
    this.eventsService.publish<SectionLocation>(PageBuilderEventType.export, this.location);
  }

  dropRow($event: CdkDragDrop<RowDropEvent>): void {
    this.eventsService.publish<CdkDragDrop<RowDropEvent>>(PageBuilderEventType.rowDrop, $event);
  }

  setId(event: Event): void {
    const setHtmlIdEvent: SetHtmlIdEvent = { event, pageElementWithHtmlId: this.section };
    this.eventsService.publish<SetHtmlIdEvent>(PageBuilderEventType.setHtmlId, setHtmlIdEvent);
  }

  addSection(): void {
    this.eventsService.publish<SectionLocation>(PageBuilderEventType.addSection, this.location);
  }
}
