import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input } from '@angular/core';
import { ArtifactTypeModel } from '@private/pages/artifact-type-management/artifact-type/types/artifact.type.types';
import { NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { GetAttributeFromClientAttribute, GetDataTypeFromDataTypeId } from '@shared/methods/artifact.methods';
import { IsCounter } from '@shared/methods/data-type.methods';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { EDITOR_KEY } from '@shared/constants/constants';

@Component({
  selector: 'app-artifact-type-attributes-form',
  templateUrl: './artifact-type-attributes-form.component.html',
  styleUrls: ['./artifact-type-attributes-form.component.scss'],
})
export class ArtifactTypeAttributesFormComponent {
  @Input() m: ArtifactTypeModel;
  @Input() updatePrimaryAttributes: () => void;
  @Input() updateAvailableAttributes: () => void;
  @Input() onAttributeChange: (artifactTypeAttribute: NewArtifactTypeClientAttribute, index: number) => void;

  initialValue = true;
  isShowHelpDialog: boolean;
  settings: any;
  helpModel: string;
  currentAttributeId: string;
  editorKey = EDITOR_KEY;

  constructor(private readonly tinyMceHelper: TinyMceHelper) {}

  ngOnInit(): void {
    const { attributes, dataTypes } = this.m.options;
    Object.keys(this.m.artifactType.attributes).forEach(key => {
      const attribute = GetAttributeFromClientAttribute(this.m.artifactType.attributes[key], attributes.listMap);

      if (attribute && IsCounter(GetDataTypeFromDataTypeId(attribute.dataTypeId, dataTypes.listMap)?.kind)) {
        this.initialValue = !!this.m.artifactType.attributes[key]?.initialValue;
      }
    });
    this.initTiny();
  }

  onInitValueChange(artifactTypeAttribute: NewArtifactTypeClientAttribute): void {
    artifactTypeAttribute.initialValue = this.initialValue ? this.initialValue.toString(): '';
  }

  addAttribute(): void {
    this.m.editableHelpingAttributes.clientAttributes = [...this.m.editableHelpingAttributes.clientAttributes, new NewArtifactTypeClientAttribute()];
  }

  dropItem(event: CdkDragDrop<any, any>): void {
    moveItemInArray(this.m.editableHelpingAttributes.clientAttributes, event.previousIndex, event.currentIndex);
    this.m.editableHelpingAttributes.clientAttributes = [...this.m.editableHelpingAttributes.clientAttributes];
  }

  removeAttribute(index: number): void {
    this.m.editableHelpingAttributes.clientAttributes = this.m.editableHelpingAttributes.clientAttributes.filter((_, i) => i !== index);
    this.updatePrimaryAttributes();
    this.updateAvailableAttributes();
  }

  showHelp(attributeId: string): void {
    this.currentAttributeId = attributeId;
    const attribute = this.m.editableHelpingAttributes.clientAttributes.find(attr => attr.id === attributeId);
    if (!attribute) return;

    !attribute.help && (attribute.help = '');
    this.helpModel = attribute.help;
    this.isShowHelpDialog = true;
  }

  apply(): void {
    const attribute = this.m.editableHelpingAttributes.clientAttributes.find(attr => attr.id === this.currentAttributeId);
    attribute && (attribute.help = this.helpModel);
  }

  private initTiny(): void {
    this.settings = this.tinyMceHelper.getTinyMceAdvancedSettings();
    this.settings.inline = false;
    this.settings.height = '500px';
    this.settings.sandbox_iframes = false;
  }
}
