import { Injectable } from '@angular/core';
import { SystemAdminUserTenantApplicationResponseDto } from '@api/models';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Constants, ID_KEY } from '@shared/constants/constants';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { StateKey } from '@shared/types/local-storage.types';
import { NewSystemUser } from '@shared/types/user.types';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApplicationSwitcherService {
  applications = new ListContainer<NewApplication>();

  selectedApplication$: Observable<NewApplication>;

  private selectedApplicationSubject: BehaviorSubject<NewApplication | null> = new BehaviorSubject<NewApplication | null>(null);

  constructor(private localStorageService: LocalStorageService) {
    this.selectedApplication$ = this.selectedApplicationSubject.pipe(filter(Boolean));
  }

  get selectedApplication(): NewApplication | null {
    return this.selectedApplicationSubject.value;
  }

  set selectedApplication(application: NewApplication | null) {
    this.selectedApplicationSubject.next(application);
  }

  setApplications(applications: NewApplication[], user: NewSystemUser | SelfUserResponseDto): void {
    if (!user.tenant?.isAdmin) {
      applications = this.filterApplicationsForAppAdmin(applications, user?.tenant?.applications || []);
    }

    this.applications.setList(applications, ID_KEY);
    this.setLatestSelectedApplication();
  }

  selectApplicationById(applicationId: string): void {
    if (this.applications.loaded) {
      const application = this.applications?.listMap[applicationId];
      application && this.changeSelectedApplication(application);
    }
  }

  changeSelectedApplication(application: NewApplication | null): void {
    if (application) {
      this.localStorageService.setToState(StateKey.session, Constants.selectedApplication, application.id);
      this.selectedApplication = application;
    }
  }

  private filterApplicationsForAppAdmin(applications: NewApplication[], tenantApplications: SystemAdminUserTenantApplicationResponseDto[]): NewApplication[] {
    return applications.filter(application => tenantApplications.find(tenantApplication => tenantApplication.id === application.id)?.isAdmin);
  }

  private setLatestSelectedApplication(): void {
    const lastApplicationId: string = this.localStorageService.getFromState(StateKey.session, Constants.selectedApplication);
    this.selectedApplication = null;

    if (lastApplicationId && this.applications.listMap[lastApplicationId]) {
      this.selectedApplication = this.applications.listMap[lastApplicationId];
    } else {
      if (this.applications.list.length) {
        this.selectedApplication = this.applications.list[0];
      }
    }

    this.changeSelectedApplication(this.selectedApplication);
  }
}
