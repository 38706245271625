import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';

import { SessionType } from '@shared/types/session-flow.types';
import { BaseSessionFlowFormComponent } from '../base.session-flow-form.component';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    // PRIMENG
    MultiSelectModule,
    InputTextModule,
  ],
  selector: 'app-openid-session-flow-form',
  templateUrl: './openid.session-flow-form.component.html',
  styleUrls: ['./openid.session-flow-form.component.scss'],
})
export class OpenIdSessionFlowFormComponent extends BaseSessionFlowFormComponent<SessionType.openid> {}
