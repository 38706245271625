import { PageStyles } from './page-styles';

export class PageSettings {
  autoReload = false;
  reloadInterval = 0;
  styles: PageStyles = new PageStyles();
  backgroundImafeFileId: string;

  constructor(dto?: Partial<PageSettings>) {
    if (dto) {
      dto.styles && (this.styles = new PageStyles(dto.styles));
    }
  }
}
