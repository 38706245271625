<!--rows with inputs to set content of menuitem and style of content-->
<div class="row" *ngIf="m.selected.treeItem">
  <div class="col-4 mb-0">
    <button class="w-100" (click)="selectContent(menuStylesPropNamesEnum.label);fontStyleOp.toggle($event)" icon="pi pi-cog" label="Label" pButton></button>
  </div>

  <div class="col-8 mb-0">
    <span class="dynamic-label">
      <input (change)="s.setTreeItemsToMenu()" [(ngModel)]="m.selected.treeItem.label" id="label" pInputText type="text" />
      <label class="dynamic-label-text" for="label" translate>{{'Label'| translate}}</label>
    </span>
  </div>
</div>

<div class="row" *ngIf="m.selected.treeItem">
  <div class="col-4 mb-0 ">
    <button class="w-100" (click)="selectContent(menuStylesPropNamesEnum.subLabel);fontStyleOp.toggle($event)"
            icon="pi pi-cog" label="Sub label" pButton></button>
  </div>

  <div class="col-8 mb-0">
    <span class="dynamic-label">
      <input (change)="s.setTreeItemsToMenu()" [(ngModel)]="m.selected.treeItem.subLabel" id="subLabel" pInputText type="text" />
      <label class="dynamic-label-text" for="subLabel" translate>{{'Sub label' | translate}}</label>
    </span>
  </div>
</div>

<div class="row" *ngIf="m.selected.treeItem">
  <div class="col-4 mb-0">
    <button class="w-100" (click)="selectContent(menuStylesPropNamesEnum.chip);fontStyleOp.toggle($event)" icon="pi pi-cog" label="Chip" pButton></button>
  </div>

  <div class="col-8 mb-0">
    <span class="dynamic-label">
      <input (change)="s.setTreeItemsToMenu()" [(ngModel)]="m.selected.treeItem.chip" id="chip" pInputText type="text" />
      <label class="dynamic-label-text" for="chip" translate>{{'Chip' | translate}}</label>
    </span>
  </div>
</div>

<div class="row" *ngIf="m.selected.treeItem">
  <div class="col-4 mb-0">
    <button class="w-100" (click)="selectContent(menuStylesPropNamesEnum.icon);fontStyleOp.toggle($event)" icon="pi pi-cog" label="Icon" pButton></button>
  </div>

  <div class="col-8 mb-0">
    <span class="dynamic-label icon-field">
      <p-dropdown (onChange)="s.setTreeItemsToMenu()"
                  [(ngModel)]="m.selected.treeItem.menuIcon"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  [virtualScrollItemSize]="20"
                  [options]="iconOptions"
                  [showClear]="true"
                  [style]="{'width':'100%'}"
                  [virtualScroll]="true"
                  id="icon">

        <ng-template let-selectedIcon pTemplate="selected.item">
          <div *ngIf="m.selected.treeItem.menuIcon" class="p-dropdown-item">
            <i [class]="selectedIcon.value"></i>
            <label class="ml-2">{{selectedIcon.label}}</label>
          </div>
        </ng-template>

        <ng-template let-icon pTemplate="item">
          <div class="p-dropdown-items">
            <i [class]="icon.value"></i>
            <label class="ml-2">{{icon.label}}</label>
          </div>
        </ng-template>

      </p-dropdown>
      <label class="dynamic-label-text" for="icon" translate>Icon</label>
    </span>
  </div>
</div>
<hr>

<div class="grid">
  <div class="col-4 mb-0">
    <button (click)="selectGenStyle();selectItemStyle();otherOp.toggle($event)" class="w-100" icon="pi pi-cog"
            label="{{'Other'| translate}}" pButton type="button"></button>
  </div>

  <div class="col-4 mb-0">
    <button (click)="selectGenStyle();selectItemStyle();borderOp.toggle($event)" class="w-100" icon="bi bi-border-style"
            label="{{'Borders'| translate}}" pButton type="button"></button>
  </div>

  <div class="col-4 mb-0">
    <button (click)="selectGenStyle();selectItemStyle();colorOp.toggle($event)" class="w-100" icon="pi pi-palette"
            label="{{'Colors'| translate}}" pButton type="button"></button>
  </div>
</div>
<!--declared overlays/dialogs-->
<p-overlayPanel #fontStyleOp [dismissable]="true" [showCloseIcon]="true" [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>
    <p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="selectItemStyle()">
      <p-tabPanel *ngFor="let styleType of m.styleTypes" [header]="styleType | translate">
        <h5>{{ selectedLabel + ' styles' | translate}}</h5>
        <app-content-style-form [contentStyle]="m.selected?.contentStyle" [isShowExtended]="true"></app-content-style-form>
      </p-tabPanel>
    </p-tabView>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #colorOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>

    <p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="selectItemStyle()">
      <p-tabPanel *ngFor="let styleType of m.styleTypes" [header]="styleType | translate">

        <h5>{{'Colors'| translate}}</h5>
        <div class="col-12 mb-0">
          <app-color-picker [(color)]="m.selected.defaultStyle.item.backgroundColor"
                            label="{{'Background color'|translate}}"></app-color-picker>
        </div>

      </p-tabPanel>
    </p-tabView>

  </ng-template>
</p-overlayPanel>

<p-overlayPanel #otherOp [style]="{'max-width':'380px'}" appendTo="body">
  <ng-template pTemplate>

    <h5>{{'Other styles'| translate}}</h5>
    <div class="row">

      <div class="col-6 mb-0">
        <h6> {{'Align item' | translate}}</h6>
        <p-dropdown
          [(ngModel)]="m.settings.item[m.selected.menuItem.hash].positioned"
          (ngModelChange)="s.setTreeItemsToMenu()"
          [autoDisplayFirst]="false"
          [options]="m.options.menuItemHorizontalAlign"
          id="menuItemAlignement">
        </p-dropdown>
      </div>

    </div>
    <hr>
    <app-padding-form (changesEmitted)="menuWidgetHelper.refreshMenuItemStylesPipe(m,m.selected.menuItem.hash)"
                      [paddingStyles]="m.selected.defaultGeneralStyle"></app-padding-form>
    <hr>
    <app-margin-form (changesEmitted)="menuWidgetHelper.refreshMenuItemStylesPipe(m,m.selected.menuItem.hash)"
                     [marginStyles]="m.selected.defaultGeneralStyle"></app-margin-form>

  </ng-template>
</p-overlayPanel>

<p-overlayPanel #borderOp [style]="{'max-width':'500px'}" appendTo="body">
  <ng-template pTemplate>

    <p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="selectItemStyle()">
      <p-tabPanel *ngFor="let styleType of m.styleTypes" [header]="styleType | translate">

        <app-border-form [styles]="m.selected.defaultStyle.item"></app-border-form>
        <button (click)="styleCopyService.copyBorderStyles(m.selected.defaultStyle.item)" class="mr-2"
                icon="pi pi-copy" pButton pTooltip="{{'Copy style'| translate}}"
                type="button"></button>
        <button (click)="styleCopyService.pasteBorderStyle(m.selected.defaultStyle.item)"
                *ngIf="styleCopyService.copiedBorderStyles" icon="bi bi-clipboard-plus" pButton
                pTooltip="{{'Paste style'| translate}}" type="button"></button>

      </p-tabPanel>
    </p-tabView>

  </ng-template>
</p-overlayPanel>
