import { Component, Input } from '@angular/core';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';
import { MenuItemUiType } from '@widgets/menu-widget/types/tree-types';

@Component({
  selector: 'app-default-style-settings-form',
  templateUrl: './default-style-settings-form.component.html',
  styleUrls: ['./default-style-settings-form.component.scss'],
})
export class DefaultStyleSettingsFormComponent {
  @Input() m: MenuWidgetModel;
  @Input() s: MenuWidgetService;
  menuItemUiType = MenuItemUiType;
}
