import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { SectionLocation } from '@private/pages/page-management/page-builder-graphical/types/section-location';
import { StickyElement } from '@shared/components/page/directives/sticky-element';
import { StickyPageElementService } from '@shared/components/page/services/sticky-page-element.service';

@Directive({
  selector: '[appStickyRow]',
})
export class StickyRowDirective extends StickyElement implements SectionLocation {
  @Input() sectionIndex: number;
  @Input() rowIndex: number;

  constructor(elRef: ElementRef<HTMLElement>, renderer: Renderer2, stickySectionService: StickyPageElementService) {
    super(elRef, renderer, stickySectionService);
  }

  ngOnInit() {
    if (this.rowIndex === undefined) {
      console.warn('rowIndex is undefined in StickyRowDirective');
    }
  }

  set zIndex(zIndex: number) {
    this.renderer.setStyle(this.elRef.nativeElement, 'z-index', zIndex);
  }
}
