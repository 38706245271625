const getUrlFromImageFileId = (backgroundImageFileId: string, rootUrl: string, artifactControllerDownloadPath: string, token?: string) => {
  let imageUrl = (rootUrl + artifactControllerDownloadPath).replace('{id}', backgroundImageFileId);
  imageUrl = token ? imageUrl + '?authorization=Bearer%20' + token : imageUrl;
  return imageUrl;
};

const checkIsExternalUrl = (value: string | null) => {
  if (!value) return false;
  const urlPattern = /(https?:\/\/[\da-z\.-]+\.[a-z\.]{2,6}[\/\w \.-]*)/i;
  if (urlPattern.test(value)) return true;
  return false;
};

export const ImageMethods = {
  getUrlFromImageFileId,
  checkIsExternalUrl,
};
