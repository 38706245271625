import { Injectable } from '@angular/core';
import { PageResponseDto } from '@api/models/page-response-dto';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ActiveLinkCheckHelper } from '@widgets/menu-widget/helpers/active-link-check.helper';
import { MenuWidgetHelper } from '@widgets/menu-widget/helpers/menu-widget.helper';
import { MenuWidgetComponent } from '@widgets/menu-widget/menu-widget.component';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import {
  ChipStyles,
  FontStyles,
  HorizontalDividerStyles,
  IconStyles,
  MenuItemContentStyles,
  MenuItemGeneralStyles,
  MenuItemStyles,
  VerticalDividerStyles,
} from '@widgets/menu-widget/types/menu-widget-styles.types';
import { MenuItem, MenuWidgetModel, MenuWidgetModelDto, MenuWidgetValue } from '@widgets/menu-widget/types/menu-widget.types';
import { TreeItem } from '@widgets/menu-widget/types/tree-types';
import { MenuItemSettings } from '../types/menu-widget-settings.types';

@Injectable()
export class MenuWidgetService {
  c: MenuWidgetComponent;
  m: MenuWidgetModel;

  constructor(
    public elvisUtil: ElvisUtil,
    private readonly cache: NewCacheService,
    private readonly menuWidgetHelper: MenuWidgetHelper,
    private readonly activeLinkCheckHelper: ActiveLinkCheckHelper,
  ) {}

  async init(context: MenuWidgetComponent): Promise<void> {
    this.initWidgetValue(context);

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
    this.initSubscriptions();
    this.setMenuItemsToTree();
    this.menuWidgetHelper.setPositionStyleBasedOnMenuType(this.m);
    if (context.isLayoutMode && this.shouldExpandActiveItem()) {
      this.findAndExpandActiveMenuItems();
    }

    if (this.m.isFirstLoad) {
      this.m.isFirstLoad = false;
    }
  }

  addTreeItem(): void {
    const newTreeItem = new TreeItem();
    this.addSettingsByHash(newTreeItem.hash);
    this.addRecordsByHash(newTreeItem.hash, true);
    this.addItemUnderSelectedItem(newTreeItem);
    setTimeout(() => (document.getElementById('settings_form')?.querySelector('#label') as HTMLInputElement)?.focus());
    this.setTreeItemsToMenu();
    this.m.selected.treeItem = newTreeItem;
    this.m.selected.pageId = null;
    if (this.m.selected.menuItem) {
      //commented out because high chance thast jan will want this behavior back
      //this.styleCopyPastingService.copyMenuItem(this.m.selected.menuItem);
      this.m.selected.menuItem = this.menuWidgetHelper.findNode(this.m.items.menu, newTreeItem.hash) as MenuItem;
      //this.m.selected.menuItem && this.styleCopyPastingService.pasteIntoSingleMenuItem(this.m.selected.menuItem,
      // this.m); this.styleCopyPastingService.copiedMenuWidgetItem = null;
    } else {
      this.m.selected.menuItem = this.menuWidgetHelper.findNode(this.m.items.menu, newTreeItem.hash) as MenuItem;
    }
    this.setBehaviorOptionDisability();
    this.m?.rerenderSettings();
  }

  addItemUnderSelectedItem(newTreeItem: TreeItem): void {
    if (this.m.selected.treeItem?.parent) {
      this.m.selected.treeItem.parent.children.push(newTreeItem);
    } else {
      this.m.items.tree.push(newTreeItem);
    }
  }

  removeTreeItem(node: TreeItem): void {
    const nodeChildren = node.children;
    const filterItems = (items: TreeItem[], index: number, target: TreeItem): void => {
      if (target.children?.length) {
        if (target.children.find(item => item.hash === node.hash)) {
          items[index].children = target.children.filter(item => item.hash !== node.hash);
        } else {
          target.children.forEach((item, index) => filterItems(target.children, index, item));
        }
      }
    };

    if (this.m.items.tree.find(item => item.hash === node.hash)) {
      this.m.items.tree = this.m.items.tree.filter(item => item.hash !== node.hash);
    } else {
      this.m.items.tree.forEach((item, index) => filterItems(this.m.items.tree, index, item));
    }

    this.menuWidgetHelper.unselectAll(this.m);
    this.setBehaviorOptionDisability();
    this.deleteRecordsOfChildren(nodeChildren);
    this.deleteSettingsByHash(node.hash);
    this.deleteRecordsByHash(node.hash);
    this.setTreeItemsToMenu();
  }

  onTreeItemSelect(event: { originalEvent?: MouseEvent; node: TreeItem }): void {
    this.synchronizeAliasSettings(event.node);
    this.m.selected.treeItem = event.node;
    this.m.selected.menuItem = this.menuWidgetHelper.findNode(this.m.items.menu, event.node.hash) as MenuItem;

    this.setBehaviorOptionDisability();

    const page = this.m.options.pages.listMap[this.m.selected.treeItem?.url || ''];
    if (page || this.m.selected.treeItem?.url) {
      this.m.selected.pageId = page?.id || (this.m.selected.treeItem.url as string);
      this.setTreeItemsToMenu();
    } else {
      this.m.selected.pageId = null;
    }
  }

  onPageSelect(option: any): void {
    this.m.selected.treeItem && (this.m.selected.treeItem.alias = this.m.options.pages.listMap[option.value].alias || '');
    this.setTreeItemsToMenu();
  }

  setTreeItemsToMenu(): void {
    if (this.m.selected.treeItem && this.m.selected.treeItem.isPageSelection) {
      this.m.selected.treeItem.url = this.m.selected.pageId || '';
    }
    this.m.items.menu = this.menuWidgetHelper.convertTreeItemsToMenu(this.m.items.tree);
  }

  setMenuItemsToTree(): void {
    this.m.items.tree = this.menuWidgetHelper.convertMenuItemsToTree(this.m.items.menu);
  }

  onDefaultStylesClick(): void {
    this.setTreeItemsToMenu();
    if (this.m.selected.treeItem?.usesDefaultStyle) {
      this.deleteRecordsByHash(this.m.selected.treeItem.hash);
    } else {
      this.m.selected.treeItem && this.addRecordsByHash(this.m.selected.treeItem.hash, false);
    }
  }

  setBehaviorOptionDisability(): void {
    if (!this.m.selected.treeItem) {
      this.m.behaviorOptionDisabled = false;
    } else {
      this.m.behaviorOptionDisabled = this.isBehaviorDisabled(this.m.selected.treeItem);
    }
  }

  findAndExpandActiveMenuItems(): void {
    this.setTreeItemsToMenu();

    const activeItems = [];
    const items: MenuItem[] = [...this.m.items.menu];
    const visited: Record<string, boolean> = {};

    while (items.length) {
      const item: any = items.shift();
      visited[(item as MenuItem).hash] = true;
      if (this.activeLinkCheckHelper.isRouterLinkActive(item, this.m.items.menu, this.c.queryParams)) {
        activeItems.push(item);
      }
      for (const child of item.children) {
        if (!visited[child.hash]) {
          visited[child.hash] = true;
          items.push(child);
        }
      }
    }

    activeItems.forEach(item => this.menuWidgetHelper.expandParents(item));
  }

  private initSubscriptions(): void {
    this.c.registerSubscription(
      this.cache.data.pages.subscribe(pages =>
        this.m.options.pages.setList(
          (pages as PageResponseDto[]).filter(page => !page.deleted).map(dto => new Page(dto)),
          'id',
        ),
      ),
    );
  }

  private deleteRecordsByHash(hash: string): void {
    this.deleteRecordsFromStyle(this.m.styles.standardStyles, hash);
    this.deleteRecordsFromStyle(this.m.styles.activeStyles, hash);
    this.deleteRecordsFromStyle(this.m.styles.hoverStyles, hash);
    this.m.styles.generalMenuItemStyles[hash] && delete this.m.styles.generalMenuItemStyles[hash];
    this.m.styles.horizontalDivider[hash] && delete this.m.styles.horizontalDivider[hash];
    this.m.styles.verticalDivider[hash] && delete this.m.styles.verticalDivider[hash];
  }

  private addRecordsByHash(hash: string, usesDefaultStyle?: boolean): void {
    const defaultStyle = this.menuWidgetHelper.getDefaultStyleUsedByItem(this.m, this.m.selected.menuItem as MenuItem);

    this.addRecordsToStyle(this.m.styles.standardStyles, hash, usesDefaultStyle);
    this.addRecordsToStyle(this.m.styles.activeStyles, hash, usesDefaultStyle);
    this.addRecordsToStyle(this.m.styles.hoverStyles, hash, usesDefaultStyle);

    if (!this.m.styles.generalMenuItemStyles[hash]) {
      this.m.styles.generalMenuItemStyles[hash] = usesDefaultStyle
        ? new MenuItemGeneralStyles(defaultStyle.generalMenuItemStyles)
        : new MenuItemGeneralStyles();
    }
    if (!this.m.styles.horizontalDivider[hash]) {
      this.m.styles.horizontalDivider[hash] = usesDefaultStyle
        ? new HorizontalDividerStyles(defaultStyle.horizontalDividerStyles)
        : new HorizontalDividerStyles();
    }
    if (!this.m.styles.verticalDivider[hash]) {
      this.m.styles.verticalDivider[hash] = usesDefaultStyle ? new VerticalDividerStyles(defaultStyle.verticalDividerStyles) : new VerticalDividerStyles();
    }
  }

  private addSettingsByHash(hash: string): void {
    this.m.settings.item[hash] = new MenuItemSettings();
  }

  private deleteSettingsByHash(hash: string): void {
    delete this.m.settings.item[hash];
  }

  private addRecordsToStyle(style: MenuItemContentStyles, hash: string, usesDefaultStyle?: boolean): void {
    if (!usesDefaultStyle && !style.item[hash]) {
      style.item[hash] = new MenuItemStyles();
    }
    if (!usesDefaultStyle && !style.label[hash]) {
      style.label[hash] = new FontStyles();
    }
    if (!usesDefaultStyle && !style.subLabel[hash]) {
      style.subLabel[hash] = new FontStyles();
    }
    if (!style.chip[hash]) {
      style.chip[hash] = new ChipStyles();
    }
    if (!style.icon[hash]) {
      style.icon[hash] = new IconStyles();
    }
  }

  private deleteRecordsFromStyle(style: MenuItemContentStyles, hash: string): void {
    style.item[hash] && delete style.item[hash];
    style.label[hash] && delete style.label[hash];
    style.subLabel[hash] && delete style.subLabel[hash];
  }

  private deleteRecordsOfChildren(items: MenuItem[] | TreeItem[]): void {
    items.forEach((item: MenuItem | TreeItem) => {
      this.deleteRecordsByHash(item.hash);
      item.children && this.deleteRecordsOfChildren(item.children);
    });
  }

  private initWidgetValue(context: MenuWidgetComponent): void {
    if (context.isLayoutMode) {
      !context.widget.value && (context.widget.value = new MenuWidgetValue());

      if (context.widget.value.model instanceof MenuWidgetModel) {
        return;
      }

      const model = context.widget.value.model as any as MenuWidgetModelDto;
      context.widget.value.model = new MenuWidgetModel({
        menuItems: model?.menuItems,
        styles: model?.styles,
        settings: model?.settings,
      });
    }
  }

  private isBehaviorDisabled(selectedItem: TreeItem): boolean {
    return !!selectedItem.children.length;
  }

  private shouldExpandActiveItem(): boolean {
    const { type, expandPanelToActive } = this.m.settings.menu;
    return type === MenuTypesEnum.panel && expandPanelToActive;
  }

  private synchronizeAliasSettings(treeItem: TreeItem): void {
    if (treeItem.isPageSelection && treeItem.url) {
      treeItem.alias = this.m.options.pages.listMap[treeItem.url].alias || '';
    }
  }
}
