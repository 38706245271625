/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext,HttpEventType,  HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ArtifactLinkResponseDto } from '../../models/artifact-link-response-dto';
import { ArtifactUploadLinkCreateRequestDto } from '../../models/artifact-upload-link-create-request-dto';

export interface ArtifactControllerUploadWithLink$Params {
  notify: boolean;
      body: ArtifactUploadLinkCreateRequestDto

cb?: any;
}

export function artifactControllerUploadWithLink(http: HttpClient, rootUrl: string, params: ArtifactControllerUploadWithLink$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactLinkResponseDto>> {
  const rb = new RequestBuilder(rootUrl, artifactControllerUploadWithLink.PATH + '?ngsw-bypass=true', 'post');
  if (params) {
    rb.query('notify', params.notify, {});
    rb.body(params.body, 'multipart/form-data');
  }
  rb.header('ngsw-bypass', 'true', {});

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context, reportProgress: true })
  ).pipe(
    tap(event => {
        if (event.type === HttpEventType.UploadProgress && event.loaded && event.total) {
            params.cb && params.cb(Math.round(100 * (event.loaded / event.total)));
        }
    }),
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ArtifactLinkResponseDto>;
    })
  );
}

artifactControllerUploadWithLink.PATH = '/api/tenant/artifact/upload-link';
