import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  CREATED_BY_EMAIL_KEY,
  CREATED_BY_KEY,
  CREATED_ON_KEY,
  EMPTY_FILTER_VALUE_SINGLE,
  ID_KEY,
  UPDATED_BY_EMAIL_KEY,
  UPDATED_BY_KEY,
  UPDATED_ON_KEY,
} from '@shared/constants/constants';
import { GetArtifactAttributeValuePath, TransformDecimalToServer } from '@shared/methods/client-attribute.methods';
import { IsUser } from '@shared/methods/data-type.methods';
import { BaseFolderFilterService } from '@shared/services/filter/filter-types/base-folder-filter.service';
import { BaseNumericFilterService } from '@shared/services/filter/filter-types/base-numeric-filter.service';
import { NumericFilterType } from '@shared/services/filter/filter-types/numeric-filter';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { MongoFilterOperator } from '@shared/types/filter.types';
import { SystemTenant } from '@shared/types/shared.types';
import { ComplexFilterMetadata, FilterType, NewTableColumn } from '@shared/types/table.types';
import { DateUtil } from '@shared/utils/date.util';
import { FilterOperator } from 'primeng/api';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { CoreListFilterEnum, ListMetaData, ListReqMetaData, ListResDtoI } from '../types/core.types';
import { CoreRxSubscriptionsComponent } from './core-rx-subscriptions.component';

@Directive()
export abstract class NewCoreListComponent<Entity> extends CoreRxSubscriptionsComponent implements OnInit, OnDestroy {
  @Input() readonly loadDataMethod: (params?: Partial<ListReqMetaData>, extras?: Record<string, any>) => Promise<ListResDtoI<Entity>>;
  @Input() readonly sortByAttribute: boolean = false;

  loading = true;
  currentUser: ArtifactLinkResponseDto;
  data: Entity[] = [];
  meta: ListMetaData = new ListMetaData();
  nonAttributeFields = [
    ID_KEY,
    CREATED_BY_EMAIL_KEY,
    UPDATED_BY_EMAIL_KEY,
    CREATED_ON_KEY,
    UPDATED_ON_KEY,
    CREATED_BY_KEY,
    UPDATED_BY_KEY,
    NonAttributeKeys.IS_HEADING,
    NonAttributeKeys.SECTION,
  ];

  protected constructor(
    protected readonly cache: NewCacheService,
    protected readonly baseNumericFilter: BaseNumericFilterService,
    protected readonly baseFolderFilter: BaseFolderFilterService,
    protected dateUtil?: DateUtil,
  ) {
    super();
  }

  static getMongoFilterByMatchMode(matchMode: CoreListFilterEnum, value: any, filterType: FilterType | null, useIsoDate: boolean): Record<string, any> {
    switch (matchMode) {
      case CoreListFilterEnum.startsWith:
        return { $regex: '^' + String(value), $options: 'i' };
      case CoreListFilterEnum.endsWith:
        return { $regex: String(value) + '$', $options: 'i' };
      case CoreListFilterEnum.contains:
        return { $regex: String(value), $options: 'i' };
      case CoreListFilterEnum.notContains:
        return { $not: { $regex: String(value), $options: 'i' } };
      case CoreListFilterEnum.equals:
        // TODO: fix
        // return { $eq: String(value) };
        return { $eq: value };
      case CoreListFilterEnum.notEquals:
        return { $not: { $eq: String(value) } };
      case CoreListFilterEnum.lessThan:
        return { $lt: String(value) };
      case CoreListFilterEnum.lessThanOrEqualTo:
        return { $lte: String(value) };
      case CoreListFilterEnum.greaterThan:
        return { $gt: String(value) };
      case CoreListFilterEnum.greaterThanOrEqualTo:
        return { $gte: String(value) };
      case CoreListFilterEnum.in:
        // TODO: consult
        return { $in: filterType === FilterType.boolean ? NewCoreListComponent.updateEmptyBooleanValues(value) : this.mapEnumAndUserValues(value) };
      // $oid with
      // micha
      case CoreListFilterEnum.notIn:
        // value.map((item:
        return { $nin: filterType === FilterType.boolean ? NewCoreListComponent.updateEmptyBooleanValues(value) : this.mapEnumAndUserValues(value) };
      // any) => ({
      // $oid: item
      // })) : value
      // };
      case CoreListFilterEnum.isNot:
        return { $not: value };
      case CoreListFilterEnum.before:
        return { $lt: value };
      case CoreListFilterEnum.after:
        return { $gt: value };
      case CoreListFilterEnum.dateIs:
        return { $eq: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.dateIsNot:
        return { $not: { $eq: useIsoDate ? { $date: value } : value } };
      case CoreListFilterEnum.dateBefore:
        return { $lt: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.dateAfter:
        return { $gt: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.dateBeforeOrEqualTo:
        return { $lte: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.dateAfterOrEqualTo:
        return { $gte: useIsoDate ? { $date: value } : value };
      case CoreListFilterEnum.isEmpty:
        return { $eq: value };
      case CoreListFilterEnum.isNotEmpty:
        return { $not: { $eq: value } };
      default:
        throw new Error(`Match mode value must be one of CoreListFilterEnum, but received: "${String(matchMode)}"`);
    }
  }

  private static mapEnumAndUserValues(values: any[]): any {
    return values.map(value => (value === '' ? value : { $oid: value }));
  }

  /**
   * @param values array of boolean values
   * @returns updated boolean values, where empty string '' is supplemented with null value
   */
  private static updateEmptyBooleanValues(values: any[]): any {
    const hasEmptyValue = values.includes(EMPTY_FILTER_VALUE_SINGLE);
    if (hasEmptyValue) {
      const hasNullValue = values.includes(null);
      return hasNullValue ? values : [...values, null];
    }
    return values;
  }

  private static getLogicalMongoOperator(operator?: string): string | null {
    switch (operator) {
      case FilterOperator.AND:
        return MongoFilterOperator.AND;
      case FilterOperator.OR:
        return MongoFilterOperator.OR;
      default:
        return null;
    }
  }

  ngOnInit(): void {
    this.onInit();
    this.currentUser = this.cache.userProfile.value as ArtifactLinkResponseDto;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy();
  }

  abstract transformFiltersToComplexData(filters: Record<string, FilterMetadata[]>): Record<string, ComplexFilterMetadata>;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onInit(): void {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onDestroy(): void {}

  protected isFieldAttribute(fieldName: string): boolean {
    return !this.nonAttributeFields.includes(fieldName) && !NonAttributeKeys.isArtifactTypeKey(fieldName);
  }

  protected getIdColumn(): NewTableColumn {
    return new NewTableColumn({ label: NonAttributeKeys.ID, key: NonAttributeKeys.ID });
  }

  protected transformSorting(sortField: string | undefined, sortOrder: number | undefined): Record<string, number> {
    const field = this.sortByAttribute ? GetArtifactAttributeValuePath(sortField as string) : sortField;
    return sortField && sortOrder ? { [field as any]: sortOrder } : {};
  }

  protected transformMultiSorting(sortFields: string[], sortOrder: number[]): Record<string, number> {
    if (sortFields.length && sortOrder.length) {
      return sortFields.reduce<Record<string, number>>((sortObject, sortField, index) => {
        const field = this.isFieldAttribute(sortField) ? GetArtifactAttributeValuePath(sortField) : sortField.replace('.email', '');
        sortObject[field] = sortOrder[index];
        return sortObject;
      }, {});
    } else {
      return {};
    }
  }

  // TODO - please refactor as soon as possible!!!
  /**
   * Returns processed filters for mongo query.
   * @param filters complex filter metadata per attribute
   * @returns processed filters for mongo query
   */
  protected processFilters(filters: Record<string, ComplexFilterMetadata>): Record<string, any> {
    const names = Object.keys(filters);
    const mongoFilters: Record<string, Record<string, any>[]> = {};

    names.forEach(name => {
      const mongoFilter: Record<string, any>[] = [];
      const tableColumnMetadata = filters[name].tableColumnMetadata;
      const { dbFilterKey, filterType, attributeMetadata } = tableColumnMetadata;
      let mongoFilterOperator: string | null = null;

      Array.isArray(filters[name].filterMetadata) &&
        filters[name].filterMetadata
          .filter(filter => !(String(filter.value).includes(SystemTenant.system) || String(filter.value).includes(SystemTenant.anonymous)))
          .forEach(filter => {
            // check and update for current user filter (enum indicates, the value will be array)
            let filterValue = filter.value;
            if (filterValue && this.isEnumBasedFilterType(filterType)) {
              const baseDataType = attributeMetadata?.dataType?.baseDataType;
              const isUserType = (baseDataType && IsUser(baseDataType)) || NonAttributeKeys.isUserSpecificAttributeKey(name);

              if (isUserType) {
                !Array.isArray(filterValue) && (filterValue = [filterValue]);
                filterValue = (filterValue as string[]).map(value => (value === NonAttributeKeys.CURRENT_USER_ID ? this.currentUser.id : value));
              }

              if (tableColumnMetadata.filterKey === NonAttributeKeys.ARTIFACT_TYPE_ID && filterValue?.length) {
                const { matchMode, operator } = filter;
                const value = Array.isArray(filterValue) ? filterValue?.map($oid => ({ $oid })) : [{ $oid: filterValue }];
                const matchModeObject = matchMode === CoreListFilterEnum.notIn ? { $nin: value } : { $in: value };

                mongoFilterOperator = NewCoreListComponent.getLogicalMongoOperator(operator);
                if (mongoFilterOperator) {
                  if (!mongoFilters[mongoFilterOperator]) mongoFilters[mongoFilterOperator] = [];
                  mongoFilters[mongoFilterOperator] = [
                    ...mongoFilters[mongoFilterOperator].filter(f => !Object.keys(f).includes(tableColumnMetadata.filterKey)),
                    { [tableColumnMetadata.filterKey]: matchModeObject },
                  ];
                }
                return;
              }
            }

            const matchMode: CoreListFilterEnum = filterValue && filterValue[0];
            const isEmptyTypeFilter = matchMode === CoreListFilterEnum.isNotEmpty || matchMode === CoreListFilterEnum.isEmpty;

            if (isEmptyTypeFilter && matchMode) {
              mongoFilter.push({
                [dbFilterKey]: NewCoreListComponent.getMongoFilterByMatchMode(matchMode, '', filterType, false),
              });
              mongoFilter.push({
                [dbFilterKey]: NewCoreListComponent.getMongoFilterByMatchMode(matchMode, null, filterType, false),
              });
            }

            // TODO - zamysliet sa nad touto podmienkou
            if ((filterValue || typeof filterValue === 'boolean' || filterValue === '') && JSON.stringify(filterValue) !== '[]') {
              const { matchMode, operator } = filter;

              mongoFilterOperator = NewCoreListComponent.getLogicalMongoOperator(operator);

              if (tableColumnMetadata.isAttribute) {
                if (!tableColumnMetadata.attributeMetadata) {
                  throw new Error(`Property "attributeMetadata" must be set if "isAttribute" is true!`);
                }

                if (this.dateUtil) {
                  switch (tableColumnMetadata.attributeMetadata.dataType.baseDataType) {
                    case BaseDataType.date:
                      filterValue = this.dateUtil.convertToServerDate(new Date(filterValue));
                      break;
                    case BaseDataType.dateTime:
                      filterValue = this.dateUtil.convertToServerDatetime(new Date(filterValue));
                      break;
                    case BaseDataType.time:
                      filterValue = this.dateUtil.convertToServerTime(new Date(filterValue));
                      break;
                    case BaseDataType.decimal:
                      filterValue = TransformDecimalToServer(filterValue);
                  }
                }

                if (typeof filterValue === 'boolean') {
                  filterValue = JSON.stringify(filterValue);
                }

                if (!filterType || this.isEnumBasedFilterType(filterType)) {
                  const matchModeObject = matchMode === CoreListFilterEnum.notIn ? { $nin: filterValue } : { $in: filterValue };
                  let query: any = { [dbFilterKey]: matchModeObject };

                  if (this.filterNotEmpty(filterValue) && Array.isArray(filterValue)) {
                    const index = filterValue.findIndex(item => (Array.isArray(item) && !item.length) || item === '');
                    if (index !== -1) {
                      query = { $or: [{ [dbFilterKey]: { $exists: false } }, { [dbFilterKey]: matchModeObject }] };
                    }
                  }

                  this.filterNotEmpty(filterValue) && mongoFilter.push(query);
                } else {
                  if (filterType === FilterType.numeric) {
                    const numericFilterAddon = this.getNumericFilterAddon(name, attributeMetadata?.dataType || null, filterValue, matchMode);
                    mongoFilter.push(numericFilterAddon);
                  } else if (!isEmptyTypeFilter) {
                    mongoFilter.push({
                      [dbFilterKey]: NewCoreListComponent.getMongoFilterByMatchMode(matchMode as CoreListFilterEnum, filterValue, filterType, false),
                    });
                  }
                }
              } else {
                if (name.includes('_id') || (name.includes('_Id') && matchMode === 'notEquals')) {
                  if (this.filterNotEmpty(filterValue)) {
                    mongoFilter.push({
                      [name]: {
                        $not: {
                          $in: typeof filterValue === 'string' ? { $oid: filterValue } : filterValue.map((id: any) => ({ $oid: id })),
                        },
                      },
                    });
                  }
                } else {
                  if (name.includes('id') || name.includes('Id')) {
                    if (this.filterNotEmpty(filterValue)) {
                      mongoFilter.push({
                        [name]: {
                          $in: typeof filterValue === 'string' ? { $oid: filterValue } : filterValue.map((id: any) => ({ $oid: id })),
                        },
                      });
                    }
                  } else {
                    const useIsoDate = NonAttributeKeys.isDateSpecificAttributeKey(name);

                    if (useIsoDate && this.dateUtil && (matchMode === CoreListFilterEnum.dateIs || matchMode === CoreListFilterEnum.dateIsNot)) {
                      const range = this.dateUtil.getStartEndDateFromDate(new Date(filter.value));
                      const $or = [{ [name]: { $gt: { $date: String(new Date(range.end)) } } }, { [name]: { $lt: { $date: String(new Date(range.start)) } } }];
                      const $and = [{ [name]: { $lt: { $date: String(new Date(range.end)) } } }, { [name]: { $gt: { $date: String(new Date(range.start)) } } }];
                      const res = matchMode === CoreListFilterEnum.dateIs ? { $and } : { $or };
                      mongoFilter.push(res);
                    } else if (filterType === FilterType.folder) {
                      const folderFilterAddon = this.getFolderFilterAddon(dbFilterKey, filterValue, filterType, matchMode);
                      mongoFilter.push(folderFilterAddon);
                    } else {
                      mongoFilter.push({
                        [name]: NewCoreListComponent.getMongoFilterByMatchMode(matchMode as CoreListFilterEnum, filterValue, filterType, useIsoDate),
                      });
                    }
                  }
                }
              }
            } else {
              if (name === 'deleted') {
                const { matchMode, operator, value } = filter;
                mongoFilterOperator = NewCoreListComponent.getLogicalMongoOperator(operator);
                mongoFilter.push({ deleted: NewCoreListComponent.getMongoFilterByMatchMode(matchMode as CoreListFilterEnum, value, filterType, false) });
              }
            }
          });

      if (mongoFilterOperator) {
        if (!mongoFilters[mongoFilterOperator]) {
          mongoFilters[mongoFilterOperator] = [];
        }
        mongoFilters[mongoFilterOperator] = [...mongoFilters[mongoFilterOperator], ...mongoFilter];
      }
    });

    return mongoFilters;
  }

  protected getNumericFilterAddon(attrKey: string, dataType: NewDataType | null, filterValue: any, matchMode?: string): Record<string, any> {
    const value = Array.isArray(filterValue) ? filterValue : [filterValue];
    const numericFilterAddon = this.baseNumericFilter.getQuery(attrKey, dataType, value, matchMode as NumericFilterType);
    return { $and: [numericFilterAddon] };
  }

  protected getFolderFilterAddon(attrKey: string, filterValue: any, filterType: FilterType | null, matchMode?: string): Record<string, any> {
    return this.baseFolderFilter.getQuery(attrKey, null, filterValue, matchMode as CoreListFilterEnum) || {};
  }

  /**
   * @param value filter value
   * @returns
   */
  private filterNotEmpty(value: any): boolean {
    return value === '' || (value && JSON.stringify(value) !== '[]');
  }

  private isEnumBasedFilterType(filterType: FilterType | null): boolean {
    return filterType === FilterType.enum || filterType === FilterType.user;
  }
}
