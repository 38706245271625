import { Background } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import {
  BackgroundAttachmentEnum,
  BackgroundPositionEnum,
  BackgroundRepeatEnum,
  BackgroundSizeEnum,
} from '@shared/components/image-pointer/types/image-pointer.types';
import { BackgroundTypeEnum } from '@widgets/shared/types/style.types';

export class PageStyles implements Background {
  backgroundType: BackgroundTypeEnum;
  backgroundColor: string;
  backgroundImage?: string;
  background: string;
  backgroundSize: string;
  backgroundPosition: string;
  backgroundRepeat: string;
  backgroundAttachment: string;
  backgroundOrigin: string;
  backgroundClip: string;
  backgroundBlendMode: string;
  backgroundOpacity: string;

  private static readonly _background_image_property = '--background-image';
  private static readonly _background_size_property = '--background-size';
  private static readonly _background_position_property = '--background-position';
  private static readonly _background_repeat_property = '--background-repeat';
  private static readonly _background_attachment_property = '--background-attachment';
  private static readonly _background_origin_property = '--background-origin';
  private static readonly _opacity_property = '--opacity';

  constructor(styles?: Partial<PageStyles>) {
    if (styles) {
      Object.assign(this, styles);
    }

    if (!this.backgroundRepeat) this.backgroundRepeat = BackgroundRepeatEnum.noRepeat;
    if (!this.backgroundSize) this.backgroundSize = BackgroundSizeEnum.auto;
    if (!this.backgroundPosition) this.backgroundPosition = BackgroundPositionEnum.centerCenter;
    if (!this.backgroundAttachment) this.backgroundAttachment = BackgroundAttachmentEnum.scroll;
    if (!this.backgroundOpacity) this.backgroundOpacity = '1';
  }

  static applyToBody(pageStyles: PageStyles): void {
    if (pageStyles) {
      const body = document.body;

      body.style.backgroundColor = pageStyles.backgroundColor === 'initial' || !pageStyles.backgroundColor ? '' : pageStyles.backgroundColor;
      body.style.backgroundClip = pageStyles.backgroundClip || '';
      body.style.backgroundBlendMode = pageStyles.backgroundBlendMode || '';

      body.style.setProperty(this._background_image_property, pageStyles.background || '');
      body.style.setProperty(this._background_size_property, pageStyles.backgroundSize || '');
      body.style.setProperty(this._background_position_property, pageStyles.backgroundPosition || '');
      body.style.setProperty(this._background_repeat_property, pageStyles.backgroundRepeat || '');
      body.style.setProperty(this._background_attachment_property, pageStyles.backgroundAttachment || '');
      body.style.setProperty(this._background_origin_property, pageStyles.backgroundOrigin || '');
      body.style.setProperty(this._opacity_property, pageStyles.backgroundOpacity || '');
    }
  }

  static resetToBody(): void {
    const body = document.body;

    body.style.backgroundColor = '';
    body.style.backgroundClip = '';
    body.style.backgroundBlendMode = '';

    body.style.removeProperty(this._background_image_property);
    body.style.removeProperty(this._background_size_property);
    body.style.removeProperty(this._background_position_property);
    body.style.removeProperty(this._background_repeat_property);
    body.style.removeProperty(this._background_attachment_property);
    body.style.removeProperty(this._background_origin_property);
    body.style.removeProperty(this._opacity_property);
  }
}
