<div class='layout-topbar'>
  <div class='topbar-left'>
    <button *ngIf='m.user && m.canUserAccessAdmin' (click)="s.goToAdmin()" pButton type="button" label="Admin"
            class="p-button-text p-button-plain"></button>
    <button *ngIf='m.user && !m.user.isSystemAdmin' [routerLink]="['/application-selection']" pButton type="button"
            label="Applications"
            class="p-button-text p-button-plain"></button>
  </div>

  <div class='topbar-right'>
    <ul class='topbar-menu'>
      <li class='profile-item' [ngClass]="{'active-menuitem fadeInDown': app.topBarUserMenuActive}">
        <a href='#' (click)='app.onTopBarUserMenuButtonClick($event)'>
          <img [src]="m.userProfileImageUrl || '../../../../assets/images/default-avatar.jpg'" alt='diamond-layout'
               class='profile-image' />
          <span class='profile-name'>{{ m.userName }}</span>
        </a>
        <ul class='profile-menu fade-in-up'>
          <li>
            <a [routerLink]="['/profile']">
              <span class='pi pi-user'></span>
              <span>{{ 'Profile' | translate }}</span>
            </a>
          </li>
          <li>
            <a (click)='logout()'>
              <span class='pi pi-power-off'></span>
              <span>{{ 'Logout' | translate }}</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
