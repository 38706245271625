/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ApplicationCreateRequestDto } from '../../models/application-create-request-dto';
import { ApplicationResponseDto } from '../../models/application-response-dto';

export interface ApplicationControllerCreate$Params {
      body: ApplicationCreateRequestDto

}

export function applicationControllerCreate(http: HttpClient, rootUrl: string, params: ApplicationControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationResponseDto>> {
  const rb = new RequestBuilder(rootUrl, applicationControllerCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }
  rb.header('ngsw-bypass', 'true', {});

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ApplicationResponseDto>;
    })
  );
}

applicationControllerCreate.PATH = '/api/tenant/application';
