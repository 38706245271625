import { Exception } from '@shared/types/exception.types';
import { SelectOption } from '@shared/types/shared.types';
import { get } from 'lodash';

export class ListContainer<Type> {
  private isLoaded = false;
  private items: Type[];
  private itemsMap: Record<string, Type>;

  constructor(list: Type[] = []) {
    this.items = list;
  }

  get list(): Type[] {
    return this.items;
  }

  get listMap(): Record<string, Type> {
    if (!this.itemsMap) {
      this.throwListMapError('ListContainer.listMap', 'listMap not set');
    }
    return this.itemsMap;
  }

  get loaded(): boolean {
    return this.isLoaded;
  }

  setList(list: Type[], listMapKey?: string): void {
    this.items = list;
    if (listMapKey) {
      this.itemsMap = {};
      this.items.forEach(item => (this.itemsMap[item[listMapKey as keyof Type] as any] = item));
    }
    this.isLoaded = true;
  }

  setItem(item: Type, listMapKey?: string): void {
    this.items.push(item);
    if (listMapKey) {
      this.itemsMap[item[listMapKey as keyof Type] as any] = item;
    }
  }

  filterByKey(path: string | string[], comparisonValue: string | string[]): Type[] {
    return this.items.filter(option => {
      const value = String(get(option, path));
      return Array.isArray(comparisonValue) ? comparisonValue.includes(value) : value === comparisonValue;
    });
  }

  toSelectOptions<ValueT>(labelKey?: string, valueKey?: string): SelectOption<string, ValueT>[] {
    return this.items.map(item => new SelectOption(labelKey ? get(item, labelKey) : item, valueKey ? get(item, valueKey) : item));
  }

  private throwListMapError(name: string, message: string): void {
    // const type: Constructor<Type> = new Type(); // todo: try to instantiate generic class
    if (this.items && this.items.length) {
      message += ` on class ${(this.items[0] as any).constructor.name}`;
    }
    throw new Exception({ name, message, originalEvent: null });
  }
}
