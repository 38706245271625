import { firstValueFrom, map, Observable, of, tap } from 'rxjs';
import { DbEntityCachedDataArguments, IdRecord, NewCachedSubjectUpdateFunctions } from '../types/new-cached-subject.types';
import { NewCachedSubject } from './new-cached-subject';

export class DbEntityCachedData<T extends IdRecord> extends NewCachedSubject<Array<T>> {
  private readonly _updateFns: NewCachedSubjectUpdateFunctions<T>;
  private readonly _keysForIndexing: Array<string> = ['id'];
  private readonly _listMap: Map<string, T> = new Map();
  private readonly _itemsCountLimit: number = 3000;

  constructor(args: DbEntityCachedDataArguments<T>) {
    const { localStorageMeta, noUpdateTimeMs, initialValue, updateFns, onUpdate, itemsCountLimit, callUpdateOnInit, additionalKeysForIndexing } = args;

    super({
      localStorageMeta,
      noUpdateTimeMs,
      initialValue: initialValue || [],
      // TODO: uncomment it when correct handling logic for deleted entities will be implemented
      // updateFn: () => args.updateFns.list(JSON.stringify({ deleted: { $eq: null } })),
      updateFn: () => args.updateFns.list(),
      onUpdate: onUpdate ? entity => onUpdate(entity as any) : undefined,
      callUpdateOnInit,
    });

    this._updateFns = updateFns;

    if (additionalKeysForIndexing) this._keysForIndexing = [...this._keysForIndexing, ...additionalKeysForIndexing];

    if (itemsCountLimit) {
      if (Number.isNaN(itemsCountLimit) || itemsCountLimit < 0) throw Error('"itemsCountLimit" can\'t be a negative number');
      this._itemsCountLimit = Math.round(itemsCountLimit);
    }

    if (this.value?.length) {
      this.value.forEach((entity: any) => this._keysForIndexing.forEach(key => this._listMap.set(entity[key as keyof IdRecord], entity)));
    }
  }

  get(id: string): T | null {
    return this._listMap.get(id) ? structuredClone(this._listMap.get(id))! : null;
  }

  get$(id: string): Observable<T> {
    if (this._listMap.has(id)) return of(structuredClone(this._listMap.get(id)) as T);

    return this._updateFns.get(id).pipe(
      tap((entity: T) => {
        const currentData = [...(this.getValue() as Array<T>)];
        const entityCopy = structuredClone(entity);

        if (currentData.length >= this._itemsCountLimit) {
          const needsToBeDeleted = currentData.splice(this._itemsCountLimit - 1);
          needsToBeDeleted.forEach(item => this._listMap.delete(item.id));
        }

        this._keysForIndexing.forEach(key => this._listMap.set(entity[key as keyof IdRecord], entityCopy));
        this.next([entityCopy, ...currentData]);
        return structuredClone(entity);
      }),
    );
  }

  async getAsync(id: string): Promise<T> {
    return firstValueFrom(this.get$(id));
  }

  getMany(ids: Array<string>): Array<T> {
    return ids.map(id => (this._listMap.has(id) ? (structuredClone(this._listMap.get(id)) as T) : null)).filter(Boolean) as Array<T>;
  }

  getMany$(ids: Array<string>, additionalFilters: Record<string, any>[] = []): Observable<Array<T>> {
    const needsToBeLoaded: Array<string> = [];
    const res: Array<T> = [];

    ids.forEach(id => (this._listMap.has(id) ? res.push(this._listMap.get(id) as T) : needsToBeLoaded.push(id)));

    if (needsToBeLoaded.length || additionalFilters.length) {
      // TODO: uncomment it when correct handling logic for deleted entities will be implemented
      // const filter = { $and: [{ deleted: { $eq: null } }, ...additionalFilters] };
      const filter = { $and: [...additionalFilters] };

      if (needsToBeLoaded.length) filter.$and.push({ _id: { $in: needsToBeLoaded.map(id => ({ $oid: id })) } });

      return this._updateFns.list(JSON.stringify(filter)).pipe(
        map((data: Array<T>) => [...data, ...res]),
        tap((data: Array<T>) => {
          const currentData = [...(this.getValue() as Array<T>)];

          if (currentData.length + data.length > this._itemsCountLimit) {
            const amountForDeletion = currentData.length + data.length - this._itemsCountLimit;
            const needsToBeDeleted = currentData.splice(currentData.length - amountForDeletion);
            needsToBeDeleted.forEach(item => this._listMap.delete(item.id));
          }

          res.forEach(entity => this._keysForIndexing.forEach(key => this._listMap.set(entity[key as keyof IdRecord], entity)));
          this.next(structuredClone([...res, ...currentData]));
        }),
      );
    }

    return of(structuredClone(res));
  }

  async getManyAsync(ids: Array<string>, additionalFilters: Record<string, any>[] = []): Promise<Array<T>> {
    return firstValueFrom(this.getMany$(ids, additionalFilters));
  }

  setItem(entity: T): void {
    const storedData = this._listMap.has(entity.id) ? (this.getValue() as Array<T>).filter(item => item.id !== entity.id) : (this.getValue() as Array<T>);
    const entityCopy = structuredClone(entity);
    this.next([entityCopy, ...storedData]);
    this._keysForIndexing.forEach(key => this._listMap.set(entity[key as keyof IdRecord], entityCopy));
  }

  protected _dataUpdateCb(value: Array<T>): void {
    (value || [])?.forEach(entity => this._listMap.set(entity.id, entity));
  }
}
