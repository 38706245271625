import { Component, inject, Input } from '@angular/core';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { SendEmailKeys, WorkflowActionSendEmail } from '@workflows/types';
import { EDITOR_KEY } from '@shared/constants/constants';

@Component({
  selector: 'app-action-send-email',
  templateUrl: './action-send-email.component.html',
  styleUrls: ['./action-send-email.component.scss'],
})
export class ActionSendEmailComponent {
  @Input() action: WorkflowActionSendEmail;

  settings = { ...inject(TinyMceHelper).getTinyMceAdvancedSettings(), inline: false };
  editorKey = EDITOR_KEY;
  protected readonly RuleKeys = SendEmailKeys;

  onIsDynamicRecipientChange(isDynamic: boolean): void {
    const idSettings = this.action.actionSettings[SendEmailKeys.emailsTo];
    idSettings.value = isDynamic ? '' : [''];
  }

  onAddRecipient(index: number): void {
    (this.action.actionSettings[SendEmailKeys.emailsTo].value as string[]).splice(index + 1, 0, '');
  }

  onRemoveRecipient(index: number): void {
    (this.action.actionSettings[SendEmailKeys.emailsTo].value as string[]).splice(index, 1);
  }

  emailsToTrackByFn(index: number): number {
    return index;
  }
}
