import { Directive, Input, OnInit } from '@angular/core';
import { SessionFlowService } from '@private/pages/current-tenant/session-flow/services/session-flow.service';

import { SessionFlow, SessionFlowScopes, SessionType } from '@shared/types/session-flow.types';
import { SelectOption } from '@shared/types/shared.types';

@Directive()
export abstract class BaseSessionFlowFormComponent<G extends SessionType> implements OnInit {
  @Input() protected sessionFlow: SessionFlow<G>;
  scopesOptions: SelectOption<string, SessionFlowScopes>[];

  constructor(protected readonly s: SessionFlowService) {}

  ngOnInit(): void {
    this.scopesOptions = Object.entries(SessionFlowScopes).reduce<SelectOption<string, SessionFlowScopes>[]>(
      (options: SelectOption<string, SessionFlowScopes>[], [key, value]: [string, SessionFlowScopes]) => {
        const label = key.toUpperCase();
        const option = new SelectOption(label, value);
        options.push(option);

        return options;
      },
      [],
    );
  }
}
