import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { PageBuilderGraphicalService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical.service';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { AnnouncementService } from '@shared/services/announcement.service';
import { SidebarModalStateService } from '@widgets/sidebar-modal-widget/services/sidebar-modal-state.service';
import { SidebarModalWidgetService } from '@widgets/sidebar-modal-widget/services/sidebar-modal.service';
import { ButtonView } from '@widgets/sidebar-modal-widget/types/sidebar-modal-widget-button.types';
import {
  ButtonState,
  SidebarModalState,
  SidebarModalWidgetModel,
  SidebarModalWidgetModelDto,
  SidebarModalWidgetValue,
} from '@widgets/sidebar-modal-widget/types/sidebar-modal.types';
import { SidebarWidgetValue } from '@widgets/sidebar-widget/types/sidebar-widget.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import {
  APPLICATION_ID,
  CURRENT_PAGE,
  DISABLED,
  HASH,
  IS_LAYOUT_MODE,
  IS_PREVIEW_MODE,
  LABEL,
  PAGE_ID,
  WIDGET,
} from '@widgets/widgets-core/constants/widgets-core.constants';
import { SidebarModalConstants } from '@widgets/sidebar-modal-widget/constants/constants';

@Component({
  selector: 'app-sidebar-modal-widget',
  templateUrl: './sidebar-modal-widget.component.html',
  styleUrls: ['./sidebar-modal-widget.component.scss'],
  providers: [SidebarModalWidgetService, PageBuilderGraphicalService, SidebarModalStateService],
})
export class SidebarModalWidgetComponent extends WidgetsCoreComponent<SidebarModalWidgetValue> implements OnInit, OnDestroy {
  m: SidebarModalWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(PAGE_ID) public pageId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<SidebarWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    @Inject(CURRENT_PAGE) public originalPage: Page,
    @Inject(HASH) public hash: string,
    @Inject(DISABLED) public disabled: boolean,
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    public readonly s: SidebarModalWidgetService,
    public readonly dragDropService: PageBuilderGraphicalDragDropService,
  ) {
    super(route, router, announcement, elRef);
  }

  get isShowButton(): boolean {
    const width = document.body.offsetWidth;
    return !this.m.settings.isFixed || (this.m.settings.isFixed && !this.isPreviewMode) || width < SidebarModalConstants.MOBILE_BREAK_POINT;
  }

  get currentButton(): ButtonView {
    let key: string = this.m.isOpen ? SidebarModalState.Open : SidebarModalState.Close;
    key += this.m.isHover ? ButtonState.Hover : ButtonState.NoHover;
    return (this.m.settings.buttons.buttonView as any)[key];
  }

  get buttonStyles(): any {
    return Object.assign(this.currentButton.borderStyles, this.currentButton.generalStyles, { flexDirection: this.m.settings.buttonContentFlexDirection, justifyContent: this.m.settings.alignButton });
  }

  ngOnInit(): void {
    this.s.init(this, this.isLayoutMode && this.widget?.value?.model ? (this.widget.value.model as SidebarModalWidgetModelDto) : null);
  }

  ngOnDestroy(): void {
    this.s.onDestroy();
  }

  onChangeSettings(isChangePosition = false): void {
    isChangePosition && this.s.afterChangePositionAction();
    this.s.storeSizeState();
    this.m.isOpen && this.s.render();
  }

  dropSection(event: CdkDragDrop<void>): void {
    if ((event.previousContainer.data as any).modalId !== this.m.page.modalId) {
      return;
    }
    this.dragDropService.dropSection(event, this.m.page);
  }
}
