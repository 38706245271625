<div class="grid">
  <div class="col-12">
    <h5>{{ header | translate }}</h5>
    <div class="field-radiobutton mb-0">
      <p-checkbox
        (ngModelChange)="handleClickChange.emit($event)"
        [(ngModel)]="handleClick"
        binary="true"
        id="ListItemClickAction"
        inputId="listItemClickAction"
      >
      </p-checkbox>
      <label for="listItemClickAction">{{ 'Handle click' | translate }}</label>
    </div>
  </div>

  <ng-container *ngIf="handleClick">
    <div class="col-6">
      <div *ngIf="!isAddToLinkDisabled" class="field-radiobutton">
        <p-radioButton
          (ngModelChange)="onClickActionChange($event)"
          [(ngModel)]="clickAction"
          [disabled]="isAddToLinkDisabled"
          [value]="ArtifactListItemClickAction.addToLink"
          id="AddToLink"
          inputId="addToLink"
          name="listItemClickAction"
        >
        </p-radioButton>
        <label for="addToLink">{{ 'Add to link' | translate }}</label>
      </div>

      <div class="field-radiobutton">
        <p-radioButton
          (ngModelChange)="onClickActionChange($event)"
          [(ngModel)]="clickAction"
          [value]="ArtifactListItemClickAction.goToPage"
          id="GoToPage"
          inputId="goToPage"
          name="listItemClickAction"
        >
        </p-radioButton>
        <label for="goToPage">{{ 'Go to page' | translate }}</label>
      </div>

      <div class="field-radiobutton">
        <p-radioButton
          (ngModelChange)="onClickActionChange($event)"
          [(ngModel)]="clickAction"
          [value]="ArtifactListItemClickAction.goToExternalPage"
          id="GoToExternalPage"
          inputId="goToExternalPage"
          name="listItemClickAction"
        >
        </p-radioButton>
        <label for="goToExternalPage">{{ 'Go to external page' | translate }}</label>
      </div>

      <div *ngIf="!isGoToDefaultPageDisabled" class="field-radiobutton">
        <p-radioButton
          (ngModelChange)="onClickActionChange($event)"
          [(ngModel)]="clickAction"
          [disabled]="isGoToDefaultPageDisabled"
          [value]="ArtifactListItemClickAction.goToDefaultArtifactPage"
          id="GoToDefaultArtifactPage"
          inputId="goToDefaultArtifactPage"
          name="listItemClickAction"
        >
        </p-radioButton>
        <label for="goToDefaultArtifactPage">{{ 'Go to default Artifact page' | translate }}</label>
      </div>

      <div class="field-radiobutton">
        <p-radioButton
          (ngModelChange)="onClickActionChange($event)"
          [(ngModel)]="clickAction"
          [value]="ArtifactListItemClickAction.selectItem"
          id="SelectItem"
          inputId="selectItem"
          name="listItemClickAction">
        </p-radioButton>
        <label for="selectItem">{{ 'Select item' | translate }}</label>
      </div>

      <div class="field-radiobutton">
        <p-radioButton
                (ngModelChange)="onClickActionChange($event)"
                [(ngModel)]="clickAction"
                [value]="ArtifactListItemClickAction.doNothing"
                id="doNothing"
                inputId="doNothing"
                name="listItemClickAction">
        </p-radioButton>
        <label for="doNothing">{{ 'Do nothing' | translate }}</label>
      </div>
    </div>

    <div [ngSwitch]="clickAction" class="col-6 flex flex-column">
      <span *ngIf="isEmittingUrlKeyEditable" class="dynamic-label">
        <label for="urlKey">{{ 'Key for changing url on item click' | translate }}</label>
        <input
          (ngModelChange)="emittingUrlKeyChange.emit($event)"
          [(ngModel)]="emittingUrlKey"
          id="urlKey"
          pInputText
          placeholder="'artifactId' url key"
          type="text"
        />
      </span>

      <ng-container *ngIf="showFolderEmittingOption">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox
              (ngModelChange)="shouldEmitFolderUrlKeyChange.emit($event)"
              [(ngModel)]="shouldEmitFolderUrlKey"
              binary="true"
              id="ShouldEmitFolderUrlKey"
              inputId="ShouldEmitFolderUrlKey"
            >
            </p-checkbox>
            <label for="ShouldEmitFolderUrlKey">{{ 'Enable folder url' | translate }}</label>
          </span>
        </span>

        <span *ngIf="shouldEmitFolderUrlKey" class="dynamic-label">
          <label for="folderUrlKey">{{ 'Key for changing folder url on item click' | translate }}</label>
          <input
            (ngModelChange)="emittingUrlKeyChange.emit($event)"
            [(ngModel)]="emittingFolderUrlKey"
            id="folderUrlKey"
            pInputText
            placeholder="'artifactId' url key"
            type="text"
          />
        </span>
      </ng-container>

      <div *ngIf="isPageOpeningEditable" class="field-radiobutton">
        <p-checkbox
          (ngModelChange)="openPageInNewTabChange.emit($event)"
          [(ngModel)]="openPageInNewTab"
          binary="true"
          id="GoToPageInNewTab"
          inputId="goToPageInNewTab"
        >
        </p-checkbox>
        <label for="goToPageInNewTab">{{ 'Open page in new tab' | translate }}</label>
      </div>

      <span *ngSwitchCase="ArtifactListItemClickAction.goToPage" class="dynamic-label">
        <p-dropdown
          (ngModelChange)="selectedPageChange.emit($event)"
          [(ngModel)]="selectedPage"
          [autoDisplayFirst]="false"
          [filter]="true"
          [options]="options.pages"
          [selectedPageId]="selectedPage"
          [virtualScrollItemSize]="20"
          [virtualScroll]="true"
          appNotifyIfSelectedPageDeletedDirective
          appendTo="body"
          placeholder="Select a page"
        >
        </p-dropdown>

        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="useAlias" (ngModelChange)="useAliasChange.emit($event)" binary="true" id="useAlias"></p-checkbox>
            <label for="useAlias">{{ 'Use page alias' | translate }}</label>
          </span>
        </span>
      </span>

      <span *ngSwitchCase="ArtifactListItemClickAction.goToExternalPage" class="dynamic-label">
        <label for="urlKey">{{ 'External page' | translate }}</label>
        <input
          #externalPageInput="ngModel"
          (ngModelChange)="externalPageChange.emit($event)"
          [(ngModel)]="externalPage"
          [pattern]="http_https_validation_regex"
          id="externalPage"
          pInputText
          placeholder="External page address"
          type="text"
        />
        <div *ngIf="externalPageInput.errors?.pattern" class="mt-3">
          <p class="text-red">{{ 'Please include' | translate }} <span class="text-bold"> http:// </span> or <span
            class="text-bold"> https:// </span></p>
        </div>
      </span>

      <span *ngSwitchCase="ArtifactListItemClickAction.selectItem" class="dynamic-label field-checkbox mt-auto mb-0">
        <ng-container *ngIf="!isHideMultiselect">
          <p-checkbox
            (ngModelChange)="multiselectChange.emit($event)"
            [(ngModel)]="multiselect"
            binary="true"
            id="Multiselect"
            inputId="multiselect">
          </p-checkbox>
          <label for="multiselect">{{ 'Multiselect' | translate }}</label>
        </ng-container>
      </span>
    </div>

    <div *ngIf="clickAction === ArtifactListItemClickAction.goToPage && !filtersDisabled" class="col-12 grid">
      <div class="col-6">
        <h5>{{ 'Query parameters' | translate }}</h5>
      </div>
      <div class="col-6">
        <button (click)="addFilter()" label="Add parameter" pButton type="button"></button>
      </div>

      <div class="col-12">
        <p-accordion>
          <p-accordionTab
            *ngFor="let filter of filtersHolder[filtersKey]; index as filterIndex"
            [(selected)]="tabsActiveState[filterIndex]"
            [header]="filter.name"
            class="filter-tab">
            <a
              (click)="removeFilter(filter)"
              class="pi pi-trash p-button p-button-rounded p-button-text p-button-danger remove-filter"
              pRipple>
            </a>

            <p-dropdown
              (ngModelChange)="onFilterAttributeChange($event, filter, filterIndex)"
              [filter]="true"
              [ngModel]="filter.attribute"
              [optionLabel]="'name'"
              [options]="options.attributes"
              placeholder="Attribute">
            </p-dropdown>

            <app-display-at-enum
              (onSelect)="onEnumValueSelect($event, filter)"
              *ngIf="filter.dataType?.isEnum; else nonEnumValuesTemplate"
              [activeIndexes]="filter.value.selectedEnumValues | selectedValuesToIndexes : filter.dataType.values"
              [enumOptions]="filter.dataType.values"
              [isPointer]="true"
              [isShowActive]="true"
              class="block-container full-size-container">
            </app-display-at-enum>

            <ng-template #nonEnumValuesTemplate>
              <app-date-time-filter
                *ngIf="filterTypeDetectionService.isDateTime(filter)"
                [dataType]="filter.dataType?.baseDataType"
                [m]="filter">
              </app-date-time-filter>

              <app-boolean-filter
                *ngIf="filter.dataType?.baseDataType === BaseDataType.boolean"
                [m]="filter"
                [showClear]="true">
              </app-boolean-filter>

              <div
                *ngIf="filter.dataType?.baseDataType === BaseDataType.text
               || filterTypeDetectionService.isFolderPath(filter)"
                class="grid">
                <div class="col-12">
                <span class="p-input-icon-right">
                  <i (click)="filter.value.ruleTypes[0].value = ''" *ngIf="filter.value"
                     class="pi pi-times clear-ico"></i>
                  <input
                    [(ngModel)]="filter.value.ruleTypes[0].value"
                    [placeholder]="'Enter Attribute value'"
                    pInputText
                    style="width: 100%"
                    type="text"
                  />
                </span>
                </div>
              </div>

              <app-user-filter
                *ngIf="filterTypeDetectionService.isUser(filter)"
                [m]="filter"
                [users]="options.users">
              </app-user-filter>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </ng-container>
</div>
