import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy } from '@angular/core';
import { AutocompleteInitEvent, AutocompleteSelectionEvent } from '@shared/components/context-autocomplete/types/base/autocomplete.types';
import { debounceTime, distinctUntilChanged, filter, fromEvent, map, merge, share, startWith, Subscription, tap } from 'rxjs';
import { AutocompleteService } from '../../services';

export type SupportAutoCompleteType = HTMLInputElement | HTMLTextAreaElement;

@Directive()
export abstract class AbstractAutocompleteDirective<T> implements AfterViewInit, OnDestroy {
  @Input() ownerId: string;
  @Input() variablePrefix = '';
  @Input() variableSuffix = '';

  event: KeyboardEvent;
  initEvent: AutocompleteInitEvent<T>;

  private autocompleteSubscription: Subscription;
  protected caretPosition: number;
  protected debounce = 250;
  protected escapeKey = 'Escape';
  protected enterKey = 'Enter';
  protected arrowDownKey = 'ArrowDown';

  protected constructor(
    protected elementRef: ElementRef,
    protected zone: NgZone,
    protected contextVariableAutocompleteService: AutocompleteService<T>,
  ) {}

  protected abstract doOpenOverlay(variableName: string, doFocusFirstItem?: boolean): void;

  protected abstract onVariableSelection(event: any): void;

  protected abstract isFocusInPotentialVariable(query: string, caretPosition: number): boolean;

  protected abstract shouldReactToEvent(event: AutocompleteSelectionEvent<T>): boolean;

  ngAfterViewInit(): void {
    if (this.elementRef) {
      this.zone.runOutsideAngular(() => {
        const selection$ = this.contextVariableAutocompleteService.autocompleteSelection$.pipe(
          filter(selectionEvent => this.shouldReactToEvent(selectionEvent) && selectionEvent.ownerId === this.ownerId),
          tap(selectionEvent => this.onVariableSelection(selectionEvent)),
        );

        const keyDown$ = fromEvent(this.elementRef.nativeElement, 'keydown').pipe(
          map(event => event as KeyboardEvent),
          filter(event => this.isDownKey(event)),
          tap(event => {
            event.preventDefault();
            event.stopPropagation();
          }),
        );

        const keypressBase$ = fromEvent(this.elementRef.nativeElement, 'keyup').pipe(
          map(event => event as KeyboardEvent),
          tap(event => (this.event = event)),
          share(),
        );

        const sourceValueBase$ = keypressBase$.pipe(
          filter(event => !this.isEscape(event) && !this.isEnter(event) && !this.isDownKey(event)),
          debounceTime(this.debounce),
          map(() => this.elementRef.nativeElement.value as string),
          share(),
        );

        const sourceValueEmpty$ = sourceValueBase$.pipe(
          map(query => !query),
          startWith(true),
          distinctUntilChanged(),
          tap(isEmpty => isEmpty && this.doCloseOverlay()),
        );

        const keypressDown$ = keypressBase$.pipe(
          filter(event => this.elementRef.nativeElement.value && this.isDownKey(event)),
          tap(event => {
            event.preventDefault();
            event.stopPropagation();
          }),
          map(() => this.elementRef.nativeElement.value as string),
        );

        const invokeAutocompleteSelection$ = merge(keypressDown$, sourceValueBase$).pipe(
          tap(() => (this.caretPosition = (this.elementRef.nativeElement as SupportAutoCompleteType).selectionStart || 0)),
          map(query => this.isFocusInPotentialVariable(query, this.caretPosition)),
          tap(isFocusInPotentialVariable => !isFocusInPotentialVariable && this.doCloseOverlay()),
          filter(isFocusInPotentialVariable => !!this.caretPosition && isFocusInPotentialVariable),
          map(() => this.getVariableName(this.elementRef.nativeElement.value as string, this.caretPosition)),
          tap(variable => this.zone.run(() => this.doOpenOverlay(variable, this.isDownKey(this.event)))),
        );

        this.autocompleteSubscription = merge(selection$, keyDown$, invokeAutocompleteSelection$, sourceValueEmpty$).subscribe();
      });
    }
  }

  ngOnDestroy(): void {
    this.autocompleteSubscription?.unsubscribe();
  }

  protected doCloseOverlay(): void {
    this.contextVariableAutocompleteService.notifyAutocompleteClose(this.ownerId, this.initEvent?.type);
  }

  protected getCurrentVariablePrefixIndex(query: string, caretPosition: number): number {
    const queryToCaret = query.substring(0, caretPosition);
    return queryToCaret.lastIndexOf(this.variablePrefix);
  }

  protected getVariableName(query: string, caretPosition: number): string {
    const variablePrefixIndex = this.getCurrentVariablePrefixIndex(query, caretPosition);
    const variableSuffixIndex = query.indexOf(this.variableSuffix, variablePrefixIndex);
    if (variableSuffixIndex > 0) {
      const candidate = query.substring(variablePrefixIndex + 1, variableSuffixIndex);
      const indexOfCandidatePrefix = candidate.indexOf(this.variablePrefix);
      return indexOfCandidatePrefix > 0 ? candidate.substring(0, indexOfCandidatePrefix) : candidate;
    }
    return query.substring(variablePrefixIndex + 1, caretPosition);
  }

  private isDownKey(event: KeyboardEvent): boolean {
    return event.key === this.arrowDownKey;
  }

  private isEscape(event: KeyboardEvent): boolean {
    return event.key === this.escapeKey;
  }

  private isEnter(event: KeyboardEvent): boolean {
    return event.key === this.enterKey;
  }
}
