import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { IS_DELETED_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { DoSomethingWithConfirmationParams, SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BreadcrumbService } from '../../../services/app.breadcrumb.service';
import { UserService } from './services/user.service';
import { UserPageModel } from './types/user-page.types';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  providers: [UserService, DialogService],
})
export class UserComponent extends CoreComponent<UserService, UserPageModel> {
  IS_DELETED_LABEL = IS_DELETED_LABEL;
  defaultApplicationOptions: SelectOption<string, string>[] = [];

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: UserService,
    announcement: AnnouncementService,
    public readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly confirmationService: ConfirmationService,
    private readonly elvisUtil: ElvisUtil,
    private readonly cache: NewCacheService,
  ) {
    super(route, router, translate, new UserPageModel(), service, announcement);
  }

  onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'User' }]);
  }

  async save(): Promise<void> {
    this.m.inSavingProgress = true;
    const dto = this.m.user.id ? await this.s.update() : await this.s.create();

    if (dto) {
      this.cache.data.users.setItem(dto);
      await this.s.cancel();
    }

    this.m.inSavingProgress = false;
  }

  async restoreWithConfirmation(): Promise<void> {
    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Restore', 'Are you sure that you want to restore user?'),
      async () => {
        const dto = await this.s.restore.call(this.s);

        if (dto) {
          this.cache.data.users.setItem(dto);
          await this.s.cancel();
        }
      },
    );
  }

  async deleteWithConfirmation(): Promise<void> {
    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Delete', 'Are you sure that you want to delete'),
      this.s.delete.bind(this.s),
    );
  }

  async resetPasswordWithConfirmation(): Promise<void> {
    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Reset password', 'Are you sure that you want to reset password'),
      this.s.resetUserPassword.bind(this.s),
    );
  }

  setDefaultApplicationOptions(): void {
    this.defaultApplicationOptions = this.m.user.applications?.length
      ? this.m.user.applications.map(app => {
          const application = this.m.applications.listMap[app.id];
          return new SelectOption(application.name, application.id);
        })
      : [];
  }
}
