import { Component, Input } from '@angular/core';
import { ArtifactResponseDto } from '@api/models';
import { DATFileLayoutVariant } from '@shared/types/display-at-types';
import { SingleFileAttributeValuePresentationService } from '@widgets/card-widget/services/single-file-attribute-value-presentation.service';
import { FileWithUrl } from '@widgets/card-widget/types/file-with.url';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-single-file-attribute-value-presentation',
  templateUrl: './single-file-attribute-value-presentation.component.html',
  styleUrls: ['./single-file-attribute-value-presentation.component.scss'],
})
export class SingleFileAttributeValuePresentationComponent {
  @Input() layoutVariant: keyof typeof DATFileLayoutVariant;
  file$: Observable<FileWithUrl | null>;
  readonly nonImageFileIcon = 'assets/images/non-image-file.ico';

  constructor(private readonly service: SingleFileAttributeValuePresentationService) {}

  @Input() set fileDto(dto: ArtifactResponseDto) {
    this.file$ = dto ? this.service.getFileArtifactByDto(dto) : of(null);
  }

  get isDefaultLayout(): boolean {
    return this.layoutVariant === 'DEFAULT';
  }

  get isPictureLayout(): boolean {
    return this.layoutVariant === 'PICTURE';
  }

  get isPictureWithNameLayout(): boolean {
    return this.layoutVariant === 'PICTURE_WITH_NAME';
  }

  get isPictureWithLinkLayout(): boolean {
    return this.layoutVariant === 'PICTURE_WITH_LINK';
  }
}
