import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { DisplayAtMetadata } from '@shared/types/display-at-types';
import {
  AlignContent,
  AlignItems,
  BackgroundTypeEnum,
  BorderStyles,
  BorderStylesEnum,
  BorderWidthEnum,
  ContainerClassEnum,
  DisplayEnum,
  FlexDirection,
  FlexWrap,
  FontFamilyEnum,
  FontStyleEnum,
  FontWeightEnum,
  JustifyContent,
  LabelPositionEnum,
  ListStyleEnum,
  OverflowEnum,
  TextDecorationEnum,
  TextHorizontalAlignEnum,
  TextVerticalAlignEnum,
} from '@widgets/shared/types/style.types';

export class LinkTypeFormatSettings {
  label = new LinkTypeLabelFormatSettings();
  value = new LinkTypeValueFormatSettings();
  innerArea = new InnerAreaStyles({
    borderRightStyle: BorderStylesEnum.none,
    borderLeftStyle: BorderStylesEnum.none,
    borderTopStyle: BorderStylesEnum.none,
    borderBottomStyle: BorderStylesEnum.none,
  });
  outerArea = new OuterAreaStyles();
  hideOnPageLoad = false;
  reserveSpaceWhenHidden = false;

  constructor(settings?: Partial<LinkTypeFormatSettings>) {
    if (settings) {
      this.hideOnPageLoad = !!settings.hideOnPageLoad;
      this.reserveSpaceWhenHidden = !!settings.reserveSpaceWhenHidden;
      settings.label && (this.label = new LinkTypeLabelFormatSettings(settings.label));
      settings.value && (this.value = new LinkTypeValueFormatSettings(settings.value));
      settings.innerArea && (this.innerArea = new InnerAreaStyles(settings.innerArea));
      settings.outerArea && (this.outerArea = new OuterAreaStyles(settings.outerArea));
    }
  }
}

export class AttributeFormatSettings {
  editable = true;
  label = new AttributeLabelFormatSettings();
  value = new AttributeValueFormatSettings();
  innerArea = new InnerAreaStyles({
    borderRightStyle: BorderStylesEnum.none,
    borderLeftStyle: BorderStylesEnum.none,
    borderTopStyle: BorderStylesEnum.none,
    borderBottomStyle: BorderStylesEnum.none,
  });
  outerArea = new OuterAreaStyles();
  initialValue: any = undefined;
  showCounterOption?: boolean;
  counterOptionValue?: boolean = true;
  ignoreInitialValue = false;
  hideOnPageLoad = false;
  reserveSpaceWhenHidden = false;
  sizeLimit?: number;

  constructor(settings?: Partial<AttributeFormatSettings>) {
    if (settings) {
      this.editable = !!settings.editable;
      this.ignoreInitialValue = !!settings.ignoreInitialValue;
      this.hideOnPageLoad = !!settings.hideOnPageLoad;
      this.reserveSpaceWhenHidden = !!settings.reserveSpaceWhenHidden;
      settings.label && (this.label = new AttributeLabelFormatSettings(settings.label));
      settings.value && (this.value = new AttributeValueFormatSettings(settings.value));
      settings.innerArea && (this.innerArea = new InnerAreaStyles(settings.innerArea));
      settings.outerArea && (this.outerArea = new OuterAreaStyles(settings.outerArea));
      settings.initialValue && (this.initialValue = settings.initialValue);
      (settings.showCounterOption !== undefined) && (this.showCounterOption = settings.showCounterOption);
      (settings.counterOptionValue !== undefined) && (this.counterOptionValue = settings.counterOptionValue);
    }
  }
}

export class AttributeLabelFormatSettings {
  visible = true;
  showIcon = false;
  showLink = true;
  position = LabelPositionEnum.top;
  useCustomLabel = false;
  customLabel = '';
  styles = new FormatStyles();

  constructor(settings?: Partial<AttributeLabelFormatSettings>) {
    if (settings) {
      this.visible = !!settings.visible;
      this.showIcon = !!settings.showIcon;
      settings.showLink !== undefined && (this.showLink = settings.showLink);
      this.useCustomLabel = !!settings.useCustomLabel;
      settings.customLabel && (this.customLabel = settings.customLabel);
      settings.position && (this.position = settings.position);
      settings.styles && (this.styles = new FormatStyles(settings.styles));
    }
  }
}

export class AttributeValueFormatSettings {
  showPlaceholder = false;
  placeholder = '';
  showIcon = false;
  styles = new FormatStyles();
  fieldStyles = new FieldStyles();
  displayMetadata?: DisplayAtMetadata;

  constructor(settings?: Partial<AttributeValueFormatSettings>) {
    if (settings) {
      this.showPlaceholder = !!settings.showPlaceholder;
      settings.placeholder && (this.placeholder = settings.placeholder);
      this.showIcon = !!settings.showIcon;
      settings.styles && (this.styles = new FormatStyles(settings.styles));
      settings.fieldStyles && (this.fieldStyles = new FieldStyles(settings.fieldStyles));
      this.displayMetadata = settings.displayMetadata;
    }
  }
}

export class LinkTypeLabelFormatSettings {
  visible = true;
  position = LabelPositionEnum.top;
  showDirection = true;
  editInView = true;
  useCustomLabel = false;
  customLabel = '';
  styles = new FormatStyles();

  constructor(settings?: Partial<LinkTypeLabelFormatSettings>) {
    if (settings) {
      Object.assign(this, settings);
      settings.styles && (this.styles = new FormatStyles(settings.styles));
    }
  }
}

export class LinkTypeValueFormatSettings {
  showIcon = false;
  paddingLeft: string | null = null;
  listStyle: ListStyleEnum = ListStyleEnum.disc;
  styles = new FormatStyles();
  displayMetadata?: DisplayAtMetadata;

  constructor(settings?: Partial<LinkTypeValueFormatSettings>) {
    if (settings) {
      this.showIcon = !!settings.showIcon;
      settings.styles && (this.styles = new FormatStyles(settings.styles));
      this.displayMetadata = settings.displayMetadata;
    }
  }
}

export class FieldStyles {
  backgroundColor: '#ffffff';
  borderColor = '#ced4da';
  borderRadius = '4px';
  borderStyle = BorderStylesEnum.solid;
  borderWidth = BorderWidthEnum.thin;
  fontWeight = FontWeightEnum.normal;
  fontStyle = FontStyleEnum.normal;
  fontFamily = FontFamilyEnum.nunito;
  fontSize = '14px';
  textDecoration = TextDecorationEnum.none;
  textAlign = TextHorizontalAlignEnum.left;
  verticalAlign = TextVerticalAlignEnum.top;
  color?: string;
  prefix?: string;
  suffix?: string;
  minDecimalPlaces?: number;
  maxDecimalPlaces?: number;
  minTextareaHeight: string = '33px';

  constructor(styles?: Partial<FieldStyles>) {
    if (styles) {
      Object.assign(this, styles);
    }
  }
}

export class FormatStyles implements StylesDto {
  display = DisplayEnum.flex;
  fontWeight = FontWeightEnum.normal;
  fontStyle = FontStyleEnum.normal;
  fontSize = '14px';
  textDecoration = TextDecorationEnum.none;
  textAlign = TextHorizontalAlignEnum.left;
  verticalAlign = TextVerticalAlignEnum.top;
  color?: string;
  fontFamily = FontFamilyEnum.nunito;
  prefix?: string;
  suffix?: string;
  minDecimalPlaces?: number;
  maxDecimalPlaces?: number;
  padding?: string;
  borderRadius?: string;
  borderColor?: string;
  borderWidth?: string;
  borderStyle?: string;
  alignContent: AlignContent;
  alignItems: AlignItems = AlignItems.center;
  backgroundColor: string;
  backgroundType: BackgroundTypeEnum;
  borderBottomLeftRadius: string;
  borderBottomRightRadius: string;
  borderBottomWidth: BorderWidthEnum;
  borderLeftWidth: BorderWidthEnum;
  borderRadiusRange: number[];
  borderRightWidth: BorderWidthEnum;
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  borderTopWidth: BorderWidthEnum;
  boxShadow: string;
  columnGap: string;
  containerClass: ContainerClassEnum;
  flexDirection: FlexDirection;
  flexWrap: FlexWrap;
  gap: string;
  gridTemplateColumns: string;
  gridTemplateRows: string;
  justifyContent: JustifyContent;
  marginBottom: string;
  marginLeft: string;
  marginRight: string;
  marginTop: string;
  maxWidth: string;
  minWidth: string;
  overflowX: OverflowEnum;
  overflowY: OverflowEnum;
  paddingBottom: string;
  paddingLeft: string;
  paddingRight: string;
  paddingTop: string;
  rowGap: string;

  '--background-attachment': string;
  '--background-color': string;
  '--background-image': string;
  '--background-position': string;
  '--background-repeat': string;
  '--background-size': string;
  '--border-style': string;
  '--box-shadow': string;
  '--opacity': string;

  constructor(styles?: Partial<FormatStyles>) {
    styles && Object.assign(this, styles);
  }
}

export class InnerAreaStyles extends BorderStyles {
  backgroundColor?: string;
  paddingTop = '5px';
  paddingLeft = '15px';
  paddingBottom = '5px';
  paddingRight = '15px';

  constructor(params?: Partial<InnerAreaStyles>) {
    super();
    Object.assign(this, params);
  }
}

export class OuterAreaStyles {
  paddingTop = '0px';
  paddingLeft = '1px';
  paddingBottom = '0px';
  paddingRight = '1px';

  constructor(params?: Partial<OuterAreaStyles>) {
    Object.assign(this, params);
  }
}
