import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TemplatesTableComponent } from './templates/components/templates-table/templates-table.component';
import { GetTemplateFilterOptionsPipe } from './templates/pipes/get-template-filter-options.pipe';
import { PrintTemplateToTablePipe } from './templates/pipes/print-template-to-table.pipe';
import { TemplatesComponent } from './templates/templates.component';

@NgModule({
  declarations: [TemplatesComponent, TemplatesTableComponent, PrintTemplateToTablePipe, GetTemplateFilterOptionsPipe],
  imports: [CommonModule, TranslateModule, ElvisSharedModule, TableModule, MultiSelectModule, FileUploadModule, TooltipModule, OverlayPanelModule, FormsModule],
})
export class TemplateManagementModule {}
