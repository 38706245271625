import { Directive, Input, OnInit } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GlobalConstants } from '@shared/constants/global.constants';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { SelectOption } from '@shared/types/shared.types';
import { filter } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseDataType } from '../../../../../e2e/enums/base-data-type-enum/baseDataType.enum';
import { AttributeActionHandlerService } from '../services';
import { ModifyAttributeValueEvent } from '../types';
import { AttributeValueModifiable } from '../types/attribute-value-modifiable';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';

@Directive({
  selector: '[appModifyAttributeValue]',
})
export class ModifyAttributeValueDirective extends AbstractRuleAttributeDirective implements OnInit {
  @Input() attributeOwner: AttributeValueModifiable;

  constructor(
    protected actionAttributeHandler: AttributeActionHandlerService,
    private cache: NewCacheService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.actionAttributeHandler.registerModifyAttributeValueEventListener(this.key);
    this.initSubscription = this.actionAttributeHandler.modifyAttributeValueEventMap$
      .pipe(
        filter(e => !!e.event && this.isMatchingAttributeData(e.event) && this.actionAttributeHandler.containsModifyAttributeValueEvent(this.key)),
        map(() => this.actionAttributeHandler.getModifyAttributeValueEvent(this.key)),
      )
      .subscribe((modifyAttributeValueEvent: ModifyAttributeValueEvent) => {
        this.modifyAttributeValue(modifyAttributeValueEvent);
        this.actionAttributeHandler.removeModifyAttributeValueEventFromMap(this.key, false);
      });
  }

  ngOnDestroy(): void {
    this.actionAttributeHandler.unregisterModifyAttributeValueEventListener(this.key);
    super.ngOnDestroy();
  }

  private modifyAttributeValue(event: ModifyAttributeValueEvent) {
    const newValue = this.processAttributeValue(event);
    this.attributeOwner.modifyAttributeValue(newValue, event.operation);
  }

  private processAttributeValue(event: ModifyAttributeValueEvent): any {
    const attribute = this.cache.data.attributes.get(event.attributeId);
    const dataType = this.cache.data.dataTypes.get(attribute?.dataTypeId || '');

    if (dataType?.baseDataType === BaseDataType.user) {
      const uesr = this.cache.userProfile.value as ArtifactLinkResponseDto;

      return (event.value as SelectOption<string, string>[]).map(option =>
        option.value === NonAttributeKeys.CURRENT_USER_ID
          ? new SelectOption(uesr.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)], uesr.id)
          : option,
      );
    }

    return event.value;
  }
}
