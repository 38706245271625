import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { LimitedTopBarComponent } from '@limited/components/limited-top-bar/limited-top-bar.component';
import { LimitedTopBarModel } from '@limited/components/limited-top-bar/types/limited-top-bar.model';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GlobalConstants } from '@shared/constants/global.constants';
import { CoreService } from '@shared/core/services/core.service';
import { ImageMethods } from '@shared/methods/image.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';

@Injectable({ providedIn: 'root' })
export class LimitedTopBarService extends CoreService<LimitedTopBarComponent, LimitedTopBarModel> {
  constructor(
    public readonly localStorageService: LocalStorageService,
    private readonly cache: NewCacheService,
    private readonly authorizationService: AuthorizationService,
    private readonly apiConfiguration: ApiConfiguration,
  ) {
    super();
  }

  async init(context: LimitedTopBarComponent, model: LimitedTopBarModel): Promise<void> {
    super.init(context, model);
    this.c.registerSubscription(
      this.cache.user.subscribe(user => {
        if (!user) return;

        const attributes = (this.cache.userProfile.value as ArtifactLinkResponseDto)?.attributes;

        this.m.user = user as SelfUserResponseDto;
        this.m.userName = attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string;
        this.m.userProfileImageUrl = ImageMethods.getUrlFromImageFileId(
          attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.profilePictureAttributeId)]?.value as string,
          this.apiConfiguration.rootUrl,
          this.authorizationService.getToken,
        );
        this.m.canUserAccessAdmin = Boolean(this.m.user.tenant?.isAdmin || this.m.user.tenant?.applications.some(app => app.isAdmin));
      }),
    );
  }

  async goToAdmin(): Promise<void> {
    this.cache.refreshCache();
    this.authorizationService.resetLoggedUserData();
    await this.c.router.navigate(['/admin']);
  }
}
