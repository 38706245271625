import { CreateArtifactsActionWorkflowSettingsDto } from '@api/models/create-artifacts-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { WorkflowActionType } from '@workflows/types';
import { WorkflowActionDeleteArtifactsDto } from '@workflows/types/actions/action-delete-artifacts';
import { cloneDeep, isArray } from 'lodash';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export enum CreateArtifactsKeys {
  forEachPath = 'forEachPath',
  artifactTypeId = 'artifactTypeId',
  attributesMap = 'attributesMap',
  storeResultsPath = 'storeResultsPath',
  parentFolderId = 'parentFolderId',
  filePath = 'filePath',
}

export type WorkflowCreateAttributeMapValue = string | string[];
export type WorkflowCreateAttributeMap = Record<string, WorkflowCreateAttributeMapValue>;

export class WorkflowActionCreateArtifactsDto implements CreateArtifactsActionWorkflowSettingsDto {
  forEachPath: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.forEachPath] = { value: '', isDynamic: false };
  artifactTypeId: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.artifactTypeId] = { value: '', isDynamic: false };
  attributesMap: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.attributesMap] = { value: {}, isDynamic: false };
  storeResultsPath: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.storeResultsPath] = { value: '', isDynamic: false };
  parentFolderId: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.parentFolderId] = { value: '', isDynamic: false };
  filePath: CreateArtifactsActionWorkflowSettingsDto[CreateArtifactsKeys.filePath] = { value: '', isDynamic: false };

  constructor(action?: WorkflowActionDeleteArtifactsDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionCreateArtifacts extends AbstractWorkflowAction<WorkflowActionCreateArtifactsDto> {
  constructor(dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionCreateArtifactsDto, type: WorkflowActionType.CREATE_ARTIFACTS, dto });
    dto && this.fromDto(dto);
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  fromDto(dto: WorkflowActionDto) {
    if (dto) {
      Object.assign(this, dto);
    }
  }

  toServer(): WorkflowActionDto {
    const attributesMap = cloneDeep(this.actionSettings.attributesMap.value) as WorkflowCreateAttributeMap;

    Object.keys(attributesMap).forEach(key => {
      isArray(attributesMap[key]) && !attributesMap[key].length && delete attributesMap[key];
      !isArray(attributesMap[key]) && attributesMap[key] === null && delete attributesMap[key];
    });

    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [CreateArtifactsKeys.attributesMap]: {
          isDynamic: this.actionSettings.attributesMap.isDynamic,
          value: attributesMap,
        },
        [CreateArtifactsKeys.parentFolderId]: {
          isDynamic: this.actionSettings.parentFolderId.isDynamic,
          value: this.actionSettings.parentFolderId.value || null,
        },
        [CreateArtifactsKeys.storeResultsPath]: {
          isDynamic: false,
          value: this.actionSettings.storeResultsPath.value || null,
        },
        [CreateArtifactsKeys.forEachPath]: {
          isDynamic: false,
          value: this.actionSettings.forEachPath.value || null,
        },
        [CreateArtifactsKeys.filePath]: {
          isDynamic: false,
          value: this.actionSettings.filePath.value || null,
        },
      },
    };
  }
}
