import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTypeValueResponseDto } from '@api/models';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { DATEnumEditVariant, DATEnumMultipleEditVariant } from '@shared/types/display-at-types';
import { SharedMethods } from '@shared/methods/shared.methods';
import { EMPTY_OPTION_FILTER_URL_VALUE } from '@shared/constants/constants';
import {
  ChipFormatStyles,
  StylesDtoKeys
} from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';
import { FilterRelevantStylesPipe } from '@shared/pipes/styles-pipes/filter-relevant-styles.pipe';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, MultiSelectModule, DropdownModule, CheckboxModule, RadioButtonModule, ArtifactAttributeFormFieldLabelWithIconComponent, FilterRelevantStylesPipe],
  selector: 'app-artifact-attribute-form-field-enumerated',
  templateUrl: './artifact-attribute-form-field-enumerated.component.html',
  styleUrls: ['./artifact-attribute-form-field-enumerated.component.scss'],
})
export class ArtifactAttributeFormFieldEnumeratedComponent {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;
  @Input() options: DataTypeValueResponseDto[];
  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() columnStylesEvent: RuntimeStateNotification<any> | undefined;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<void>();
  @Output() fieldBlur: EventEmitter<void> = new EventEmitter<void>();

  labelBehaviourEnum = LabelBehaviourEnum;
  uuid = SharedMethods.getUniqueId();
  emptyLabel = EMPTY_OPTION_FILTER_URL_VALUE;
  relevantStyleKeys: StylesDtoKeys[] = [];

  ngOnInit(): void {
    this.isRadiobutton && (this.attr.value = this.options.find(option => option.value === this.attr.value.value));
    this.setCssKeys();
  }

  private setCssKeys() {
    this.relevantStyleKeys = [...ChipFormatStyles];
  }

  get directionClass(): string {
    return DATEnumEditVariant[this.code as keyof typeof DATEnumEditVariant] === DATEnumEditVariant.RADIO_BUTTON_HORIZONTAL ||
          DATEnumMultipleEditVariant[this.code as keyof typeof DATEnumMultipleEditVariant] === DATEnumMultipleEditVariant.CHECKBOX_GROUP_HORIZONTAL
          ? 'flex-row': 'flex-column';
  }

  get alignStyle(): any {
    const value = this.formatSettings.value.displayMetadata?.horizontalAlign;
    return value ? { 'justify-content': value }: null;
  }

  get isCheckbox(): boolean {
    const value = DATEnumMultipleEditVariant[this.code as keyof typeof DATEnumMultipleEditVariant];
    return value === DATEnumMultipleEditVariant.CHECKBOX_GROUP || value === DATEnumMultipleEditVariant.CHECKBOX_GROUP_HORIZONTAL;
  }

  get isRadiobutton(): boolean {
    const value = DATEnumEditVariant[this.code as keyof typeof DATEnumEditVariant];
    return value === DATEnumEditVariant.RADIO_BUTTON || value === DATEnumEditVariant.RADIO_BUTTON_HORIZONTAL;
  }

  get code(): string {
    return this.formatSettings.value.displayMetadata?.editVariantCode || '';
  }
}
