<div class="flex icon-position-auth" [ngStyle]="styles">
    <div *ngIf="s.isShowUserProfilePicture" class="user-picture_container" (click)="clickHandler($event)"  [ngStyle]="m.settings.imageStyle">
        <div class="user-picture cursor-pointer" [ngStyle]="{ backgroundImage: 'url('+m.userProfilePictureUrl+')' }"></div>
    </div>

    <div *ngIf="s.isShowLetters"
        (click)="clickHandler($event)"
        [ngStyle]="m.settings.letterStyle"
        class="icon-as-initials cursor-pointer"
        pTooltip="{{ m.userName }}"
    >
        {{ m.userName | toUserInitials : m.currentUser?.email }}
    </div>

    <span *ngIf="s.isShowUserIcon"
        (click)="clickHandler($event)"
        [ngClass]="m.settings.userIcon"
        [ngStyle]="m.settings.userIconStyle"
        [pTooltip]="'Log out' | translate"
        class="cursor-pointer"
    ></span>

    <div *ngIf="s.isShowFallbackIcon" class="user-picture_container" [ngStyle]="m.settings.imageStyle">
        <div class="user-picture cursor-pointer" (click)="clickHandler($event)" [ngStyle]="{ backgroundImage: 'url(/assets/images/default-avatar.jpg)' }"></div>
    </div>
</div>
