import { WorkflowActionDto } from '@api/models';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { SaveableItem } from '@shared/interfaces/saveable-item';
import { OnRuleActivation, OnRuleDeactivation } from './rule-common.types';
import { WorkflowTriggerEvent } from './rule-trigger.types';
import { FormatAttributeAction } from './workflow-action-event.types';

export type WorkflowActionValueType = string | number | null;

export enum WorkflowActionType {
  // FE actions
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  SET_MANDATORY = 'SET_MANDATORY',
  SET_OPTIONAL = 'SET_OPTIONAL',
  AGGREGATE = 'AGGREGATE',
  SUBTRACT = 'SUBTRACT',
  MULTIPLY = 'MULTIPLY',
  DIVIDE = 'DIVIDE',
  CALCULATE = 'CALCULATE',
  FORMULA = 'FORMULA',
  SET_VALUE = 'SET_VALUE',
  COPY_VALUE = 'COPY_VALUE',
  SET_VALUE_FROM_LINK = 'SET_VALUE_FROM_LINK',
  USE_CUSTOM_ATTRIBUTE_VALUES = 'USE_CUSTOM_ATTRIBUTE_VALUES',
  RESET_ATTRIBUTE_VALUES = 'RESET_ATTRIBUTE_VALUES',
  COPY_LINK_FROM_LINK = 'COPY_LINK_FROM_LINK',
  CREATE_LINK = 'CREATE_LINK',
  FORMAT = 'FORMAT',

  // BE actions
  SEND_EMAIL = 'SEND_EMAIL',
  SCHEDULE_WORKFLOW = 'SCHEDULE_WORKFLOW',
  EXEC_CUSTOM_JAVASCRIPT = 'EXEC_CUSTOM_JAVASCRIPT',
  GET_ARTIFACTS = 'GET_ARTIFACTS',
  CREATE_ARTIFACTS = 'CREATE_ARTIFACTS',
  UPDATE_ARTIFACTS = 'UPDATE_ARTIFACTS',
  DELETE_ARTIFACTS = 'DELETE_ARTIFACTS',
  FETCH_DATA = 'FETCH_DATA',
  FETCH_DATA_IMAP = 'FETCH_DATA_IMAP',
  GET_LINKS = 'GET_LINKS',
  CREATE_LINKS = 'CREATE_LINKS',
  DELETE_LINKS = 'DELETE_LINKS',
  MANAGE_USER_APPLICATIONS = 'MANAGE_USER_APPLICATIONS',
  THROW_ERROR = 'THROW_ERROR',
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const workflowActionsTypeCheck: [WorkflowActionDto['type']] extends [`${WorkflowActionType}`]
  ? `${WorkflowActionType}` extends WorkflowActionDto['type']
    ? true
    : false
  : false = true;

// export interface WorkflowActionDto {
//   id: string;
//   type: WorkflowActionType;
//   alias?: string;
//   artifactTypeId?: string;
//   attributeId?: string;
// }

export interface WorkflowAction extends WorkflowActionDto, SaveableItem<WorkflowActionDto>, OnRuleActivation, OnRuleDeactivation {
  canBeExecuted(triggerEvent?: WorkflowTriggerEvent): boolean;

  execute(triggerEvent?: WorkflowTriggerEvent): void;

  isArtifactTypeRequired(): boolean;

  isAttributeRequired(): boolean;

  /** output of action which is calculated upon any string / number operations */
  isCalculatedValueType(): boolean;

  /** calculated value output for string / number operations */
  getCalculatedValue(): WorkflowActionValueType;

  isValid(): boolean;

  /** returns supported attribute data types or null if it doesn't matter */
  getSupportedDataTypes(): BaseDataType[] | null;

  getSupportedDataKinds(): DataTypeKind[] | null;
}

export enum WorkflowAttributeActionEventType {
  SHOW_HIDE = 'SHOW_HIDE',
  ATTRIBUTE_VALUE = 'ATTRIBUTE_VALUE',
  ATTRIBUTE_OPTION_VALUES = 'ATTRIBUTE_OPTION_VALUES',
}

export type RuleAttributeAction = {
  actionType: WorkflowActionType.SHOW | WorkflowActionType.HIDE | WorkflowActionType.SET_MANDATORY | WorkflowActionType.SET_OPTIONAL;
  payload: {
    pageId: string;
    artifactTypeId: string;
    attributeId: string;
  };
};

export type RuleFormatAttributeAction = {
  actionType: WorkflowActionType.FORMAT;
  payload: FormatAttributeAction;
};

// export type RuleNotificationAction = {
//   actionType: WorkflowActionType.NOTIFY;
//   payload: {
//     pageId: string;
//     widgetId?: string;
//   };
// };

export type RuleLinkAttributeAction = {
  actionType: WorkflowActionType.SET_VALUE_FROM_LINK;
  payload: {
    pageId: string;
    artifactTypeId: string;
    attributeId: string;
    linkAttributeId: string;
  };
};

export type BaseRuleAction = {
  actionType: WorkflowActionType;
};

export type RuleAction = RuleAttributeAction | BaseRuleAction | RuleLinkAttributeAction | /* RuleNotificationAction | */ RuleFormatAttributeAction;

export const ImplementedWorkflowActionTypes: Record<WorkflowActionType, boolean> = {
  // FE actions
  [WorkflowActionType.SHOW]: true,
  [WorkflowActionType.HIDE]: true,
  [WorkflowActionType.SET_MANDATORY]: true,
  [WorkflowActionType.SET_OPTIONAL]: true,
  [WorkflowActionType.AGGREGATE]: true,
  [WorkflowActionType.SUBTRACT]: true,
  [WorkflowActionType.MULTIPLY]: true,
  [WorkflowActionType.DIVIDE]: true,
  [WorkflowActionType.CALCULATE]: true,
  [WorkflowActionType.FORMULA]: true,
  [WorkflowActionType.SET_VALUE]: true,
  [WorkflowActionType.COPY_VALUE]: true,
  [WorkflowActionType.SET_VALUE_FROM_LINK]: true,
  [WorkflowActionType.USE_CUSTOM_ATTRIBUTE_VALUES]: true,
  [WorkflowActionType.RESET_ATTRIBUTE_VALUES]: true,
  [WorkflowActionType.COPY_LINK_FROM_LINK]: true,
  [WorkflowActionType.CREATE_LINK]: false,
  [WorkflowActionType.FORMAT]: false,

  // BE actions
  [WorkflowActionType.SEND_EMAIL]: true,
  [WorkflowActionType.SCHEDULE_WORKFLOW]: true,
  [WorkflowActionType.EXEC_CUSTOM_JAVASCRIPT]: true,
  [WorkflowActionType.GET_ARTIFACTS]: true,
  [WorkflowActionType.CREATE_ARTIFACTS]: true,
  [WorkflowActionType.UPDATE_ARTIFACTS]: true,
  [WorkflowActionType.DELETE_ARTIFACTS]: true,
  [WorkflowActionType.FETCH_DATA]: true,
  [WorkflowActionType.FETCH_DATA_IMAP]: true,
  [WorkflowActionType.GET_LINKS]: true,
  [WorkflowActionType.CREATE_LINKS]: true,
  [WorkflowActionType.DELETE_LINKS]: true,
  [WorkflowActionType.MANAGE_USER_APPLICATIONS]: true,
  [WorkflowActionType.THROW_ERROR]: true,
};
