import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GetDataTypeFromClientAttributePipe } from '@shared/pipes/artifact-pipes/get-data-type-from-client-attribute.pipe';
import { GetValueFromPathPipe } from '@shared/pipes/common/get-value-from-path-pipe';
import { SafeHtmlPipe, SafePipe } from '@shared/pipes/common/safe-html.pipe';
import { GetApplicationNamePipe } from '@shared/pipes/user-pipes/get-application-name.pipe';
import { CanHaveInitialValuePipe } from '@widgets/artifact-widget/pipes/can-have-initial-value.pipe';
import { ShouldShowPlaceholderOptionPipe } from '@widgets/artifact-widget/pipes/should-show-placeholder-option.pipe';
import { IsInStringArrayPipe } from './is-in-string-array.pipe';
import { IsLinkDirectionIncomingPipe } from './is-link-direction-incoming.pipe';
import { IsLinkDirectionOutgoingPipe } from './is-link-direction-outgoing.pipe';
import { MapToArrayPipe } from './map-to-array.pipe';
import { ContainerClassListPipe } from './container-class-list.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ToHtmlPipe } from './to-html.pipe';
import { ToTimePipe } from './to-time.pipe';
import { ToUserInitialsPipe } from './to-user-initials.pipe';
import { ToUserPipe } from './to-user.pipe';
import { TransformArrayToSelectOptionsPipe } from './transform-array-to-select-options.pipe';
import { UseDecimalPlacesPipe } from './use-decimal-places.pipe';
import { ReturnsToHtmlBreaksPipe } from './returns-to-html-breaks.pipe'

const pipes = [
  IsInStringArrayPipe,
  ToDatePipe,
  TransformArrayToSelectOptionsPipe,
  ToTimePipe,
  IsLinkDirectionOutgoingPipe,
  IsLinkDirectionIncomingPipe,
  GetValueFromPathPipe,
  GetApplicationNamePipe,
  SafeHtmlPipe,
  SafePipe,
  ToHtmlPipe,
  ToUserPipe,
  ToUserInitialsPipe,
  GetDataTypeFromClientAttributePipe,
  ShouldShowPlaceholderOptionPipe,
  CanHaveInitialValuePipe,
  MapToArrayPipe,
  ContainerClassListPipe,
  UseDecimalPlacesPipe,
  ReturnsToHtmlBreaksPipe,
];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class CommonPipesModule {}
