export type FilterValueDataType = string | boolean | number | Date;

export class ImapFilter {
  key: ImapFilterKeyEnum = ImapFilterKeyEnum.seq;
  value: FilterValueDataType | null = null;
  dataType: ImapFilterDataType = ImapFilterDataType.string;

  constructor(filter?: Partial<ImapFilter>) {
    if (filter) {
      Object.assign(this, filter);
    }
  }
}

export enum ImapFilterKeyEnum {
  seq = 'seq',
  answered = 'answered',
  deleted = 'deleted',
  draft = 'draft',
  flagged = 'flagged',
  seen = 'seen',
  all = 'all',
  new = 'new',
  old = 'old',
  recent = 'recent',
  from = 'from',
  to = 'to',
  cc = 'cc',
  bcc = 'bcc',
  body = 'body',
  subject = 'subject',
  larger = 'larger',
  smaller = 'smaller',
  uid = 'uid',
  modseq = 'modseq',
  emailId = 'emailId',
  threadId = 'threadId',
  before = 'before',
  on = 'on',
  since = 'since',
  sentBefore = 'sentBefore',
  sentOn = 'sentOn',
  sentSince = 'sentSince',
  keyword = 'keyword',
  unKeyword = 'unKeyword',
}

export enum ImapFilterDataType {
  string = 'string',
  boolean = 'boolean',
  date = 'date',
  number = 'number',
}

export const ImapFilterKeyWithDataType: Record<ImapFilterKeyEnum, ImapFilterDataType> = {
  seq: ImapFilterDataType.string,
  answered: ImapFilterDataType.boolean,
  deleted: ImapFilterDataType.boolean,
  draft: ImapFilterDataType.boolean,
  flagged: ImapFilterDataType.boolean,
  seen: ImapFilterDataType.boolean,
  all: ImapFilterDataType.boolean,
  new: ImapFilterDataType.boolean,
  old: ImapFilterDataType.boolean,
  recent: ImapFilterDataType.boolean,
  from: ImapFilterDataType.string,
  to: ImapFilterDataType.string,
  cc: ImapFilterDataType.string,
  bcc: ImapFilterDataType.string,
  body: ImapFilterDataType.string,
  subject: ImapFilterDataType.string,
  larger: ImapFilterDataType.number,
  smaller: ImapFilterDataType.number,
  uid: ImapFilterDataType.string,
  modseq: ImapFilterDataType.number,
  emailId: ImapFilterDataType.string,
  threadId: ImapFilterDataType.string,
  before: ImapFilterDataType.date,
  on: ImapFilterDataType.date,
  since: ImapFilterDataType.date,
  sentBefore: ImapFilterDataType.date,
  sentOn: ImapFilterDataType.date,
  sentSince: ImapFilterDataType.date,
  keyword: ImapFilterDataType.string,
  unKeyword: ImapFilterDataType.string,
};
