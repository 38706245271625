import { Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListQueryDto } from '@api/models';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TranslateService } from '@ngx-translate/core';
import { ArtifactListService } from '@private/pages/artifact-management/artifact-list/services/artifact-list.service';
import { ArtifactListModel } from '@private/pages/artifact-management/artifact-list/types/artifact-list.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { saveAs } from 'file-saver';
import { FileUpload } from 'primeng/fileupload';
import { lastValueFrom } from 'rxjs';
import { ArtifactListTableComponent } from './components/artifact-list-table/artifact-list-table.component';

@Component({
  selector: 'app-artifact-list',
  templateUrl: './artifact-list.component.html',
  styleUrls: ['./artifact-list.component.scss'],
  providers: [ArtifactListService],
})
export class ArtifactListComponent extends CoreComponent<ArtifactListService, ArtifactListModel> implements OnDestroy {
  @ViewChild('artifactList') artifactList: ArtifactListTableComponent;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    announcement: AnnouncementService,
    public readonly service: ArtifactListService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
  ) {
    super(route, router, translate, new ArtifactListModel(), service, announcement);
  }

  onInit(): void {
    this.breadcrumbService.setItems([{ label: 'Artifacts' }]);
  }

  async export(): Promise<void> {
    try {
      if (!this.m.artifactType) {
        await this.announcement.error('First you need to choose the Artifact Type!');
        return;
      }

      this.tenantArtifactService
        .artifactControllerExport$Response({ filter: JSON.stringify({ $and: [{ artifactTypeId: { $in: [{ $oid: this.m.artifactType.id }] } }] }) })
        .subscribe(response => {
          const header = response.headers.get('content-disposition') || '';
          saveAs(response.body, !header ? 'export.xlsx' : header.slice(header.search('filename="') + 10, -1));
        });
    } catch (e: any) {
      await this.announcement.error('Something went wrong when importing data.' + e?.error?.message);
      console.error(e);
    }
  }

  showImportDialog(): void {
    this.m.showImportDialog = true;
  }

  onImportDialogHide(importField: FileUpload): void {
    importField.clear();
    this.m.triggerWorkflowsOnImport = false;
    this.m.showImportDialog = false;
  }

  async import({ files }: { files: Array<File> }, importField: FileUpload): Promise<void> {
    try {
      if (!files || !files.length) {
        await this.announcement.error('You need to choose file first!');
        return;
      }

      await lastValueFrom(
        this.tenantArtifactService.artifactControllerImport({
          body: {
            file: files[0],
            applicationId: this.applicationSwitcherService.selectedApplication?.id || '',
            triggerWorkflow: this.m.triggerWorkflowsOnImport,
          },
        }),
      );

      await this.announcement.success('Job for importing artifacts was created');
      importField.clear();
      this.m.triggerWorkflowsOnImport = false;
      this.m.showImportDialog = false;
    } catch (e: any) {
      await this.announcement.error('Something went wrong when importing data.' + e?.error?.message);
      console.error(e);
    }
  }

  loadDataMethod(): any {
    return (meta?: Record<string, string>) => lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: meta as ListQueryDto }));
  }

  async onArtifactTypeChange(event: any): Promise<void> {
    this.m.showTable = false;
    setTimeout(async () => {
      this.m.artifactType = event.value;
      await this.s.setArtifactTypeToUrl();
      await this.service.prepareAndAutoSelectAttributeOptions();
      this.m.showTable = true;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.s.deleteSubscriptions();
  }
}
