import {
  GoogleSessionFlowDataDto,
  InternalSessionFlowDataDto,
  MicrosoftSessionFlowDataDto,
  OpenIdSessionFlowDataDto,
  TenantAdminSessionFlowResponseDto,
} from '@api/models';
import { SessionFlowScopes, SessionType } from '@shared/types/session-flow.types';

abstract class BaseSessionFlowTypeData {
  scopes: SessionFlowScopes[] = [];
}

export class GoogleSessionFlowTypeData extends BaseSessionFlowTypeData implements GoogleSessionFlowDataDto {
  clientId: string = '';
  clientSecret: string = '';

  constructor(sessionFlowTypeData?: Partial<GoogleSessionFlowTypeData>) {
    super();
    sessionFlowTypeData && Object.assign(this, sessionFlowTypeData);
  }
}

export class InternalSessionFlowTypeData extends BaseSessionFlowTypeData implements InternalSessionFlowDataDto {
  constructor(sessionFlowTypeData?: Partial<InternalSessionFlowTypeData>) {
    super();
    sessionFlowTypeData && Object.assign(this, sessionFlowTypeData);
  }
}

export class MicrosoftSessionFlowTypeData extends BaseSessionFlowTypeData implements MicrosoftSessionFlowDataDto {
  clientId: string = '';
  clientSecret: string = '';
  tenant: string = 'common';

  constructor(sessionFlowTypeData?: Partial<MicrosoftSessionFlowTypeData>) {
    super();
    sessionFlowTypeData && Object.assign(this, sessionFlowTypeData);
  }
}

export class OpenIdSessionFlowTypeData extends BaseSessionFlowTypeData implements OpenIdSessionFlowDataDto {
  clientId: string = '';
  issuer: string = '';
  authorizeUrl: string = '';
  tokenUrl: string = '';
  revokeUrl: string = '';

  constructor(sessionFlowTypeData?: Partial<OpenIdSessionFlowTypeData>) {
    super();
    sessionFlowTypeData && Object.assign(this, sessionFlowTypeData);
  }
}

export class IndexSessionFlowTypeData {
  static google = GoogleSessionFlowTypeData;
  static internal = InternalSessionFlowTypeData;
  static microsoft = MicrosoftSessionFlowTypeData;
  static openid = OpenIdSessionFlowTypeData;
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sessionTypeCheck: InstanceType<(typeof IndexSessionFlowTypeData)[SessionType]> extends TenantAdminSessionFlowResponseDto['typeData'] ? true : false =
  true;
