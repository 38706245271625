import { WidgetModel } from '../../shared/types/widget-model.interface';
import { FilterWidgetSettings, FilterWidgetSettingsDto } from './filter-widget-settings.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { FilterWidgetOptions } from './filter-widget-options.types';

export class FilterWidgetValue {
  constructor(public model: FilterWidgetModel = new FilterWidgetModel()) {}
}

export class FilterWidgetModel implements WidgetModel<FilterWidgetModelDto> {
  settings = new FilterWidgetSettings();
  options = new FilterWidgetOptions();
  dataType: NewDataType | null;
  activeIndexes: number[] = [];
  filter: ArtifactFilter;
  isLoad: boolean;
  isRefresh: boolean;

  constructor(dto?: FilterWidgetModelDto) {
    dto && this.fromDto(dto);
  }

  toServer(): FilterWidgetModelDto {
    return {
      settings: this.settings.toServer(),
    };
  }

  fromDto(dto: FilterWidgetModelDto): void {
    if (dto) {
      dto.settings && (this.settings = new FilterWidgetSettings(dto.settings));
    }
  }
}

export interface FilterWidgetModelDto {
  settings: FilterWidgetSettingsDto;
}

export interface FilterWidgetSelectTag {
  index: number;
  isCtrl?: boolean;
  currentIndexes?: number[];
}
