import { isEqual } from 'lodash';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { NewCachedSubjectGeneralArguments, NewCachedSubjectLocalStorageData, NewCachedSubjectLocalStorageMeta } from '../types/new-cached-subject.types';

export class NewCachedSubject<T> extends BehaviorSubject<Array<T> | T | null> {
  loaded = false;
  protected readonly updateFn: () => Observable<T>;
  protected readonly localStorageMeta: NewCachedSubjectLocalStorageMeta | null = null;
  protected readonly noUpdateTime: number = 3000;
  protected lastUpdateTime = 0;
  protected isUpdating = false;

  private _isFirstInit = true;
  private readonly _callUpdateOnInit: boolean;
  private readonly _onUpdate: (entity: T | Array<T> | null) => void;

  constructor(args: NewCachedSubjectGeneralArguments<T>) {
    const { updateFn, localStorageMeta, noUpdateTimeMs, onUpdate, callUpdateOnInit, initialValue } = args;

    super(initialValue || null);

    this._callUpdateOnInit = Boolean(callUpdateOnInit);
    this.updateFn = updateFn;
    noUpdateTimeMs && (this.noUpdateTime = noUpdateTimeMs);
    localStorageMeta && (this.localStorageMeta = localStorageMeta);
    onUpdate && (this._onUpdate = onUpdate);

    this.deserialize();
    this.subscribe({
      next: () => this.serialize(),
    });

    if (this._callUpdateOnInit) this.update();
    else {
      this._onUpdate?.(this.value);
      // this.loaded = true;
    }
  }

  getValue(): T | T[] | null {
    return structuredClone(super.getValue());
  }

  update(): void {
    if (this.isUpdating) return;

    this.isUpdating = true;
    this.updateFn?.().subscribe({
      next: value => {
        this.lastUpdateTime = Date.now();

        if (!isEqual(this.value, value)) {
          this.next(structuredClone(value));
          this._dataUpdateCb(value);
          this._onUpdate?.(value);
        }
      },
      error: reason => {
        if (this.value == null) this.error(reason);
      },
      complete: () => {
        this.isUpdating = false;
        this.loaded = true;

        // if (this._isFirstInit && this._callUpdateOnInit) this._isFirstInit = false;
        // else this.loaded = true;
      },
    });
  }

  // !!!!! This is an internal implementation detail, please don't touch it !!!!!
  protected _subscribe(subscriber: Subscriber<T>): Subscription {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const subscription = super._subscribe(subscriber);
    const now = Date.now();

    if (this.loaded) {
      if (this.lastUpdateTime + this.noUpdateTime < now) setTimeout(() => this.update());
    } else this.loaded = true;

    // {
    //   if (this._isFirstInit && this._callUpdateOnInit) this._isFirstInit = false;
    //   else this.loaded = true;
    // }

    return subscription;
  }

  protected serialize(): void {
    if (this.localStorageMeta == null || this.localStorageMeta?.skipSync?.(this.value)) return;

    const { stateKey, key, localStorageService, storeOnlyValue } = this.localStorageMeta;
    const data: NewCachedSubjectLocalStorageData<Array<T> | T> | Array<T> | T | null = storeOnlyValue
      ? this.value
      : { lastUpdateTime: this.lastUpdateTime, lastValue: this.value };

    if (stateKey) localStorageService.setToState(stateKey, key, data);
    else localStorageService.set(key, data);
  }

  protected deserialize(): void {
    if (this.localStorageMeta == null || this.localStorageMeta?.skipSync?.(this.value)) return;

    const { stateKey, key, localStorageService, storeOnlyValue } = this.localStorageMeta;
    const item: NewCachedSubjectLocalStorageData<T> | T | null = stateKey ? localStorageService.getFromState(stateKey, key) : localStorageService.get(key);
    if (item == null) return;

    try {
      if (!storeOnlyValue) {
        const { lastValue, lastUpdateTime } = item as NewCachedSubjectLocalStorageData<T>;
        this.next(lastValue);
        this._dataUpdateCb(lastValue as T);
        this.lastUpdateTime = lastUpdateTime;
      } else {
        this.next(item as T);
        this._dataUpdateCb(item as T);
        this.lastUpdateTime = 0;
      }
      this._onUpdate?.(this.value);
    } catch (error) {
      console.error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  protected _dataUpdateCb(value: T): void {}
}
