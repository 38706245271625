<div
  *ngIf="(linkedData | isArtifactMapNotEmpty) && (linkRestrictionParams | getRelevantLinks : artifact?.id : links) as linksData; else emptyLink"
  [ngSwitch]="selectedVariant"
  class="full-size-container"
>
  <ng-container *ngSwitchCase="'DEFAULT'">
    <div class="table-cell-content-link" [ngStyle]="contentColumnFormat?.styles">
      <ul class="link-cell">
        <li *ngFor="let link of linksData" class="link">
          <ng-container [ngTemplateOutlet]="singleLink" [ngTemplateOutletContext]="{ link: link }"></ng-container>
        </li>
      </ul>
      <ng-container *ngIf="showLinkButtons" [ngTemplateOutlet]="addLinkButton"></ng-container>
    </div>
  </ng-container>

  <div *ngSwitchCase="'SUMMARY_ICON'" class="icon-variant-container">
    <p-badge *ngIf="linksData?.length" [value]="linksData.length" severity="info" styleClass="ml-2 mr-2"></p-badge>
  </div>

  <ng-container *ngSwitchCase="'PART_WITH_LOAD_MORE'">
    <div class="table-cell-content-link">
      <ul class="link-cell">
        <li *ngFor="let link of linksData | slice : 0 : itemsPerPage * currentPage + itemsPerPage" class="link">
          <ng-container [ngTemplateOutlet]="singleLink" [ngTemplateOutletContext]="{ link: link }"></ng-container>
        </li>
        <ng-container [ngTemplateOutlet]="loadMore" [ngTemplateOutletContext]="{ linksData: linksData }"></ng-container>
      </ul>
      <ng-container *ngIf="showLinkButtons" [ngTemplateOutlet]="addLinkButton"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'PART_WITH_PAGINATION'">
    <div class="table-cell-content-link">
      <ul class="link-cell">
        <li
          *ngFor="let link of linksData | slice : itemsPerPage * currentPage : itemsPerPage * currentPage + itemsPerPage"
          class="link">
          <ng-container [ngTemplateOutlet]="singleLink" [ngTemplateOutletContext]="{ link: link }"></ng-container>
        </li>
        <p-paginator
          [rows]="5"
          [totalRecords]="linksData.length"
          [showJumpToPageDropdown]="true"
          dropdownAppendTo="body"
          styleClass="paginator-transparent paginator-nowrap paginator-with-narrow-dropdown"
          [showPageLinks]="false"
          [alwaysShow]="false"
          (onPageChange)="paginate($event)"
        ></p-paginator>
      </ul>
      <ng-container *ngIf="showLinkButtons" [ngTemplateOutlet]="addLinkButton"></ng-container>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'CUSTOM'">
    <div *ngIf="linksData?.length && (customVariantTemplate$ | async) as widget" class="link-card-widget-container">
      <div
        *ngFor="
          let artifactLink of linksData
            | getLinkedArtifactsByLinksData : linkedData : linkRestrictionParams.direction
            | slice : 0 : itemsPerPage * currentPage + itemsPerPage
        "
        class="link link-card-widget-item cursor-pointer"
        (click)="handleLinkedArtifactClick(artifactLink.artifact, $event)"
      >
        <app-widget-container
          *ngIf="widget.code === widgetType.card && filesLoaded"
          [artifact]="artifactLink.artifact"
          [isClickable]="false"
          [isLayoutMode]="true"
          [isListMatrixCard]="true"
          [isPreviewMode]="isPreviewMode"
          [disabled]="isPreviewMode"
          [widget]="widget"
          [artifactAdditionalData]="{files: files | mapToArray, links: linksDto, linkedArtifacts: linkedData | mapToArray}"
          appWidgetContainerStopPropagation
        >
        </app-widget-container>
        <button *ngIf="showRemoveButton" pButton pRipple class="{{ deleteLinkStyleClass }} card-link-delete" icon="pi pi-times"
                (click)="deleteLink(artifactLink.link, $event)"></button>
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="loadMore" [ngTemplateOutletContext]="{ linksData: linksData }"></ng-container>
    <ng-container [ngTemplateOutlet]="addLinkButton"></ng-container>
  </ng-container>
</div>

<!-- templates -->
<ng-template #singleLink let-link="link">
  <span class="link-label">
    <a class="{{ linkStyleClass }} cursor-pointer" (click)="handleLinkClick(link, $event)">
      {{ link | linkPrimaryAttributesToString : linkRestrictionParams.direction : linkedData : artifactTypes.listMap }}
    </a>
  </span>
  <button *ngIf="showLinkButtons && showRemoveButton" type="button" pButton pRipple class="{{ deleteLinkStyleClass }}" icon="pi pi-times"
          (click)="deleteLink(link, $event)"></button>
</ng-template>

<ng-template #loadMore let-linksData="linksData">
  <div *ngIf="linksData?.length > itemsPerPage * currentPage + itemsPerPage" class="{{ showMoreStyleClass }}"
       (click)="showMoreItems($event)">
    <i class="pi pi-arrow-down"></i>
    {{ 'Show more' | translate }}
  </div>
  <div *ngIf="currentPage && linksData?.length <= itemsPerPage * currentPage + itemsPerPage"
       class="{{ hideMoreStyleClass }}" (click)="hideMoreItems($event)">
    <i class="pi pi-arrow-up"></i>
    {{ 'Hide more' | translate }}
  </div>
</ng-template>

<ng-template #addLinkButton>
  <button *ngIf="showAddLinkButton" type="button" pButton pRipple class="{{ addLinkStyleClass }}" icon="pi pi-plus"
          (click)="addLinkClicked()"></button>
</ng-template>

<ng-template #emptyLink>
  &nbsp;
  <ng-container *ngIf="showLinkButtons" [ngTemplateOutlet]="addLinkButton"></ng-container>
</ng-template>
