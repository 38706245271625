export class CustomEventSettings {
	enable: boolean = false;
	types: string[] = [''];
	payload: any;

	constructor(dto?: CustomEventSettingsDto) {
		dto && Object.assign(this, dto);
	}
}

export class CustomEventSettingsDto {
	type: string;
	payload: any;
}
