<div class="mb-3">
  <button pButton pRipple type="button" label="{{ 'Save' | translate }}"
          class="d-block ml-auto p-button p-button-success" (click)="saveTenant()"></button>
</div>

<div class="mb-3">
  <app-tenant-settings [tenant]="m.tenant" [publicPageOptions]="m.publicPageOptions"></app-tenant-settings>
</div>

<div>
  <app-tenant-oslc-root-services [tenant]="m.tenant"></app-tenant-oslc-root-services>
</div>
