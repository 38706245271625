<ng-container *ngIf="initialized && m">
  <h2 *ngIf="label && !isLayoutMode">{{ label }}</h2>
  <editor
    *ngIf="!disabled"
    [(ngModel)]="m.text"
    [init]="settings"
    (onFocus)="onfocus($event)"
    [apiKey]="editorKey"
    outputFormat="html"
  >
  </editor>

  <ng-container *ngIf="disabled">
    <div class="mce-content-body mce-content-readonly" [innerHTML]="m.text | toHtml | replaceRuntimeVariables | async | safeHtml"></div>
  </ng-container>
</ng-container>
