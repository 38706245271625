<ng-container *ngIf="fullAttribute.multipleValues && !(fullDataType.baseDataType | isUser) && fullAttribute">
  <span class="dynamic-label"
        *ngIf="(fullDataType.baseDataType | isInteger) || (fullDataType.baseDataType | isDecimal)">
    <p-chips
      [inputId]="'simpleField' + index"
      [(ngModel)]="artifactAttribute.value"
      (ngModelChange)="checkNumFormatMultiple()"
      [ngClass]="{ 'p-chips-filled': artifactAttribute.value }"
    >
    </p-chips>
    <label class="dynamic-label-text" [for]="'simpleField' + index">{{ fullAttribute.name }}</label>
  </span>

  <span class="dynamic-label" *ngIf="fullDataType.baseDataType | isText">
    <p-chips [inputId]="'simpleField' + index" [(ngModel)]="artifactAttribute.value"
             [ngClass]="{ 'p-chips-filled': artifactAttribute.value }"></p-chips>
    <label class="dynamic-label-text" [for]="'simpleField' + index">{{ fullAttribute.name }}</label>
  </span>

  <span class="dynamic-label" *ngIf="fullDataType.baseDataType | isDateOrTime">
    <p-calendar
      [(ngModel)]="artifactAttribute.value"
      [firstDayOfWeek]="firstDayOfWeek"
      [selectionMode]="'multiple'"
      [readonlyInput]="true"
      [dateFormat]="dateFormat"
      [showTime]="fullDataType.baseDataType | isDateTime"
      [inputId]="'simpleField' + index"
      [timeOnly]="fullDataType.baseDataType | isTime"
    >
    </p-calendar>
    <label class="dynamic-label-text" [for]="'simpleField' + index">{{ fullAttribute.name }}</label>
  </span>
</ng-container>

<ng-container *ngIf="!fullAttribute.multipleValues && !(fullDataType.baseDataType | isUser)">
  <span class="dynamic-label"
        *ngIf="(fullDataType.baseDataType | isInteger) || (fullDataType.baseDataType | isDecimal)">
    <input
      type="text"
      [id]="'simpleField' + index"
      pInputText
      [(ngModel)]="artifactAttribute.value"
      (ngModelChange)="checkNumFormat($event, 'simpleField' + index, artifactAttribute, 'initialValue')"
    />
    <label class="dynamic-label-text" [for]="'simpleField' + index">{{ fullAttribute.name }}</label>
  </span>

  <span class="dynamic-label" *ngIf="fullDataType.baseDataType | isText">
    <input type="text" [id]="'simpleField' + index" pInputText [(ngModel)]="artifactAttribute.value" />
    <label class="dynamic-label-text" [for]="'simpleField' + index">{{ fullAttribute.name }}</label>
  </span>

  <span class="dynamic-label" *ngIf="fullDataType.baseDataType | isHtml">
    <editor
      class="editor-enabled"
      [(ngModel)]="artifactAttribute.value"
      [init]="settings"
      [disabled]="disabledField"
      [apiKey]="editorKey"
      outputFormat="html"
    >
    </editor>
  </span>

  <span class="dynamic-label" *ngIf="fullDataType.baseDataType | isDateOrTime">
    <p-calendar
      [(ngModel)]="artifactAttribute.value"
      [firstDayOfWeek]="firstDayOfWeek"
      [selectionMode]="'single'"
      [readonlyInput]="true"
      [dateFormat]="dateFormat"
      [showTime]="fullDataType.baseDataType | isDateTime"
      [inputId]="'simpleField' + index"
      [timeOnly]="fullDataType.baseDataType | isTime"
    >
    </p-calendar>
    <label class="dynamic-label-text" [for]="'simpleField' + index">{{ fullAttribute.name }}</label>
  </span>

  <span class="dynamic-label dynamic-checkbox" *ngIf="fullDataType.baseDataType | isBoolean">
    <span class="dynamic-checkbox-box">
      <p-checkbox [(ngModel)]="artifactAttribute.value" binary="true" [inputId]="'booleanField' + index"></p-checkbox>
    </span>
    <label class="dynamic-label-text">{{ fullAttribute.name }}</label>
  </span>
</ng-container>

<ng-container *ngIf="fullDataType.baseDataType | isUser">
  <span class="dynamic-label">
    <app-user-autocomplete [attribute]="fullAttribute" [(value)]="artifactAttribute.value"
                           [excludePublicUser]="true"></app-user-autocomplete>
    <label class="dynamic-label-text" [for]="'simpleField' + index">{{ fullAttribute.name }}</label>
  </span>
</ng-container>
