/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ArtifactResponseDto } from '../../models/artifact-response-dto';
import { ArtifactUploadRequestDto } from '../../models/artifact-upload-request-dto';

export interface ArtifactControllerUpload$Params {
  notify: boolean;
      body: ArtifactUploadRequestDto

}

export function artifactControllerUpload(http: HttpClient, rootUrl: string, params: ArtifactControllerUpload$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactResponseDto>> {
  const rb = new RequestBuilder(rootUrl, artifactControllerUpload.PATH, 'post');
  if (params) {
    rb.query('notify', params.notify, {});
    rb.body(params.body, 'multipart/form-data');
  }
  rb.header('ngsw-bypass', 'true', {});

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ArtifactResponseDto>;
    })
  );
}

artifactControllerUpload.PATH = '/api/tenant/artifact/upload';
