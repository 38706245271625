import { inject } from '@angular/core';
import { RegularUserResponseDto } from '@api/models';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

export class RuntimeVariablesOptions {
  readonly artifactTypes: ListContainer<NewArtifactType> = new ListContainer<NewArtifactType>();
  readonly users: ListContainer<RegularUserResponseDto> = new ListContainer<RegularUserResponseDto>();
  readonly attributes: ListContainer<NewAttribute> = new ListContainer<NewAttribute>();
  readonly dataTypes: ListContainer<NewDataType> = new ListContainer<NewDataType>();

  private readonly newCache = inject(NewCacheService);

  async init(): Promise<void> {
    const artifactTypes = (this.newCache.data.artifactTypes.value as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto));
    const attributes = (this.newCache.data.attributes.value as AttributeResponseDto[]).map(dto => new NewAttribute(dto));
    const dataTypes = (this.newCache.data.dataTypes.value as DataTypeResponseDto[]).map(dto => new NewDataType(dto));

    this.artifactTypes.setList(artifactTypes, 'id');
    this.attributes.setList(attributes, 'id');
    this.dataTypes.setList(dataTypes, 'id');
    this.users.setList(this.newCache.data.users.value as RegularUserResponseDto[], 'id');
  }
}
