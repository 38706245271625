<div #rowBodyRef
     [cdkDragData]="{ rowIndex: rowIndex }"
     cdkDrag
     class="toolbar-container">
  <div [style.left]="toolbarPositionLeft"
       class="toolbar">
    <i cdkDragHandle
       class="bi bi-arrows-move toolbar__icon"
       pRipple
       pTooltip="{{'Move' | translate}}"
       tooltipPosition="top"></i>
    <i (click)="style()"
       class="bi bi-sliders toolbar__icon"
       pRipple
       pTooltip="{{'Style' | translate}}"
       tooltipPosition="top"></i>
    <i (click)="copy()"
       class="pi pi-copy toolbar__icon"
       pRipple
       pTooltip="{{'Copy' | translate}}"
       tooltipPosition="top"></i>
    <i (click)="paste()"
       *ngIf="copyPasterService.hasRowCopy$ | async"
       class="bi bi-clipboard-plus toolbar__icon"
       pRipple
       pTooltip="{{'Paste' | translate}}"
       tooltipPosition="top"></i>
    <i (click)="delete()"
       class="bi bi-trash toolbar__icon"
       pRipple
       pTooltip="{{'Delete' | translate}}"
       tooltipPosition="top"></i>
  </div>

  <div
   [ngStyle]="row.styles"
   [style.background-image]="row.styles.backgroundImage | internalImageIdToUrl"
   [style.background-size]="'cover'"
   [style.background-position]="'center'"
   [style.background-repeat]="'no-repeat'"
   class="row g-0 row-content">
    <app-page-block
      *ngFor="let block of row.blocks; index as blockIndex"
      [blockIndex]="blockIndex"
      [block]="block"
      [class]="'col-sm-' + row.layout[blockIndex]"
      [rowIndex]="rowIndex"
      [modalId]="modalId"
      [page]="page"
      [sectionIndex]="sectionIndex"></app-page-block>
  </div>

  <p-message
    *ngIf="widgetHelper.isLayoutSumError(handleValidationErrorsService.errors, sectionIndex, rowIndex)"
    [text]="handleValidationErrorsService.errors.fields.sections[sectionIndex].rows[rowIndex].layout.sum"
    severity="error"></p-message>
</div>
