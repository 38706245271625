<div *ngIf="section.styles" class="style-block">
  <p-accordion [multiple]="true">
    <p-accordionTab [header]="'Outer section container' | translate" [selected]="true">
      <app-page-element-styler
        [element]="outerContainer"
        [isContainerClassEditable]="true"
        [styles]="section.styles.outerContainer"
        [applicationId]="applicationId"
      >
      </app-page-element-styler>

      <app-sticky-positioning [settings]="section.styles.outerContainer.stickyPositioning"></app-sticky-positioning>
    </p-accordionTab>

    <p-accordionTab [header]="'Inner section container' | translate">
      <app-page-element-styler
        [element]="innerContainer"
        [isContainerClassEditable]="true"
        [styles]="section.styles.innerContainer"
        [applicationId]="applicationId"
      >
      </app-page-element-styler>
    </p-accordionTab>

    <p-accordionTab [header]="'Responsive design' | translate">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox [(ngModel)]="section.sectionHide.pc" binary="true" inputId="hidePC"></p-checkbox>
          <label for="hidePC">{{ 'Hide on PC' | translate}}</label>
        </span>
      </span>

      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox [(ngModel)]="section.sectionHide.tablet" binary="true" inputId="hideTablet"></p-checkbox>
          <label for="hideTablet">{{ 'Hide on Tablet' | translate}}</label>
        </span>
      </span>

      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox [(ngModel)]="section.sectionHide.mobile" binary="true" inputId="hideMobile"></p-checkbox>
          <label for="hideMobile">{{ 'Hide on Mobile' | translate}}</label>
        </span>
      </span>
    </p-accordionTab>
  </p-accordion>
</div>
