<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid">
  <div *ngIf="m" class="col-12 top_box">
    <div>
      <div class="p-inputgroup">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [ngModel]="!!m.user.deleted" [disabled]="true" binary="true" id="isDeleted"></p-checkbox>
            <label for="isDeleted">{{ IS_DELETED_LABEL | translate }}</label>
          </span>
        </span>
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="m.user.isLoginDisabled" binary="true" inputId="isLoginDisabled"></p-checkbox>
            <label style="width: 120px" for="isLoginDisabled">{{ 'Is Login Disabled' | translate }}</label>
          </span>
        </span>
        <span *ngIf="!m.user?.id" class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="m.generatePassword" binary="true" inputId="generatePassword"></p-checkbox>
            <label style="width: 150px" for="generatePassword">
              {{ 'Generate password' | translate }}
              <i class="pi pi-info-circle ml-1"
                 pTooltip="{{ 'Generated password will be sent to user via Email' | translate }}"
                 tooltipPosition="top"></i>
            </label>
          </span>
        </span>
      </div>
    </div>
    <div>
      <a *ngIf="m.userProfileUrl !== null" [routerLink]="m.userProfileUrl!.routerLink"
         [queryParams]="m.userProfileUrl!.queryParams">
        <button
          pButton
          pTooltip="{{ 'User profile page' | translate }}"
          type="button"
          icon="pi pi-eye"
          class="p-button p-button-rounded p-button-secondary p-button-outlined"
        ></button>
      </a>
      <button
        (click)="s.resetPasswordWithConfirmation()"
        *ngIf="m.user && m.user.id && !m.user.deleted"
        [label]="'Reset password' | translate"
        class="p-button p-button-secondary"
        pButton
      ></button>
      <button
        *ngIf="m.user && m.user.id && m.user.deleted"
        (click)="s.restoreWithConfirmation()"
        [label]="'Restore' | translate"
        class="p-button p-button-info"
        pButton
      ></button>
      <button
        (click)="s.deleteWithConfirmation()"
        *ngIf="m.user && m.user.id && !m.user.deleted"
        [label]="'Delete' | translate"
        class="p-button p-button-danger"
        pButton
      ></button>
      <button (click)="s.cancel()" [label]="'Cancel' | translate" class="p-button p-button-warning" pButton
              type="button"></button>
      <button (click)="s.save()" [label]="'Save' | translate" class="p-button p-button-success" pButton type="button">
        <span *ngIf="m.inProgress" class="pi pi-spin pi-spinner"></span>
      </button>
    </div>
  </div>

  <app-system-admin-user-form *ngIf="m" [m]="m" class="flex-grow-1"></app-system-admin-user-form>
</div>
