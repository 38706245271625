import { WorkflowConditionGroupDto } from '@api/models/workflow-condition-group-dto';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { RuleConditionOperatorType, WorkflowConditionType } from '@workflows/types';
import { RuleCondition, RuleConditionValue } from '@workflows/types/conditions/rule-condition';

export class RuleConditionGroup implements WorkflowConditionGroupDto {
  conditions: RuleCondition[];
  groups: RuleConditionGroup[];
  operator: RuleConditionOperatorType;

  private readonly dto?: WorkflowConditionGroupDto;

  constructor(
    private readonly conditionConverter: RuleConditionValueConverterService,
    group?: WorkflowConditionGroupDto | RuleConditionGroup,
  ) {
    Object.assign(this, group);
    if (group && !(group instanceof RuleConditionGroup)) {
      this.dto = group;
      this.fromDto();
    }
  }

  static defaultCondition(manual = false): RuleCondition {
    return new RuleCondition({
      operationType: WorkflowConditionType.CONTAINS,
      source: new RuleConditionValue({ value: '', isDynamic: true, manual }),
      destination: new RuleConditionValue({ value: '', isDynamic: false, manual: false }),
    });
  }

  static defaultGroup(conditionConverter: RuleConditionValueConverterService): RuleConditionGroup {
    return new RuleConditionGroup(conditionConverter, {
      operator: RuleConditionOperatorType.AND,
      conditions: [RuleConditionGroup.defaultCondition()],
      groups: [],
    });
  }

  toServer(converterService: RuleConditionValueConverterService): WorkflowConditionGroupDto {
    const ruleConditionGroup = new RuleConditionGroup(this.conditionConverter, this);
    converterService.valuesToServer(ruleConditionGroup);

    return {
      conditions: ruleConditionGroup.conditions.map(condition => condition.toServer()),
      groups: ruleConditionGroup.groups.map(group => group.toServer(converterService)),
      operator: ruleConditionGroup.operator,
    };
  }

  fromDto(): void {
    if (!this.dto) return;

    this.conditions = this.conditions.map(
      condition =>
        new RuleCondition({
          source: new RuleConditionValue(condition.source),
          destination: new RuleConditionValue(condition.destination),
          operationType: condition.operationType,
          extra: condition.extra,
        }),
    );
    this.groups = this.groups?.map(group => new RuleConditionGroup(this.conditionConverter, group)) || [];

    this.conditionConverter.valuesToClient(this);
  }
}
