import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArtifactLinkResponseDto } from '@api/models';
import { ArtifactGroupItemResponseDto } from '@api/models/artifact-group-item-response-dto';
import { ArtifactGroupResponseDto } from '@api/models/artifact-group-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { DELETED_USER_VALUE, ID_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetArtifactAttributeValuePath } from '@shared/methods/client-attribute.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ApexChartWidgetComponent } from '@widgets/apex-chart/apex-chart-widget.component';
import { ApexAxis } from '@widgets/apex-chart/types/axis.types';
import { ApexChartType } from '@widgets/apex-chart/types/chart.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { FontStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { LinkFilterTypes } from '@widgets/shared/components/artifact-filters/components/link-filter/types/link-filter.types';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ArtifactFilter, ArtifactFilterDto, ArtifactFilterType } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { DateFormatEnum } from '@widgets/shared/types/date-format.types';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { ApexAxisChartSeries, ApexYAxis } from 'ng-apexcharts/lib/model/apex-types';
import { lastValueFrom } from 'rxjs';
import {
  ApexAxisPosition,
  ApexChartWidgetModel,
  ApexChartWidgetModelDto,
  ApexChartWidgetTypeChart,
  ApexChartWidgetValue,
  ApexUserDisplay,
  ContainerSize,
} from '../types/apex-chart-widget.types';

@Injectable()
export class ApexChartWidgetService {
  c: ApexChartWidgetComponent;
  m: ApexChartWidgetModel;

  constructor(
    private readonly cache: NewCacheService,
    private route: ActivatedRoute,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly filterService: ArtifactFiltersService,
  ) {}

  async init(context: ApexChartWidgetComponent, dto?: ApexChartWidgetModelDto | null): Promise<void> {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new ApexChartWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new ApexChartWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
    !this.m.id && (this.m.id = 'chart-' + ElvisUtil.makeHash(16));
    this.m.options.systemAttributes = this.filterService.getSystemAttributes();

    if (!this.c.isLayoutMode) return;

    const data = this.cache.data;

    context.registerSubscriptions([
      data.artifactTypes.subscribe(artifactTypes => {
        this.m.options.artifactTypes.setList(
          (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
          ID_KEY,
        );
        this.raceResolver();
      }),
      data.linkTypes.subscribe(linkTypes => {
        this.m.options.linkTypes.setList(
          (linkTypes as LinkTypeResponseDto[])
            .map(dto => new LinkType(dto))
            .reduce((directionalLinkTypes: DirectionalLinkType[], linkType: LinkType) => {
              return [
                ...directionalLinkTypes,
                new DirectionalLinkType(LinkDirection.outgoing, linkType),
                new DirectionalLinkType(LinkDirection.incoming, linkType),
              ];
            }, []),
        );
      }),
      data.dataTypes.subscribe(dataTypes => {
        this.m.options.dataTypes.setList(
          (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
          ID_KEY,
        );
        this.raceResolver();
      }),
      data.attributes.subscribe(attributes => {
        this.m.options.attributes = [...(attributes as AttributeResponseDto[]).map(dto => new NewAttribute(dto)), ...this.filterService.getSystemAttributes()];
        this.raceResolver();
      }),
      data.users.subscribe(async userDtos => {
        this.m.options.users.setList(userDtos as ArtifactLinkResponseDto[]);

        const { users } = this.m.options;

        this.m.options.usersData = {};
        users.list.forEach(user => {
          this.m.options.usersData[user.id] = {
            email: user.attributes[GlobalConstants.getValue(GlobalConstantsEnum.emailAttributeId)].value,
            name: user.attributes[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)].value,
          };
        });

        this.raceResolver();
      }),
      this.route.queryParams.subscribe(params => {
        this.m.urlParams = params;
        this.urlKeyCheck();
      }),
    ]);
  }

  async raceResolver(): Promise<void> {
    if (!this.m.options.attributes.length || !this.m.options.dataTypes.loaded || !this.m.options.users.loaded || !this.m.options.artifactTypes.loaded) {
      return;
    }

    this.m.settings.fromDto(this.m.options);
    await this.setRestrictions();
    this.initOptions();
    this.updateAxis();
    this.checkDateFormatVisibility();
    this.updateChartData();
    this.initResizeObserver();
  }

  initResizeObserver(): void {
    const elem = (document as any).getElementById(this.m.id);
    const parent = elem?.closest('.block-part-body') || elem?.closest('.block-part');
    if (!parent) return;
    this.m.resizeObserver = new (window as any).ResizeObserver(() => this.refreshChart());
    this.m.resizeObserver.observe(parent);
  }

  urlKeyCheck(isFetchData?: boolean): void {
    const params = this.m.urlParams;
    const urlKeys = Object.keys(params);
    const currentUrlKeys = Object.keys(this.m.currentUrlParams);
    let doUpdate = false;

    const folderKey = this.m.settings.folderKey;
    this.m.folderId = params[folderKey];

    let chartFilters: ArtifactFilter[] = [];
    this.m.settings.charts.forEach(chart => chart.attributesFilter.length && (chartFilters = [...chartFilters, ...chart.attributesFilter]));

    [...this.m.settings.attributesFilter, ...chartFilters]
      ?.filter(af => af.type === ArtifactFilterType.link)
      .forEach(af => {
        const key = (af.value as LinkFilterTypes).name;
        if ((urlKeys.includes(key) && this.m.currentUrlParams[key] !== params[key]) || (!urlKeys.includes(key) && currentUrlKeys.includes(key))) {
          doUpdate = true;
        }
      });

    if (this.m.settings.showUrlChartChange && this.m.settings.urlChartChangeKey) {
      if (isFetchData) {
        doUpdate = true;
      } else {
        const val = params[this.m.settings.urlChartChangeKey];

        if (val !== this.m.settings.prevUrlChartNames) {
          this.m.settings.prevUrlChartNames = val;
          doUpdate = true;
        }
      }
    }

    if (this.m.settings.listenUrlFolderId && this.m.prevFolderId !== this.m.folderId) {
      doUpdate = true;
    }

    doUpdate && this.updateChartData();

    this.m.currentUrlParams = params;
    this.m.prevFolderId = this.m.folderId;
  }

  getFilterFromAttribute(attr: any, filterOption: string | undefined): ArtifactFilter | null {
    const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
    if (!dataType || !filterOption) return null;

    const value = this.filterService.getFilterValueFromString(filterOption, dataType, this.m.options.users.list);

    const dto: ArtifactFilterDto = {
      name: attr.name,
      value,
      attributeId: attr.id,
      dataTypeId: dataType.id,
      type: null,
    } as ArtifactFilterDto;

    return ArtifactFilter.fromDtoAndOptions(dto, {
      attributes: this.m.options.attributes,
      dataTypes: this.m.options.dataTypes.list,
      linkTypes: this.m.options.linkTypes.list,
    });
  }

  onRemoveChart(e: Event, index: number): void {
    e.preventDefault();
    e.stopPropagation();
    this.m.settings.charts.splice(index, 1);
    setTimeout(() => {
      this.refreshChart();
    });
  }

  onRemoveAxis(e: Event, index: number): void {
    e.preventDefault();
    e.stopPropagation();
    this.m.settings.axes.splice(index, 1);
    this.updateAxis();
  }

  onChartAxisChange(chart: ApexChartType): void {
    const axis = this.m.settings.axes.find(a => a.name === chart.axis);
    if (!axis) return;

    chart.axisId = axis.id;
  }

  updateAxis(): void {
    this.m.options.axisNames = this.m.settings.axes.map(a => a.name);

    setTimeout(() => {
      this.m.settings.charts.forEach(ch => {
        !ch.axisId && (ch.axisId = this.m.settings.axes[0].id);

        const axis = this.m.settings.axes.find(a => a.id === ch.axisId);
        ch.axis = axis ? axis.name : this.m.settings.axes[0].name;
      });
    });
  }

  onArtifactTypesChange(isSetCharts = true): void {
    let attrIds: string[] = [];
    !this.m.settings.artifactTypes.length && (this.m.settings.columnAttribute = null);

    this.m.settings.artifactTypes.forEach((at: NewArtifactType) => {
      const ids = Object.values(at.attributes).map(value => value.id);
      if (attrIds.length) {
        attrIds = attrIds.filter(id => ids.includes(id));
      } else {
        attrIds = [...ids];
      }
    });

    isSetCharts &&
      this.m.settings.charts.forEach((ch, index) => {
        this.setArtifactTypeToChart(ch, index);
      });

    const artifactAttributes = this.m.options.attributes.filter(attr => attrIds.includes(attr.id));
    this.m.options.commonAttributes = [...artifactAttributes, ...this.m.options.systemAttributes];
    this.m.options.filteredAttributes = [...artifactAttributes.filter(this.isDateOrEnumOrUser.bind(this)), ...this.m.options.systemAttributes];
    this.m.settings.columnAttribute?.value && this.updateChartData();
  }

  setArtifactTypeToChart(chart: ApexChartType, index: number): void {
    const artifactType = this.m.settings.artifactTypes[0];

    if (!artifactType) {
      chart.artifactType = null;
      chart.selectedAttribute = null;
      chart.attributes = [];
      return;
    }

    if (chart.artifactType?.value.id === artifactType.id) return;

    chart.selectedAttribute = null;
    chart.artifactType = new SelectOption(artifactType.name, artifactType);
    this.onChartArtifactTypeChange(index);
  }

  onChartArtifactTypeChange(index: number, _chart?: ApexChartType): void {
    const chart = _chart || this.m.settings.charts[index];
    if (!chart.artifactType) return;

    if (!chart.artifactType.value.id) {
      chart.selectedAttribute = null;
      chart.attributes = [];
    }

    const ids = Object.values(chart.artifactType.value.attributes).map(value => value.id);
    chart.attributes = this.m.options.attributes.filter(attr => ids.includes(attr.id)).filter(this.isNumber.bind(this));
    chart.allAttributes = [
      ...this.m.options.attributes.filter(attr => ids.includes(attr.id)),
      ...this.getLinks(chart.artifactType.value.id),
      ...this.filterService.getSystemAttributes(),
    ];
  }

  getLinks(artifactTypeId: string): DirectionalLinkType[] {
    return this.m.options.linkTypes.list
      .filter((linkType: DirectionalLinkType) => this.checkLinkRestriction(linkType, artifactTypeId) && linkType.isLinkingArtifactTypeId(artifactTypeId))
      .map(link => {
        link.name = link.direction === LinkDirection.outgoing ? link.outgoingName : link.incomingName;
        return link;
      });
  }

  onAddAxis(): void {
    let name = 'Axis-' + (this.m.settings.axes.length + 1);
    const names = this.m.settings.axes.map(a => a.name);
    names.includes(name) && (name += '-copy');
    this.m.settings.axes.push(new ApexAxis({ name }));
    this.updateAxis();
  }

  onAddChart(): void {
    // todo add constant and define logic of auto-naming
    let name = 'Chart-' + (this.m.settings.charts.length + 1);
    const names = this.m.settings.charts.map(ch => ch.name);
    names.includes(name) && (name += '-copy');
    const chart = new ApexChartType();
    chart.name = name;
    this.m.settings.charts.push(chart);
    this.setArtifactTypeToChart(chart, this.m.settings.charts.length - 1);
    this.updateAxis();
  }

  selectStyle(styles: FontStyles): void {
    this.m.settings.selectedStyle = styles;
  }

  pickStyles(styles: FontStyles, title: string, el: any): void {
    this.m.settings.selectedStyle = styles;
    this.m.settings.selectedTitle = title;
    el.hide();
  }

  initOptions(): void {
    this.m.settings.artifactTypes.length && this.m.settings.columnAttribute?.value && this.onArtifactTypesChange(false);

    this.m.settings.charts.forEach((chart, index) => {
      this.onChartArtifactTypeChange(index, chart);
    });
  }

  isDateOrEnumOrUser(attr: NewAttribute): boolean {
    const dataType = this.getDataTypeById(attr.dataTypeId);

    return !!dataType && (dataType.isDate || dataType.isDateTime || dataType.isEnum || dataType.isUser);
  }

  isUser(attr: NewAttribute): boolean {
    const dataType = this.getDataTypeById(attr.dataTypeId);

    return !!dataType && dataType.isUser;
  }

  isEnum(attr: NewAttribute): boolean {
    const dataType = this.getDataTypeById(attr.dataTypeId);

    return !!dataType && dataType.isEnum;
  }

  isDateOrDateTime(attr: NewAttribute): boolean {
    if (attr.systemAttributeKey) {
      return NonAttributeKeys.isDateSpecificAttributeKey(attr.systemAttributeKey);
    }
    const dataType = this.getDataTypeById(attr.dataTypeId);
    return dataType && (dataType.isDateTime || dataType.isDate);
  }

  isNumber(attr: NewAttribute): boolean {
    const dataType = this.getDataTypeById(attr.dataTypeId);

    return !!dataType && dataType.isSimple && (dataType.isInteger || dataType.isDecimal);
  }

  getDataTypeById(id: string): NewDataType {
    return this.m.options.dataTypes.filterByKey('id', id)[0];
  }

  onAxisChange(): void {
    this.checkDateFormatVisibility();
    this.updateChartData();
  }

  checkDateFormatVisibility(): void {
    this.m.showDateFormat = this.m.settings.columnAttribute ? this.isDateOrDateTime(this.m.settings.columnAttribute.value) : false;
    this.m.showUserFormat = this.m.settings.columnAttribute
      ? this.isUser(this.m.settings.columnAttribute.value) || this.filterService.isUserSystemAttribute(this.m.settings.columnAttribute.value.id)
      : false;
    this.m.showDateFormat && !this.m.settings.dateFormat && (this.m.settings.dateFormat = new SelectOption(DateFormatEnum.day));
  }

  getParsedValue(val: string): number {
    return parseInt(val) || 0;
  }

  getSize(): ContainerSize {
    const elem = (document as any).getElementById(this.m.id);
    const parent = elem?.closest('.block-part-body') || elem?.closest('.block-part') || elem?.closest('.sidebar_block_part');
    const wrapper = parent?.querySelector('div');

    if (!elem || !parent || !wrapper) return {};

    const { paddingLeft: left, paddingRight: right, paddingTop: top, paddingBottom: bottom } = wrapper.style;
    const { paddingLeft: parentLeft, paddingRight: parentRight, paddingTop: parentTop, paddingBottom: parentBottom } = parent.style;

    const widthGap = this.getParsedValue(left) + this.getParsedValue(right) + this.getParsedValue(parentLeft) + this.getParsedValue(parentRight);
    const heightGap = this.getParsedValue(top) + this.getParsedValue(bottom) + this.getParsedValue(parentTop) + this.getParsedValue(parentBottom);
    let height = parent.style.height ? parseInt(parent.style.height) - heightGap : null;
    let width = parent.offsetWidth - widthGap;

    const card = elem.closest('app-card-area');
    if (card?.style) {
      const container = card.closest('article');
      const contHeight = container?.style['grid-template-rows'];
      const { paddingLeft, paddingRight, paddingTop, paddingBottom } = card.style;
      width = container.clientWidth - parseInt(paddingLeft) - parseInt(paddingRight);
      height = container.clientHeight - parseInt(paddingTop) - parseInt(paddingBottom);
      contHeight === 'auto' && (height = null);
    }

    return { width, height: height || width / 2 };
  }

  async getChartData(chart: ApexChartType): Promise<any> {
    if (!chart.artifactType || !this.m.settings.columnAttribute?.value) {
      return;
    }

    const isSystemAttribute = this.filterService.isSystemAttribute(this.m.settings.columnAttribute.value.id);
    const isDateTimeSystemAttribute = this.filterService.isDateTimeSystemAttribute(this.m.settings.columnAttribute.value.id);

    const attr = isSystemAttribute
      ? this.m.settings.columnAttribute.value.systemAttributeKey || ''
      : GetArtifactAttributeValuePath(this.m.settings.columnAttribute.value.id);

    const $and: any[] = [
      {
        artifactTypeId: { $in: [{ $oid: chart.artifactType.value.id }] },
      },
      { deleted: { $eq: null } },
    ];

    // todo if(when) implemented global link filtering then check link override if filtered link is same global and local
    const promiseArr = [...this.m.settings.attributesFilter, ...chart.attributesFilter]
      .filter(af => af.type === ArtifactFilterType.link)
      .map(af => this.filterService.getLinkQuery(af));

    promiseArr.length &&
      (await Promise.all(promiseArr)).forEach(query => {
        query && $and.push(query);
      });

    const ids: string[] = [];
    chart.attributesFilter?.forEach(af => {
      ids.push(af.attributeId);
      const query = this.filterService.getQuery(af);
      query && $and.push(query);
    });

    this.m.settings.attributesFilter?.forEach(af => {
      if (ids.includes(af.attributeId)) return;
      const query = this.filterService.getQuery(af);
      query && $and.push(query);
    });

    if (this.m.settings.listenUrlFolderId && this.m.folderId) {
      const $in = this.m.folderId.split(',').map(id => ({ $oid: id }));
      $and.push({ ['folderData.parentId']: { $in } } as any);
    }

    const filter: string = JSON.stringify({
      $and,
    });

    const params: any = {
      filter,
      groupBy: attr,
    };

    if (chart.selectedAttribute?.id) {
      params.attributeIds = JSON.stringify([chart.selectedAttribute?.id]);
      params.subOperations = JSON.stringify(['sum']);
    }

    let isDateTime = false;
    const dataType = this.m.options.dataTypes.listMap[this.m.settings.columnAttribute?.value.dataTypeId];
    if (isDateTimeSystemAttribute || (dataType?.baseDataType && [BaseDataType.date, BaseDataType.dateTime].includes(dataType.baseDataType))) {
      // todo check it: may be allow only one common dateformat for all charts
      params.dateFormat = this.m.settings.dateFormat?.value || DateFormatEnum.day;
      isDateTime = true;
    }

    const res: ArtifactGroupResponseDto = await lastValueFrom(this.tenantArtifactService.artifactControllerGroupby(params));
    const data = res?.data;
    return isDateTime && data ? data.filter(item => !!item.value) : data;
  }

  isChartValid(chart: any): boolean {
    return chart.artifactType && chart.selectedAttribute;
  }

  async updateChartData(): Promise<any> {
    if (!this.m.settings.charts.length && this.m.settings.columnAttribute?.value) return;

    this.setFilteredChart();
    const promiseArr = this.m.settings.filteredCharts.map(chart => this.getChartData(chart));
    const data = await Promise.all(promiseArr);
    this.refreshChart(data);
  }

  setFilteredChart(): void {
    const value = this.m.settings.urlChartChangeKey ? this.m.urlParams[this.m.settings.urlChartChangeKey] : null;

    if (this.m.settings.showUrlChartChange && this.m.settings.urlChartChangeKey && value) {
      const chartNames = value.split(',');
      this.m.settings.filteredCharts = this.m.settings.charts.filter(ch => !ch.isUnderUrlControl || chartNames.includes(ch.name));
    } else {
      this.m.settings.filteredCharts = this.m.settings.charts;
    }
  }

  async refreshChart(_data?: ArtifactGroupItemResponseDto[][]): Promise<void> {
    const chartData = _data || this.m.chartData;
    if (!chartData?.length) {
      return;
    }

    this.m.chartData = chartData;

    const { width, height } = this.getSize();
    const { series, yaxis } = this.getOptions(chartData);
    const dataLabelsColors: string[] = this.getDataLabelColors(chartData);
    const xAxisLabelStyles: any = cloneDeep(this.m.settings.labelStyles);
    xAxisLabelStyles.colors = xAxisLabelStyles.color;

    this.m.chartOptions = {
      chart: {
        type: this.m.settings.areaMode ? ApexChartWidgetTypeChart.area : ApexChartWidgetTypeChart.bar,
        toolbar: { show: this.m.settings.showToolbar },
        zoom: { enabled: this.m.settings.showToolbar, allowMouseWheelZoom: false, autoScaleYaxis: this.m.settings.autoScaleYaxis },
        width,
        events: {
          animationEnd(chart: any) {
            const svg = chart.el?.getElementsByTagName('svg')[0];
            svg && svg.setAttribute('overflow', 'visible');
          },
        },
      },
      series,
      yaxis,
      dataLabels: {
        enabled: this.m.settings.showDataLabels,
        style: {
          colors: dataLabelsColors,
        },
      },
      xaxis: {
        labels: {
          style: xAxisLabelStyles,
          datetimeUTC: false,
        },
      },
      title: {
        text: this.m.settings.title,
        align: 'center',
        floating: true,
        style: this.m.settings.titleStyles,
      },
      subtitle: {
        text: this.m.settings.subTitle,
        align: 'center',
        style: this.m.settings.subTitleStyles,
      },
      plotOptions: {
        bar: {
          barHeight: '100%',
          borderRadius: parseInt(this.m.settings.borderRadius, 10),
        },
      },
      noData: this.m.settings.noData.toChartOptions(),
      grid: this.m.settings.grid,
    };

    if (this.m.settings.columnAttribute?.value && this.isDateOrDateTime(this.m.settings.columnAttribute?.value)) {
      !this.m.chartOptions.xaxis && (this.m.chartOptions.xaxis = {});
      this.m.chartOptions.xaxis.type = 'datetime';
    }

    height && this.m.chartOptions.chart && (this.m.chartOptions.chart.height = height);
  }

  getOpacityColor(color: string, opacity: string): string {
    if (!opacity || !color) return color;

    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  private getAxisById(id: string): ApexAxis | null {
    return this.m.settings.axes.find(axis => axis.id === id) || null;
  }

  private getOptions(chartData: ArtifactGroupItemResponseDto[][]): any {
    const series: ApexAxisChartSeries = [];
    const yaxis: ApexYAxis[] = [];
    const axisNameMap: any = {};
    const seriesAttrIds: string[] | any = [];

    this.m.settings.filteredCharts.forEach((ch, index) => {
      const data = chartData[index];
      if (!data) return;

      const attrId = ch.selectedAttribute?.id || null;
      const color = this.getOpacityColor(ch.color, ch.opacity);

      series.push({
        name: ch.name,
        type: ch.type,
        data: data
          .map(item => ({
            x: this.getValue(item.value),
            y: attrId && item.sum ? item.sum[attrId] : item.count || null,
          }))
          .filter(item => !item.x.includes(DELETED_USER_VALUE)),
        color,
      });

      seriesAttrIds.push(ch.selectedAttribute?.id);

      let isFirst = false;
      if (!axisNameMap[ch.axis]) {
        axisNameMap[ch.axis] = ch.name;
        isFirst = true;
      }

      const currentAxis = cloneDeep(this.getAxisById(ch.axisId));
      const opposite = currentAxis?.position.value === ApexAxisPosition.right;

      const axis: ApexYAxis = { seriesName: axisNameMap[ch.axis] };
      if (isFirst) {
        axis.title = { text: currentAxis?.name || ch.axis, style: currentAxis?.titleStyle || {} };
        axis.opposite = opposite;

        if (currentAxis) {
          axis.labels = { style: currentAxis?.labelStyle, formatter: this.axisFormatter.bind(this) };
          axis.labels.style && (axis.labels.style.colors = currentAxis?.labelStyle.color);
        }
      } else {
        axis.show = false;
      }
      axis.logarithmic = this.m.settings.logarithmic;
      this.m.settings.logBase && (axis.logBase = this.m.settings.logBase);

      yaxis.push(axis);
    });

    return { series, yaxis };
  }

  private axisFormatter(val: number): string {
    return this.m.settings.roundAxisY ? this.roundCounter(val) : val?.toString();
  }

  private roundCounter(val: number): string {
    let sign = '';
    if (val > 9999) {
      if (val > 999999) {
        val = Math.round(val / 100000) / 10;
        sign = 'M';
      } else {
        val = Math.round(val / 100) / 10;
        sign = 'K';
      }
    } else {
      val = Math.round(val * 100) / 100;
    }
    return `${val}${sign}`;
  }

  private getDataLabelColors(chartData: ArtifactGroupItemResponseDto[][]): string[] {
    return Array(chartData.length).fill(this.m.settings.dataLabelsColor);
  }

  private getValue(item: string): string | string[] {
    if (Array.isArray(item) && !item.length) {
      return this.m.settings.emptyLabel;
    }

    if (this.m.settings.columnAttribute) {
      if (this.isUser(this.m.settings.columnAttribute.value) || this.filterService.isUserSystemAttribute(this.m.settings.columnAttribute.value.id)) {
        return (Array.isArray(item) ? item : [item]).map(item => this.getUserLabel(item));
      }

      if (this.isEnum(this.m.settings.columnAttribute.value)) {
        const dataType = this.getDataTypeById(this.m.settings.columnAttribute.value.dataTypeId);

        if (dataType) {
          return Array.isArray(item) ? item.map(value => this.getLabel(value, dataType)) : this.getLabel(item, dataType);
        }
      }

      if (this.isDateOrDateTime(this.m.settings.columnAttribute.value)) {
        return this.getLabelByOptions(item);
      }
    }

    return item || this.m.settings.emptyLabel;
  }

  private getLabel(item: string, dataType: NewDataType): string {
    return dataType.values?.find(val => val.value === item)?.label || item || this.m.settings.emptyLabel;
  }

  private getLabelByOptions(label: string): string {
    if (this.m.settings.dateFormat?.value === DateFormatEnum.week) {
      const [year, week] = label.split('-W');
      const weekTime = parseInt(week) * (7 * 24 * 60 * 60 * 1000);
      return new Date(new Date(year).getTime() + new Date(weekTime).getTime()).toString();
    }

    const offset = new Date().getTimezoneOffset() / 60;
    return moment(label).subtract(offset, 'h').format();
  }

  private getUserLabel(id: string): string {
    const field: any = this.m.settings.userDisplayType || ApexUserDisplay.email;
    const user = (this.m.options.usersData as any)[id];
    const userLabel = user ? user[field] : DELETED_USER_VALUE;
    return user ? userLabel : this.m.settings.emptyLabel;
  }

  private checkLinkRestriction(linkType: DirectionalLinkType, artifactTypeId: string): boolean {
    const restrictions = this.m.restrictions;
    if (!restrictions) return false;

    const { id, direction } = linkType;

    if (artifactTypeId && restrictions[artifactTypeId])
      return restrictions[artifactTypeId].some(({ linkType }) => linkType?.value === id && linkType?.meta === direction);

    return false;
  }

  private async setRestrictions(): Promise<void> {
    this.m.restrictions = await LinkMethods.getGroupedLinkRestrictions(this.m.options.artifactTypes.list, this.m.options.linkTypes.list);
  }
}
