@if (visible) {
  <div class="dynamic-label">
    <span class="dynamic-file-upload">
      <span *ngIf="isMandatory" class="form-mandatory-field"></span>
      <span [ngClass]="{ 'upload-hidden': !isEnabled }">
        <p-fileUpload
          #fileUpload
          class="file-upload-wrapper"
          name="demo[]"
          mode="basic"
          [ngClass]="{ 'file-upload-wrapper__align-right': label }"
          [disabled]="!isEnabled"
          [customUpload]="true"
          [auto]="true"
          [multiple]="attribute?.multipleValues"
          (uploadHandler)="onFilesAdd($event)"
        >
        </p-fileUpload>
      </span>
      <div class="file-wrapper">
        <span *ngIf="!fileUpload.multiple && fileValue">
          <div *ngIf="fileValue.formatData?.filename || fileValue.name" class="file-item" [id]="'file-component' + index">
            <div class="flex">
              <span *ngIf="formatSettings?.value?.showIcon && attribute?.icon" [class]="attribute.icon" class="mr-1"></span>
              <span appFileArtifactViewer [file]="fileValue" [ngClass]="{ 'p-text-truncate': truncate }" class="downloadable cursor-pointer">
                {{ fileValue.formatData?.filename || fileValue.name }} [{{ fileValue.formatData?.size || fileValue.size | getFileSize }}]
                <app-upload-progress *ngIf="isInSavingProcess && !fileValue.id && !fileValue.formatData?.id"
                                     [fileName]="fileValue.formatData?.filename || fileValue.name"></app-upload-progress>
              </span>
            </div>
            <span *ngIf="isEditMode && isEnabled" class="button-box">
              <button pButton icon="pi pi-times" class="p-button-danger p-button-text utility-button" type="button" (click)="onFileRemove()"></button>
            </span>
          </div>
        </span>

        <ul *ngIf="fileUpload.multiple && fileValue?.length" class="file-list" [id]="'file-component' + index">
          <li *ngFor="let file of fileValue; let i = index">
            <div *ngIf="file && (file.formatData?.filename || file.name)" class="file-item">
              <div class="flex">
                <span *ngIf="formatSettings?.value?.showIcon && attribute?.icon" [class]="attribute.icon" class="mr-1"></span>
                <div appFileArtifactViewer [file]="file" [ngClass]="{ 'p-text-truncate': truncate }" class="downloadable cursor-pointer">
                  {{ file.formatData?.filename || file.name }} [{{ file.formatData?.size || file.size | getFileSize }}]
                  <app-upload-progress *ngIf="isInSavingProcess && !file.id && !file.formatData?.id"
                                       [fileName]="file.formatData?.filename || file.name"></app-upload-progress>
                </div>
              </div>
              <div *ngIf="isEditMode && isEnabled" class="button-box">
                <button pButton icon="pi pi-times" type="button" class="p-button-danger p-button-text utility-button" (click)="onFileRemove(i)"></button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <label [for]="'file-component' + index" [ngStyle]="formatSettings?.label?.styles" class="dynamic-label-text">
        <span *ngIf="formatSettings?.label?.showIcon && attribute?.icon" [class]="attribute.icon" class="mr-1"></span>
        <span> {{ label }} </span>
      </label>
    </span>
  </div>
}
