import { RegularUserResponseDto } from '@api/models';
import { Environment } from '@environments/environment';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';

export class ArtifactListModel {
  rowsPerPage: number = Environment.tableConfig.rowsPerPage;
  rowsPerPageOptions: number[] = Environment.tableConfig.rowsPerPageOptions;
  rows: NewArtifact[] = [];
  columns: TableColumn[] = [];
  artifactTypeOptions: SelectOption<string, NewArtifactType>[] = [];
  artifactType: NewArtifactType | null = null;
  attributeOptions: SelectOption<string, string>[] = [];
  artifactTypes = new ListContainer<NewArtifactType>();
  attributes = new ListContainer<NewAttribute>();
  dataTypes = new ListContainer<NewDataType>();
  users = new ListContainer<RegularUserResponseDto>();
  selectedAttributeIds: string[] = [];
  showTable: boolean = true;
  showImportDialog: boolean = false;
  triggerWorkflowsOnImport: boolean = false;

  constructor(args?: Partial<ArtifactListModel>) {
    args && Object.assign(this, args);
  }
}
