import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsDate, IsDateTime, IsTime } from '@shared/methods/data-type.methods';
import { DATSystemDateLayoutVariant } from '@shared/types/display-at-types';
import { AllDaysInMonthMap, StartEndDate } from '@shared/types/filter.types';
import { clone } from 'lodash';
import moment from 'moment';

export const ONE_WEEK_IN_MILLISECONDS = 60 * 60 * 24 * 7 * 1000;
export const ISO_STRING_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;

export const ConvertToServerDate = (date: Date): string => {
  if (!IsDateValid(date)) return '';

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
};

export const ConvertToServerTime = (date: Date): string => {
  if (!IsDateValid(date)) return '';
  return date.toISOString().slice(11, 16);
};

export const ConvertToServerDatetime = (date: Date): string => {
  if (!IsDateValid(date) || isNaN(date as any)) return '';
  return date.toISOString().slice(0, 16) + 'Z';
};

export const ConvertToClientDate = (date: Date): string => {
  return IsDateValid(date) ? moment(date).utc().local().format('DD.MM.Y') : '';
};

export const ConvertToClientTime = (date: Date): string => {
  if (!IsDateValid(date)) return '';

  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
};

export const ConvertToClientDatetime = (date: Date): string => {
  if (!IsDateValid(date)) return '';
  return `${ConvertToClientDate(date)} ${ConvertToClientTime(date)}`;
};

export const ConvertDateValueToServer = (value: Date, baseDataType: BaseDataType): string => {
  if (IsDate(baseDataType)) return ConvertToServerDate(value);
  if (IsDateTime(baseDataType) || !baseDataType) return ConvertToServerDatetime(value);
  if (IsTime(baseDataType)) return ConvertToServerTime(value);
  return '';
};

export const ConvertToDateByFormat = (date: Date, code: string, isDate?: boolean): string => {
  const utc = moment(date).utc().local();

  const year = isDate ? utc.format('Y') : date.getFullYear();
  let month = isDate ? utc.format('M') : (date.getMonth() + 1).toString();
  let day = isDate ? utc.format('D') : date.getDate().toString();
  let hours = isDate ? utc.format('H') : date.getHours().toString();
  let minutes = isDate ? utc.format('m') : date.getMinutes().toString();
  let sec = isDate ? utc.format('s') : date.getSeconds().toString();
  const mouthCut = month;
  const dayCut = day;

  month.length < 2 && (month = '0' + month);
  day.length < 2 && (day = '0' + day);
  hours.length < 2 && (hours = '0' + hours);
  minutes.length < 2 && (minutes = '0' + minutes);
  sec.length < 2 && (sec = '0' + sec);

  const time = `${hours}:${minutes}:${sec}`;

  const {
    DEFAULT,
    DD_MM_YY,
    DD_MM_YYYY,
    DD_MM_YYYY_DOTTED,
    MM_DD_YYYY_DOTTED,
    M_D_YY_DOTTED,
    MM_DD_YYYY,
    MM_DD_YYYY_SLASHED,
    M_D_YY_HH_MM_SS_DOTTED,
    MM_DD_HH_MM_SS_YYYY,
    MM_DD_YYYY_HH_MM_SS_SLASHED,
  } = DATSystemDateLayoutVariant;
  const key = (DATSystemDateLayoutVariant as any)[code];

  if (key === DEFAULT) {
    return isDate ? ConvertToClientDate(date) : ConvertToClientDatetime(date);
  } else if (key === DD_MM_YY) {
    return `${day}-${month}-${year.toString().slice(2)}`;
  } else if (key === DD_MM_YYYY) {
    return `${day}-${month}-${year}`;
  } else if (key === DD_MM_YYYY_DOTTED) {
    return `${day}.${month}.${year}`;
  } else if (key === MM_DD_YYYY_DOTTED) {
    return `${month}.${day}.${year}`;
  } else if (key === M_D_YY_DOTTED) {
    return `${mouthCut}.${dayCut}.${year}`;
  } else if (key === MM_DD_YYYY_SLASHED) {
    return `${month}/${day}/${year}`;
  } else if (key === MM_DD_YYYY) {
    return `${month}-${day}-${year}`;
  } else if (key === M_D_YY_HH_MM_SS_DOTTED) {
    return `${mouthCut}.${dayCut}.${year} ${time}`;
  } else if (key === MM_DD_HH_MM_SS_YYYY) {
    return `${month}-${day}-${year} ${time}`;
  } else if (key === MM_DD_YYYY_HH_MM_SS_SLASHED) {
    return `${month}/${day}/${year} ${time}`;
  }
  return isDate ? ConvertToClientDate(date) : ConvertToClientDatetime(date);
};

export const ConvertStringOrDateToDate = (value: Date | string): Date => {
  return typeof value === 'string' ? new Date(value) : value;
};

export const GetAllDaysInMonthMap = (year: number, month: number): AllDaysInMonthMap => {
  const date = new Date(year, month, 1);

  const dates: AllDaysInMonthMap = {};

  while (date.getMonth() === month) {
    dates[ConvertToClientDate(new Date(date))] = clone(date);
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const GetAllDaysInRangeMap = (startEndDate: StartEndDate): AllDaysInMonthMap => {
  const { start, end } = startEndDate;
  const date = new Date(start);
  const datesMap: AllDaysInMonthMap = {};

  while (date.getTime() <= end.getTime()) {
    datesMap[ConvertToClientDate(new Date(date))] = clone(date);
    date.setDate(date.getDate() + 1);
  }
  return datesMap;
};

export const GetStartOfTheMinute = (date: Date): Date => {
  const newDate = clone(date);
  newDate.setSeconds(0);
  return newDate;
};

export const GetEndOfTheMinute = (date: Date): Date => {
  const newDate = clone(date);
  newDate.setSeconds(59);
  return newDate;
};

export const GetStartOfTheDay = (day: Date): Date => {
  const newDay = clone(day);
  newDay.setHours(0, 0, 0, 0);
  return newDay;
};

export const GetEndOfTheDay = (day: Date): Date => {
  const newDay = clone(day);
  newDay.setHours(23, 59, 59, 999);
  return newDay;
};

/** @todo Get rid of a side effect */
export const GetDatePlusDays = (date: Date, days: number): Date => {
  date.setDate(date.getDate() + days);
  return date;
};

/** @todo Get rid of a side effect */
export const GetDateMinusDays = (date: Date, days: number): Date => {
  date.setDate(date.getDate() - days);
  return date;
};

export const GetYesterday = (): Date => GetDateMinusDays(new Date(), 1);

export const GetToday = (): Date => new Date();

export const GetTomorrow = (): Date => GetDatePlusDays(new Date(), 1);

export const GetLastMondayDate = (): Date => {
  return GetMondayForDayInWeek(GetDayBeforeWeek());
};

export const GetLastSundayDate = (): Date => {
  return GetSundayForDayInWeek(GetDayBeforeWeek());
};

export const GetMondayOfCurrentWeekDate = (): Date => {
  return GetMondayForDayInWeek(new Date());
};

export const GetSundayOfCurrentWeekDate = (): Date => {
  return GetSundayForDayInWeek(new Date());
};

export const GetNextMondayDate = (): Date => {
  return GetMondayForDayInWeek(GetDayAfterWeek());
};

export const GetNextSundayDate = (): Date => {
  return GetSundayForDayInWeek(GetDayAfterWeek());
};

export const GetMondayForDayInWeek = (day: Date): Date => {
  const dayInWeekNumber = day.getDay();
  const diffToMonday = day.getDate() - dayInWeekNumber + (dayInWeekNumber === 0 ? -6 : 1);
  return new Date(clone(day).setDate(diffToMonday));
};

export const GetSundayForDayInWeek = (day: Date): Date => {
  const dayInWeekNumber = day.getDay();
  const diffToMonday = day.getDate() - dayInWeekNumber + (dayInWeekNumber === 0 ? -6 : 1);
  return new Date(clone(day).setDate(diffToMonday + 6));
};

export const GetDayBeforeWeek = (): Date => {
  return new Date(new Date().getTime() - ONE_WEEK_IN_MILLISECONDS);
};

export const GetDayAfterWeek = (): Date => {
  return new Date(new Date().getTime() + ONE_WEEK_IN_MILLISECONDS);
};

export const GetFirstDayOfLastMonth = (): Date => {
  return new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
};

export const GetLastDayOfLastMonth = (): Date => {
  const lastDay = new Date();
  lastDay.setDate(0);
  return lastDay;
};

export const GetFirstDayOfCurrentMonth = (): Date => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
};

export const GetLastDayOfCurrentMonth = (): Date => {
  return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
};

export const GetFirstDayOfNextMonth = (): Date => {
  return new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
};

export const GetLastDayOfNextMonth = (): Date => {
  return new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0);
};

export const GetLastDayOfMonthAfterMonths = (numberOfMonths: number): Date => {
  return new Date(new Date().getFullYear(), new Date().getMonth() + (numberOfMonths - 1), 0);
};

export const GetFirstDayOfLastYear = (): Date => {
  return new Date(new Date().getFullYear() - 1, 0, 1);
};

export const GetLastDayOfLastYear = (): Date => {
  return new Date(new Date().getFullYear() - 1, 11, 31);
};

export const GetFirstDayOfCurrentYear = (): Date => {
  return new Date(new Date().getFullYear(), 0, 1);
};

export const GetLastDayOfCurrentYear = (): Date => {
  return new Date(new Date().getFullYear(), 11, 31);
};

export const GetFirstDayOfNextYear = (): Date => {
  return new Date(new Date().getFullYear() + 1, 0, 1);
};

export const GetLastDayOfNextYear = (): Date => {
  return new Date(new Date().getFullYear() + 1, 11, 31);
};

export const IsValueISODate = (value: any): boolean => {
  return value && typeof value === 'string' && !!value.match(ISO_STRING_REGEX)?.length;
};

export const IsDateValid = (date: Date): boolean => {
  return !!date && !isNaN(date as any);
};
