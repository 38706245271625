import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WorkflowListTableComponent } from '@private/pages/workflow-management/workflow-list/components/workflow-list-table/workflow-list-table.component';
import { WorkflowCustomRunComponent } from '@private/pages/workflow-management/workflow/components/workflow-custom-run/workflow-custom-run.component';
import { AttributeAutocompleteOverlayComponent } from '@shared/components/context-autocomplete/components';
import { FormulaAutocompleteOverlayComponent } from '@shared/components/context-autocomplete/components/formulajs-autocomplete-overlay/formula-autocomplete-overlay.component';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { WorkflowsComponentsModule } from '@workflows/components';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { WorkflowToTablePipe } from './workflow-list/pipes/workflow-to-table.pipe';
import { WorkflowListComponent } from './workflow-list/workflow-list.component';
import { WorkflowComponent } from './workflow/workflow.component';

@NgModule({
  declarations: [WorkflowListComponent, WorkflowToTablePipe, WorkflowListTableComponent, WorkflowComponent],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    CheckboxModule,
    CommonPipesModule,
    ElvisSharedModule,
    TranslateModule,
    TriStateCheckboxModule,
    RouterLink,
    MultiSelectModule,
    FormsModule,
    WorkflowsComponentsModule,
    LoaderModule,
    AttributeAutocompleteOverlayComponent,
    FormulaAutocompleteOverlayComponent,
    OverlayPanelModule,
    InputTextModule,
    CalendarModule,
    WorkflowCustomRunComponent,
  ],
})
export class WorkflowManagementModule {}
