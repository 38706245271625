import {
  DYNAMIC_ATTRIBUTE_PREFIX,
  DYNAMIC_ATTRIBUTE_SUFFIX,
  WorkflowAction,
  WorkflowActionType,
  WorkflowBEActionTypes,
  WorkflowFEActionTypes,
  WorkflowOwnerType,
  WorkflowRule,
} from '../../types';
import { WorkflowActionFormat } from '../../types/actions/action-format';

export const IsWorkflowFormattingAction = (workflow: WorkflowRule): boolean => {
  return formatOwnerTypes.includes(workflow.ownerType) && workflow.actions.some(action => (action as WorkflowActionFormat).type === WorkflowActionType.FORMAT);
};

const formatOwnerTypes = [WorkflowOwnerType.WIDGET];

export const IsWorkflowActionFE = (action: WorkflowAction): boolean => {
  return action.type in WorkflowFEActionTypes;
};

export const IsWorkflowActionBE = (action: WorkflowAction): boolean => {
  return action.type in WorkflowBEActionTypes;
};

export const GetDynamicNotation = (id: string): string => {
  return `${DYNAMIC_ATTRIBUTE_PREFIX}${id}${DYNAMIC_ATTRIBUTE_SUFFIX}`;
};
