import { LinkResponseDto } from '@api/models/link-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ID_KEY } from '@shared/constants/constants';
import { NewAttribute } from '@shared/types/attribute.types';
import { AggregatedLinks } from '@widgets/card-widget/types/aggregated-links';
import { CardWidgetMode } from '@widgets/card-widget/types/card-widget-mode';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';
import { CardWidgetSettings } from '@widgets/card-widget/types/card-widget-settings';
import { cloneDeep } from 'lodash';
import { lastValueFrom, tap } from 'rxjs';
import { CardWidgetComponent } from '../card-widget.component';

export abstract class AbstractCardWidgetModeStrategy {
  private context: CardWidgetComponent;

  protected constructor(
    protected readonly tenantArtifactService: TenantArtifactService,
    protected readonly tenantLinkService: TenantLinkService,
  ) {}

  protected get widgetMode(): CardWidgetMode {
    return this.settings.widgetMode;
  }

  protected get model(): CardWidgetModel {
    return this.context.m;
  }

  protected get options(): CardWidgetOptions {
    return this.model.options;
  }

  protected get settings(): CardWidgetSettings {
    return this.model.settings;
  }

  init(context: CardWidgetComponent): void {
    this.context = context;
  }

  onModeChange(): void {
    console.warn(this.getWarningMessage('mode change'));
  }

  onArtifactTypeChange(): void {
    console.warn(this.getWarningMessage('artifact type change'));
  }

  protected async loadArtifactExample(): Promise<void> {
    const filter = JSON.stringify({
      artifactTypeId: { $oid: this.settings.artifactTypeId },
      $and: [{ deleted: { $eq: null } }],
    });
    const limit = 1;
    const { data } = await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: { filter, limit } }));
    this.options.artifactOptions.setList(data, ID_KEY);
  }

  protected async loadLinkedArtifacts(): Promise<void> {
    const { outgoing, incoming } = await this.getArtifactExampleLinks();
    this.options.outgoingLinks.setList(outgoing, ID_KEY);
    this.options.incomingLinks.setList(incoming, ID_KEY);

    const $in = [
      ...new Set([
        ...outgoing.map(({ destinationArtifactId }: LinkResponseDto) => destinationArtifactId),
        ...incoming.map(({ sourceArtifactId }: LinkResponseDto) => sourceArtifactId),
      ]),
    ].map(($oid: string) => ({ $oid }));

    const filter = JSON.stringify({ $and: [{ _id: { $in } }] });
    const { data } = await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: { filter } }));

    this.options.linkedArtifacts.setList(data, ID_KEY);
  }

  protected async loadArtifactFiles(): Promise<void> {
    const $in = this.getFileIds().map(($oid: string) => ({ $oid }));
    const filter = JSON.stringify({ _id: { $in } });

    await lastValueFrom(
      this.tenantArtifactService.artifactControllerList({ body: { filter } }).pipe(
        tap(({ data }) => {
          this.options.files.setList(data, ID_KEY);
          this.options.artifactOptions.setList([...this.options.artifactOptions.list.map(item => cloneDeep(item))], ID_KEY);
        }),
      ),
    );
  }

  protected async getArtifactExampleLinks(): Promise<AggregatedLinks> {
    if (!this.options.artifactOptions.loaded || !this.options.artifactOptions.list.length) {
      return { outgoing: [], incoming: [] };
    }

    const $oid = this.model.artifact.id;
    const filter = JSON.stringify({
      $and: [{ $or: [{ destinationArtifactId: { $in: [{ $oid }] } }, { sourceArtifactId: { $in: [{ $oid }] } }] }, { deleted: { $eq: null } }],
    });
    const { data } = await lastValueFrom(this.tenantLinkService.linkControllerList({ body: { filter } }));

    return data.reduce(
      ({ outgoing, incoming }: { incoming: LinkResponseDto[]; outgoing: LinkResponseDto[] }, link: LinkResponseDto) => {
        return {
          outgoing: link.sourceArtifactId === $oid ? [...outgoing, link] : outgoing,
          incoming: link.destinationArtifactId === $oid ? [...incoming, link] : incoming,
        };
      },
      { outgoing: [], incoming: [] },
    );
  }

  protected getFileIds(): string[] {
    const artifact = this.model.artifact;

    if (!artifact) return [];

    const attributeIds = Object.keys(this.options.artifactTypes.listMap[artifact.artifactTypeId].attributes);
    const attributes = this.options.attributes.filterByKey('id', attributeIds);
    const fileAttributes = attributes.filter(({ dataTypeId }: NewAttribute) => {
      return this.options.dataTypes.listMap[dataTypeId].baseDataType === BaseDataType.file;
    });

    const fileIds = new Set<string>();

    fileAttributes.forEach((attribute: NewAttribute) => {
      const fileAttribute = artifact.attributes[attribute.id];

      if (!fileAttribute) {
        return;
      }

      if (Array.isArray(fileAttribute?.value)) {
        fileAttribute.value.forEach((id: string) => fileIds.add(id));
      } else {
        fileIds.add(fileAttribute.value as string);
      }
    });

    return [...fileIds].filter(Boolean);
  }

  private getWarningMessage(event: string): string {
    return `An action for "${event}" event in "${this.widgetMode}" mode is empty`;
  }
}
