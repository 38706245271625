import { RangedStyleValue } from '@shared/components/grid-layout-generator/types/ranged-style-value';
import { RangedStyleValueDto } from '@shared/components/grid-layout-generator/types/ranged-style-value-dto';
import {
  AlignContent,
  AlignItems,
  BackgroundTypeEnum,
  BorderWidthEnum,
  ContainerClassEnum,
  DisplayEnum,
  FlexDirection,
  FlexWrap,
  FontFamilyEnum,
  FontStyleEnum,
  FontWeightEnum,
  JustifyContent,
  OverflowEnum,
  TextDecorationEnum,
  TextHorizontalAlignEnum,
  TextVerticalAlignEnum,
} from '@widgets/shared/types/style.types';

export interface Padding {
  paddingTop: string;
  paddingRight: string;
  paddingBottom: string;
  paddingLeft: string;
}

export interface Margin {
  marginTop: string;
  marginRight: string;
  marginBottom: string;
  marginLeft: string;
}

export interface BorderWidth {
  borderTopWidth: BorderWidthEnum;
  borderRightWidth: BorderWidthEnum;
  borderBottomWidth: BorderWidthEnum;
  borderLeftWidth: BorderWidthEnum;
}

export interface BorderStyle {
  borderTopStyle?: string;
  borderRightStyle?: string;
  borderBottomStyle?: string;
  borderLeftStyle?: string;
}

export interface BorderColor {
  borderTopColor?: string;
  borderRightColor?: string;
  borderBottomColor?: string;
  borderLeftColor?: string;
}

export interface BorderRadius {
  borderTopLeftRadius: string;
  borderTopRightRadius: string;
  borderBottomRightRadius: string;
  borderBottomLeftRadius: string;
  borderRadiusRange: number[];
}

export interface Border extends BorderWidth, BorderStyle, BorderColor, BorderRadius {}

export interface BoxShadow {
  boxShadow: string;
}

export interface Background {
  backgroundType: BackgroundTypeEnum;
  backgroundColor: string;
  backgroundImage?: string;
  backgroundPosition?: string;
  backgroundSize?: string;
  backgroundRepeat?: string;
  backgroundAttachment?: string;
  backgroundOpacity?: string;
  background?: string;
}

export interface BackgroundPseudo {
  '--background-color': string;
  '--background-repeat': string;
  '--background-size': string;
  '--background-position': string;
  '--background-attachment': string;
  '--background-image': string;
  '--opacity': string;
  '--border-style': string;
  '--box-shadow': string;
}

export interface Overflow {
  overflowY: OverflowEnum;
  overflowX: OverflowEnum;
}

export interface ContainerClass {
  containerClass: ContainerClassEnum;
  height?: string;
}

export interface Dimension {
  maxWidth: string;
  minWidth: string;
}

export interface Gap {
  columnGap: string;
  rowGap: string;
}

export interface GridContainer extends Gap {
  gridTemplateColumns: string;
  gridTemplateRows: string;
}

export interface RangedGapDto {
  columnGap: RangedStyleValueDto<string>;
  rowGap: RangedStyleValueDto<string>;
}

export interface RangedGap {
  columnGap: RangedStyleValue<string>;
  rowGap: RangedStyleValue<string>;
}

export interface RangedGridContainerDto extends RangedGapDto {
  gridTemplateColumns: RangedStyleValueDto<string>;
  gridTemplateRows: RangedStyleValueDto<string>;
}

export interface RangedGridContainer extends RangedGap {
  gridTemplateColumns: RangedStyleValue<string>;
  gridTemplateRows: RangedStyleValue<string>;
}

export interface GridItem {
  gridColumnStart: string | number;
  gridColumnEnd: string | number;
  gridRowStart: string | number;
  gridRowEnd: string | number;
}

export interface FlexContainer {
  flexDirection: FlexDirection;
  justifyContent: JustifyContent;
  alignItems: AlignItems;
  flexWrap: FlexWrap;
  alignContent: AlignContent;
  gap: string;
}

export interface FlexChildElement {
  order?: number;
  flexGrow?: number;
  flexShrink?: number;
  flexBasis?: string;
  flex?: string;
  alignSelf?: AlignItems;
  justifySelf?: JustifyContent;
}

export interface ElementDisplay {
  display: DisplayEnum;
}

export interface TextFormatStyles {
  fontWeight: FontWeightEnum;
  fontStyle: FontStyleEnum;
  fontSize: string;
  textDecoration: TextDecorationEnum;
  textAlign: TextHorizontalAlignEnum;
  verticalAlign: TextVerticalAlignEnum;
  color?: string;
  fontFamily: FontFamilyEnum;
  prefix?: string;
  suffix?: string;
  minDecimalPlaces?: number;
  maxDecimalPlaces?: number;
}

export interface StylesDto
  extends Padding,
    Margin,
    Background,
    BackgroundPseudo,
    Border,
    BoxShadow,
    Overflow,
    ContainerClass,
    Dimension,
    GridContainer,
    FlexContainer,
    ElementDisplay,
    TextFormatStyles,
    FlexChildElement {}

export type StylesDtoKeys = keyof StylesDto;

// Padding
export const PaddingStylesDtoKeysArray: StylesDtoKeys[] = ['paddingTop', 'paddingRight', 'paddingBottom', 'paddingLeft'];

// Margin
export const MarginStylesDtoKeysArray: StylesDtoKeys[] = ['marginTop', 'marginRight', 'marginBottom', 'marginLeft'];

// Background
export const BackgroundStylesDtoKeysArray: StylesDtoKeys[] = [
  'backgroundType',
  'backgroundColor',
  'backgroundImage',
  'backgroundPosition',
  'backgroundSize',
  'backgroundRepeat',
  'backgroundAttachment',
  'backgroundOpacity',
  'background',
];

// BackgroundPseudo
export const BackgroundPseudoStylesDtoKeysArray: StylesDtoKeys[] = [
  '--background-color',
  '--background-repeat',
  '--background-size',
  '--background-position',
  '--background-attachment',
  '--background-image',
  '--opacity',
  '--border-style',
  '--box-shadow',
];

// Border (includes BorderWidth, BorderStyle, BorderColor, and BorderRadius)
export const BorderStylesDtoKeysArray: StylesDtoKeys[] = [
  // BorderWidth
  'borderTopWidth',
  'borderRightWidth',
  'borderBottomWidth',
  'borderLeftWidth',
  // BorderStyle
  'borderTopStyle',
  'borderRightStyle',
  'borderBottomStyle',
  'borderLeftStyle',
  // BorderColor
  'borderTopColor',
  'borderRightColor',
  'borderBottomColor',
  'borderLeftColor',
  // BorderRadius
  'borderTopLeftRadius',
  'borderTopRightRadius',
  'borderBottomRightRadius',
  'borderBottomLeftRadius',
  'borderRadiusRange',
];

// BoxShadow
export const BoxShadowStylesDtoKeysArray: StylesDtoKeys[] = ['boxShadow'];

// Overflow
export const OverflowStylesDtoKeysArray: StylesDtoKeys[] = ['overflowY', 'overflowX'];

// ContainerClass
export const ContainerClassStylesDtoKeysArray: StylesDtoKeys[] = ['containerClass', 'height'];

// Dimension
export const DimensionStylesDtoKeysArray: StylesDtoKeys[] = ['maxWidth', 'minWidth'];

// GridContainer (includes Gap keys)
export const GridContainerStylesDtoKeysArray: StylesDtoKeys[] = ['columnGap', 'rowGap', 'gridTemplateColumns', 'gridTemplateRows'];

// FlexContainer
export const FlexContainerStylesDtoKeysArray: StylesDtoKeys[] = ['flexDirection', 'justifyContent', 'alignItems', 'flexWrap', 'alignContent', 'gap'];

// FlexChildElement
export const FlexChildElementStylesDtoKeysArray: StylesDtoKeys[] = ['order', 'flexGrow', 'flexShrink', 'flexBasis', 'flex', 'alignSelf', 'justifySelf'];

// ElementDisplay
export const ElementDisplayStylesDtoKeysArray: StylesDtoKeys[] = ['display'];

// TextFormatStyles
export const TextFormatStylesDtoKeysArray: StylesDtoKeys[] = [
  'fontWeight',
  'fontStyle',
  'fontSize',
  'textDecoration',
  'textAlign',
  'verticalAlign',
  'color',
  'fontFamily',
  'prefix',
  'suffix',
  'minDecimalPlaces',
  'maxDecimalPlaces',
];

export const ChipFormatStyles: StylesDtoKeys[] = ['fontFamily', 'fontSize', 'fontStyle', 'fontWeight', 'color'];
