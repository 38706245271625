import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NewDataType } from '@shared/types/data-type.types';
import { DATBooleanEditVariant, DEFAULT_VARIANT_KEY } from '@shared/types/display-at-types';
import { AbstractDisplayAtComponent } from '../abstract-display-at.component';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';

@Component({
  selector: 'app-display-at-boolean',
  templateUrl: './display-at-boolean.component.html',
  styleUrls: ['display-at-boolean.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayAtBooleanComponent extends AbstractDisplayAtComponent {
  @Input() dataType: NewDataType;
  @Input() formatSettings: AttributeFormatSettings;

  handleValueChange() {
    const enumKey = this.selectedEditVariant as keyof typeof DATBooleanEditVariant;
    if (DATBooleanEditVariant[enumKey] !== DATBooleanEditVariant.DEFAULT) {
      const currentValue = this.artifact.attributes[this.attributeId].value;
      const emptyAfterLast = DATBooleanEditVariant[enumKey] === DATBooleanEditVariant.TOGLE_3_STATE;
      const nextValue = this.dataTypeHelper.getNextBooleanValue(currentValue, emptyAfterLast);
      this.displayAtControlService.doUpdateValueSelection(this.ownerId, this.artifact, this.attributeId, nextValue);
      if (this.doInternalUpdateOfValue) {
        this.artifact.attributes[this.attributeId].value = nextValue;
        this.cdr.markForCheck();
      }
    }
  }

  get is2State(): boolean {
    const value = DATBooleanEditVariant[this.code as keyof typeof DATBooleanEditVariant];
    return value === DATBooleanEditVariant.TOGGLE_2_STATE;
  }

  get code(): string {
    return this.formatSettings?.value?.displayMetadata?.editVariantCode || DEFAULT_VARIANT_KEY;
  }
}
