import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { EDITOR_KEY, EMAIL_KEY } from '@shared/constants/constants';
import { TinyMceHelper } from '@shared/helpers/tiny-mce.helper';
import { SharedMethods } from '@shared/methods/shared.methods';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ClientData } from '@shared/types/local-storage.types';
import { SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { EditorModule } from '@tinymce/tinymce-angular';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';

// TODO: this component shouldn't probably even exist, we could re-use attribute-value-edit-field. Check this.

@Component({
  selector: 'app-artifact-attribute-value-edit-field',
  templateUrl: './artifact-attribute-value-edit-field.component.html',
  styleUrls: ['./artifact-attribute-value-edit-field.component.scss'],
})
export class ArtifactAttributeValueEditFieldComponent implements OnInit {
  @Input() clientAttribute: NewClientAttribute;
  @Input() fullDataType: NewDataType;
  @Input() fullAttribute: NewAttribute;
  @Input() disabledField: boolean;
  @Input() index: number;

  settings: any;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  firstDayOfWeek: number;
  userOptions: SelectOption<any, any>[] = [];
  labelBehaviourEnum = LabelBehaviourEnum;
  options: Array<DataTypeValueResponseDto> | undefined;
  uniqueId = '';
  editorKey = EDITOR_KEY;

  constructor(private readonly cache: NewCacheService, private readonly elvisUtil: ElvisUtil, private readonly tinyMceHelper: TinyMceHelper) {}

  get isSimple(): boolean {
    return this.fullDataType.kind === DataTypeKind.simple;
  }

  get isEnumerated(): boolean {
    return this.fullDataType.kind === DataTypeKind.enumerated;
  }

  get isInteger(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.integer;
  }

  get isDecimal(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.decimal;
  }

  get isHyperlink(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.hyperlink;
  }

  get isHtml(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.html;
  }

  get isTime(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.time;
  }

  get isDatetime(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.dateTime;
  }

  get isUser(): boolean {
    return this.fullDataType.baseDataType === BaseDataType.user;
  }

  get minDate(): Date | undefined {
    return this.fullDataType.minimum ? new Date(this.fullDataType.minimum) : undefined;
  }

  get maxDate(): Date | undefined {
    return this.fullDataType.maximum ? new Date(this.fullDataType.maximum) : undefined;
  }

  async ngOnInit(): Promise<void> {
    if (this.clientAttribute) {
      if (this.isUser) {
        this.fillUserOptions();
      }
      if (this.clientAttribute && this.isEnumerated && !this.fullAttribute.multipleValues) {
        this.options = this.fullDataType.values;
      }

      if (this.isHtml && this.isSimple && !this.fullAttribute.multipleValues) {
        this.settings = this.tinyMceHelper.getTinyMceAdvancedSettings();
        this.settings.inline = false;
        this.uniqueId = SharedMethods.getUniqueId();
      }
    }

    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;
  }

  async checkNumFormat(event: string, id: string, targetObject: any, key: string): Promise<void> {
    this.elvisUtil.checkNumFormat(event, id, targetObject, key, this.isInteger, this.isDecimal);
  }

  async checkNumFormatMultiple(): Promise<void> {
    const checkNum = (startValue: string): string => {
      const decimal = /^[0-9]{0,}([.][0-9]{0,})?$/g;
      const integer = /^[0-9]{0,}$/g;
      let value = startValue;

      if (this.isInteger && !integer.test(value)) {
        value = value.substr(0, value.length - 1);
        return checkNum(value);
      } else if (this.isDecimal && !decimal.test(value)) {
        value = value.substr(0, value.length - 1);
        return checkNum(value);
      }
      return value;
    };
    this.clientAttribute.value = this.clientAttribute.value.map((value: string) => checkNum(value)).filter((item: any) => !!item);
  }

  async onNumericMultipleFieldChange(event: string, index: number, artifactTypeAttribute: any): Promise<void> {
    await this.checkNumFormat(event, 'intField' + index, artifactTypeAttribute, 'value');
  }

  async onNumericFieldChange(): Promise<void> {
    await this.checkNumFormatMultiple();
  }

  private fillUserOptions(): void {
    const userOptions = this.elvisUtil.transformAnyToSelectOptions([], EMAIL_KEY);

    let invalidUsers = [];
    if (this.clientAttribute.value) {
      invalidUsers = this.clientAttribute.value
        ?.filter((option: SelectOption<string, any>) => !userOptions.find(item => item.value.id === option.value.id))
        .map((option: SelectOption<string, any>) => new SelectOption<string, string>('<Invalid system-user>', option.value.id));
      this.clientAttribute.value = this.clientAttribute.value.map((user: SelectOption<string, any>) => this.fillUserOption(user));
    }

    this.userOptions = [...userOptions, ...invalidUsers];
  }

  private fillUserOption(item: SelectOption<string, any>): any {
    return this.userOptions.find(option => {
      if (option.value && option.value.id) return option.value.id === item.value.id;
      else return option.value === item.value.id;
    });
  }
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ChipsModule,
    MultiSelectModule,
    SliderModule,
    DropdownModule,
    InputTextareaModule,
    CalendarModule,
    CheckboxModule,
    EditorModule,
    DataTypePipesModule,
  ],
  exports: [ArtifactAttributeValueEditFieldComponent],
  declarations: [ArtifactAttributeValueEditFieldComponent],
})
export class AppArtifactAttributeValueEditFieldModule {}
