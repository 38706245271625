import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AutocompleteBaseEvent, AutocompleteEventType, AutocompleteInitEvent, AutocompleteSelectionEvent } from '../types/base/autocomplete.types';

@Injectable({ providedIn: 'root' })
export class AutocompleteService<T> {
  private readonly initSubject: Subject<AutocompleteInitEvent<T>>;
  private readonly initObservable: Observable<AutocompleteInitEvent<T>>;
  private readonly selectionSubject: Subject<AutocompleteSelectionEvent<T>>;
  private readonly selectionObservable: Observable<AutocompleteSelectionEvent<T>>;
  private readonly closeSubject: Subject<AutocompleteBaseEvent>;
  private readonly closeObservable: Observable<AutocompleteBaseEvent>;

  protected constructor() {
    this.initSubject = new Subject();
    this.initObservable = this.initSubject.asObservable();
    this.selectionSubject = new Subject();
    this.selectionObservable = this.selectionSubject.asObservable();
    this.closeSubject = new Subject();
    this.closeObservable = this.closeSubject.asObservable();
  }

  get autocompleteInit$(): Observable<AutocompleteInitEvent<T>> {
    return this.initObservable;
  }

  get autocompleteSelection$(): Observable<AutocompleteSelectionEvent<T>> {
    return this.selectionObservable;
  }

  get autocompleteClose$(): Observable<AutocompleteBaseEvent> {
    return this.closeObservable;
  }

  notifyAutocompleteClose(ownerId: string, type: AutocompleteEventType): void {
    this.closeSubject.next({ ownerId, type });
  }

  notifyAutocompleteInit(initEvent: AutocompleteInitEvent<T>): void {
    this.initSubject.next(initEvent);
  }

  notifyAutocompleteSelection(ownerId: string, selectedOption: T, initEvent: AutocompleteInitEvent<T>): void {
    this.selectionSubject.next({ ownerId, selectedOption, initEvent, type: initEvent.type });
  }
}
