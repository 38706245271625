//TODO create exported object "Constants"
import { NonAttributeKeys } from '@shared/types/attribute.types';

export const TOKEN_KEY = 'token';
export const USER_KEY = 'user';
export const TENANTS_KEY = 'tenants';
export const SELECTED_TENANT_KEY = 'selectedTenant';
export const LANGUAGE_KEY = 'language';
export const UI_CONFIG_KEY = 'uiConfig';
export const CLIENT_DATA_KEY = 'clientData';
export const SIDEBAR_MODAL_STATE = 'sidebarModalState';
export const EMPTY_FILTER_VALUE_SINGLE = '';
export const EMPTY_FILTER_VALUE_MULTIPLE = [];
export const EMPTY_GROUP_VALUE = 'empty';
export const DELETED_USER_VALUE = 'deleted user';
export const EMPTY_GROUP_HEADER_SUFFIX = 'unassigned';
export const NOT_MATCHING_GROUP_VALUE = "doesn't match any group";
export const CURRENT_USER_OPTION_LABEL = '[current system-user]';
export const CURRENT_USER_URL_FILTER_VALUE = 'current-user';
export const CURRENT_USER_TEAMS_LABEL = "[current user's teams]";
export const BLANK_FILTER_OPTION_LABEL = '[Is empty]';
export const BLANK_OPTION_FILTER_URL_VALUE = 'blank';
export const EMPTY_OPTION_FILTER_URL_VALUE = 'empty';
export const IS_NOT_EMPTY_OPTION_FILTER_LABEL = '[Is not empty]';
export const IS_NOT_EMPTY_OPTION_FILTER_VALUE = 'Is not empty';
export const ALL_OPTIONS_FILTER_VALUE = 'all';
export const ERROR_GETTING_APPLICATION = '[ERROR GETTING APPLICATION]';
export const FILTER_OPERATOR_AND = 'and';
export const FILTER_OPERATOR_OR = 'or';
export const ARTIFACTS_ID_KEY = 'artifacts_id';
export const ARTIFACT_TYPE_KEY = 'artifacttype';
export const FOLDER_FILTER_KEY = 'folderId';
export const URL_KEY_VALUE_ARTIFACT_ID = 'artifactId';
export const URL_KEY_VALUE_TEMPLATE_ID = 'templateId';
export const URL_KEY_VALUE_SAVE_TO_FOLDER_ID = 'folderId';
export const URL_KEY_MODULE_INSERT_PARENT_ID = 'moduleInsertParentId';
export const URL_KEY_MODULE_INSERT_INTO = 'moduleInsertInto';
export const URL_KEY_MODULE_INSERT_IS_HEADING = 'moduleInsertIsHeading';
export const HTTP_HTTPS_VALIDATION_REGEX = /^(http|https):\/\/.*$/;
export const MONGO_ID_REGEX = /[0-9a-fA-F]{24}/;
export const DYNAMIC_ATTRIBUTE_NOTATION_REGEX = /{attributes\["[0-9a-fA-F]{24}"]\.value}/;
export const INVALID_USER_LABEL = '<Invalid user>';

export const CREATED_ON_LABEL = 'Created on';
export const CREATED_ON_KEY = 'created.on';
export const CREATED_BY_LABEL = 'Created by';
export const CREATED_BY_KEY = 'created.by';
export const UPDATED_ON_LABEL = 'Updated on';
export const UPDATED_ON_KEY = 'updated.on';
export const UPDATED_BY_LABEL = 'Updated by';
export const UPDATED_BY_KEY = 'updated.by';
export const CREATED_BY_EMAIL_KEY = 'created.by.email';
export const UPDATED_BY_EMAIL_KEY = 'updated.by.email';
export const FILENAME_LABEL = 'Filename';
export const FILENAME_KEY = 'filename';
export const SIZE_LABEL = 'Size';
export const SIZE_KEY = 'size';
export const ID_LABEL = 'ID';
export const ID_KEY = 'id';
export const RESULT_LABEL = 'Result';
export const RESULT_KEY = 'result';
export const STATUS_LABEL = 'Status';
export const STATUS_KEY = 'status';
export const NAME_LABEL = 'Name';
export const NAME_KEY = 'name';
export const TENANT_LABEL = 'Tenant';
export const TENANT_KEY = 'tenant';
export const EMAIL_LABEL = 'Email';
export const EMAIL_KEY = 'email';
export const USER_NAME_LABEL = 'User name';
export const USER_NAME_KEY = 'username';
export const PHONE_LABEL = 'Phone';
export const PHONE_KEY = 'phone';
export const IS_SYSTEM_ADMIN_LABEL = 'Is system admin';
export const IS_SYSTEM_ADMIN_KEY = 'isSystemAdmin';
export const IS_TENANT_ADMIN_LABEL = 'Is tenant admin';
export const IS_TENANT_ADMIN_KEY = 'tenant.isAdmin';
export const IS_DELETED_LABEL = 'Is deleted';
export const DELETED_LABEL = 'Deleted';
export const DELETED_KEY = 'deleted';
export const ICON_LABEL = 'Icon';
export const ICON_KEY = 'icon';
export const APPLICATION_LABEL = 'Application';
export const APPLICATION_ID_KEY = 'applicationId';
export const IS_PUBLIC_LABEL = 'Is public';
export const IS_PUBLIC_KEY = 'isPublic';
export const ALIAS_LABEL = 'Alias';
export const ALIAS_KEY = 'alias';
export const LINK_TYPE_LABEL = 'Link type';
export const LINK_TYPE_KEY = 'linkType';
export const FORMAT_LABEL = 'Format';
export const FORMAT_KEY = 'format';
export const IS_LINK_REQUIRED_LABEL = 'Required';
export const IS_LINK_REQUIRED_KEY = 'isLinkRequired';
export const LINK_REQUIRED_KEY = 'linkRequired';
export const SOURCE_ARTIFACT_TYPES_LABEL = 'Source artifact types';
export const SOURCE_ARTIFACT_TYPES_KEY = 'sourceArtifactTypeIds';
export const RELATION_TYPE_LABEL = 'Relation type';
export const RELATION_TYPE_KEY = 'relationType';
export const DESTINATION_ARTIFACT_TYPES_LABEL = 'Destination artifact types';
export const DESTINATION_ARTIFACT_TYPES_KEY = 'destinationArtifactTypeIds';
export const AVR_TYPE_FIELD_NAME = 'AVR Type Field Name';
export const AVR_TYPE_FIELD_NAME_KEY = 'avr.type.field.name';
export const AVR_TYPE_FIELD_VALUE = 'AVR Type Field Value';
export const AVR_TYPE_FIELD_VALUE_KEY = 'avr.type.field.value';
export const ATTRIBUTE_NAME_KEY = 'attribute.name';
export const DATA_TYPE_LABEL = 'Data type';
export const DATA_TYPE_KEY = 'baseDataType';
export const DATA_TYPE_KIND_LABEL = 'Kind';
export const DATA_TYPE_KIND_KEY = 'dataTypeKind';
export const ATTRIBUTE_DATA_TYPE_NAME_KEY = 'attribute.dataType.name';
export const NO_VALUE = 'No value';
export const INITIAL_VALUE_LABEL = 'Initial value';
export const INITIAL_VALUE_KEY = 'initialValue';
export const IS_MANDATORY_LABEL = 'Is mandatory';
export const IS_MANDATORY_KEY = 'isMandatory';
export const ARTIFACT_TYPE_LABEL = 'Artifact type';
export const TARGET_LABEL = 'Target';
export const TARGET_KEY = 'target';
export const DESCRIPTION_LABEL = 'Description';
export const DESCRIPTION_KEY = 'description';
export const PRIMARY_TEXT_LABEL = 'Primary text';
export const PRIMARY_TEXT_KEY = 'primary text';
export const INCOMING_NAME_LABEL = 'Incoming name';
export const INCOMING_NAME_KEY = 'incomingName';
export const OUTGOING_NAME_LABEL = 'Outgoing name';
export const OUTGOING_NAME_KEY = 'outgoingName';
export const SOURCE_NAME = 'Source';
export const SOURCE_KEY = 'sourceArtifactTypeId';
export const DESTINATION_LABEL = 'Destination';
export const DESTINATION_KEY = 'destinationArtifactTypeId';
export const NOTIFY_SOURCE_LABEL = 'Notify source';
export const NOTIFY_SOURCE_KEY = 'notifySource';
export const NOTIFY_DESTINATION_LABEL = 'Notify destination';
export const NOTIFY_DESTINATION_KEY = 'notifyDestination';
export const WORKFLOW_TYPE_LABEL = 'Type';
export const WORKFLOW_TYPE_KEY = 'type';
export const WORKFLOW_OWNER_TYPE_LABEL = 'Owner type';
export const WORKFLOW_OWNER_TYPE_KEY = 'ownerType';
export const TYPE_LABEL = 'Type';
export const TYPE_KEY = 'type';
export const UNRESOLVED_VALUE = '[Unresolved]';
export const INITIAL_COLUMN_GAP = '0px';
export const INITIAL_ROW_GAP = '0px';
export const INITIAL_GRID_COLUMN_SIZE = '1fr';
export const INITIAL_GRID_ROW_SIZE = '100px';
export const INACCESSIBLE_APPLICATION_NAME = '[Inaccessible App]';
export const FOLDER_PATH_SEPARATOR = '/';
export const AUTOCOMPLETE_CONTAINER_CLASS = 'autocomplete-list-container';
export const ISO_FORMAT_WITH_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
export const WINDOW_TARGET_SELF = '_self';
export const WINDOW_TARGET_BLANK = '_blank';

export const Constants = Object.freeze({
  page: 'page',
  token: 'token',
  user: 'user',
  tenants: 'tenants',
  selectedTenant: 'selectedTenant',
  selectedApplication: 'selectedApplication',
  language: 'language',
  uiConfig: 'uiConfig',
  clientData: 'clientData',
  emptyFilterValueSingle: '',
  emptyFilterValueMultiple: [],
  primaryAttributesDefaultId: '617474726962757465736964',
  primaryAttributesArtifactTypeId: '617274696661637474797065',
  buildHash: 'buildHash',
  artifactCustomAttributes: [
    { code: ARTIFACTS_ID_KEY, name: 'Artifact ID', value: '6172746966616374735f6964' },
    {
      code: NonAttributeKeys.CREATED_BY,
      name: NonAttributeKeys.CREATED_BY_LABEL,
      value: NonAttributeKeys.CREATED_BY_ID,
    },
    {
      code: NonAttributeKeys.UPDATED_BY,
      name: NonAttributeKeys.UPDATED_BY_LABEL,
      value: NonAttributeKeys.UPDATED_BY_ID,
    },
    {
      code: NonAttributeKeys.CREATED_ON,
      name: NonAttributeKeys.CREATED_ON_LABEL,
      value: NonAttributeKeys.CREATED_ON_ID,
    },
    {
      code: NonAttributeKeys.UPDATED_ON,
      name: NonAttributeKeys.UPDATED_ON_LABEL,
      value: NonAttributeKeys.UPDATED_ON_ID,
    },
    {
      code: ARTIFACT_TYPE_KEY,
      name: 'Artifact Type',
      value: '617274696661637474797065',
    },
    {
      code: NonAttributeKeys.FOLDER_PATH,
      name: NonAttributeKeys.FOLDER_PATH_LABEL,
      value: NonAttributeKeys.FOLDER_PATH_ID,
    },
    {
      code: NonAttributeKeys.SECTION_ATTRIBUTE,
      name: NonAttributeKeys.SECTION_ATTRIBUTE_LABEL,
      value: NonAttributeKeys.SECTION_ATTRIBUTE_ID,
    },
  ],
  fileArtifactCustomAttributes: [
    {
      code: NonAttributeKeys.FILE_ARTIFACT_PATH,
      name: NonAttributeKeys.FILE_ARTIFACT_LABEL,
      value: NonAttributeKeys.FILE_ARTIFACT_PATH_ID,
    },
  ],
  cssShadowGeneratorLink: 'https://html-css-js.com/css/generator/box-shadow/',
  smBreakpoint: 426,
  mdBreakpoint: 768,
});
