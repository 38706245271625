import { NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonDirective } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { AclItemType } from '../../../../../../widgets/shared/components/acl/types/acl.types';
import { CreateArtifactsKeys } from '../../../../../types/actions/action-create-artifacts';
import { WorkflowActionInsertAcl, WorkflowInsertAclTypes } from '../../../../../types/actions/action-insert-acl';

@Component({
  selector: 'app-action-insert-acl',
  standalone: true,
  imports: [DropdownModule, TranslateModule, FormsModule, InputTextModule, CheckboxModule, ButtonDirective, NgForOf, CalendarModule, FloatLabelModule],
  templateUrl: './action-insert-acl.component.html',
  styleUrl: './action-insert-acl.component.scss',
})
export class ActionInsertAclComponent {
  @Input() action: WorkflowActionInsertAcl;

  protected readonly aclTypes: WorkflowInsertAclTypes[];
  protected readonly entityTypes: AclItemType[];
  protected readonly RuleKeys = CreateArtifactsKeys;
  protected readonly AclItemType = AclItemType;

  constructor() {
    this.aclTypes = Object.values(WorkflowInsertAclTypes);
    this.entityTypes = Object.values(AclItemType);
  }

  onIsIdDynamicChange(): void {}

  onAddEntityRow(): void {
    this.action.entitiesArray.push({ id: '', type: AclItemType.user, expirationDate: null });
  }

  onRemoveEntityRow(index: number): void {
    this.action.entitiesArray.splice(index, 1);
  }
}
