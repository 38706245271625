<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'Id' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      id="isIdDynamic" binary="true" class="mr-2 pt-2"
      [(ngModel)]="action.actionSettings.id.isDynamic"
      (onChange)="onIsIdDynamicChange()"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input id="idInput" [(ngModel)]="action.actionSettings.id.value" pInputText type="text" [placeholder]="'Enter id' | translate" class="w-100" />
  </div>
</div>

<div class="row mt-1">
  <div class="col-4 text-bold">
    {{ 'For each path' | translate }}
  </div>
  <div class="col-8">
    <input id="forEachPathInput" [(ngModel)]="action.actionSettings.forEachPath.value" pInputText type="text" [placeholder]="'Enter for-each-path' | translate"
           class="w-100" />
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Method' | translate }}
  </div>
  <div class="col-8">
    <p-dropdown [(ngModel)]="action.actionSettings.aclType.value" [options]="aclTypes" appendTo="body"></p-dropdown>
  </div>
</div>

<div class="row pt-4">
  <div class="col-2 text-bold">
    {{ 'Entities' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings.entities.isDynamic" id="isEntitiesDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let attribute of action.entitiesArray; let index = index">
      <div class="record-row">
        <p-floatLabel>
          <p-dropdown [id]="'entityType' + index" [(ngModel)]="attribute.type" [options]="entityTypes" appendTo="body"></p-dropdown>
          <label [for]="'entityType' + index" translate>Entity type</label>
        </p-floatLabel>

        <p-floatLabel>
          <input [id]="'entityId' + index" [(ngModel)]="attribute.id" pInputText type="text" class="w-100" />
          @if (attribute.type === AclItemType.user) {
            <label [for]="'entityId' + index" translate> User id </label>
          } @else {
            <label [for]="'entityId' + index" translate> Team id </label>
          }
        </p-floatLabel>

        <p-floatLabel>
          <p-calendar [id]="'expirationDate' + index" [(ngModel)]="attribute.expirationDate" [showTime]="true" [showClear]="true"
                      [style]="{'width':'100%'}"></p-calendar>
          <label [for]="'expirationDate' + index" translate>Expiration date (optional)</label>
        </p-floatLabel>

        <div class="delete-entity-container">
          <button [id]="'deleteEntity' + index" pButton class="p-button-text p-button-danger" icon="pi pi-trash" (click)="onRemoveEntityRow(index)"></button>
        </div>
      </div>
    </div>
    <button class="p-button p-button-text pl-0" pButton type="button" icon="pi pi-plus" (click)="onAddEntityRow()" label="Add entity"></button>
  </div>
</div>
