import { Injectable } from '@angular/core';
import { TenantUserService } from '@api/services/tenant-user.service';
import { ColorScheme, UiConfig } from '@private/pages/profile/types/user.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { CLIENT_DATA_KEY, Constants, UI_CONFIG_KEY } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { HasUiConfig } from '@shared/types/has-ui-config.types';
import { StateKey } from '@shared/types/local-storage.types';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfigService<T extends HasUiConfig> {
  app: T;
  private readonly menuThemes: any[];
  private readonly componentThemes: any[];

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly tenantUserService: TenantUserService,
    private readonly announcement: AnnouncementService,
    private readonly cache: NewCacheService,
  ) {
    this.menuThemes = [
      { name: 'white', color: '#ffffff', logoColor: 'dark', componentTheme: 'blue' },
      { name: 'darkgray', color: '#343a40', logoColor: 'white', componentTheme: 'blue' },
      { name: 'blue', color: '#1976d2', logoColor: 'white', componentTheme: 'blue' },
      { name: 'bluegray', color: '#455a64', logoColor: 'white', componentTheme: 'lightgreen' },
      { name: 'brown', color: '#5d4037', logoColor: 'white', componentTheme: 'cyan' },
      { name: 'cyan', color: '#0097a7', logoColor: 'white', componentTheme: 'cyan' },
      { name: 'green', color: '#388e3C', logoColor: 'white', componentTheme: 'green' },
      { name: 'indigo', color: '#303f9f', logoColor: 'white', componentTheme: 'indigo' },
      { name: 'deeppurple', color: '#512da8', logoColor: 'white', componentTheme: 'deeppurple' },
      { name: 'orange', color: '#F57c00', logoColor: 'dark', componentTheme: 'orange' },
      { name: 'pink', color: '#c2185b', logoColor: 'white', componentTheme: 'pink' },
      { name: 'purple', color: '#7b1fa2', logoColor: 'white', componentTheme: 'purple' },
      { name: 'teal', color: '#00796b', logoColor: 'white', componentTheme: 'teal' },
    ];
    this.componentThemes = [
      { name: 'blue', color: '#42A5F5' },
      { name: 'green', color: '#66BB6A' },
      { name: 'lightgreen', color: '#9CCC65' },
      { name: 'purple', color: '#AB47BC' },
      { name: 'deeppurple', color: '#7E57C2' },
      { name: 'indigo', color: '#5C6BC0' },
      { name: 'orange', color: '#FFA726' },
      { name: 'cyan', color: '#26C6DA' },
      { name: 'pink', color: '#EC407A' },
      { name: 'teal', color: '#26A69A' },
    ];
  }

  public setApp(app: T): void {
    this.app = app;
  }

  uiChange(uiConfig: UiConfig): void {
    const sessionState = this.localStorageService.get(StateKey.session);
    this.localStorageService.setToState(StateKey.session, CLIENT_DATA_KEY, { ...sessionState[CLIENT_DATA_KEY], [UI_CONFIG_KEY]: uiConfig });

    lastValueFrom(
      this.tenantUserService.userControllerUpdateClientData({
        body: {
          uiConfig: uiConfig,
          language: sessionState[Constants.clientData][Constants.language],
        },
      }),
    ).catch(() => this.announcement.error('Ui configuration could not be saved'));
  }

  changeColorScheme(scheme: ColorScheme): void {
    this.app.uiConfig.colorScheme = scheme;
    this.changeStyleSheetsColor('layout-css', 'layout-' + scheme + '.css', 1);
    this.changeStyleSheetsColor('theme-css', 'theme-' + scheme + '.css', 1);

    const mobileLogoLink: HTMLImageElement = document.getElementById('logo-mobile') as HTMLImageElement;
    // const invoiceLogoLink: HTMLImageElement = document.getElementById('invoice-logo') as HTMLImageElement;
    const footerLogoLink: HTMLImageElement = document.getElementById('footer-logo') as HTMLImageElement;
    if (mobileLogoLink && footerLogoLink) {
      if (scheme === 'light') {
        mobileLogoLink.src = 'assets/layout/images/logo-dark.svg';
        // invoiceLogoLink.src = 'assets/layout/images/logo-dark.svg';
        footerLogoLink.src = 'assets/layout/images/logo-dark.svg';
      } else {
        mobileLogoLink.src = 'assets/layout/images/logo-white.svg';
        // invoiceLogoLink.src = 'assets/layout/images/logo-white.svg';
        footerLogoLink.src = 'assets/layout/images/logo-white.svg';
      }
    }
  }

  changeMenuTheme(name: string, logoColor: ColorScheme): void {
    this.app.uiConfig.menuTheme = 'layout-sidebar-' + name;
    this.app.uiConfig.logoColor = logoColor;

    this.uiChange(this.app.uiConfig);

    const appLogoLink: HTMLImageElement = document.getElementById('app-logo') as HTMLImageElement;
    if (!appLogoLink) return;

    if (logoColor === 'dark') {
      appLogoLink.src = 'assets/layout/images/logo-dark.svg';
    } else {
      appLogoLink.src = 'assets/layout/images/logo-white.svg';
    }
  }

  changeComponentTheme(theme: string): void {
    this.app.uiConfig.componentTheme = theme;
    this.changeStyleSheetsColor('theme-css', theme, 3);
    this.uiChange(this.app.uiConfig);
  }

  getMenuThemes(): any[] {
    return this.menuThemes;
  }

  getComponentThemes(): any[] {
    return this.componentThemes;
  }

  initStyleSheets(uiConfig: UiConfig): void {
    const theme = document.getElementById('theme-css');
    const layout = document.getElementById('layout-css');
    if (theme && layout) {
      const themeUrlTokens = theme.getAttribute('href')?.split('/');
      const layoutUrlTokens = layout.getAttribute('href')?.split('/');

      if (themeUrlTokens?.length && layoutUrlTokens?.length) {
        themeUrlTokens[2] = uiConfig.componentTheme;
        themeUrlTokens[3] = 'theme-' + uiConfig.colorScheme + '.css';
        layoutUrlTokens[3] = 'layout-' + uiConfig.colorScheme + '.css';

        this.replaceLink(theme, themeUrlTokens.join('/'));
        this.replaceLink(layout, layoutUrlTokens.join('/'));
      }
    }
  }

  changeStyleSheetsColor(id: string, value: string, from: number): void {
    const element = document.getElementById(id);
    const urlTokens = element?.getAttribute('href')?.split('/');
    if (urlTokens?.length) {
      if (from === 1) {
        // which function invoked this function
        urlTokens[urlTokens.length - 1] = value;
      } else if (from === 2) {
        // which function invoked this function
        if (value !== null) {
          urlTokens[urlTokens.length - 2] = value;
        }
      } else if (from === 3) {
        // which function invoked this function
        urlTokens[urlTokens.length - 2] = value;
      }

      this.replaceLink(element, urlTokens.join('/'));
    }
  }

  replaceLink(linkElement: HTMLElement | null, href: string): void {
    if (linkElement) {
      if (this.isIe()) {
        linkElement.setAttribute('href', href);
      } else {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        this.setNodeAttribute(cloneLinkElement, 'href', href);
        this.setNodeAttribute(cloneLinkElement, 'id', id + '-clone');

        linkElement.parentNode?.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
          linkElement.remove();
          this.setNodeAttribute(cloneLinkElement, 'id', id || '');
        });
      }
    }
  }

  isIe(): boolean {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  private setNodeAttribute(node: Node, name: string, value: string): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    node?.setAttribute(name, value);
  }
}
