import { WidgetModel } from '../../shared/types/widget-model.interface';
import { PictureWidgetSettings, PictureWidgetSettingsDto } from './picture-widget-settings.types';
import { SelectOption } from '@shared/types/shared.types';
import { PictureWidgetOptions } from '@widgets/picture-widget/types/picture-widget-options.types';

export class PictureWidgetValue {
  constructor(public model: PictureWidgetModel = new PictureWidgetModel()) { }
}

export class PictureWidgetModel implements WidgetModel<PictureWidgetModelDto> {
  settings = new PictureWidgetSettings();
  options = new PictureWidgetOptions();

  initialized = false;
  showBorderRadius: boolean;
  lightboxVisible: boolean;

  fileObjectUrl: string;

  constructor(dto?: PictureWidgetModelDto) {
    dto && this.fromDto(dto);
  }

  toServer(): PictureWidgetModelDto {
    return {
      settings: this.settings.toServer(),
    };
  }

  fromDto(dto: PictureWidgetModelDto): void {
    if (dto) {
      dto.settings && (this.settings = new PictureWidgetSettings(dto.settings));
    }
  }
}

export interface PictureWidgetModelDto {
  settings: PictureWidgetSettingsDto;
}

export enum PictureFormatEnum {
  rectangle = 'rectangle',
  circle = 'circle',
  ellipse = 'ellipse',
  triangle = 'triangle',
}

export enum PictureSizeEnum {
  contain = 'contain',
  cover = 'cover',
  auto = 'right',
}

export enum PictureRepeatEnum {
  repeat = 'repeat',
  noRepeat = 'no-repeat',
}

export enum PicturePositionEnum {
  centerTop = 'center top',
  centerCenter = 'center center',
  centerBottom = 'center bottom',
  leftTop = 'left top',
  leftCenter = 'left center',
  leftBottom = 'left bottom',
  rightTop = 'right top',
  rightCenter = 'right center',
  rightBottom = 'right bottom',
}

export interface PicturePositionOption {
  value: PicturePositionEnum;
  label: string;
}

export interface PictureFormatOption {
  value: PictureFormatEnum;
  label: string;
}

export interface PictureSizeOption {
  value: PictureSizeEnum;
  label: string;
}

export interface PictureRepeatOption {
  value: PictureRepeatEnum;
  label: string;
}

export interface PictureOverlay {
  enabled: boolean;
  opacity: number;
  color: string;
}

export interface PictureLinkOptions {
  enabled: boolean;
  openInNewTab: boolean;
  external: boolean;
  url: string;
  page: SelectOption<string, string> | null;
}

export const PICTURE_FORMATS: PictureFormatOption[] = [
  { value: PictureFormatEnum.rectangle, label: 'Rectangle' },
  { value: PictureFormatEnum.circle, label: 'Circle' },
  { value: PictureFormatEnum.ellipse, label: 'Ellipse' },
  { value: PictureFormatEnum.triangle, label: 'Triangle' },
];

export const PICTURE_SIZES: PictureSizeOption[] = [
  { value: PictureSizeEnum.contain, label: 'Contain' },
  { value: PictureSizeEnum.cover, label: 'Cover' },
  { value: PictureSizeEnum.auto, label: 'Auto' },
];

export const PICTURE_REPEAT: PictureRepeatOption[] = [
  { value: PictureRepeatEnum.repeat, label: 'repeat' },
  { value: PictureRepeatEnum.noRepeat, label: 'no repeat' },
];

export const PICTURE_POSITIONS: PicturePositionOption[] = [
  { value: PicturePositionEnum.centerTop, label: PicturePositionEnum.centerTop },
  { value: PicturePositionEnum.centerCenter, label: PicturePositionEnum.centerCenter },
  { value: PicturePositionEnum.centerBottom, label: PicturePositionEnum.centerBottom },
  { value: PicturePositionEnum.leftTop, label: PicturePositionEnum.leftTop },
  { value: PicturePositionEnum.leftCenter, label: PicturePositionEnum.leftCenter },
  { value: PicturePositionEnum.leftBottom, label: PicturePositionEnum.leftBottom },
  { value: PicturePositionEnum.rightTop, label: PicturePositionEnum.rightTop },
  { value: PicturePositionEnum.rightCenter, label: PicturePositionEnum.rightCenter },
  { value: PicturePositionEnum.rightBottom, label: PicturePositionEnum.rightBottom },
];
