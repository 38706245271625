import { ChartConstants } from '@widgets/chart-widget/constants/constants';
import { ChartWidgetTypeChart } from '@widgets/chart-widget/types/chart-widget-view.types';
import { Chart } from 'chart.js';

// @ts-ignore
export class ChartOptionsSettings implements Chart.ChartOptions {
  // @ts-ignore
  legend?: Chart.ChartLegendOptions | undefined = { position: 'right' };
  // @ts-ignore
  tooltips?: Chart.ChartTooltipOptions | undefined;
  isShowTotal = false;
  isShowLegend = true;
  cutoutPercentage = ChartConstants.defaultCutout;
  cutout: string;
  plugins = {};

  constructor(dto?: ChartOptionsSettingsDto) {
    dto && this.fromDto(dto);
  }

  toServer(): ChartOptionsSettingsDto {
    return new ChartOptionsSettingsDto(this);
  }

  fromDto(dto: ChartOptionsSettingsDto): void {
    const { legend, isShowTotal, isShowLegend, cutoutPercentage } = dto;
    Object.assign(this, { legend, isShowTotal });
    isShowLegend !== undefined && (this.isShowLegend = isShowLegend);
    cutoutPercentage && (this.cutoutPercentage = cutoutPercentage);
    !legend && (this.legend = { position: 'right' });
  }
}

export class ChartWidgetSettings {
  chartType: ChartWidgetTypeChart | null = ChartWidgetTypeChart.doughnut;
  chartOptions: ChartOptionsSettings = new ChartOptionsSettings();

  constructor(dto?: ChartSettingsDto) {
    dto && this.fromDto(dto);
  }

  toServer(): ChartSettingsDto {
    return <ChartSettingsDto>{
      chartType: this.chartType,
      chartOptions: this.chartOptions.toServer(),
    };
  }

  fromDto(dto: ChartSettingsDto): void {
    const { chartType, chartOptions } = dto;
    chartType && Object.assign(this, { chartType });
    this.chartOptions = new ChartOptionsSettings(chartOptions);
  }
}

export class ChartSettingsDto {
  chartType: ChartWidgetTypeChart | null = null;
  chartOptions: ChartOptionsSettings;

  constructor(settings: ChartSettingsDto) {
    const { chartType, chartOptions } = settings;
    Object.assign(this, { chartType, chartOptions });
  }
}

export class ChartOptionsSettingsDto {
  // @ts-ignore
  legend?: Chart.ChartLegendOptions | undefined;
  isShowTotal: boolean;
  isShowLegend: boolean;
  cutoutPercentage?: number;

  constructor(settings: ChartOptionsSettings) {
    const { legend, isShowTotal, isShowLegend, cutoutPercentage } = settings;
    Object.assign(this, { legend, isShowTotal, isShowLegend, cutoutPercentage });
  }
}
