import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrInputMappersType, AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';

export type SwissBillAvrType = Extract<AvrTypesType, 'swiss-bill'>;

export enum SwissBillOutputType {
  svg = 'SVG',
  pdf = 'PDF',
}
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const outputTypeCheck: [AvrInputMappersType[SwissBillAvrType]['outputType']] extends [`${SwissBillOutputType}`]
  ? `${SwissBillOutputType}` extends AvrInputMappersType[SwissBillAvrType]['outputType']
    ? true
    : false
  : false = true;

export class SwissBillAvrInputMapperDto extends BaseAvrInputMapperDto<SwissBillAvrType> {
  constructor() {
    super(
      {
        ['currency']: {
          isOptional: false,
          value: '',
          readableName: 'Currency',
          description: '[STRING] Currency (3&nbsp;characters)',
        },
        ['amount']: {
          isOptional: true,
          value: '',
          readableName: 'Amount',
          description: '[STRING / NUMBER] Amount of money (max&nbsp;12&nbsp;digits)',
        },
        ['reference']: {
          isOptional: true,
          value: '',
          readableName: 'Reference',
          description: '[STRING] QR-IBAN (max&nbsp;27&nbsp;characters) || OR || Creditor Reference (ISO&nbsp;11649) (max&nbsp;25&nbsp;characters)',
        },
        ['message']: {
          isOptional: true,
          value: '',
          readableName: 'Message',
          description: '[STRING] Transaction message (max&nbsp;140&nbsp;characters)',
        },
        ['additionalInformation']: {
          isOptional: true,
          value: '',
          readableName: 'Additional Information',
          description: '[STRING] Transaction additional information (max&nbsp;100&nbsp;characters)',
        },

        ['av1']: {
          isOptional: true,
          value: '',
          readableName: 'Alternative scheme 1',
          description: '[STRING] Alternative scheme (max&nbsp;100&nbsp;characters)',
        },
        ['av2']: {
          isOptional: true,
          value: '',
          readableName: 'Alternative scheme 2',
          description: '[STRING] Alternative scheme (max&nbsp;100&nbsp;characters)',
        },
        ['creditor.account']: {
          isOptional: false,
          value: '',
          readableName: 'Creditor - Account',
          description: '[STRING] Creditor SWISS IBAN (21&nbsp;characters)',
        },
        ['creditor.name']: {
          isOptional: false,
          value: '',
          readableName: 'Creditor - Name',
          description: '[STRING] Creditor name (max&nbsp;70&nbsp;characters)',
        },
        ['creditor.address']: {
          isOptional: false,
          value: '',
          readableName: 'Creditor - Address',
          description: '[STRING] Creditor address (max&nbsp;70&nbsp;characters)',
        },
        ['creditor.buildingNumber']: {
          isOptional: true,
          value: '',
          readableName: 'Creditor - Building Number',
          description: '[STRING / INTEGER] Creditor building number (max&nbsp;16&nbsp;characters)',
        },
        ['creditor.zip']: {
          isOptional: false,
          value: '',
          readableName: 'Creditor - ZIP',
          description: '[STRING / INTEGER] Creditor ZIP (max&nbsp;16&nbsp;characters)',
        },
        ['creditor.city']: {
          isOptional: false,
          value: '',
          readableName: 'Creditor - City',
          description: '[STRING] Creditor city (max&nbsp;35&nbsp;characters)',
        },
        ['creditor.country']: {
          isOptional: false,
          value: '',
          readableName: 'Creditor - Country',
          description: '[STRING] Creditor country (2&nbsp;characters)',
        },
        ['debtor.name']: {
          isOptional: false,
          value: '',
          readableName: 'Debtor - Name',
          description: '[STRING] Debtor name (max&nbsp;70&nbsp;characters)',
        },

        ['debtor.address']: {
          isOptional: false,
          value: '',
          readableName: 'Debtor - Address',
          description: '[STRING] Debtor address (max&nbsp;70&nbsp;characters)',
        },
        ['debtor.buildingNumber']: {
          isOptional: true,
          value: '',
          readableName: 'Debtor - Building Number',
          description: '[STRING / INTEGER] Debtor building number (max&nbsp;16&nbsp;characters)',
        },
        ['debtor.zip']: {
          isOptional: false,
          value: '',
          readableName: 'Debtor - ZIP',
          description: '[STRING / INTEGER] Debtor ZIP (max&nbsp;16&nbsp;characters)',
        },
        ['debtor.city']: {
          isOptional: false,
          value: '',
          readableName: 'Debtor - City',
          description: '[STRING] Debtor city (max&nbsp;35&nbsp;characters)',
        },
        ['debtor.country']: {
          isOptional: false,
          value: '',
          readableName: 'Debtor - Country',
          description: '[STRING] Debtor country (2&nbsp;characters)',
        },
      },
      {
        ['debtor.isOptional']: {
          isOptional: false,
          value: false,
          readableName: 'Debtor - Is optional',
          description: 'Option to set all debtor fields as optional',
        },
        ['outputType']: {
          isOptional: false,
          value: SwissBillOutputType.svg,
          readableName: 'Output type',
          description: 'Select from supported output types',
        },
      },
    );
  }
}
