import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { FadeAnimation } from '@shared/animations/animations';
import { SharedMethods } from '@shared/methods/shared.methods';
import { ElvisActionService } from '@shared/services/elvis-action.service';
import { IsItemInFirstLevelPipe } from '@widgets/menu-widget/pipes/is-item-in-first-level.pipe';
import { MenuTypesEnum } from '@widgets/menu-widget/types/menu-option.types';
import { MenuStylesPropNamesEnum } from '../../types/menu-widget-styles.types';
import { MenuItem, MenuWidgetModel } from '../../types/menu-widget.types';
import {
  LinkQueryParamMap,
  MenuItemCurrentPageBehaviorEnum,
  MenuItemUiType,
  TreeItem
} from '../../types/tree-types';
import { CustomClickService } from '@widgets/shared/services/custom-click-service';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { CustomEventSettings } from '@widgets/shared/types/custom-event';
import { MenuItemAction } from '@widgets/menu-widget/types/menu-action-types';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  animations: [FadeAnimation],
})
export class MenuItemComponent {
  @Input() item: MenuItem | TreeItem;
  @Input() m: MenuWidgetModel;
  @Input() stopPropagation = true;
  @Input() queryParams: Params;

  menuStylesPropNamesEnum = MenuStylesPropNamesEnum;
  menuItemUiType = MenuItemUiType;
  subMenuMouseOvered = false;
  subMenuDomRectangle: DOMRect;

  constructor(
      private readonly elvisActionService: ElvisActionService,
      private readonly router: Router,
      private readonly route: ActivatedRoute,
      private readonly customClickService: CustomClickService,
  ) {}

  get mouseOvered(): boolean {
    return !this.item.isTextOnly && this.m.settings.item[this.item.hash].mouseOvered;
  }

  get onlyIconInsideItem(): boolean {
    return !this.item.subLabel && !this.item.label;
  }

  get directionStyles(): any {
    return { flexDirection: this.m.settings.menu.innerFlexDirection };
  }

  onMouseEnter(event: MouseEvent, item: MenuItem): void {
    if (item.isTextOnly) {
      this.m.settings.item[item.hash].mouseOvered = true;
    }
    if (this.shouldComputeSubmenuOffset()) {
      this.subMenuDomRectangle = this.getSubmenuDomRectangle(event.target as Element);
    }
    if (!this.isAdvancedMode() && this.shouldOpenSubmenuOnHover()) {
      this.openSubMenu();
    }
  }

  onMouseLeave(item: MenuItem): void {
    if (item.isTextOnly) {
      this.m.settings.item[item.hash].mouseOvered = false;
    }
    if (!this.isAdvancedMode() && this.shouldOpenSubmenuOnHover()) {
      this.closeSubMenu();
    }
  }

  async navigateWithRouter(action: MenuItemAction): Promise<any> {
    const alias = action?.url ? (this.m.options.pages?.listMap[action.url]?.alias || ''): '';
    const urlTreeRoute = action.useAlias ? [alias] : [action.url];

    const navigationExtra: NavigationExtras = { queryParams: action.queryParamsListToMap().parametersToAdd };
    action.fragmentParam && (navigationExtra.fragment = action.fragmentParam);

    const urlTree = this.router.createUrlTree(urlTreeRoute, navigationExtra);
    await this.router.navigateByUrl(urlTree);
  }

  async onAnchorClick($event: Event, item: MenuItem | TreeItem): Promise<void> {
    $event.preventDefault();
    this.stopPropagation && $event.stopPropagation();

    item.actions.forEach(action => {
      if (action.type === DefaultElvisActions.customEvent) {
        this.customClickService.onClick({ enable: true, types: [action.model.name] } as CustomEventSettings);
      } else {

        if (this.isAdvancedMode()) return;
        if (this.shouldOpenSubmenuOnClick()) this.item.expanded = !this.item.expanded;
        this.elvisActionService.fireElvisAction(DefaultElvisActions[action.type as keyof typeof DefaultElvisActions], this.m.items.menu, this.queryParams);

        if (!this.shouldStayOnSamePage(action.type) && this.shouldUseRouterNavigation(action, action.type)) {
          this.navigateWithRouter(action);
          return;
        }

        if (!this.shouldStayOnSamePage(action.type) && this.shouldUseWindowNavigation(action)) {
          window.open(action.url, '_blank', 'noopener');
          return;
        }

        if (this.shouldStayOnSamePage(action.type)) {
          this.setQueryParamsFromLinkItem(action).then(() => {
            if (action.fragmentParam) {
              const offset = SharedMethods.computeStickyOffset();
              SharedMethods.scrollToHtmlElement(action.fragmentParam, offset);
            }
          });
        }
      }
    });
  }

  private isAdvancedMode(): boolean {
    return this.item instanceof TreeItem;
  }

  private shouldOpenSubmenuOnHover(): boolean {
    return this.m.settings.menu.type !== MenuTypesEnum.panel || this.m.settings.menu.openSubmenuOnHover;
  }

  private shouldOpenSubmenuOnClick(): boolean {
    return !this.shouldOpenSubmenuOnHover();
  }

  private openSubMenu(): void {
    this.item.expanded = true;
  }

  private closeSubMenu(): void {
    setTimeout(() => {
      if (!this.m.settings.item[this.item.hash].mouseOvered && !this.subMenuMouseOvered) {
        this.item.expanded = false;
      }
    }, 300);
  }

  private async setQueryParamsFromLinkItem(action: MenuItemAction): Promise<void> {
    const menuLinkQueryParamsMap = action.queryParamsListToMap();
    const queryParams = this.getQueryParameters(menuLinkQueryParamsMap);
    const navigationExtras = this.getNavigationExtras(queryParams, action);

    await this.router.navigate([], navigationExtras);
  }

  private getQueryParameters(menuLinkQueryParamsMap: LinkQueryParamMap): Params {
    const queryParams = { ...this.queryParams, ...menuLinkQueryParamsMap.parametersToAdd };
    menuLinkQueryParamsMap.parameterKeysToRemove.forEach((paramKey: string) => delete queryParams[paramKey]);
    return queryParams;
  }

  private getNavigationExtras(queryParams: Params, action: MenuItemAction): NavigationExtras {
    const navigationExtras: NavigationExtras = { queryParams, relativeTo: this.route, replaceUrl: true };
    action.fragmentParam && (navigationExtras.fragment = action.fragmentParam);
    return navigationExtras;
  }

  private shouldUseRouterNavigation(action: MenuItemAction, actionType: any): boolean {
    const isLink = actionType === MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage || actionType === MenuItemCurrentPageBehaviorEnum.leaveCurrentPage;
    if (!isLink || !action.url) return false;
    return action.isPageSelection;
  }

  private shouldUseWindowNavigation(action: MenuItemAction): boolean {
    return !action.isPageSelection && !!action.url;
  }

  private shouldStayOnSamePage(actionType: any): boolean {
    return actionType === MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage;
  }

  // Computing offset is done only in horizontal menu, on TOP level, if item has children and is not yet expanded
  private shouldComputeSubmenuOffset(): boolean {
    return (
      !!this.item.children.length &&
      !this.item.expanded &&
      this.m.settings.menu.type === MenuTypesEnum.horizontal &&
      new IsItemInFirstLevelPipe().transform(this.m.items.menu, this.item as MenuItem)
    );
  }

  private getSubmenuDomRectangle(target: Element): DOMRect {
    return target.getBoundingClientRect();
  }
}
