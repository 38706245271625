export enum FormulaJsFunction {
  ABS = 'ABS',
  ACCRINT = 'ACCRINT',
  ACOS = 'ACOS',
  ACOSH = 'ACOSH',
  ACOT = 'ACOT',
  ACOTH = 'ACOTH',
  AGGREGATE = 'AGGREGATE',
  AND = 'AND',
  ARABIC = 'ARABIC',
  ASIN = 'ASIN',
  ASINH = 'ASINH',
  ATAN = 'ATAN',
  ATANH = 'ATANH',
  AVEDEV = 'AVEDEV',
  AVERAGE = 'AVERAGE',
  AVERAGEA = 'AVERAGEA',
  AVERAGEIF = 'AVERAGEIF',
  AVERAGEIFS = 'AVERAGEIFS',
  BASE = 'BASE',
  BETADIST = 'BETADIST',
  BETAINV = 'BETAINV',
  BIN2DEC = 'BIN2DEC',
  BIN2HEX = 'BIN2HEX',
  BIN2OCT = 'BIN2OCT',
  BINOMDIST = 'BINOMDIST',
  BITAND = 'BITAND',
  BITLSHIFT = 'BITLSHIFT',
  BITOR = 'BITOR',
  BITRSHIFT = 'BITRSHIFT',
  BITXOR = 'BITXOR',
  CEILING = 'CEILING',
  CEILINGMATH = 'CEILINGMATH',
  CEILINGPRECISE = 'CEILINGPRECISE',
  CHAR = 'CHAR',
  CLEAN = 'CLEAN',
  CODE = 'CODE',
  COMBIN = 'COMBIN',
  COMBINA = 'COMBINA',
  COMPLEX = 'COMPLEX',
  CONCATENATE = 'CONCATENATE',
  CONVERT = 'CONVERT',
  CORREL = 'CORREL',
  COS = 'COS',
  COSH = 'COSH',
  COT = 'COT',
  COTH = 'COTH',
  COUNT = 'COUNT',
  COUNTA = 'COUNTA',
  COUNTBLANK = 'COUNTBLANK',
  COUNTIF = 'COUNTIF',
  COUNTIFS = 'COUNTIFS',
  COUNTUNIQUE = 'COUNTUNIQUE',
  COVARIANCEP = 'COVARIANCEP',
  COVARIANCES = 'COVARIANCES',
  CSC = 'CSC',
  CSCH = 'CSCH',
  CUMIPMT = 'CUMIPMT',
  CUMPRINC = 'CUMPRINC',
  DATE = 'DATE',
  DATEVALUE = 'DATEVALUE',
  DAY = 'DAY',
  DAYS = 'DAYS',
  DB = 'DB',
  DDB = 'DDB',
  DEC2BIN = 'DEC2BIN',
  DEC2HEX = 'DEC2HEX',
  DEC2OCT = 'DEC2OCT',
  DECIMAL = 'DECIMAL',
  DELTA = 'DELTA',
  DEVSQ = 'DEVSQ',
  DOLLARDE = 'DOLLARDE',
  DOLLARFR = 'DOLLARFR',
  EDATE = 'EDATE',
  EFFECT = 'EFFECT',
  EOMONTH = 'EOMONTH',
  ERF = 'ERF',
  ERFC = 'ERFC',
  EVEN = 'EVEN',
  EXACT = 'EXACT',
  EXP = 'EXP',
  EXPONDIST = 'EXPONDIST',
  FACT = 'FACT',
  FACTDOUBLE = 'FACTDOUBLE',
  FALSE = 'FALSE',
  FDIST = 'FDIST',
  FIND = 'FIND',
  FINV = 'FINV',
  FISHER = 'FISHER',
  FISHERINV = 'FISHERINV',
  FLOOR = 'FLOOR',
  FLOORMATH = 'FLOORMATH',
  FLOORPRECISE = 'FLOORPRECISE',
  FORECAST = 'FORECAST',
  FREQUENCY = 'FREQUENCY',
  FV = 'FV',
  FVSCHEDULE = 'FVSCHEDULE',
  GAMMA = 'GAMMA',
  GAMMALN = 'GAMMALN',
  GAUSS = 'GAUSS',
  GCD = 'GCD',
  GEOMEAN = 'GEOMEAN',
  GESTEP = 'GESTEP',
  GROWTH = 'GROWTH',
  HARMEAN = 'HARMEAN',
  HEX2BIN = 'HEX2BIN',
  HEX2DEC = 'HEX2DEC',
  HEX2OCT = 'HEX2OCT',
  HOUR = 'HOUR',
  HYPGEOMDIST = 'HYPGEOMDIST',
  IF = 'IF',
  IFERROR = 'IFERROR',
  IFNA = 'IFNA',
  IFS = 'IFS',
  IMABS = 'IMABS',
  IMAGINARY = 'IMAGINARY',
  IMARGUMENT = 'IMARGUMENT',
  IMCONJUGATE = 'IMCONJUGATE',
  IMCOS = 'IMCOS',
  IMCOSH = 'IMCOSH',
  IMCOT = 'IMCOT',
  IMCSC = 'IMCSC',
  IMCSCH = 'IMCSCH',
  IMDIV = 'IMDIV',
  IMEXP = 'IMEXP',
  IMLN = 'IMLN',
  IMPOWER = 'IMPOWER',
  IMPRODUCT = 'IMPRODUCT',
  IMREAL = 'IMREAL',
  IMSEC = 'IMSEC',
  IMSECH = 'IMSECH',
  IMSIN = 'IMSIN',
  IMSINH = 'IMSINH',
  IMSQRT = 'IMSQRT',
  IMSUB = 'IMSUB',
  IMSUM = 'IMSUM',
  IMTAN = 'IMTAN',
  INT = 'INT',
  INTERCEPT = 'INTERCEPT',
  IPMT = 'IPMT',
  IRR = 'IRR',
  ISEVEN = 'ISEVEN',
  ISOCEILING = 'ISOCEILING',
  ISODD = 'ISODD',
  ISOWEEKNUM = 'ISOWEEKNUM',
  ISPMT = 'ISPMT',
  KURT = 'KURT',
  LARGE = 'LARGE',
  LCM = 'LCM',
  LEFT = 'LEFT',
  LEN = 'LEN',
  LINEST = 'LINEST',
  LN = 'LN',
  LOG = 'LOG',
  LOGNORMDIST = 'LOGNORMDIST',
  LOGNORMINV = 'LOGNORMINV',
  LOWER = 'LOWER',
  MAX = 'MAX',
  MAXA = 'MAXA',
  MEDIAN = 'MEDIAN',
  MID = 'MID',
  MIN = 'MIN',
  MINA = 'MINA',
  MINUTE = 'MINUTE',
  MIRR = 'MIRR',
  MOD = 'MOD',
  MODEMULT = 'MODEMULT',
  MODESNGL = 'MODESNGL',
  MONTH = 'MONTH',
  MROUND = 'MROUND',
  MULTINOMIAL = 'MULTINOMIAL',
  NETWORKDAYS = 'NETWORKDAYS',
  NETWORKDAYSINTL = 'NETWORKDAYSINTL',
  NOMINAL = 'NOMINAL',
  NORMDIST = 'NORMDIST',
  NORMINV = 'NORMINV',
  NORMSDIST = 'NORMSDIST',
  NORMSINV = 'NORMSINV',
  NOT = 'NOT',
  NOW = 'NOW',
  NPER = 'NPER',
  NPV = 'NPV',
  NUMBERVALUE = 'NUMBERVALUE',
  OCT2BIN = 'OCT2BIN',
  OCT2DEC = 'OCT2DEC',
  OCT2HEX = 'OCT2HEX',
  ODD = 'ODD',
  OR = 'OR',
  PDURATION = 'PDURATION',
  PEARSON = 'PEARSON',
  PERCENTILEEXC = 'PERCENTILEEXC',
  PERCENTILEINC = 'PERCENTILEINC',
  PERCENTRANKEXC = 'PERCENTRANKEXC',
  PERCENTRANKINC = 'PERCENTRANKINC',
  PERMUT = 'PERMUT',
  PERMUTATIONA = 'PERMUTATIONA',
  PHI = 'PHI',
  PMT = 'PMT',
  POISSONDIST = 'POISSONDIST',
  POWER = 'POWER',
  PPMT = 'PPMT',
  PROB = 'PROB',
  PRODUCT = 'PRODUCT',
  PROPER = 'PROPER',
  PV = 'PV',
  QUARTILEEXC = 'QUARTILEEXC',
  QUARTILEINC = 'QUARTILEINC',
  QUOTIENT = 'QUOTIENT',
  RADIANS = 'RADIANS',
  RAND = 'RAND',
  RANDBETWEEN = 'RANDBETWEEN',
  RANKAVG = 'RANKAVG',
  RANKEQ = 'RANKEQ',
  RATE = 'RATE',
  REGEXEXTRACT = 'REGEXEXTRACT',
  REGEXMATCH = 'REGEXMATCH',
  REGEXREPLACE = 'REGEXREPLACE',
  REPLACE = 'REPLACE',
  REPT = 'REPT',
  RIGHT = 'RIGHT',
  ROMAN = 'ROMAN',
  ROUND = 'ROUND',
  ROUNDDOWN = 'ROUNDDOWN',
  ROUNDUP = 'ROUNDUP',
  RSQ = 'RSQ',
  SEARCH = 'SEARCH',
  SEC = 'SEC',
  SECH = 'SECH',
  SECOND = 'SECOND',
  SIGN = 'SIGN',
  SIN = 'SIN',
  SINH = 'SINH',
  SKEW = 'SKEW',
  SKEWP = 'SKEWP',
  SLOPE = 'SLOPE',
  SMALL = 'SMALL',
  SPLIT = 'SPLIT',
  SQRT = 'SQRT',
  SQRTPI = 'SQRTPI',
  STANDARDIZE = 'STANDARDIZE',
  STDEVA = 'STDEVA',
  STDEVP = 'STDEVP',
  STDEVPA = 'STDEVPA',
  STDEVS = 'STDEVS',
  STEYX = 'STEYX',
  SUBSTITUTE = 'SUBSTITUTE',
  SUBTOTAL = 'SUBTOTAL',
  SUM = 'SUM',
  SUMIF = 'SUMIF',
  SUMIFS = 'SUMIFS',
  SUMPRODUCT = 'SUMPRODUCT',
  SUMSQ = 'SUMSQ',
  SWITCH = 'SWITCH',
  T = 'T',
  TAN = 'TAN',
  TANH = 'TANH',
  TDIST = 'TDIST',
  TEXTJOIN = 'TEXTJOIN',
  TIME = 'TIME',
  TIMEVALUE = 'TIMEVALUE',
  TINV = 'TINV',
  TODAY = 'TODAY',
  TRIM = 'TRIM',
  TRIMMEAN = 'TRIMMEAN',
  TRUE = 'TRUE',
  TRUNC = 'TRUNC',
  UNICHAR = 'UNICHAR',
  UNICODE = 'UNICODE',
  UPPER = 'UPPER',
  VARA = 'VARA',
  VARP = 'VARP',
  VARPA = 'VARPA',
  VARS = 'VARS',
  WEEKDAY = 'WEEKDAY',
  WEEKNUM = 'WEEKNUM',
  WEIBULLDIST = 'WEIBULLDIST',
  WORKDAY = 'WORKDAY',
  WORKDAYINTL = 'WORKDAYINTL',
  XOR = 'XOR',
  YEAR = 'YEAR',
  YEARFRAC = 'YEARFRAC',
  ZTEST = 'ZTEST',
}
