import { RecordDto } from '@api/models/record-dto';
import { TeamResponseDto } from '@api/models/team-response-dto';

export class NewTeam implements TeamResponseDto {
  id: string;
  name: string;
  description: string;
  teamPrivateFolderId: string;
  isProtected: boolean;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null;

  constructor(dto?: TeamResponseDto) {
    dto && Object.assign(this, dto);
  }
}
