<ng-container *ngIf="m">
  <div
    *ngIf="isLayoutMode; else advancedModeTemplate"
    [class.cursor-pointer]="m.settings.clickAction.isHandled"
    [ngStyle]="m.styles"
    (click)="onWidgetClick(); $event.preventDefault()"
    class="grid">
    <ng-container *ngIf="!m.selected.showBadge">
      <div *ngIf="m.settings.label && (isTop() || isLeft())" [ngClass]="isLeft() ? 'col-6': 'col-12'"
           [ngStyle]="m.settings.labelStyles">
        {{m.settings.label}}
      </div>
      <div *ngIf="m.selected.icon && helper.isIconOnTop(m)" [ngClass]="'col-12'" [ngStyle]="m.settings.iconStyles">
        <i [ngClass]="m.selected.icon" [ngStyle]="m.settings.iconStyles" class="pi"></i>
      </div>
      <div [ngClass]="isLeft() || isRight() ? 'col-6': 'col-12'" [ngStyle]="m.settings.counterStyles">
        <i *ngIf="helper.isIconOnLeft(m)" [ngClass]="m.selected.icon" [ngStyle]="m.settings.iconStyles" class="pi"></i>
        {{m.settings.prefix}}
        {{m?.counter | useDecimalPlaces : m.settings.minDecimalPlaces : m.settings.maxDecimalPlaces : true }}
        {{m.settings.postfix}}
        <span *ngIf="!m?.counter && m?.counter !== 0" class="pi pi-spin pi-spinner"></span>
        <i *ngIf="helper.isIconOnRight(m)" [ngClass]="m.selected.icon" [ngStyle]="m.settings.iconStyles" class="pi"></i>
      </div>
      <div *ngIf="m.selected.icon && helper.isIconOnBottom(m)" [ngClass]="'col-12'" [ngStyle]="m.settings.iconStyles">
        <i [ngClass]="m.selected.icon" [ngStyle]="m.settings.iconStyles" class="pi"></i>
      </div>
      <div *ngIf="isBottom() || isRight()" [ngClass]="isRight() ? 'col-6': 'col-12'" [ngStyle]="m.settings.labelStyles">
        {{m.settings.label}}
      </div>
    </ng-container>

    <div *ngIf="m.selected.showBadge" [ngStyle]="m.settings.counterStyles" style="width: 100%">
      <p-badge [id]="m.badgeId" [ngClass]="m.selected.icon" [ngStyle]="m.settings.iconStyles" [value]="m.counter"></p-badge>
      <span *ngIf="!m?.counter && m?.counter !== 0" class="pi pi-spin pi-spinner"></span>
    </div>
  </div>

  <ng-template #advancedModeTemplate>
    <p-accordion (onOpen)="onTabOpen()" styleClass="accord">
      <p-accordionTab [header]="'Settings' | translate" [selected]="true">
        <app-number-widget-settings [m]="m" [s]="s"></app-number-widget-settings>
      </p-accordionTab>

      <p-accordionTab [header]="'Styles' | translate">
        <app-page-element-styler [styles]="m.styles" class="mb-3"></app-page-element-styler>
      </p-accordionTab>

      <p-accordionTab [header]="'Content settings' | translate">
        <app-number-widget-content-settings [m]="m" [s]="s"></app-number-widget-content-settings>
      </p-accordionTab>
    </p-accordion>
  </ng-template>
</ng-container>
