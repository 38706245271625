<div class="login-body dynamic-static-label" *ngIf="authChecked">
  <div class="login-wrapper">
    <div class="login-panel">
      <div class="login-panel-header">
        <img src="../../../../assets/icons/big_logo_dark.png" class="logo" alt="diamond-layout" />
        <app-language-switcher class="language-switcher"></app-language-switcher>
      </div>

      <div class="login-form">
        <h2 translate>Login to your Account</h2>
        <p></p>
        <div class="p-inputgroup login-item">
          <span class="p-inputgroup-addon">
            <span class="pi pi-user"></span>
          </span>
          <span class="dynamic-label ">
            <input type="text" id="username" pInputText [(ngModel)]="username" />
            <label class="dynamic-label-text" for="username" translate>User Name</label>
          </span>
        </div>

        <div class="p-inputgroup login-item">
          <span class="p-inputgroup-addon">
            <span class="pi pi-key"></span>
          </span>
          <span class="dynamic-label ">
            <input #pass id="password" pPassword type="password" [feedback]="false" (keyup.enter)="login()" />
            <label class="dynamic-label-text" for="password" translate>Password</label>
          </span>
        </div>

        <button id="login" pButton label="{{ 'Login' | translate }}" type="button" (click)="login()">
          <span *ngIf="inProgress" class="pi pi-spin pi-spinner"></span>
        </button>
        <div class="button-separator"></div>
        <button id="forgottenPassword" pButton label="{{ 'Forgotten password' | translate }}" type="button"
                (click)="recoverPassword()"></button>

        <span class="ssoBtns">
          <ng-container *ngFor="let ssoService of ssoServices">
            <p-button pRipple type="button" styleClass="p-button-rounded p-button-icon-only"
                      (click)="ssoService.onClickHandler(redirectIfLogged.bind(this))" [title]="ssoService.tooltip">
              <ng-template pTemplate="content"><div [outerHTML]="ssoService.content"></div></ng-template>
            </p-button>
          </ng-container>
        </span>
      </div>
    </div>

    <div class="login-image">
      <div class="login-image-content">
        <h1 translate>Access to your</h1>
        <h1 translate>Elwis</h1>
        <h1 translate>Account</h1>
      </div>
      <div class="image-footer"></div>
    </div>
  </div>
</div>
