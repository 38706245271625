<ng-container *ngIf="m?.settings">
  <ng-container *ngIf="isLayoutMode; else advancedModeTemplate">
    <div *ngIf="sseEvents" class="alw-table-reload-element">
      <span>* {{ 'Some data updates occurred on the server' | translate }}</span>
      <button (click)="onReloadDataClick()" class="p-button p-button-secondary" label="{{ 'Reload data' | translate }}"
              pButton type="button"></button>
    </div>

    <h5 *ngIf="m.settings.list.caption">{{ m.settings.list.caption }}</h5>

    <div
      *ngIf="isTemplateSelected && canRender"
      [ngStyle]="m.settings.list.styles"
      [style.grid-template-columns]="gridTemplateColumns$ | async"
      class="d-grid relative">
      <app-widget-container
        (click)="onCardClick(artifact)"
        *ngFor="let artifact of (isOrientationHorizontal ? m.options.artifacts.list : (m.options.artifacts.list | transpose : m.settings.list.columnsCount))"
        [applicationId]="applicationId"
        [artifactAdditionalData]="m.cardArtifactAdditionalData"
        [artifact]="artifact"
        [disabled]="isPreviewMode"
        [isClickable]="m.settings.listItemClickAction.isHandled"
        [isLayoutMode]="true"
        [isListMatrixCard]="true"
        [isPreviewMode]="isPreviewMode"
        [style.margin-bottom]="cardMarginBottom"
        [widget]="m.cardWidget | cloneDeep"
        appWidgetContainerStopPropagation
      >
      </app-widget-container>

      <app-loader *ngIf="loading$ | async; else empty" [loading]="true"></app-loader>

      <ng-template #empty>
        <div *ngIf="!m.options.artifacts.list.length">{{ m.settings.list.emptyMessage }}</div>
      </ng-template>
    </div>

    <div>
      <p-paginator
        (onPageChange)="onPageChange($event)"
        *ngIf="isPaginatorVisible && canRender"
        [first]="m.listResponseMeta?.skip"
        [rows]="m.settings.list.cardsPerPage"
        [styleClass]="'no-background'"
        [totalRecords]="m.listResponseMeta?.totalCount"
      >
      </p-paginator>
    </div>

    <h5 *ngIf="m.settings.list.summary">{{ m.settings.list.summary }}</h5>
  </ng-container>

  <ng-template #advancedModeTemplate>
    <p-accordion>
      <p-accordionTab [header]="'List settings' | translate" [selected]="true">
        <div class="grid">
          <div class="col-6">
            <div class="dynamic-label">
              <p-dropdown
                (onChange)="onArtifactTypeChange()"
                [(ngModel)]="m.settings.list.artifactTypeId"
                [autoDisplayFirst]="false"
                [filter]="true"
                [options]="m.options.artifactTypes.list | transformArrayToSelectOptions : NAME_VALUE"
                appendTo="body"
                inputId="cardWidgetArtifactType"
                optionValue="value.id"
                optionLabel="label"
              >
                <ng-template let-option pTemplate="item">
                  <ng-container *ngTemplateOutlet="attributeOption; context: { $implicit: option }"></ng-container>
                </ng-template>
                <ng-template let-selected pTemplate="selectedItem">
                  <ng-container *ngTemplateOutlet="attributeOption; context: { $implicit: selected }"></ng-container>
                </ng-template>

                <ng-template #attributeOption let-option>
                  {{ option | getAttributeLabelWithApplication : m.options.applications }}
                </ng-template>
              </p-dropdown>
              <label [for]="'cardWidgetArtifactType'"
                     class="dynamic-label-text">{{ 'Artifact type' | translate }}</label>
            </div>
          </div>

          <div class="col-6">
            <button
              (click)="onChooseCardClick()"
              *ngIf="!isTemplateSelected; else templateName"
              class="p-button p-button-success p-component"
              label="{{ 'Choose card template' | translate }}"
              pButton
              pRipple
            ></button>

            <ng-template #templateName>
              <input [ngModel]="m.cardWidget.templateName" [readOnly]="true" pInputText />

              <button (click)="onTemplateUnselection()" class="ml-2 p-button-danger p-button-sm" icon="pi pi-times"
                      pButton pRipple type="button"></button>
            </ng-template>
          </div>

          <div class="col-6">
            <h5>{{ 'Caption' | translate }}</h5>
            <input [(ngModel)]="m.settings.list.caption" pInputText type="text" />
          </div>

          <div class="col-6">
            <h5>{{ 'Summary' | translate }}</h5>
            <input [(ngModel)]="m.settings.list.summary" pInputText type="text" />
          </div>

          <div class="col-6">
            <h5>{{ 'Cards per page' | translate }}</h5>
            <p-inputNumber [(ngModel)]="m.settings.list.cardsPerPage" [max]="1000" [min]="1"
                           placeholder="5"></p-inputNumber>
          </div>

          <div class="col-6">
            <h5>{{ 'Columns count' | translate }}</h5>
            <p-inputNumber [(ngModel)]="m.settings.list.columnsCount" [max]="12" [min]="1"
                           placeholder="3"></p-inputNumber>
          </div>

          <div class="col-6">
            <h5>{{ 'Do filter on url change' | translate }}</h5>
            <p-checkbox [(ngModel)]="m.settings.list.doFilterOnUrlChange" [binary]="true" id="filterOnUrl"
                        inputId="filterOnUrl"></p-checkbox>
            <label class="settings-checkbox-label" for="filterOnUrl">{{ m.settings.list.doFilterOnUrlChange }}</label>
          </div>

          <div class="col-6">
            <h5>{{ 'Do sort on url change' | translate }}</h5>
            <p-checkbox [(ngModel)]="m.settings.list.doSortOnUrlChange" [binary]="true" id="sortOnUrl"
                        inputId="sortOnUrl"></p-checkbox>
            <label class="settings-checkbox-label" for="sortOnUrl">{{ m.settings.list.doSortOnUrlChange }}</label>
          </div>

          <div class="col-6">
            <h5>{{ 'Empty message' | translate }}</h5>
            <input [(ngModel)]="m.settings.list.emptyMessage" pInputText type="text" />
          </div>

          <div class="col-6">
            <h5>{{ 'List orientation' | translate }}</h5>
            <p-selectButton
              [(ngModel)]="m.settings.list.orientation"
              [options]="options.listOrientation"
              optionLabel="label"
              optionValue="value">
            </p-selectButton>
          </div>

          <div class="col-12">
            <app-list-item-click-action-settings-form
              [(clickAction)]="m.settings.listItemClickAction.actionType"
              [(emittingUrlKey)]="m.settings.listItemClickAction.emittingKey"
              [(externalPage)]="m.settings.listItemClickAction.externalPage"
              [(handleClick)]="m.settings.listItemClickAction.isHandled"
              [(openPageInNewTab)]="m.settings.listItemClickAction.openInNewTab"
              [(selectedPage)]="m.settings.listItemClickAction.selectedPage"
              [(useAlias)]="m.settings.listItemClickAction.useAlias"
              [filtersHolder]="$any(m.settings.listItemClickAction)"
              [filtersKey]="'filtersForQueryParams'"
              [options]="clickActionSettingsOptions"
            >
            </app-list-item-click-action-settings-form>
          </div>

          <ng-container *ngIf="artifactType">
            <div class="col-12">
              <app-artifact-filters
                (filtersChange)="onFiltersChange()"
                [attributeAndLinkOptions]="m.options.combinedAttributeAndLinkOptions | filterByArtifactType : artifactType"
                [dataTypes]="m.options.dataTypes.list"
                [filtersHolder]="$any(m)"
                [filtersKey]="'filters'"
                [users]="m.options.users"
              >
              </app-artifact-filters>
            </div>
            <div class="col-12">
              <app-artifact-sorts
                (sortsChange)="onSortsChange()"
                [attributes]="m.options.combinedAttributeOptions | filterByArtifactType : artifactType"
                [sortsHolder]="$any(m)"
                [sortsKey]="'sorts'"
              >
              </app-artifact-sorts>
            </div>
          </ng-container>
        </div>
      </p-accordionTab>

      <p-accordionTab [header]="'List styles' | translate" [selected]="false">
        <app-page-element-styler [styles]="m.settings.list.styles" class="mb-3"></app-page-element-styler>

        <div class="grid">
          <div class="col-6">
            <app-grid-gap-field [(gap)]="m.settings.list.styles.columnGap"
                                [trackType]="trackType.column"></app-grid-gap-field>
          </div>

          <div class="col-6">
            <app-grid-gap-field [(gap)]="m.settings.list.styles.rowGap"
                                [trackType]="trackType.row"></app-grid-gap-field>
          </div>
        </div>
      </p-accordionTab>
    </p-accordion>
  </ng-template>
</ng-container>
