import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { ListContainer } from '@shared/types/list-container.types';
import {
  LinkQueryParamActionEnum,
  MenuItemCurrentPageBehaviorEnum,
  MenuItemUiType
} from '@widgets/menu-widget/types/tree-types';
import { FlexDirection, FlexDirectionEnum, StyleOptions } from '../../shared/types/style.types';
import { MenuItemHorizontalPosition, MenuItemVerticalPosition } from './menu-widget-styles.types';
import { SelectOption } from '@shared/types/shared.types';
import { MenuRepresentation } from '@widgets/menu-widget/types/menu-representation-types';
import { TreeNode } from '@private/components/type-system-element-dropdown/tree-node';

export class MenuWidgetOptions extends StyleOptions {
  display = GetSelectOptionsFromEnum(FlexDirectionEnum);
  pages = new ListContainer<Page>();
  menuItemHorizontalAlign = GetSelectOptionsFromEnum(MenuItemHorizontalPosition);
  menuItemVerticalAlign = GetSelectOptionsFromEnum(MenuItemVerticalPosition);
  menuTypes = GetSelectOptionsFromEnum(MenuTypesEnum);
  menuItemUiType = GetSelectOptionsFromEnum(MenuItemUiType);
  menuItemRepresentationType = GetSelectOptionsFromEnum(MenuRepresentation);
  defaultElvisActions = GetSelectOptionsFromEnum(DefaultElvisActions);
  elvisActions: TreeNode[];
  currentPageBehavior = GetSelectOptionsFromEnum(MenuItemCurrentPageBehaviorEnum);
  linkQueryParamAction = GetSelectOptionsFromEnum(LinkQueryParamActionEnum);
  flexDirection: SelectOption<FlexDirection, FlexDirection>[] = GetSelectOptionsFromEnum<FlexDirection>(FlexDirection);

  constructor() {
    super();

    const parts = Object.keys(MenuActionParts);
    this.elvisActions = parts.map((part: string, i: number) => ({
      children: this.getChildrenByGroupName(part), selectable: false, expanded : false, key: `group-${i}`, label: MenuActionParts[(part as keyof typeof MenuActionParts)], styleClass: 'group-node',
    }));
  }

  private getChildrenByGroupName(name: string): TreeNode[] {
    const groups = {
      [MenuActionParts.other]: [
        DefaultElvisActions.goToProfile,
        DefaultElvisActions.logout,
        DefaultElvisActions.customEvent,
        DefaultElvisActions.doNothing,
      ],
      [MenuActionParts.navigation]: [
        MenuItemCurrentPageBehaviorEnum.leaveCurrentPage
      ],
      [MenuActionParts.query]: [
        MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage,
        DefaultElvisActions.removeAllQueryParams,
        DefaultElvisActions.removeQueryParamsFromThisMenuWidget,
      ],
    };

    // @ts-ignore
    const group = groups[MenuActionParts[name as keyof typeof MenuActionParts]];
    return group ? group.map((item: string, i: number) => this.getTreeItem(item, `${name}-item-${i}`)): [];

  }

  private getTreeItem(name: string, id: string): TreeNode {
    return {
      label: name,
      longLabel: name,
      data: name,
      selectable: true,
      key: id,
    } as TreeNode;
  }
}

export enum MenuActionParts {
  other=  'Other',
  navigation = 'Navigation',
  query = 'Work with query parameters',

}

export enum MenuTypesEnum {
  horizontal = 'horizontal',
  tiered = 'tiered',
  panel = 'panel',
  // slide = 'slide'
}
