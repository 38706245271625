import { QueryParamsHandling } from '@angular/router';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { MenuItemAction, MenuItemActionDto } from '@widgets/menu-widget/types/menu-action-types';
import { MenuWidgetOptions } from './menu-option.types';
import { MenuWidgetSettings } from './menu-widget-settings.types';
import {
  ChipStyles,
  FontStyles,
  IconStyles,
  MenuItemContentStyles,
  MenuItemDefaultContentStyles,
  MenuItemGeneralStyles,
  MenuItemStyles,
  MenuWidgetAllStyles,
} from './menu-widget-styles.types';
import { MenuItemCurrentPageBehaviorEnum, MenuItemUiType, TreeItem } from './tree-types';

export class MenuWidgetValue {
  constructor(public model: MenuWidgetModel = new MenuWidgetModel()) {}
}

export class MenuWidgetModel {
  options = new MenuWidgetOptions();
  items = new MenuWidgetItems();
  styles = new MenuWidgetAllStyles();
  settings = new MenuWidgetSettings();
  selected = new Selected();
  styleTypes = ['Standard', 'On hover', 'On active link'];
  horizontalMenuContainers = ['left', 'center', 'right'];
  isFirstLoad = true;
  behaviorOptionDisabled = true;
  canRenderSettings = false;

  constructor(model?: MenuWidgetModelDto) {
    if (model) {
      model.styles && (this.styles = new MenuWidgetAllStyles(model.styles));
      model.settings && (this.settings = new MenuWidgetSettings(model.settings));
      model.menuItems && (this.items.menu = model.menuItems.map(item => new MenuItem({ ...item, expanded: false })));
    }
    this.rerenderSettings();
  }

  toServer(): MenuWidgetModelDto {
    this.deleteMenuItemParent(this.items.menu);
    return {
      menuItems: this.items.toServer(),
      styles: this.styles,
      settings: this.settings,
    };
  }

  rerenderSettings(): void {
    this.canRenderSettings = false;
    setTimeout(() => (this.canRenderSettings = true));
  }

  private deleteMenuItemParent(items: MenuItem[]): void {
    items.forEach(item => {
      item.parent && delete item.parent;
      item.children && this.deleteMenuItemParent(item.children);
    });
  }
}

export interface MenuWidgetModelDto {
  menuItems: MenuItem[];
  styles: MenuWidgetAllStyles;
  settings: MenuWidgetSettings;
}

export class MenuWidgetItems {
  tree: TreeItem[] = [];
  menu: MenuItem[] = [];

  constructor(items?: Partial<MenuWidgetItems>) {
    if (items) {
      items.tree && (this.tree = items.tree.map(item => new TreeItem(item)));
      items.menu && (this.menu = items.menu.map(item => new MenuItem(item)));
    }
  }

  toServer(): MenuItem[] {
    return this.menu.map(menuItem => menuItem.toServer());
  }
}

export class MenuItem {
  showEmptyParamsAsActive = false;
  hash: string;
  imageUrl?: string;
  menuIcon: string;
  label: string;
  subLabel: string;
  chip: string;
  usesDefaultStyle = true;
  target?: string;
  command?: (event?: any) => void;
  expanded?: boolean;
  disabled?: boolean;
  visible?: boolean;
  escape?: boolean;
  routerLinkActiveOptions?: any;
  separator?: boolean;
  badge?: string;
  badgeStyleClass?: string;
  title?: string;
  id?: string;
  uiType = MenuItemUiType.button;
  actions: MenuItemAction[] = [new MenuItemAction()];
  automationId?: any;
  tabindex?: string;
  routerLink?: any;
  queryParamsHandling?: QueryParamsHandling;
  preserveFragment?: boolean;
  skipLocationChange?: boolean;
  replaceUrl?: boolean;
  state?: {
    [k: string]: any;
  };
  children: MenuItem[] = [];
  parent?: MenuItem | null = null;

  constructor(dto?: Partial<MenuItem>) {
    dto && this.fromDto(dto);
  }

  get isTextOnly(): boolean {
    return this.children.length > 0 || this.actions.filter(action => action.type !== DefaultElvisActions.doNothing).length > 0;
  }

  get isStayOnCurrentPage(): boolean {
    let isStayOnCurrentPage = false;

    this.actions.forEach(action => {
      action.type === MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage && (isStayOnCurrentPage = true);
    });

    return isStayOnCurrentPage;
  }

  fromDto(dto: Partial<MenuItem>): void {
    if (dto) {
      Object.assign(this, dto);
      dto.actions?.length && (this.actions = dto.actions.map(action => new MenuItemAction(action as MenuItemActionDto)));
      dto.children?.length && (this.children = dto.children.map(item => new MenuItem({ ...item, menuIcon: item.menuIcon || '' })));
    }
  }

  toServer(): MenuItem {
    const dto = new MenuItem({ ...this, menuIcon: this.menuIcon || '' });
    dto.actions = this.actions.map(action => action.toServer()) as MenuItemAction[];
    dto.children.forEach(child => {
      child.actions = child.actions.map(action => action.toServer()) as MenuItemAction[];
    });
    return dto;
  }
}

export class Selected {
  menuItem: MenuItem | null = null;
  treeItem: TreeItem | null = null;
  itemStyle: MenuItemStyles | null = null;
  style: MenuItemContentStyles | null = null;
  defaultStyle: MenuItemDefaultContentStyles | null = null;
  contentStyle: FontStyles | IconStyles | ChipStyles;
  defaultGeneralStyle: MenuItemGeneralStyles;

  constructor(selected?: Partial<Selected>) {
    selected && Object.assign(this, selected);
  }
}
