import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { PageHelper } from '@shared/helpers/page-helper';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { BackgroundTypeEnum, StyleOptions } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-page-settings',
  templateUrl: './page-settings.component.html',
  styleUrls: ['./page-settings.component.scss'],
})
export class PageSettingsComponent implements OnInit {

  @Input() page: Page;
  readonly styleOptions: StyleOptions = new StyleOptions();
  readonly backgroundType: typeof BackgroundTypeEnum = BackgroundTypeEnum;
  gradientChangeCb: () => void;

  applicationId: string;

  constructor(
    private pageHelper: PageHelper,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly authorizationService: AuthorizationService,
  ) { }

  ngOnInit(): void {
    if (!this.page.seoFields) this.page.seoFields = {};
    if (!this.page.seoFields.ogTitle) this.page.seoFields.ogTitle = this.page.name;
    this.gradientChangeCb = this.gradientChange.bind(this);
    this.applicationId = this.applicationSwitcherService.selectedApplication?.id || '';
  }

  stylesTypeChange(): void {
    this.page.settings.styles.background = '';
    this.pageHelper.setBackgroundToBody(this.page.settings.styles, this.authorizationService.getToken, this.authorizationService.getAnonymousToken);
  }

  gradientChange(): void {
    setTimeout(() => this.pageHelper.setBackgroundToBody(this.page.settings.styles, this.authorizationService.getToken, this.authorizationService.getAnonymousToken));
  }

  onBackgroundImageUrlChange(value: string) {
    this.page.settings.styles.background = `url(${value})`;
    this.gradientChangeCb();
  }

  onBackgroundFileIdChange(id: string) {
    this.page.settings.styles.background = id;
    this.gradientChangeCb();
  }
}
