/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { JobsResponseDto } from '../../models/jobs-response-dto';
import { LinkImportRequestDto } from '../../models/link-import-request-dto';

export interface LinkControllerImport$Params {
      body: LinkImportRequestDto

}

export function linkControllerImport(http: HttpClient, rootUrl: string, params: LinkControllerImport$Params, context?: HttpContext): Observable<StrictHttpResponse<JobsResponseDto>> {
  const rb = new RequestBuilder(rootUrl, linkControllerImport.PATH, 'post');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }
  rb.header('ngsw-bypass', 'true', {});

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<JobsResponseDto>;
    })
  );
}

linkControllerImport.PATH = '/api/tenant/link/import';
