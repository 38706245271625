import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FolderResponseDto } from '@api/models';
import { Application } from '@private/pages/application-management/application/types/application.types';
import { FOLDER_PATH_SEPARATOR } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { NewApplication } from '@shared/types/application.types';
import { FolderFilterData } from '@shared/types/filter.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ListContainer } from '@shared/types/list-container.types';
import { TreeNode } from 'primeng/api';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { FolderFilterHelperService } from './service';

@Component({
  selector: 'app-folder-filter',
  templateUrl: './folder-filter.component.html',
  styleUrls: ['./folder-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderFilterComponent {
  @Input() applications: ListContainer<Application>;
  @Input() showControlButtons = true;
  @Input() controlButtonsStyleClass: string;
  @Output() onFilterApply: EventEmitter<FolderFilterData> = new EventEmitter();
  @Output() onFilterClear: EventEmitter<any> = new EventEmitter();
  @Output() onFilterDataUpdate: EventEmitter<FolderFilterData> = new EventEmitter();
  folders$: Observable<TreeNode<FolderResponseDto>[]>;
  selectedFolders: TreeNode<FolderResponseDto>[] = [];
  selectedApplication?: NewApplication;
  includeSubfolders: boolean;
  externalFilterApplied: boolean;
  private folders?: TreeNode<FolderResponseDto>[];

  constructor(
    private readonly folderFilterHelper: FolderFilterHelperService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  @Input() set folderFilterData(data: FolderFilterData) {
    this.setFolderFilterDataFromOutside(data);
  }

  onApplyClick() {
    const selectedFolders = this.selectedFolders || [this.getFolderByApplication()];
    selectedFolders.forEach(folderNode => (folderNode.expanded = false));
    const folderFilterData = this.getCurrentFolderFilterData();
    if (this.includeSubfolders) {
      folderFilterData.folderPathForChildren = selectedFolders[0].data?.folderPath + '' + selectedFolders[0].data?.name + FOLDER_PATH_SEPARATOR;
    }
    this.onFilterApply.emit(folderFilterData);
  }

  onClearClick() {
    this.onFilterClear.emit();
  }

  onApplicationSelection(application: NewApplication) {
    this.selectedApplication = application;
    this.folders = undefined;
    this.initFoldersObserver(this.selectedApplication, !this.externalFilterApplied);
  }

  folderNodeExpand(parent: TreeNode<FolderResponseDto>) {
    if (parent.children) {
      return;
    }
    this.getFolderFilter$(parent).subscribe(children => {
      parent.children = children;
      this.cdr.markForCheck();
    });
  }

  folderNodeSelect(event: PointerEvent, node: TreeNode<FolderResponseDto>) {
    const metaKey = event.metaKey || event.ctrlKey;
    if (metaKey && this.includeSubfolders) {
      this.selectedFolders = this.selectedFolders.filter(treeNode => treeNode !== node);
      return;
    }
  }

  private setFolderFilterDataFromOutside(data: FolderFilterData) {
    if (data) {
      this.selectedApplication = !data.externalFilterApplied ? data.application : undefined;
      this.folders = data.folders;
      this.selectedFolders = data.selectedFolders || [];
      this.includeSubfolders = data.includeSubfolders || false;
      if (data.externalFilterApplied) {
        this.externalFilterApplied = data.externalFilterApplied;
        return;
      }
      if (this.selectedApplication) {
        this.initFoldersObserver(this.selectedApplication);
        return;
      }
      if (data.folderPathForChildren) {
        this.getFoldersByArtifactFolderPath$(data.folderPathForChildren).subscribe(folders => {
          const rootFolderNode = folders[0];
          const folderPathSplits = data.folderPathForChildren?.split(FOLDER_PATH_SEPARATOR) as string[];
          const selectedFolderName = folderPathSplits[folderPathSplits?.length - 2];
          const selectedFolder = folders.find(folderNode => folderNode.data?.name === selectedFolderName);
          this.folders = [rootFolderNode];
          this.selectedApplication = this.applications.list.find(app => app.defaultFolderId === rootFolderNode.data?.id);
          this.selectedFolders = selectedFolder ? [selectedFolder] : [];
          if (selectedFolder) {
            let currentFolderParent = selectedFolder.parent;
            while (currentFolderParent) {
              currentFolderParent.expanded = true;
              currentFolderParent = currentFolderParent.parent;
            }
          }
          this.initFoldersObserver(this.selectedApplication as NewApplication, true);
          this.cdr.markForCheck();
        });
      }
    }
  }

  private initFoldersObserver(selectedApplication: NewApplication, doNotifyFilterDataUpdate = false) {
    this.folders$ = of(true).pipe(
      switchMap(() =>
        this.folders
          ? of(this.folders)
          : this.getFolderFilter$(null, selectedApplication).pipe(
              map(folders => {
                const teamsFolderId = GlobalConstants.getValue(GlobalConstantsEnum.teamsFolderId) || '';
                const usersFolderId = GlobalConstants.getValue(GlobalConstantsEnum.usersFolderId) || '';

                return folders.filter(folder => folder.data?.id !== teamsFolderId && folder.data?.id !== usersFolderId);
              }),
              tap(folders => (this.folders = folders)),
            ),
      ),
      tap(() => {
        if (doNotifyFilterDataUpdate) {
          const folderFilterEntry = this.getCurrentFolderFilterData();
          this.onFilterDataUpdate.emit(folderFilterEntry);
        }
      }),
    );
  }

  private getFolderFilter$(parentFolder: TreeNode | null, application?: NewApplication): Observable<TreeNode<FolderResponseDto>[]> {
    return this.folderFilterHelper
      .getFoldersByParentAndApplication$(parentFolder?.data.id, application)
      .pipe(map(sortedFolders => sortedFolders.map(folder => this.toTreeNode(folder))));
  }

  private getFolderByApplication(): TreeNode<FolderResponseDto> {
    return this.folders?.find(folder => (this.selectedApplication as NewApplication).defaultFolderId === folder.data?.id) as TreeNode<FolderResponseDto>;
  }

  private getFoldersByArtifactFolderPath$(folderPath: string): Observable<TreeNode<FolderResponseDto>[]> {
    return this.folderFilterHelper.getFoldersByArtifactFolderPath$(folderPath).pipe(
      map(sortedFolders => sortedFolders.map(folder => this.toTreeNode(folder))),
      map(sortedTreeNodes => {
        const sortedTreeNodesMap = sortedTreeNodes.reduce((map: Record<string, TreeNode<FolderResponseDto>>, item) => {
          map[item.data?.id as string] = item;
          return map;
        }, {});
        sortedTreeNodes
          .filter(treeNode => !!treeNode.data?.parentFolderId)
          .forEach(treeNode => {
            const parent = sortedTreeNodesMap[treeNode.data?.parentFolderId as string];
            treeNode.parent = parent;
            parent.children = parent.children ? [...parent.children, treeNode] : [treeNode];
          });
        return sortedTreeNodes;
      }),
    );
  }

  private toTreeNode(folder: FolderResponseDto): TreeNode<FolderResponseDto> {
    const treeNode: TreeNode<FolderResponseDto> = {
      data: folder,
      label: folder.name,
      leaf: !folder.hasChilds,
    };
    return treeNode;
  }

  private getCurrentFolderFilterData(): FolderFilterData {
    return {
      application: this.selectedApplication,
      selectedFolders: this.selectedFolders,
      folders: this.folders,
      includeSubfolders: this.includeSubfolders,
    };
  }
}
