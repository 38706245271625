import { Injectable } from '@angular/core';
import { FILTER_OPERATOR_AND } from '@shared/constants/constants';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import {
  GetDateMinusDays,
  GetDatePlusDays,
  GetEndOfTheDay,
  GetEndOfTheMinute,
  GetFirstDayOfCurrentMonth,
  GetFirstDayOfCurrentYear,
  GetFirstDayOfLastMonth,
  GetFirstDayOfLastYear,
  GetFirstDayOfNextMonth,
  GetFirstDayOfNextYear,
  GetLastDayOfCurrentMonth,
  GetLastDayOfCurrentYear,
  GetLastDayOfLastMonth,
  GetLastDayOfLastYear,
  GetLastDayOfMonthAfterMonths,
  GetLastDayOfNextMonth,
  GetLastDayOfNextYear,
  GetLastMondayDate,
  GetLastSundayDate,
  GetMondayOfCurrentWeekDate,
  GetNextMondayDate,
  GetNextSundayDate,
  GetStartOfTheDay,
  GetStartOfTheMinute,
  GetSundayOfCurrentWeekDate,
} from '@shared/methods/date.methods';
import { CustomDateSettings, DateFilterEnum, DateRange, DateRangeFilterEnum } from '@shared/types/filter.types';
import { FilterMetadata } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class FilterMetadataUtil {
  transformDateRangeEnumToDateRange(dateRange: DateRangeFilterEnum, customSettings: CustomDateSettings): DateRange {
    switch (dateRange) {
      case DateRangeFilterEnum.dateBetween:
        return {
          start: customSettings.isDateTime ? customSettings.start : GetStartOfTheDay(customSettings.start),
          end: customSettings.isDateTime ? customSettings.end : GetEndOfTheDay(customSettings.end),
        };
      case DateRangeFilterEnum.custom:
        return { start: customSettings.start, end: customSettings.end };
      case DateRangeFilterEnum.dueInDays:
        return this.dueInDaysRange(customSettings.offsetInDays);
      case DateRangeFilterEnum.dueInDaysOrLess:
        return this.dueInDaysOrLessRange(customSettings.offsetInDays);
      case DateRangeFilterEnum.dueInDaysOrMore:
        return this.dueInDaysOrMoreRange(customSettings.offsetInDays);
      case DateRangeFilterEnum.ageInDays:
        return this.ageInDaysRange(customSettings.offsetInDays);
      case DateRangeFilterEnum.ageInDaysOrLess:
        return this.ageInDaysOrLessRange(customSettings.offsetInDays);
      case DateRangeFilterEnum.ageInDaysOrMore:
        return this.ageInDaysOrMoreRange(customSettings.offsetInDays);
      case DateRangeFilterEnum.yesterday:
        return this.getRangeFromDate(GetDateMinusDays(new Date(), 1));
      case DateRangeFilterEnum.today:
        return this.getRangeFromDate(new Date());
      case DateRangeFilterEnum.tomorrow:
        return this.getRangeFromDate(GetDatePlusDays(new Date(), 1));
      case DateRangeFilterEnum.afterToday:
        return this.afterToday();
      case DateRangeFilterEnum.beforeToday:
        return this.beforeToday();
      case DateRangeFilterEnum.todayOrAfter:
        return this.todayOrAfter();
      case DateRangeFilterEnum.todayOrBefore:
        return this.todayOrBefore();
      case DateRangeFilterEnum.lastWeek:
        return this.lastWeekRange();
      case DateRangeFilterEnum.currentWeek:
        return this.currentWeekRange();
      case DateRangeFilterEnum.nextWeek:
        return this.nextWeekRange();
      case DateRangeFilterEnum.lastMonth:
        return this.lastMonthRange();
      case DateRangeFilterEnum.currentMonth:
        return this.currentMonthRange();
      case DateRangeFilterEnum.nextMonth:
        return this.nextMonthRange();
      case DateRangeFilterEnum.lastYear:
        return this.lastYearRange();
      case DateRangeFilterEnum.currentYear:
        return this.currentYearRange();
      case DateRangeFilterEnum.nextYear:
        return this.nextYearRange();
      case DateRangeFilterEnum.last7Days:
        return this.lastDaysRange(7);
      case DateRangeFilterEnum.last30Days:
        return this.lastDaysRange(30);
      case DateRangeFilterEnum.last60Days:
        return this.lastDaysRange(60);
      case DateRangeFilterEnum.last90Days:
        return this.lastDaysRange(90);
      case DateRangeFilterEnum.last120Days:
        return this.lastDaysRange(120);
      case DateRangeFilterEnum.next6Months:
        return this.nextMonthsRange(6);
      case DateRangeFilterEnum.next12Months:
        return this.nextMonthsRange(12);
      default:
        return { start: new Date(), end: new Date() };
    }
  }

  getFilterMetadataFromRange(startEndDate: DateRange): FilterMetadata[] {
    return [
      this.specificDateFilter(startEndDate.start, CoreListFilterEnum.dateAfterOrEqualTo),
      this.specificDateFilter(startEndDate.end, CoreListFilterEnum.dateBeforeOrEqualTo),
    ];
  }

  getRangeFromDate(date: Date): DateRange {
    return { start: GetStartOfTheDay(date), end: GetEndOfTheDay(date) };
  }

  getRangeOfExactMinute(date: Date): DateRange {
    return { start: GetStartOfTheMinute(date), end: GetEndOfTheMinute(date) };
  }

  isFilterRange(dateRange: DateRangeFilterEnum): boolean {
    return dateRange === DateRangeFilterEnum.dateBetween;
  }

  isFilterNumeric(dateRange: DateRangeFilterEnum): boolean {
    const { dueInDays, dueInDaysOrMore, dueInDaysOrLess, ageInDays, ageInDaysOrMore, ageInDaysOrLess } = DateRangeFilterEnum;
    return [dueInDays, dueInDaysOrMore, dueInDaysOrLess, ageInDays, ageInDaysOrMore, ageInDaysOrLess].includes(dateRange);
  }

  isDateRangeAutomaticallyComputed(filterType: DateRangeFilterEnum | DateFilterEnum | undefined): boolean {
    const { dateBetween, custom, dueInDays, dueInDaysOrMore, dueInDaysOrLess, ageInDays, ageInDaysOrMore, ageInDaysOrLess } = DateRangeFilterEnum;
    return (
      !!filterType &&
      !Object.prototype.hasOwnProperty.call(DateFilterEnum, filterType) &&
      ![dateBetween, custom, dueInDays, dueInDaysOrMore, dueInDaysOrLess, ageInDays, ageInDaysOrMore, ageInDaysOrLess].includes(
        filterType as DateRangeFilterEnum,
      )
    );
  }

  isFilterValidAndActive(value: any, filterType: DateRangeFilterEnum | DateFilterEnum | undefined): boolean {
    if (!this.isDateRangeAutomaticallyComputed(filterType)) {
      return !!value;
    }
    return !!filterType;
  }

  transformDateRangeToMongoQuery({ start, end }: DateRange, attributeKey: string, useIsoDate: boolean): any {
    const query: { $and: any[] } = { $and: [] };

    if (start) {
      query.$and.push({ [attributeKey]: { $gte: useIsoDate ? { $date: start } : start } });
    }

    if (end) {
      query.$and.push({ [attributeKey]: { $lte: useIsoDate ? { $date: end } : end } });
    }

    return query;
  }

  private dueInDaysRange(offsetInDays: number): DateRange {
    return this.getRangeFromDate(GetDatePlusDays(new Date(), offsetInDays));
  }

  private dueInDaysOrLessRange(offsetInDays: number): DateRange {
    return { start: GetStartOfTheDay(new Date()), end: GetEndOfTheDay(GetDatePlusDays(new Date(), offsetInDays)) };
  }

  private dueInDaysOrMoreRange(offsetInDays: number): DateRange {
    return { start: GetStartOfTheDay(GetDatePlusDays(new Date(), offsetInDays)), end: null as any };
  }

  private ageInDaysRange(offsetInDays: number): DateRange {
    return this.getRangeFromDate(GetDateMinusDays(new Date(), offsetInDays));
  }

  private ageInDaysOrLessRange(offsetInDays: number): DateRange {
    return { start: GetStartOfTheDay(GetDateMinusDays(new Date(), offsetInDays)), end: null as any };
  }

  private ageInDaysOrMoreRange(offsetInDays: number): DateRange {
    return { start: null as any, end: GetEndOfTheDay(GetDateMinusDays(new Date(), offsetInDays)) };
  }

  private lastWeekRange(): DateRange {
    return { start: GetStartOfTheDay(GetLastMondayDate()), end: GetEndOfTheDay(GetLastSundayDate()) };
  }

  private currentWeekRange(): DateRange {
    return { start: GetStartOfTheDay(GetMondayOfCurrentWeekDate()), end: GetEndOfTheDay(GetSundayOfCurrentWeekDate()) };
  }

  private nextWeekRange(): DateRange {
    return { start: GetStartOfTheDay(GetNextMondayDate()), end: GetEndOfTheDay(GetNextSundayDate()) };
  }

  private lastMonthRange(): DateRange {
    return { start: GetStartOfTheDay(GetFirstDayOfLastMonth()), end: GetEndOfTheDay(GetLastDayOfLastMonth()) };
  }

  private currentMonthRange(): DateRange {
    return { start: GetStartOfTheDay(GetFirstDayOfCurrentMonth()), end: GetEndOfTheDay(GetLastDayOfCurrentMonth()) };
  }

  private nextMonthRange(): DateRange {
    return { start: GetStartOfTheDay(GetFirstDayOfNextMonth()), end: GetEndOfTheDay(GetLastDayOfNextMonth()) };
  }

  private lastYearRange(): DateRange {
    return { start: GetStartOfTheDay(GetFirstDayOfLastYear()), end: GetEndOfTheDay(GetLastDayOfLastYear()) };
  }

  private currentYearRange(): DateRange {
    return { start: GetStartOfTheDay(GetFirstDayOfCurrentYear()), end: GetEndOfTheDay(GetLastDayOfCurrentYear()) };
  }

  private nextYearRange(): DateRange {
    return { start: GetStartOfTheDay(GetFirstDayOfNextYear()), end: GetEndOfTheDay(GetLastDayOfNextYear()) };
  }

  private lastDaysRange(numberOfDays: number): DateRange {
    return { start: GetStartOfTheDay(GetDateMinusDays(new Date(), numberOfDays - 1)), end: GetEndOfTheDay(new Date()) };
  }

  private nextMonthsRange(numberOfMonths: number): DateRange {
    return { start: GetStartOfTheDay(new Date()), end: GetEndOfTheDay(GetLastDayOfMonthAfterMonths(numberOfMonths)) };
  }

  private specificDateFilter(value: Date | undefined, matchMode: CoreListFilterEnum, operator = FILTER_OPERATOR_AND): FilterMetadata {
    return { value: value || null, matchMode, operator };
  }

  private afterToday(): DateRange {
    return { start: GetStartOfTheDay(GetDatePlusDays(new Date(), 1)), end: null as any };
  }

  private beforeToday(): DateRange {
    return { start: null as any, end: GetEndOfTheDay(GetDateMinusDays(new Date(), 1)) };
  }

  private todayOrAfter(): DateRange {
    return { start: GetStartOfTheDay(new Date()), end: null as any };
  }

  private todayOrBefore(): DateRange {
    return { start: null as any, end: GetEndOfTheDay(new Date()) };
  }
}
