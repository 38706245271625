import { Injectable } from '@angular/core';
import { TenantUserService } from '@api/services/tenant-user.service';
import { Environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { CLIENT_DATA_KEY, LANGUAGE_KEY } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { ClientData, LocalStorageSessionState, StateKey } from '@shared/types/local-storage.types';
import { SelectOption } from '@shared/types/shared.types';
import { lastValueFrom } from 'rxjs';
import { LanguageEnum } from '../types/language-switcher.types';

@Injectable({ providedIn: 'root' })
export class LanguageSwitcherService {
  public languages: SelectOption<string, LanguageEnum>[];
  public selectedLanguage: LanguageEnum;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly translate: TranslateService,
    private readonly tenantUserService: TenantUserService,
    private readonly announcement: AnnouncementService,
    private readonly cache: NewCacheService,
  ) {
    this.init();
  }

  public changeLanguage(lang: LanguageEnum): void {
    this.translate.use(lang);
    const sessionState: LocalStorageSessionState = this.localStorageService.get(StateKey.session);

    if (sessionState) {
      const newClientData = { ...sessionState[CLIENT_DATA_KEY], [LANGUAGE_KEY]: lang };

      this.localStorageService.setToState(StateKey.session, CLIENT_DATA_KEY, newClientData);

      if (sessionState && sessionState.user && sessionState.user.id) {
        lastValueFrom(this.tenantUserService.userControllerUpdateClientData({ body: newClientData })).catch(() =>
          this.announcement.error('Client data could not be saved'),
        );
      }
    }
  }

  private init(): void {
    this.localStorageService.initialize();
    const clientData: ClientData = this.localStorageService.getFromState(StateKey.session, CLIENT_DATA_KEY);

    this.translate.addLangs(Environment.translations.locales);
    this.translate.setDefaultLang(Environment.translations.defaultLocale);
    this.translate.use(clientData.language);

    this.languages = this.translate.langs.map(lang => new SelectOption(lang));
    this.selectedLanguage = clientData.language;
  }
}
