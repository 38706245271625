<app-loader [loading]="m.loading"></app-loader>

<div class="grid">
  <div class="col-12 top_box">
    <div *ngIf="m.loggedUserMeta && m.loggedUserMeta.isTenantAdmin">
      <div class="p-inputgroup">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [ngModel]="!!m.user.deleted" [disabled]="true" binary="true" inputId="isDeleted"
                        id="isDeleted"></p-checkbox>
            <label for="isDeleted" style="cursor: default">{{ IS_DELETED_LABEL | translate }}</label>
          </span>
        </span>
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="m.user.isLoginDisabled" binary="true" inputId="isLoginDisabled"></p-checkbox>
            <label style="width: 120px" for="isLoginDisabled">{{ 'Is Login Disabled' | translate }}</label>
          </span>
        </span>
        <span *ngIf="!m.user?.id" class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="m.generatePassword" binary="true" inputId="generatePassword"></p-checkbox>
            <label style="width: 150px" for="generatePassword">
              {{ 'Generate password' | translate }}
              <i class="pi pi-info-circle ml-1"
                 pTooltip="{{ 'Generated password will be sent to user via Email' | translate }}"
                 tooltipPosition="top"></i>
            </label>
          </span>
        </span>
      </div>
    </div>
    <div>
      <a *ngIf="m.userProfileUrl !== null" [routerLink]="m.userProfileUrl!.routerLink"
         [queryParams]="m.userProfileUrl!.queryParams">
        <button
          pButton
          pTooltip="{{ 'User profile page' | translate }}"
          type="button"
          icon="pi pi-eye"
          class="p-button p-button-rounded p-button-secondary p-button-outlined"
        ></button>
      </a>
      <button
        (click)="resetPasswordWithConfirmation()"
        *ngIf="m.loggedUserMeta && m.loggedUserMeta.isTenantAdmin && m.user && m.user.id && !m.user.deleted"
        [label]="'Reset password' | translate"
        class="p-button p-button-secondary"
        pButton
      ></button>
      <button
        *ngIf="m.loggedUserMeta && m.loggedUserMeta.isTenantAdmin && m.user && m.user.id && m.user.deleted"
        (click)="restoreWithConfirmation()"
        [label]="'Restore' | translate"
        class="p-button p-button-info"
        pButton
      ></button>
      <button
        *ngIf="m.loggedUserMeta && m.loggedUserMeta.isTenantAdmin && m.user && m.user.id && !m.user.deleted"
        (click)="deleteWithConfirmation()"
        [label]="'Delete' | translate"
        class="p-button p-button-danger"
        pButton
      ></button>
      <a [routerLink]="['/admin/user-list']">
        <button class="p-button p-button-warning" label="{{ 'Cancel' | translate }}" pButton type="button"></button>
      </a>
      <button (click)="save()" class="p-button p-button-success" label="{{ 'Save' | translate }}" pButton type="button">
        <span *ngIf="m.inSavingProgress" class="pi pi-spin pi-spinner"></span>
      </button>
    </div>
  </div>

  <app-admin-user-form [m]="m" class="flex-grow-1"></app-admin-user-form>

  <div class="col-12" *ngIf="m.loggedUserMeta && applicationSwitcherService.selectedApplication">
    <app-tenant-admin-user-form *ngIf="m.loggedUserMeta.isTenantAdmin" [m]="m"></app-tenant-admin-user-form>
    <app-application-admin-user-form
      *ngIf="!m.loggedUserMeta.isTenantAdmin && m.loggedUserMeta.isApplicationAdmin"
      [m]="m"
      [currentApplication]="applicationSwitcherService.selectedApplication"
    >
    </app-application-admin-user-form>
  </div>

  <div class="col-3" *ngIf="m.teams">
    <div class="dynamic-label p-float-label">
      <p-dropdown [(ngModel)]="m.defaultTeamId" [options]="m.teams" [placeholder]="'Default team' | translate"
                  id="defaultTeam"></p-dropdown>
      <label for="defaultTeam" translate> Default team </label>
    </div>
  </div>

  <div class="col-3">
    <div class="dynamic-label p-float-label">
      <p-dropdown [(ngModel)]="m.defaultApplicationId" [autoDisplayFirst]="false" [options]="defaultApplicationOptions"
                  (onShow)="setDefaultApplicationOptions()"
                  [showClear]="true"
                  id="defaultApplication"></p-dropdown>
      <label for="defaultApplication" translate> Default application </label>
    </div>
  </div>
</div>
