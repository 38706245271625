import { Component, Input, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewTableColumn } from '@shared/types/table.types';
import { ArtifactListWidgetTableHelper } from '../../services/artifact-list-widget-table-helper.service';
import { AddArtifactTableLinkItem, ArtifactListTableModel, DeleteArtifactTableLinkItem } from '../../types/artifact-list-widget-table.types';
import { GroupItem } from '../../types/list-widget-grouping.types';
import { ListWidgetOptions } from '../../types/list-widget-options.types';
import { ListWidgetTableSettings } from '../../types/list-widget-settings.types';

@Component({
  selector: 'app-artifact-list-table-group',
  templateUrl: './artifact-list-table-group.component.html',
  styleUrls: ['./artifact-list-table-group.component.scss'],
})
export class ArtifactListTableGroupComponent {
  @Input() template: TemplateRef<any>;
  @Input() m: ArtifactListTableModel;
  @Input() groupItem: GroupItem;
  @Input() columns: NewTableColumn[];
  @Input() settings: ListWidgetTableSettings;
  @Input() options: ListWidgetOptions;
  @Input() groupIndex: number;
  @Input() editing: boolean;
  @Input() canShowTooltipMap: Record<string, boolean>;
  @Input() columnHtmlTypeMap: Record<string, any>;
  @Input() columnFileTypeMap: Record<string, any>;
  @Input() onRowEditInit: (artifact: NewArtifact) => void;
  @Input() onRowEditSave: (artifact: NewArtifact, index: number, checkGroups?: boolean) => void;

  @Input() handleAddLinkClick: (addArtifactTableLinkItem: AddArtifactTableLinkItem, index: number) => void;
  @Input() handleDeleteLinkClick: (deleteArtifactTableLinkItem: DeleteArtifactTableLinkItem) => void;

  constructor(public readonly helper: ArtifactListWidgetTableHelper, private readonly router: Router, private readonly announcement: AnnouncementService) {}

  toggleGroup(): void {
    if (this.groupItem.metaData) {
      this.groupItem.metaData.toggled = !this.groupItem.metaData.toggled;
    }
  }

  isMandatoryField(artifact: NewArtifact, key: string): boolean {
    return artifact.attributes[key]?.isMandatory;
  }

  async onNavigateToArtifact(artifact: NewArtifact): Promise<void> {
    const artifactType = this.options.artifactTypes.listMap[artifact.artifactTypeId];
    if (artifactType?.defaultPageId) {
      const queryParams = { artifactId: artifact.id };
      await this.router.navigate([artifactType.defaultPageId], { queryParams });
    } else {
      await this.announcement.warn(`Artifact-type '${artifactType.name}' does not have assigned default page.`);
    }
  }
}
