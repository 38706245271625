import { PictureFormatEnum, PictureLinkOptions, PictureOverlay, PicturePositionEnum, PictureRepeatEnum, PictureSizeEnum } from '../types/picture-widget.types';

export class PictureWidgetSettings {
  useExternalLink = true;
  fileId: string | null = null;
  url = 'assets/images/default-avatar.jpg';
  alt = 'Default picture';
  openInLightbox = false;
  format: PictureFormatEnum = PictureFormatEnum.rectangle;
  height = '100px';
  repeat: PictureRepeatEnum = PictureRepeatEnum.noRepeat;
  borderRadius = 0;
  backgroundColorContainer = 'initial';
  backgroundColorPicture = 'initial';
  pictureSize: PictureSizeEnum = PictureSizeEnum.contain;
  picturePosition: PicturePositionEnum = PicturePositionEnum.centerCenter;
  pictureOverlay: PictureOverlay = { enabled: false, opacity: 0.5, color: '#ffffff' };
  pictureLinkOptions: PictureLinkOptions = { enabled: false, openInNewTab: true, external: true, url: '', page: null };
  openSidebar = false;
  sidebarId: string;

  constructor(dto?: PictureWidgetSettingsDto) {
    dto && Object.assign(this, dto);
  }

  toServer(): PictureWidgetSettingsDto {
    return new PictureWidgetSettingsDto(this);
  }
}

export class PictureWidgetSettingsDto {
  url: string;
  alt: string;
  openInLightbox: boolean;
  format: PictureFormatEnum;
  height: string;
  repeat: PictureRepeatEnum;
  borderRadius: number;
  backgroundColorContainer: string;
  backgroundColorPicture: string;
  pictureSize: PictureSizeEnum;
  picturePosition: PicturePositionEnum;
  pictureOverlay: PictureOverlay;
  pictureLinkOptions: PictureLinkOptions;
  openSidebar: boolean;
  sidebarId: string;

  constructor(settings: PictureWidgetSettings) {
    Object.assign(this, settings);
  }
}
