import { Directive, ElementRef, Input, NgZone } from '@angular/core';
import { AutocompleteService } from '@shared/components/context-autocomplete/services';
import { AutocompleteEventType, AutocompleteSelectionEvent } from '@shared/components/context-autocomplete/types/base/autocomplete.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { AttributeAutocompleteSelectionEvent } from '../types/attribute-autocomplete.types';
import { AbstractAutocompleteDirective, SupportAutoCompleteType } from './abstract/abstract-autocomplete.directive';

@Directive({
  selector: '[appAttributeAutocomplete]',
  standalone: true,
})
export class AttributeAutocompleteDirective extends AbstractAutocompleteDirective<NewAttribute> {
  @Input() attributes: NewAttribute[];

  @Input() variablePrefix = '{';
  @Input() variableSuffix = '}';

  constructor(elementRef: ElementRef, zone: NgZone, contextVariableAutocompleteService: AutocompleteService<NewAttribute>) {
    super(elementRef, zone, contextVariableAutocompleteService);
  }

  protected doOpenOverlay(variableName: string, doFocusFirstItem?: boolean) {
    this.initEvent = {
      options: this.attributes,
      ownerId: this.ownerId,
      variableNamePart: variableName,
      event: this.event,
      doFocusFirstItem,
      caretPositionInQuery: this.caretPosition,
      type: AutocompleteEventType.attribute,
    };
    this.contextVariableAutocompleteService.notifyAutocompleteInit(this.initEvent);
  }

  protected onVariableSelection(selectionEvent: AttributeAutocompleteSelectionEvent): void {
    const nativeElement = this.elementRef.nativeElement as SupportAutoCompleteType;
    const query = nativeElement.value;
    const caretPosition = selectionEvent.initEvent.caretPositionInQuery;
    const variableNamePart = selectionEvent.initEvent.variableNamePart;
    const variableIndex = this.getCurrentVariablePrefixIndex(query, caretPosition);
    const prefix = query.substring(0, variableIndex);
    const suffix = query.substring(variableIndex + variableNamePart.length + 1);
    const variableName = selectionEvent.selectedOption.alias;
    const result = prefix + this.variablePrefix + variableName + this.variableSuffix + suffix;
    const updatedCaretPosition = result.length - suffix.length;
    nativeElement.value = result;
    nativeElement.focus();
    nativeElement.setSelectionRange(updatedCaretPosition, updatedCaretPosition);
  }

  protected isFocusInPotentialVariable(query: string, caretPosition: number): boolean {
    const queryToCaret = query.substring(0, caretPosition);
    const variablePrefixIndex = queryToCaret.lastIndexOf(this.variablePrefix);
    const variableSuffixIndex = queryToCaret.lastIndexOf(this.variableSuffix);
    return variablePrefixIndex >= 0 && variableSuffixIndex < variablePrefixIndex;
  }

  protected shouldReactToEvent(event: AutocompleteSelectionEvent<NewAttribute>): boolean {
    return event.type === AutocompleteEventType.attribute;
  }
}
