import { ArtifactLinkResponseDto } from '@api/models';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { LabelPositionEnum, TextHorizontalAlignEnum } from '@widgets/shared/types/style.types';

export class NumberWidgetOptions {
  artifactTypes = new ListContainer<NewArtifactType>();
  attributes: NewAttribute[] = [];
  artifactAttributeValues = new ListContainer<NewAttribute>();
  artifactCountableAttributeValues = new ListContainer<NewAttribute>();
  dataTypes = new ListContainer<NewDataType>();
  linkTypes = new ListContainer<DirectionalLinkType>();
  numberTypes = new ListContainer<NumberTypes>();
  align = GetSelectOptionsFromEnum(TextHorizontalAlignEnum);
  labelPosition = GetSelectOptionsFromEnum(LabelPositionEnum);
  iconList: SelectOption<string, string>[] = [];

  users: ListContainer<ArtifactLinkResponseDto> = new ListContainer<ArtifactLinkResponseDto>();
  pages: ListContainer<Page> = new ListContainer<Page>();

  constructor() {
    this.numberTypes.setList(Object.keys(NumberTypes).map(key => NumberTypes[key as keyof typeof NumberTypes]));
  }
}

export enum NumberTypes {
  count = 'count',
  sum = 'sum',
  min = 'min',
  max = 'max',
  avg = 'average',
  first = 'first',
  last = 'last',
}
