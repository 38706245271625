import { Component, Input } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ID_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetArtifactAttributeValuePath } from '@shared/methods/client-attribute.methods';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ListContainer } from '@shared/types/list-container.types';
import { GetArtifactsKeys, WorkflowActionGetArtifacts } from '@workflows/types/actions/action-get-artifacts';

@Component({
  selector: 'app-action-get-artifacts',
  templateUrl: './action-get-artifacts.component.html',
  styleUrls: ['./action-get-artifacts.component.scss'],
})
export class ActionGetArtifactsComponent {
  @Input() action: WorkflowActionGetArtifacts;
  @Input() users: ListContainer<ArtifactLinkResponseDto>;

  protected readonly RuleKeys = GetArtifactsKeys;
  protected readonly NAME_VALUE_PATH = GetArtifactAttributeValuePath(GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId));
  protected readonly ID_VALUE = ID_KEY;
}
