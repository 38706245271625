import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { GenericArea } from '@shared/components/grid-layout-generator/types/generic-area';
import { StyleApplicationBreakpoint } from '@shared/components/grid-layout-generator/types/style-application-breakpoint';
import { CoreRxSubscriptionsComponent } from '@shared/core/components/core-rx-subscriptions.component';
import { SidebarWidgetService } from '@shared/services/sidebar-widget.service';
import {
  DATBooleanLayoutVariant,
  DATEnumLayoutVariant,
  DATLinkLayoutVariant,
  UserIconSizeEnum
} from '@shared/types/display-at-types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { TextDisplayOption } from '@widgets/card-widget/components/card-area-styler/card-area-styler.component';
import { CardAreaAttributeStylesTypes } from '@widgets/card-widget/components/card-area/types/card-area-types';
import { CardWidgetAreaContent } from '@widgets/card-widget/types/card-widget-area-content';
import {
  CardWidgetAreaContentItem,
  IsCardContentItemAttribute,
  IsCardContentItemLink,
  IsCardContentItemWidget,
} from '@widgets/card-widget/types/card-widget-area-content-item';
import { CardWidgetModel, MyArtifact } from '@widgets/card-widget/types/card-widget-model';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';
import { ContentType } from '@widgets/card-widget/types/content-type';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { APPLICATION_ID, DISABLED, IS_LAYOUT_MODE, IS_LIST_MATRIX_CARD, IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-card-area',
  templateUrl: './card-area.component.html',
  styleUrls: ['../../../../shared/styles/toolbar.scss', './card-area.component.scss'],
})
export class CardAreaComponent extends CoreRxSubscriptionsComponent implements OnInit {
  @Input() listItemArtifact: MyArtifact;
  @Input() artifactDto: ArtifactResponseDto;

  @Input() area: GenericArea<CardWidgetAreaContent>;
  @Input() attributeStyles: CardAreaAttributeStylesTypes;
  @Input() model: CardWidgetModel;
  @Input() isInSidebar: boolean;
  @Input() ownerId: string;
  @Input() breakpoint: StyleApplicationBreakpoint;

  @Output() downloadFile: EventEmitter<ArtifactResponseDto> = new EventEmitter<ArtifactResponseDto>();
  @Output() deleteWidget: EventEmitter<BlockPartWidget> = new EventEmitter<BlockPartWidget>();
  @Output() openAttributeSettings: EventEmitter<CardWidgetAreaContentItem> = new EventEmitter<CardWidgetAreaContentItem>();

  @ViewChildren('contentItemToolbarContainer') contentItemToolbarContainers: QueryList<ElementRef<HTMLDivElement>>;

  advancedWidget: BlockPartWidget | null = null;
  userIconSize: UserIconSizeEnum = UserIconSizeEnum.MEDIUM;

  toolbarContainerDisabled$: Observable<boolean>;
  isReady = true;
  linkLayoutVariant = DATLinkLayoutVariant;

  readonly dataType: typeof BaseDataType = BaseDataType;

  constructor(
    @Inject(APPLICATION_ID) public readonly applicationId: string,
    @Inject(IS_LAYOUT_MODE) public readonly isLayoutMode: boolean,
    @Inject(IS_PREVIEW_MODE) public readonly isPreviewMode: boolean,
    @Inject(DISABLED) public readonly disabled: boolean,
    @Inject(IS_LIST_MATRIX_CARD) public readonly isListMatrixCard: boolean,
    public readonly widgetService: SidebarWidgetService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    super();
  }

  get artifact(): ArtifactResponseDto {
    return this.isListMatrixCard ? this.artifactDto : this.model.artifact;
  }

  get showWidgetSettings(): boolean {
    return !this.isPreviewMode;
  }

  get options(): CardWidgetOptions {
    return this.model.options;
  }

  get isEllipsis(): boolean {
    return this.area.content.textDisplay === TextDisplayOption.ellipsis;
  }

  isEnum(item: CardWidgetAreaContentItem): boolean {
    return this.options.dataTypes.listMap[this.options.attributes.listMap[item.content as string]?.dataTypeId]?.isEnum || this.isBooleanEnum(item);
  }

  isBoolean(item: CardWidgetAreaContentItem): boolean {
    return this.options.dataTypes.listMap[this.options.attributes.listMap[item.content as string]?.dataTypeId]?.isBoolean && !this.isBooleanEnum(item);
  }

  getAttribute(id: string): any {
    return this.model.options.attributes.listMap[id];
  }

  ngOnInit(): void {
    this.toolbarContainerDisabled$ = this.model.advancedModeOpen.pipe(map((advancedMode: boolean) => !advancedMode));
    !this.ownerId && (this.ownerId = ElvisUtil.makeHash(16));

    this.registerSubscriptions([
      this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<any>) => {
        if (event.type === RuntimeStateNotificationEnum.updateDisplayVariant) {
          this.isReady = false;
          setTimeout(() => {
            this.isReady = true;
          });
        }
      }),
    ]);
  }

  isAttribute(contentItem: CardWidgetAreaContentItem): boolean {
    return IsCardContentItemAttribute(contentItem);
  }

  isLink(contentItem: CardWidgetAreaContentItem): boolean {
    return IsCardContentItemLink(contentItem);
  }

  isWidget(contentItem: CardWidgetAreaContentItem): boolean {
    return IsCardContentItemWidget(contentItem);
  }

  download(file: ArtifactResponseDto): void {
    this.downloadFile.emit(file);
  }

  onAdvancedModeOpen($event: MouseEvent, contentItem: CardWidgetAreaContentItem): void {
    $event.stopPropagation();

    if (this.isWidget(contentItem)) {
      this.advancedWidget = contentItem.content as BlockPartWidget;
    } else {
      this.openAttributeSettings.emit(contentItem);
    }
  }

  onAdvancedWidgetSidebarHide(): void {
    this.advancedWidget = null;

    // TODO remove this code when bug with sidebar will be fixed
    const overlays = document.querySelectorAll('.p-sidebar-mask');
    overlays && overlays.forEach(item => item.remove());
  }

  onContentItemDelete($event: MouseEvent, deletedItem: CardWidgetAreaContentItem): void {
    $event.stopPropagation();

    this.deleteContentItem(deletedItem);
    this.deleteAttributeStylesIfUnused(deletedItem);
  }

  private isBooleanEnum(item: CardWidgetAreaContentItem): boolean {
    return (
      this.options.dataTypes.listMap[this.options.attributes.listMap[item.content as string]?.dataTypeId]?.isBoolean &&
      Boolean(this.attributeStyles[item.content as string].settings.value.displayMetadata?.selectedVariantCode) &&
      this.attributeStyles[item.content as string].settings.value.displayMetadata?.selectedVariantCode !== DATBooleanLayoutVariant.DEFAULT &&
      Boolean(
        DATEnumLayoutVariant[
          this.attributeStyles[item.content as string].settings.value.displayMetadata!.selectedVariantCode! as any as keyof typeof DATEnumLayoutVariant
        ],
      )
    );
  }

  private deleteContentItem(deletedItem: CardWidgetAreaContentItem): void {
    if (deletedItem.type === ContentType.widget) {
      this.deleteWidget.emit(deletedItem.content as BlockPartWidget);
    }

    this.area.content.items = this.area.content.items.filter((item: CardWidgetAreaContentItem) => item !== deletedItem);
  }

  private deleteAttributeStylesIfUnused(deletedItem: CardWidgetAreaContentItem): void {
    if (!this.isAttribute(deletedItem)) {
      return;
    }

    const isSameAttributeAbsentInCard = this.model.areas.every((area: GenericArea<CardWidgetAreaContent>) => {
      return area.content.items.every(({ content }: CardWidgetAreaContentItem) => content !== deletedItem.content);
    });

    if (isSameAttributeAbsentInCard) {
      delete this.attributeStyles[deletedItem.content as string];
    }
  }
}
