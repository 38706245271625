import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { LinkTypeCreateRequestDto } from '@api/models/link-type-create-request-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { LinkTypeRestrictionRequestDto } from '@api/models/link-type-restriction-request-dto';
import { TenantLinkTypeService } from '@api/services/tenant-link-type.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { ID_KEY } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { LinkRestriction } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { cloneDeep, isEqual } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { LinkTypeModel } from '../types/link-type.types';

@Injectable()
export class LinkTypeService extends CoreService<any, LinkTypeModel> {
  constructor(
    private readonly tenantLinkTypeService: TenantLinkTypeService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateUtil: TranslateUtil,
    private readonly blockUiService: BlockUiService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly cache: NewCacheService,
  ) {
    super();
  }

  async init(context: any, model: LinkTypeModel): Promise<void> {
    super.init(context, model);
    this.initSubscriptions();
  }

  async save(): Promise<void> {
    this.blockUiService.blockUi();

    try {
      this.m.linkType.name = this.m.linkType.name.trim();

      if (!this.m.linkType.directionalLabel) {
        this.setDefaultDirectionLabel();
      }
      let linkType;

      if (this.m.linkType.id) {
        linkType = await this.updateLinkType();
      } else {
        linkType = await this.createNewLinkType();
      }

      if (linkType) {
        this.cache.data.linkTypes.setItem(linkType);
        await this.cancel();
      }
    } finally {
      this.blockUiService.unblockUi();
    }
  }

  async deleteWithConfirmation(linkType: LinkType, callback: (() => void) | null = null): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    this.confirmationService.confirm({
      header,
      message: message + ' ' + linkType.name + '?',
      acceptLabel,
      rejectLabel,
      accept: () =>
        this.delete(linkType.id).then(() => {
          callback ? callback() : this.cancel();
        }),
    });
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl('/admin/link-type-list');
  }

  async checkBeforeNavigation(url: string): Promise<void> {
    if (this.linkTypeChanged()) {
      await this.confirmBeforeLinkTypeChange(
        () => {
          this.c.canDeactivate = true;
          this.m.linkType = cloneDeep(this.m.linkTypeCopy);
          this.setOriginalObject<LinkType>(this.m.linkType);
          this.c.router.navigateByUrl(url);
        },
        () => (this.c.canDeactivate = false),
      );
    } else {
      if (!this.c.canDeactivate) {
        this.c.canDeactivate = true;
        await this.c.router.navigateByUrl(url);
      }
    }
  }

  private async delete(id: string): Promise<void> {
    this.m.inProgress = true;
    this.blockUiService.blockUi();

    try {
      const success = await this.tenantLinkTypeService.linkTypeControllerDelete({ id }).toPromise();
      success && (await this.cancel());
    } finally {
      this.m.inProgress = false;
      this.blockUiService.unblockUi();
    }
  }

  private initSubscriptions(): void {
    const { applications, linkTypes, artifactTypes } = this.cache.data;

    this.c.registerSubscriptions([
      applications.subscribe(applications =>
        this.m.applications.setList(
          applications!.map(dto => new NewApplication(dto as ApplicationResponseDto)),
          ID_KEY,
        ),
      ),
      linkTypes.subscribe(linkTypes => {
        this.m.linkTypes.setList(
          linkTypes!.map(dto => new LinkType(dto as LinkTypeResponseDto)),
          ID_KEY,
        );
        this.initLinkType();
      }),
      artifactTypes.subscribe(artifactTypes => (this.m.artifactTypes = artifactTypes!.map(dto => new NewArtifactType(dto as ArtifactTypeResponseDto)))),
    ]);
  }

  private initLinkType(): void {
    if (this.c.urlParams.id) {
      this.initLinkTypeFromUrlId();
    } else {
      this.setLinkType(new LinkType({ applicationId: this.applicationSwitcherService.selectedApplication?.id }));
    }
  }

  private initLinkTypeFromUrlId(): void {
    const linkType = this.m.linkTypes.listMap[this.c.urlParams.id];
    if (linkType) {
      linkType.restrictions = linkType.restrictions.filter(restrtiction => {
        restrtiction.isLinkRequired = new SelectOption(restrtiction.isLinkRequired, restrtiction.isLinkRequired) as any;
        return restrtiction;
      });
      this.setLinkType(new LinkType(linkType));
      this.setOriginalObject<LinkType>(this.m.linkType);
      this.m.isDeleted = Boolean(linkType.deleted);
    }
  }

  private setLinkType(linkType: LinkType): void {
    this.m.linkType = linkType;
  }

  private setDefaultDirectionLabel(): void {
    this.m.linkType.incomingName = this.m.linkType.name;
    this.m.linkType.outgoingName = this.m.linkType.name;
  }

  private linkTypeChanged(): boolean {
    return !isEqual(this.m.linkType, this.getOriginalObject);
  }

  private async updateLinkType(): Promise<LinkTypeResponseDto> {
    const { id } = this.m.linkType;
    const changedFields = this.getChangedDataFromOriginalObject<LinkType>(this.m.linkType);
    if ('directionalLabel' in changedFields) {
      delete changedFields.directionalLabel;
    }
    this.mapLinkRestrictions(changedFields);

    changedFields.restrictions &&
      changedFields.restrictions.map(restriction => {
        restriction.isLinkRequired = (restriction.isLinkRequired as any).value;
      });

    return await lastValueFrom(this.tenantLinkTypeService.linkTypeControllerUpdate({ body: { id, ...changedFields } }));
  }

  private async createNewLinkType(): Promise<LinkTypeResponseDto> {
    const { name, alias, description, incomingName, outgoingName, uri, applicationId } = this.m.linkType;
    this.m.linkType.restrictions.map(restriction => {
      restriction.isLinkRequired = (restriction.isLinkRequired as any).value;
    });
    const body: LinkTypeCreateRequestDto = {
      name,
      alias,
      description,
      incomingName,
      outgoingName,
      uri,
      applicationId: applicationId || this.applicationSwitcherService.selectedApplication?.id || '',
      restrictions: this.m.linkType.restrictions as LinkTypeRestrictionRequestDto[],
    };
    return await lastValueFrom(this.tenantLinkTypeService.linkTypeControllerCreate({ body }));
  }

  private mapLinkRestrictions(changedFields: Partial<LinkType>): void {
    if (changedFields.restrictions) {
      changedFields.restrictions = changedFields.restrictions.map(
        ({ singleDestination, destinationArtifactTypeId, sourceArtifactTypeId, singleSource, isLinkRequired, notifySource, notifyDestination }) =>
          new LinkRestriction({
            singleDestination,
            destinationArtifactTypeId,
            sourceArtifactTypeId,
            singleSource,
            isLinkRequired,
            notifySource,
            notifyDestination,
          }),
      );
    }
  }

  private async confirmBeforeLinkTypeChange(accept: () => void, reject: () => void): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll([
      'Change link type',
      "You have unsaved changes. Are you sure you don't want to save them?",
      'Yes',
      'No',
    ]);
    this.confirmationService.confirm({
      header,
      message,
      acceptLabel,
      rejectLabel,
      accept: () => accept && accept(),
      reject: () => reject && reject(),
    });
  }
}
