import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ArtifactWidgetModel, ArtifactWidgetSelectedEntities } from '@widgets/artifact-widget/types/artifact-widget.types';

@Pipe({ name: 'getCustomAttributeValue' })
export class GetCustomAttributeValuePipe implements PipeTransform {
  // TODO: figure how to call change detection for this pipe and use it
  transform(dataTypeId: string, selected: ArtifactWidgetSelectedEntities, model: ArtifactWidgetModel): unknown {
    const dataType = Constants.artifactCustomAttributes.filter(dataType => dataType.value === dataTypeId);
    const artifact = selected.artifact;

    if (dataType[0].code === NonAttributeKeys.CREATED_BY) {
      const user = model.options.users.filterByKey('id', (artifact?.created?.by as any).id || artifact?.created?.by || '');
      return user[0]?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string;
    } else {
      if (dataType[0].code === NonAttributeKeys.UPDATED_BY) {
        const user = model.options.users.filterByKey('id', (artifact?.updated?.by as any).id || artifact?.updated?.by || '');
        return user[0]?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string;
      }
    }

    return dataTypeId;
  }
}
