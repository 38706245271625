<div *ngIf="isChipStyle || isIconStyle || isShowOrder" class="grid">
  <div class="col-12 md:col-6 lg:col-6">
    <h6>Order</h6>
    <span class="dynamic-label">
      <input [(ngModel)]="contentStyle.order" id="order" pInputText type="text" />
    </span>
  </div>
</div>

<app-font-style-form
        *ngIf="!isShowExtended"
        [fontStyles]="contentStyle"
        [hideFontFamilySettings]="isIconStyle"
        [hideHorizontalAlignSettings]="isIconStyle"
></app-font-style-form>

<p-accordion *ngIf="isShowExtended" styleClass="accord mb-3">
  <p-accordionTab [header]="'Styles' | translate" [selected]="true">
    <app-font-style-form
            [fontStyles]="contentStyle"
            [hideFontFamilySettings]="isIconStyle"
            [hideHorizontalAlignSettings]="isIconStyle"
    ></app-font-style-form>
  </p-accordionTab>
  <p-accordionTab *ngIf="isLoaded" [header]="'Extra Styles' | translate" [selected]="false">
    <app-page-element-styler [styles]="contentStyle" [isShowOverflow]="!isShowExtended" [isShowBorderRadius]="!contentStyle?.borderRadius" class="mb-3"></app-page-element-styler>
  </p-accordionTab>
</p-accordion>

<button (click)="styleService.copyContentStyles(contentStyle)"
        class="mr-2" icon="pi pi-copy" pButton pTooltip="{{'Copy style' | translate}}" type="button"></button>

<button *ngIf="styleService.copiedContentStyles" (click)="styleService.pasteStyleIntoContent(contentStyle)"
        icon="bi bi-clipboard-plus" pButton pTooltip="{{'Paste style'| translate}}" type="button"></button>

