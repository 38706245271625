import { Directive, Input } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { NewArtifact } from '@shared/types/artifact.types';
import { RuleDataHolderService, RuleTriggerEventHandlerService } from '../services';
import { AbstractAttributeValueChange } from '../shared/types/abstract-attribute-value-change';
import { WorkflowTriggerAttributeValueChange, WorkflowTriggerEvent } from '../types';
import { AbstractRuleEventNotifyDirective } from './abstract-rule-event-notify.directive';

@Directive({
  selector: '[appAttributeChangeValueNotify]',
})
export class RuleAttributeChangeValueNotifyDirective extends AbstractRuleEventNotifyDirective {
  @Input() artifact: NewArtifact;
  @Input() artifactDto: ArtifactResponseDto;
  @Input() artifactTypeId: string;
  @Input() attributeId: string;
  @Input() widgetId: string;
  @Input() getAttributeValueFn: (attributeId: string) => any;

  constructor(
    protected readonly ruleDataHolder: RuleDataHolderService,
    protected readonly ruleTriggerEventHandler: RuleTriggerEventHandlerService,
    protected readonly host: AbstractAttributeValueChange,
  ) {
    super(ruleDataHolder, ruleTriggerEventHandler);

    // overriding default function from host component
    const hostOnValueChangeFn = host.onValueChange.bind(host);
    host.onValueChange = (newValue: any): void => {
      hostOnValueChangeFn(newValue);

      setTimeout(() => {
        const ruleTriggerEvent: WorkflowTriggerEvent = {
          pageId: this.pageId,
          definition: new WorkflowTriggerAttributeValueChange(this.artifactTypeId, this.attributeId),
          payload: {
            artifact: this.artifact,
            artifactDto: this.artifactDto,
            newValue: newValue,
            getAttributeValueFn: this.getAttributeValueFn,
          },
          widgetId: this.widgetId,
        };
        this.checkAndNotify(ruleTriggerEvent);
      }, 50);
    };
  }
}
