import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetAttributeFromClientAttribute, GetDataTypeFromClientAttribute } from '@shared/methods/artifact.methods';
import { IsDateOrTime, IsHyperlink, IsUser } from '@shared/methods/data-type.methods';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { DateUtil } from '@shared/utils/date.util';
import { StringUtil } from '@shared/utils/string.util';
import { ConvertTextToHtmlMethods } from '@shared/methods/convert-text-to-html.methods';

@Pipe({ name: 'getAttributeReadonlyValue' })
export class GetAttributeReadonlyValuePipe implements PipeTransform {
  constructor(
    private readonly dateUtil: DateUtil,
    private readonly stringUtil: StringUtil,
  ) {}

  transform(clientAttribute: NewClientAttribute, attributes: ListContainer<NewAttribute>, dataTypes: ListContainer<NewDataType>, showIcon?: boolean): string {
    const attribute = GetAttributeFromClientAttribute(clientAttribute, attributes.listMap);
    const dataType = GetDataTypeFromClientAttribute(clientAttribute, attributes.listMap, dataTypes.listMap);

    if (this.shouldReturnEmptyString(clientAttribute, attribute, dataType)) return '';
    return this.transformValueToClient(clientAttribute, attribute || new NewAttribute(), dataType || new NewDataType(), showIcon);
  }

  private shouldReturnEmptyString(clientAttribute: NewClientAttribute, attribute: NewAttribute | null, dataType: NewDataType | null): boolean {
    return !clientAttribute || !attribute || !dataType || (typeof clientAttribute.value !== 'boolean' && !clientAttribute.value);
  }

  private transformValueToClient(clientAttribute: NewClientAttribute, attribute: NewAttribute, dataType: NewDataType, showIcon?: boolean): string {
    const { value } = clientAttribute;
    const { multipleValues } = attribute;

    if (!dataType.baseDataType) return '';
    if (IsUser(dataType.baseDataType)) return this.transformUserToClient(value);
    else if (IsDateOrTime(dataType.baseDataType)) return this.transformDateOrTimeToClient(dataType.baseDataType, multipleValues, value);
    else if (IsHyperlink(dataType.baseDataType)) return this.transformHyperlinkToClient(multipleValues, value);

    if (dataType.kind === DataTypeKind.enumerated && dataType.values) {
      return this.transformEnumeratedToClient(multipleValues, dataType.values, value, showIcon);
    } else if (typeof value === 'string') {
      if (dataType.isText) {
        return ConvertTextToHtmlMethods.returnsToBreaks(value);
      }
      return String(value);
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      return String(value);
    } else {
      return value?.join(', ');
    }
  }

  private transformDateOrTimeToClient(dataType: BaseDataType, multipleValues: boolean, value: any): string {
    if (multipleValues) return this.transformDateOrTimeMultipleToClient(dataType, value);
    return this.transformDateOrTimeSingleToClient(dataType, value);
  }

  private transformDateOrTimeSingleToClient(dataType: BaseDataType, value: any): string {
    switch (dataType) {
      case BaseDataType.date:
        return value instanceof Date ? this.dateUtil.convertToClientDate(value) : value;
      case BaseDataType.time:
        return value instanceof Date ? this.dateUtil.convertToClientTime(value) : value;
      case BaseDataType.dateTime:
        return value instanceof Date ? this.dateUtil.convertToClientDatetime(value) : value;
      default:
        return '';
    }
  }

  private transformDateOrTimeMultipleToClient(dataType: BaseDataType, value: string[]): string {
    switch (dataType) {
      case BaseDataType.date:
        return value.map((date: string | Date) => (date instanceof Date ? this.dateUtil.convertToClientDate(new Date(date)) : value)).join(', ');
      case BaseDataType.time:
        return value.map((time: string | Date) => (time instanceof Date ? this.dateUtil.convertToClientTime(time) : time)).join(', ');
      case BaseDataType.dateTime:
        return value.map((dateTime: string | Date) => (dateTime instanceof Date ? this.dateUtil.convertToClientDatetime(dateTime) : dateTime)).join(', ');
      default:
        return '';
    }
  }

  private transformEnumeratedToClient(multipleValues: boolean, dataTypeValues: DataTypeValueResponseDto[], value: any, showIcon?: boolean): string {
    const valueIds = (Array.isArray(value) ? value : [value]).map((item: any) => item?.value) || [];

    if (multipleValues)
      return dataTypeValues
        .filter(item => value.includes(item) || valueIds.includes(item.value))
        .map(value => this.getFullEnumeratedValue(value, showIcon))
        .join(showIcon ? '' : ', ');

    const dataTypeValue = dataTypeValues?.find(item => item.value === value?.value);
    return dataTypeValue ? this.getFullEnumeratedValue(dataTypeValue, showIcon) : '';
  }

  private getFullEnumeratedValue(value: DataTypeValueResponseDto, showIcon?: boolean): string {
    return showIcon ? `<span class="${value.icon} mr-1"></span> ${value.label} <br>` : value.label;
  }

  private transformUserToClient(value: SelectOption<string, ArtifactLinkResponseDto>[]): string {
    return value
      ? value
          .map(
            (item: SelectOption<string, ArtifactLinkResponseDto>) =>
              item.value?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)] || item.label,
          )
          .join(', ')
      : '';
  }

  private transformHyperlinkToClient(multipleValues: boolean, value: any): string {
    return this.stringUtil.formatMultipleHyperlinkForDisplay(multipleValues === true ? value : [value]);
  }
}
