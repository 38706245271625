<hr>

<ng-container *ngIf="!(m.selected.treeItem && m.selected.treeItem.uiType === menuItemUiType.divider) else dividerForm">
  <app-default-menu-item-button-form [m]="m" [s]="s"></app-default-menu-item-button-form>
</ng-container>

<ng-template #dividerForm>

  <div *ngIf="((m.items.menu | isItemInFirstLevel : m.selected.menuItem) && (m.settings.menu.type | isMenuHorizontal)) else verticalDividerForm">
    <app-divider-horizontal-form
      [dividerStyle]="(m.selected.treeItem.parent ?
        m.styles.defaultSubMenuItemStyles.horizontalDividerStyles :
        m.styles.defaultFirstLevelItemStyles.horizontalDividerStyles)"
      [options]="m.options">
    </app-divider-horizontal-form>
  </div>

  <ng-template #verticalDividerForm>
    <app-divider-vertical-form [dividerStyle]="(m.selected.treeItem.parent ?
        m.styles.defaultSubMenuItemStyles.verticalDividerStyles :
        m.styles.defaultFirstLevelItemStyles.verticalDividerStyles)" [options]="m.options"></app-divider-vertical-form>
  </ng-template>

</ng-template>

<!--  <div class="col-12 mb-0">-->
<!--    <button (click)="styleCopyService.pasteIntoAllItems(m)"-->
<!--            class="p-button p-button-warning w-100"-->
<!--            label="{{'Apply this item style to entire menu'| translate}}"-->
<!--            pButton-->
<!--            type="button">-->
<!--    </button>-->
<!--  </div>-->
