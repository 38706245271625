import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InternalImageIdToUrlPipe } from './internal-image-id-to-url.pipe';
import { InternalImageExtractUrlPipe } from './internal-image-extract-url.pipe';
import { ImageFilterStylesPipe } from './image-filter-styles.pipe'

const pipes = [InternalImageIdToUrlPipe, InternalImageExtractUrlPipe, ImageFilterStylesPipe];

@NgModule({
  declarations: [...pipes],
  imports: [CommonModule],
  exports: [...pipes],
})
export class ImagePipesModule {}
