<div class="list_top">
  <div class="grid list_top__filters">
    <div class="col-6">
      <label for="isShowBadge" style="margin-right: 10px">Show as badge</label>
      <p-checkbox (onChange)="s.updateValue()"
                  [(ngModel)]="m.selected.showBadge"
                  binary="true"
                  id="isShowBadge">
      </p-checkbox>
    </div>
    <div class="col-6">
      <label for="valueType" style="margin-right: 10px">Fixed value</label>
      <p-checkbox (onChange)="s.updateValue()"
                  [(ngModel)]="m.selected.showFixedValue"
                  binary="true"
                  id="valueType">
      </p-checkbox>
    </div>

    <div *ngIf="m.selected.showFixedValue" class="col-6">
      <input (change)="s.updateValue()"
             [(ngModel)]="m.settings.fixedCounter"
             [disabled]="!m.selected.showFixedValue"
             id="fixedCounter" pInputText
             placeholder="Fixed counter value"
             style="width: 100%" type="text" />
    </div>

    <div *ngIf="m.selected.showFixedValue" class="col-12">
      Example:<br>
      number is 12, color is lila and label is on bottom<br>
      number is 14, color is green and label is on top<br>
    </div>

    <ng-container *ngIf="!m.selected.showFixedValue">
      <div class="col-6">
        <div class="dynamic-label p-float-label">
          <p-dropdown
            (onChange)="s.onArtifactTypeChange(); s.updateValue()"
            [(ngModel)]="m.selected.artifactType"
            [options]="m.options.artifactTypes.list | transformArrayToSelectOptions: NAME_VALUE"
            id="artifactType"
            filter="true"
            optionLabel="label"
            placeholder="Select artifact type"
            showClear="true"
          >
          </p-dropdown>
          <label for="artifactType" translate> Artifact type </label>
        </div>
      </div>
      <div class="col-6">
        <div class="dynamic-label p-float-label">
          <p-dropdown
            (onChange)="s.numberTypesUpdateOptions(); s.checkAndUpdateUrl(); s.updateValue()"
            [(ngModel)]="m.selected.attribute"
            [disabled]="!m.selected.artifactType"
            [options]="m.options.artifactCountableAttributeValues.list | transformArrayToSelectOptions: NAME_VALUE: ID_VALUE"
            id="attributeCount"
            optionLabel="label"
            placeholder="Count by attribute value"
            showClear="true"
          >
          </p-dropdown>
          <label for="attributeCount" translate> Count by attribute value </label>
        </div>
      </div>

      <div class="col-6">
        <div class="dynamic-label p-float-label">
          <p-dropdown
            (onChange)="s.onArtifactTypeChange(); s.updateValue()"
            [(ngModel)]="m.selected.numberType"
            [options]="m.options.numberTypes.list | transformArrayToSelectOptions"
            id="numberType"
            optionLabel="label"
            placeholder="Number type"
          >
          </p-dropdown>
          <label for="numberType" translate> Number type </label>
        </div>
      </div>
      <div class="col-6"></div>

      <div class="col-6 mb-0">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox
              (onChange)="s.checkboxHandler(true)"
              [(ngModel)]="m.selected.listenUrlFolderId"
              id="listenFolderId"
              binary="true"
            ></p-checkbox>
            <label for="listenFolderId" translate> Listen FolderId </label>
          </span>
        </span>
      </div>

      <div class="col-6 mb-0">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox
                    (onChange)="s.checkboxHandler()"
                    [(ngModel)]="m.selected.listenUrlArtifactId"
                    id="listenArtifactId"
                    binary="true"
            ></p-checkbox>
            <label for="listenArtifactId" translate> Listen ArtifactId </label>
          </span>
        </span>
      </div>

      <div class="col-6">
        <span class="dynamic-label">
          <input
            (onChange)="s.urlKeyCheck()"
            [disabled]="!m.selected.listenUrlFolderId"
            [(ngModel)]="m.selected.folderKey"
            type="text"
            id="folderKey"
            pInputText
          />
          <label class="dynamic-label-text" for="folderKey" translate>Folder url key</label>
        </span>
      </div>

      <div class="col-6">
        <span class="dynamic-label">
          <input
            (onChange)="s.urlKeyCheck()"
            [disabled]="!m.selected.listenUrlArtifactId"
            [(ngModel)]="m.selected.artifactKey"
            type="text"
            id="artifactKey"
            pInputText
          />
          <label class="dynamic-label-text" for="artifactKey" translate>Artifact url key</label>
        </span>
      </div>

      <div class="col-6 mb-0">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox
              (onChange)="s.urlKeyCheck(true)"
              [(ngModel)]="m.selected.listenUrlFilterOption"
              id="listenFilterOption"
              binary="true"
            ></p-checkbox>
            <label for="listenFilterOption" translate> Do filter on url change</label>
          </span>
        </span>
      </div>

      <div class="col-12">
        <app-list-item-click-action-settings-form
          [(clickAction)]="m.settings.clickAction.actionType"
          [(emittingUrlKey)]="m.settings.clickAction.emittingKey"
          [(externalPage)]="m.settings.clickAction.externalPage"
          [(handleClick)]="m.settings.clickAction.isHandled"
          [(openPageInNewTab)]="m.settings.clickAction.openInNewTab"
          [(selectedPage)]="m.settings.clickAction.selectedPage"
          [(useAlias)]="m.settings.clickAction.useAlias"
          [header]="'Number click action'"
          [filtersHolder]="$any(m.settings.clickAction)"
          [filtersKey]="'filtersForQueryParams'"
          [isAddToLinkDisabled]="true"
          [isGoToDefaultPageDisabled]="true"
          [isHideMultiselect]="true"
          [options]="clickActionSettingsOptions"
        >
        </app-list-item-click-action-settings-form>
        <app-custom-event-settings-form
                *ngIf="m.settings.clickAction.isHandled"
                [(customEvent)]="m.settings.customEvents"
        ></app-custom-event-settings-form>
      </div>

      <div class="col-12">
        <app-artifact-filters
          (filtersChange)="s.updateValue()"
          [attributeAndLinkOptions]="(m.options.artifactAttributeValues.list | transformArrayToSelectOptions : NAME_VALUE)!"
          [dataTypes]="m.options.dataTypes.list"
          [filtersHolder]="m.selected!"
          [filtersKey]="'attributesFilter'"
          [users]="m.options.users">
        </app-artifact-filters>
      </div>

      <div *ngIf="showSorting" class="col-12">
        <app-artifact-sorts
                (sortsChange)="s.updateValue()"
                [attributes]="(m.options.artifactAttributeValues.list | transformArrayToSelectOptions : NAME_VALUE)!"
                [sortsHolder]="$any(m)"
                [sortsKey]="'sorts'"
        >
        </app-artifact-sorts>
      </div>
    </ng-container>
  </div>
</div>
