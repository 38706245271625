import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { INVALID_USER_LABEL } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { filter, map, Observable, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, DropdownModule, MultiSelectModule, InputSwitchModule, ArtifactAttributeFormFieldLabelWithIconComponent],
  selector: 'app-artifact-attribute-form-field-user',
  templateUrl: './artifact-attribute-form-field-user.component.html',
  styleUrls: ['./artifact-attribute-form-field-user.component.scss'],
})
export class ArtifactAttributeFormFieldUserComponent implements OnInit {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() users: ListContainer<ArtifactLinkResponseDto>;
  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() onChange: (value: any) => Promise<void>;
  @Input() onBlur: () => void;

  userOptions: SelectOption<string, string>[] = [];
  labelBehaviourEnum = LabelBehaviourEnum;
  currentUserSelected$: Observable<boolean>;

  private currentUserOption: SelectOption<string, string>;

  constructor(private readonly cache: NewCacheService) {}

  ngOnInit() {
    this.fillUserOptions();
    this.currentUserSelected$ = this.cache.user.pipe(
      filter(() => this.attribute.multipleValues),
      tap((currentUser: any) => (this.currentUserOption = new SelectOption(currentUser.email, currentUser.id))),
      map(currentUser => {
        const attributeValueArray = this.attr.value as SelectOption<string, string>[];
        return !!attributeValueArray?.find(item => item.value === currentUser.id);
      }),
    );
  }

  onSingleUserChange(): void {
    this.attr.value && (this.attr.value = [this.attr.value]);
    this.onChange(this.attr.value);
  }

  fillUserOptions(): void {
    const usersMap = this.users.listMap;
    let invalidUsers: SelectOption<string, string>[] = [];
    let selectedUsers: SelectOption<string, string>[] = [];
    let otherUsers: SelectOption<string, string>[] = this.users.list
      .filter(u => !u.deleted)
      .map(
        (u: ArtifactLinkResponseDto) => new SelectOption(u?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string, u.id),
      );

    if (this.attr.value) {
      if (Array.isArray(this.attr.value)) {
        this.attr.value = this.attr.value.map(option => {
          if (!option) return null;
          return !usersMap[option.value] || usersMap[option.value]?.deleted ? new SelectOption(INVALID_USER_LABEL, option.value) : option;
        });
        selectedUsers = this.attr.value?.filter(Boolean) || [];
        otherUsers = otherUsers.filter(user => !selectedUsers.find(item => item?.value === user.value));
        invalidUsers = this.attr.value
          .filter((option: SelectOption<string, string>) => option && (!usersMap[option.value] || usersMap[option.value]?.deleted))
          .map((option: SelectOption<string, string>) => new SelectOption<string, string>(INVALID_USER_LABEL, option.value));
      } else {
        invalidUsers =
          !usersMap[this.attr.value] || usersMap[this.attr.value]?.deleted ? [new SelectOption<string, string>(INVALID_USER_LABEL, this.attr.value)] : [];
      }
    }

    this.userOptions = [...selectedUsers, ...otherUsers, ...invalidUsers];
  }

  onSubscribeMeToggle(amSelected: boolean) {
    if (this.currentUserOption) {
      this.attr.value ??= [];
      this.attr.value = amSelected
        ? [...this.attr.value, this.currentUserOption]
        : (this.attr.value as SelectOption<string, string>[]).filter(item => item.value !== this.currentUserOption.value);
      this.onChange(this.attr.value);
    }
  }
}
