import { Injectable } from '@angular/core';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageStyles } from '@private/pages/page-management/page-builder-graphical/types/page-styles';
import { WidgetCreateRequestDto, WidgetUpdateRequestDto } from '@shared/types/widget.types';
import { WidgetType } from '@widgets/widgets-core/types/widgets.types';
import { TableMethods } from '../methods/table.methods';
import { LocalStorageService } from '../services/local-storage.service';
import { TableColumn } from '../types/table.types';
import { ImageMethods } from '@shared/methods/image.methods';
import { ApiConfiguration } from '@api/api-configuration';
import { TenantArtifactService } from '@api/services';

@Injectable({ providedIn: 'root' })
export class PageHelper {
  usePublicToken = false;
  private _isOnPage = false;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly apiConfiguration: ApiConfiguration,
  ) { }

  get isOnPage(): boolean {
    return this._isOnPage;
  }

  set isOnPage(isOnPage: boolean) {
    this._isOnPage = isOnPage;
  }

  getPreparedToServerListWidget(part: PageBlockPart): WidgetCreateRequestDto | WidgetUpdateRequestDto {
    const widget = part.widget!;
    const dto = widget.requestDto;

    // TODO - to completely refactor this part of code, it is very very bad solution
    if ([WidgetType.list, WidgetType.listNew].includes(widget.code)) {
      const state = this.localStorageService.get(part.hash);

      if (state) {
        if (!state.tableFormatSettings && (dto as any).value.model.state?.tableFormatSettings) {
          state.tableFormatSettings = (dto as any).value.model.state?.tableFormatSettings;
        }
        delete state.selection;
        state.columnOrder = (dto as any).value.model.selected.columns.map((col: TableColumn) => col.key);

        if (this.isColumnCountInconsistent(state, part)) this.fixColumnCountInconsistency(state);

        (dto as any).value.model.state = state;
        state.filters && TableMethods.formatTableFilterNamesToServer((dto as any).value.model.state.filters);
        this.localStorageService.remove(part.hash as string);
      }
    }

    return dto;
  }

  setBackgroundToBody(pageStyles: PageStyles, token: string, publicToken: string): void {

    let url = pageStyles.background;
    const isExternal = ImageMethods.checkIsExternalUrl(url);
    if (!isExternal) {
      if (!token || this.usePublicToken) token = publicToken;
      url = `url(${ImageMethods.getUrlFromImageFileId(url, this.apiConfiguration.rootUrl, TenantArtifactService.ArtifactControllerDownloadPath, token)})`;
    }

    document.body.style.background = url;
    document.body.style.setProperty('background-repeat', 'no-repeat');
    document.body.style.setProperty('background-size', 'cover');
    document.body.style.setProperty('background-position', 'center');
    document.body.style.setProperty('background-attachment', 'fixed');
  }

  resetBackgroundOnBody(): void {
    document.body.style.background = '';
  }

  private isColumnCountInconsistent(state: any, part: Partial<PageBlockPart>): boolean {
    const utilColsCount = part.widget?.value.model.settings.editableRow ? 2 : 1;
    return state.columnWidths && state.columnWidths.split(',').length - utilColsCount !== state.columnOrder.length;
  }

  private fixColumnCountInconsistency(state: Record<string, any>): void {
    const colWidths = state.columnWidths.split(',');
    const difference = state.columnOrder.length - (colWidths.length - 2);
    for (let i = 0; i < difference; i++) {
      colWidths.splice(colWidths.length - 2, 0, '200');
    }
    state.columnWidths = colWidths.join(',');
  }
}
