import { Pipe, PipeTransform } from '@angular/core';
import { PageSectionRowHideDto } from '@api/models/page-section-row-hide-dto';
import { HideClasses } from '@shared/types/screen.types';

@Pipe({
  name: 'containerClassList',
})
export class ContainerClassListPipe implements PipeTransform {
  transform(containerClass?: string, params?: PageSectionRowHideDto): string[] {
    const classList: string[] = containerClass ? [containerClass] : [];
    if (params) {
      params.pc && classList.push(HideClasses.pc);
      params.tablet && classList.push(HideClasses.tablet);
      params.mobile && classList.push(HideClasses.mobile);
    }
    return classList;
  }
}
