import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ArtifactGroupItemResponseDto,
  ArtifactGroupResponseDto,
  ArtifactLinkResponseDto,
  ArtifactTypeResponseDto,
  AttributeResponseDto,
  DataTypeResponseDto,
  LinkTypeResponseDto,
} from '@api/models';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetArtifactAttributeValuePath } from '@shared/methods/client-attribute.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { ServerSendEventMethods } from '@shared/methods/server-send-event.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewDataType } from '@shared/types/data-type.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { LinkType } from '@shared/types/link-type.types';
import { ChartWidgetComponent } from '@widgets/chart-widget/chart-widget.component';
import { ChartWidgetHelper } from '@widgets/chart-widget/helpers/chart-widget.helper';
import { ChartTotalPosition } from '@widgets/chart-widget/types/chart-widget-options.types';
import { FitMode } from '@widgets/chart-widget/types/chart-widget-selected.types';
import { ChartWidgetTypeChart } from '@widgets/chart-widget/types/chart-widget-view.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ArtifactFilterType } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';
import { lastValueFrom } from 'rxjs';
import { ChartConstants } from '../constants/constants';
import { ChartWidgetModel, ChartWidgetModelDto, ChartWidgetValue } from '../types/chart-widget.types';
import { ChartWidgetColorService } from './chart-widget-color.service';

@Injectable()
export class ChartWidgetService {
  c: ChartWidgetComponent;
  m: ChartWidgetModel;

  constructor(
    private readonly cache: NewCacheService,
    public readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly colorService: ChartWidgetColorService,
    private readonly filterService: ArtifactFiltersService,
    private readonly helper: ChartWidgetHelper,
    private readonly route: ActivatedRoute,
  ) {}

  async init(context: ChartWidgetComponent, dto?: ChartWidgetModelDto | null): Promise<void> {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new ChartWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new ChartWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;

    if (!this.c.isLayoutMode) return;

    context.registerSubscriptions([
      this.runtimeStateNotificationService?.events$.subscribe(async (event: RuntimeStateNotification) => {
        if (ServerSendEventMethods.isCreateArtifact(event.type) || ServerSendEventMethods.isUpdateArtifact(event.type)) {
          event.data.artifactTypeId === this.m.selected.artifactType?.value.id && this.onUpdateArtifact();
        }
      }),
      this.route.queryParams.subscribe(() => this.onUpdateArtifact()),
    ] as any);

    await this.loadOptions();

    this.m.selected.fromDto(this.m.options);
    this.onChartTypeChange();
    await this.setRestrictions();
    await this.onArtifactTypeChange();
    await this.updateChartData();
    this.initResizeObserver();

    this.m.options.systemAttributes = this.filterService.getSystemAttributes();
  }

  async onUpdateArtifact(): Promise<void> {
    this.setUserList();
    await this.updateChartData();
  }

  async onGroupByChange(): Promise<any> {
    await this.updateChartData();
  }

  onChartTypeChange(): void {
    if (this.m.settings.chartType === ChartWidgetTypeChart.pie) {
      this.m.settings.chartOptions.cutoutPercentage = ChartConstants.pieCutout;
    } else if (this.m.settings.chartOptions.cutoutPercentage === ChartConstants.pieCutout) {
      this.m.settings.chartOptions.cutoutPercentage = ChartConstants.defaultCutout;
    }

    if (this.m.settings.chartType === ChartWidgetTypeChart.pie) {
      const { chartTotalPosition } = this.m.selected;
      if (!chartTotalPosition || chartTotalPosition !== ChartTotalPosition.bottom) {
        this.m.settings.chartOptions.isShowTotal = false;
      }
      chartTotalPosition && (this.m.selected.chartTotalPosition = ChartTotalPosition.bottom);
      this.m.options.initChartTotalPosition(true);
    } else {
      if (this.m.selected.fitMode === FitMode.horizontal) {
        this.m.options.initChartTotalPosition();
      } else {
        this.m.options.initChartTotalPositionForVertical();
      }
    }
  }

  async onArtifactTypeChange(): Promise<void> {
    if (this.m.selected.artifactType?.value) {
      const ids = Object.values(this.m.selected.artifactType?.value.attributes).map(value => value.id);

      this.m.options.artifactAttributes = [
        ...this.m.options.attributes.filter(attr => ids.includes(attr.id) && this.isSupportedAttributeType(attr.dataTypeId)),
        ...this.getLinks(),
        ...this.filterService.getSystemAttributes(),
      ];

      this.m.options.artifactAttributesForTotal = this.m.options.artifactAttributes.filter(attr => {
        const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
        return (
          dataType &&
          dataType.kind === DataTypeKind.simple &&
          (dataType.baseDataType === BaseDataType.integer || dataType.baseDataType === BaseDataType.decimal)
        );
      });

      this.setUserList();
    }
  }

  getLinks(): DirectionalLinkType[] {
    return this.m.options.linkTypes.list
      .filter((linkType: DirectionalLinkType) => this.checkLinkRestriction(linkType) && linkType.isLinkingArtifactTypeId(this.m.selected.artifactType.value.id))
      .map(link => {
        link.name = link.direction === LinkDirection.outgoing ? link.outgoingName : link.incomingName;
        return link;
      });
  }

  checkLinkRestriction(linkType: DirectionalLinkType): boolean {
    const restrictions = this.m.restrictions;
    if (!restrictions) return false;

    const { id, direction } = linkType;
    const artifactTypeId = this.m.selected.artifactType.value.id;

    if (artifactTypeId && restrictions[artifactTypeId])
      return restrictions[artifactTypeId].some(({ linkType }) => linkType?.value === id && linkType?.meta === direction);

    return false;
  }

  async setRestrictions(): Promise<void> {
    this.m.restrictions = await LinkMethods.getGroupedLinkRestrictions(this.m.options.artifactTypes.list, this.m.options.linkTypes.list);
  }

  setUserList(): void {
    this.m.options.users.setList(this.cache.data.users.value as ArtifactLinkResponseDto[], ID_KEY);
  }

  setArtifactAttributeValues(data: ArtifactGroupItemResponseDto[]): void {
    if (!this.m.selected.groupByAttribute?.value.id || !this.m.selected.artifactType?.value.id) {
      return;
    }

    this.m.valuesMap = {};
    const valuesList: string[] = [ChartConstants.blank];
    const aggregatedData = data.reduce<Record<string, number>>((res, item) => {
      if (Array.isArray(item.value)) {
        item.value.forEach(key => (res[key || ChartConstants.blank] = item.count));
        return res;
      }

      const key = (item.value as string) || ChartConstants.blank;
      res[key] = item.count;
      return res;
    }, {});

    if (this.isAttributeTypeUser(this.m.selected.groupByAttribute?.value.dataTypeId)) {
      valuesList.push(ChartConstants.currentUser);
      this.m.valuesMap[ChartConstants.currentUser] = this.m.options.systemUser.id;

      this.m.options.users.list
        .filter(user => Boolean(aggregatedData[user.id]) && this.m.options.systemUser.id !== user.id)
        .forEach(user => {
          if (!user || valuesList.includes(user.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string)) return;

          valuesList.push(user.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string);
          this.m.valuesMap[user.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string] = user.id;
        });
    } else {
      data.forEach(dto => {
        const value = (dto?.value as string) || null;
        const val = this.isGroupByAttributeEnum()
          ? this.getLabelByValue(value, this.m.selected.groupByAttribute?.value.dataTypeId)
          : value || ChartConstants.blank;
        this.m.valuesMap[val] = value;

        if (!valuesList.includes(val)) {
          valuesList.push(val);
        }
      });
    }

    valuesList.sort((a, b) => {
      if (a === ChartConstants.blank || (a === ChartConstants.currentUser && b !== ChartConstants.blank)) return 1;
      else if (b === ChartConstants.blank || (b === ChartConstants.currentUser && a !== ChartConstants.blank)) return -1;

      return 0;
    });

    this.m.options.artifactAttributeValues.setList(valuesList);
  }

  getLabelByValue(value: string | null, dataTypeId: any): string {
    return this.m.options.dataTypes.listMap[dataTypeId]?.values?.find(v => v.value === value)?.label || ChartConstants.blank;
  }

  isGroupByAttributeEnum(): boolean {
    return this.m.options.dataTypes.listMap[this.m.selected.groupByAttribute?.value.dataTypeId]?.kind === DataTypeKind.enumerated;
  }

  isAttributeTypeUser(dataTypeId: string): boolean {
    return this.m.options.dataTypes.listMap[dataTypeId]?.baseDataType === BaseDataType.user;
  }

  getOr(list: string[], attr: string): any {
    const $or: any = [{ [attr]: { $in: list.filter(v => v) } }];
    list.includes('') && ['', []].forEach($eq => $or.push({ [attr]: { $eq } }));
    return $or;
  }

  onFitChange(): void {
    this.updateCenterTotal();
    this.updateChartData();
  }

  updateCenterTotal(): void {
    if (this.m.selected.fitMode === FitMode.vertical) {
      this.m.settings.chartOptions.isShowLegend = false;
      this.m.selected.chartTotalPosition = ChartTotalPosition.center;

      if (this.m.settings.chartType === ChartWidgetTypeChart.pie) {
        this.m.settings.chartOptions.isShowTotal = false;
        this.m.selected.totalByAttribute = null;
      }

      setTimeout(() => {
        this.m.options.chartTotalPosition.setList(this.m.options.chartTotalPosition.list.filter(item => item === ChartTotalPosition.center));
      });
    } else {
      this.m.options.initChartTotalPosition(this.m.settings.chartType === ChartWidgetTypeChart.pie);
    }
  }

  updateTotalPosition(): void {
    this.m.updateTotal = !this.m.updateTotal;
  }

  updateFit(): void {
    const elem = (document as any).getElementById(this.c.chartId);
    if (!elem) return;
    const parent = elem.closest('.block-part-body') || elem.closest('.block-part');
    const wrapper = parent?.querySelector('div');
    const title = elem.getElementsByClassName('title-cont')[0] || 0;
    if (!elem || !parent || !wrapper) return;

    wrapper.style.padding = '0';
    parent.style.padding = '0';
    const titleHeight = title && parseInt(title.offsetHeight, 10);
    let width = parent.offsetWidth;
    let height = parent.offsetHeight;

    const card = elem.closest('app-card-area');
    if (card?.style) {
      const { paddingLeft, paddingRight, paddingTop, paddingBottom } = card.style;
      width = card.clientWidth - parseInt(paddingLeft) - parseInt(paddingRight);
      height = card.clientHeight - parseInt(paddingTop) - parseInt(paddingBottom);
    }

    elem.style.width = (this.m.selected.fitMode === FitMode.vertical ? height : width) - titleHeight + 'px';
  }

  initResizeObserver(): void {
    const elem = (document as any).getElementById(this.c.chartId);
    const card = elem.closest('app-card-area');
    if (!card) return;
    this.m.resizeObserver = new (window as any).ResizeObserver(() => this.updateFit());
    this.m.resizeObserver.observe(card);
  }

  async updateChartData(): Promise<any> {
    if (!this.m.selected.groupByAttribute?.value.id || !this.m.selected.artifactType?.value.id) {
      return;
    }
    this.m.options.chartData = null;
    this.checkRestriction();

    this.m.prevFit !== this.m.selected.fitMode && this.updateFit();
    this.m.prevFit = this.m.selected.fitMode;

    // const markers: string[] = this.m.options.artifactAttributeValues.list
    //   .map(item => (item === ChartConstants.blank ? '' : item))
    //   .filter(item => item !== ChartConstants.currentUser);
    // const list = markers.map(label => this.m.valuesMap[label] || label || '');

    const isSystemAttribute = this.filterService.isSystemAttribute(this.m.selected.groupByAttribute.value.id);
    const isDateTimeSystemAttribute = this.filterService.isDateTimeSystemAttribute(this.m.selected.groupByAttribute.value.id);
    const isUserSystemAttribute = this.filterService.isUserSystemAttribute(this.m.selected.groupByAttribute.value.id);

    const attr = isSystemAttribute
      ? this.m.selected.groupByAttribute.value.systemAttributeKey || ''
      : GetArtifactAttributeValuePath(this.m.selected.groupByAttribute.value.id);

    const $and: any[] = [{ artifactTypeId: { $in: [{ $oid: this.m.selected.artifactType.value.id }] } }, { deleted: { $eq: null } }];
    // if (!isSystemAttribute) {
    //   $and.push({ $or: this.getOr(list, attr) });
    // }

    const promiseArr = this.m.selected.attributesFilter?.filter(af => af.type === ArtifactFilterType.link).map(af => this.filterService.getLinkQuery(af));

    promiseArr.length && (await Promise.all(promiseArr)).forEach(query => query && $and.push(query));

    this.m.selected.attributesFilter?.forEach(af => {
      const query = this.filterService.getQuery(af);
      query && $and.push(query);
    });

    const filter: string = JSON.stringify({ $and });
    const params: any = { filter, groupBy: attr };

    if (this.m.selected.totalByAttribute) {
      params.attributeIds = JSON.stringify([this.m.selected.totalByAttribute.value.id]);
      params.subOperations = JSON.stringify(['sum']);
    }

    const dataType = this.m.options.dataTypes.listMap[this.m.selected.groupByAttribute.value.dataTypeId];
    if (isDateTimeSystemAttribute || (dataType?.baseDataType && [BaseDataType.date, BaseDataType.dateTime].includes(dataType.baseDataType))) {
      params.dateFormat = 'DAY';
    }

    const res: ArtifactGroupResponseDto = await lastValueFrom(this.tenantArtifactService.artifactControllerGroupby(params));
    const values: number[] = [];
    const data: any = {};
    const totalSumPerGroup: number[] = [];
    let labels: string[] = [];
    let total = 0;
    let totalSum = 0;

    this.setArtifactAttributeValues(res.data);

    res.data?.sort(this.helper.blankSortFn).forEach((item: ArtifactGroupItemResponseDto) => {
      // if empty array then store it and show it as [blank]
      (Array.isArray(item.value) && item.value.length ? item.value : [item.value]).forEach((v: string) => {
        const key = v || ChartConstants.blank;
        !data[key] && (data[key] = 0);
        data[key] += item.count;
      });

      const sum = item.sum ? Object.values(item.sum)[0] : 0;
      totalSum += sum;
      totalSumPerGroup.push(sum);
    });

    Object.keys(data).forEach(id => {
      let label = isSystemAttribute ? id : ChartConstants.blank;
      Object.keys(this.m.valuesMap).forEach(key => {
        if (this.m.valuesMap[key] === id) {
          label = key;
        }
      });

      labels.push(label);
      values.push(data[id]);
      total += data[id];
    });

    if (isUserSystemAttribute) {
      this.setUserList();
      labels = labels.map(
        label =>
          (this.m.options.users.listMap[label]?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) ||
          ChartConstants.blank,
      );
    }

    const backgroundColor: string[] = [];
    const dataTypeId = this.m.selected.groupByAttribute?.value.dataTypeId;
    const dataTypeValue: Array<DataTypeValueResponseDto> = this.m.options.dataTypes.listMap[dataTypeId]?.values || [];

    labels.forEach(label => {
      const bgColor = dataTypeValue.find(dt => dt.value === label || dt.label === label)?.backgroundColor;
      backgroundColor.push(bgColor || this.colorService.getColor(dataTypeId + ':' + label));
    });

    const totalSumLabel = this.m.selected.totalByAttribute?.label || null;

    this.m.options.chartData = {
      labels,
      datasets: [
        {
          label: this.m.selected.groupByAttribute?.label,
          data: totalSumLabel ? totalSumPerGroup : values,
          backgroundColor,
        },
      ],
      counter: {
        total,
        totalSum: this.m.selected.totalByAttribute ? Math.trunc(totalSum * 100) / 100 : null,
        totalSumLabel,
        totalSumPerGroup,
      },
    };
  }

  private checkRestriction(): void {
    if (this.m.settings.chartOptions.cutoutPercentage > 98) {
      this.m.settings.chartOptions.cutoutPercentage = 98;
    }
    if (this.m.settings.chartOptions.cutoutPercentage < 2) {
      this.m.settings.chartOptions.cutoutPercentage = 2;
    }
  }

  private isSupportedAttributeType(dataTypeId: string): boolean {
    const dataType = this.m.options.dataTypes.listMap[dataTypeId];

    if (!dataType) return false;
    if (dataType.isEnum || dataType.isUser) return true;

    return dataType.isSimple && (dataType.isDate || dataType.isDateTime);
  }

  private async loadOptions(): Promise<void> {
    const { dataTypes, artifactTypes, attributes, linkTypes } = this.cache.data;

    this.m.options.linkTypes.setList(
      (linkTypes.value as LinkTypeResponseDto[])
        .map(dto => new LinkType(dto))
        .reduce((directionalLinkTypes: DirectionalLinkType[], linkType: LinkType) => {
          return [
            ...directionalLinkTypes,
            new DirectionalLinkType(LinkDirection.outgoing, linkType),
            new DirectionalLinkType(LinkDirection.incoming, linkType),
          ];
        }, []),
    );
    this.m.options.dataTypes.setList(
      (dataTypes.value as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
      ID_KEY,
    );
    this.m.options.artifactTypes.setList(
      (artifactTypes.value as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
      ID_KEY,
    );
    this.m.options.attributes = [...(attributes.value as AttributeResponseDto[]), ...this.filterService.getSystemAttributes()];
    this.m.options.systemUser = this.cache.userProfile.value as ArtifactLinkResponseDto;
  }
}
