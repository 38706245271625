import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrInputMappersType, AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';

export type QrCodesAvrType = Extract<AvrTypesType, 'qr-codes'>;

export enum QrCodesOutputType {
  svg = 'SVG',
  png = 'PNG',
}
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const outputTypeCheck: [AvrInputMappersType[QrCodesAvrType]['outputType']] extends [`${QrCodesOutputType}`]
  ? `${QrCodesOutputType}` extends AvrInputMappersType[QrCodesAvrType]['outputType']
    ? true
    : false
  : false = true;

export class QrCodesAvrInputMapperDto extends BaseAvrInputMapperDto<QrCodesAvrType> {
  constructor() {
    super(
      {
        ['content']: {
          isOptional: false,
          value: '',
          readableName: 'Content',
          description: '[STRING] Content (URL, Plain Text, vCard, Email Address, SMS, WiFi Network Information, Geolocation, vEvent etc.)',
        },
      },
      {
        ['outputType']: {
          isOptional: false,
          value: QrCodesOutputType.svg,
          readableName: 'Output type',
          description: 'Select from supported output types',
        },
      },
    );
  }
}
