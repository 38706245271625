import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantUserService } from '@api/services/tenant-user.service';
import { Environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CoreComponent } from '@shared/core/components/core.component';
import { CoreParams } from '@shared/core/types/core.types';
import { PageHelper } from '@shared/helpers/page-helper';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AuthorizationHelperService } from '@shared/services/authorization/authorization-helper.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { PushNotificationService } from '@shared/services/push-notification.service';
import { ScriptLoaderService } from '@shared/services/script-loading.service';
import { StateKey } from '@shared/types/local-storage.types';
import { lastValueFrom } from 'rxjs';
import { AppService } from './app.service';
import { AppModel } from './app.types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends CoreComponent<AppService, AppModel> {
  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: AppService,
    announcement: AnnouncementService,
    public readonly blockUiService: BlockUiService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly scriptLoaderService: ScriptLoaderService,
    private readonly pageHelper: PageHelper,
    private readonly authorizationHelperService: AuthorizationHelperService,
    private readonly authorizationService: AuthorizationService,
    private readonly tenantUserService: TenantUserService,
  ) {
    super(route, router, translate, new AppModel(), service, announcement, new CoreParams({ initOnQueryParamChange: false }));
  }

  async onInit(): Promise<void> {
    super.onInit();
    await this.processSsoTokenIfNeeded();
    if (Environment.enableInstana) this.loadInstanaScripts();
  }

  onDestroy(): void {
    super.onDestroy();
    this.pushNotificationService.destroy();
  }

  private async processSsoTokenIfNeeded(): Promise<void> {
    const { ssoLogin, ssoToken, ssoError } = this.route.snapshot.queryParams;

    if (ssoError) {
      await this.announcement.error(ssoError);
      await this.router.navigate(['.'], { queryParams: {} });
      window.opener && setTimeout(() => window.close(), 1000);
      return;
    }

    if (ssoLogin !== 'true' || !ssoToken || !window.opener) return;

    this.pageHelper.usePublicToken = false;
    await this.authorizationHelperService.setToken(ssoToken, StateKey.session);
    await this.authorizationService.afterLogin(await lastValueFrom(this.tenantUserService.userControllerGetInfo()), ssoToken);
    window.close();
  }

  private loadInstanaScripts(): void {
    this.scriptLoaderService.load(
      `(function (s, t, a, n) {
        s[t] ||
          ((s[t] = a),
          (n = s[a] =
            function () {
              n.q.push(arguments);
            }),
          (n.q = []),
          (n.v = 2),
          (n.l = 1 * new Date()));
      })(window, 'InstanaEumObject', 'ineum');

      ineum('reportingUrl', 'https://instana.softacus.com:446/eum/');
      ineum('key', '${Environment.instanaKey}');
      ineum('trackSessions');
      ineum('autoPageDetection', true);
      ineum('meta', 'userEmail', (() => { return JSON.parse(localStorage.getItem('sessionState') || localStorage.getItem('masterState') || '{}').user?.email || 'NO EMAIL';})());
      ineum('meta', 'tenantId', (() => { return JSON.parse(localStorage.getItem('sessionState') || localStorage.getItem('masterState') || '{}').user?.tenant?.id || 'NO TENANT ID';})());`,
      { async: 'true' },
      true,
    );
    this.scriptLoaderService.load(`https://instana.softacus.com:446/eum/eum.min.js`, { async: 'true', crossorigin: 'anonymous', defer: 'true' });
  }
}
