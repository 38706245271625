import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SidebarWidgetService {
  static readonly commonSettingsKey = 'commonSettings';

  settingsMap: Record<string, SidebarPositionSettings> = {};
  positionChange$: Observable<void>;

  private readonly positionChange: Subject<void> = new Subject<void>();

  constructor() {
    this.positionChange$ = this.positionChange.asObservable();
  }

  isPositionRight(id?: string): boolean {
    return this.getPositionSettings(id).position === SidebarPosition.right;
  }

  isPositionLeft(id?: string): boolean {
    return this.getPositionSettings(id).position === SidebarPosition.left;
  }

  isFullscreen(id?: string): boolean {
    return this.getPositionSettings(id).fullScreen;
  }

  getPositionSettings(_id?: string): SidebarPositionSettings {
    const id = _id || SidebarWidgetService.commonSettingsKey;
    !this.settingsMap[id] && (this.settingsMap[id] = { position: SidebarPosition.left, fullScreen: false });
    return this.settingsMap[id];
  }

  togglePosition(_id?: string): void {
    const id = _id || SidebarWidgetService.commonSettingsKey;
    const pos = this.getPositionSettings(id);
    this.settingsMap[id].position = pos.position === SidebarPosition.left ? SidebarPosition.right : SidebarPosition.left;
    this.positionChange.next();
  }

  toggleFullscreen(_id?: string): void {
    const id = _id || SidebarWidgetService.commonSettingsKey;
    const pos = this.getPositionSettings(id);
    this.settingsMap[id].fullScreen = !pos.fullScreen;
  }
}

export interface SidebarPositionSettings {
  position: SidebarPosition;
  fullScreen: boolean;
}

export enum SidebarPosition {
  left = 'left',
  right = 'right',
}

export enum SidebarActions {
  toggleFullscreen = 'toggleFullscreen',
  togglePosition = 'togglePosition',
}
