import { Component, Input } from '@angular/core';
import { PageSection } from '@private/pages/page-management/page-builder-graphical/types/page-section';

@Component({
  selector: 'app-page-section-settings',
  templateUrl: './page-section-settings.component.html',
  styleUrls: ['./page-section-settings.component.scss'],
})
export class PageSectionSettingsComponent {
  @Input() section: PageSection;
  @Input() applicationId: string;

  @Input() set domElements([outerContainer, innerContainer]: [HTMLDivElement, HTMLDivElement]) {
    this.outerContainer = outerContainer;
    this.innerContainer = innerContainer;
  }

  outerContainer: HTMLElement;
  innerContainer: HTMLElement;
}
