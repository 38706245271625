import { ActivatedRoute } from '@angular/router';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { LinkDirection, NewLinkBoilerplate } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetAttributeFromClientAttribute, GetDataTypeFromClientAttribute } from '@shared/methods/artifact.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewArtifactType, NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { LinkType } from '@shared/types/link-type.types';
import { ArtifactTypeLinkRestriction, NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { LinkPopupModelDto } from '@widgets/link-popup/types/link-popup.types';
import { PAGE_BUILDER_URL } from '@widgets/shared/constants/widget.constants';
import { ReplaceRuntimeVariablesPipe } from '@widgets/shared/pipes/replace-runtime-variables.pipe';
import { AttributeFormatSettings, LinkTypeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { WidgetSaveButtonVisibilityEnum } from '@widgets/shared/types/style.types';
import { isEqual } from 'lodash';
import { lastValueFrom, Subject, Subscription, tap } from 'rxjs';
import { ArtifactWidgetFormItem, ArtifactWidgetFormItemDto } from './artifact-widget-form.types';
import { ArtifactWidgetOptions } from './artifact-widget-options.types';
import { ArtifactWidgetSettings } from './artifact-widget-settings.types';

export class ArtifactWidgetValue {
  constructor(public model: ArtifactWidgetModel = new ArtifactWidgetModel()) {}
}

export interface ArtifactWidgetModelDto {
  form: ArtifactWidgetFormItemDto[];
  selected: ArtifactWidgetModelSelectedDto;
  settings: ArtifactWidgetSettings;
  formatsMap: ArtifactWidgetFormatsMap;
  linkRestrictions: ArtifactTypeLinkRestriction[];
  linkingPopupDtoMap?: Record<string, Record<string, LinkPopupModelDto>>;
}

export interface ArtifactWidgetModelSelectedDto {
  artifactTypeId: string;
  linkType: { id: string; direction: LinkDirection } | null;
}

export interface ArtifactWidgetModelParams {
  options: ArtifactWidgetOptions;
  model: ArtifactWidgetModelDto;
  attributes: ListContainer<NewAttribute>;
  cache: NewCacheService;
  route: ActivatedRoute;
}

export class ArtifactWidgetSelectedEntities {
  artifactType: NewArtifactType | null = null;
  prevArtifactTypeId?: string | null;
  artifact: NewArtifact | null = null;
  linkType: SelectOption<string, LinkType, LinkDirection> | null = null;
  item: SelectOption<string, NewClientAttribute | LinkType> | null = null;

  constructor(entities?: Partial<ArtifactWidgetSelectedEntities>) {
    entities && Object.assign(this, entities);
  }
}

export class ArtifactWidgetSubscriptions {
  runtimeState$: Subscription;
  queryParams$: Subscription;
  saveDebounce$: Subject<ArtifactSaveEventObject> = new Subject<ArtifactSaveEventObject>();
}

export class ArtifactWidgetFormatsMap {
  attribute: Record<string, AttributeFormatSettings> = {};
  linkType: Record<string, LinkTypeFormatSettings> = {};
}

export interface ArtifactWidgetModelArgs {
  replaceRuntimeVariablesPipe?: ReplaceRuntimeVariablesPipe;
}

export class ArtifactWidgetModel {
  replaceRuntimeVariablesPipe?: ReplaceRuntimeVariablesPipe;
  artifactDto?: ArtifactResponseDto;

  options = new ArtifactWidgetOptions();
  selected = new ArtifactWidgetSelectedEntities();
  subscriptions = new ArtifactWidgetSubscriptions();
  settings = new ArtifactWidgetSettings();
  formatsMap = new ArtifactWidgetFormatsMap();

  form: ArtifactWidgetFormItem[] = [];
  originalForm: ArtifactWidgetFormItem[] = [];
  linkRestrictions: ArtifactTypeLinkRestriction[] = [];
  artifactWidgetCustomAttributeHelper = new ArtifactWidgetCustomAttributeHelper();

  newLinksMap: Record<string, Record<LinkDirection, NewLinkBoilerplate[]>> = {};
  linkMap: Record<string, Record<LinkDirection, NewLink[]>> = {};
  linksDto: LinkResponseDto[] = [];
  linkedArtifactsMap: Record<string, NewArtifact> = {};
  queryParams: Record<string, any> = {};
  linkingPopupDtoMap: Record<string, Record<string, LinkPopupModelDto>> = {};

  artifactId: string | null;
  previousArtifactId: string | null;
  previousFolderId: string | null;
  isFirstLoad = true;
  isFormFocus = false;
  isNoItemEditable = false;
  isAttributeSelected = false;
  isEditInProgress = false;
  showSaveButton = false;
  showSaveAndNotifyButton = false;
  hasFormChanged = false;
  isNoTabKeyActiveInUrl = true;
  doResetForm = false;
  isCaptchaValid = false;
  initSequence: number;
  initAttributesValue: Record<string, NewClientAttribute> = {};

  newArtifactFile: Blob | null = null;

  constructor(args?: ArtifactWidgetModelArgs) {
    this.options = new ArtifactWidgetOptions();

    if (args?.replaceRuntimeVariablesPipe) this.replaceRuntimeVariablesPipe = args.replaceRuntimeVariablesPipe;
  }

  setFromDto(params: ArtifactWidgetModelParams): void {
    this.options = params.options;
    const model = params.model;

    if (model) {
      this.settings = new ArtifactWidgetSettings(model.settings);
      this.formatsMap = new ArtifactWidgetFormatsMap();
      this.linkingPopupDtoMap = model?.linkingPopupDtoMap || {};

      if (params.model.formatsMap?.attribute) {
        const attributeKeys = Object.keys(params.model.formatsMap.attribute);
        attributeKeys.forEach(key => (this.formatsMap.attribute[key] = new AttributeFormatSettings(params.model.formatsMap.attribute[key])));
      }

      if (params.model.formatsMap?.linkType) {
        const linkTypeKeys = Object.keys(params.model.formatsMap.linkType);
        linkTypeKeys.forEach(key => (this.formatsMap.linkType[key] = new LinkTypeFormatSettings(params.model.formatsMap.linkType[key])));
      }

      if (model.selected.artifactTypeId) {
        this.selected.artifactType = this.options.artifactTypes.listMap[model.selected.artifactTypeId];

        if (this.selected.artifactType) {
          Object.keys(this.selected.artifactType.attributes).forEach(key => {
            const attribute = this.selected.artifactType?.attributes[key];
            this.options.clientAttributesPattern[key] = attribute?.initialValue;
          });
        }
      }

      this.generateAttributeOptions(params.attributes, params.options, params.route);

      if (model.selected.linkType?.id?.length && model.selected.linkType.direction) {
        const linkType = this.options.linkTypes.listMap[model.selected.linkType.id];
        this.selected.linkType = new SelectOption(
          LinkMethods.isOutgoing(model.selected.linkType.direction) ? linkType.outgoingName : linkType.incomingName,
          linkType,
          model.selected.linkType.direction,
        );
      }

      if (model.form?.length) {
        this.form = model.form
          .map(item => {
            const { id, meta, columns, tabSettings, folderPickerSettings } = item as ArtifactWidgetFormItemDto;

            if (this.options.linkTypeIdsMap[id]) {
              const linkType = this.options.linkTypeOptions.find(option => option.meta === meta && option.value.id === id);
              return new ArtifactWidgetFormItem({ linkType, columns, tabSettings });
            } else {
              const attribute = this.options.clientAttributes.find(option => option.value.id === id);

              if (!attribute) return null;

              return new ArtifactWidgetFormItem({ attribute, columns, tabSettings, folderPickerSettings });
            }
          })
          .filter(Boolean) as ArtifactWidgetFormItem[];
      }

      const relevantLinkTypes = (params.cache.data.linkTypes.value as LinkTypeResponseDto[]).filter(linkType => {
        const { restrictions } = linkType as LinkTypeResponseDto;

        if (restrictions) {
          return restrictions.some(
            restriction =>
              restriction.sourceArtifactTypeId === (this.selected.artifactType?.id as string) ||
              restriction.destinationArtifactTypeId === (this.selected.artifactType?.id as string),
          );
        }

        return false;
      });
      this.linkRestrictions = LinkMethods.getLinkRestrictionsForArtifactType(this.selected.artifactType?.id as string, relevantLinkTypes);
    }
  }

  setNoItemEditableFlag(value: boolean): void {
    this.isNoItemEditable = value;
    this.updateShowSaveButtonFlag();
  }

  setShowSaveButtonFlag(value: boolean): void {
    this.showSaveButton = value;
  }

  setHasFormChangedFlag(value: boolean): void {
    this.hasFormChanged = value;
  }

  updateNoAttributeEditableFlag(): void {
    if (!this.form || !Array.isArray(this.form)) {
      return;
    }

    for (const item of this.form) {
      if (item.attribute && this.formatsMap.attribute[item?.attribute?.value?.id]?.editable !== false) {
        this.setNoItemEditableFlag(false);
        this.updateShowSaveButtonFlag();
        return;
      }
    }
    this.setNoItemEditableFlag(true);
    this.updateShowSaveButtonFlag();
  }

  updateShowSaveButtonFlag(): void {
    const { saveButtonVisibility, saveAndNotifyButtonVisibility } = this.settings;
    const { always, onChangeGreen, onChange } = WidgetSaveButtonVisibilityEnum;

    if (!this.isNoItemEditable) {
      this.showSaveButton = [always, onChangeGreen].includes(saveButtonVisibility) || (saveButtonVisibility === onChange && this.hasFormChanged);
      this.showSaveAndNotifyButton =
        [always, onChangeGreen].includes(saveAndNotifyButtonVisibility) || (saveAndNotifyButtonVisibility === onChange && this.hasFormChanged);
    }
  }

  updateHasFormChangedFlag(): void {
    this.hasFormChanged = !isEqual(this.originalForm, this.form);
  }

  async mapFilesToArtifactType(
    cache: NewCacheService,
    announcement: AnnouncementService,
    attributes: Record<string, NewAttribute>,
    dataTypes: Record<string, NewDataType>,
  ): Promise<void> {
    if (this.selected.artifactType) {
      try {
        const fileIds: Set<string> = new Set();
        const formAttributes = this.form
          .filter(item => {
            return (
              item.attribute &&
              GetDataTypeFromClientAttribute(item.attribute.value, attributes, dataTypes)?.baseDataType === BaseDataType.file &&
              item.attribute.value?.value?.length
            );
          })
          .map(item => item.attribute?.value);

        formAttributes.forEach(attr => {
          if (Array.isArray(attr?.value)) {
            attr?.value?.forEach(file => !file?.id && fileIds.add(file));
          } else {
            attr?.value?.length && !attr.value?.id && fileIds.add(attr.value);
          }
        });

        if (fileIds.size) {
          await lastValueFrom(
            cache.data.artifacts.getMany$([...fileIds]).pipe(
              tap(data => {
                formAttributes.forEach(attr => {
                  if (attr) {
                    if (Array.isArray(attr.value)) {
                      const attributeFiles: Array<any> = [];
                      attr.value.forEach(value => attributeFiles.push(data.find(fileArtifactRes => fileArtifactRes.id === value)));
                      attr.value = attributeFiles;
                    } else {
                      attr.value = data.find(fileArtifactRes => fileArtifactRes.id === attr.value);
                    }
                  }
                });
              }),
            ),
          );
        }
      } catch (e) {
        console.log(e);
        await announcement.error('Failed to load files');
      }
    }
  }

  async generateAttributeOptions(attributes: ListContainer<NewAttribute>, options?: ArtifactWidgetOptions, route?: ActivatedRoute): Promise<void> {
    this.selected?.prevArtifactTypeId !== this.selected?.artifactType?.id && (this.options.clientAttributes = []);
    this.selected && (this.selected.prevArtifactTypeId = this.selected.artifactType?.id);

    this.options.alreadyUsedFormItemsMap = {};
    const ids = this.options.clientAttributes.map(attr => attr.value.id);

    const allAttributes = options?.attributes.loaded ? options.attributes : this.options.attributes;
    const allDataTypes = options?.dataTypes.loaded ? options.dataTypes : this.options.dataTypes;

    if (this.selected?.artifactType) {
      const clientAttributes = Object.values(this.selected.artifactType.attributes);

      for (const item of clientAttributes) {
        const attribute = allAttributes.listMap[item.id];
        const dataType = allDataTypes.listMap[attribute.dataTypeId];

        if (!ids.includes(item.id)) {
          const value = this.formatClientAttributeValueByDataType(item, dataType);

          this.options.clientAttributes.push(
            new SelectOption(
              GetAttributeFromClientAttribute(item, attributes.listMap)?.name || '',
              new NewClientAttribute({
                id: item.id,
                isMandatory: item.isMandatory,
                value,
              }),
            ),
          );
        }

        this.options.alreadyUsedFormItemsMap[item.id] = false;
      }

      if (this.replaceRuntimeVariablesPipe) {
        this.options.clientAttributes.forEach(item => {
          this.replaceRuntimeVariablesPipe!.transform(item.value.value).then(value => (item.value.value = value));
        });
      }
    }

    this.options.clientAttributes = [
      ...this.options.clientAttributes,
      ...this.artifactWidgetCustomAttributeHelper.getCustomAttributeOptions(this.selected?.artifactType?.format),
    ];

    if (this.settings.widgetType === ArtifactWidgetType.registration) {
      this.options.clientAttributes.push(
        new SelectOption(
          NonAttributeKeys.PASSWORD_LABEL,
          new NewClientAttribute({
            id: NonAttributeKeys.PASSWORD_ID,
            isMandatory: true,
            value: '',
          }),
        ),
        new SelectOption(
          NonAttributeKeys.CONFIRM_PASSWORD_LABEL,
          new NewClientAttribute({
            id: NonAttributeKeys.CONFIRM_PASSWORD_ID,
            isMandatory: true,
            value: '',
          }),
        ),
      );
    }

    if (this.settings.widgetType === ArtifactWidgetType.login) {
      this.options.clientAttributes = [
        new SelectOption(
          NonAttributeKeys.LOGIN_LABEL,
          new NewClientAttribute({
            id: NonAttributeKeys.LOGIN_ID,
            isMandatory: true,
            value: undefined,
          }),
        ),
        new SelectOption(
          NonAttributeKeys.PASSWORD_LABEL,
          new NewClientAttribute({
            id: NonAttributeKeys.PASSWORD_ID,
            isMandatory: true,
            value: undefined,
          }),
        ),
      ];
    }

    if (this.settings.widgetType === ArtifactWidgetType.recoverPassword) {
      const { email, token } = route?.snapshot.queryParams || {};

      this.options.clientAttributes = [
        new SelectOption(
          NonAttributeKeys.RECOVER_PASSWORD_LABEL,
          new NewClientAttribute({
            id: NonAttributeKeys.LOGIN_ID,
            isMandatory: true,
            value: email || undefined,
          }),
        ),
      ];

      if (token || window.location.href.includes(PAGE_BUILDER_URL)) {
        this.options.clientAttributes.push(
          new SelectOption(
            NonAttributeKeys.NEW_PASSWORD_LABEL,
            new NewClientAttribute({
              id: NonAttributeKeys.PASSWORD_ID,
              isMandatory: true,
              value: '',
            }),
          ),
          new SelectOption(
            NonAttributeKeys.CONFIRM_PASSWORD_LABEL,
            new NewClientAttribute({
              id: NonAttributeKeys.CONFIRM_PASSWORD_ID,
              isMandatory: true,
              value: '',
            }),
          ),
        );
      }
    }

    this.options.linkTypeOptions.forEach(option => (this.options.alreadyUsedFormItemsMap[option.value.id + '_' + option.meta] = false));

    this.selected?.artifactType &&
      Object.keys(this.selected.artifactType.attributes).forEach(key => {
        const attribute = this.selected.artifactType?.attributes[key];
        this.options.clientAttributesPattern[key] = attribute?.initialValue;
      });
  }

  toServer(): ArtifactWidgetModelDto {
    return {
      form: this.form.map(item => item.toServer()),
      selected: {
        artifactTypeId: this.selected.artifactType?.id || '',
        linkType: { id: this.selected.linkType?.value.id || '', direction: this.selected.linkType?.meta as any },
      },
      settings: this.settings,
      formatsMap: this.formatsMap,
      linkRestrictions: this.linkRestrictions,
      linkingPopupDtoMap: this.linkingPopupDtoMap,
    };
  }

  private formatClientAttributeValueByDataType(item: NewArtifactTypeClientAttribute, dataType: NewDataType): any {
    if (this.formatsMap.attribute[item.id]?.ignoreInitialValue) return null;

    const { date, dateTime, time, user, text, html } = BaseDataType;
    const widgetItemInitialValue = this.formatsMap.attribute[item.id]?.initialValue;
    const attribute = this.options.attributes.listMap[item.id];

    if (dataType.baseDataType === BaseDataType.boolean) {
      if (item.initialValue === 'true') return true;
      if (item.initialValue === 'false') return false;
      return null;
    }

    if (dataType.kind === DataTypeKind.enumerated) {
      return attribute?.multipleValues
        ? item.initialValue.map((itemInitialValue: string) => dataType.values?.find(value => value.value === itemInitialValue))
        : dataType.values?.find(value => value.value === item.initialValue) || null;
    }

    if (dataType.baseDataType === user) {
      if (widgetItemInitialValue && widgetItemInitialValue === ArtifactFormItemSingleUserInitialValueType.currentUser)
        return [
          new SelectOption(this.options.currentUser.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)], this.options.currentUser.id),
        ];

      if (item.initialValue) {
        if (attribute?.multipleValues) {
          return item.initialValue
            .map((id: string) => this.options.users.listMap[id])
            .filter((item: SelectOption<string, string> | undefined) => Boolean(item))
            .map(
              (user: ArtifactLinkResponseDto) => new SelectOption(user.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)], user.id),
            );
        } else {
          const user = this.options.users.listMap[item.initialValue];
          return user ? [new SelectOption(user.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string, user.id)] : null;
        }
      }

      return null;
    }

    if ([date, dateTime, time].includes(dataType.baseDataType as BaseDataType)) {
      if (widgetItemInitialValue && widgetItemInitialValue === ArtifactFormItemSingleDateInitialValueType.today) return new Date();
      if (attribute.multipleValues) return item.initialValue.length ? item.initialValue : null;
      if (item.initialValue) return new Date(item.initialValue);

      return null;
    }

    if ([text, html].includes(dataType.baseDataType as BaseDataType)) return widgetItemInitialValue ? widgetItemInitialValue : item.initialValue;

    if (dataType.baseDataType === BaseDataType.hyperlink) {
      if (item.initialValue === '') return null;
      return item.initialValue;
    }

    if (dataType.baseDataType === BaseDataType.file) {
      if (item.initialValue === '') return null;
      return item.initialValue;
    }

    return item.initialValue;
  }
}

export class ArtifactSaveEventObject {
  form: ArtifactWidgetFormItem[];
  artifactId: string;
  event: ArtifactSaveEventEnum;

  constructor(params?: Partial<ArtifactSaveEventObject>) {
    params && Object.assign(this, params);
  }

  get isBlur(): boolean {
    return this.event === ArtifactSaveEventEnum.blur;
  }

  get isChange(): boolean {
    return this.event === ArtifactSaveEventEnum.change;
  }
}

export interface SetDisplayVariantsMetaDataArgs {
  attributeId: string;
  attributes: ListContainer<NewAttribute>;
  isDate: boolean;
  isDateTime: boolean;
  formatSettings: AttributeFormatSettings;
  dataType: NewDataType;
}

export enum ArtifactWidgetType {
  artifact = 'Artifact',
  linkedArtifact = 'Linked artifact',
  registration = 'Registration',
  login = 'Login',
  recoverPassword = 'Recover password',
}

export enum ArtifactWidgetActionAfterLogin {
  defaultApplicationPage = 'Default user application page',
  specificPage = 'Specific page after login',
  applicationSelection = 'Application selection',
}

export enum ArtifactSaveEventEnum {
  blur = 'blur',
  change = 'change',
}

export enum ArtifactFormItemType {
  attribute = 'attribute',
  linkType = 'linkType',
}

export enum ArtifactFormItemSingleDateInitialValueType {
  today = 'today',
}

export enum ArtifactFormItemSingleUserInitialValueType {
  currentUser = 'currentUser',
}

export interface ArtifactUrlModuleParams {
  parentArtifactId: string | null;
  isHeading: boolean;
  into: boolean;
}
