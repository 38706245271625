<div class="mb-2">
  <span *ngIf="attribute.clientAttribute.isMandatory" class="mandatory-field"></span>
  <i [class]="attribute.attribute.icon"></i>
  {{ attribute.attribute.name }}
  <span *ngIf="valueDescription" class="text-italic text-bold">({{ valueDescription | translate }})</span>
</div>

<div class="flex align-items-center">
  <app-artifact-attribute-form-field
    [clientAttribute]="attribute.clientAttribute"
    [attribute]="attribute.attribute"
    [dataType]="attribute.dataType"
    [index]="fieldIndex"
    [labelBehaviour]="labelBehavior"
    [users]="users"
    [onChangeCb]="onChange"
    [previewAndFormatSettings]="formatSettings | constructPreviewFormatSettings : false"
    class="flex-1">
  </app-artifact-attribute-form-field>

  <p-button
    pTooltip="Clear"
    tooltipPosition="top"
    styleClass="p-button-rounded p-button-danger p-button-text ml-2"
    (onClick)="onClear()">
    <i class="bi bi-eraser"></i>
  </p-button>
  <p-button
    pTooltip="Restore"
    tooltipPosition="top"
    styleClass="p-button-rounded p-button-success p-button-text ml-2"
    (onClick)="onRestore()">
    <i class="pi pi-replay"></i>
  </p-button>
</div>
