import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TenantWorkflowService } from '@api/services/tenant-workflow.service';
import { TranslateModule } from '@ngx-translate/core';
import { AnnouncementService } from '@shared/services/announcement.service';
import { WorkflowClientInputData } from '@workflows/types/actions/action-schedule-workflow';
import { ButtonDirective } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-workflow-custom-run',
  standalone: true,
  imports: [
    ButtonDirective,
    CalendarModule,
    CheckboxModule,
    InputTextModule,
    NgIf,
    OverlayPanelModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    NgClass,
  ],
  templateUrl: './workflow-custom-run.component.html',
  styleUrl: './workflow-custom-run.component.scss',
})
export class WorkflowCustomRunComponent {
  @Input() ruleId: string;

  inputData: WorkflowClientInputData[] = [];
  protected scheduleDateTime: Date = new Date();
  protected waitUntilFinished = false;

  constructor(
    private readonly workflowService: TenantWorkflowService,
    private readonly announcement: AnnouncementService,
  ) {}

  onAddInputData(dataIndex: number, valueIndex: number): void {
    (this.inputData[dataIndex].value as string[]).splice(valueIndex + 1, 0, '');
  }

  onRemoveInputData(dataIndex: number, valueIndex: number): void {
    (this.inputData[dataIndex].value as string[]).splice(valueIndex, 1);
  }

  async runWorkflow(): Promise<void> {
    const inputDataValue = this.inputData.reduce(
      (inputDataMap: Record<string, string | string[] | null>, inputData: WorkflowClientInputData) => ({
        ...inputDataMap,
        [inputData.key]: inputData.isNull ? null : inputData.value,
      }),
      {},
    );

    lastValueFrom(
      this.workflowService.workflowControllerScheduleWorkflow({
        id: this.ruleId,
        body: { inputDataValue, datetime: this.scheduleDateTime.toISOString(), waitUntilFinished: this.waitUntilFinished },
      }),
    )
      .then(() => this.announcement.info('Workflow has been run'))
      .catch(() => this.announcement.error('Error running this workflow'));
  }
}
