import { inject, Injectable } from '@angular/core';
import { BulkArtifactEditPopupOptions } from '@shared/components/bulk-artifact-edit-popup/types/bulk-artifact-edit-popup-options';
import { BulkEditingAttribute } from '@shared/components/bulk-artifact-edit-popup/types/bulk-editing-attribute';
import { ID_KEY } from '@shared/constants/constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Injectable()
export class AttributesService {
  private readonly selectedArtifacts: NewArtifact[];
  private readonly artifactTypesOfSelectedArtifacts: ListContainer<NewArtifactType>;
  private readonly attributesOfArtifactTypes: ListContainer<NewAttribute>;
  private readonly allDataTypes: ListContainer<NewDataType>;

  constructor() {
    const { artifacts, artifactTypes, attributes, dataTypes }: BulkArtifactEditPopupOptions = inject(DynamicDialogConfig<BulkArtifactEditPopupOptions>).data;

    this.selectedArtifacts = artifacts;
    this.artifactTypesOfSelectedArtifacts = this.getArtifactTypesOfArtifacts(artifacts, artifactTypes);
    this.attributesOfArtifactTypes = this.getAttributesOfArtifactTypes(this.artifactTypesOfSelectedArtifacts, attributes);
    this.allDataTypes = dataTypes;
  }

  getBulkEditingAttributes(): BulkEditingAttribute[] {
    return this.attributesOfArtifactTypes.list
      .filter((attribute: NewAttribute) => this.isEditableAttribute(attribute))
      .map((attribute: NewAttribute) => this.createBulkEditingAttribute(attribute));
  }

  private createBulkEditingAttribute(attribute: NewAttribute): BulkEditingAttribute {
    const sameAttributeValue = this.isSameAttributeValue(attribute);
    const clientAttribute = this.createClientAttribute(attribute, sameAttributeValue);

    return new BulkEditingAttribute({
      id: attribute.id,
      attribute,
      clientAttribute,
      dataType: this.allDataTypes.listMap[attribute.dataTypeId],
      sameAttributeValue,
    });
  }

  private createClientAttribute(attribute: NewAttribute, sameAttributeValue: boolean): NewClientAttribute {
    return new NewClientAttribute({
      id: attribute.id,
      value: sameAttributeValue
        ? this.selectedArtifacts.find(this.artifactTypeHasAttribute(attribute))?.attributes[attribute.id]?.value
        : attribute.multipleValues
          ? []
          : '',
      name: attribute.name,
      isMandatory: this.artifactTypesOfSelectedArtifacts.list.some((artifactType: NewArtifactType) => artifactType.attributes[attribute.id]?.isMandatory),
    });
  }

  private getArtifactTypesOfArtifacts(artifacts: NewArtifact[], allArtifactTypes: ListContainer<NewArtifactType>): ListContainer<NewArtifactType> {
    const artifactTypeIds = artifacts.map(({ artifactTypeId }: NewArtifact) => artifactTypeId);
    const result = new ListContainer<NewArtifactType>();
    result.setList(allArtifactTypes.filterByKey(ID_KEY, [...new Set(artifactTypeIds)]), ID_KEY);

    return result;
  }

  private getAttributesOfArtifactTypes(artifactTypes: ListContainer<NewArtifactType>, allAttributes: ListContainer<NewAttribute>): ListContainer<NewAttribute> {
    const attributeIds = artifactTypes.list.reduce((acc: string[], artifactType: NewArtifactType) => [...acc, ...Object.keys(artifactType.attributes)], []);
    const result = new ListContainer<NewAttribute>();
    result.setList(allAttributes.filterByKey(ID_KEY, [...new Set(attributeIds)]), ID_KEY);

    return result;
  }

  private isSameAttributeValue(attribute: NewAttribute): boolean {
    return this.selectedArtifacts.filter(this.artifactTypeHasAttribute(attribute)).every(this.artifactHasSameAttributeValue(attribute));
  }

  private artifactTypeHasAttribute(attribute: NewAttribute): ({ artifactTypeId }: NewArtifact) => boolean {
    return ({ artifactTypeId }: NewArtifact) => this.artifactTypesOfSelectedArtifacts.listMap[artifactTypeId].hasAttribute(attribute.id);
  }

  private isEditableAttribute({ dataTypeId }: NewAttribute): boolean {
    const dataType = this.allDataTypes.listMap[dataTypeId];

    return !dataType?.isFile && !dataType?.isCounter;
  }

  private artifactHasSameAttributeValue(attribute: NewAttribute): (artifact: NewArtifact, index: number, artifacts: NewArtifact[]) => boolean {
    return (artifact: NewArtifact, index: number, artifacts: NewArtifact[]): boolean => {
      return attribute.multipleValues
        ? artifact.attributes[attribute.id].value?.every((value: string, index: number) => value === artifacts[0].attributes[attribute.id].value[index])
        : artifact.attributes[attribute.id].value === artifacts[0].attributes[attribute.id].value;
    };
  }
}
