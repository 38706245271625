import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegularUserResponseDto } from '@api/models';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  BLANK_FILTER_OPTION_LABEL,
  BLANK_OPTION_FILTER_URL_VALUE,
  CURRENT_USER_OPTION_LABEL,
  CURRENT_USER_URL_FILTER_VALUE,
  EMPTY_OPTION_FILTER_URL_VALUE,
  IS_NOT_EMPTY_OPTION_FILTER_LABEL,
  IS_NOT_EMPTY_OPTION_FILTER_VALUE,
} from '@shared/constants/constants';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { ConvertToServerDatetime } from '@shared/methods/date.methods';
import { NewAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DATTextFilterWidgetLayoutVariant } from '@shared/types/display-at-types';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { NewUser } from '@shared/types/user.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { FilterItem } from '@widgets/filter-widget/types/filter-widget-options.types';
import { TimeFilterRuleType } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter-options.types';
import { DateTimeFilter } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter.types';
import { NumberFilterService } from '@widgets/shared/components/artifact-filters/components/number-filter/service/number-filter.service';
import { NumberFilterRuleType } from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter-options.types';
import { NumberFilterOption, NumberFilterRange } from '@widgets/shared/components/artifact-filters/components/number-filter/types/number-filter.types';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { FILTER_WIDGET_NULL_IN_URL } from '@widgets/shared/constants/widget.constants';
import { FilterWidgetComponent } from '../filter-widget.component';
import { FilterWidgetModel, FilterWidgetModelDto, FilterWidgetSelectTag, FilterWidgetValue } from '../types/filter-widget.types';

@Injectable()
export class FilterWidgetService {
  c: FilterWidgetComponent;
  m: FilterWidgetModel;

  constructor(
    private readonly cache: NewCacheService,
    private router: Router,
    private route: ActivatedRoute,
    private attributeUtil: AttributeUtil,
    private readonly filterService: ArtifactFiltersService,
    private readonly filterMetaDataUtil: FilterMetadataUtil,
    private readonly numberFilterService: NumberFilterService,
  ) {}

  init(context: FilterWidgetComponent, dto?: FilterWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new FilterWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new FilterWidgetModel(dto);
    }

    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;

    const data = this.cache.data;

    context.registerSubscriptions([
      data.attributes.subscribe(attributes => {
        this.m.options.attributes = [...(attributes as AttributeResponseDto[]), ...this.filterService.getSystemAttributes()];
        this.raceResolver();
      }),
      data.dataTypes.subscribe(dataTypes => {
        this.m.options.dataTypes.setList(
          (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
          'id',
        );
        this.raceResolver();
      }),
      data.users.subscribe(users => {
        this.m.options.users.setList(users as RegularUserResponseDto[], 'id');
        this.raceResolver();
      }),
      this.route.queryParams.subscribe(this.onUrlChange.bind(this)),
    ]);

    if (this.m.settings.urlKey) {
      if (this.m.settings.isFilterMode) {
        this.m.settings.text = this.route.snapshot.queryParams[this.m.settings.urlKey] || '';
        return;
      }
    }
  }

  raceResolver(): void {
    if (!this.m.options.attributes.length || !this.m.options.dataTypes.loaded || !this.m.options.users.loaded) {
      return;
    }
    this.m.options.attributeValues.setList([
      ...this.m.options.attributes.filter(attr => this.optionsFilter(attr)),
      ...this.filterService.getSystemAttributes(),
    ]);
    this.m.settings.updateDto(this);
    this.updateValue();
    this.m.isLoad = true;

    const param = this.route.snapshot.queryParams[this.m.settings.urlKey];
    if (!param) {
      return;
    }

    if (this.isAttributeBoolean(this.m.settings.currentAttribute)) {
      this.m.filter.value = param.split(',').map((item: any) => (item === EMPTY_OPTION_FILTER_URL_VALUE ? '' : item));
      return;
    }

    if (this.isAttributeDecimalOrInteger(this.m.settings.currentAttribute)) {
      this.m.filter.value = this.numberFilterService.getFilterValueFromString(param);
    }

    if (this.m.settings.isDateMode) {
      this.initFilter();
      const val = param.split(',');
      const ruleType = val[0];
      this.m.filter.value.ruleTypes[0].ruleType = ruleType;

      if (ruleType === DateRangeFilterEnum.dateBetween) {
        this.m.filter.value.ruleTypes[0].value = [this.getDateFromValue(val[1]), this.getDateFromValue(val[2])];
      } else {
        this.m.filter.value.ruleTypes[0].value = val[1];
      }
    }

    this.m.activeIndexes = param
      .split(',')
      .map((val: any) => this.findIndexByValue(val))
      .filter((val: any) => !!val || val === 0);
  }

  getDateFromValue(val: string): Date | null {
    return !val ? null : new Date(val);
  }

  onKeypress(e: any): void {
    !this.isShowSearchButton() && e.key === 'Enter' && this.updateUrl(true);
  }

  onClearInput(): void {
    this.m.settings.text = '';
    this.updateUrl(true);
  }

  onClearBoolean(): void {
    this.m.filter.value = null;
    this.doUpdateUrl({}, this.m.settings.urlKey);
  }

  setActive(index: number | null = null): void {
    if (!index && index !== 0) {
      return;
    }

    if (this.m.activeIndexes.includes(index)) {
      this.m.activeIndexes = this.m.activeIndexes.filter(i => i !== index);
    } else {
      this.m.activeIndexes.push(index);
    }
  }

  findIndexByValue(val: string): number | null {
    let index = null;
    this.m.options.tagList.list.forEach((tagItem, indx) => {
      tagItem.value === val && (index = indx);
    });

    return index;
  }

  getQueryStringByActive(): string {
    if (this.m.settings.isDateMode) {
      return this.getUrlValueForDate();
    } else if (this.isAttributeBoolean(this.m.settings.currentAttribute)) {
      return this.getUrlValueForBoolean();
    } else if (this.isAttributeDecimalOrInteger(this.m.settings.currentAttribute)) {
      return this.getUrlValueForDecimalOrInteger();
    }

    return this.m.activeIndexes.map(index => this.m.options.tagList.list[index].value).join(',');
  }

  getUrlValueForDecimalOrInteger(): string {
    const rule = this.m.filter.value?.ruleTypes[0];
    const isEmptyTypeFilter = this.isEmptyTypeFilter(rule.ruleType);
    if ((!rule?.value || this.isRangeEmpty(rule)) && !isEmptyTypeFilter) {
      return '';
    }
    const ruleKey = Object.keys(NumberFilterRuleType)[Object.values(NumberFilterRuleType).indexOf(rule.ruleType)] as keyof typeof CoreListFilterEnum;
    const val = isEmptyTypeFilter ? '' : `,${rule.value}`;
    return `${rule.value instanceof NumberFilterRange ? (rule.value as NumberFilterRange).getUrlString() : `${CoreListFilterEnum[ruleKey]}${val}`}`;
  }

  isEmptyTypeFilter(ruleType?: NumberFilterRuleType): boolean {
    return ruleType === NumberFilterRuleType.isEmpty || ruleType === NumberFilterRuleType.isNotEmpty;
  }

  isRangeEmpty(rule: NumberFilterOption): boolean {
    if (rule.value instanceof NumberFilterRange) {
      return !rule.value.from || !rule.value.to;
    }

    return false;
  }

  getUrlValueForBoolean(): string {
    return this.m.filter.value === null || this.m.filter.value === '' ? FILTER_WIDGET_NULL_IN_URL : this.m.filter.value;
  }

  getUrlValueForDate(): string {
    const rule = (this.m.filter.value as DateTimeFilter)?.ruleTypes[0];
    const rangeTypes = Object.keys(DateRangeFilterEnum);
    if ((!rule || !rule.value) && !rangeTypes.includes(rule.ruleType)) {
      return '';
    }

    const ruleType = rule.ruleType;

    if (this.isCalendarType(ruleType)) {
      const date = rule.value instanceof Date ? rule.value : new Date(rule.value as any);
      return `${ruleType},${ConvertToServerDatetime(date)}`;
    } else if (this.filterMetaDataUtil.isFilterNumeric(ruleType as DateRangeFilterEnum)) {
      return `${ruleType},${rule.value}`;
    } else if (ruleType === DateRangeFilterEnum.dateBetween && Array.isArray(rule.value)) {
      const urlValue = rule.value.map(date => ConvertToServerDatetime(date)).join(',');
      return `${ruleType},${urlValue}`;
    }

    return ruleType;
  }

  isDataFilterPresent(): boolean {
    return this.m.settings.isDateMode && !!(this.m.filter.value as DateTimeFilter)?.ruleTypes[0]?.value;
  }

  isCalendarType(filterType: DateFilterEnum | DateRangeFilterEnum | TimeFilterRuleType): boolean {
    const { dateIs, dateIsNot, dateAfter, dateBefore, dateAfterOrEqualTo, dateBeforeOrEqualTo } = DateFilterEnum;
    return [dateIs, dateIsNot, dateAfter, dateBefore, dateAfterOrEqualTo, dateBeforeOrEqualTo].includes(filterType as DateFilterEnum);
  }

  removeUrlKey(key: string): void {
    this.changeUrlKey(key);
  }

  changeUrlKey(from: string, to?: string): void {
    const queryParams: any = {};
    !this.m.settings.isFilterMode && to && (this.m.activeIndexes.length > 0 || this.isDataFilterPresent()) && (queryParams[to] = this.getQueryStringByActive());
    this.m.settings.isFilterMode && to && (queryParams[to] = this.m.settings.text);
    this.doUpdateUrl(queryParams, from);
  }

  selectHandler(e: FilterWidgetSelectTag): void {
    if (e?.currentIndexes) {
      this.m.activeIndexes = e.currentIndexes;
    } else {
      e.isCtrl ? this.setActive(e.index) : (this.m.activeIndexes = [e.index]);
    }

    this.updateUrl();
  }

  onChangeFilter(isClear?: boolean): void {
    isClear && (this.m.settings.text = '');
    this.updateUrl(isClear);
  }

  updateUrl(isText?: boolean): void {
    const queryParams: any = {};
    const value = isText ? this.m.settings.text : this.getQueryStringByActive();
    (value || this.isAttributeBoolean(this.m.settings.currentAttribute)) && (queryParams[this.m.settings.urlKey] = value);
    queryParams[this.m.settings.urlKey] === null && (queryParams[this.m.settings.urlKey] = 'null');
    this.doUpdateUrl(queryParams, this.m.settings.urlKey);
  }

  doUpdateUrl(queryParams: any, excludeKey: string): void {
    Object.keys(this.route.snapshot.queryParams)
      .filter(key => key !== excludeKey)
      .forEach(key => {
        queryParams[key] = this.route.snapshot.queryParams[key];
      });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }

  isShowSearchButton(): boolean {
    return this.m.settings.textSelectedVariant.label === DATTextFilterWidgetLayoutVariant.DEFAULT;
  }

  optionsFilter(attr: NewAttribute): boolean {
    return (
      this.isAttributeDecimalOrInteger(attr) ||
      this.isAttributeEnumOrText(attr) ||
      this.isAttributeUser(attr) ||
      this.isAttributeDate(attr) ||
      this.isAttributeBoolean(attr) ||
      this.isAttributeCounter(attr)
    );
  }

  isAttributeDecimalOrInteger(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr?.dataTypeId];
    return dataType && dataType.kind === DataTypeKind.simple && [BaseDataType.decimal, BaseDataType.integer].includes(dataType?.baseDataType as BaseDataType);
  }

  isAttributeDate(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
    return dataType && dataType.kind === DataTypeKind.simple && [BaseDataType.date, BaseDataType.dateTime].includes(dataType?.baseDataType as BaseDataType);
  }

  isAttributeBoolean(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr?.dataTypeId];
    return dataType && dataType.kind === DataTypeKind.counter && dataType?.baseDataType === BaseDataType.boolean;
  }

  isAttributeCounter(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr?.dataTypeId];
    return dataType && dataType.kind === DataTypeKind.counter;
  }

  isAttributeEnum(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
    return dataType?.kind === DataTypeKind.enumerated;
  }

  isAttributeText(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
    return dataType?.kind === DataTypeKind.simple && (dataType?.baseDataType === BaseDataType.text || dataType?.baseDataType === BaseDataType.hyperlink);
  }

  isAttributeUser(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
    return dataType?.kind === DataTypeKind.simple && dataType?.baseDataType === BaseDataType.user;
  }

  isAttributeEnumOrText(attr: NewAttribute): boolean {
    const dataType = this.m.options.dataTypes.listMap[attr.dataTypeId];
    return (
      dataType?.kind === DataTypeKind.enumerated ||
      (dataType?.kind === DataTypeKind.simple && (dataType?.baseDataType === BaseDataType.text || dataType?.baseDataType === BaseDataType.hyperlink))
    );
  }

  onChangeAttribute(): void {
    this.m.activeIndexes = [];
    this.m.settings?.attribute?.label && (this.m.settings.urlKey = this.attributeUtil.getUrlQueryAttributeName(this.m.settings?.attribute?.label));

    this.updateValue();
  }

  updateValue(): void {
    if (!this.m.settings?.attribute) {
      return;
    }

    const attribute = this.getAttributeById(this.m.settings.attribute.value);
    this.m.settings.currentAttribute = attribute;
    this.m.settings.isFilterMode = false;
    this.m.settings.isDateMode = this.isSystemDateAttribute(attribute) || this.isAttributeDate(attribute);
    (this.m.settings.isDateMode || this.isAttributeBoolean(attribute) || this.isAttributeDecimalOrInteger(attribute)) && this.initFilter();

    if (this.isSystemAttribute(attribute)) {
      if (attribute.id === NonAttributeKeys.FOLDER_PATH_ID) {
        this.m.settings.isFilterMode = true;
      } else {
        this.m.settings.isFilterMode = false;
        !this.m.settings.isDateMode && this.m.options.tagList.setList(this.getUserList());
      }

      return;
    }

    this.m.settings.isFilterMode = this.isAttributeText(attribute) || this.isAttributeCounter(attribute);
    this.m.dataType = this.m.options.dataTypes.listMap[attribute.dataTypeId];
    this.m.dataType?.values && this.m.options.tagList.setList(this.m.dataType.values.map(dto => new FilterItem(dto)));
    this.isAttributeUser(attribute) && this.m.options.tagList.setList(this.getUserList());
  }

  getAttributeById(id: string): NewAttribute {
    return this.m.options.attributes.filter(attr => attr.id === id)[0];
  }

  private onUrlChange(params: any): void {
    if (this.m.settings.urlKey && !params[this.m.settings.urlKey]) {
      this.m.activeIndexes = [];
      this.m.filter && (this.m.filter = new ArtifactFilter());
      this.m.settings.text = '';
      this.m.isRefresh = !this.m.isRefresh;
    }
  }

  private getUserList(): FilterItem[] {
    const currentUser = this.getTagItemByUser({ email: CURRENT_USER_OPTION_LABEL, id: CURRENT_USER_URL_FILTER_VALUE || '' } as NewUser);
    const users = [...this.m.options.users.list.map(user => this.getTagItemByUser(user)), currentUser];

    if (!this.isSystemAttribute(this.m.settings.currentAttribute)) {
      users.push(this.getTagItemByUser({ email: BLANK_FILTER_OPTION_LABEL, id: BLANK_OPTION_FILTER_URL_VALUE } as NewUser));
      users.push(this.getTagItemByUser({ email: IS_NOT_EMPTY_OPTION_FILTER_LABEL, id: IS_NOT_EMPTY_OPTION_FILTER_VALUE } as NewUser));
    }

    return users;
  }

  private initFilter(): void {
    this.m.filter = ArtifactFilter.initial();
    if (this.m.settings.isDateMode) {
      this.m.filter.value = new DateTimeFilter();
      this.m.filter.dataType = new NewDataType({ baseDataType: 'DATE' } as DataTypeResponseDto);
    }
  }

  private getTagItemByUser(user: NewUser): FilterItem {
    return {
      label: user.email,
      value: user.id,
    } as FilterItem;
  }

  private isSystemDateAttribute(attribute: NewAttribute): boolean {
    return [NonAttributeKeys.CREATED_ON_ID, NonAttributeKeys.UPDATED_ON_ID].includes(attribute.id);
  }

  private isSystemAttribute(attribute: NewAttribute): boolean {
    return [
      NonAttributeKeys.FOLDER_PATH_ID,
      NonAttributeKeys.CREATED_BY_ID,
      NonAttributeKeys.UPDATED_BY_ID,
      NonAttributeKeys.CREATED_ON_ID,
      NonAttributeKeys.UPDATED_ON_ID,
    ].includes(attribute.id);
  }
}
