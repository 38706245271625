<div>
  <h6 *ngIf="label">{{ label | translate }}</h6>
  <div class="enum-list_item">
    <span class="p-float-label color-field">
      <span class="color-picker-wrapper">
        <p-colorPicker
          (onChange)="onColorChange($event.value)"
          [(ngModel)]="color"
          [disabled]="disabled"
          id="itemBorderBottomColorPicker">
        </p-colorPicker>
        <label *ngIf="!color" class="picker-label" for="{{uniqueId}}"></label>
      </span>
      <input [disabled]="disabled" [placeholder]="placeholder" (ngModelChange)="onColorChange($event)"
             [(ngModel)]="color" id="{{uniqueId}}" pInputText type="text">
    </span>
  </div>
</div>
