import { AfterViewInit, Component, Input } from '@angular/core';
import { ArtifactTypeFormatEnum, NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import {
  CreateArtifactsKeys,
  WorkflowActionCreateArtifacts,
  WorkflowCreateAttributeMap,
  WorkflowCreateAttributeMapValue,
} from '@workflows/types/actions/action-create-artifacts';
import { isArray } from 'lodash';

@Component({
  selector: 'app-action-create-artifacts',
  templateUrl: './action-create-artifacts.component.html',
  styleUrls: ['./action-create-artifacts.component.scss'],
})
export class ActionCreateArtifactsComponent implements AfterViewInit {
  @Input() action: WorkflowActionCreateArtifacts;
  @Input() artifactTypes: NewArtifactType[];
  @Input() attributes: ListContainer<NewAttribute>;

  useValues: boolean[] = [];
  selectedArtifactType: NewArtifactType | null;
  readonly RuleKeys = CreateArtifactsKeys;
  readonly ArtifactTypeFormatEnum = ArtifactTypeFormatEnum;

  ngAfterViewInit(): void {
    this.loadAttributesAndUseValues();
    this.setSelectedArtifactType();
  }

  onIsArtifactTypeDynamicChange(): void {
    this.action.actionSettings.artifactTypeId.value = null as any;
  }

  onArtifactTypeChange(artifactTypeId: string): void {
    this.generateAttributesMap(artifactTypeId);
    this.setAllUseValuesToTrue();
    this.setSelectedArtifactType();
  }

  onIsAttributeMapDynamicChange(): void {
    this.generateAttributesMap();
    this.setAllUseValuesToTrue();
  }

  onUseValueChange(key: string, value: WorkflowCreateAttributeMapValue, useValue: boolean): void {
    const attributesMap = this.action.actionSettings.attributesMap;
    if (!useValue) {
      (attributesMap.value as WorkflowCreateAttributeMap)[key] = isArray(value) ? [] : (null as any);
    } else {
      (attributesMap.value as WorkflowCreateAttributeMap)[key] = isArray(value) ? [''] : '';
    }
  }

  onAddAttributeValue(key: string): void {
    ((this.action.actionSettings.attributesMap.value as WorkflowCreateAttributeMap)[key] as string[]).push('');
  }

  onRemoveAttributeValue(key: string, index: number): void {
    ((this.action.actionSettings.attributesMap.value as WorkflowCreateAttributeMap)[key] as string[]).splice(index, 1);
  }

  attributesTrackByFn(index: number): number {
    return index;
  }

  attrValuesToTrackByFn(index: number): number {
    return index;
  }

  private generateAttributesMap(artifactTypeId: string = this.action.actionSettings.artifactTypeId.value as string): void {
    const artifactType = this.artifactTypes.find(at => at.id === artifactTypeId);
    if (!artifactType) return;

    this.action.actionSettings.attributesMap.value = Object.values(artifactType.attributes).reduce((attributesMap, attr) => {
      return {
        ...attributesMap,
        [attr.id]: !this.action.actionSettings.attributesMap.isDynamic && this.attributes.listMap[attr.id].multipleValues ? [''] : '',
      };
    }, {});
  }

  private loadAttributesAndUseValues(): void {
    const artifactType = this.artifactTypes.find(at => at.id === this.action.actionSettings.artifactTypeId.value);
    if (!artifactType) return;

    const useValues: boolean[] = [];

    this.action.actionSettings.attributesMap.value = Object.values(artifactType.attributes).reduce((attributesMap, attr) => {
      const attributeValue = this.action.actionSettings.attributesMap.value[attr.id];
      const isAttributeValueSet = attributeValue !== undefined;
      useValues.push(isAttributeValueSet);

      return {
        ...attributesMap,
        [attr.id]: isAttributeValueSet ? attributeValue : this.attributes.listMap[attr.id].multipleValues ? [] : null,
      };
    }, {});

    this.useValues = useValues;
  }

  private setSelectedArtifactType(): void {
    this.selectedArtifactType = this.artifactTypes.find(artifactType => artifactType.id === this.action.actionSettings.artifactTypeId.value) || null;
    if (this.selectedArtifactType?.format !== ArtifactTypeFormatEnum.file) {
      this.action.actionSettings.filePath.value = '';
    }
  }

  private setAllUseValuesToTrue(): void {
    this.useValues = Object.values(this.action.actionSettings.attributesMap.value || {}).map(() => true);
  }
}
