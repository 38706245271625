import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Environment } from '@environments/environment';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DATDateCalendarFormat, DATSystemDateLayoutVariant } from '@shared/types/display-at-types';
import { ClientData } from '@shared/types/local-storage.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { CalendarModule } from 'primeng/calendar';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, CalendarModule, ArtifactAttributeFormFieldLabelWithIconComponent],
  selector: 'app-artifact-attribute-form-field-date',
  templateUrl: './artifact-attribute-form-field-date.component.html',
  styleUrls: ['./artifact-attribute-form-field-date.component.scss'],
})
export class ArtifactAttributeFormFieldDateComponent implements OnInit {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;

  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() onChange: () => Promise<void>;
  @Input() onBlur: () => void;

  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  firstDayOfWeek: number;
  labelBehaviourEnum = LabelBehaviourEnum;

  constructor(private readonly cache: NewCacheService) {}

  get showTime(): boolean {
    return this.dataType.isDateTime;
  }

  ngOnInit(): void {
    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;

    const displayVariant = this.formatSettings.value.displayMetadata?.selectedVariantCode;

    if (displayVariant) {
      this.dateFormat = DATDateCalendarFormat[displayVariant as keyof typeof DATDateCalendarFormat] || Environment.calendarConfig.clientDateFormat;
    }
  }
}
