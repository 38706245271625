<div class="artifact-widget">
  <div *ngIf="!isLayoutMode" class="artifact-widget_settings settings">
    <app-artifact-widget-type-picker [m]="m" [onArtifactTypeChange]="onArtifactTypeChange.bind(this)"></app-artifact-widget-type-picker>

    <div
      *ngIf="m?.options && m?.settings && (m.settings.widgetType() | shouldShowArtifactWidgetSettings : m.selected.linkType?.value)"
      class="container settings_form"
      id="settings_form"
    >
      <app-artifact-widget-item-picker
        *ngIf="m?.selected.artifactType || (m.settings.widgetType() | isLoginWidgetType) || (m.settings.widgetType() | isRecoverPasswordWidgetType)"
        [model]="m"
        [onItemSelect]="onItemSelectCb.bind(this)" [queryParams]="urlParams">
      </app-artifact-widget-item-picker>

      <p-accordion
        *ngIf="m.selected.artifactType || (m.settings.widgetType() | isLoginWidgetType) || (m.settings.widgetType() | isRecoverPasswordWidgetType)">
        <p-accordionTab [(selected)]="isWidgetSettingsTabSelected" [header]="'Widget settings' | translate">
          <app-artifact-widget-settings
            [artifactType]="m.selected.artifactType"
            [model]="m"
            [saveButtonOptions]="m.options.saveButton"
            [saveAndNotifyButtonOptions]="m.options.saveAndNotifyButton"
            [widgetSettings]="m.settings"
            (onChangeUrlKey)="setFolderPath()"
          >
          </app-artifact-widget-settings>
        </p-accordionTab>

        <p-accordionTab [(selected)]="isFormItemSettingsTabSelected" [header]="'Form item settings' | translate">
          <app-artifact-widget-form-item-settings [model]="m" [hash]="widget.id"></app-artifact-widget-form-item-settings>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <div #formDiv *ngIf="isLayoutMode" [ngStyle]="m?.settings?.styles">
    @let buttonContainers = m?.settings.buttonContainers;

    @if (buttonContainers?.[buttonPosition.top]) {
      <ng-template [ngTemplateOutlet]="menuButtonContainer" [ngTemplateOutletContext]="{ position: buttonPosition.top, buttonContainers }"></ng-template>
    }

    <div class="widget-form-container">
      @if (buttonContainers?.[buttonPosition.left]) {
        <ng-template [ngTemplateOutlet]="menuButtonContainer" [ngTemplateOutletContext]="{ position: buttonPosition.left, buttonContainers }"></ng-template>
      }

      <div class="widget-form">
        <app-artifact-widget-form
          #form
          *ngIf="m?.form?.length && !m?.isFirstLoad && !isLoadingData"
          [applicationId]="applicationId"
          [changeFormFocus]="changeFormFocus.bind(this)"
          [hash]="widget.id"
          [model]="this.m"
          [onLinkDialogOpen]="onLinkDialogOpen.bind(this)"
          [ids]="ids"
          [resetConfigurableSettings]="resetConfigurableSettingsToDefault.bind(this)">
        </app-artifact-widget-form>
      </div>
      <div #spinner [ngClass]="{ hidden: !isLoadingData }" class="spinner loader-custom">
        <div class="loader-custom__wrapper">
          <span class="pi pi-spin pi-spinner loader-custom__wheel"></span>
        </div>
      </div>

      @if (buttonContainers?.[buttonPosition.right]) {
        <ng-template [ngTemplateOutlet]="menuButtonContainer" [ngTemplateOutletContext]="{ position: buttonPosition.right, buttonContainers }"></ng-template>
      }
    </div>

    @if (buttonContainers?.[buttonPosition.bottom]) {
      <ng-template [ngTemplateOutlet]="menuButtonContainer" [ngTemplateOutletContext]="{ position: buttonPosition.bottom, buttonContainers }"></ng-template>
    }
  </div>

  <app-link-popup #linkDialog [applicationId]="ids.applicationId" [dtoMap]="m?.linkingPopupDtoMap"></app-link-popup>
</div>

<p-sidebar
  [dismissible]="true"
  [modal]="true"
  [position]="sidebarService.getPositionSettings(m.advancedModeMenuWidget?.id).position"
  [fullScreen]="sidebarService.getPositionSettings(m.advancedModeMenuWidget?.id).fullScreen"
  [style]="!sidebarService.getPositionSettings(m.advancedModeMenuWidget?.id).fullScreen ? { width: '40em' } : {}"
  [visible]="!!m.advancedModeMenuWidget"
  appendTo="body"
  (onHide)="onButtonContainerSidebarClose()"
>
  <ng-template pTemplate="header">
    <app-sidebar-header [name]="'Buttons container'" [widgetId]="m.advancedModeMenuWidget?.id"></app-sidebar-header>
  </ng-template>

  <div class="layout-wrapper dynamic-float-label">
    <app-widget-container *ngIf="m.advancedModeMenuWidget" [applicationId]="applicationId" [widget]="m.advancedModeMenuWidget"
                          appWidgetContainerStopPropagation>
    </app-widget-container>
  </div>
</p-sidebar>

<ng-template #menuButtonContainer let-position="position" let-buttonContainers="buttonContainers">
  <div class="toolbar-container" [class.toolbar-container]="!isPreviewMode">
    <div class="toolbar">
      <ng-container>
        <i (click)="openButtonContainerAdvancedMode(position)" class="bi bi-tools toolbar__icon" pRipple pTooltip="{{ 'Advanced Mode' | translate }}"
           tooltipPosition="top">
        </i>
        <i (click)="removeButtonContainer(position)" class="bi bi-trash toolbar__icon" pRipple pTooltip="{{ 'Delete' | translate }}"
           tooltipPosition="top">
        </i>
      </ng-container>
    </div>
    <app-widget-container
      [applicationId]="applicationId"
      [isPreviewMode]="isPreviewMode"
      [widget]="buttonContainers?.[position]"
      [isLayoutMode]="true"
    ></app-widget-container>
    @if (!isPreviewMode && isContainerEmpty(position)) {
      <div class="button-container-placeholder"></div>
    }
  </div>
</ng-template>
