<div class="row mt-1">
  <div class="col-4 text-bold">
    {{ 'For each path' | translate }}
  </div>
  <div class="col-8">
    <input
      id="forEachPathInput"
      [(ngModel)]="action.actionSettings[RuleKeys.forEachPath].value"
      pInputText
      type="text"
      placeholder="Enter for-each-path"
      class="w-100"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Artifact type' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      [(ngModel)]="action.actionSettings[RuleKeys.artifactTypeId].isDynamic"
      id="isArtifactTypeDynamic"
      binary="true"
      class="mr-2 pt-2"
      (ngModelChange)="onIsArtifactTypeDynamicChange()"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      *ngIf="action.actionSettings[RuleKeys.artifactTypeId].isDynamic"
      id="artifactTypeInput"
      [(ngModel)]="action.actionSettings[RuleKeys.artifactTypeId].value"
      pInputText
      type="text"
      placeholder="Enter dynamic artifact-type"
      class="w-100"
    />
    <p-dropdown
      *ngIf="!action.actionSettings[RuleKeys.artifactTypeId].isDynamic"
      [(ngModel)]="action.actionSettings[RuleKeys.artifactTypeId].value"
      [options]="artifactTypes | transformArrayToSelectOptions: 'name' : 'id'"
      [filter]="true"
      id="artifactTypeSelect"
      appendTo="body"
      (onChange)="onArtifactTypeChange($event.value)"
    >
    </p-dropdown>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Attributes' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      [(ngModel)]="action.actionSettings[RuleKeys.attributesMap].isDynamic"
      id="isAttributesMapDynamic"
      binary="true"
      class="mr-2 pt-2"
      (onChange)="onIsAttributeMapDynamicChange()"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div *ngFor="let attribute of action.actionSettings[RuleKeys.attributesMap].value | keyvalue; let index = index; trackBy: attributesTrackByFn">
      <div class="row">
        <div class="col-1">
          <p-checkbox
            [(ngModel)]="useValues[index]"
            [id]="'useValue' + index"
            binary="true"
            class="mr-2 pt-0"
            (ngModelChange)="onUseValueChange(attribute.key, attribute.value, $event)"
          ></p-checkbox>
        </div>
        <div class="col-4" [ngClass]="{ 'not-in-use': !useValues[index] }">
          {{ attributes.listMap[attribute.key]?.name }}
        </div>
        <div class="col-7">
          <div *ngIf="attributes.listMap[attribute.key]?.multipleValues && !action.actionSettings[RuleKeys.attributesMap].isDynamic; else singleInput">
            <div *ngFor="let value of attribute.value; let attrIndex = index; trackBy: attrValuesToTrackByFn" class="mb-1">
              <ng-container *ngIf="useValues[index]">
                <input
                  [id]="'attrValue' + attrIndex"
                  [(ngModel)]="attribute.value[attrIndex]"
                  pInputText
                  type="text"
                  class="w-100"
                  [placeholder]="'Enter value ' + (attrIndex + 1)"
                />
                <button
                  class="p-button p-button-text"
                  pButton
                  type="button"
                  icon="pi pi-plus"
                  [ngClass]="{ hidden: attrIndex !== attribute.value.length - 1 }"
                  (click)="onAddAttributeValue(attribute.key)"
                ></button>
                <button
                  *ngIf="attribute.value.length > 1"
                  class="p-button p-button-text p-button-danger"
                  pButton
                  type="button"
                  icon="pi pi-minus"
                  (click)="onRemoveAttributeValue(attribute.key, attrIndex)"
                ></button>
              </ng-container>
            </div>
          </div>

          <ng-template #singleInput>
            <input
              *ngIf="useValues[index]"
              [(ngModel)]="action.actionSettings[RuleKeys.attributesMap].value![attribute.key]"
              [placeholder]="'Enter value' | translate"
              pInputText
              class="w-100"
            />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Store results path' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.storeResultsPath].value"
      [placeholder]="'Enter store results path' | translate"
      pInputText
      class="w-100"
      id="scopeInput"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Parent folder id' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.parentFolderId].isDynamic" id="isParentFolderDynamic" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings[RuleKeys.parentFolderId].value"
      [placeholder]="'Enter parent folder id' | translate"
      pInputText
      class="w-100"
      id="parentFolderIdInput"
    />
  </div>
</div>

<div *ngIf="(selectedArtifactType?.format || ArtifactTypeFormatEnum.file) === ArtifactTypeFormatEnum.file" class="row">
  <div class="col-2 text-bold">
    {{ 'File path' | translate }}
  </div>
  <div class="col-2">
    <p-triStateCheckbox [ngModel]="false" id="isFolderPathDynamic" [disabled]="true"></p-triStateCheckbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input id="filePathInput" [(ngModel)]="action.actionSettings[RuleKeys.filePath].value" pInputText type="text" placeholder="Enter file-path" class="w-100" />
  </div>
</div>
