import { isEqual } from 'lodash';

import { SessionFlowCreateRequestDto, SessionFlowResponseDto, SessionFlowUpdateRequestDto, TenantAdminSessionFlowResponseDto } from '@api/models';
import { RecordDto } from '@api/models/record-dto';
import { IndexSessionFlowTypeData } from '@shared/types/session-flow.type-data.types';

export enum SessionType {
  internal = 'internal',
  openid = 'openid',
  google = 'google',
  microsoft = 'microsoft',
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sessionTypeCheck: [SessionFlowResponseDto['type']] extends [`${SessionType}`]
  ? `${SessionType}` extends SessionFlowResponseDto['type']
    ? true
    : false
  : false = true;

export enum SessionFlowScopes {
  user = 'user',
  smtp = 'smtp',
  imap = 'imap',
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sessionFlowScopesCheck: [SessionFlowResponseDto['scopes'][number]] extends [`${SessionFlowScopes}`]
  ? `${SessionFlowScopes}` extends SessionFlowResponseDto['scopes'][number]
    ? true
    : false
  : false = true;

export class SessionFlow<K extends SessionType = SessionType> implements TenantAdminSessionFlowResponseDto {
  id: string;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null;
  active: boolean = false;
  domainSessionFlow: boolean = false;
  typeData: InstanceType<(typeof IndexSessionFlowTypeData)[K]>;
  type: K;

  constructor(sessionFlow?: Partial<SessionFlow>, sessionFlowType?: K) {
    if (sessionFlowType) {
      this.type = sessionFlowType;
      this.typeData = new IndexSessionFlowTypeData[sessionFlowType]() as typeof this.typeData;
    }
    sessionFlow && Object.assign(this, sessionFlow);
  }

  static fromType(sessionFlowType: SessionType): SessionFlow {
    return new SessionFlow(undefined, sessionFlowType);
  }

  static fromServer(sessionFlow: SessionFlowResponseDto | TenantAdminSessionFlowResponseDto): SessionFlow {
    if (!(sessionFlow as TenantAdminSessionFlowResponseDto).typeData)
      throw new Error(
        JSON.stringify({ message: 'Not expected', description: 'Only current tenant session-flows are allowed to be transformed to SessionFlow' }),
      );
    const {
      type,
      typeData: { scopes, ...restTypeData },
      ...rest
    } = sessionFlow as TenantAdminSessionFlowResponseDto;
    return new SessionFlow({
      type: type as SessionType,
      typeData: new IndexSessionFlowTypeData[type]({
        scopes: scopes as SessionFlowScopes[],
        ...restTypeData,
      }),
      ...rest,
    });
  }

  static toServerCreate(sessionFlow: SessionFlow): SessionFlowCreateRequestDto {
    if (sessionFlow.id) throw new Error(JSON.stringify({ message: 'Not expected', description: `Session flow with ID can't be created` }));
    return {
      active: sessionFlow.active,
      domainSessionFlow: sessionFlow.domainSessionFlow,
      type: sessionFlow.type,
      typeData: sessionFlow.typeData,
    } as SessionFlowCreateRequestDto;
  }

  static toServerUpdate(sessionFlow: SessionFlow, originalSessionFlow: SessionFlow): SessionFlowUpdateRequestDto {
    return {
      id: sessionFlow.id,
      ...(!isEqual(sessionFlow.active, originalSessionFlow.active) ? { active: sessionFlow.active } : {}),
      ...(!isEqual(sessionFlow.domainSessionFlow, originalSessionFlow.domainSessionFlow) ? { domainSessionFlow: sessionFlow.domainSessionFlow } : {}),
      ...(!isEqual(sessionFlow.typeData, originalSessionFlow.typeData) ? { typeData: sessionFlow.typeData } : {}),
    } as SessionFlowUpdateRequestDto;
  }
}
