import { Background } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { BackgroundAttachmentEnum, BackgroundPositionEnum, BackgroundRepeatEnum, BackgroundSizeEnum } from '@shared/components/image-pointer/types/image-pointer.types';
import { BackgroundTypeEnum } from '@widgets/shared/types/style.types';

export class PageStyles implements Background {
  backgroundType: BackgroundTypeEnum;
  backgroundColor: string;
  backgroundImage?: string;
  background: string;
  backgroundSize: string;
  backgroundPosition: string;
  backgroundRepeat: string;
  backgroundAttachment: string;
  backgroundOrigin: string;
  backgroundClip: string;
  backgroundBlendMode: string;
  backgroundOpacity: string;

  constructor(styles?: Partial<PageStyles>) {
    if (styles) {
      Object.assign(this, styles);
    }

    if (!this.backgroundColor) this.backgroundColor = 'initial';
    if (!this.backgroundRepeat) this.backgroundRepeat = BackgroundRepeatEnum.noRepeat;
    if (!this.backgroundSize) this.backgroundSize = BackgroundSizeEnum.auto;
    if (!this.backgroundPosition) this.backgroundPosition = BackgroundPositionEnum.centerCenter;
    if (!this.backgroundAttachment) this.backgroundAttachment = BackgroundAttachmentEnum.scroll;
    if (!this.backgroundOpacity) this.backgroundOpacity = '0';
  }

  static applyToBody(pageStyles: PageStyles): void {

    if (pageStyles) {
      const body = document.body;

      body.style.background = pageStyles.background || '';
      const alphaNum = parseFloat(pageStyles.backgroundOpacity);
      if (alphaNum >= 0 || alphaNum <= 1) {
        body.style.background = `linear-gradient(rgba(0, 0, 0, ${pageStyles.backgroundOpacity}), rgba(0, 0, 0, ${pageStyles.backgroundOpacity})), ${pageStyles.background}`;
      }

      body.style.backgroundColor = pageStyles.backgroundColor || '';
      body.style.backgroundSize = pageStyles.backgroundSize || '';
      body.style.backgroundPosition = pageStyles.backgroundPosition || '';
      body.style.backgroundRepeat = pageStyles.backgroundRepeat || '';
      body.style.backgroundAttachment = pageStyles.backgroundAttachment || '';
      body.style.backgroundOrigin = pageStyles.backgroundOrigin || '';
      body.style.backgroundClip = pageStyles.backgroundClip || '';
      body.style.backgroundBlendMode = pageStyles.backgroundBlendMode || '';
    }
  }
}
