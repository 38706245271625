import { Injectable } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GlobalConstants } from '@shared/constants/global.constants';
import { UserProfilePictureService } from '@shared/services/user-profile/user-profile-picture.service';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';

import { Observable, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserProfileAndPictureData, UserProfileData } from '../../types/user-profile.types';

@Injectable({ providedIn: 'root' })
export class UserProfileService {
  userProfileDataMap: Record<string, Observable<UserProfileData>> = {};
  userProfilePictureMap: Record<string, Observable<UserProfileAndPictureData>> = {};

  constructor(
    private readonly userProfilePictureService: UserProfilePictureService,
    private readonly cache: NewCacheService,
  ) {}

  getProfilesData$(userIds: string[]): Observable<UserProfileData>[] {
    return userIds.map(id => this.getProfileData$(id));
  }

  getProfilesAndPicturesData$(userIds: string[]): Observable<UserProfileAndPictureData>[] {
    return userIds.map(id => this.getProfileAndPictureData$(id));
  }

  private getProfileData$(userId: string): Observable<UserProfileData> {
    if (!this.userProfileDataMap[userId]) {
      this.userProfileDataMap[userId] = this.cache.data.users.get$(userId).pipe(
        map(artifact => this.toUserProfileData(artifact as ArtifactLinkResponseDto)),
        shareReplay(),
      );
    }

    return this.userProfileDataMap[userId];
  }

  private getProfileAndPictureData$(userId: string): Observable<UserProfileAndPictureData> {
    if (!this.userProfilePictureMap[userId]) {
      this.userProfilePictureMap[userId] = this.getProfileData$(userId).pipe(
        switchMap(userProfileData => {
          return this.userProfilePictureService.getUserImage$(userProfileData.dto).pipe(map(userPicture => ({ ...userProfileData, userPicture })));
        }),
        shareReplay(),
      );
    }

    return this.userProfilePictureMap[userId];
  }

  private toUserProfileData(dto: ArtifactResponseDto): UserProfileData {
    return {
      dto,
      userName: dto.attributes[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)].value as string,
      userEmail: dto.attributes[GlobalConstants.getValue(GlobalConstantsEnum.emailAttributeId)].value as string,
    } as UserProfileData;
  }
}
