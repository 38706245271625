import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { TreeNode } from '@private/components/type-system-element-dropdown/tree-node';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { ArtifactTypeRestrictionsFormModel } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-restrictions-form/types/artifact-type-restrictions-form.types';
import { DirectionalLinkTypeSystemElement } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-restrictions-form/types/directional-link-type-system-element';
import { ArtifactTypeOptionsDisablingService } from '@private/pages/artifact-type-management/artifact-type/services/artifact-type-options-disabling.service';
import { LinkValidationType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { DisabledArtifactTypesByRestriction } from '@private/pages/artifact-type-management/disabled-artifact-types-by.restriction';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  DESTINATION_ARTIFACT_TYPES_KEY,
  DESTINATION_ARTIFACT_TYPES_LABEL,
  ID_KEY,
  INACCESSIBLE_APPLICATION_NAME,
  IS_LINK_REQUIRED_KEY,
  IS_LINK_REQUIRED_LABEL,
  LINK_TYPE_KEY,
  LINK_TYPE_LABEL,
  RELATION_TYPE_KEY,
  RELATION_TYPE_LABEL,
  SOURCE_ARTIFACT_TYPES_KEY,
  SOURCE_ARTIFACT_TYPES_LABEL,
} from '@shared/constants/constants';
import { CoreRxSubscriptionsComponent } from '@shared/core/components/core-rx-subscriptions.component';
import { LinkMethods } from '@shared/methods/link.methods';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { RelationTypeEnum, UngroupedArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { DbEntityCachedData } from '@shared/utils/db-entity-cached-data.subject';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable, share, startWith, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-artifact-type-restrictions-form',
  templateUrl: './artifact-type-restrictions-form.component.html',
  styleUrls: ['./artifact-type-restrictions-form.component.scss'],
})
export class ArtifactTypeRestrictionsFormComponent extends CoreRxSubscriptionsComponent implements OnChanges, OnInit {
  @Input() artifactTypeId: string;
  @Input() linkRestrictions: UngroupedArtifactTypeLinkRestriction[];
  @Input() applications: ListContainer<NewApplication>;

  artifactTypeLinkRestrictions: UngroupedArtifactTypeLinkRestriction[] = [];
  linkMethods = LinkMethods;

  m = new ArtifactTypeRestrictionsFormModel();
  loading = true;
  linkValidationType = [
    new SelectOption(LinkValidationType.none, LinkValidationType.none, false),
    new SelectOption(LinkValidationType.source, LinkValidationType.source, false),
    new SelectOption(LinkValidationType.destination, LinkValidationType.destination, false),
  ];

  disabledArtifactTypes$: Observable<DisabledArtifactTypesByRestriction>;

  private artifactTypeIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private linkRestrictionsSubject: BehaviorSubject<UngroupedArtifactTypeLinkRestriction[]> = new BehaviorSubject<UngroupedArtifactTypeLinkRestriction[]>([]);
  private restrictionChange: Subject<void> = new Subject<void>();

  constructor(
    private readonly tenantWidgetService: TenantWidgetService,
    private readonly blockUiService: BlockUiService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateUtil: TranslateUtil,
    private readonly cache: NewCacheService,
    private readonly cdr: ChangeDetectorRef,
    private readonly artifactTypeOptionsDisablingService: ArtifactTypeOptionsDisablingService,
  ) {
    super();
  }

  ngOnChanges({ artifactTypeId, linkRestrictions }: SimpleChanges): void {
    if (artifactTypeId) {
      this.artifactTypeIdSubject.next(artifactTypeId.currentValue);
    }

    if (linkRestrictions) {
      this.linkRestrictionsSubject.next(linkRestrictions.currentValue);
    }
  }

  async ngOnInit(): Promise<void> {
    await this.initOptions();
    if (!this.linkRestrictions) {
      this.linkRestrictions = [];
    } else {
      this.linkRestrictions.forEach(restriction => {
        if (restriction.linkType) {
          restriction.linkType = { ...restriction.linkType };
          restriction.isLinkRequired = new SelectOption(restriction.isLinkRequired, restriction.isLinkRequired) as any;
        }
      });
    }
    this.artifactTypeLinkRestrictions = this.linkRestrictions.filter(({ sourceArtifactTypeId, destinationArtifactTypeId }) =>
      [sourceArtifactTypeId, destinationArtifactTypeId].includes(this.artifactTypeId),
    );
    this.setDirectionalLinkTypesToRestrictions();

    this.loading = false;

    this.disabledArtifactTypes$ = combineLatest([this.artifactTypeIdSubject, this.linkRestrictionsSubject, this.restrictionChange]).pipe(
      startWith(null),
      map(() => this.artifactTypeOptionsDisablingService.getDisabledArtifactTypes(this.artifactTypeLinkRestrictions)),
      tap(() => this.cdr.detectChanges()),
      share(),
    );
  }

  onChangeLinkRequired(index: number, option: SelectOption<string, string>): void {
    const restriction = this.artifactTypeLinkRestrictions[index];
    const realRestriction = this.linkRestrictions.find(item => {
      return (
        item.linkType?.value === restriction.linkType?.value &&
        item.destinationArtifactTypeId === restriction?.destinationArtifactTypeId &&
        item.sourceArtifactTypeId == restriction?.sourceArtifactTypeId
      );
    });
    if (realRestriction?.linkType) {
      realRestriction.isLinkRequired = new SelectOption(option.value, option.value) as any;
    }
  }

  onRestrictionLinkTypeChange(index: number, restriction: UngroupedArtifactTypeLinkRestriction): void {
    this.setCurrentArtifactTypeToRestrictionAccordingToDirection(restriction);
    this.setCorrectRelationIfSourceMatchesDestination(restriction);
    this.replaceOldRestrictionWithNewOne(index, new UngroupedArtifactTypeLinkRestriction(restriction));
    this.restrictionChange.next();
  }

  onRestrictionArtifactTypeChange(index: number, restriction: UngroupedArtifactTypeLinkRestriction): void {
    this.setCorrectRelationIfSourceMatchesDestination(restriction);
    this.replaceOldRestrictionWithNewOne(index, new UngroupedArtifactTypeLinkRestriction(restriction));
    this.restrictionChange.next();
  }

  onLinkTypeModelChange(directionalLinkTypeId: `${string}${LinkDirection}`, restriction: UngroupedArtifactTypeLinkRestriction): void {
    const linkDirection = directionalLinkTypeId.includes(LinkDirection.outgoing) ? LinkDirection.outgoing : LinkDirection.incoming;
    const linkTypeId = directionalLinkTypeId.replace(linkDirection, '');
    restriction.linkType = this.m.options.linkType.find(
      ({ value, meta }: SelectOption<string, string, LinkDirection>) => value === linkTypeId && meta === linkDirection,
    )!;
  }

  onRelationShow(selectedRestriction: UngroupedArtifactTypeLinkRestriction): void {
    this.m.options.relationTypes.forEach(relationType => {
      if (relationType.value === RelationTypeEnum.oneToMany || relationType.value === RelationTypeEnum.manyToOne) {
        relationType.disabled = false;
      }
    });
    // if source and target artifact types are the same disable relationTypes 'one to many' and 'many to one'
    if (selectedRestriction.destinationArtifactTypeId === selectedRestriction.sourceArtifactTypeId) {
      this.m.options.relationTypes.forEach(relationType => {
        if (relationType.value === RelationTypeEnum.oneToMany || relationType.value === RelationTypeEnum.manyToOne) {
          relationType.disabled = true;
        }
      });
    }
  }

  onRelationChange(value: RelationTypeEnum, restriction: UngroupedArtifactTypeLinkRestriction): void {
    const realRestriction = this.linkRestrictions.find(item => {
      return (
        item.linkType?.value === restriction.linkType?.value &&
        item.destinationArtifactTypeId === restriction?.destinationArtifactTypeId &&
        item.sourceArtifactTypeId == restriction?.sourceArtifactTypeId
      );
    });
    restriction && realRestriction && Object.assign(realRestriction, LinkMethods.changeRelation(value));
  }

  convertRelationValue(singleSource: boolean, singleDestination: boolean): RelationTypeEnum {
    return LinkMethods.convertRelationValue(singleSource, singleDestination);
  }

  addRestriction(): void {
    const newRestriction = new UngroupedArtifactTypeLinkRestriction();
    this.linkRestrictions.push(newRestriction);
    this.artifactTypeLinkRestrictions.push(newRestriction);
    this.linkRestrictionsSubject.next(this.linkRestrictions);
  }

  generateRestrictionUsageMessage(countOfUsagesInLinkedArtifactWidgets: number): string {
    return +countOfUsagesInLinkedArtifactWidgets > 0 ? ` This restriction used in ${countOfUsagesInLinkedArtifactWidgets} linked artifact widgets!` : '';
  }

  async deleteRestriction(index: number): Promise<void> {
    const linkId = this.artifactTypeLinkRestrictions[index].linkType?.value;
    const sourceArtifactTypeId = this.artifactTypeLinkRestrictions[index].sourceArtifactTypeId;
    const destinationArtifactTypeId = this.artifactTypeLinkRestrictions[index].destinationArtifactTypeId;
    const linkName = this.artifactTypeLinkRestrictions[index].linkType?.label + '_' + this.artifactTypeLinkRestrictions[index].linkType?.meta;
    let countOfUsagesInWidgets = 0;
    if (linkId && sourceArtifactTypeId && destinationArtifactTypeId) {
      countOfUsagesInWidgets = await LinkMethods.checkRestrictionBeforeDelete(
        linkId,
        sourceArtifactTypeId,
        destinationArtifactTypeId,
        this.tenantWidgetService,
      );
    }
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    this.confirmationService.confirm({
      header,
      message: message + ' ' + linkName + '?' + this.generateRestrictionUsageMessage(countOfUsagesInWidgets),
      acceptLabel,
      rejectLabel,
      accept: async () => {
        this.blockUiService.blockUi();

        try {
          // deleting
          const indexOfRestrictionToDelete = this.findIndexInUnfilteredArray(index);
          if (indexOfRestrictionToDelete || indexOfRestrictionToDelete === 0) {
            this.linkRestrictions.splice(indexOfRestrictionToDelete, 1);
          }
          this.artifactTypeLinkRestrictions.splice(index, 1);
          this.linkRestrictionsSubject.next(this.linkRestrictions);
        } catch (e) {
          console.error(e);
        } finally {
          this.blockUiService.unblockUi();
        }
      },
    });
  }

  getLinkTypeLabel(restriction: UngroupedArtifactTypeLinkRestriction, node: TreeNode): string {
    if (restriction.isBidirectional) {
      const application = this.applications.listMap[node.data.applicationId];
      const applicationName = application ? `(${application.name})` : INACCESSIBLE_APPLICATION_NAME;
      const linkTypeName = node.data.linkType.name;

      return `${linkTypeName} ${applicationName}`;
    }

    return node.longLabel!;
  }

  private setCurrentArtifactTypeToRestrictionAccordingToDirection(restriction: UngroupedArtifactTypeLinkRestriction): void {
    restriction.sourceArtifactTypeId = restriction.linkType?.meta === LinkDirection.outgoing ? this.artifactTypeId : '';
    restriction.destinationArtifactTypeId = restriction.linkType?.meta === LinkDirection.outgoing ? '' : this.artifactTypeId;
  }

  private replaceOldRestrictionWithNewOne(index: number, newRestriction: UngroupedArtifactTypeLinkRestriction): void {
    const indexOfRestrictionToDelete = this.findIndexInUnfilteredArray(index);
    if (indexOfRestrictionToDelete != null && indexOfRestrictionToDelete !== -1) {
      this.linkRestrictions[indexOfRestrictionToDelete] = newRestriction;
    }
    this.artifactTypeLinkRestrictions[index] = newRestriction;
  }

  private setCorrectRelationIfSourceMatchesDestination(restriction: UngroupedArtifactTypeLinkRestriction): void {
    if (restriction.sourceArtifactTypeId === restriction.destinationArtifactTypeId) {
      restriction.singleSource = false;
      restriction.singleDestination = false;
    }
  }

  private async initOptions(): Promise<void> {
    this.m.options.column = [
      new TableColumn(IS_LINK_REQUIRED_LABEL, IS_LINK_REQUIRED_KEY),
      new TableColumn(LINK_TYPE_LABEL, LINK_TYPE_KEY),
      new TableColumn(SOURCE_ARTIFACT_TYPES_LABEL, SOURCE_ARTIFACT_TYPES_KEY),
      new TableColumn(RELATION_TYPE_LABEL, RELATION_TYPE_KEY),
      new TableColumn(DESTINATION_ARTIFACT_TYPES_LABEL, DESTINATION_ARTIFACT_TYPES_KEY),
    ];
    const { linkTypes, artifactTypes } = this.cache.data;

    this.registerSubscriptions(await Promise.all([this.getLinkTypesSubscription(linkTypes), this.getArtifactTypesSubscription(artifactTypes)]));

    (this.cache.data.linkTypes.value as LinkTypeResponseDto[])!.forEach(linkType => {
      setTimeout(() => {
        this.m.options.linkType.push(new SelectOption(linkType.outgoingName, linkType.id, LinkDirection.outgoing));
        this.m.options.linkType.push(new SelectOption(linkType.incomingName, linkType.id, LinkDirection.incoming));
        this.m.generalLinkTypeLabelMap[linkType.id] = linkType.name;
      });
    });

    (this.cache.data.artifactTypes.value as ArtifactTypeResponseDto[])?.map(at => new SelectOption<string, string>(at.name, at.id));
  }

  private getLinkTypesSubscription(linkTypes: DbEntityCachedData<LinkTypeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = linkTypes.subscribe(async linkTypes => {
        this.m.options.linkTypes.setList(
          linkTypes!
            .map(dto => new LinkType(dto as LinkTypeResponseDto))
            .reduce((acc: DirectionalLinkTypeSystemElement[], curr: LinkType) => {
              return [
                ...acc,
                new DirectionalLinkTypeSystemElement(LinkDirection.outgoing, curr),
                new DirectionalLinkTypeSystemElement(LinkDirection.incoming, curr),
              ];
            }, []),
          ID_KEY,
        );
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private getArtifactTypesSubscription(artifactTypes: DbEntityCachedData<ArtifactTypeResponseDto>): Promise<Subscription> {
    return new Promise<Subscription>(resolve => {
      const subscription = artifactTypes.subscribe(artifactTypes => {
        this.m.options.artifactTypes = artifactTypes!.map(dto => new NewArtifactType(dto as ArtifactTypeResponseDto));
        setTimeout(() => resolve(subscription));
      });
    });
  }

  private setDirectionalLinkTypesToRestrictions(): void {
    this.artifactTypeLinkRestrictions.forEach((restriction: UngroupedArtifactTypeLinkRestriction) => {
      if (restriction.linkType) {
        restriction.directionalLinkTypeId = `${restriction.linkType.value}${restriction.linkType.meta}`;
      }
    });
  }

  private findIndexInUnfilteredArray(index: number): number | null {
    const restrictionToFind = this.artifactTypeLinkRestrictions[index];
    let indexOfRestrictionToDelete: number | null = null;
    this.linkRestrictions.find((restriction, i) => {
      if (
        restriction.linkType?.value === restrictionToFind.linkType?.value &&
        restriction.linkType?.meta === restrictionToFind.linkType?.meta &&
        restriction.sourceArtifactTypeId === restrictionToFind.sourceArtifactTypeId &&
        restriction.destinationArtifactTypeId === restrictionToFind.destinationArtifactTypeId
      ) {
        indexOfRestrictionToDelete = i;
        return true;
      }
      return false;
    });
    return indexOfRestrictionToDelete;
  }
}
