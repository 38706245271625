export class UrlMethods {
  static getPageIdOrAliasFromUrl(url: string): string {
    let path = url.replace(/^\/page\/|\/admin\/page-builder|(?:\?.*|#.*)$/g, '');
    if (path.charAt(0) === '/') path = path.substring(1);
    return path;
  }

  static windowOpen(path: string, queryString: string = '', target: string = '_self'): void {
    if (path.charAt(0) !== '/') path = '/' + path;
    if (queryString?.length && queryString.charAt(0) !== '?') queryString = '?' + queryString;

    const url = `${window.location.origin}${path}${queryString}`;
    window.open(url, target);
  }
}
