import { Component, Injector, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArtifactTypeAttributeRequestDto } from '@api/models/artifact-type-attribute-request-dto';
import { ArtifactTypeCreateRequestDto } from '@api/models/artifact-type-create-request-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { ArtifactTypeUpdateRequestDto } from '@api/models/artifact-type-update-request-dto';
import { TenantLinkTypeService } from '@api/services/tenant-link-type.service';
import { TranslateService } from '@ngx-translate/core';
import { PageBuilderHelper } from '@private/helpers/page-builder.helper';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { ArtifactTypeAvrFormComponent } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/artifact-type-avr-form.component';
import { ArtifactTypeService } from '@private/pages/artifact-type-management/artifact-type/services/artifact-type.service';
import { ArtifactTypeModel } from '@private/pages/artifact-type-management/artifact-type/types/artifact.type.types';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { DefaultPageParams } from '@private/types/page-builder-helper.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { Constants, ID_KEY, IS_DELETED_LABEL, NAME_KEY } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { FileHelper } from '@shared/helpers/file.helper';
import { GetAttributeFromClientAttribute, GetDataTypeFromClientAttribute, GetDataTypeFromDataTypeId } from '@shared/methods/artifact.methods';
import { AttributeValueToServer } from '@shared/methods/client-attribute.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { ArtifactTypeFormatEnum, NewArtifactType, NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { LinkRestriction, UngroupedArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { APPLICATION_ID, HASH, IS_LAYOUT_MODE, IS_SHOW_PICKER, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-artifact-type',
  templateUrl: './artifact-type.component.html',
  styleUrls: ['./artifact-type.component.scss'],
  providers: [ArtifactTypeService],
})
export class ArtifactTypeComponent extends CoreComponent<ArtifactTypeService, ArtifactTypeModel> {
  @ViewChild('avrForm') avrForm: ArtifactTypeAvrFormComponent;
  IS_DELETED_LABEL = IS_DELETED_LABEL;
  ArtifactTypeFormat = ArtifactTypeFormatEnum;
  NAME_VALUE = NAME_KEY;
  ID_VALUE = ID_KEY;

  constructor(
    route: ActivatedRoute,
    router: Router,
    service: ArtifactTypeService,
    translate: TranslateService,
    announcement: AnnouncementService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly blockUiService: BlockUiService,
    private readonly pageBuilderHelper: PageBuilderHelper,
    private readonly fileHelper: FileHelper,
    private readonly cache: NewCacheService,
    private readonly injector: Injector,
    private readonly tenantLinkTypeService: TenantLinkTypeService,
  ) {
    super(route, router, translate, new ArtifactTypeModel(), service, announcement);
  }

  onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Artifact Type' }]);
    this.m.injectorForWidgetComponent = this.getInjectorForWidgetComponent();
  }

  async mapAttributeChanges(clientAttributes: NewArtifactTypeClientAttribute[]): Promise<Record<string, ArtifactTypeAttributeRequestDto>> {
    return clientAttributes.reduce((changes: any, attribute: NewArtifactTypeClientAttribute) => {
      const fullAttribute = GetAttributeFromClientAttribute(attribute, this.m.options.attributes.listMap);
      if (!fullAttribute) {
        this.announcement.error('Attribute must be chosen');
        return;
      }
      changes[attribute.id] = {
        initialValue: AttributeValueToServer(
          GetDataTypeFromDataTypeId(fullAttribute.dataTypeId, this.m.options.dataTypes.listMap),
          fullAttribute,
          attribute.initialValue,
        ),
        isMandatory: attribute.isMandatory,
      };
      return changes;
    }, {}) as any as Record<string, ArtifactTypeAttributeRequestDto>;
  }

  async save(): Promise<void> {
    this.m.inProgress = true;
    this.blockUiService.blockUi();
    let artifactType: ArtifactTypeResponseDto | null = null;

    try {
      artifactType = await this.saveArtifactTypeAndFiles();

      if (artifactType) {
        this.cache.data.artifactTypes.setItem(artifactType);
        await this.announcement.success('Artifact type saved successfully');
      }
    } catch (e) {
      await this.announcement.error('Failed to save artifact type');
    }

    try {
      if (artifactType) {
        if (!this.m.artifactType.id) await this.createAndAssignDefaults(artifactType);
        await this.s.cancel();
      }
    } catch (e) {
      await this.announcement.error('Failed to create default page and assign default widget templates');
    } finally {
      this.blockUiService.unblockUi();
      this.m.inProgress = false;
    }
  }

  getDeletedLinkRestrictions(): UngroupedArtifactTypeLinkRestriction[] {
    return this.m.originalHelpingAttributes.linkRestrictions.filter(
      item =>
        !this.m.editableHelpingAttributes.linkRestrictions.find(
          i =>
            i.linkType &&
            item.linkType &&
            i.linkType.value === item.linkType.value &&
            i.linkType.meta === item.linkType.meta &&
            i.notifySource === item.notifySource &&
            i.notifyDestination === item.notifyDestination &&
            i.sourceArtifactTypeId === item.sourceArtifactTypeId &&
            i.destinationArtifactTypeId === item.destinationArtifactTypeId,
        ),
    );
  }

  showPopupFolder(): void {
    this.m.isShowFolderPicker = true;
  }

  onChangeIcon(val: string | null): void {
    this.m.artifactType.icon = val || '';
  }

  onArtifactTypeFormatChange(format: ArtifactTypeFormatEnum): void {
    if (format === ArtifactTypeFormatEnum.module) {
      this.s.autoInitPreferredModuleTypes();
    } else {
      this.m.artifactType.preferredArtifactTypeIds = [];
    }
  }

  private getInjectorForWidgetComponent(): Injector {
    const appId = this.applicationSwitcherService.selectedApplication?.id || '';
    return Injector.create({
      providers: [
        { provide: APPLICATION_ID, useValue: appId },
        { provide: IS_LAYOUT_MODE, useValue: true },
        { provide: WIDGET, useValue: { value: { model: { options: null } } } },
        { provide: IS_SHOW_PICKER, useValue: true },
        { provide: LABEL, useValue: null },
        { provide: HASH, useValue: null },
      ],
      parent: this.injector,
    });
  }

  private async saveArtifactTypeAndFiles(): Promise<ArtifactTypeResponseDto> {
    const { editableHelpingAttributes } = this.m;
    const { clientAttributes } = editableHelpingAttributes;
    const { attributes, dataTypes, applications } = this.m.options;

    //TODO remake apps.listMap
    if (this.applicationSwitcherService.selectedApplication)
      await this.fileHelper.createNewFiles(
        clientAttributes.filter(attr => GetDataTypeFromClientAttribute(attr, attributes.listMap, dataTypes.listMap)?.baseDataType === BaseDataType.file),
        applications.listMap[this.applicationSwitcherService.selectedApplication?.id],
        attributes.listMap,
      );

    this.m.artifactType.avrMapper = this.avrForm.toServer();
    this.m.artifactType.name = this.m.artifactType.name.trim();

    if (this.m.artifactType.id) {
      return await this.updateArtifactType();
    } else {
      return await this.createArtifactType(clientAttributes);
    }
  }

  private async createArtifactType(clientAttributes: NewArtifactTypeClientAttribute[]): Promise<ArtifactTypeResponseDto> {
    const body: ArtifactTypeCreateRequestDto = {
      ...this.m.artifactType.toCreateDto({
        applicationId: this.m.artifactType.applicationId || this.applicationSwitcherService.selectedApplication?.id || '',
        attributes: await this.mapAttributeChanges(clientAttributes),
      }),
    };

    if (!body.primaryAttributes.length) {
      body.primaryAttributes[0] = Constants.primaryAttributesDefaultId;
    }

    return await lastValueFrom(this.s.tenantArtifactTypeService.artifactTypeControllerCreate({ body }));
  }

  private async updateArtifactType(): Promise<ArtifactTypeResponseDto> {
    const { id, defaultWidgets, delimiter, isLoggingDisabled } = this.m.artifactType;
    const changed = this.s.getChangedDataFromOriginalObject<NewArtifactType>(this.m.artifactType);
    const changedHelpingAttributes = this.m.getChangedHelpingAttributes();
    const updateDto: Partial<ArtifactTypeUpdateRequestDto> = {
      ...changed,
      attributes: await this.mapAttributeChanges(this.m.editableHelpingAttributes.clientAttributes),
    };
    const deletedLinkRestrictions = this.getDeletedLinkRestrictions();
    let linksSaved = false;
    if (changedHelpingAttributes.linkRestrictions) {
      const changedRestrictions = Object.getOwnPropertyNames(changedHelpingAttributes.linkRestrictions);
      if (changedRestrictions.length) {
        const changedRestrictionsArray: UngroupedArtifactTypeLinkRestriction[] = [];
        changedRestrictions.forEach(key => {
          changedRestrictionsArray.push(changedHelpingAttributes.linkRestrictions[key]);
        });
        await this.saveLinkRestrictions(changedRestrictionsArray, deletedLinkRestrictions);
        linksSaved = true;
      }
    }
    if (!linksSaved && deletedLinkRestrictions.length) {
      await this.saveLinkRestrictions([], deletedLinkRestrictions);
    }
    return await lastValueFrom(
      this.s.tenantArtifactTypeService.artifactTypeControllerUpdate({
        body: {
          id,
          ...updateDto,
          defaultWidgets,
          delimiter,
          isLoggingDisabled,
        },
      }),
    );
  }

  private async saveLinkRestrictions(
    changedLinkRestrictions: UngroupedArtifactTypeLinkRestriction[],
    deletedLinkRestrictions: UngroupedArtifactTypeLinkRestriction[],
  ): Promise<void> {
    const editableLinkRestrictions = this.m.editableHelpingAttributes.linkRestrictions;
    const linkTypeForChangeIds: string[] = [];
    if (deletedLinkRestrictions.length) {
      const linkTypesWithoutRestrictions = deletedLinkRestrictions.filter(deletedRestriction => {
        const isLinkTypeInEditable = editableLinkRestrictions.find(restriction => restriction.linkType?.value === deletedRestriction.linkType?.value);
        if (isLinkTypeInEditable && deletedRestriction.linkType) {
          linkTypeForChangeIds.push(deletedRestriction.linkType.value);
        }
        return !isLinkTypeInEditable;
      });
      const linkTypeWithoutRestrictionsIds: string[] = [];
      linkTypesWithoutRestrictions.map(restriction => {
        if (restriction.linkType && !linkTypeWithoutRestrictionsIds.find(id => id === restriction.linkType?.value)) {
          linkTypeWithoutRestrictionsIds.push(restriction.linkType.value);
        }
      });
      for await (const linkTypeId of linkTypeWithoutRestrictionsIds) {
        await lastValueFrom(this.tenantLinkTypeService.linkTypeControllerUpdate({ body: { id: linkTypeId, restrictions: [] } })).then(dto =>
          this.cache.data.linkTypes.setItem(dto),
        );
      }
    }
    const filteredLinkRestrictions = editableLinkRestrictions.filter(restriction => {
      const isInChanged = changedLinkRestrictions.find(changedRestriction => changedRestriction?.linkType?.value === restriction.linkType?.value);
      const isInDeleted = linkTypeForChangeIds.find(id => id === restriction.linkType?.value);
      return isInChanged || isInDeleted;
    });
    const restrictions = this.getProcessedLinkRestrictions(filteredLinkRestrictions);
    const linkTypeIds = filteredLinkRestrictions.map(restriction => restriction.linkType && restriction.linkType.value) as string[];
    const filteredLinkTypeIds: string[] = [];
    linkTypeIds.forEach(id => {
      if (!filteredLinkTypeIds.find(uniqueId => uniqueId === id)) {
        filteredLinkTypeIds.push(id);
      }
    });
    for await (const linkTypeId of filteredLinkTypeIds) {
      const linkType = await this.cache.data.linkTypes.getAsync(linkTypeId);

      linkType.restrictions?.forEach(dto => {
        if (
          dto.sourceArtifactTypeId !== this.m.artifactType.id &&
          dto.destinationArtifactTypeId !== this.m.artifactType.id &&
          ![...restrictions[linkTypeId]]?.find(json => {
            const { sourceArtifactTypeId, destinationArtifactTypeId } = JSON.parse(json);
            return dto.sourceArtifactTypeId === sourceArtifactTypeId && destinationArtifactTypeId === dto.destinationArtifactTypeId;
          })
        ) {
          restrictions[linkTypeId].add(JSON.stringify(dto));
        }
      });

      await lastValueFrom(
        this.tenantLinkTypeService.linkTypeControllerUpdate({
          body: {
            id: linkTypeId,
            restrictions: [...restrictions[linkTypeId]].map(item => JSON.parse(item)),
          },
        }),
      ).then(dto => this.cache.data.linkTypes.setItem(dto));
    }
  }

  private getProcessedLinkRestrictions(restrictionsToProcess: UngroupedArtifactTypeLinkRestriction[]): Record<string, Set<string>> {
    const restrictions: Record<string, Set<string>> = {};

    restrictionsToProcess.forEach(restriction => {
      if (restriction.linkType && !restrictions[restriction.linkType.value]) restrictions[restriction?.linkType?.value] = new Set();
      if (restriction.linkType && restriction.linkType.meta === LinkDirection.outgoing) {
        restriction.linkType &&
          restrictions[restriction.linkType.value].add(
            JSON.stringify(
              new LinkRestriction({
                sourceArtifactTypeId: restriction.sourceArtifactTypeId,
                singleSource: restriction.singleSource,
                destinationArtifactTypeId: restriction.destinationArtifactTypeId,
                singleDestination: restriction.singleDestination,
                isLinkRequired: (restriction.isLinkRequired as any).value,
                notifySource: restriction.notifySource,
                notifyDestination: restriction.notifyDestination,
              }),
            ),
          );
      } else {
        restriction.linkType &&
          restrictions[restriction.linkType.value].add(
            JSON.stringify(
              new LinkRestriction({
                sourceArtifactTypeId: restriction.sourceArtifactTypeId,
                singleSource: restriction.singleSource,
                destinationArtifactTypeId: restriction.destinationArtifactTypeId,
                singleDestination: restriction.singleDestination,
                isLinkRequired: (restriction.isLinkRequired as any).value,
                notifySource: restriction.notifySource,
                notifyDestination: restriction.notifyDestination,
              }),
            ),
          );
      }
    });
    return restrictions;
  }

  private async createAndAssignDefaults(artifactType: ArtifactTypeResponseDto): Promise<void> {
    const { attributes, dataTypes, artifactTypes, linkTypes } = this.m.options;

    this.cache.data.dataTypes.update();
    this.cache.data.artifactTypes.update();

    await this.pageBuilderHelper.createAndAssignDefaultsToArtifactType(new DefaultPageParams(artifactType, attributes, dataTypes, artifactTypes, linkTypes));
  }
}
