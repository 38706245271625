import { Padding } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { NewArtifact } from './artifact.types';

export const DEFAULT_VARIANT_KEY = 'DEFAULT';
export const CUSTOM_VARIANT_KEY = 'CUSTOM';

/** DisplayAtMetadata == display attribute type */
/** DAT == display attribute type */
export interface DisplayAtMetadata {
  attributeType: DisplayAttributeType;
  selectedVariantCode: string;
  editVariantCode?: string;
  horizontalAlign?: string;
  showClear?: boolean;
  isMultiple?: boolean;
  selectedEditCode?: string;
  customVariantMetadata?: DisplayAtCustomVariantMetadata;
}

export interface DisplayAtFormatContext {
  padding?: Padding;
}

export interface DisplayAtDropdownItem {
  label: string;
  code: string;
}

export interface DisplayAtSelectionItem {
  /** identifier of owner of this relation */
  ownerId: string;
  attributeId: string;
  selectedVariant: string;
  selectionType: DisplayAtSelectionTypeEnum;
}

/** Interface for value selection change event */
export interface DisplayAtValueSelectionItem {
  /** identifier of owner of this relation */
  ownerId: string;
  artifact: NewArtifact;
  attributeId: string;
  value: any;
}

export enum DisplayAtSelectionTypeEnum {
  DISPLAY = 'DISPLAY',
  EDIT = 'EDIT',
}

export interface DisplayAtCustomVariantMetadata {
  cardWidgetId: string;
}

export interface DisplayAtCustomVariant extends DisplayAtSelectionItem, DisplayAtCustomVariantMetadata {}

export enum DisplayAttributeType {
  simple = 'SIMPLE',
  text = 'TEXT',
  enumerated = 'ENUM',
  dateTime = 'DATE_TIME',
  user = 'USER',
  html = 'HTML',
  file = 'FILE',
  boolean = 'BOOLEAN',
  link = 'LINK',
  hyperlink = 'HYPERLINK',
  systemDate = 'SYSTEM_DATE',
}

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATEnumLayoutVariant {
  DEFAULT = 'DAT_ENUM_DEFAULT',
  FULL_CELL = 'DAT_ENUM_FULL_CELL_COLOR',
  CHIP_V1 = 'DAT_ENUM_SIMPLE_CHIP',
  CHIP_V2 = 'DAT_ENUM_CHIP_WITH_ICON',
  COLOR_ICON_TEXT = 'DAT_ENUM_COLORED_ICON_TEXT',
  COLOR_ICON_COLOR_TEXT = 'DAT_ENUM_COLORED_ICON_COLORED_TEXT',
  COLOR_ICON = 'DAT_ENUM_COLORED_ICON',
  // CIRCLE = 'DAT_ENUM_CIRCLE',
  DROPDOWN = 'DAT_ENUM_DROPDOWN',
  CUSTOM = 'DAT_ENUM_CUSTOM',
}

/*
Colored icon + Text (no chip)
Colored Icon + Colored Text (no chip)
Colored icon only (no chip)
*/

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATEnumEditVariant {
  DEFAULT = 'DEFAULT',
  DROPDOWN = 'DAT_ENUM_EDIT_DROPDOWD_SELECTION',
  RADIO_BUTTON = 'DAT_RADIO_BUTTON',
  RADIO_BUTTON_HORIZONTAL = 'DAT_RADIO_BUTTON_HORIZONTAL',
  CLICK_TO_SELECT_NEXT = 'DAT_ENUM_EDIT_NEXT_VALUE_SELECTION',
}

export enum DATEnumMultipleEditVariant {
  DEFAULT = 'DEFAULT',
  CHECKBOX_GROUP = 'DAT_CHECKBOX_GROUP',
  CHECKBOX_GROUP_HORIZONTAL = 'DAT_CHECKBOX_GROUP_HORIZONTAL',
}

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATUserLayoutVariant {
  DEFAULT = 'DAT user default',
  NAME_ONLY = 'DAT user name',
  ICON_AND_NAME = 'DAT user icon & name',
  ICON_ONLY = 'DAT user icon only',
  CUSTOM = 'DAT user custom',
}

export enum DATUserSubscribeLayoutVariant {
  ADD_TO_LIST = 'DAT Add current user to the list',
}

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATBooleanLayoutVariant {
  DEFAULT = 'DEFAULT',
  MODERN_CHECKBOX = 'DAT_BOOLEAN_MODERN_CHECKBOX',
}

export enum DATBooleanEditVariant {
  DEFAULT = 'DEFAULT',
  TOGGLE_2_STATE = 'DAT_BOOLEAN_2STATE_TOGGLE',
  TOGLE_3_STATE = 'DAT_BOOLEAN_3STATE_TOGGLE',
  RADIO_BUTTON = 'DAT_RADIO_BUTTON',
  RADIO_BUTTON_HORIZONTAL = 'DAT_RADIO_BUTTON_HORIZONTAL',
}

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATLinkLayoutVariant {
  DEFAULT = 'DAT link default',
  SUMMARY_ICON = 'DAT summary icon',
  PART_WITH_LOAD_MORE = 'DAT part with load more',
  PART_WITH_PAGINATION = 'DAT part with pagination',
  CUSTOM = 'DAT link custom',
}

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATHtmlLayoutVariant {
  DEFAULT = 'DAT html default',
  // DETAIL_ON_HOVER = 'DAT icon & detail on hover',
  DETAIL_ON_CLICK = 'DAT icon & detail on click',
  CUSTOM = 'DAT html custom',
}

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATTextLayoutVariant {
  DEFAULT = 'DAT text default',
  DETAIL_ON_CLICK = 'DAT icon & detail on click',
  DEFAULT_CARD = 'Default card widget',
  CUSTOM = 'DAT text custom',
}

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATTextFilterWidgetLayoutVariant {
  DEFAULT = 'Show search button, enter disable',
  HIDE_BUTTON_ENTER_ENABLE = 'Hide button, enter enable',
}

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATFileLayoutVariant {
  DEFAULT = 'DAT file default',
  PICTURE = 'picture',
  PICTURE_WITH_NAME = 'picture with name',
  PICTURE_WITH_LINK = 'picture with link',
}

/*eslint-enable*/

/*eslint-disable @typescript-eslint/naming-convention */
export enum DATHyperlinkLayoutVariant {
  DEFAULT = 'DAT hyperlink default',
  REDIRECT_ICON = 'DAT icon and redirect on click',
}

export enum HyperlinkDisplayVariant {
  DEFAULT = 'DEFAULT',
  REDIRECT_ICON = 'REDIRECT_ICON',
}

export enum DATSystemDateLayoutVariant {
  DEFAULT = 'DEFAULT',
  DD_MM_YY = 'DAT DD-MM-YY',
  DD_MM_YYYY = 'DAT DD-MM-YYYY',
  DD_MM_YYYY_DOTTED = 'DAT DD.MM.YYYY',
  MM_DD_YYYY_DOTTED = 'DAT MM.DD.YYYY',
  M_D_YY_DOTTED = 'DAT M.D.YY',
  MM_DD_YYYY = 'DAT MM-DD-YYY',
  MM_DD_YYYY_SLASHED = 'DAT MM/DD/YYYY',
  M_D_YY_HH_MM_SS_DOTTED = 'DAT M.D.YY HH:MM:SS',
  MM_DD_HH_MM_SS_YYYY = 'DAT MM-DD-YYY HH:MM:SS',
  MM_DD_YYYY_HH_MM_SS_SLASHED = 'DAT MM/DD/YYYY HH:MM:SS',
}

export enum DATDateCalendarFormat {
  DEFAULT = '',
  DD_MM_YY = 'dd-mm-y',
  DD_MM_YYYY = 'dd-mm-yy',
  DD_MM_YYYY_DOTTED = 'dd.mm.yy',
  MM_DD_YYYY_DOTTED = 'mm.dd.yy',
  M_D_YY_DOTTED = 'm.d.yy',
  MM_DD_YYYY = 'mm-dd-yy',
  MM_DD_YYYY_SLASHED = 'mm/dd/yy',
  M_D_YY_HH_MM_SS_DOTTED = 'm.d.yy',
  MM_DD_HH_MM_SS_YYYY = 'mm-dd-yy',
  MM_DD_YYYY_HH_MM_SS_SLASHED = 'mm/dd/yy',
}

/*eslint-enable*/

export enum UserIconSizeEnum {
  LARGE = 32,
  MEDIUM = 24,
  SMALL = 16,
}
