<div class="rule-top-container" [class.scrollable]="scrollable">
  <div *ngIf="ruleAdministrationType | showRuleOwnerTypeAndOwnerPipe" class="rule-type-owner-container">
    <div class="col-3">
      <label for="ruleType" class="ml-2" translate>Type</label>
      <p-dropdown
        [(ngModel)]="rule.type"
        [options]="ruleTypes"
        [disabled]="!isNew"
        [autoDisplayFirst]="false"
        id="ruleType"
        (onChange)="onRuleTypeChange($event.value)"
      >
        <ng-template let-option pTemplate="item">{{ option | translate }}</ng-template>
        <ng-template let-option pTemplate="selectedItem">{{ option | translate }}</ng-template>
      </p-dropdown>
    </div>
    <div class="col-3">
      <label for="ruleOwnerType" class="ml-2" translate>Owner type</label>
      <p-dropdown
        [(ngModel)]="rule.ownerType"
        [options]="ruleOwnerTypes"
        [disabled]="rule.type | isOwnerTypeDisabled : isNew"
        [autoDisplayFirst]="false"
        id="ruleOwnerType"
        (onChange)="onOwnerTypeChange($event.value)"
      >
        <ng-template let-option pTemplate="item">{{ option | titlecase }}</ng-template>
        <ng-template let-option pTemplate="selectedItem">{{ option | titlecase }}</ng-template>
      </p-dropdown>
    </div>
    <div class="col-3 align-self-end">
      <ng-container *ngIf="rule.ownerType | showRuleOwner">
        <label for="ruleOwner" class="ml-2" translate>Owner</label>
        <p-dropdown
          id="ruleOwner"
          appendTo="body"
          [filter]="true"
          filterBy="name"
          optionValue="id"
          optionLabel="name"
          [autoDisplayFirst]="false"
          [options]="ruleOwnerOptions"
          [disabled]="rule.ownerType | isOwnerDisabled : isNew"
          [(ngModel)]="rule.ownerId"
          (onChange)="onOwnerChange($event.value)"
        >
        </p-dropdown>
      </ng-container>
    </div>
    <div class="col-3 static">
      <ng-container *ngIf="ruleAdministrationType | showUsedIn : rule.ownerType : rule.global">
        <span class="dynamic-label">
          <label for="ruleUsedIn" class="ml-2" translate>Used in</label>
          <p-multiSelect
            id="ruleUsedIn"
            appendTo="body"
            [filter]="true"
            filterBy="name"
            optionValue="id"
            optionLabel="name"
            [disabled]="rule.ownerType | isUsedInDisabled : isNew"
            [options]="ruleUsedInOptions"
            [(ngModel)]="rule.usedIn"
          >
          </p-multiSelect>
        </span>
      </ng-container>
    </div>
  </div>

  <div class="rule-global-active-container mb-4 mt-4">
    <div class="col-2">
      <p-checkbox [(ngModel)]="rule.active" inputId="ruleActive" [binary]="true" [disabled]="!editable"></p-checkbox>
      <label for="ruleActive" class="ml-2" translate>Is Active</label>
    </div>

    <div class="col-2">
      <ng-container *ngIf="rule.ownerType | showIsGlobal">
        <p-checkbox
          [(ngModel)]="rule.global"
          inputId="ruleGlobal"
          [binary]="true"
          [disabled]="!editable"
          (onChange)="onGlobalChange($event.value)"
        ></p-checkbox>
        <label for="ruleGlobal" class="ml-2" translate>Is Global</label>
      </ng-container>
    </div>

    <div class="col-2">
      <div class="dynamic-label p-float-label mb-0">
        <p-inputNumber [(ngModel)]="rule.priority" mode="decimal" [maxFractionDigits]="5" [useGrouping]="false"
                       class="rule-priority-input"></p-inputNumber>
        <label for="priorityInput" translate>Priority</label>
      </div>
    </div>
  </div>

  <div class="dynamic-label p-float-label mt-4">
    <input #ruleNameInput pInputText id="ruleName" type="text" [(ngModel)]="rule.name" [disabled]="!editable" />
    <label class="dynamic-label-text" for="ruleName" translate>Name</label>
  </div>

  <div class="dynamic-label p-float-label mt-6">
    <textarea pInputTextarea id="ruleDescription" rows="3" [(ngModel)]="rule.description"
              [disabled]="!editable"> </textarea>
    <label class="dynamic-label-text" for="ruleDescription" translate>Description</label>
  </div>

  <span *ngIf="rule.ownerType !== OwnerType.CUSTOM && rule.ownerType !== OwnerType.LINK">
    {{ 'WORKFLOWS.COMMON.' + (defaultArtifactType ? 'ADDED' : 'ADD') | translate }}
    <p-dropdown
      class="workflow-element-dropdown"
      styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
      appendTo="body"
      placeholder="{{ 'WORKFLOWS.PLACEHOLDER.DEFAULT_ARTIFACT_TYPE' | translate }}"
      [filter]="true"
      filterBy="name"
      optionValue="id"
      [options]="artifactTypes"
      [readonly]="!editable"
      [(ngModel)]="rule.defaultArtifactTypeId"
      (onChange)="onArtifactTypeChange($event.value)"
    >
      <ng-template let-option pTemplate="item">{{ option.name }}</ng-template>
      <ng-template let-option pTemplate="selectedItem">{{ option.name }}</ng-template>
    </p-dropdown>
    <span *ngIf="defaultArtifactType">
      {{ 'WORKFLOWS.COMMON.AS' | translate }}
      {{ 'WORKFLOWS.PLACEHOLDER.DEFAULT_ARTIFACT_TYPE' | translate }}
      {{ 'WORKFLOWS.COMMON.FOR_THIS_RULE' | translate }}
    </span>
  </span>

  <div *ngFor="let trigger of rule.triggers; let i = index" class="rule-element-container" [class.readonly]="!editable">
    <app-workflows-rule-trigger
      [trigger]="trigger"
      [artifactTypes]="artifactTypes"
      [linkableArtifactTypes]="linkableArtifactTypes$ | async"
      [attributes]="attributes.list"
      [dataTypes]="dataTypes"
      [linkTypes]="linkTypes"
      [defaultArtifactType]="defaultArtifactType"
      [pageWidgets]="pageWidgets"
      [editable]="rule.ownerType | isTriggerEditable : editable"
      (onTriggerTypeUpdate)="onTriggerTypeUpdate($event, trigger)"
      (onTriggerPropertyUpdate)="onTriggerPropertyUpdate($event)"
    ></app-workflows-rule-trigger>
    <div>
      @if (rule.ownerType | showTriggerButtons : editable) {
        <p-button
          [label]="'Trigger' | translate"
          [pTooltip]="'WORKFLOWS.COMMON.ADD_TRIGGER' | translate"
          class="hidable-button"
          [class.visibility-hidden]="!trigger.isValid()"
          icon="pi pi-plus" text="true" severity="info"
          (click)="addNewTrigger()"
        ></p-button>
        @if (rule.triggers.length > 1) {
          <p-button
            [pTooltip]="'WORKFLOWS.COMMON.DELETE_TRIGGER' | translate"
            class="hidable-button"
            icon="pi pi-trash" severity="danger" text="true"
            (click)="deleteTrigger(trigger)"
          ></p-button>
        }
      }
      <ng-container *ngIf="rule.ownerType === OwnerType.CUSTOM">
        <button
          *ngIf="!trigger.inputDataClientArray?.length"
          pButton
          label="{{ 'WORKFLOWS.COMMON.ADD_INPUTS' | translate }}"
          class="p-button p-button-info btn-workflows"
          (click)="onAddTriggerInputs()"
        ></button>
        <button
          *ngIf="trigger.inputDataClientArray?.length"
          pButton
          label="{{ 'WORKFLOWS.COMMON.REMOVE_INPUTS' | translate }}"
          class="p-button p-button-danger btn-workflows"
          (click)="onRemoveTriggerInputs()"
        ></button>
      </ng-container>
    </div>
    <div *ngIf="trigger.inputDataClientArray?.length">
      <app-trigger-manual [trigger]="trigger"></app-trigger-manual>
    </div>
  </div>

  <ng-container *ngIf="hasValidTriggers()">
    <div class="rule-condition-container">
      <div class="rule-condition-header">
        <div>
          <span *ngIf="rule.condition"> {{ 'Conditions' | translate }} </span>
        </div>
        <div>
          <button
            *ngIf="!rule.condition && (rule.defaultArtifactTypeId || rule.ownerType === OwnerType.CUSTOM || rule.ownerType === OwnerType.LINK)"
            pButton
            class="p-button-info"
            type="button"
            (click)="onActivateConditions()"
            [label]="'Add conditions' | translate"
          ></button>
          <button
            *ngIf="rule.condition"
            pButton
            class="p-button p-button-danger"
            type="button"
            (click)="onDeactivateConditions()"
            [label]="'Remove all conditions' | translate"
          ></button>
        </div>
      </div>
      <ng-container *ngIf="rule.condition">
        <app-workflows-rule-condition
          #condition
          [group]="rule.condition"
          [defaultArtifactType]="defaultArtifactType"
          [artifactTypes]="artifactTypes"
          [attributes]="attributes.list"
          [allAttributes]="attributes"
          [dataTypes]="dataTypes"
          [triggers]="rule.triggers"
          [workflowType]="rule.type"
          [depth]="1"
        ></app-workflows-rule-condition>
      </ng-container>
    </div>
    <div *ngFor="let action of rule.actions; let index = index" class="rule-element-container" [class.readonly]="!editable">
      <app-workflows-rule-action
        [selectableActions]="selectableActions"
        [triggers]="rule.triggers"
        [action]="action"
        [applications]="applications"
        [artifactTypes]="artifactTypes"
        [linkableArtifactTypes]="linkableArtifactTypes$ | async"
        [attributes]="attributes.list"
        [allAttributes]="attributes"
        [dataTypes]="dataTypes"
        [linkTypes]="linkTypes"
        [defaultArtifactType]="defaultArtifactType"
        [editable]="editable"
        [index]="index"
        (onActionTypeUpdate)="onActionTypeUpdate($event, action)"
        (onActionPropertyUpdate)="onActionPropertyUpdate($event)"
      ></app-workflows-rule-action>
      <div>
        @if (editable) {
          <p-button [label]="'Action' | translate"
                    [pTooltip]="'WORKFLOWS.COMMON.ADD_ACTION' | translate"
                    class="hidable-button"
                    icon="pi pi-plus" text="true" severity="info"
                    [class.visibility-hidden]="!action.isValid()"
                    (click)="addNewAction(index)"
          ></p-button>
          <p-button [pTooltip]="(action.condition ? 'WORKFLOWS.COMMON.EDIT_CONDITIONS' : 'WORKFLOWS.COMMON.ADD_CONDITIONS') | translate"
                    class="hidable-button"
                    [class.force-hiding]="!action.type"
                    [class.force-showing]="action.condition"
                    [icon]="action.condition ? 'bi bi-gear-fill' : 'bi bi-gear'" text="true" outlined="true" severity="info"
                    (click)="onEditActionConditions(action, $event)"
          ></p-button>
          @if (rule.actions.length > 1) {
            <p-button [pTooltip]="'WORKFLOWS.COMMON.DELETE_ACTION' | translate"
                      class="hidable-button"
                      icon="pi pi-trash" text="true" severity="danger"
                      (click)="deleteAction(action)"
            ></p-button>
          }
        }
      </div>
    </div>
  </ng-container>
</div>

<p-overlayPanel #actionConditionsOp
                appendTo="body"
                dismissable="false"
                [style]="{'width': '75vw', 'min-height': '420px', 'max-height': '420px', 'overflow-y': 'auto'}">
  <ng-template pTemplate>
    <button pButton class="action-condition-close-button" type="button"
            icon="pi pi-times"
            (click)="actionConditionsOp.hide()"
    ></button>
    @if (selectedAction()) {
      @if (!selectedAction()!.condition) {
        <button
          pButton
          class="p-button-info"
          type="button"
          (click)="onActivateActionConditions()"
          [label]="'Add conditions' | translate"
        ></button>
      } @else {
        <button
          pButton
          class="p-button p-button-danger mb-4"
          type="button"
          (click)="onDeactivateActionConditions()"
          [label]="'Remove all conditions' | translate"
        ></button>
        <app-workflows-rule-condition
          [group]="selectedAction()?.condition"
          [defaultArtifactType]="defaultArtifactType"
          [artifactTypes]="artifactTypes"
          [attributes]="attributes.list"
          [allAttributes]="attributes"
          [dataTypes]="dataTypes"
          [triggers]="rule.triggers"
          [workflowType]="rule.type"
          [depth]="1"
          [isActionCondition]="true"
        ></app-workflows-rule-condition>
      }
    }
  </ng-template>
</p-overlayPanel>
