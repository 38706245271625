<div class="grid">
  <div class="col-12 mb-0 pb-0">
    <h4 *ngIf="m.selected?.treeItem?.usesDefaultStyle && (m.items.menu | isItemInFirstLevel : m.selected.menuItem)" class="my-0">
      Using default top level menu item style
    </h4>
    <h4 *ngIf="m.selected?.treeItem?.usesDefaultStyle && !(m.items.menu | isItemInFirstLevel : m.selected.menuItem)" class="my-0">
      Using default sub menu item style
    </h4>
    <h4 class="my-0" *ngIf="!m.selected?.treeItem?.usesDefaultStyle">Using own item style</h4>
  </div>

  <div class="col-12 mb-0" *ngIf="m.selected.treeItem">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox (onChange)="s.onDefaultStylesClick()" [(ngModel)]="m.selected?.treeItem.usesDefaultStyle" binary="true" id="defaultStyle"></p-checkbox>
        <label for="defaultStyle">{{ 'Use default styles' | translate }}</label>
      </span>
    </span>
  </div>

  <div class="col-12" *ngIf="m.selected.treeItem">
    <h6>Item type</h6>
    <p-dropdown
      (onChange)="s.onDefaultStylesClick()"
      [(ngModel)]="m.selected.treeItem.behavior"
      [autoDisplayFirst]="false"
      [options]="m.options.menuItemBehavior"
      [disabled]="m.behaviorOptionDisabled"
      pTooltip="{{ 'Option not available if item contains sub items' | translate }}"
      [tooltipDisabled]="!m.behaviorOptionDisabled"
      id="menu-type"
    >
    </p-dropdown>
  </div>

  <div class="col-12" *ngIf="m.selected.treeItem?.behavior === menuItemBehavior.action">
    <h6>Action type</h6>
    <p-dropdown
      [(ngModel)]="m.selected.treeItem.defaultElvisAction"
      (ngModelChange)="s.setTreeItemsToMenu()"
      [autoDisplayFirst]="false"
      [options]="m.options.defaultElvisActions"
      id="action-type"
    >
      <ng-template pTemplate="selectedItem" let-selected> {{ selected.label | translate }}</ng-template>
      <ng-template let-option pTemplate="item"> {{ option.label | translate }}</ng-template>
    </p-dropdown>
  </div>

  <div class="col-12" *ngIf="m.selected.treeItem?.behavior === menuItemBehavior.link">
    <h6>Current page behavior</h6>
    <p-dropdown
      [(ngModel)]="m.selected.treeItem.currentPageBehavior"
      (ngModelChange)="s.setTreeItemsToMenu()"
      [autoDisplayFirst]="false"
      [options]="m.options.currentPageBehavior"
      id="link-page"
    >
      <ng-template pTemplate="selectedItem" let-selected> {{ selected.label | translate }}</ng-template>
      <ng-template let-option pTemplate="item"> {{ option.label | translate }}</ng-template>
    </p-dropdown>
  </div>

  <ng-container *ngIf="m.selected.treeItem && m.selected.treeItem.behavior === menuItemBehavior.link">
    <ng-container *ngIf="m.selected.treeItem.currentPageBehavior === currentPageBehavior.leaveCurrentPage">
      <div class="col-4 mb-0">
        <span class="dynamic-label dynamic-checkbox">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="m.selected.treeItem.isPageSelection" (ngModelChange)="s.setTreeItemsToMenu()" binary="true" id="typeUrl"></p-checkbox>
            <label for="typeUrl">{{ m.selected.treeItem.isPageSelection ? 'Page' : ('Url' | translate) }}</label>
          </span>
        </span>

        <span class="dynamic-label dynamic-checkbox" *ngIf="m.selected.treeItem.isPageSelection">
          <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="m.selected.treeItem.useAlias" (ngModelChange)="s.setTreeItemsToMenu()" binary="true" id="useAlias"></p-checkbox>
            <label for="useAlias">{{ 'Use page alias' | translate }}</label>
          </span>
        </span>
      </div>

      <div class="col-8 my-0">
        <span *ngIf="!m.selected.treeItem.isPageSelection" class="dynamic-label">
          <input
            #urlInput="ngModel"
            [(ngModel)]="m.selected.treeItem.url"
            (ngModelChange)="s.setTreeItemsToMenu()"
            [pattern]="http_https_validation_regex"
            id="url"
            pInputText
            type="text"
          />
          <label class="dynamic-label-text" for="url">{{ 'Url' | translate }}</label>
          <div *ngIf="urlInput.errors?.pattern" class="mt-3">
            <p class="text-red">{{ 'Please include' | translate }} <span class="text-bold"> http:// </span> or <span class="text-bold"> https:// </span></p>
          </div>
        </span>

        <span *ngIf="m.selected.treeItem.isPageSelection" class="dynamic-label">
          <p-dropdown
            [(ngModel)]="m.selected.pageId"
            (onChange)="s.onPageSelect($event)"
            [options]="m.options.pages.list | transformArrayToSelectOptions : NAME_VALUE : ID_VALUE"
            [autoDisplayFirst]="false"
            [filter]="true"
            id="page"
            appendTo="body"
            [virtualScroll]="false"
            [virtualScrollItemSize]="10"
            appNotifyIfSelectedPageDeletedDirective
            [selectedPageId]="m.selected.pageId"
          >
          </p-dropdown>
          <label class="dynamic-label-text" for="page">{{ 'Select Page' | translate }}</label>
        </span>

        <small *ngIf="m.selected.treeItem.isPageSelection && m.selected.treeItem.useAlias && !m.selected.treeItem.alias" class="alias-error">
          {{ "This page does not have an alias" | translate }}
        </small>
      </div>
    </ng-container>

    <ng-container *ngIf="m.selected.treeItem.isPageSelection">
      <div class="col-6 mb-0 text-center">
        <button
          (click)="queryParamsOp.toggle($event)"
          label="{{ 'Query parameters' | translate }}"
          class="w-100"
          icon="bi bi-question-lg"
          pButton
          type="button"
        ></button>
      </div>
      <div class="col-6 mb-0 text-center">
        <button
          (click)="fragmentParamOp.toggle($event)"
          label="{{ 'Scroll to div parameter' | translate }}"
          class="w-100"
          icon="bi bi-hash"
          pButton
          type="button"
        ></button>
      </div>
    </ng-container>

    <div *ngIf="m.selected.treeItem.currentPageBehavior === currentPageBehavior.stayOnCurrentPage" class="col-12 mb-0">
      <span class="dynamic-label dynamic-checkbox">
        <span class="dynamic-checkbox-box">
          <p-checkbox
            [(ngModel)]="m.selected?.treeItem.showEmptyParamsAsActive"
            (ngModelChange)="s.setTreeItemsToMenu()"
            binary="true"
            id="showNoParamsAsActive"
          >
          </p-checkbox>
          <label for="showNoParamsAsActive">{{ 'Show item with no parameters as active' | translate }}</label>
        </span>
      </span>
    </div>

    <div class="col-6">
      <span class="dynamic-label">
        <p-dropdown
          [(ngModel)]="m.settings.menu.innerFlexDirection"
          [options]="m.options.flexDirection"
          appendTo="body"
          id="direction">
        </p-dropdown>
        <label class="dynamic-label-text" for="direction" translate> Direction </label>
      </span>
    </div>
  </ng-container>
</div>

<p-overlayPanel #queryParamsOp [style]="{ 'min-width': '800px' }" appendTo="body">
  <div *ngIf="m.selected.treeItem" class="p-grid query-params-overlay">
    <div *ngFor="let param of m.selected.treeItem.queryParams; let i = index" class="row">
      <div class="col-3">
        <p-dropdown [(ngModel)]="param.action" [options]="m.options.linkQueryParamAction" [autoDisplayFirst]="false" appendTo="body">
          <ng-template pTemplate="selectedItem" let-selected> {{ selected.label | translate }}</ng-template>
          <ng-template let-option pTemplate="item"> {{ option.label | translate }}</ng-template>
        </p-dropdown>
      </div>
      <div [ngClass]="{ 'col-4': param.action === queryParamActions.addNew, 'col-8': param.action === queryParamActions.removeExisting }">
        <input type="text" [id]="'queryParamKey' + i" pInputText [(ngModel)]="param.key" placeholder="Enter key" class="w-100" />
      </div>
      <div *ngIf="param.action === queryParamActions.addNew" class="col-4">
        <input type="text" [id]="'queryParamVal' + i" pInputText [(ngModel)]="param.value" placeholder="Enter value" class="w-100" />
      </div>
      <div class="col-1 text-center">
        <button
          pButton
          class="p-button p-button-rounded p-button-danger p-button-outlined"
          type="button"
          pTooltip="{{ 'Delete' | translate }}"
          icon="pi pi-trash"
          iconPos="left"
          (click)="onDeleteParamClick(i)"
        ></button>
      </div>
    </div>
    <button (click)="onAddQueryParamClick()" label="{{ 'Add' | translate }}" icon="pi pi-plus" pButton type="button"></button>
  </div>
</p-overlayPanel>

<p-overlayPanel #fragmentParamOp [style]="{ 'max-width': '380px' }" appendTo="body">
  <div *ngIf="m.selected.treeItem">
    <input
      type="text"
      pInputText
      [(ngModel)]="m.selected.treeItem.fragmentParam"
      (ngModelChange)="s.setTreeItemsToMenu()"
      placeholder="Enter div ID to scroll into"
    />
  </div>
</p-overlayPanel>
