import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { GlobalConstants } from '@shared/constants/global.constants';
import { SharedMethods } from '@shared/methods/shared.methods';
import { NewArtifact } from '@shared/types/artifact.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { SelectOption } from '@shared/types/shared.types';

/**
 * Applicable only for user data types.
 */
@Pipe({ name: 'toUser' })
export class ToUserPipe implements PipeTransform {
  transform(artifact: NewArtifact | ArtifactLinkResponseDto, key: string, usersMap: Record<string, ArtifactLinkResponseDto>): string {
    const rawUserValue = NonAttributeKeys.isUserSpecificAttributeKeyOrId(key) ? this.forSystemUser(key, artifact) : artifact.attributes[key]?.value;
    if (!rawUserValue) return '';

    return Array.isArray(rawUserValue) ? rawUserValue.map(value => this.getUserName(value, usersMap)).join(', ') : this.getUserName(rawUserValue, usersMap);
  }

  private getUserName(value: string | SelectOption<string, string>, usersMap: Record<string, ArtifactLinkResponseDto>): string {
    return typeof value === 'string'
      ? (usersMap[value]?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) || ''
      : (usersMap[value.value]?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) || '';
  }

  private forSystemUser(key: string, artifact: NewArtifact | ArtifactLinkResponseDto) {
    let userKey = key;
    if (NonAttributeKeys.isUserSpecificAttributeId(key)) {
      userKey = key === NonAttributeKeys.CREATED_BY_ID ? NonAttributeKeys.CREATED_BY : NonAttributeKeys.UPDATED_BY;
    }
    return SharedMethods.getValueFromPath(userKey, artifact);
  }
}
