import { Pipe, PipeTransform } from '@angular/core';
import { TextHorizontalAlignEnum } from '@widgets/shared/types/style.types';

@Pipe({
  name: 'setAlignmentClassToChildren',
  standalone: true,
})
export class SetAlignmentClassToChildrenPipe implements PipeTransform {
  transform(textAlign?: TextHorizontalAlignEnum): string {
    if (textAlign === TextHorizontalAlignEnum.center) return 'place-items-center';
    if (textAlign === TextHorizontalAlignEnum.right) return 'place-items-right';

    return '';
  }
}
