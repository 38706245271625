import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { UrlMethods } from '@shared/methods/url.methods';
import { LinkQueryParam, LinkQueryParamActionEnum, MenuItemCurrentPageBehaviorEnum } from '@widgets/menu-widget/types/tree-types';
import { xor } from 'lodash';
import { MenuItem } from '../types/menu-widget.types';

@Injectable({ providedIn: 'root' })
export class ActiveLinkCheckHelper {
  isRouterLinkActive(item: MenuItem, allMenuItems: MenuItem[], queryParams: Params): boolean {
    if (item.isStayOnCurrentPage)
      return this.isItemWithStayOnCurrentPageActive(item, allMenuItems, queryParams);

    const isQueryParamsActive = this.isItemLinkWithQueryParamsActive(item, queryParams);
    let isPathActive = false;
    const idOrAlias = UrlMethods.getPageIdOrAliasFromUrl(window.location.pathname);

    item.actions.forEach(action => {
      idOrAlias === action.url || idOrAlias === action.alias && (isPathActive = true);
    })

    return isPathActive && isQueryParamsActive;
  }

  isItemWithStayOnCurrentPageActive(item: MenuItem, allMenuItems: MenuItem[], queryParams: Params): boolean {
    const paramsToAdd = this.getAllQueryParams(item);

    if (!paramsToAdd.length) {
      return item.showEmptyParamsAsActive && this.isNoQueryParamFromThisMenuInUse(allMenuItems, queryParams);
    }

    return paramsToAdd.every(itemQueryParam => this.isQueryParamInUse(itemQueryParam, queryParams));
  }

  isItemLinkWithQueryParamsActive(item: MenuItem, queryParams: Params): boolean {
    const paramsToAdd = this.getAllQueryParams(item);
    return !paramsToAdd.length || paramsToAdd.every(itemQueryParam => this.isQueryParamInUse(itemQueryParam, queryParams));
  }

  isNoQueryParamFromThisMenuInUse(allMenuItems: MenuItem[], queryParams: Params): boolean {
    const items = allMenuItems.filter(menuItem => menuItem.actions.filter(action => action.type === MenuItemCurrentPageBehaviorEnum.stayOnCurrentPage).length);
    return items.every(item => item.actions.filter(action => !action.getAddParamsThatAreInUse(queryParams).length).length);
  }

  // XOR is used to compare two arrays
  isQueryParamInUse(itemQueryParam: LinkQueryParam, queryParams: Params): boolean {
    if (!itemQueryParam.value) return false;
    return itemQueryParam.value.split(',').length > 1
      ? xor(itemQueryParam.value.split(','), queryParams[itemQueryParam.key]?.split(',')).length === 0
      : queryParams[itemQueryParam.key] === itemQueryParam.value;
  }

  getAllQueryParams(item: MenuItem): LinkQueryParam[] {
    let queryParams: LinkQueryParam[] = [];
    item.actions.forEach(action => {
      action.queryParams.forEach(param => param.action === LinkQueryParamActionEnum.addNew && (queryParams.push(param)))
    });

    return queryParams;
  }
}
