import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AttributeBasedEvent } from '@workflows/shared';
import { AttributeVisibleSettable } from '@workflows/shared/types/attribute-visible-settable';
import { filter, map } from 'rxjs';
import { AttributeActionHandlerService } from '../services';
import { AbstractRuleAttributeDirective } from './abstract-rule-attribute.directive';

@Directive({ selector: '[appShowHideAttribute]' })
export class ShowHideAttributeDirective extends AbstractRuleAttributeDirective implements OnInit, OnDestroy {
  @Input() set appShowHideAttribute(appShowHideAttribute: AttributeBasedEvent) {
    if (appShowHideAttribute) {
      this.artifactTypeId = appShowHideAttribute.artifactTypeId;
      this.attributeId = appShowHideAttribute.attributeId;
    }
  }

  @Input() attributeOwner: AttributeVisibleSettable;

  constructor(protected actionAttributeHandler: AttributeActionHandlerService) {
    super();
  }

  ngOnInit(): void {
    this.actionAttributeHandler.registerShowHideEventListener(this.key);
    this.initSubscription = this.actionAttributeHandler.showHideEventMap$
      .pipe(
        filter(() => this.actionAttributeHandler.containsShowHideValueEvent(this.key)),
        map(() => this.actionAttributeHandler.getShowHideValueEvent(this.key)),
        // debounceTime(100),
      )
      .subscribe(showHideEvent => {
        showHideEvent.showAttribute ? this.doShow() : this.doHide();
        this.actionAttributeHandler.removeShowHideEventFromMap(this.key, false);
      });
  }

  ngOnDestroy(): void {
    this.actionAttributeHandler.unregisterShowHideEventListener(this.key);
    super.ngOnDestroy();
  }

  private doShow() {
    this.attributeOwner && this.attributeOwner.setAttributeVisible(true);
  }

  private doHide() {
    this.attributeOwner && this.attributeOwner.setAttributeVisible(false);
  }
}
