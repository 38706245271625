import { Component, ElementRef, Input, OnChanges, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import { PageBuilderGraphicalCopyPasterService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-copy-paster.service';
import { PageBuilderGraphicalDragDropService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-drag-drop.service';
import { PageBuilderGraphicalEventsService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-events.service';
import { CommonToolbarActions } from '@private/pages/page-management/page-builder-graphical/types/common-toolbar-actions';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { PageBuilderEventType } from '@private/pages/page-management/page-builder-graphical/types/page-builder-event-type';
import { PageRow } from '@private/pages/page-management/page-builder-graphical/types/page-row';
import { RowLocation } from '@private/pages/page-management/page-builder-graphical/types/row-location';
import { StyleElementEvent } from '@private/pages/page-management/page-builder-graphical/types/style-element-event';
import { HandleValidationErrorsService } from '@private/services/handle-validation-errors.service';
import { WidgetHelper } from '@shared/helpers/widget.helper';
import { SharedMethods } from '@shared/methods/shared.methods';
import { HideClasses } from '@shared/types/screen.types';
import { ContainerClassEnum } from '@widgets/shared/types/style.types';

@Component({
  selector: 'app-page-row',
  templateUrl: './page-row.component.html',
  styleUrls: ['../../../../../../shared/styles/toolbar.scss', './page-row.component.scss'],
})
export class PageRowComponent implements OnChanges, RowLocation, CommonToolbarActions {
  @Input() sectionIndex: number;
  @Input() row: PageRow;
  @Input() rowIndex: number;
  @Input() page: Page;

  @Input() toolbarShiftInPx: number;
  @Input() marginRight: string;
  @Input() marginLeft: string;
  @Input() paddingLeft: string;
  @Input() borderLeftWidth: string;
  @Input() borderLeftStyle: string;
  @Input() borderLeftColor: string;
  @Input() containerClass: ContainerClassEnum;
  @Input() modalId = '';

  @ViewChild('rowBodyRef', { read: ElementRef, static: true }) rowBodyRef: ElementRef<HTMLDivElement>;

  toolbarPositionLeft: string;

  constructor(
    public readonly copyPasterService: PageBuilderGraphicalCopyPasterService,
    public readonly widgetHelper: WidgetHelper,
    public readonly handleValidationErrorsService: HandleValidationErrorsService,
    private readonly eventsService: PageBuilderGraphicalEventsService,
    public readonly dragDropService: PageBuilderGraphicalDragDropService,
    private renderer: Renderer2,
  ) { }

  private get rowBodyBorderLeftWidthInPx(): number {
    return +SharedMethods.getComputedStylePropertyWithoutUnit(this.rowBodyRef.nativeElement, 'border-left-width');
  }

  private get propertiesAffectingToolbarPosition(): string[] {
    return ['toolbarShiftInPx', 'marginRight', 'marginLeft', 'paddingLeft', 'borderLeftWidth', 'borderLeftStyle', 'borderLeftColor', 'containerClass'];
  }

  private get location(): RowLocation {
    const filteredSections = this.dragDropService.currentSections.filter(section => section.modalId === this.modalId && section.index === this.sectionIndex);
    const sectionIndex = filteredSections[0].innerIndex;
    const { rowIndex } = this;

    return { sectionIndex, rowIndex };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateToolbarPositionIfNeeded(changes);
  }

  style(): void {
    const { row: pageElement } = this;
    this.eventsService.publish<StyleElementEvent>(PageBuilderEventType.styleElement, { pageElement });
  }

  copy(): void {
    this.eventsService.publish<RowLocation>(PageBuilderEventType.copy, this.location);
  }

  paste(): void {
    this.eventsService.publish<RowLocation>(PageBuilderEventType.paste, this.location);
  }

  delete(): void {
    this.eventsService.publish<RowLocation>(PageBuilderEventType.delete, this.location);
  }

  private updateToolbarPositionIfNeeded(changes: SimpleChanges): void {
    if (this.isToolbarPositionUpdateNeeded(changes)) {
      this.toolbarPositionLeft = this.getToolbarLeftShift() + 'px';
    }
  }

  private isToolbarPositionUpdateNeeded(changes: SimpleChanges): boolean {
    return this.propertiesAffectingToolbarPosition.some((property: string) => property in changes);
  }

  private getToolbarLeftShift(): number {
    const rowRowBodyShiftInPx = this.rowBodyRef.nativeElement.getBoundingClientRect().left;
    const { toolbarShiftInPx } = this;

    const relativeToSectionToolbarShiftInPx = rowRowBodyShiftInPx >= toolbarShiftInPx ? 0 : toolbarShiftInPx - rowRowBodyShiftInPx;

    return relativeToSectionToolbarShiftInPx - this.rowBodyBorderLeftWidthInPx;
  }
}
