<ng-container *ngIf="options.areLoaded && isReady">
  <div
    #contentItemToolbarContainer
    *ngFor="let item of area.content.items"
    [class.toolbar-container--disabled]="toolbarContainerDisabled$ | async"
    [class.toolbar-container]="showWidgetSettings"
  >
    <div *ngIf="showWidgetSettings" class="toolbar">
      <ng-container *ngIf="model.advancedModeOpen | async">
        <i
          (click)="onAdvancedModeOpen($event, item)"
          class="bi bi-tools toolbar__icon"
          pRipple
          pTooltip="{{ 'Advanced Mode' | translate }}"
          tooltipPosition="top"
        >
        </i>
        <i (click)="onContentItemDelete($event, item)" class="bi bi-trash toolbar__icon" pRipple
           pTooltip="{{ 'Delete' | translate }}" tooltipPosition="top">
        </i>
      </ng-container>
    </div>

    <div [class.widget]="isWidget(item)">
      <ng-container *ngIf="isAttribute(item)">
        <ng-container
          *ngIf="isBoolean(item)">
          <app-attribute-value
            *ngIf="
              attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode &&
                attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode !== 'DEFAULT';
              else independentCardTemplate
            "
            [artifactDto]="artifact"
            [attributeName]="item.content | getAttributeName : options"
            [attributeSettings]="attributeStyles[item.content]"
            [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
            [isEllipsis]="isEllipsis"
          >
            <app-display-at-boolean
              class="block-container full-size-container"
              [ownerId]="ownerId"
              [attributeId]="item.content"
              [artifact]="artifact"
              [dataType]="options.dataTypes.listMap[options.attributes.listMap[item.content]?.dataTypeId]"
              [selectedVariant]="attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode"
              [doInternalUpdateOfValue]="true"
            >
            </app-display-at-boolean>
          </app-attribute-value>
        </ng-container>

        <ng-container
          *ngIf="isEnum(item)">
          <app-attribute-value
            *ngIf="
              attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode &&
                attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode !== 'DEFAULT';
              else independentCardTemplate
            "
            [artifactDto]="artifact"
            [attributeName]="item.content | getAttributeName : options"
            [attributeSettings]="attributeStyles[item.content]"
            [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
            [isEllipsis]="isEllipsis"
          >
            <app-display-at-enum
              style="display: block; width: fit-content;"
              [ownerId]="ownerId"
              [attributeId]="item.content"
              [attribute]="options.attributes.listMap[item.content]"
              [artifact]="artifact"
              [artifactDto]="artifact"
              [dataType]="options.dataTypes.listMap[options.attributes.listMap[item.content]?.dataTypeId]"
              [enumOptions]="options.dataTypes.listMap[options.attributes.listMap[item.content]?.dataTypeId].values"
              [selectedVariant]="attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode"
              [doInternalUpdateOfValue]="true"
              [isFilterWidget]="true"
            >
            </app-display-at-enum>
          </app-attribute-value>
        </ng-container>

        <ng-container
          *ngIf="artifact && ((item.content | isBaseDataTypeEqualTo : dataType.user : options) || (item.content | isSystemUser))">
          <app-attribute-value
            *ngIf="
              attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode &&
                attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode !== 'DEFAULT';
              else independentCardTemplate
            "
            [artifactDto]="artifact"
            [attributeName]="item.content | getAttributeName : options"
            [attributeSettings]="attributeStyles[item.content]"
            [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
            [isEllipsis]="isEllipsis"
          >
            <app-display-at-user
              class="block-container full-size-container"
              [defaultIconSize]="userIconSize"
              [ownerId]="ownerId"
              [attributeId]="item.content"
              [artifact]="artifact"
              [selectedVariant]="attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode"
              [valueStyles]="attributeStyles[item.content].settings.value.styles"
            >
            </app-display-at-user>
          </app-attribute-value>
        </ng-container>

        <app-attribute-value
          *ngIf="item.content | isSystemDate"
          [artifactDto]="artifact"
          [attributeName]="item.content | getAttributeName : options"
          [attributeSettings]="attributeStyles[item.content]"
          [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
          [isEllipsis]="isEllipsis"
        >
          <app-display-at-system-date
            [formatStyles]="attributeStyles[item.content].settings.value.styles"
            [selectedVariant]="attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode"
            [attrId]="item.content"
            [artifactNew]="artifact"
          ></app-display-at-system-date>
        </app-attribute-value>

        <app-attribute-value
          *ngIf="item.content | isBaseDataTypeEqualTo : dataType.dateTime : options"
          [artifactDto]="artifact"
          [attributeName]="item.content | getAttributeName : options"
          [attributeSettings]="attributeStyles[item.content]"
          [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
          [isEllipsis]="isEllipsis"
        >
          <app-display-at-datetime
            [formatStyles]="attributeStyles[item.content].settings.value.styles"
            [selectedVariant]="attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode"
            [attrId]="item.content"
            [artifactNew]="artifact"
          ></app-display-at-datetime>
        </app-attribute-value>

        <app-attribute-value
          *ngIf="item.content | isBaseDataTypeEqualTo : dataType.date : options"
          [artifactDto]="artifact"
          [attributeName]="item.content | getAttributeName : options"
          [attributeSettings]="attributeStyles[item.content]"
          [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
          [isEllipsis]="isEllipsis"
        >
          <app-display-at-datetime
            [formatStyles]="attributeStyles[item.content].settings.value.styles"
            [selectedVariant]="attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode"
            [attrId]="item.content"
            [artifactNew]="artifact"
            [isDate]="true"
          ></app-display-at-datetime>
        </app-attribute-value>

        <app-attribute-value
          *ngIf="item.content | isBaseDataTypeEqualTo : dataType.file : options"
          [artifactDto]="artifact"
          [attributeName]="item.content | getAttributeName : options"
          [attributeSettings]="attributeStyles[item.content]"
          [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
          [isEllipsis]="isEllipsis"
        >

          <display-at-file
            *ngIf="item.content | isSystemFileAttribute; else singleFile"
            [fileDto]="artifact"
            [layoutVariant]="$any(attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode)"
            [formatStyles]="attributeStyles[item.content].settings.value.styles"
          >
          </display-at-file>

          <ng-template #singleFile>
            <display-at-file
              *ngIf="item.content | isSingleFileAttribute : options; else multipleFile"
              [fileDto]="$any(item.content | getAttributeValue : item.linkDirection : artifact : options | async)[0]"
              [layoutVariant]="$any(attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode)"
              [formatStyles]="attributeStyles[item.content].settings.value.styles"
            >
            </display-at-file>
          </ng-template>

          <ng-template #multipleFile>
            <ul class="file-list">
              <li
                *ngFor="let file of item.content | getAttributeValue : item.linkDirection : artifact : options | async">
                <display-at-file
                  [fileDto]="file"
                  [formatStyles]="attributeStyles[item.content].settings.value.styles"
                  [layoutVariant]="$any(attributeStyles[item.content].settings.value.displayMetadata?.selectedVariantCode) || 'DEFAULT'"
                >
                </display-at-file>
              </li>
            </ul>
          </ng-template>
        </app-attribute-value>

        <app-attribute-value
          *ngIf="item.content | isBaseDataTypeEqualTo : dataType.html : options"
          [artifactDto]="artifact"
          [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
          [attributeName]="item.content | getAttributeName : options"
          [attributeSettings]="attributeStyles[item.content]"
          [isEllipsis]="isEllipsis"
        >
          <div
            [ngStyle]="attributeStyles[item.content].settings.value.styles"
            class="block-container"
            [innerHTML]="
              $any(item.content | getAttributeValue : item.linkDirection : artifact : options | async)
                | replaceRuntimeVariables : artifact
                | async
                | toHtml
                | safeHtml
            "
          ></div>
        </app-attribute-value>

        <app-attribute-value
          *ngIf="item.content | isBaseDataTypeEqualTo : dataType.hyperlink : options"
          [artifactDto]="artifact"
          [attributeIcon]="listItemArtifact ? listItemArtifact[item.content]?.icon : ''"
          [attributeName]="item.content | getAttributeName : options"
          [attributeSettings]="attributeStyles[item.content]"
          [isEllipsis]="isEllipsis"
        >
          <ng-template
            #hyperlinkValue
            [ngTemplateOutletContext]="{ hyperlinkVariable: $any(item.content | getAttributeValue : item.linkDirection : artifact : options | async) }"
            [ngTemplateOutlet]="hyperlinkValue"
            let-hyperlinkValue="hyperlinkVariable"
          >
            <ng-container *ngIf="item.content | isRedirectIcon: attributeStyles; else defaultCase">
              <app-display-hyperlink-icon
                [attr]="hyperlinkValue | getHyperlinkValue"
                [attribute]="getAttribute(item.content)"
                [ownerId]="ownerId"
              ></app-display-hyperlink-icon>
            </ng-container>

            <ng-template #defaultCase>
              <span *ngIf="item.content | isSingleHyperlinkAttribute : options; else multipleHyperlinks"
                    class="table-hyperlink-item" isSingle>
                <a [href]="hyperlinkValue" [ngStyle]="attributeStyles[item.content].settings.value.styles">
                  {{ hyperlinkValue }}
                </a>
              </span>
              <ng-template #multipleHyperlinks>
                <div [innerHTML]="hyperlinkValue | getHyperlinks | toHtml : dataType.hyperlink" [ngStyle]="attributeStyles[item.content].settings.value.styles"></div>
              </ng-template>
            </ng-template>
          </ng-template>

        </app-attribute-value>

        <ng-container
          *ngIf="
            !isEnum(item) &&
            !isBoolean(item) &&
            !(item.content | isSystemUser) &&
            !(item.content | isSystemDate) &&
            !(item.content | isDateTime) &&
            !(item.content | isBaseDataTypeEqualTo : dataType.user : options) &&
            !(item.content | isBaseDataTypeEqualTo : dataType.file : options) &&
            !(item.content | isBaseDataTypeEqualTo : dataType.html : options) &&
            !(item.content | isBaseDataTypeEqualTo : dataType.hyperlink : options) &&
            !(item.content | isBaseDataTypeEqualTo : dataType.dateTime : options) &&
            !(item.content | isBaseDataTypeEqualTo : dataType.date : options)
          "
        >
          <app-attribute-value
            *ngIf="isListMatrixCard && listItemArtifact[item.content]; else independentCardTemplate"
            [artifactDto]="artifact"
            [attributeIcon]="listItemArtifact ? listItemArtifact[item.content]?.icon : ''"
            [attributeName]="listItemArtifact[item.content].label"
            [attributeSettings]="attributeStyles[item.content]"
            [attributeValue]="$any(listItemArtifact[item.content].value) | replaceRuntimeVariables : artifactDto | async"
            [isEllipsis]="isEllipsis"
          >
          </app-attribute-value>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="isLink(item)">
        <app-attribute-value
          *ngIf="isListMatrixCard; else independentCardTemplate"
          [artifactDto]="artifact"
          [attributeName]="listItemArtifact[item.content + item.linkDirection]?.label"
          [attributeSettings]="attributeStyles[item.content]"
          [isEllipsis]="isEllipsis"
        >
          <div
            *ngIf="linkLayoutVariant[this.model.settings.attributeStyles[item.content]?.settings.value?.displayMetadata?.selectedVariantCode] === linkLayoutVariant.SUMMARY_ICON; else defaultMatrix"
            class="icon-variant-container">
            <p-badge [value]="(listItemArtifact[item.content + item.linkDirection]?.value).length" severity="info"
                     styleClass="ml-2 mr-2"></p-badge>
          </div>
          <ng-template #defaultMatrix>
            <ul>
              <li
                *ngFor="let link of listItemArtifact[item.content + item.linkDirection]?.value">
                <a [queryParams]="link.queryParams" [routerLink]="link.url" target="_blank">{{ link.name }}</a>
              </li>
            </ul>
          </ng-template>
        </app-attribute-value>

        <ng-template #independentCardTemplate>
          <app-attribute-value
            [artifactDto]="artifact"
            [attributeName]="item.content | getAttributeName : options"
            [attributeSettings]="attributeStyles[item.content]"
            [isEllipsis]="isEllipsis"
          >
            <div
              *ngIf="linkLayoutVariant[this.model.settings.attributeStyles[item.content]?.settings.value?.displayMetadata?.selectedVariantCode] === linkLayoutVariant.SUMMARY_ICON; else default"
              class="icon-variant-container">
              <p-badge
                [value]="(item.content | getAttributeValue : item.linkDirection : options.artifactOptions.list[0] : options | async).length"
                severity="info"
                styleClass="ml-2 mr-2"></p-badge>
            </div>
            <ng-template #default>
              <ul>
                <li
                  *ngFor="let link of item.content | getAttributeValue : item.linkDirection : options.artifactOptions.list[0] : options | async">
                  <a [queryParams]="link.queryParams" [routerLink]="link.url" target="_blank">{{ link.name }}</a>
                </li>
              </ul>
            </ng-template>
          </app-attribute-value>
        </ng-template>
      </ng-container>

      <app-widget-container
        *ngIf="isWidget(item)"
        [applicationId]="applicationId"
        [disabled]="disabled"
        [isInSidebar]="isInSidebar"
        [isLayoutMode]="true"
        [isParentWidgetClickable]="isParentWidgetClickable"
        [isPreviewMode]="isPreviewMode"
        [widget]="$any(item.content)"
        [isInCardWidget]="true"
        appWidgetContainerStopPropagation
      >
      </app-widget-container>
    </div>

    <ng-template #independentCardTemplate>
      <app-attribute-value
        [artifactDto]="artifact"
        [attributeIcon]="listItemArtifact | getAttributeIcon : item.content : options"
        [attributeName]="item.content | getAttributeName : options"
        [attributeSettings]="attributeStyles[item.content]"
        [attributeValue]="$any(item.content | getAttributeValue : item.linkDirection : artifact : options | async)"
        [isEllipsis]="isEllipsis"
      >
      </app-attribute-value>
    </ng-template>
  </div>
</ng-container>

<p-sidebar
  (onHide)="onAdvancedWidgetSidebarHide()"
  *ngIf="isLayoutMode"
  [dismissible]="true"
  [modal]="true"
  [position]="widgetService.getPositionSettings(advancedWidget?.id).position"
  [fullScreen]="widgetService.getPositionSettings(advancedWidget?.id).fullScreen"
  [style]="!widgetService.getPositionSettings(advancedWidget?.id).fullScreen ? { width: '40em' } : {}"
  [visible]="!!advancedWidget"
  appendTo="body"
>
  <ng-template pTemplate="header">
    <app-sidebar-header [name]="advancedWidget?.code | underscoreToDash"
                        [widgetId]="advancedWidget?.id"></app-sidebar-header>
  </ng-template>

  <div class="layout-wrapper dynamic-float-label">
    <app-widget-container *ngIf="advancedWidget" [applicationId]="applicationId" [widget]="advancedWidget"
                          appWidgetContainerStopPropagation>
    </app-widget-container>
  </div>
</p-sidebar>

<app-display-at-overlay [ownerId]="ownerId"></app-display-at-overlay>
