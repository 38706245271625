import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FileService } from '@private/services/file.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { finalize, take } from 'rxjs';
import { ImagePointerOptions } from './types/image-pointer-options.types';
import { PageStyles } from '@private/pages/page-management/page-builder-graphical/types/page-styles';
import { ImageMethods } from '@shared/methods/image.methods';
import { ApiConfiguration } from '@api/api-configuration';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';

@Component({
  selector: 'app-image-pointer',
  templateUrl: './image-pointer.component.html',
  styleUrl: './image-pointer.component.scss',
})
export class ImagePointerComponent implements OnInit {

  @Input() applicationId: string | null;
  @Input() imageFileId: string;
  @Input() useExternalLink: boolean | undefined;
  @Input() styles: PageStyles | undefined;
  @Input() onlyUpload: boolean;

  @Output() imageUrlChange = new EventEmitter<string>();
  @Output() imageFileIdChange = new EventEmitter<string>();
  @Output() useExternalLinkChange = new EventEmitter<boolean>();
  @Output() stylesChange = new EventEmitter<PageStyles>();

  uploadedFiles: File[] = [];
  isLoadingData = true;
  private url: string | null = null;

  options = new ImagePointerOptions();

  constructor(
    private readonly fileService: FileService,
    private readonly cache: NewCacheService,
    private readonly apiConfiguration: ApiConfiguration,
    private readonly authorizationService: AuthorizationService,
  ) { }

  get imageUrl(): string | null {
    return this.url;
  }

  @Input() set imageUrl(value: string | null) {
    this.url = value;
  }

  ngOnInit() {
    if (this.useExternalLink == undefined) this.useExternalLink = ImageMethods.checkIsExternalUrl(this.url);
  }

  onUseExternalLinkChange(value: boolean) {
    this.useExternalLink = value;
    this.useExternalLinkChange.emit(this.useExternalLink);

    this.imageFileId = this.useExternalLink ? '' : this.imageFileId;
    this.imageFileIdChange.emit(this.imageFileId);

    this.onImageUrlChange('');
  }

  onImageUrlChange(value: string) {
    this.url = value;
    this.imageUrlChange.emit(value);
    this.stylesChange.emit(this.styles);
  }

  async onBasicUpload(event: any): Promise<void> {
    for (const file of event.files) {
      this.uploadedFiles.push(file);
      await this.uploadToServer(file);
    }
  }

  async uploadToServer(file: File): Promise<void> {
    if (!this.applicationId || !file) return;

    this.isLoadingData = true;

    const application = await this.cache.data.applications.getAsync(this.applicationId);
    const defaultFolderId = application.defaultFolderId;

    this.fileService.uploadFileArtifact(file, defaultFolderId)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoadingData = false;
        })
      )
      .subscribe({
        next: fileArtifact => {
          this.imageFileId = fileArtifact.id;
          this.imageFileIdChange.emit(fileArtifact.id);
        },
        error: err => {
          console.error('Error uploading file:', err);
        }
      });
  }

  onChange(): void {
    this.stylesChange.emit(this.styles);
  }
}
