import { Component, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteBaseEvent, AutocompleteEventType } from '@shared/components/context-autocomplete/types/base/autocomplete.types';
import { AUTOCOMPLETE_CONTAINER_CLASS } from '@shared/constants/constants';
import { NewAttribute } from '@shared/types/attribute.types';
import { Listbox, ListboxModule } from 'primeng/listbox';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { AutocompleteService } from '../../services';
import { AttributeAutocompleteInitEvent } from '../../types/attribute-autocomplete.types';
import { AbstractAutocompleteComponent } from '../abstract-autocomplete.component';

@Component({
  selector: 'app-attribute-autocomplete-overlay',
  templateUrl: 'attribute-autocomplete-overlay.component.html',
  styleUrls: ['./attribute-autocomplete-overlay.component.scss', '../autocomplete-common.scss'],
  standalone: true,
  imports: [OverlayPanelModule, ListboxModule, FormsModule, TranslateModule],
})
export class AttributeAutocompleteOverlayComponent extends AbstractAutocompleteComponent<NewAttribute> {
  @ViewChild(OverlayPanel) panel: OverlayPanel;
  @ViewChild(Listbox) listBox: Listbox;

  styleClass: string = AUTOCOMPLETE_CONTAINER_CLASS;

  constructor(contextVariableAutocompleteService: AutocompleteService<NewAttribute>, zone: NgZone) {
    super(contextVariableAutocompleteService, zone);
  }

  protected onOptionSelected(attribute: NewAttribute) {
    this.contextVariableAutocompleteService.notifyAutocompleteSelection(this.ownerId, attribute, this.initEvent);
    this.panel.hide();
  }

  protected onAutocompleteInit(initEvent: AttributeAutocompleteInitEvent) {
    const { variableNamePart, event, target, doFocusFirstItem } = initEvent;
    const attributeNamePrefix = variableNamePart?.toLowerCase();
    // in case attribute name prefix is in format of variable name
    const rawAttributeNamePrefix = attributeNamePrefix?.includes('-') ? attributeNamePrefix.replace('-', ' ') : attributeNamePrefix;
    const shouldCheckVariableFormat = rawAttributeNamePrefix && attributeNamePrefix !== rawAttributeNamePrefix;
    this.initEvent = initEvent;
    this.ownerId = initEvent.ownerId;
    this.options = initEvent.options.filter(
      attr =>
        attr.name.toLowerCase().startsWith(attributeNamePrefix) || (shouldCheckVariableFormat && attr.name.toLowerCase().startsWith(rawAttributeNamePrefix)),
    );
    this.panel.show(event, target);

    if (doFocusFirstItem) {
      this.doFocusFirstItem();
    }
  }

  protected shouldReactToEvent(event: AutocompleteBaseEvent): boolean {
    return event.type === AutocompleteEventType.attribute;
  }
}
