import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { ChipsModule } from 'primeng/chips';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {
  ChipFormatStyles,
  StylesDtoKeys
} from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';
import { FilterRelevantStylesPipe } from '@shared/pipes/styles-pipes/filter-relevant-styles.pipe';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, InputTextareaModule, ArtifactAttributeFormFieldLabelWithIconComponent, ChipsModule, FilterRelevantStylesPipe],
  selector: 'app-artifact-attribute-form-field-hyperlink',
  templateUrl: './artifact-attribute-form-field-hyperlink.component.html',
  styleUrls: ['./artifact-attribute-form-field-hyperlink.component.scss'],
})
export class ArtifactAttributeFormFieldHyperlinkComponent {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;
  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() onChange: () => Promise<void>;
  @Input() onBlur: () => void;
  @Input() folderId: string;
  @Input() columnStylesEvent: RuntimeStateNotification<any> | undefined;

  labelBehaviourEnum = LabelBehaviourEnum;
  relevantStyleKeys: StylesDtoKeys[] = [];

  constructor(public readonly h: DataTypeHelper) {}

  ngOnInit(): void {
    this.setCssKeys();
  }

  private setCssKeys() {
    this.relevantStyleKeys = [...ChipFormatStyles];
  }
}
