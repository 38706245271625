/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { applicationControllerCount } from '../fn/tenant-application/application-controller-count';
import { ApplicationControllerCount$Params } from '../fn/tenant-application/application-controller-count';
import { applicationControllerCreate } from '../fn/tenant-application/application-controller-create';
import { ApplicationControllerCreate$Params } from '../fn/tenant-application/application-controller-create';
import { applicationControllerDelete } from '../fn/tenant-application/application-controller-delete';
import { ApplicationControllerDelete$Params } from '../fn/tenant-application/application-controller-delete';
import { applicationControllerExport } from '../fn/tenant-application/application-controller-export';
import { ApplicationControllerExport$Params } from '../fn/tenant-application/application-controller-export';
import { applicationControllerGet } from '../fn/tenant-application/application-controller-get';
import { ApplicationControllerGet$Params } from '../fn/tenant-application/application-controller-get';
import { applicationControllerList } from '../fn/tenant-application/application-controller-list';
import { ApplicationControllerList$Params } from '../fn/tenant-application/application-controller-list';
import { applicationControllerUpdate } from '../fn/tenant-application/application-controller-update';
import { ApplicationControllerUpdate$Params } from '../fn/tenant-application/application-controller-update';
import { ApplicationListResponseDto } from '../models/application-list-response-dto';
import { ApplicationResponseDto } from '../models/application-response-dto';
import { BaseCountResponseDto } from '../models/base-count-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantApplicationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `applicationControllerCount()` */
  static readonly ApplicationControllerCountPath = '/api/tenant/application/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationControllerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerCount$Response(params?: ApplicationControllerCount$Params, context?: HttpContext): Observable<StrictHttpResponse<BaseCountResponseDto>> {
    return applicationControllerCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationControllerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerCount(params?: ApplicationControllerCount$Params, context?: HttpContext): Observable<BaseCountResponseDto> {
    return this.applicationControllerCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<BaseCountResponseDto>): BaseCountResponseDto => r.body)
    );
  }

  /** Path part for operation `applicationControllerExport()` */
  static readonly ApplicationControllerExportPath = '/api/tenant/application/export';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationControllerExport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationControllerExport$Response(params: ApplicationControllerExport$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return applicationControllerExport(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationControllerExport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationControllerExport(params: ApplicationControllerExport$Params, context?: HttpContext): Observable<Blob> {
    return this.applicationControllerExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `applicationControllerGet()` */
  static readonly ApplicationControllerGetPath = '/api/tenant/application/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerGet$Response(params: ApplicationControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationResponseDto>> {
    return applicationControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerGet(params: ApplicationControllerGet$Params, context?: HttpContext): Observable<ApplicationResponseDto> {
    return this.applicationControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationResponseDto>): ApplicationResponseDto => r.body)
    );
  }

  /** Path part for operation `applicationControllerDelete()` */
  static readonly ApplicationControllerDeletePath = '/api/tenant/application/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerDelete$Response(params: ApplicationControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationResponseDto>> {
    return applicationControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerDelete(params: ApplicationControllerDelete$Params, context?: HttpContext): Observable<ApplicationResponseDto> {
    return this.applicationControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationResponseDto>): ApplicationResponseDto => r.body)
    );
  }

  /** Path part for operation `applicationControllerList()` */
  static readonly ApplicationControllerListPath = '/api/tenant/application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerList$Response(params?: ApplicationControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationListResponseDto>> {
    return applicationControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationControllerList(params?: ApplicationControllerList$Params, context?: HttpContext): Observable<ApplicationListResponseDto> {
    return this.applicationControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationListResponseDto>): ApplicationListResponseDto => r.body)
    );
  }

  /** Path part for operation `applicationControllerUpdate()` */
  static readonly ApplicationControllerUpdatePath = '/api/tenant/application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationControllerUpdate$Response(params: ApplicationControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationResponseDto>> {
    return applicationControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationControllerUpdate(params: ApplicationControllerUpdate$Params, context?: HttpContext): Observable<ApplicationResponseDto> {
    return this.applicationControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationResponseDto>): ApplicationResponseDto => r.body)
    );
  }

  /** Path part for operation `applicationControllerCreate()` */
  static readonly ApplicationControllerCreatePath = '/api/tenant/application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationControllerCreate$Response(params: ApplicationControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationResponseDto>> {
    return applicationControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  applicationControllerCreate(params: ApplicationControllerCreate$Params, context?: HttpContext): Observable<ApplicationResponseDto> {
    return this.applicationControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationResponseDto>): ApplicationResponseDto => r.body)
    );
  }

}
