import { NgClass } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonDirective } from 'primeng/button';
import { MultiSelectModule } from 'primeng/multiselect';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';

import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { SessionFlowScopes, SessionType } from '@shared/types/session-flow.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { ObjectUtil } from '@shared/utils/object.util';

import { CONST_SESSION_SCOPES_KEY, CONST_SESSION_TYPE_KEY, SessionFlowListService } from '../../services/session-flow-list.service';
import { PrintSessionFlowToTablePipe } from './pipes/print-session-flow-to-table.pipe';

@Component({
  standalone: true,
  imports: [
    NgClass,
    RouterModule,
    FormsModule,
    TranslateModule,
    // PRIMENG
    ButtonDirective,
    TableModule,
    TooltipModule,
    MultiSelectModule,
    // CUSTOM
    ElvisSharedModule,
    PrintSessionFlowToTablePipe,
  ],
  selector: 'app-session-flow-list-table',
  templateUrl: './session-flow-list-table.component.html',
  styleUrls: ['./session-flow-list-table.component.scss'],
})
export class SessionFlowListTableComponent extends CoreListComponent<any> {
  @ViewChild('table') table: Table;
  @Input() columns: TableColumn[];
  typeOptions: SelectOption<string, SessionType>[];
  scopesOptions: SelectOption<string, SessionFlowScopes>[];
  SESSION_TYPE_KEY = CONST_SESSION_TYPE_KEY;
  SESSION_SCOPES_KEY = CONST_SESSION_SCOPES_KEY;

  constructor(
    public readonly objectU: ObjectUtil,
    public readonly sessionFlowListService: SessionFlowListService,
  ) {
    super();
  }

  protected onInit(): void {
    super.onInit();

    this.typeOptions = Object.entries(SessionType).map<SelectOption<string, SessionType>>(([key, value]: [string, SessionType]) => {
      const label = key.charAt(0).toUpperCase() + key.slice(1);
      return new SelectOption(label, value);
    });

    this.scopesOptions = Object.entries(SessionFlowScopes).map<SelectOption<string, SessionFlowScopes>>(([key, value]: [string, SessionFlowScopes]) => {
      const label = key.toUpperCase();
      return new SelectOption(label, value);
    });
  }
}
