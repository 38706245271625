import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { AttributeFormatSettings, FormatStyles } from '@widgets/shared/types/attribute-format-settings.types';
import { BorderStylesEnum, BorderWidthEnum, TextHorizontalAlignEnum } from '@widgets/shared/types/style.types';
import { FormFieldCommunicatorService } from '@shared/services/form-field-communicator.service';

@Component({
  selector: 'app-attribute-format-value-styles-form',
  templateUrl: './attribute-format-value-styles-form.component.html',
  styleUrls: ['./attribute-format-value-styles-form.component.scss'],
})
export class AttributeFormatValueStylesFormComponent {
  @Input() formatSettings: AttributeFormatSettings;
  @Input() attribute: NewAttribute;
  @Input() dataType: NewDataType;
  @Input() areEditableStylesDisabled = false;
  @Input() showPrefixSuffixSettings = false;
  @Input() showDecimalPlacesSettings = false;

  @Output() valueAlignmentChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() styleChange: EventEmitter<void> = new EventEmitter<void>();
  @Output() editEnabled: EventEmitter<void> = new EventEmitter<void>();
  @Output() editDisabled: EventEmitter<void> = new EventEmitter<void>();

  readonly borderStyleOptions = GetSelectOptionsFromEnum(BorderStylesEnum);
  readonly textHorizontalAlignOptions = GetSelectOptionsFromEnum(TextHorizontalAlignEnum);
  readonly borderWidthOptions = GetSelectOptionsFromEnum(BorderWidthEnum);

  constructor(private readonly formFieldCommunicator: FormFieldCommunicatorService) {}

  onEditabilityChange(isEditable: boolean): void {
    if (isEditable) {
      this.editEnabled.emit();
    } else {
      this.editDisabled.emit();
    }
  }

  onCounterOptionChange(): void {
    this.formFieldCommunicator.notifyFormCounter(Boolean(this.formatSettings.counterOptionValue));
  }

  onCustomPlaceholderUseChange(): void {
    this.formatSettings.value.placeholder = '';
  }

  onStylesChange(fontStyles: FormatStyles) {
    this.formatSettings.value.styles = new FormatStyles(fontStyles);
    this.styleChange.emit();
  }

  onMinDecimalPlacesChange(value: any): void {
    if (value === null) this.formatSettings.value.fieldStyles.minDecimalPlaces = undefined;
    this.styleChange.emit();
  }

  onMaxDecimalPlacesChange(value: any): void {
    if (value === null) this.formatSettings.value.fieldStyles.maxDecimalPlaces = undefined;
    this.styleChange.emit();
  }
}
