import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { WorkflowOwnerType, WorkflowType } from '@workflows/types';

export class WorkflowListModel {
  options = new WorkflowListOptions();
  columns: TableColumn[];
}

export class WorkflowListOptions {
  users: ListContainer<ArtifactLinkResponseDto> = new ListContainer<ArtifactLinkResponseDto>();
  userOptions: SelectOption<string, string>[] = [];
  workflowTypeOptions: SelectOption<string, WorkflowType>[] = [];
  workflowOwnerTypeOptions: SelectOption<string, WorkflowOwnerType>[] = [];
}
