<div class="grid">
  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <p-multiSelect [options]="scopesOptions" [(ngModel)]="sessionFlow.typeData.scopes" [disabled]="sessionFlow.id ? true : false" id="scopes"></p-multiSelect>
      <label class="dynamic-label-text" for="scopes" translate>Scopes</label>
    </span>
  </div>

  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <input [(ngModel)]="sessionFlow.typeData.clientId" id="type-clientId" pInputText type="text" />
      <label class="dynamic-label-text" for="type-clientId" translate>Client Id</label>
    </span>
  </div>

  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <input [(ngModel)]="sessionFlow.typeData.authorizeUrl" id="type-authorizeUrl" pInputText type="text" />
      <label class="dynamic-label-text" for="type-authorizeUrl" translate>Authorize URL</label>
    </span>
  </div>

  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <input [(ngModel)]="sessionFlow.typeData.revokeUrl" id="type-revokeUrl" pInputText type="text" />
      <label class="dynamic-label-text" for="type-revokeUrl" translate>Revoke URL</label>
    </span>
  </div>

  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <input [(ngModel)]="sessionFlow.typeData.tokenUrl" id="type-tokenUrl" pInputText type="text" />
      <label class="dynamic-label-text" for="type-tokenUrl" translate>Token URL</label>
    </span>
  </div>

  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <input [(ngModel)]="sessionFlow.typeData.issuer" id="type-issuer" pInputText type="text" />
      <label class="dynamic-label-text" for="type-issuer" translate>Issuer</label>
    </span>
  </div>
</div>
