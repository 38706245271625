import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ApiConfiguration } from '@api/api-configuration';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { TranslateModule } from '@ngx-translate/core';
import { LayoutService } from '@private/pages/page-management/page-builder-graphical/services/layout.service';
import { PageBuilderGraphicalEventsService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-events.service';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { AdminTopBarModel } from '@shared/components/admin-topbar/types/app.top-bar.types';
import { GlobalConstants } from '@shared/constants/global.constants';
import { ImageMethods } from '@shared/methods/image.methods';
import { AdminTopBarCompatible } from '@shared/types/admin-top-bar.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { AppInterface } from '@shared/types/shared.types';
import { PAGE_BUILDER_URL } from '@widgets/shared/constants/widget.constants';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthorizationService } from '../../services/authorization/authorization.service';

@Component({
  selector: 'app-admin-top-bar',
  templateUrl: './admin-top-bar.component.html',
  styleUrls: ['./admin-top-bar.component.scss'],
  providers: [PageBuilderGraphicalEventsService, LayoutService],
})
export class AdminTopBarComponent<T extends AdminTopBarCompatible> implements OnInit, OnDestroy {
  @Input() app: T;
  @Input() appInterface: AppInterface;
  appInterfaceEnum = AppInterface;

  breadcrumb$: Subscription;
  user$: Subscription | null;
  router$: Subscription | null;
  m = new AdminTopBarModel();
  headerIsVisible = true;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly authorizationService: AuthorizationService,
    private readonly confirmationService: ConfirmationService,
    private readonly layoutService: LayoutService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly cache: NewCacheService,
    private readonly router: Router,
    private readonly apiConfiguration: ApiConfiguration,
  ) {
    this.breadcrumb$ = this.breadcrumbService.itemsHandler.subscribe(res => (this.m.breadcrumbs = res));
  }

  ngOnInit(): void {
    this.cache.initCache();

    if (this.cache.isLoaded) this.setUserWithPictureToModel();
    else this.cache.isLoaded$.subscribe(() => this.setUserWithPictureToModel());

    this.router$ = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && event.url.includes(PAGE_BUILDER_URL)) {
        this.headerIsVisible = true;
      }
    });
    this.layoutService.init();
  }

  async logout(): Promise<void> {
    await this.authorizationService.logoutWithConfirmation(this.confirmationService);
  }

  togglePageBuilderHeader(): void {
    this.headerIsVisible = !this.headerIsVisible;
    this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.togglePageBuilderHeaderVisible, this.headerIsVisible);
  }

  ngOnDestroy(): void {
    this.breadcrumb$ && this.breadcrumb$.unsubscribe();
    this.user$ && this.user$.unsubscribe();
    this.router$ && this.router$.unsubscribe();
  }

  private setUserWithPictureToModel(): void {
    const user = this.cache.userProfile.value as ArtifactLinkResponseDto;
    const userPictureId = user?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.profilePictureAttributeId)]?.value as string;

    this.m.userName = (user?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) || '';
    this.m.userPicture = userPictureId
      ? ImageMethods.getUrlFromImageFileId(userPictureId, this.apiConfiguration.rootUrl, this.authorizationService.getToken)
      : '';
  }
}

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule],
  declarations: [AdminTopBarComponent],
  exports: [AdminTopBarComponent],
})
export class AdminTopBarModule {}
