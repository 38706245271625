import { Injectable } from '@angular/core';
import { PageBuilderGraphicalCopyPasterHelperService } from '@private/pages/page-management/page-builder-graphical/services/page-builder-graphical-copy-paster-helper.service';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PageElement } from '@private/pages/page-management/page-builder-graphical/types/page-element';
import { AnnouncementService } from '@shared/services/announcement.service';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageBuilderGraphicalCopyPasterService {
  private runtimeStateNotificationHandlers: Record<string, (event: RuntimeStateNotification) => void>;
  private eventFromPageBuilder = 'pageBuilder';

  constructor(
    private readonly helperService: PageBuilderGraphicalCopyPasterHelperService,
    private readonly announcement: AnnouncementService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    this.initRuntimeStateNotificationHandlers();
    this.initSubscriptions();
  }

  get hasSectionCopy$(): Observable<boolean> {
    return this.helperService.hasSectionCopy$;
  }

  get hasRowCopy$(): Observable<boolean> {
    return this.helperService.hasRowCopy$;
  }

  get hasBlockPartCopy$(): Observable<boolean> {
    return this.helperService.hasBlockPartCopy$;
  }

  copyLayout(elementToCopy: PageElement, fireEvent = true): void {
    const method = this.helperService.copyMethods[elementToCopy.pageElementType];

    if (fireEvent && elementToCopy.constructor.name === 'PageBlockPart') {
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<PageBlockPart>(RuntimeStateNotificationEnum.copyWidget, elementToCopy as PageBlockPart, null, this.eventFromPageBuilder),
      );
    }

    if (method) {
      method(elementToCopy);
    } else {
      this.announcement.error(`Copy action for "${elementToCopy.constructor.name}" is not implemented yet.`);
    }
  }

  pasteLayout(elementToReplace: PageElement): void {
    const method = this.helperService.pasteMethods[elementToReplace.pageElementType];

    if (method) {
      method(elementToReplace);
    } else {
      this.announcement.error(`Paste action for "${elementToReplace.constructor.name}" is not implemented yet.`);
    }
  }

  private initRuntimeStateNotificationHandlers(): void {
    this.runtimeStateNotificationHandlers = {};

    this.runtimeStateNotificationHandlers[RuntimeStateNotificationEnum.copyWidget] = (event: RuntimeStateNotification<string>) => {
      event.extras !== this.eventFromPageBuilder && this.copyLayout(event.data as any, false);
    };
  }

  private initSubscriptions(): void {
    this.runtimeStateNotificationService.events$.subscribe((event: RuntimeStateNotification<unknown>) => {
      if (this.runtimeStateNotificationHandlers[event.type]) {
        this.runtimeStateNotificationHandlers[event.type](event);
      }
    });
  }
}
