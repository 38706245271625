import { Injectable } from '@angular/core';
import { BaseSsoService } from '@shared/services/sso/base.sso.service';

@Injectable()
export class GoogleSsoService extends BaseSsoService {
  tooltip = 'Login with Google Account';
  content = '<span class="p-button-icon pi pi-google" aria-hidden="true"></span>';

  async onClickHandler(redirectAfterLogin: () => void): Promise<void> {
    await super.clickHandler(redirectAfterLogin, 'google');
  }
}
