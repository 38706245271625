import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { ArtifactFormatModuleDataResponseDto } from '@api/models/artifact-format-module-data-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Constants, ID_KEY, WINDOW_TARGET_BLANK, WINDOW_TARGET_SELF } from '@shared/constants/constants';
import { ConvertToServerDatetime } from '@shared/methods/date.methods';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { TimeFilterRuleType } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter-options.types';
import { DateTimeFilter } from '@widgets/shared/components/artifact-filters/components/date-time-filter/types/date-time-filter.types';
import { TextFilter } from '@widgets/shared/components/artifact-filters/components/text-filter/types/text-filter.types';
import { FilterTypeDetectionService } from '@widgets/shared/components/artifact-filters/services/filter-type-detection.service';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { FILTER_WIDGET_NULL_IN_URL } from '@widgets/shared/constants/widget.constants';
import { ClickActionSettings } from '@widgets/shared/types/click-action-settings';
import { ArtifactListItemClickAction } from '../types/artifact-list-item-click-action';

interface NavigationParams {
  commands: any[];
  extras?: NavigationExtras;
  openInNewTab: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ArtifactClickHandlerService {
  private artifactTypes: ListContainer<NewArtifactType> = new ListContainer<NewArtifactType>();

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly cache: NewCacheService,
    private readonly announcement: AnnouncementService,
    private readonly attributeUtil: AttributeUtil,
    private readonly filterTypeDetectionService: FilterTypeDetectionService,
    private readonly filterMetaDataUtil: FilterMetadataUtil,
  ) {
    this.cache.data.artifactTypes.subscribe(artifactTypes => {
      this.artifactTypes.setList(
        (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
        ID_KEY,
      );
    });
  }

  async handleArtifactClickIfNeeded(artifact: ArtifactResponseDto | null, settings: ClickActionSettings, queryParams: Params): Promise<void> {
    if (!settings.isHandled) {
      return;
    }

    await {
      [ArtifactListItemClickAction.addToLink]: async () => await this.addIdToLink(artifact, settings, queryParams),
      [ArtifactListItemClickAction.goToPage]: async () => await this.goToSelectedPage(artifact, settings),
      [ArtifactListItemClickAction.goToExternalPage]: async () => await this.goToExternalPage(artifact, settings),
      [ArtifactListItemClickAction.goToDefaultArtifactPage]: async () => await this.goToDefaultArtifactPage(artifact, settings),
      [ArtifactListItemClickAction.selectItem]: async () => await this.handleItemClick(),
      [ArtifactListItemClickAction.doNothing]: () => {},
    }[settings.actionType]();
  }

  async removeEmittingKeyFromUrl(queryParams: Params, emittingKey: string): Promise<void> {
    await this.router.navigate([], {
      queryParams: { ...queryParams, [emittingKey]: null },
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  async openPage({ openInNewTab, commands, extras }: NavigationParams, externalPage?: boolean): Promise<void> {
    if (openInNewTab || externalPage) {
      const url = this.createUrl(commands, extras, externalPage);
      const target = openInNewTab ? WINDOW_TARGET_BLANK : WINDOW_TARGET_SELF;
      window.open(url, target, 'noopener');
    } else {
      await this.router.navigate(commands, extras);
    }
  }

  private async addIdToLink(artifact: ArtifactResponseDto | null, settings: ClickActionSettings, queryParams: Params): Promise<void> {
    if (!artifact) {
      await this.announcement.warn('The artifact whose ID you want to add is missing');

      return;
    }

    await this.router.navigate([], {
      queryParams: this.getQueryParamsForArtifactClick(artifact, queryParams, settings),
      relativeTo: this.route,
      queryParamsHandling: 'merge',
    });
  }

  private async goToSelectedPage(artifact: ArtifactResponseDto | null, settings: ClickActionSettings): Promise<void> {
    if (!settings.selectedPage) {
      await this.announcement.warn('Page to go is not selected');
      return;
    }

    const navigationParams = await this.getSelectedPageNavigationParams(artifact, settings);
    await this.openPage(navigationParams);
  }

  private async goToExternalPage(artifact: ArtifactResponseDto | null, settings: ClickActionSettings): Promise<void> {
    if (!settings.externalPage) {
      return await this.announcement.warn('Page to go is not entered');
    }

    await this.openPage(this.getExternalPageNavigationParams(artifact, settings), true);
  }

  private async goToDefaultArtifactPage(artifact: ArtifactResponseDto | null, settings: ClickActionSettings): Promise<void> {
    if (!artifact) {
      await this.announcement.warn('Artifact for navigation is absent');
      return;
    }

    const navigationParams = await this.getDefaultArtifactPageNavigationParams(artifact, settings);
    await this.openPage(navigationParams);
  }

  private async handleItemClick(): Promise<void> {
    // TODO: handle select action
  }

  private getQueryParamsForArtifactClick(artifact: ArtifactResponseDto | null, queryParams: Params, settings: ClickActionSettings): Params {
    if (!artifact) {
      return queryParams;
    }

    const params = { ...queryParams };

    if (settings.emittingKey.length) {
      params[settings.emittingKey] = artifact.id;
    }

    if (settings.shouldEmitFolderKey && !!settings.emittingFolderKey) {
      params[settings.emittingFolderKey] = (artifact.formatData as ArtifactFormatModuleDataResponseDto).folderId;
    }
    return params;
  }

  private async getDefaultArtifactPageNavigationParams(artifact: ArtifactResponseDto, settings: ClickActionSettings): Promise<NavigationParams> {
    const { artifactTypeId } = artifact;
    const { openInNewTab } = settings;
    const { defaultPageId } = this.artifactTypes.listMap[artifactTypeId];
    const queryParams = this.getQueryParamsForArtifactClick(artifact, {}, settings);
    const page = await this.cache.data.pages.getAsync(defaultPageId);

    return {
      commands: [defaultPageId],
      extras: { queryParams, skipLocationChange: !!page?.pageParameters },
      openInNewTab,
    };
  }

  private async getSelectedPageNavigationParams(artifact: ArtifactResponseDto | null, settings: ClickActionSettings): Promise<NavigationParams> {
    const { selectedPage, openInNewTab, useAlias } = settings;
    const queryParams = this.getQueryParamsForArtifactClick(artifact, {}, settings);
    const page = await this.cache.data.pages.getAsync(selectedPage || '');

    settings.filtersForQueryParams.forEach((filter: ArtifactFilter) => {
      const attributeName = filter.attribute instanceof DirectionalLinkType ? filter.attribute.label : filter.attribute?.name || '';
      const key = this.attributeUtil.getUrlQueryAttributeName(attributeName);
      const value = this.getQueryParamValue(filter);

      if (key && value) {
        queryParams[key] = value;
      }
    });

    return {
      commands: [useAlias ? page.alias : selectedPage],
      extras: { queryParams, skipLocationChange: !!page?.pageParameters },
      openInNewTab,
    };
  }

  private getExternalPageNavigationParams(artifact: ArtifactResponseDto | null, settings: ClickActionSettings): NavigationParams {
    const { externalPage, openInNewTab } = settings;
    const queryParams = this.getQueryParamsForArtifactClick(artifact, {}, settings);

    return {
      commands: [externalPage],
      extras: { queryParams },
      openInNewTab,
    };
  }

  private createUrl(commands: any[], extras: NavigationExtras | undefined, externalUrl?: boolean): string {
    let url = this.router.createUrlTree(commands, extras).toString();

    if (externalUrl) {
      if (url.charAt(0) === '/') {
        url = url.slice(1);
      }
      if (url.match(/http:\/[^\/]/)) {
        url = url.replace(/http:\//, 'http://');
      }
      if (url.match(/https:\/[^\/]/)) {
        url = url.replace(/https:\//, 'https://');
      }
    }

    return url;
  }

  private getQueryParamValue(filter: ArtifactFilter): string {
    if (filter.dataType?.isEnum) {
      return filter.value.selectedEnumValues.join(',');
    }

    if (this.isSystemDateFilter(filter) || this.isDateFilter(filter)) {
      return this.getUrlValueForDate(filter);
    }

    if (this.isBooleanFilter(filter)) {
      return this.getUrlValueForBoolean(filter.value);
    }

    if (this.isTextFilter(filter) || this.filterTypeDetectionService.isFolderPath(filter)) {
      return this.getUrlValueForText(filter);
    }

    if (this.filterTypeDetectionService.isUser(filter)) {
      return this.getUrlValueForUser(filter);
    }

    return '';
  }

  private getUrlValueForDate(filter: ArtifactFilter): string {
    const rule = (filter.value as DateTimeFilter)?.ruleTypes[0];
    const rangeTypes = Object.keys(DateRangeFilterEnum);
    if ((!rule || !rule.value) && !rangeTypes.includes(rule.ruleType)) {
      return '';
    }

    const ruleType = rule.ruleType;

    if (this.isCalendarType(ruleType)) {
      const date = rule.value instanceof Date ? rule.value : new Date(rule.value as any);
      return `${ruleType},${ConvertToServerDatetime(date)}`;
    } else {
      if (this.filterMetaDataUtil.isFilterNumeric(ruleType as DateRangeFilterEnum)) {
        return `${ruleType},${rule.value}`;
      }
    }

    return ruleType;
  }

  private isSystemDateFilter(filter: ArtifactFilter): boolean {
    return !!filter.attribute && [NonAttributeKeys.CREATED_ON_ID, NonAttributeKeys.UPDATED_ON_ID].includes(filter.attributeId);
  }

  private isDateFilter(filter: ArtifactFilter): boolean {
    const dataType = filter.dataType;

    return !!dataType && dataType.kind === DataTypeKind.simple && [BaseDataType.date, BaseDataType.dateTime].includes(dataType?.baseDataType as BaseDataType);
  }

  private isBooleanFilter(filter: ArtifactFilter): boolean {
    return filter.dataType?.baseDataType === BaseDataType.boolean;
  }

  private isCalendarType(filterType: DateFilterEnum | DateRangeFilterEnum | TimeFilterRuleType): boolean {
    const { dateIs, dateIsNot, dateAfter, dateBefore, dateAfterOrEqualTo, dateBeforeOrEqualTo } = DateFilterEnum;
    return [dateIs, dateIsNot, dateAfter, dateBefore, dateAfterOrEqualTo, dateBeforeOrEqualTo].includes(filterType as DateFilterEnum);
  }

  private getUrlValueForBoolean(value: string): string {
    return value === null || value === '' ? FILTER_WIDGET_NULL_IN_URL : value;
  }

  private isTextFilter(filter: ArtifactFilter): boolean {
    return filter.dataType?.baseDataType === BaseDataType.text;
  }

  private getUrlValueForText(filter: ArtifactFilter): string {
    return (filter.value as TextFilter).ruleTypes.map(({ value }) => value).join(',');
  }

  private getUrlValueForUser(filter: ArtifactFilter): string {
    return filter.value.map(({ value }: SelectOption<string, string>) => value).join(',');
  }
}
