import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { JobsTableComponent } from '@private/pages/jobs-management/jobs/components/jobs-table/jobs-table.component';
import { JobsValueToTablePipe } from '@private/pages/jobs-management/jobs/pipes/jobs-value-to-table.pipe';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { JobsComponent } from './jobs/jobs.component';

const components = [JobsComponent, JobsTableComponent];

@NgModule({
  declarations: [...components, JobsValueToTablePipe],
  imports: [
    CommonModule,
    TranslateModule,
    ElvisSharedModule,
    TableModule,
    MultiSelectModule,
    TooltipModule,
    CommonPipesModule,
    FileUploadModule,
    DialogModule,
    FormsModule,
  ],
  exports: [...components],
})
export class JobsManagementModule {}
