import { NumberWidgetOptions } from '@widgets/number-widget/types/number-widget-options.types';
import { NumberWidgetSelected, NumberWidgetSelectedDto } from '@widgets/number-widget/types/number-widget-selected.types';
import { NumberWidgetSettings, NumberWidgetSettingsDto } from '@widgets/number-widget/types/number-widget-settings.types';
import { NumberWidgetStyles } from '@widgets/number-widget/types/number-widget-styles.types';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { Params } from '@angular/router';
import { ArtifactSort } from '@widgets/shared/components/artifact-sorts/types/artifact-sort';
import { ArtifactSortDto } from '@widgets/shared/components/artifact-sorts/types/artifact-sort-dto';

export class NumberWidgetValue {
  constructor(public model: NumberWidgetModel = new NumberWidgetModel()) {}
}

export class NumberWidgetModel {
  options = new NumberWidgetOptions();
  selected = new NumberWidgetSelected();
  settings = new NumberWidgetSettings();
  styles = new NumberWidgetStyles();
  restrictions: Record<string, ArtifactTypeLinkRestriction[]> | null = null;
  counter: number | string;
  badgeId: string;
  folderId: string | null;
  artifactId: string | null;
  countableAttributeId: string | null;
  prevFolderId: string | null;
  prevArtifactId: string | null;
  currentParams: Params;
  sorts: ArtifactSort[] = [];

  constructor(dto?: NumberWidgetModelDto, options?: ClickActionSettingsOptions) {
    dto && this.fromDto(dto, options!);
  }

  toServer(): NumberWidgetModelDto {
    const dto: NumberWidgetModelDto = {
      selected: this.selected.toServer(),
      settings: this.settings.toServer(),
      styles: { ...this.styles },
    };
    this.sorts.length && (dto.sorts = this.sorts.map((sort: ArtifactSort) => sort.toServer()));

    return dto;
  }

  fromDto(dto: NumberWidgetModelDto, options: ClickActionSettingsOptions): void {
    dto.selected && (this.selected = new NumberWidgetSelected(dto.selected));
    dto.settings && (this.settings = new NumberWidgetSettings(dto.settings, options));
    dto.styles && (this.styles = new NumberWidgetStyles(dto.styles));
    dto.sorts?.length && (this.sorts = dto.sorts.map((sortDto: ArtifactSortDto) => ArtifactSort.fromDtoAndOptions(sortDto, options.attributes)));
  }
}

export interface NumberWidgetModelDto {
  selected: NumberWidgetSelectedDto;
  settings: NumberWidgetSettingsDto;
  styles: NumberWidgetStyles;
  sorts?: ArtifactSortDto[];
}
