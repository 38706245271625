import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { ConditionDateFilterType } from '@workflows/types/conditions/rule-condition';

@Pipe({
  name: 'getDateOperationTypes',
  standalone: true,
})
export class GetDateOperationTypesPipe implements PipeTransform {
  transform(dateFilterEnum = DateFilterEnum, dateRangeFilterEnum = DateRangeFilterEnum): ConditionDateFilterType[] {
    return [...Object.values(dateFilterEnum), ...Object.values(dateRangeFilterEnum)];
  }
}
