<p-overlayPanel appendTo="body">
  <ng-template pTemplate>
    @if (options.length) {
      <p-listbox
        [listStyle]="{ height: '200px', width: '250px' }"
        [options]="options"
        dataKey="dataKey"
        optionLabel="name"
        [ngModel]="selectedOption"
        styleClass="{{ styleClass }}"
        (onClick)="onOptionSelected($event.option)"
      >
        <ng-template let-option pTemplate="item">
          <span>{{ option.name }}</span>
        </ng-template>
      </p-listbox>
    } @else {
      <div class="no-results">{{ 'No results' | translate }}</div>
    }
  </ng-template>
</p-overlayPanel>
