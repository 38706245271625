import { Injectable } from '@angular/core';
import { CardWidgetTypeResponseDto } from '@api/models/card-widget-type-response-dto';
import { PageBlockPartWidgetResponseDto } from '@api/models/page-block-part-widget-response-dto';
import { TenantTemplateService } from '@api/services/tenant-template.service';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { ID_KEY } from '@shared/constants/constants';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { CardWidgetDto } from '@widgets/card-widget/types/card-widget-dto';
import { CardWidgetModel, CardWidgetModelOptions } from '@widgets/card-widget/types/card-widget-model';
import { ListWidgetOptions } from '@widgets/shared/components/artifact-list-table/types/list-widget-options.types';
import { cloneDeep } from 'lodash';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DisplayAtTemplateService {
  templatesMap: Record<string, Observable<BlockPartWidget>> = {};

  constructor(
    private readonly tenantTemplateService: TenantTemplateService,
    private readonly tenantWidgetService: TenantWidgetService,
  ) {}

  getTemplateForArtifactType$(artifactType: NewArtifactType, options: CardWidgetModelOptions): Observable<BlockPartWidget> {
    if (!this.templatesMap[artifactType.id]) {
      const defaultCardWidgetTemplateId = artifactType.defaultWidgets.cardWidgetTemplateId;
      if (!defaultCardWidgetTemplateId) return EMPTY;

      this.templatesMap[artifactType.id] = this.tenantTemplateService.templateControllerGet({ id: defaultCardWidgetTemplateId }).pipe(
        switchMap(templateDto => {
          const widgetId = (templateDto.template as PageBlockPartWidgetResponseDto).widgetId;
          return this.tenantWidgetService.widgetControllerGet({ id: widgetId! });
        }),
        map(widgetDto => this.initCardWidgetFromDto(widgetDto as CardWidgetTypeResponseDto, options)),
        shareReplay(),
      );
    }
    return this.templatesMap[artifactType.id];
  }

  private initCardWidgetFromDto(widgetDto: CardWidgetTypeResponseDto, options: any): BlockPartWidget<CardWidgetDto> {
    const widget = new BlockPartWidget(cloneDeep(widgetDto));
    const cardWidgetModelDto = { ...widget.value.model };
    const model = CardWidgetModel.fromDtoAndOptions(cardWidgetModelDto, options);
    this.setCardWidgetOptions(model, options);
    widget.value = { model };
    return widget;
  }

  private setCardWidgetOptions(model: CardWidgetModel, options: ListWidgetOptions): void {
    model.options.attributes.setList(options.attributes.list, ID_KEY);
    model.options.systemAttributes.setList(options.systemAttributes.list, ID_KEY);
    model.options.dataTypes.setList(options.dataTypes.list, ID_KEY);
    model.options.artifactTypes.setList(options.artifactTypes.list, ID_KEY);
    model.options.linkTypes.setList(options.linkTypes.list, ID_KEY);
    model.options.applications.setList(options.applications.list, ID_KEY);
    model.options.users.setList(options.users.list, ID_KEY);
    model.options.pages.setList(options.pages.list, ID_KEY);
  }
}
