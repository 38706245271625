import { Injectable } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Observable, Subject } from 'rxjs';
import { Exception } from '../types/exception.types';
import { CachedUserMeta } from '../types/new-cached-subject.types';
import { NewCachedSubject } from '../utils/new-cached-subject';
import { NewAppCache, NewAppCacheData } from './new-cache.types';

@Injectable({ providedIn: 'root' })
export class NewCacheService {
  private readonly _cache: NewAppCache;
  private readonly _isCacheLoadedSubject = new Subject<boolean>();
  private readonly _isCacheLoadedObservable = this._isCacheLoadedSubject.asObservable();

  constructor() {
    this._cache = new NewAppCache(() => this._onDataLoaded());
  }

  get isLoaded(): boolean {
    return this._cache && this._cache.loaded;
  }

  get isLoaded$(): Observable<boolean> {
    return this._isCacheLoadedObservable;
  }

  get user(): NewCachedSubject<SelfUserResponseDto> {
    return this._cache.user;
  }

  get userProfile(): NewCachedSubject<ArtifactLinkResponseDto> {
    return this._cache.userProfile;
  }

  get data(): NewAppCacheData {
    return this._cache.data;
  }

  get userMeta(): CachedUserMeta | undefined {
    return structuredClone(this._cache?.userMeta) || undefined;
  }

  initCache(): void {
    if (this._cache.loaded) return;

    try {
      this._cache.initCache();
    } catch (e) {
      console.error(
        new Exception({
          name: 'InitCacheError',
          message: 'Something went wrong during NEW cache initialization',
          originalEvent: e,
        }),
      );
    }
  }

  refreshCache(): void {
    this.destroy();
    this.initCache();
  }

  destroy(): void {
    if (this._cache && this._cache.loaded) {
      const { users, applications, artifactTypes, artifacts, dataTypes, linkTypes, attributes, teams, pages, templates } = this._cache.data;

      this._cache.user && this._cache.user.complete();
      users && users.complete();
      teams && teams.complete();
      applications && applications.complete();
      artifactTypes && artifactTypes.complete();
      linkTypes && linkTypes.complete();
      attributes && attributes.complete();
      dataTypes && dataTypes.complete();
      pages && pages.complete();
      artifacts && artifacts.complete();
      templates && templates.complete();

      this._cache.loaded = false;
      this._isCacheLoadedSubject.next(false);
    }
  }

  private _onDataLoaded() {
    setTimeout(() => {
      this._cache.loaded = true;
      this._isCacheLoadedSubject.next(true);
    });
  }
}
