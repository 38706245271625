import { Directive, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AutocompleteBaseEvent, AutocompleteInitEvent } from '@shared/components/context-autocomplete/types/base/autocomplete.types';
import { Listbox } from 'primeng/listbox';
import { OverlayPanel } from 'primeng/overlaypanel';
import { filter, merge, Subscription, tap } from 'rxjs';
import { AutocompleteService } from '../services';

@Directive()
export abstract class AbstractAutocompleteComponent<T> implements OnInit, OnDestroy {
  abstract panel: OverlayPanel;
  abstract listBox: Listbox;
  ownerId: string;
  options: T[];
  selectedOption: T;

  protected initEvent: AutocompleteInitEvent<T>;
  private initSubscription: Subscription;

  protected constructor(
    protected readonly contextVariableAutocompleteService: AutocompleteService<T>,
    protected readonly zone: NgZone,
  ) {}

  protected abstract onAutocompleteInit(initEvent: any): void;

  protected abstract onOptionSelected(option: T): void;

  protected abstract shouldReactToEvent(event: AutocompleteBaseEvent): boolean;

  ngOnInit() {
    const autocompleteInit$ = this.contextVariableAutocompleteService.autocompleteInit$.pipe(
      filter(initEvent => this.shouldReactToEvent(initEvent)),
      tap(initEvent => this.onAutocompleteInit(initEvent)),
    );
    const autocompleteClose$ = this.contextVariableAutocompleteService.autocompleteClose$.pipe(
      filter(closeEvent => this.shouldReactToEvent(closeEvent) && this.ownerId === closeEvent.ownerId),
      tap(() => {
        this.zone.run(() => {
          this.onAutocompleteClose();
        });
      }),
    );

    this.initSubscription = merge(autocompleteInit$, autocompleteClose$).subscribe();
  }

  ngOnDestroy() {
    this.initSubscription.unsubscribe();
  }

  // TODO: focus does not work currently, needs investigation...
  protected doFocusFirstItem() {
    const firstItem = this.listBox?.el.nativeElement.getElementsByTagName('li')[0];
    firstItem && firstItem.focus();
  }

  private onAutocompleteClose() {
    this.panel.hide();
  }
}
