<div *ngIf="artifact?.attributes[attributeId]" [ngSwitch]="selectedVariant" class="full-size-container dat-boolean-container">
  <div *ngSwitchCase="'DEFAULT'">
    <p-triStateCheckbox
      *ngIf="!is2State"
      [ngModel]="artifact.attributes[attributeId].value"
      [disabled]="!isValueClickable()"
      (onChange)="handleValueChange()"
    ></p-triStateCheckbox>
    <p-checkbox
      *ngIf="is2State"
      binary="true"
      [(ngModel)]="artifact.attributes[attributeId].value"
      [disabled]="!isValueClickable()"
      (onChange)="handleValueChange()">
    ></p-checkbox>
  </div>

  <div *ngSwitchCase="'MODERN_CHECKBOX'">
    <p-inputSwitch
      [ngModel]="artifact.attributes[attributeId].value"
      [disabled]="!isValueClickable()"
      styleClass="{{ artifact.attributes[attributeId].value === false ? 'tri-state-input-switch' : '' }}"
      (onChange)="handleValueChange()"
    ></p-inputSwitch>
  </div>
</div>
