import { Component, Input, OnInit } from '@angular/core';
import { ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { NumberWidgetService } from '@widgets/number-widget/services/number-widget.service';
import { NumberWidgetModel } from '@widgets/number-widget/types/number-widget.types';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { NumberTypes } from '@widgets/number-widget/types/number-widget-options.types';

@Component({
  selector: 'app-number-widget-settings',
  templateUrl: './number-widget-settings.component.html',
  styleUrls: ['./number-widget-settings.component.scss'],
})
export class NumberWidgetSettingsComponent implements OnInit {
  @Input() m: NumberWidgetModel;
  @Input() s: NumberWidgetService;

  ID_VALUE = ID_KEY;
  NAME_VALUE = NAME_KEY;

  clickActionSettingsOptions: ClickActionSettingsOptions;

  ngOnInit(): void {
    this.clickActionSettingsOptions = {
      pages: this.m.options.pages.toSelectOptions('name', ID_KEY),
      attributes: this.m.options.attributes,
      dataTypes: this.m.options.dataTypes.list,
      users: this.m.options.users,
    };
  }

  get showSorting(): boolean {
    const { first, last } = NumberTypes;
    return this.m.selected.numberType.value === first || this.m.selected.numberType.value === last;
  }
}
