import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { CheckboxModule } from 'primeng/checkbox';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { DATBooleanEditVariant, DEFAULT_VARIANT_KEY } from '@shared/types/display-at-types';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedMethods } from '@shared/methods/shared.methods';
import { EMPTY_OPTION_FILTER_URL_VALUE } from '@shared/constants/constants';
import { RuntimeStateNotification } from '@widgets/shared/types/runtime-state-notification.types';
import { FilterRelevantStylesPipe } from '@shared/pipes/styles-pipes/filter-relevant-styles.pipe';
import { StylesDtoKeys } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ArtifactAttributeFormFieldLabelWithIconComponent, TriStateCheckboxModule, RadioButtonModule, CheckboxModule, FilterRelevantStylesPipe],
  selector: 'app-artifact-attribute-form-field-boolean',
  templateUrl: './artifact-attribute-form-field-boolean.component.html',
  styleUrls: ['./artifact-attribute-form-field-boolean.component.scss'],
})
export class ArtifactAttributeFormFieldBooleanComponent {
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() index: number;
  @Input() formatSettings: AttributeFormatSettings;
  @Input() label: string;
  @Input() onChange: () => Promise<void>;
  @Input() columnStylesEvent: RuntimeStateNotification<any> | undefined;

  uuid = SharedMethods.getUniqueId();
  emptyLabel = EMPTY_OPTION_FILTER_URL_VALUE;
  relevantStyleKeys: StylesDtoKeys[] = ['color', 'borderStyle', 'borderRadius', 'borderColor', 'backgroundColor', 'borderWidth'] as StylesDtoKeys[];

  get directionClass(): string {
    return DATBooleanEditVariant[this.code as keyof typeof DATBooleanEditVariant] === DATBooleanEditVariant.RADIO_BUTTON_HORIZONTAL
        ? 'flex-row': 'flex-column';
  }

  get alignStyle(): any {
    const value = this.formatSettings.value.displayMetadata?.horizontalAlign;
    return value ? { 'justify-content': value }: null;
  }

  get isRadiobutton(): boolean {
    const value = DATBooleanEditVariant[this.code as keyof typeof DATBooleanEditVariant];
    return value === DATBooleanEditVariant.RADIO_BUTTON || value === DATBooleanEditVariant.RADIO_BUTTON_HORIZONTAL;
  }

  get isDefault(): boolean {
    const value = DATBooleanEditVariant[this.code as keyof typeof DATBooleanEditVariant];
    return value === DATBooleanEditVariant.DEFAULT;
  }

  get isTriState(): boolean {
    const value = DATBooleanEditVariant[this.code as keyof typeof DATBooleanEditVariant];
    return value === DATBooleanEditVariant.TOGLE_3_STATE;
  }

  get is2State(): boolean {
    const value = DATBooleanEditVariant[this.code as keyof typeof DATBooleanEditVariant];
    return value === DATBooleanEditVariant.TOGGLE_2_STATE;
  }

  get code(): string {
    return this.formatSettings.value.displayMetadata?.editVariantCode || DEFAULT_VARIANT_KEY;
  }
}
