import { Pipe, PipeTransform } from '@angular/core';
import { PreviewFormatSettings } from '@shared/components/artifact-attribute-form-field/types/artifact-format-settings.interface';
import { AttributeFormatSettings } from '../../../widgets/shared/types/attribute-format-settings.types';

@Pipe({
  name: 'constructPreviewFormatSettings',
  standalone: true,
})
export class ConstructPreviewFormatSettingsPipe implements PipeTransform {
  transform(formatSettings: AttributeFormatSettings | null, isPreviewMode: boolean): PreviewFormatSettings {
    return { formatSettings, isPreviewMode };
  }
}
