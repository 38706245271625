<div class="option-container check">
  <label for="useExternalLink"> {{ 'Use external link' | translate }} </label>
  <p-checkbox [ngModel]="useExternalLink" (ngModelChange)="onUseExternalLinkChange($event)" binary="true"
              id="useExternalLink"></p-checkbox>
</div>

<div *ngIf="useExternalLink" class="option-container col">
  <span>{{ 'Url' | translate }}</span>
  <input [ngModel]="url"
         (ngModelChange)="onImageUrlChange($event)"
         pInputText
         placeholder="{{'Set url' | translate}}"
         type="text" />
</div>

<div *ngIf="!useExternalLink" class="option-container col">
  <p-fileUpload
    name="myfile[]"
    mode="basic"
    accept="image/*"
    [multiple]="false"
    auto="true"
    uploadLabel="{{'Upload picture' | translate}}"
    cancelLabel="{{'Cancel' | translate}}"
    [fileLimit]="1"
    [customUpload]="true"
    [files]="uploadedFiles"
    [disabled]="!!uploadedFiles.length"
    (uploadHandler)="onBasicUpload($event)"
  >
  </p-fileUpload>

  <ng-container *ngIf="uploadedFiles.length">
    <ul>
      <li *ngFor="let file of uploadedFiles">{{ file.name }} - {{ file.size }} bytes</li>
    </ul>
    <div class="option-container">
      <div #spinner [ngClass]="{ hidden: !isLoadingData }" class="spinner loader-custom">
        <div class="loader-custom__wrapper">
          <span class="pi pi-spin pi-spinner loader-custom__wheel"></span>
        </div>
      </div>
    </div>
  </ng-container>

  <p *ngIf="!uploadedFiles.length">There is no selected image now!</p>
</div>