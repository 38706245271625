@if (sessionFlow) {
  <div class="form_wrapper">
    <div class="form">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-3">
          <span class="dynamic-label">
            <p-dropdown
              [(ngModel)]="sessionFlow.type"
              [autoDisplayFirst]="false"
              [options]="typeOptions"
              [disabled]="sessionFlow.id ? true : false"
              (onChange)="s.onSessionTypeChange($event)"
              id="type"
            ></p-dropdown>
            <label class="dynamic-label-text" for="type" translate>Type</label>
          </span>
        </div>

        <div class="col-12 md:col-6 lg:col-3">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox binary="true" inputId="isActive" id="isActive" [(ngModel)]="sessionFlow.active"></p-checkbox>
              <label for="isActive" translate>{{ 'Is Active' | translate }}</label>
            </span>
          </span>
        </div>

        <div class="col-12">
          <p-card>
            <div class="mb-3">
              <h4 class="m-0">{{ "'" + sessionFlow.type + "' Type Settings" }}</h4>
            </div>
            <ng-container *ngComponentOutlet="indexSessionFlowFormComponents[sessionFlow.type]; inputs: { sessionFlow }"></ng-container>
          </p-card>
        </div>
      </div>
    </div>
  </div>
}
