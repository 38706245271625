import { Injectable } from '@angular/core';
import {
  ApplicationResponseDto,
  ArtifactResponseDto,
  ArtifactTypeResponseDto,
  AttributeResponseDto,
  DataTypeResponseDto,
  LinkTypeResponseDto,
  PageResponseDto,
  SelfUserResponseDto,
} from '@api/models';
import { TenantArtifactTypeService } from '@api/services/tenant-artifact-type.service';
import { TenantFolderService } from '@api/services/tenant-folder.service';
import { Environment } from '@environments/environment';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import {
  ATTRIBUTE_DATA_TYPE_NAME_KEY,
  ATTRIBUTE_NAME_KEY,
  Constants,
  DATA_TYPE_LABEL, HELP_KEY, HELP_LABEL,
  ID_KEY,
  INITIAL_VALUE_KEY,
  INITIAL_VALUE_LABEL,
  IS_MANDATORY_KEY,
  IS_MANDATORY_LABEL,
  NAME_KEY,
  NAME_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { GetAttributeFromClientAttribute, GetDataTypeFromClientAttribute } from '@shared/methods/artifact.methods';
import { AttributeValueToClient } from '@shared/methods/client-attribute.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { IconsService } from '@shared/services/icons.service';
import { NewApplication } from '@shared/types/application.types';
import { ArtifactTypeFormatEnum, NewArtifactType, NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { NewSystemUser } from '@shared/types/user.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { FolderTreeNode } from '@widgets/folder-widget/types/folder-widget.types';
import {
  PRIVATE_USER_FOLDER,
  PRIVATE_USER_FOLDER_LABEL,
  USER_DEFAULT_TEAM_FOLDER,
  USER_DEFAULT_TEAM_FOLDER_LABEL,
} from '@widgets/shared/constants/widget.constants';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { cloneDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom, take, tap } from 'rxjs';
import { BaseDataType } from '../../data-type/components/data-type-form/types/data-type-form.types';
import { ArtifactTypeComponent } from '../artifact-type.component';
import { ArtifactTypeModel } from '../types/artifact.type.types';

@Injectable()
export class ArtifactTypeService extends CoreService<ArtifactTypeComponent, ArtifactTypeModel> {
  constructor(
    public readonly tenantArtifactTypeService: TenantArtifactTypeService,
    private readonly cache: NewCacheService,
    private readonly iconsService: IconsService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateUtil: TranslateUtil,
    private readonly blockUiService: BlockUiService,
    private readonly tenantFolderService: TenantFolderService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly elvisU: ElvisUtil,
  ) {
    super();
  }

  async init(context: ArtifactTypeComponent, model: ArtifactTypeModel): Promise<void> {
    super.init(context, model);
    await this.registerSubscriptions();
    this.initAttributeColumns();
    this.m.rowsPerPage = Environment.tableConfig.rowsPerPage;
    this.m.rowsPerPageOptions = Environment.tableConfig.rowsPerPageOptions;
  }

  initData(): void {
    setTimeout(async () => {
      try {
        this.m.inProgress = true;
        this.m.currentUser = new NewSystemUser(this.cache.user.value as SelfUserResponseDto);

        const artifactType = this.m.options.artifactTypes.listMap[this.c.urlParams?.id];

        if (artifactType) {
          Object.keys(artifactType.attributes).forEach(key => this.m.initialAttributesMap.set(key, true));
          this.setArtifactType(new NewArtifactType(artifactType));
          this.m.isDeleted = Boolean(artifactType.deleted);
          await this.initLinkRestrictions();
          this.setOriginalObject<NewArtifactType>(this.m.artifactType);
          const folder = await lastValueFrom(this.tenantFolderService.folderControllerGet({ id: artifactType.defaultFolderId }));

          const id = this.m.currentUser.tenant?.defaultTeamId;
          if (id) {
            const res = await this.cache.data.teams.getAsync(id);
            this.m.userDefaultTeamFolderId = res?.teamPrivateFolderId || '';
          }

          if (folder.id === this.m.userPrivateFolderId) {
            this.m.folderPath = PRIVATE_USER_FOLDER_LABEL;
            this.m.artifactType.defaultFolderId = PRIVATE_USER_FOLDER;
          } else if (folder.id === this.m.userDefaultTeamFolderId) {
            this.m.folderPath = USER_DEFAULT_TEAM_FOLDER_LABEL;
            this.m.artifactType.defaultFolderId = USER_DEFAULT_TEAM_FOLDER;
          } else {
            this.m.folderPath = folder.folderPath + folder.name;
          }

          this.m.selectedAvrTypes = Object.keys(artifactType.avrMapper) as AvrTypes[];
        } else {
          this.setArtifactType();
          this.setRequiredSystemAttributes();
          this.setDefaultSystemAttribute();
        }

        await this.initClientAttributes();
        this.setOriginalHelpingAttributes();
        this.updatePrimaryAttributes();
        this.updateAvailableAttributes();
        this.m.artifactType.id && (await this.getPages());
        this.fixSequenceAttributeValue();
      } finally {
        this.m.inProgress = false;
      }
    });
  }

  async initClientAttributes(): Promise<void> {
    if (this.m.artifactType.attributes && Object.keys(this.m.artifactType.attributes).length) {
      const clientAttributes = [];
      const { dataTypes, attributes } = this.m.options;

      for (const key in this.m.artifactType.attributes) {
        const attribute = new NewArtifactTypeClientAttribute({
          id: key,
          initialValue: AttributeValueToClient(
            new AttributeToClientParams({
              dataTypes,
              attributes,
              clientAttribute: this.m.artifactType.attributes[key],
              value: this.m.artifactType.attributes[key].initialValue,
            }),
          ),
          isMandatory: this.m.artifactType.attributes[key].isMandatory,
          help: this.m.artifactType.attributes[key].help,
        });
        clientAttributes.push(attribute);
      }

      this.m.editableHelpingAttributes.clientAttributes = clientAttributes;
    }
    await this.mapFilesToArtifactType(
      this.m.editableHelpingAttributes.clientAttributes.filter(
        attr =>
          GetDataTypeFromClientAttribute(attr, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap)?.baseDataType === BaseDataType.file &&
          attr.initialValue &&
          attr.initialValue?.length,
      ),
    );
  }

  async initLinkRestrictions(): Promise<void> {
    const linkTypes = (this.cache.data.linkTypes.value as LinkTypeResponseDto[]).filter(linkType => {
      const { restrictions } = linkType as LinkTypeResponseDto;

      if (restrictions) {
        return restrictions.some(
          restriction => restriction.sourceArtifactTypeId === this.m.artifactType.id || restriction.destinationArtifactTypeId === this.m.artifactType.id,
        );
      }

      return false;
    });
    this.m.editableHelpingAttributes.linkRestrictions = LinkMethods.getUngroupedLinkRestrictionsForArtifactType(this.m.artifactType.id, linkTypes);
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl('/admin/artifact-type-list');
  }

  async getPages(): Promise<void> {
    this.m.options.pageOptions = (this.cache.data.pages.value as PageResponseDto[]).map(dto => new SelectOption(dto.name, dto.id));
  }

  onAttributeChange(artifactTypeAttribute: NewArtifactTypeClientAttribute, index: number): void {
    const attribute = GetAttributeFromClientAttribute(artifactTypeAttribute, this.m.options.attributes.listMap);
    if (attribute) {
      artifactTypeAttribute.initialValue = null;
      this.updatePrimaryAttributes();
      this.updateAvailableAttributes();

      this.m.editableHelpingAttributes.clientAttributes = this.m.editableHelpingAttributes.clientAttributes.map(
        (existingAttribute: NewArtifactTypeClientAttribute, i: number) => {
          return i === index ? new NewArtifactTypeClientAttribute(artifactTypeAttribute) : existingAttribute;
        },
      );
    }
  }

  async deleteWithConfirmation(artifactType: NewArtifactType, callback: (() => void) | null = null): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    this.confirmationService.confirm({
      header,
      message: message + ' ' + artifactType.name + '?',
      acceptLabel,
      rejectLabel,
      accept: () =>
        this.delete(artifactType.id).then(() => {
          callback ? callback() : this.cancel();
        }),
    });
  }

  async delete(id: string): Promise<void> {
    this.m.inProgress = true;
    this.blockUiService.blockUi();

    try {
      const success = await lastValueFrom(this.tenantArtifactTypeService.artifactTypeControllerDelete({ id }));
      if (success) {
        await this.cancel();
      }
    } finally {
      this.m.inProgress = false;
      this.blockUiService.unblockUi();
    }
  }

  addDefaultPrimaryAttribute(): void {
    const artifactIdAttr = new SelectOption('Artifact ID', {
      id: Constants.primaryAttributesDefaultId,
      initialValue: null,
      isMandatory: true,
      help: '',
    });
    this.m.options.primaryAttributeOptions.push(artifactIdAttr);

    const artifactTypeAttr = new SelectOption('Artifact Type', {
      id: Constants.primaryAttributesArtifactTypeId,
      initialValue: null,
      isMandatory: true,
      help: '',
    });

    this.m.options.primaryAttributeOptions.push(artifactTypeAttr);
  }

  updatePrimaryAttributes(): void {
    this.m.options.primaryAttributeOptions = this.m.editableHelpingAttributes.clientAttributes
      .filter(attr => {
        if (!attr.isMandatory) return false;

        const dataType = GetDataTypeFromClientAttribute(attr, this.m.options.attributes.listMap, this.m.options.dataTypes.listMap);
        const attribute = GetAttributeFromClientAttribute(attr, this.m.options.attributes.listMap);

        return !!dataType && attribute?.canBePrimary(dataType);
      })
      .map(attr => {
        return new SelectOption(GetAttributeFromClientAttribute(attr, this.m.options.attributes.listMap)?.name || '', attr);
      });
    this.addDefaultPrimaryAttribute();
    this.m.artifactType.primaryAttributes = this.m.artifactType.primaryAttributes.filter(primaryAttribute =>
      this.m.options.primaryAttributeOptions.find(option => option.value.id === primaryAttribute),
    );
  }

  updateAvailableAttributes(): void {
    const usedIds = this.m.editableHelpingAttributes.clientAttributes
      .filter(attr => attr && GetAttributeFromClientAttribute(attr, this.m.options.attributes.listMap))
      .map(attr => attr && GetAttributeFromClientAttribute(attr, this.m.options.attributes.listMap)?.id);
    this.m.options.attributeOptions.forEach(option => (option.disabled = usedIds.includes(option.value.id)));
    this.m.options.attributeOptions = [...this.m.options.attributeOptions];
  }

  autoInitPreferredModuleTypes(): void {
    const preferredModuleTypes: string[] = [];
    const selectedApplication = this.applicationSwitcherService.selectedApplication;
    this.m.options.artifactTypes.list.forEach(
      artifactType =>
        artifactType.applicationId === selectedApplication?.id &&
        artifactType.format === ArtifactTypeFormatEnum.text &&
        preferredModuleTypes.push(artifactType.id),
    );
    this.m.artifactType.preferredArtifactTypeIds = preferredModuleTypes;
  }

  private fixSequenceAttributeValue(): void {
    this.m.editableHelpingAttributes.clientAttributes.forEach(attr => {
      if (!attr.initialValue && attr.id === this.m.currentUser.tenant.systemAttributes?.sequenceAttributeId) {
        attr.initialValue = null;
      }
    });
  }

  private eventHandler(event: RuntimeStateNotification<unknown>): void {
    if (event.type === RuntimeStateNotificationEnum.closeFolderPicker) {
      const folder = event.data as FolderTreeNode;
      if (folder) {
        const isUserPrivateFolder: boolean = folder.id === this.m.userPrivateFolderId;
        this.m.folderPath = isUserPrivateFolder ? PRIVATE_USER_FOLDER_LABEL : folder.folderPath + '' + folder.label;
        this.m.artifactType.defaultFolderId = isUserPrivateFolder ? PRIVATE_USER_FOLDER : folder.id;

        if (folder.id === USER_DEFAULT_TEAM_FOLDER) {
          this.m.folderPath = USER_DEFAULT_TEAM_FOLDER_LABEL;
          this.m.artifactType.defaultFolderId = USER_DEFAULT_TEAM_FOLDER;
        }
      }
      this.m.isShowFolderPicker = false;
    }
  }

  private setArtifactType(entity: NewArtifactType = new NewArtifactType()): void {
    this.m.artifactType = new NewArtifactType(entity);
  }

  private setDefaultSystemAttribute(): void {
    this.m.currentUser.tenant.systemAttributes && this.m.artifactType.primaryAttributes.push(this.m.currentUser.tenant.systemAttributes.nameAttributeId);
  }

  private setRequiredSystemAttributes(): void {
    const nameAttribute = new NewArtifactTypeClientAttribute({
      id: this.m.currentUser.tenant.systemAttributes?.nameAttributeId,
      isMandatory: true,
      initialValue: null,
    });
    const sequenceAttribute = new NewArtifactTypeClientAttribute({
      id: this.m.currentUser.tenant.systemAttributes?.sequenceAttributeId,
      isMandatory: false,
      initialValue: null,
    });

    this.m.editableHelpingAttributes.clientAttributes = [nameAttribute, sequenceAttribute];
  }

  private initAttributeColumns(): void {
    this.m.attributeColumns = [
      new TableColumn(NAME_LABEL, ATTRIBUTE_NAME_KEY),
      new TableColumn(DATA_TYPE_LABEL, ATTRIBUTE_DATA_TYPE_NAME_KEY),
      new TableColumn(INITIAL_VALUE_LABEL, INITIAL_VALUE_KEY),
      new TableColumn(IS_MANDATORY_LABEL, IS_MANDATORY_KEY),
      new TableColumn(HELP_LABEL, HELP_KEY),
    ];
  }

  private async registerSubscriptions(): Promise<void> {
    const data = this.cache.data;

    this.c.registerSubscriptions([
      this.runtimeStateNotificationService.events$.subscribe(this.eventHandler.bind(this)),
      data.dataTypes.pipe(take(1)).subscribe(dataTypes =>
        this.m.options.dataTypes.setList(
          dataTypes!.map(dto => new NewDataType(dto as DataTypeResponseDto)),
          ID_KEY,
        ),
      ),
      data.applications.pipe(take(1)).subscribe(applications =>
        this.m.options.applications.setList(
          applications!.map(dto => new NewApplication(dto as ApplicationResponseDto)),
          ID_KEY,
        ),
      ),
      data.linkTypes.pipe(take(1)).subscribe(linkTypes =>
        this.m.options.linkTypes.setList(
          linkTypes!.map(dto => new LinkType(dto as LinkTypeResponseDto)),
          ID_KEY,
        ),
      ),
      data.attributes.pipe(take(1)).subscribe(attributes => {
        const mapped = attributes!.map(dto => new NewAttribute(dto as AttributeResponseDto));
        this.m.options.attributes.setList(
          mapped.filter(attribute => !attribute.deleted),
          ID_KEY,
        );
        this.m.options.attributeOptions = this.m.options.attributes.toSelectOptions(NAME_KEY); //TODO: store id instead
        // of whole object
      }),
      data.artifactTypes.pipe(take(1)).subscribe(artifactTypes => {
        // TODO: this subscription is called multiple times after saving/updating and then opening component again
        this.m.options.artifactTypes.setList(
          artifactTypes!.map(dto => new NewArtifactType(dto as ArtifactTypeResponseDto)),
          ID_KEY,
        );
        this.m.options.textArtifactTypes = this.elvisU.transformAnyToSelectOptions(
          this.m.options.artifactTypes.list.filter(at => at.format === ArtifactTypeFormatEnum.text),
          NAME_KEY,
          ID_KEY,
        );
        this.initData();
      }),
      this.cache.user.pipe(take(1)).subscribe(user => {
        this.m.userPrivateFolderId = (user as SelfUserResponseDto)?.tenant?.userPrivateFolderId || '';
      }),
      this.iconsService.icons$.subscribe((iconOptions: SelectOption<string, string>[]) => (this.m.options.iconOptions = iconOptions)),
    ]);
  }

  private async mapFilesToArtifactType(attributes: NewArtifactTypeClientAttribute[]): Promise<void> {
    const fileIds = new Set<string>();
    attributes.forEach(attr => {
      if (Array.isArray(attr.initialValue)) {
        attr.initialValue.forEach(file => fileIds.add(file));
      } else {
        attr.initialValue.length && fileIds.add(attr.initialValue);
      }
    });
    if (fileIds.size) {
      this.cache.data.artifacts
        .getMany$([...fileIds])
        .pipe(
          tap(data => {
            attributes.forEach(attr => {
              if (Array.isArray(attr.initialValue)) {
                const attributeFiles: ArtifactResponseDto[] = [];
                attr.initialValue.forEach(value => {
                  const fileArtifact = data.find(fileArtifactRes => fileArtifactRes.id === value);
                  fileArtifact && attributeFiles.push(fileArtifact);
                });
                attr.initialValue = attributeFiles;
              } else {
                attr.initialValue = data.find(fileArtifactRes => fileArtifactRes.id === attr.initialValue);
              }
            });
          }),
        )
        .subscribe({
          error(err) {
            console.log(err);
          },
        });
    }
  }

  private setOriginalHelpingAttributes(): void {
    this.m.originalHelpingAttributes = cloneDeep(this.m.editableHelpingAttributes);
  }
}
