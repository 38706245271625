import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { IsDateInRange } from '@shared/methods/date.methods';
import { SharedMethods } from '@shared/methods/shared.methods';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { WorkflowConditionType } from '@workflows/types';
import { ConditionDateFilterType, RuleCondition } from '@workflows/types/conditions/rule-condition';
import { AbstractWorkflowCondition } from './abstract/abstract.condition';

export class WorkflowDatesCondition extends AbstractWorkflowCondition {
  constructor(private readonly filterMetadataUtil: FilterMetadataUtil) {
    super();
    this.type = WorkflowConditionType.DATES;
    this.supportedTypes = [BaseDataType.date, BaseDataType.dateTime];
    this.supportedKinds = [DataTypeKind.simple];
  }

  isMet(source: Date, condition: RuleCondition): boolean {
    const type = condition.extra!.settings.operationType;

    if (this.isDateRangeType(type)) {
      const dateRange = this.filterMetadataUtil.transformDateRangeEnumToDateRange(type as DateRangeFilterEnum, condition.extra!.settings);
      return IsDateInRange(source, dateRange);
    }

    return this.isDateConditionMet(source, condition);
  }

  private isDateConditionMet(source: Date | string, condition: RuleCondition) {
    const sourceDate = source instanceof Date ? source.getTime() : null;
    const destinationDate = this.getDateAsMilliseconds(condition.destination.value);

    switch (condition.extra!.settings.operationType) {
      case DateFilterEnum.dateIs:
        return sourceDate === destinationDate;
      case DateFilterEnum.dateIsNot:
        return sourceDate !== destinationDate;
      case DateFilterEnum.dateAfter:
        return !!destinationDate && !!sourceDate && sourceDate > destinationDate;
      case DateFilterEnum.dateAfterOrEqualTo:
        return !!destinationDate && !!sourceDate && sourceDate >= destinationDate;
      case DateFilterEnum.dateBefore:
        return !!destinationDate && !!sourceDate && sourceDate < destinationDate;
      case DateFilterEnum.dateBeforeOrEqualTo:
        return !!destinationDate && !!sourceDate && sourceDate <= destinationDate;
      case DateFilterEnum.isEmpty:
        return !sourceDate;
      case DateFilterEnum.isNotEmpty:
        return !!sourceDate;
    }
    return false;
  }

  private isDateRangeType(type: ConditionDateFilterType): boolean {
    return SharedMethods.isValueInEnum(type, DateRangeFilterEnum);
  }

  private getDateAsMilliseconds(value: Date): number | null {
    if (!value) {
      return null;
    }

    value.setSeconds(0);
    return value.getTime();
  }
}
