import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { SharedMethods } from '@shared/methods/shared.methods';
import { PushNotificationService } from '@shared/services/push-notification.service';
import { AuthorizationService } from '../services/authorization/authorization.service';

@Injectable({ providedIn: 'root' })
class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService,
    private readonly cache: NewCacheService,
    private readonly pushNotificationService: PushNotificationService,
  ) {}

  async canActivate(url: string): Promise<boolean> {
    if (!this.authorizationService.isLoggedIn) {
      debugger;
      await this.router.navigateByUrl(SharedMethods.getRedirectUrlQuery(url));
      this.authorizationService.resetToken();
      this.cache.destroy();
      this.pushNotificationService.destroy();
      return false;
    }
    return true;
  }
}

export const CanActivateAuth: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AuthGuard).canActivate(state.url);
};
