<div>
  <!-- Single selection autocomplete -->
  @if (!attribute?.multipleValues && !multipleValues) {
    <p-dropdown #dropdown
                [(ngModel)]="value"
                (ngModelChange)="valueChange.emit(value)"
                (onBlur)="onBlur.emit($event)"
                (onHide)="onHide.emit($event)"
                (onFilter)="onFilter($event)"
                (onShow)="searchUsers(); onPanelShow();"
                [disabled]="disabled"
                [filter]="true"
                [showClear]="true"
                [options]="filteredUsers"
                [appendTo]="appendTo"
                [placeholder]="placeholder | translate">
      <ng-template let-item pTemplate="item">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
      </ng-template>
      <ng-template pTemplate="footer">
        <ng-container *ngTemplateOutlet="loaderTemplate;"></ng-container>
      </ng-template>
      <ng-template let-option pTemplate="selectedItem">
        <ng-container *ngTemplateOutlet="itemSelected; context: {$implicit: option}"></ng-container>
      </ng-template>
</p-dropdown>
  }

  <!-- Multiple selection autocomplete -->
  @if (attribute?.multipleValues || multipleValues) {
    <p-multiSelect #multiselect
                   [(ngModel)]="value"
                   (ngModelChange)="valueChange.emit(value)"
                   (onBlur)="onBlur.emit($event)"
                   (onPanelHide)="onHide.emit($event)"
                   (onFilter)="onFilter($event)"
                   (onPanelShow)="searchUsers(); onPanelShow();"
                   [disabled]="disabled"
                   [options]="filteredUsers"
                   [appendTo]="appendTo"
                   [placeholder]="placeholder | translate"
                   [selectionLimit]="selectionLimit"
                   [maxSelectedLabels]="maxSelectedLabels || 3">
      <ng-template let-item pTemplate="item">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
      </ng-template>
      <ng-template pTemplate="footer">
        <ng-container *ngTemplateOutlet="loaderTemplate;"></ng-container>
      </ng-template>
    </p-multiSelect>
  }
</div>

<!-- Template for selected user item -->
<ng-template #itemSelected let-option>
  <span [class]="option.icon"></span>
  <span [ngStyle]="formatSettings.value.fieldStyles | filterRelevantStyles : relevantStyleKeys: columnStylesEvent">{{ option.label }}</span>
</ng-template>

<!-- Template for user item -->
<ng-template #itemTemplate let-item>
  <div class="user-autocomplete-item">
    @if (item.meta?.profilePictureId) {
      <img [src]="item.meta.profilePictureId | internalImageIdToUrl" [width]="iconSize" [height]="iconSize" alt="" />
    } @else {
      <div
        class="icon-as-initials"
        [style.min-width]="iconSize + 'px'"
        [style.width]="iconSize + 'px'"
        [style.min-height]="iconSize + 'px'"
        [style.height]="iconSize + 'px'"
        [style.line-height]="iconSize + 'px'"
      >
        {{ item.label | removeSquareBrackets | toUserInitials : item.meta?.email }}
      </div>
    }

    <div class="user-autocomplete-item-label">
      <div style="margin-bottom: -3px;">{{ item.label }}</div>
      <small>{{ item.meta?.email }}</small>
    </div>
  </div>
</ng-template>

<!-- Template for users loader -->
<ng-template #loaderTemplate>
  @if (isLoading) {
    <div class="users-loader-container-fixed">
      <span class="pi pi-spin pi-spinner"></span>
    </div>
  }
</ng-template>
