<div *ngIf="forceShowContent || artifact.attributes[attributeId]?.value" [ngSwitch]="selectedVariant" class="at-html-container full-size-container">
  <ng-container *ngSwitchCase="'DEFAULT'">
    <ng-container *ngTemplateOutlet="defaultText"></ng-container>
  </ng-container>

  <div *ngSwitchCase="'DETAIL_ON_CLICK'" class="icon-with-detail-container">
    <i #iconClick *ngIf="artifact.attributes[attributeId].value" class="{{ attribute.icon || 'pi pi-window-maximize' }} clickable"></i>
  </div>

  <div *ngSwitchCase="'DEFAULT_CARD'">
    <ng-container
      *ngTemplateOutlet="cardVariant; context: { template$: displayAtTemplateService.getTemplateForArtifactType$(artifactType, options), isListMatrixCard: true}"></ng-container>
  </div>

  <div *ngSwitchCase="'CUSTOM'">
    <ng-container
      *ngTemplateOutlet="cardVariant; context: { template$: customVariantTemplate$, isListMatrixCard: true }"></ng-container>
  </div>
</div>

<ng-template #defaultText>
  <div [class.container-with-ellipsis]="truncate" [ngStyle]="contentColumnFormat?.styles" [innerHTML]="artifact.attributes[attributeId]?.value | replaceRuntimeVariables : artifact | async | replaceTextEnter | async">
  </div>

</ng-template>

<ng-template #default>
  <div class="multi-line-text">{{ artifact.attributes[attributeId].value | replaceRuntimeVariables : artifact | async }}</div>
</ng-template>

<ng-template #cardVariant let-template$="template$" let-isListMatrixCard="isListMatrixCard">
  <ng-container *ngIf="template$ | async as widget; else defaultText">
    <app-widget-container
      *ngIf="widget.code === widgetType.card && filesLoaded"
      [artifact]="artifactDto || artifact"
      [isClickable]="false"
      [isLayoutMode]="true"
      [isListMatrixCard]="isListMatrixCard"
      [isPreviewMode]="isPreviewMode"
      [disabled]="isPreviewMode"
      [widget]="widget"
      [artifactAdditionalData]="{files: transformFilesToArray(), links, linkedArtifacts}"
      appWidgetContainerStopPropagation
    >
    </app-widget-container>
  </ng-container>
</ng-template>
