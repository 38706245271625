import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GlobalConstants } from '@shared/constants/global.constants';
import { PageHelper } from '@shared/helpers/page-helper';
import { ImageMethods } from '@shared/methods/image.methods';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { AuthWidgetComponent } from '@widgets/auth-widget/auth-widget.component';
import { UserIconVariantType } from '@widgets/auth-widget/types/auth-widget-options.types';
import { AuthWidgetModel, AuthWidgetModelDto, AuthWidgetValue } from '@widgets/auth-widget/types/auth-widget.types';

@Injectable()
export class AuthWidgetService {
  c: AuthWidgetComponent;
  m: AuthWidgetModel;

  constructor(
    private readonly apiConfiguration: ApiConfiguration,
    private readonly cache: NewCacheService,
    private readonly authorizationService: AuthorizationService,
    private readonly pageHelper: PageHelper,
  ) {}

  get isShowUserProfilePicture(): boolean {
    return !!this.m.userProfilePictureUrl && this.m.settings.userDisplayVariant === UserIconVariantType.photo;
  }

  // show if photo mode, but picture url and icon are not present
  get isShowLetters(): boolean {
    return (
      this.m.settings.userDisplayVariant === UserIconVariantType.letters ||
      (this.m.settings.userDisplayVariant === UserIconVariantType.photo &&
        !this.m.userProfilePictureUrl &&
        this.m.settings.userAltDisplayVariant === UserIconVariantType.letters)
    );
  }

  get isShowUserIcon(): boolean {
    return (
      this.m.settings.userDisplayVariant === UserIconVariantType.icon ||
      (this.m.settings.userDisplayVariant === UserIconVariantType.photo &&
        !this.m.userProfilePictureUrl &&
        this.m.settings.userAltDisplayVariant === UserIconVariantType.icon)
    );
  }

  // if all undefined then show fallback icon
  get isShowFallbackIcon(): boolean {
    return !this.m.userProfilePictureUrl && !this.m.settings.userIcon && !this.m.userName && !this.m.currentUser?.email;
  }

  async init(context: AuthWidgetComponent, dto?: AuthWidgetModelDto | null): Promise<void> {
    this.prepareContext(context, dto);
    await this.setCurrentUser(context);
    this.setContextAndModel(context);
    await this.setUserData();
  }

  private prepareContext(context: AuthWidgetComponent, dto?: AuthWidgetModelDto | null): void {
    if (!context.widget.value || !Object.keys(context.widget.value).length) {
      context.widget.value = new AuthWidgetValue();
    }
    if (context.isLayoutMode && dto) {
      context.widget.value.model = new AuthWidgetModel(dto);
    }
  }

  private async setCurrentUser(context: AuthWidgetComponent): Promise<void> {
    context.widget.value.model.currentUser = this.cache.user.value as SelfUserResponseDto;
  }

  private setContextAndModel(context: AuthWidgetComponent): void {
    context.m = context.widget.value.model;
    this.c = context;
    this.m = context.m;
  }

  private async setUserData(): Promise<void> {
    let token = this.authorizationService.getToken;
    if (!token || this.pageHelper.usePublicToken) token = this.authorizationService.getAnonymousToken;

    const imageFileId = (this.cache.userProfile.value as ArtifactLinkResponseDto)?.attributes?.[
      GlobalConstants.getValue(GlobalConstantsEnum.profilePictureAttributeId)
    ]?.value as string;
    this.m.userProfilePictureUrl = ImageMethods.getUrlFromImageFileId(imageFileId, this.apiConfiguration.rootUrl, token);

    this.m.userName = (this.cache.userProfile.value as ArtifactLinkResponseDto).attributes?.[
      GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)
    ]?.value;
  }
}
