import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { HTTP_HTTPS_VALIDATION_REGEX } from '@shared/constants/constants';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { FilterWidgetSelectTag } from '@widgets/filter-widget/types/filter-widget.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { TextFilter } from '@widgets/shared/components/artifact-filters/components/text-filter/types/text-filter.types';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { FilterTypeDetectionService } from '@widgets/shared/components/artifact-filters/services/filter-type-detection.service';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { cloneDeep } from 'lodash';
import { ArtifactListItemClickAction } from '../../types/artifact-list-item-click-action';

@Component({
  selector: 'app-list-item-click-action-settings-form',
  templateUrl: './list-item-click-action-settings-form.component.html',
  styleUrls: ['./list-item-click-action-settings-form.component.scss'],
})
export class ListItemClickActionSettingsFormComponent implements OnInit {
  @Input() header = 'List item click action';
  @Input() isAddToLinkDisabled: boolean;
  @Input() isGoToDefaultPageDisabled: boolean;
  @Input() showFolderEmittingOption: boolean;
  @Input() shouldEmitFolderUrlKey: boolean;
  @Input() filtersDisabled: boolean;
  @Input() handleClick: boolean;
  @Input() clickAction: ArtifactListItemClickAction;
  @Input() emittingUrlKey: string;
  @Input() emittingFolderUrlKey: string;
  @Input() selectedPage: string | null;
  @Input() externalPage: string;
  @Input() openPageInNewTab: boolean;
  @Input() multiselect: boolean;
  @Input() filtersHolder: { [filtersKey: string]: ArtifactFilter[] };
  @Input() filtersKey: string;
  @Input() options: ClickActionSettingsOptions;
  @Input() isHideMultiselect: boolean;

  @Input() pageOptions: SelectOption<string, any>[];

  @Output() handleClickChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() clickActionChange: EventEmitter<ArtifactListItemClickAction> = new EventEmitter<ArtifactListItemClickAction>();
  @Output() emittingUrlKeyChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() emittingFolderUrlKeyChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() selectedPageChange: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() externalPageChange: EventEmitter<string | null> = new EventEmitter<string | null>();
  @Output() shouldEmitFolderUrlKeyChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openPageInNewTabChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() multiselectChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filtersChange: EventEmitter<ArtifactFilter[]> = new EventEmitter<ArtifactFilter[]>();

  tabsActiveState: boolean[] = [];

  protected readonly ArtifactListItemClickAction: typeof ArtifactListItemClickAction = ArtifactListItemClickAction;
  protected readonly http_https_validation_regex: RegExp = HTTP_HTTPS_VALIDATION_REGEX;
  protected readonly BaseDataType: typeof BaseDataType = BaseDataType;

  constructor(
    protected readonly filterTypeDetectionService: FilterTypeDetectionService,
    private readonly filterService: ArtifactFiltersService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  get isEmittingUrlKeyEditable(): boolean {
    return !this.isAddToLinkDisabled && this.clickAction !== this.ArtifactListItemClickAction.selectItem;
  }

  get isPageOpeningEditable(): boolean {
    return this.clickAction !== this.ArtifactListItemClickAction.addToLink && this.clickAction !== this.ArtifactListItemClickAction.selectItem;
  }

  get filters(): ArtifactFilter[] {
    return this.filtersHolder[this.filtersKey];
  }

  set filters(filters: ArtifactFilter[]) {
    this.filtersHolder[this.filtersKey] = filters;
  }

  ngOnInit(): void {
    this.options.attributes = this.options.attributes.filter((attribute: NewAttribute | NewClientAttribute) =>
      this.isAttributeAvailableToPassAsQueryParam(attribute),
    );
  }

  onClickActionChange(clickAction: ArtifactListItemClickAction): void {
    this.multiselect = clickAction === ArtifactListItemClickAction.selectItem;
    this.clickActionChange.emit(clickAction);
    this.multiselectChange.emit(this.multiselect);
  }

  onFilterAttributeChange(newAttributeOrLinkType: NewAttribute | NewClientAttribute | DirectionalLinkType, updatedFilter: ArtifactFilter, index: number): void {
    this.filterService.updateFilter(updatedFilter, newAttributeOrLinkType, this.options.dataTypes);
    this.filters = this.filters.map((filter: ArtifactFilter) => {
      if (filter === updatedFilter) {
        const filterCopy = cloneDeep(filter);

        if (!filter.dataType?.isEnum && (filter.dataType?.baseDataType === BaseDataType.text || this.filterTypeDetectionService.isFolderPath(filter))) {
          filterCopy.value = new TextFilter();
        }

        return filterCopy;
      }

      return filter;
    });
    this.tabsActiveState = this.tabsActiveState.map((state: boolean, stateIndex: number) => stateIndex === index);
    this.cdRef.detectChanges();
  }

  addFilter(): void {
    this.filters = [...this.filters, ArtifactFilter.initial()];
  }

  removeFilter(removedFilter: ArtifactFilter): void {
    this.filters = this.filters.filter((filter: ArtifactFilter) => filter !== removedFilter);
    this.filtersChange.emit();
  }

  onEnumValueSelect({ index, isCtrl }: FilterWidgetSelectTag, filter: ArtifactFilter): void {
    const value = filter.dataType!.values![index].value;
    const isAlreadySelected = filter.value.selectedEnumValues.includes(value);
    let selectedEnumValues: string[] = [];

    if (isAlreadySelected) {
      selectedEnumValues = filter.value.selectedEnumValues.filter((existingValue: string) => existingValue !== value);
    } else {
      if (isCtrl) {
        selectedEnumValues = [...filter.value.selectedEnumValues, value];
      } else {
        selectedEnumValues = [value];
      }
    }

    filter.value.selectedEnumValues = selectedEnumValues;
  }

  private isAttributeAvailableToPassAsQueryParam(attribute: NewAttribute | NewClientAttribute): boolean {
    if (attribute instanceof NewClientAttribute || attribute.isSystemAttribute) {
      return true;
    }

    const dataType = this.options.dataTypes.find(({ id }) => id === attribute.dataTypeId);

    if (dataType?.isEnum) {
      return true;
    }

    return !!dataType?.isSimple && (dataType.isText || dataType.isHyperlink || dataType.isBoolean || dataType.isDate || dataType.isDateTime || dataType.isUser);
  }
}
