import { WorkflowConditionGroupDto } from '@api/models/workflow-condition-group-dto';
import { RuleConditionValueConverterService } from '@workflows/shared/services/rule-condition-value-converter.service';
import { RuleConditionOperatorType, WorkflowConditionType } from '@workflows/types';
import { RuleCondition, RuleConditionValue } from '@workflows/types/conditions/rule-condition';
import { cloneDeep } from 'lodash';

export class RuleConditionGroup implements WorkflowConditionGroupDto {
  conditions: RuleCondition[];
  groups: RuleConditionGroup[];
  operator: RuleConditionOperatorType;

  constructor(
    private readonly conditionConverter: RuleConditionValueConverterService,
    group?: WorkflowConditionGroupDto,
  ) {
    group && this.fromDto(group);
  }

  static defaultCondition(): RuleCondition {
    return new RuleCondition({
      operationType: WorkflowConditionType.CONTAINS,
      source: new RuleConditionValue({ value: '', isDynamic: true, manual: false }),
      destination: new RuleConditionValue({ value: '', isDynamic: false, manual: false }),
    });
  }

  static defaultGroup(conditionConverter: RuleConditionValueConverterService): RuleConditionGroup {
    return new RuleConditionGroup(conditionConverter, {
      operator: RuleConditionOperatorType.AND,
      conditions: [RuleConditionGroup.defaultCondition()],
      groups: [],
    });
  }

  toServer(converterService: RuleConditionValueConverterService): WorkflowConditionGroupDto {
    const ruleConditionGroup = cloneDeep(this);
    converterService.valuesToServer(ruleConditionGroup);

    return {
      conditions: ruleConditionGroup.conditions.map(condition => condition.toServer()),
      groups: ruleConditionGroup.groups.map(group => group.toServer(converterService)),
      operator: ruleConditionGroup.operator,
    };
  }

  fromDto(dto: WorkflowConditionGroupDto): void {
    if (dto) {
      Object.assign(this, dto);

      this.conditions = this.conditions.map(
        condition =>
          new RuleCondition({
            source: new RuleConditionValue(condition.source),
            destination: new RuleConditionValue(condition.destination),
            operationType: condition.operationType,
          }),
      );
      this.groups = this.groups?.map(group => new RuleConditionGroup(this.conditionConverter, group)) || [];

      this.conditionConverter.valuesToClient(this);
    }
  }
}
