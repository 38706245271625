<div *ngIf="attributeSettings" [ngStyle]="attributeSettings.settings.outerArea">
  <div [ngStyle]="attributeSettings.settings.innerArea" class="attribute-table">
    <div *ngIf="isTopLabel" class="attribute-table__row">
      <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </div>

    <div class="attribute-table__row">
      <ng-container *ngIf="isLeftLabel">
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
      </ng-container>

      <div
        *ngIf="attributeValue; else customValueTemplate"
        [class.attribute-table__cell--ellipsis]="isEllipsis"
        [ngStyle]="valueSettings.styles"
        class="attribute-table__cell">

        <span *ngIf="attributeSettings?.settings.value.showIcon" [class]="attributeIcon"></span>
        <span *ngIf="attributeValue">
            {{ attributeSettings?.settings.value.styles.prefix }}
          </span>

        <span
          [innerHTML]="attributeValue
            | useDecimalPlaces : attributeSettings?.settings.value.styles.minDecimalPlaces : attributeSettings?.settings.value.styles.maxDecimalPlaces
            | returnsToHtmlBreaks
            | replaceRuntimeVariables : artifactDto | async">
        </span>

        <span *ngIf="attributeValue">
            {{ attributeSettings?.settings.value.styles.suffix }}
          </span>
      </div>

      <ng-template #customValueTemplate>
        <span *ngIf="attributeSettings?.settings.value.showIcon" [class]="attributeIcon"></span>
        <ng-content></ng-content>
      </ng-template>

      <ng-container *ngIf="isRightLabel">
        <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
      </ng-container>
    </div>

    <div *ngIf="isBottomLabel" class="attribute-table__row">
      <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
    </div>
  </div>

  <ng-template #labelTemplate>
    <div
      [class.attribute-table__cell--ellipsis]="isEllipsis"
      [class.attribute-table__cell--fixed]="isRightLabel || isLeftLabel"
      [ngStyle]="labelSettings.styles"
      class="attribute-table__cell">
      <span *ngIf="attributeSettings?.settings.label.showIcon" [class]="attributeIcon"></span>
      {{ label }}
    </div>
  </ng-template>
</div>
