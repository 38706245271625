import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { IS_DELETED_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';

import { SessionFlowService } from './services/session-flow.service';
import { SessionFlowModel } from './types/session-flow.types';

@Component({
  selector: 'app-session-flows',
  templateUrl: './session-flow.component.html',
  styleUrls: ['./session-flow.component.scss'],
  providers: [SessionFlowService],
})
export class SessionFlowComponent extends CoreComponent<SessionFlowService, SessionFlowModel> {
  public canDeactivate = false;

  IS_DELETED_LABEL = IS_DELETED_LABEL;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    service: SessionFlowService,
    announcement: AnnouncementService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new SessionFlowModel(), service, announcement);
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Session Flows' }]);
  }
}
