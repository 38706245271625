import { MenuTypesEnum } from './menu-option.types';
import { FlexDirectionEnum } from '../../shared/types/style.types';

export class MenuWidgetSettings {
  menu = new MenuSettings();
  item: Record<string, MenuItemSettings> = {};

  constructor(settings?: Partial<MenuWidgetSettings>) {
    if (settings) {
      settings.menu && (this.menu = new MenuSettings(settings.menu));
      settings.item && this.mapRecordToModel('item', settings.item, MenuItemSettings);
    }
  }

  private mapRecordToModel<T>(keyInClass: string, record: Record<string, any>, type: { new (...args: any): T }): void {
    for (const key in record) {
      (this as any)[keyInClass][key] = new type(record[key]);
    }
  }
}

export class MenuSettings {
  iconPosition: string | null = null;
  flexDirection = FlexDirectionEnum.row;
  innerFlexDirection = FlexDirectionEnum.row;
  type = MenuTypesEnum.horizontal;
  stretch = true;
  openSubmenuOnHover = true;
  expandPanelToActive = true;

  constructor(settings?: Partial<MenuSettings>) {
    settings && Object.assign(this, settings);
  }
}

export class MenuItemSettings {
  iconPosition: string | null = null;
  mouseOvered = false;
  positioned = 'left';

  constructor(settings?: Partial<MenuItemSettings>) {
    settings && Object.assign(this, settings);
  }
}
