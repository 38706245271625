import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { ImagePointerComponent } from './image-pointer.component';
import { DropdownModule } from 'primeng/dropdown';
import { ColorPickerModule } from 'primeng/colorpicker';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { ImagePipesModule } from '@shared/pipes/internal-image/image-pipes.module';

@NgModule({
  declarations: [ImagePointerComponent],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    CheckboxModule,
    ButtonModule,
    FileUploadModule,
    InputTextModule,
    DropdownModule,
    ColorPickerModule,
    StyleFormModule,
    ImagePipesModule,
  ],
  exports: [ImagePointerComponent],
})
export class ImagePointerModule { }
