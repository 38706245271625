import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models';
import { TranslateService } from '@ngx-translate/core';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { ImplementedWorkflowActionTypes, WorkflowAction, WorkflowActionType, WorkflowActionUseCustomAttributeValues, WorkflowTrigger } from '@workflows/types';
import { WorkflowActionSetAttributeValueDto } from '@workflows/types/actions/action-set-attribute-value';
import { AbstractWorkflowRuleItemComponent } from '../abstract';

@Component({
  selector: 'app-workflows-rule-action',
  templateUrl: './workflow-rule-action.component.html',
  styleUrls: ['./workflow-rule-action.component.scss', '../../workflow-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowRuleActionComponent extends AbstractWorkflowRuleItemComponent {
  @Input() applications: ListContainer<NewApplication>;
  @Input() selectableActions: WorkflowAction[];
  @Input() action: WorkflowAction;
  @Input() triggers: WorkflowTrigger[];
  @Input() linkableArtifactTypes: NewArtifactType[];
  @Input() allAttributes: ListContainer<NewAttribute> = new ListContainer();
  @Input() index: number;

  @Output() onActionTypeUpdate: EventEmitter<WorkflowActionType> = new EventEmitter();
  @Output() onActionPropertyUpdate: EventEmitter<WorkflowAction> = new EventEmitter();

  actionTypes: SelectOption<string, WorkflowActionType>[];
  attributeValues: DataTypeValueResponseDto[];
  expressionValid: boolean;
  protected readonly ActionType = WorkflowActionType;

  constructor(protected translateService: TranslateService, protected cdr: ChangeDetectorRef, private readonly translateUtil: TranslateUtil) {
    super(translateService);

    this.actionTypes = Object.values(WorkflowActionType)
      .filter(action => ImplementedWorkflowActionTypes[action])
      .map(val => new SelectOption(val));

    this.translateActionOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.defaultArtifactType?.currentValue) {
      if (this.action) {
        this.action.artifactTypeId = this.defaultArtifactType.id;
        if (this.action.isAttributeRequired()) {
          this.adjustCustomAttributeValues(this.action.attributeId);
        }
      }
    }
  }

  onActionTypeChange(actionType: WorkflowActionType) {
    this.onActionTypeUpdate.emit(actionType);
  }

  onArtifactTypeChange(artifactTypeId: string) {
    super.onArtifactTypeChange(artifactTypeId);
    this.action.artifactTypeId = artifactTypeId;
    this.onActionPropertyUpdate.emit(this.action);
  }

  onAttributeChange(attributeId: string) {
    this.action.attributeId = attributeId;
    this.action.values = [];
    this.adjustCustomAttributeValues(attributeId);

    if ((this.action.actionSettings as WorkflowActionSetAttributeValueDto).operation) {
      (this.action.actionSettings as WorkflowActionSetAttributeValueDto).operation.value = null as any;
    }
  }

  onExpressionValidation(isValid: boolean) {
    this.expressionValid = isValid;
  }

  /**
   * Custom attribute value specific handling...
   * @param attributeId attribute id
   */
  private adjustCustomAttributeValues(attributeId?: string) {
    if (
      attributeId &&
      (this.action.type === WorkflowActionType.USE_CUSTOM_ATTRIBUTE_VALUES || this.action.type === WorkflowActionType.RESET_ATTRIBUTE_VALUES)
    ) {
      const attribute = this.attributes.find(attr => attr.id === attributeId) as NewAttribute;
      const dataType = this.dataTypes.listMap[attribute.dataTypeId];
      this.attributeValues = dataType.values || [];
      (this.action as WorkflowActionUseCustomAttributeValues).dataTypeId = attribute.dataTypeId;
    }
  }

  private translateActionOptions(): void {
    this.actionTypes.forEach(option => {
      this.translateUtil.get('WORKFLOWS.ACTION.' + option.value).then(res => {
        option.label = res;
      });
    });
  }
}
