import { Pipe, PipeTransform } from '@angular/core';
import { DateFilterEnum, DateRangeFilterEnum } from '@shared/types/filter.types';
import { TimeFilterRuleType } from '../types/date-time-filter-options.types';

@Pipe({ name: 'isTimeFilterCalendarType' })
export class IsTimeFilterCalendarTypePipe implements PipeTransform {
	transform(filterType: DateRangeFilterEnum | DateFilterEnum | TimeFilterRuleType): boolean {
		const { timeIs, timeIsAfter, timeIsBefore, timeIsNot } = TimeFilterRuleType;
		return [timeIs, timeIsAfter, timeIsBefore, timeIsNot].includes(filterType as TimeFilterRuleType);
	}
}
