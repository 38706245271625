<p-accordion *ngIf="showAccordeon else content" >
    <p-accordionTab [header]="'Additional actions'">
        <ng-container [ngTemplateOutlet]="content"></ng-container>
    </p-accordionTab>
</p-accordion>

<ng-template #content>
    <div class="grid">
        <div class="col-4">
        <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
            <p-checkbox [(ngModel)]="customEvent.enable"
                        binary="true" inputId="enableCustomEvent">
            </p-checkbox>
            <label class="ml-2" for="enableCustomEvent">{{ 'Fire event' | translate }}</label>
            </span>
        </span>
        </div>
        <ng-container *ngIf="customEvent.enable">
            <div class="col-2">
                <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddType()"></button>
            </div>
            <div class="col-6">
                <div class="grid">
                    <div class="col-12 pt-4 pb-1" *ngFor="let type of customEvent.types;let i = index">
                        <p-floatLabel>
                            <input pInputText id="custom-event" [(ngModel)]="type" (focusout)="onChange($event, i)"/>
                            <label for="custom-event" translate>Custom event</label>
                        </p-floatLabel>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>
