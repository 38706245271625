import { Pipe, PipeTransform } from '@angular/core';
import { SelectOption } from '@shared/types/shared.types';
import { get } from 'lodash';

@Pipe({ name: 'transformArrayToSelectOptions' })
export class TransformArrayToSelectOptionsPipe<Type = any> implements PipeTransform {
  transform(items: Type[], labelKeysPath?: string, valueKeyPath?: string): SelectOption<string, Type>[] {
    return (items || []).map(item => new SelectOption(labelKeysPath ? get(item, labelKeysPath) : item, valueKeyPath ? get(item, valueKeyPath) : item));
  }
}
