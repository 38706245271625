<ng-container
  *ngIf="(dataType.baseDataType | isDateTime) || (dataType.baseDataType | isDate) || (dataType.baseDataType | isUser)">
  <p-dropdown [inputId]="'dropdown' + fieldId"
              [options]="options"
              [autoDisplayFirst]="false"
              [style]="{'width':'100%'}"
              appendTo="body"
              [(ngModel)]="formatSettings.initialValue" [showClear]="true">
  </p-dropdown>
</ng-container>

<ng-container *ngIf="dataType.baseDataType | isText">
  <textarea
    [(ngModel)]="formatSettings.initialValue"
    [id]="'text' + fieldId"
    autoResize="autoResize"
    pInputTextarea
    rows="1"
    style="width: 100%;"
  >
  </textarea>
</ng-container>

<ng-container *ngIf="(dataType.baseDataType | isHtml) && settings">
  <editor
    [(ngModel)]="formatSettings.initialValue"
    [id]="'editor' + fieldId"
    [init]="settings"
    [apiKey]="editorKey"
    class="editor-enabled"
    outputFormat="html"
  >
  </editor>
</ng-container>
