<p-table
  #table
  [value]="data"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
  [paginator]="meta.totalCount > meta.limit || meta.limit !== meta.limitOptions[0]"
  [resizableColumns]="true"
  [rows]="meta.limit"
  [rowsPerPageOptions]="meta.limitOptions"
  [totalRecords]="meta.totalCount"
  [loading]="loading"
  [columns]="columns"
  editMode="row"
  dataKey="id"
  styleClass="p-datatable-striped"
  appThirdClickUndoSort
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      @for (col of columns; track 'filters' + col.key) {
        <th pResizableColumn class="p-text-truncate">
          {{ col.label }}
          <p-sortIcon [pSortableColumn]="col.key" [field]="col.key"></p-sortIcon>

          @switch (col.key) {
            @case (SESSION_TYPE_KEY) {
              <!-- ### START - SESSION TYPE FILTER ### -->
              <p-columnFilter [field]="col.key" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" matchMode="in" display="menu">
                <ng-template pTemplate="header">
                  <div class="px-3 pt-3 pb-0">
                    <span class="p-text-bold">{{ col.key }}</span>
                  </div>
                </ng-template>
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [(ngModel)]="value" [options]="typeOptions" placeholder="Session flow type" (onChange)="filter($event.value)">
                    <ng-template let-option pTemplate="item">
                      <div>
                        <span class="ml-1">{{ option.label }}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <!-- ### END - SESSION TYPE FILTER ### -->
            }
            @case (SESSION_SCOPES_KEY) {
              <!-- ### START - SESSION SCOPES FILTER ### -->
              <p-columnFilter [field]="col.key" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" matchMode="in" display="menu">
                <ng-template pTemplate="header">
                  <div class="px-3 pt-3 pb-0">
                    <span class="p-text-bold">{{ col.key }}</span>
                  </div>
                </ng-template>
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [(ngModel)]="value" [options]="scopesOptions" placeholder="Session flow scopes" (onChange)="filter($event.value)">
                    <ng-template let-option pTemplate="item">
                      <div>
                        <span class="ml-1">{{ option.label }}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <!-- ### END - SESSION SCOPES FILTER ### -->
            }
          }
        </th>
      }
      <th translate>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-sessionFlow let-columns="columns">
    <tr [ngClass]="{ 'deleted-row-color': sessionFlow.deleted }">
      @for (col of columns; track col.key) {
        <td>
          <div class="p-text-truncate">
            <span>{{ sessionFlow | printSessionFlowToTable: col.key }}</span>
          </div>
        </td>
      }
      <td>
        <div class="table_buttons">
          @if (sessionFlow.typeData) {
            <a [routerLink]="['/admin/session-flow', sessionFlow.id]">
              <button pButton class="p-button p-button-warning" type="button" label="{{ 'Edit' | translate }}" icon="pi pi-pencil" iconPos="left"></button>
            </a>
          } @else {
            <button
              pButton
              class="p-button p-button-secondary"
              type="button"
              icon="pi pi-fw pi-exclamation-circle"
              iconPos="left"
              pTooltip="{{ 'Session flow from different tenant within current domain' | translate }}"
              disabled="true"
            ></button>
          }
        </div>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td [colSpan]="sessionFlowListService.m.columns.length + 1" translate>No records found</td>
    </tr>
  </ng-template>
</p-table>
