export enum GlobalConstantsEnum {
  profileArtifactTypeId = 'profileArtifactTypeId',
  fileArtifactTypeId = 'fileArtifactTypeId',
  emailAttributeId = 'emailAttributeId',
  expirationDateAttributeId = 'expirationDateAttributeId',
  nameAttributeId = 'nameAttributeId',
  ownerAttributeId = 'ownerAttributeId',
  primaryTextAttributeId = 'primaryTextAttributeId',
  profilePictureAttributeId = 'profilePictureAttributeId',
  seenAttributeId = 'seenAttributeId',
  sequenceAttributeId = 'sequenceAttributeId',
  currentUserPrivateFolderId = 'currentUserPrivateFolderId',
  usersFolderId = 'usersFolderId',
  currentUserDefaultApplicationId = 'currentUserDefaultApplicationId',
  currentUserDefaultTeamId = 'currentUserDefaultTeamId',
  everyoneTeamId = 'everyoneTeamId',
  teamsFolderId = 'teamsFolderId',
  publicUserProfileId = 'publicUserProfileId',
}
