/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ArtifactGroupResponseDto } from '../../models/artifact-group-response-dto';

export interface ArtifactControllerGroupby$Params {
  filter?: {
};
  groupBy: string;
  dateFormat?: 'YEAR' | 'MONTH' | 'DAY' | 'WEEK' | 'HOUR' | 'MINUTE' | 'SECOND';
  attributeIds?: Array<string>;
  subOperations?: Array<'sum' | 'min' | 'max'>;
  limit?: number;
  skip?: number;

}

export function artifactControllerGroupby(http: HttpClient, rootUrl: string, params: ArtifactControllerGroupby$Params, context?: HttpContext): Observable<StrictHttpResponse<ArtifactGroupResponseDto>> {
  const rb = new RequestBuilder(rootUrl, artifactControllerGroupby.PATH, 'get');
  if (params) {
    rb.query('filter', params.filter, {});
    rb.query('groupBy', params.groupBy, {});
    rb.query('dateFormat', params.dateFormat, {});
    rb.query('attributeIds', params.attributeIds, {});
    rb.query('subOperations', params.subOperations, {});
    rb.query('limit', params.limit, {});
    rb.query('skip', params.skip, {});
  }
  rb.header('ngsw-bypass', 'true', {});

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
        filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ArtifactGroupResponseDto>;
    })
  );
}

artifactControllerGroupby.PATH = '/api/tenant/artifact/groupby';
