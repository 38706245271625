import { StylesDto } from '@private/pages/page-management/page-builder-graphical/types/styles-dto';
import { BackgroundTypeEnum, BorderWidthEnum, ContainerClassEnum, OverflowEnum, ShadowStylesEnum } from '@widgets/shared/types/style.types';

export class Styles implements Partial<StylesDto> {
  paddingTop = '0px';
  paddingRight = '0px';
  paddingBottom = '0px';
  paddingLeft = '0px';

  borderTopLeftRadius = '0px';
  borderTopRightRadius = '0px';
  borderBottomRightRadius = '0px';
  borderBottomLeftRadius = '0px';
  borderRadiusRange: number[] = [0, 100];

  boxShadow: string = ShadowStylesEnum.none;

  backgroundColor = 'initial';
  backgroundImage?: string;

  background: string;
  gradient = '';
  gradientDirection = 'to right';
  gradientColor1 = '#fff';
  gradientColor2 = '#ccc';

  backgroundType: BackgroundTypeEnum = BackgroundTypeEnum.color;
  containerClass: ContainerClassEnum = ContainerClassEnum.box;

  maxWidth = '';
  minWidth = '';

  marginTop = '0px';
  marginRight = '0px';
  marginBottom = '0px';
  marginLeft = '0px';

  borderTopWidth = BorderWidthEnum.thin;
  borderRightWidth = BorderWidthEnum.thin;
  borderBottomWidth = BorderWidthEnum.thin;
  borderLeftWidth = BorderWidthEnum.thin;

  borderTopStyle?: string;
  borderRightStyle?: string;
  borderBottomStyle?: string;
  borderLeftStyle?: string;

  borderTopColor?: string;
  borderRightColor?: string;
  borderBottomColor?: string;
  borderLeftColor?: string;

  overflowX: OverflowEnum = OverflowEnum.visible;
  overflowY: OverflowEnum = OverflowEnum.visible;

  columnGap: string;
  rowGap: string;

  constructor(dto: Partial<StylesDto> = {}) {
    Object.assign(this, dto);
    this.initBackgroundStyles(dto);
  }

  protected initBackgroundStyles(dto: Partial<StylesDto> = {}): void {
    dto.backgroundType === BackgroundTypeEnum.color && (this.background = this.backgroundColor);
    dto.backgroundType === BackgroundTypeEnum.image && (this.background = this.backgroundImage || '');
  }
}
