import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowCustomRunComponent } from '@private/pages/workflow-management/workflow/components/workflow-custom-run/workflow-custom-run.component';
import { WorkflowService } from '@private/pages/workflow-management/workflow/services/workflow.service';
import { WorkflowModel } from '@private/pages/workflow-management/workflow/types/workflow.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { RuleAdministrationType } from '@workflows/shared';
import { WorkflowOwnerType } from '@workflows/types';
import { WorkflowTriggerManual } from '@workflows/types/triggers/trigger-manual';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss'],
  providers: [WorkflowService],
})
export class WorkflowComponent extends CoreComponent<WorkflowService, WorkflowModel> {
  @ViewChild('runWorkflowOverlayPanel') runWorkflowOverlayPanel: OverlayPanel;
  @ViewChild('customRunComponent') customRunComponent: WorkflowCustomRunComponent;

  protected readonly RuleAdministrationType = RuleAdministrationType;
  protected readonly RuleOwnerType = WorkflowOwnerType;

  constructor(
    route: ActivatedRoute,
    router: Router,
    translate: TranslateService,
    public service: WorkflowService,
    announcement: AnnouncementService,
    private breadcrumbService: BreadcrumbService,
  ) {
    super(route, router, translate, new WorkflowModel(), service, announcement);
  }

  protected onInit(): void {
    super.onInit();
    this.breadcrumbService.setItems([{ label: 'Workflow' }]);
  }

  async save(): Promise<void> {
    await this.s.save();
  }

  openRunWorkflowOverlay(event: MouseEvent): void {
    this.customRunComponent.inputData = Object.entries((this.m.rule.triggers[0] as WorkflowTriggerManual).triggerSettings.inputData.value).map(
      ([key, value]) => ({
        key,
        value: value[0] === 'STRING' ? '' : [''],
        isArray: false,
        isNull: false,
      }),
    );

    this.runWorkflowOverlayPanel.show(event);
  }
}
