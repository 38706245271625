import { Injectable } from '@angular/core';
import { RegularUserResponseDto } from '@api/models';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { TenantArtifactTypeService } from '@api/services/tenant-artifact-type.service';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { ArtifactListComponent } from '@private/pages/artifact-management/artifact-list/artifact-list.component';
import { ArtifactListModel } from '@private/pages/artifact-management/artifact-list/types/artifact-list.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { ID_KEY, ID_LABEL, NAME_KEY } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { GetAttributeFromClientAttribute } from '@shared/methods/artifact.methods';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { TableColumn } from '@shared/types/table.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { lastValueFrom, Subscription } from 'rxjs';

@Injectable()
export class ArtifactListService extends CoreService<ArtifactListComponent, ArtifactListModel> {
  private artifactTypes$: Subscription;
  private selectedApplication$: Subscription;

  constructor(
    private readonly tenantArtifactTypeService: TenantArtifactTypeService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly cache: NewCacheService,
    private readonly elvisUtil: ElvisUtil,
  ) {
    super();
  }

  deleteSubscriptions(): void {
    this.artifactTypes$ && this.artifactTypes$.unsubscribe();
    this.selectedApplication$ && this.selectedApplication$.unsubscribe();
  }

  async init(context: ArtifactListComponent, model: ArtifactListModel): Promise<void> {
    super.init(context, model);
    this.initSubscriptions();
  }

  async setArtifactTypeToUrl(): Promise<void> {
    await this.c.router.navigate([], { queryParams: { type: this.m.artifactType?.id || null } });
  }

  async filterTableColumns(): Promise<void> {
    this.setColumns();
    this.m.selectedAttributeIds.forEach(attributeId => {
      if (this.m.artifactType) {
        this.m.columns.push(
          new TableColumn(
            GetAttributeFromClientAttribute(
              Object.values(this.m.artifactType.attributes).find(item => GetAttributeFromClientAttribute(item, this.m.attributes.listMap)?.id === attributeId),
              this.m.attributes.listMap,
            )?.name || '',
            attributeId,
          ),
        );
      }
    });
  }

  async prepareAndAutoSelectAttributeOptions(): Promise<void> {
    this.m.selectedAttributeIds = [];
    try {
      if (this.m.artifactType) {
        this.m.attributeOptions = this.elvisUtil.transformAnyToSelectOptions(
          Object.values(this.m.artifactType.attributes).map(item => GetAttributeFromClientAttribute(item, this.m.attributes.listMap)),
          NAME_KEY,
          ID_KEY,
        );
      }
    } catch {
      this.m.attributeOptions = [];
    }

    this.m.attributeOptions.forEach((option, index) => {
      index < 5 && this.m.selectedAttributeIds.push(option.value);
    });
    await this.filterTableColumns();
  }

  private setColumns(): void {
    this.m.columns = [new TableColumn(ID_LABEL, ID_KEY)];
  }

  private initSubscriptions(): void {
    const data = this.cache.data;
    if (this.c.isFirstCall) {
      this.c.registerSubscriptions([
        data.artifactTypes.subscribe(
          artifactTypes =>
            artifactTypes &&
            this.m.artifactTypes.setList(
              (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
              'id',
            ),
        ),
        data.dataTypes.subscribe(
          dataTypes =>
            dataTypes &&
            this.m.dataTypes.setList(
              (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
              'id',
            ),
        ),
        data.users.subscribe(users => users && this.m.users.setList(users as RegularUserResponseDto[], 'id')),
        data.attributes.subscribe(async attributes => {
          if (!attributes) return;

          this.m.attributes.setList(
            (attributes as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
            'id',
          );
          this.selectedApplication$ = this.applicationSwitcherService.selectedApplication$.subscribe(async () =>
            setTimeout(async () => await this.setArtifactTypeToUrl().then(() => this.loadNewData())),
          );
          this.artifactTypes$ = data.artifactTypes.subscribe(() => setTimeout(async () => await this.loadNewData()));
          await this.setRows();
        }),
      ]);
    }
  }

  private async setRows(): Promise<void> {
    if (this.c.queryParams) {
      setTimeout(async () => {
        const filter = JSON.stringify({ $and: [{ artifactTypeId: { $in: [{ $oid: this.c.queryParams.type }] } }] });
        this.m.rows = (await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: { filter } }))).data.map(
          dto =>
            new NewArtifact({
              dto,
              artifactTypesMap: this.m.artifactTypes.listMap,
            }),
        );
      });
    } else {
      this.m.rows = [];
    }
  }

  private async loadNewData(setNewArtifactType = true): Promise<void> {
    const { selectedApplication } = this.applicationSwitcherService;
    if (selectedApplication) {
      const filter = JSON.stringify({ applicationId: { $in: [{ $oid: selectedApplication.id }] } });
      const artifactTypes = (await lastValueFrom(this.tenantArtifactTypeService.artifactTypeControllerList({ filter }))).data.map(
        artifactType => new NewArtifactType(artifactType),
      );

      this.m.showTable = false;
      this.m.artifactTypeOptions = this.elvisUtil.transformAnyToSelectOptions(artifactTypes, NAME_KEY);
      if (setNewArtifactType) {
        this.m.artifactType = artifactTypes.find(artifactType => artifactType.id === this.c.queryParams.type) || null;
      }

      this.m.artifactType ? await this.prepareAndAutoSelectAttributeOptions() : await this.autoSelectFirstArtifactType();

      setTimeout(() => (this.m.showTable = true));
    }
  }

  private async autoSelectFirstArtifactType(): Promise<void> {
    this.m.attributeOptions = [];
    if (this.m.artifactTypeOptions.length) {
      this.m.artifactType = this.m.artifactTypeOptions[0].value;
      await this.setArtifactTypeToUrl().then(() => this.loadNewData(false));
    }
  }
}
