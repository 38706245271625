import { PageRowBlockRequestDto } from '@api/models/page-row-block-request-dto';
import { PageRowBlockResponseDto } from '@api/models/page-row-block-response-dto';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { PageElementTypes } from '@private/pages/page-management/page-builder-graphical/types/page-element-types';

export class PageBlock {
  pageElementType: PageElementTypes = PageElementTypes.block;

  constructor(public parts: PageBlockPart[] = [new PageBlockPart()], public templateId: string | null = null) {}

  get requestDto(): PageRowBlockRequestDto {
    return {
      parts: this.parts.map((part: PageBlockPart) => part.requestDto),
      templateId: this.templateId,
    };
  }

  static fromDto(dto: PageRowBlockResponseDto): PageBlock {
    const parts = dto.parts?.map(PageBlockPart.fromDto);

    return new PageBlock(parts, dto.templateId);
  }

  removeIdsForReuse(removeHtmlIds = false): void {
    this.parts.forEach((part: PageBlockPart) => part.removeIdsForReuse(removeHtmlIds));
  }

  deletePart({ partIndex }: Pick<PartLocation, 'partIndex'>): void {
    if (this.parts.length === 1 && this.parts[partIndex].widget) {
      this.parts.splice(partIndex, 1, new PageBlockPart());
    } else {
      this.parts.splice(partIndex, 1);
    }
  }
}
