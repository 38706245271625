import { Pipe, PipeTransform } from '@angular/core';
import { SessionFlowResponseDto, TenantAdminSessionFlowResponseDto } from '@api/models';
import { CONST_SESSION_SCOPES_KEY } from '@private/pages/current-tenant/session-flow-list/services/session-flow-list.service';
import { ObjectUtil } from '@shared/utils/object.util';

@Pipe({ standalone: true, name: 'printSessionFlowToTable' })
export class PrintSessionFlowToTablePipe implements PipeTransform {
  constructor(private readonly objectUtil: ObjectUtil) {}

  transform(sessionFlow: SessionFlowResponseDto | TenantAdminSessionFlowResponseDto, key: string): string {
    if (key === CONST_SESSION_SCOPES_KEY) {
      if ((sessionFlow as TenantAdminSessionFlowResponseDto).typeData) return (sessionFlow as TenantAdminSessionFlowResponseDto).typeData.scopes.join(', ');
      return (sessionFlow as SessionFlowResponseDto).scopes.join(', ');
    }
    return this.objectUtil.getValueFromPath(key, sessionFlow);
  }
}
