<div *ngIf="row.styles" class="style-block">
  <p-accordion [multiple]="true">
    <p-accordionTab [header]="'Row container' | translate" [selected]="true">
      <app-page-element-styler [applicationId]="applicationId" [styles]="row.styles"></app-page-element-styler>
    </p-accordionTab>

    <p-accordionTab [header]="'Responsive design' | translate">
      <app-responsive-design-settings *ngIf="row.rowHide" [hidingSettings]="row.rowHide"></app-responsive-design-settings>
    </p-accordionTab>
  </p-accordion>
</div>
