@let settings = condition.extra!.settings ;

@if (settings.operationType | isFilterCalendarType) {
  <p-calendar [(ngModel)]="condition.destination.value" [showTime]="settings.isDateTime"></p-calendar>
} @else if (settings.operationType | isFilterNumericType) {
  <p-inputNumber [(ngModel)]="settings.offsetInDays"></p-inputNumber>
} @else if (settings.operationType | isFilterRangeType) {
  <div class="date-range-container">
    <p-calendar [(ngModel)]="settings.start" [placeholder]="'From' | translate"></p-calendar>
    <span> - </span>
    <p-calendar [(ngModel)]="settings.end" [placeholder]="'To' | translate" [minDate]="settings.start"></p-calendar>
  </div>
}
