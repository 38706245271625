import { computed, Injectable, signal, Signal, WritableSignal } from '@angular/core';

export type FormFieldsVisibilityEvent = {
  widgetId: string;
  attributes: Record<string, boolean>;
};

export type FormFieldsValueEvent = {
  widgetId: string;
  attributes: Record<string, any>;
};

export type FormCounterEvent = {
  counterOptionValue?: boolean;
};

@Injectable({ providedIn: 'root' })
export class FormFieldCommunicatorService {
  private _formFieldVisibilitySignal: WritableSignal<FormFieldsVisibilityEvent> = signal({ widgetId: '', attributes: {} });
  private _formFieldValuesSignal: WritableSignal<FormFieldsValueEvent> = signal({ widgetId: '', attributes: {} });
  private _formCounterSignal: WritableSignal<FormCounterEvent> = signal({});

  get formFieldVisibilitySignal(): Signal<FormFieldsVisibilityEvent> {
    return computed(this._formFieldVisibilitySignal);
  }

  get formFieldValueSignal(): Signal<FormFieldsValueEvent> {
    return computed(this._formFieldValuesSignal);
  }

  get formCounterSignal(): Signal<FormCounterEvent> {
    return computed(this._formCounterSignal);
  }

  notifyFormCounter(counterOptionValue: boolean): void {
    this._formCounterSignal.set({ counterOptionValue });
  }

  notifyFormFieldVisibility(widgetId: string, attributes: Record<string, boolean>): void {
    this._formFieldVisibilitySignal.set({ widgetId, attributes });
  }

  notifyFormFieldValue(widgetId: string, attributeId: string, value: any): void {
    this._formFieldValuesSignal.set({ widgetId, attributes: { ...this._formFieldValuesSignal().attributes, [attributeId]: value } });
  }
}
