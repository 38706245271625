import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { TenantExportImportService, TenantPageService } from '@api/services';
import { NewCacheService } from '@shared/cache/new-cache.service';
import {
  ALIAS_KEY,
  ALIAS_LABEL,
  APPLICATION_ID_KEY,
  APPLICATION_LABEL,
  ID_KEY,
  ID_LABEL,
  IS_PUBLIC_KEY,
  IS_PUBLIC_LABEL,
  NAME_KEY,
  NAME_LABEL,
  UPDATED_ON_KEY,
  UPDATED_ON_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { TableColumn } from '@shared/types/table.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { Table } from 'primeng/table';
import { PagesModel } from '../types/pages.types';

@Injectable()
export class PagesService extends CoreService<any, PagesModel> {
  constructor(
    public readonly elvisUtil: ElvisUtil,
    private readonly tenantPageService: TenantPageService,
    private readonly tenantExportImportService: TenantExportImportService,
    private readonly cache: NewCacheService,
  ) {
    super();
  }

  async init(context: any, model: PagesModel): Promise<void> {
    super.init(context, model);

    this.setColumns();
    this.initSubscriptions();
  }

  getImportMethod(): any {
    return this.tenantExportImportService.exportImportControllerImport.bind(this.tenantExportImportService);
  }

  getExportMethod(): any {
    return this.tenantPageService.pageControllerExport$Response.bind(this.tenantPageService);
  }

  doFilter(table: Table): void {
    table && table.filter(null, '', '');
  }

  private setColumns(): void {
    this.m.columns = [
      new TableColumn(ID_LABEL, ID_KEY),
      new TableColumn(NAME_LABEL, NAME_KEY),
      new TableColumn(APPLICATION_LABEL, APPLICATION_ID_KEY),
      new TableColumn(IS_PUBLIC_LABEL, IS_PUBLIC_KEY),
      new TableColumn(UPDATED_ON_LABEL, UPDATED_ON_KEY),
      new TableColumn(ALIAS_LABEL, ALIAS_KEY),
    ];
  }

  private initSubscriptions(): void {
    this.c.registerSubscription(
      this.cache.data.applications.subscribe(applications => applications && this.m.applications.setList(applications as ApplicationResponseDto[], ID_KEY)),
    );
  }
}
