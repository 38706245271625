import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { AnnouncementService } from '@shared/services/announcement.service';
import { ChartWidgetService } from '@widgets/chart-widget/services/chart-widget.service';
import { ChartWidgetModel, ChartWidgetModelDto, ChartWidgetValue } from '@widgets/chart-widget/types/chart-widget.types';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import {
  APPLICATION_ID,
  IS_IN_CARD,
  IS_LAYOUT_MODE,
  LABEL,
  WIDGET
} from '@widgets/widgets-core/constants/widgets-core.constants';

@Component({
  selector: 'app-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss'],
  providers: [ChartWidgetService],
})
export class ChartWidgetComponent extends WidgetsCoreComponent<ChartWidgetValue> implements OnInit {
  advancedWidget: BlockPartWidget | null;
  m: ChartWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<ChartWidgetValue>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    @Inject(IS_IN_CARD) public isInsideCard: boolean,
    public readonly s: ChartWidgetService,
  ) {
    super(route, router, announcement, elRef);
  }

  get chartId(): string {
    return 'chart' + this.widget.id;
  }

  ngOnInit(): void {
    this.s.init(this, this.isLayoutMode && this.widget.value?.model ? (this.widget.value.model as any as ChartWidgetModelDto) : null);
  }
}
