import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BgGradientSettingsComponent } from '@shared/components/page-element-styler/components/bg-gradient-settings/bg-gradient-settings.component';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ContainerClassComponent } from './components/container-class/container-class.component';
import { MaxWidthComponent } from './components/max-width/max-width.component';
import { StickyPositioningComponent } from './components/sticky-positioning/sticky-positioning.component';
import { PageElementStylerComponent } from './page-element-styler.component';
import { IsBackgroundOfTypePipe } from './pipes/is-background-of-type.pipe';
import { ImagePointerModule } from '../image-pointer/image-pointer.module';

@NgModule({
  declarations: [
    IsBackgroundOfTypePipe,
    ContainerClassComponent,
    MaxWidthComponent,
    PageElementStylerComponent,
    StickyPositioningComponent,
    BgGradientSettingsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,

    ButtonModule,
    DropdownModule,
    CheckboxModule,
    OverlayPanelModule,
    InputTextModule,
    RadioButtonModule,

    StyleFormModule,
    InputTextareaModule,

    ImagePointerModule
  ],
  exports: [PageElementStylerComponent, StickyPositioningComponent, IsBackgroundOfTypePipe, BgGradientSettingsComponent],
})
export class PageElementStylerModule {}
