import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthWidgetModel } from '@widgets/auth-widget/types/auth-widget.types';
import { AuthWidgetService } from '@widgets/auth-widget/services/auth-widget.service';

@Component({
  selector: 'app-user-icon',
  templateUrl: './user-icon.component.html',
  styleUrls: ['./user-icon.component.scss'],
})
export class UserIconComponent {
  @Input() m: AuthWidgetModel;
  @Input() s: AuthWidgetService;

  @Output() onClick = new EventEmitter();

  clickHandler($event: any): void {
    this.onClick.emit($event);
  }

  get styles(): any {
    const { userletterStyle, userIconStyle } = this.m.settings;
    return { justifyContent: this.s.isShowLetters ? userletterStyle.textAlign: userIconStyle.textAlign };
  }
}
