import { AfterViewInit, Component, Input } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { CopyAttributeValueActionWorkflowSettingsDto } from '@api/models/copy-attribute-value-action-workflow-settings-dto';
import { TranslateService } from '@ngx-translate/core';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import { AbstractWorkflowRuleItemComponent } from '@workflows/components/rule/abstract';
import { ModifyAttributeValueOperation } from '@workflows/shared';
import { CopyAttributeValueKeys, WorkflowActionCopyAttributeValue } from '@workflows/types/actions/action-copy-attribute-value';

@Component({
  selector: 'app-action-copy-attribute-value',
  templateUrl: './action-copy-attribute-value.component.html',
  styleUrls: ['./action-copy-attribute-value.component.scss'],
})
export class ActionCopyAttributeValueComponent extends AbstractWorkflowRuleItemComponent implements AfterViewInit {
  @Input() action: WorkflowActionCopyAttributeValue;
  @Input() allAttributes: ListContainer<NewAttribute> = new ListContainer();
  @Input() users: ListContainer<ArtifactLinkResponseDto> = new ListContainer();
  @Input() index: number;
  @Input() showOperations: boolean;

  operations: ModifyAttributeValueOperation[];
  setToAttributeOptions: NewAttribute[] = [];
  protected readonly RuleKeys = CopyAttributeValueKeys;

  constructor(translateService: TranslateService) {
    super(translateService);
    this.operations = Object.values(ModifyAttributeValueOperation);
  }

  ngAfterViewInit() {
    (this.action.actionSettings as CopyAttributeValueActionWorkflowSettingsDto)[CopyAttributeValueKeys.copyFromAttributeId].value &&
      this.initSetToAttributeOptions();
  }

  onCopyFromAttributeChange(attributeId: string): void {
    this.action.actionSettings[CopyAttributeValueKeys.copyFromAttributeId].value = attributeId;
    this.resetAttributeAndOperation();
    this.initSetToAttributeOptions();
  }

  onSetToAttributeChange(attributeId: string): void {
    this.action.attributeId = attributeId;
  }

  private resetAttributeAndOperation(): void {
    this.action.attributeId = '';
    this.action.actionSettings[CopyAttributeValueKeys.operation].value = null as any;
  }

  private initSetToAttributeOptions(): void {
    const copiedAttribute = this.allAttributes.listMap[this.action.actionSettings[CopyAttributeValueKeys.copyFromAttributeId].value as string];
    const copiedDataType = this.dataTypes.listMap[copiedAttribute.dataTypeId];

    this.setToAttributeOptions = this.selectedAttributes.filter(attribute => {
      const dataType = this.dataTypes.listMap[attribute.dataTypeId];
      const isUserOrEnumMultiple =
        (copiedDataType.kind === DataTypeKind.enumerated || copiedDataType.baseDataType === BaseDataType.user) && copiedAttribute.multipleValues;
      return (
        copiedDataType.kind === dataType.kind && copiedDataType.baseDataType === dataType.baseDataType && (!isUserOrEnumMultiple || attribute.multipleValues)
      );
    });
  }
}
