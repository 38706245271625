import { InjectionToken } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { ListWidgetLinkSettingsTypes } from '@widgets/shared/components/artifact-list-table/types/list-widget-link-settings.types';
import { ArtifactAdditionalData } from '@widgets/shared/types/artifact-additional-data';

export const APPLICATION_ID = new InjectionToken<string>('applicationId');
export const PAGE_ID = new InjectionToken<string>('pageId');
export const CURRENT_PAGE = new InjectionToken<string>('currentPage');
export const WIDGET_ID = new InjectionToken<string>('widgetId');
export const WIDGET = new InjectionToken<BlockPartWidget<{ model: unknown }>>('widget');
export const LABEL = new InjectionToken<string>('label');
export const IS_LAYOUT_MODE = new InjectionToken<boolean>('isLayoutMode');
export const IS_PREVIEW_MODE = new InjectionToken<boolean>('isPreviewMode');
export const DISABLED = new InjectionToken<boolean>('disabled');
export const HASH = new InjectionToken<string | null>('hash');
export const ID_PREFIX = new InjectionToken<string>('idPrefix');
export const IS_LIST_MATRIX_CARD = new InjectionToken<boolean>('isListMatrixCard');
export const ARTIFACT_ADDITIONAL_DATA = new InjectionToken<ArtifactAdditionalData>('artifactAdditionalData');
export const IS_IN_SIDEBAR = new InjectionToken<boolean>('isInSidebar');
export const IS_IN_CARD = new InjectionToken<boolean>('isInCard');
export const ARTIFACT = new InjectionToken<ArtifactResponseDto>('artifact');
export const IS_CLICKABLE = new InjectionToken<boolean>('isClickable');
export const IS_SHOW_PICKER = new InjectionToken<boolean>('isShowPicker');
export const LIST_SELECTION_SETTINGS = new InjectionToken<ListWidgetLinkSettingsTypes>('listSelectionSettings');
