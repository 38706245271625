import { Injectable } from '@angular/core';
import {
  ArtifactLinkResponseDto,
  ArtifactTypeResponseDto,
  ArtifactUpdateRequestDto,
  AttributeResponseDto,
  DataTypeResponseDto,
  LinkTypeResponseDto,
} from '@api/models';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { TenantArtifactTypeService } from '@api/services/tenant-artifact-type.service';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { Environment } from '@environments/environment';
import { ArtifactComponent } from '@private/pages/artifact-management/artifact/artifact.component';
import { ArtifactModel, LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { FileService } from '@private/services/file.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import {
  ARTIFACT_TYPE_KEY,
  ARTIFACT_TYPE_LABEL,
  ID_KEY,
  LINK_TYPE_KEY,
  LINK_TYPE_LABEL,
  NAME_KEY,
  TARGET_KEY,
  TARGET_LABEL,
} from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { GetAttributeFromClientAttribute, GetDataTypeFromClientAttribute } from '@shared/methods/artifact.methods';
import { AttributeValueToClient, AttributeValueToServer } from '@shared/methods/client-attribute.methods';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { lastValueFrom, tap } from 'rxjs';

@Injectable()
export class ArtifactService extends CoreService<ArtifactComponent, ArtifactModel> {
  constructor(
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly tenantArtifactTypeService: TenantArtifactTypeService,
    private readonly applicationSwitcherService: ApplicationSwitcherService,
    private readonly blockUiService: BlockUiService,
    private readonly elvisUtil: ElvisUtil,
    private readonly fileService: FileService,
    private readonly cache: NewCacheService,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
  ) {
    super();
  }

  async init(context: ArtifactComponent, model: ArtifactModel): Promise<void> {
    super.init(context, model);

    await this.initSubscriptions();
    this.initLinkColumns();
    this.initOptions();

    setTimeout(async () => {
      try {
        this.m.inProgress = true;
        await this.setArtifactTypeOptions();

        if (this.c.urlParams.id) {
          await this.initArtifactFromUrlId();
        } else {
          await this.mapAttributesToClient(true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.m.inProgress = false;
      }
    });
  }

  async mapAttributesToClient(useInitialValue: boolean): Promise<void> {
    if (!this.m.artifactType) {
      return;
    }

    if (useInitialValue) {
      this.m.artifact.clientAttributes = Object.keys(this.m.artifactType.attributes).map((id: string) => {
        const artifactTypeAttribute = this.m.artifactType?.attributes[id];
        const value = AttributeValueToClient(
          new AttributeToClientParams({
            dataTypes: this.m.dataTypes,
            attributes: this.m.attributes,
            users: this.m.users.list,
            clientAttribute: this.m.artifactType?.attributes[id],
            value: this.m.artifactType?.attributes[id].initialValue,
          }),
        );

        return new NewClientAttribute({
          id: artifactTypeAttribute?.id,
          value,
          isMandatory: artifactTypeAttribute?.initialValue,
        });
      });
    } else {
      this.m.artifact.clientAttributes.forEach(
        attribute =>
          (attribute.value = AttributeValueToClient(
            new AttributeToClientParams({
              dataTypes: this.m.dataTypes,
              attributes: this.m.attributes,
              users: this.m.users.list,
              clientAttribute: this.m.artifactType?.attributes[attribute.id],
              value: attribute.value,
            }),
          )),
      );
    }

    await this.mapFilesToArtifact(
      this.m.artifact.clientAttributes.filter(
        attr =>
          GetDataTypeFromClientAttribute(attr, this.m.attributes.listMap, this.m.dataTypes.listMap)?.baseDataType === BaseDataType.file && attr.value.length,
      ),
    );
  }

  async setArtifactLinks(): Promise<void> {
    const artifactId = this.m.artifact.id;
    if (!artifactId) {
      return;
    }
  }

  async save(): Promise<void> {
    this.blockUiService.blockUi();
    // let res = null;
    // const { links } = this.m.artifact;
    const { clientAttributes } = this.m.artifact;

    await this.createNewFiles(
      clientAttributes.filter(
        attr => GetDataTypeFromClientAttribute(attr, this.m.attributes.listMap, this.m.dataTypes.listMap)?.baseDataType === BaseDataType.file,
      ),
    );

    if (this.m.artifactType && this.checkMandatoryFields()) {
      const { id } = this.m.artifact;
      const attributes: Record<string, any> = {};
      clientAttributes.forEach(attribute => {
        const dataType = GetDataTypeFromClientAttribute(attribute, this.m.attributes.listMap, this.m.dataTypes.listMap);
        if (dataType?.kind === DataTypeKind.counter) {
          attributes[attribute.id] = { generateValue: true };
        } else {
          attributes[attribute.id] = {
            value: AttributeValueToServer(dataType, GetAttributeFromClientAttribute(attribute, this.m.attributes.listMap), attribute.value),
          };
        }
      });

      const dto: ArtifactResponseDto = id ? await this.updateArtifact(id, { attributes }) : await this.createNewArtifact({ attributes });
      if (dto) {
        this.cache.data.artifacts.setItem(dto);
        this.sendSseNotification(id, dto);
        await this.cancel();
      }

      this.blockUiService.unblockUi();
    }
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl(`/admin/artifact-list`);
  }

  private initSubscriptions(): void {
    const data = this.cache.data;

    this.c.registerSubscriptions([
      this.applicationSwitcherService.selectedApplication$.subscribe(this.applicationSubscriptionCb.bind(this)),
      data.linkTypes.subscribe(linkTypes => this.linkTypesSubscriptionCb(linkTypes!.map(dto => new LinkType(dto as LinkTypeResponseDto)))),
      data.artifactTypes.subscribe(artifactTypes => {
        this.m.artifactTypes.setList(
          artifactTypes!.map(dto => new NewArtifactType(dto as ArtifactTypeResponseDto)),
          ID_KEY,
        );
      }),
      data.attributes.subscribe(attributes =>
        this.m.attributes.setList(
          attributes!.map(dto => new NewAttribute(dto as AttributeResponseDto)),
          ID_KEY,
        ),
      ),
      data.dataTypes.subscribe(dataTypes =>
        this.m.dataTypes.setList(
          dataTypes!.map(dto => new NewDataType(dto as DataTypeResponseDto)),
          ID_KEY,
        ),
      ),
      data.users.subscribe(users => this.m.users.setList(users as ArtifactLinkResponseDto[], ID_KEY)),
    ]);
  }

  private initOptions(): void {
    this.m.rowsPerPage = Environment.tableConfig.rowsPerPage;
    this.m.rowsPerPageOptions = Environment.tableConfig.rowsPerPageOptions;
  }

  private async initArtifactFromUrlId(): Promise<void> {
    const artifact = await lastValueFrom(this.tenantArtifactService.artifactControllerGet({ id: this.c.urlParams.id }));
    this.m.artifact = new NewArtifact({ dto: artifact, artifactTypesMap: this.m.artifactTypes.listMap });
    this.m.artifactType = this.m.artifactTypes.listMap[artifact.artifactTypeId];
    await this.mapAttributesToClient(false);
    await this.setArtifactLinks();
  }

  private async applicationSubscriptionCb(application: NewApplication | null): Promise<void> {
    if (application) {
      await this.setArtifactTypeOptions();
      if (this.m.artifactType?.applicationId !== application.id) {
        this.m.artifact = new NewArtifact();
        await this.mapAttributesToClient(true);
      } else {
        if (this.c.queryParams.type) {
          this.m.artifactType = this.m.artifactTypeOptions.find(({ value }) => value.id === this.c.queryParams.type)?.value || null;
          if (!this.c.queryParams.id) {
            this.m.artifactType && (await this.mapAttributesToClient(true));
          }
        }
      }
    }
  }

  private linkTypesSubscriptionCb(linkTypes: LinkType[]): void {
    linkTypes.forEach(linkType => {
      setTimeout(() => {
        if (linkType.applicationId === this.applicationSwitcherService.selectedApplication?.id) {
          this.m.linkTypeOptions.push(new SelectOption(linkType.outgoingName, linkType, LinkDirection.outgoing));
          this.m.linkTypeOptions.push(new SelectOption(linkType.incomingName, linkType, LinkDirection.incoming));
        }
      });
    });
  }

  private async setArtifactTypeOptions(): Promise<void> {
    const applicationId = this.applicationSwitcherService.selectedApplication?.id;
    if (applicationId) {
      const artifactTypes = (
        await lastValueFrom(
          this.tenantArtifactTypeService.artifactTypeControllerList({ filter: JSON.stringify({ applicationId: { $in: [{ $oid: applicationId }] } }) }),
        )
      ).data.map(at => new NewArtifactType(at));
      this.m.artifactTypeOptions = this.elvisUtil.transformAnyToSelectOptions(artifactTypes, NAME_KEY);
    }
  }

  private checkMandatoryFields(): boolean {
    const fields: any = [];
    let hasMandatoryValues = true;

    this.m.artifact.clientAttributes.forEach(attribute => {
      if (attribute.isMandatory && attribute.value?.length === 0) {
        fields.push(GetAttributeFromClientAttribute(attribute, this.m.attributes.listMap)?.name);
        hasMandatoryValues = false;
      }
    });

    !hasMandatoryValues && this.c.announcement.error(`Mandatory fields: {{fields}} are not set`, { fields: fields.join(', ') });
    return hasMandatoryValues;
  }

  private initLinkColumns(): void {
    this.m.linkColumns = [
      new TableColumn(LINK_TYPE_LABEL, LINK_TYPE_KEY),
      new TableColumn(ARTIFACT_TYPE_LABEL, ARTIFACT_TYPE_KEY),
      new TableColumn(TARGET_LABEL, TARGET_KEY),
    ];
  }

  private async mapFilesToArtifact(attributes: NewClientAttribute[]): Promise<void> {
    const fileIds = new Set();
    attributes.forEach(attr => {
      if (Array.isArray(attr.value)) {
        attr.value?.forEach(file => fileIds.add(file));
      } else {
        attr.value?.length && fileIds.add(attr.value);
      }
    });
    if (fileIds.size) {
      const filter = JSON.stringify({ _id: { $in: [...fileIds].map(id => ({ $oid: id })) } });
      this.tenantArtifactService
        .artifactControllerList({ body: { filter } })
        .pipe(
          tap(({ data }) => {
            attributes.forEach(attr => {
              if (Array.isArray(attr.value)) {
                const attributeFiles: (ArtifactResponseDto | undefined)[] = [];
                attr.value.forEach(value => attributeFiles.push(data.find(fileArtifactRes => fileArtifactRes.id === value)));
                attr.value = attributeFiles;
              } else {
                attr.value = data.find(fileArtifactRes => fileArtifactRes.id === attr.value);
              }
            });
          }),
        )
        .subscribe({
          error(err) {
            console.log(err);
          },
        });
    }
  }

  private async createNewFiles(attributes: NewClientAttribute[]): Promise<void> {
    const folderId = this.applicationSwitcherService.selectedApplication?.defaultFolderId || '';
    for await (const attr of attributes) {
      if (attr.value) {
        attr.value = this.elvisUtil.makeArrayFromValue(attr.value);
        const uploadPromises: Promise<ArtifactResponseDto>[] = [];
        for await (const [index, file] of attr.value.entries()) {
          file &&
            !file.id &&
            uploadPromises.push(
              lastValueFrom(
                this.fileService.uploadFileArtifact(file, folderId).pipe(
                  tap(fileArtifact => {
                    GetAttributeFromClientAttribute(attr, this.m.attributes.listMap)?.multipleValues
                      ? (attr.value[index] = fileArtifact)
                      : (attr.value = fileArtifact);
                  }),
                ),
              ),
            );
        }
        await Promise.all(uploadPromises);
      }
    }
  }

  private async updateArtifact(id: string, { attributes }: any, shouldNotify = false): Promise<ArtifactResponseDto> {
    return await lastValueFrom(
      this.tenantArtifactService.artifactControllerUpdate({
        body: { id, attributes } as ArtifactUpdateRequestDto,
        notify: shouldNotify,
        resetSequence: false,
      }),
    );
  }

  private async createNewArtifact({ attributes }: any, shouldNotify = false): Promise<ArtifactResponseDto> {
    return await lastValueFrom(
      this.tenantArtifactService.artifactControllerCreate({
        body: { attributes, parentFolderId: null, artifactTypeId: this.m.artifactType?.id || '' },
        notify: shouldNotify,
      }),
    );
  }

  private sendSseNotification(id: string | null, dto: ArtifactResponseDto): void {
    const notificationType = id ? RuntimeStateNotificationEnum.updateArtifact : RuntimeStateNotificationEnum.createArtifact;
    this.runtimeStateNotificationService.events$.next(new RuntimeStateNotification(notificationType, dto));
  }
}
