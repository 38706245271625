import { Component, Inject } from '@angular/core';
import { DocumentGenerationAvrInputMapperDto } from '@api/models';
import { TenantArtifactService } from '@api/services';
import { CONST_MIMETYPES_MAP } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/components/document-generation/avr-document-generation.non-mappable-fields.component';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GetSelectOptionsFromEnum } from '@shared/methods/shared.methods';
import { AvrTypes } from '@shared/services/artifact-visual-representation/base.avr.service';
import {
  DocumentGenerationAvrType,
  DocumentGenerationOutputType,
} from '@shared/services/artifact-visual-representation/document-generation/dto/document-generation.input-mapper.dto';
import { ArtifactTypeFormatEnum } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { SelectOption } from '@shared/types/shared.types';
import {
  AbstractAvrQuerySettingsComponent,
  AVR_SETTINGS_INJECTOR,
  AvrSettingsInjectorBody,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/abstract.avr-types-settings.component';
import {
  AvrTemplateFileArtifactSources,
  AvrTemplateFileArtifactSourcesType,
  DocumentGenerationAvrWidgetSettingsDto,
} from '@widgets/avr-widget/components/avr-widget-settings/avr-types-settings/document-generation/types/document-generation.avr-types-settings.types';
import { DocumentGenerationAvrTypeService } from '@widgets/avr-widget/services/avr-types-services/document-generation/document-generation.avr-types-services.service';
import { omit } from 'lodash';
import { lastValueFrom, map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-avr-widget-document-generation-types-settingss',
  templateUrl: './document-generation.avr-types-settings.component.html',
  styleUrls: ['./document-generation.avr-types-settings.component.scss'],
})
export class AvrDocumentGenerationTypesSettingsComponent extends AbstractAvrQuerySettingsComponent<
  DocumentGenerationAvrType,
  DocumentGenerationAvrTypeService
> {
  templateArtifactSourcesOptions: SelectOption<AvrTemplateFileArtifactSourcesType, AvrTemplateFileArtifactSourcesType>[] =
    GetSelectOptionsFromEnum<AvrTemplateFileArtifactSourcesType>(AvrTemplateFileArtifactSources);
  artifactOptions: SelectOption<string, NewArtifact>[] = [];
  AvrTemplateFileArtifactSources = AvrTemplateFileArtifactSources;
  init$: Observable<boolean> = of(false).pipe(
    map(() => {
      const avrMapper = this.service.selectedArtifactType!.avrMapper[AvrTypes.documentGeneration] as DocumentGenerationAvrInputMapperDto;
      if (avrMapper.everythingWithinArtifactType === true) {
        let mimetypes: string[] = [];
        switch (avrMapper.outputType) {
          case DocumentGenerationOutputType.pdf:
            mimetypes = [...CONST_MIMETYPES_MAP[DocumentGenerationOutputType.docx], ...CONST_MIMETYPES_MAP[DocumentGenerationOutputType.pptx]];
            break;
          case DocumentGenerationOutputType.docx:
            mimetypes = [...CONST_MIMETYPES_MAP[DocumentGenerationOutputType.docx]];
            break;
          case DocumentGenerationOutputType.pptx:
            mimetypes = [...CONST_MIMETYPES_MAP[DocumentGenerationOutputType.pptx]];
            break;
          default:
            throw new Error('outputType');
        }

        return JSON.stringify({
          format: ArtifactTypeFormatEnum.file,
          artifactTypeId: { $oid: avrMapper.fileArtifactTypeId },
          'formatData.mimetype': {
            $in: mimetypes,
          },
        });
      }

      return JSON.stringify({
        _id: {
          $in: avrMapper.templates.map(template => {
            return { $oid: template.fileArtifactId };
          }),
        },
        deleted: null,
      });
    }),
    switchMap(filter => {
      return this.artifactService.artifactControllerList({ body: { filter } });
    }),
    map(({ data }) => {
      this.artifactOptions = data.map(artifactResponse => {
        const transformedArtifact = this.service.artifactResponseTransform(artifactResponse, this.service.templateFileArtifactType!);
        const label = transformedArtifact.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value || '';
        return new SelectOption(label, transformedArtifact);
      });

      const avrMapper = this.service.selectedArtifactType!.avrMapper[AvrTypes.documentGeneration] as DocumentGenerationAvrInputMapperDto;
      if (avrMapper.everythingWithinArtifactType === false) {
        const missingArtifacts = avrMapper.templates
          .filter(template => template.fileArtifactId !== '' && data.findIndex(artifact => artifact.id === template.fileArtifactId) === -1)
          .entries();
        for (const [i, missingArtifact] of missingArtifacts) {
          this.service.s.addNonAccessibleOption(this.artifactOptions, i + 1, missingArtifact);
        }
      }

      return true;
    }),
  );

  constructor(
    @Inject(AVR_SETTINGS_INJECTOR) injector: AvrSettingsInjectorBody,
    private readonly artifactService: TenantArtifactService,
  ) {
    super(injector, AvrTypes.documentGeneration as DocumentGenerationAvrType, DocumentGenerationAvrWidgetSettingsDto);
  }

  async onTemplateArtifactSourceChange(): Promise<void> {
    Object.assign(this.settingFields!, omit(new this.avrSettingDto(), 'templateFileArtifactSource'));
    await this.service.initTemplateArtifactSource();
    this.service.updateSelectedArtifact();
  }

  async onTemplateArtifactListeningKeyChange(): Promise<void> {
    this.service.listeningKeyChange.next();
  }

  onStaticArtifactChange(): void {
    lastValueFrom(this.service.setTemplateArtifact$(this.settingFields!.templateFileArtifactId)).then(() => this.service.updateSelectedArtifact());
  }
}
