import { NgComponentOutlet } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';

import { IndexSessionFlowFormComponents } from '@private/pages/current-tenant/session-flow/components/session-flow-form/components/index.session-flow-form.components';
import { SessionFlowService } from '@private/pages/current-tenant/session-flow/services/session-flow.service';
import { SessionFlow, SessionType } from '@shared/types/session-flow.types';
import { SelectOption } from '@shared/types/shared.types';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    NgComponentOutlet,
    // PRIMENG
    CheckboxModule,
    DropdownModule,
    CardModule,
    // CUSTOM
    ...IndexSessionFlowFormComponents.components,
  ],
  selector: 'app-session-flow-form',
  templateUrl: './session-flow-form.component.html',
  styleUrls: ['./session-flow-form.component.scss'],
})
export class SessionFlowFormComponent implements OnInit {
  @Input() sessionFlow: SessionFlow;
  typeOptions: SelectOption<string, SessionType>[];
  indexSessionFlowFormComponents = IndexSessionFlowFormComponents;

  constructor(protected readonly s: SessionFlowService) {}

  ngOnInit(): void {
    this.typeOptions = Object.entries(SessionType).reduce<SelectOption<string, SessionType>[]>(
      (options: SelectOption<string, SessionType>[], [key, value]: [string, SessionType]) => {
        const label = key.charAt(0).toUpperCase() + key.slice(1);
        const option = new SelectOption(label, value);
        options.push(option);

        return options;
      },
      [],
    );
  }
}
