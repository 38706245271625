import { CommonModule } from '@angular/common';
import { Component, effect, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactLinkResponseDto, ArtifactResponseDto, DataTypeValueResponseDto } from '@api/models';
import { DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { ArtifactAttributeFormFieldBooleanComponent } from '@shared/components/artifact-attribute-form-field/components/boolean/artifact-attribute-form-field-boolean.component';
import { ArtifactAttributeFormFieldDateBoundedComponent } from '@shared/components/artifact-attribute-form-field/components/date-bounded/artifact-attribute-form-field-date-bounded.component';
import { ArtifactAttributeFormFieldDateComponent } from '@shared/components/artifact-attribute-form-field/components/date/artifact-attribute-form-field-date.component';
import { ArtifactAttributeFormFieldEnumeratedComponent } from '@shared/components/artifact-attribute-form-field/components/enumerated/artifact-attribute-form-field-enumerated.component';
import { ArtifactAttributeFormFieldHyperlinkComponent } from '@shared/components/artifact-attribute-form-field/components/hyperlink/artifact-attribute-form-field-hyperlink.component';
import { ArtifactAttributeFormFieldLabelWithIconComponent } from '@shared/components/artifact-attribute-form-field/components/label-with-icon/artifact-attribute-form-field-label-with-icon.component';
import { ArtifactAttributeFormFieldNumericBoundedComponent } from '@shared/components/artifact-attribute-form-field/components/numeric-bounded/artifact-attribute-form-field-numeric-bounded.component';
import { ArtifactAttributeFormFieldNumericComponent } from '@shared/components/artifact-attribute-form-field/components/numeric/artifact-attribute-form-field-numeric.component';
import { ArtifactAttributeFormFieldTextComponent } from '@shared/components/artifact-attribute-form-field/components/text/artifact-attribute-form-field-text.component';
import { ArtifactAttributeFormFieldUserComponent } from '@shared/components/artifact-attribute-form-field/components/user/artifact-attribute-form-field-user.component';
import { PreviewFormatSettings } from '@shared/components/artifact-attribute-form-field/types/artifact-format-settings.interface';
import { DataTypeHelper } from '@shared/helpers/data-type.helper';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { FormFieldCommunicatorService } from '@shared/services/form-field-communicator.service';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { AbstractAttributeValueChange, AttributeOptionValuesSettable, AttributeValueSettable, ModifyAttributeValueOperation } from '@workflows/shared';
import { AttributeMandatorySettable } from '@workflows/shared/types/attribute-mandatory-settable';
import { AttributeValueModifiable } from '@workflows/shared/types/attribute-value-modifiable';
import { AttributeVisibleSettable } from '@workflows/shared/types/attribute-visible-settable';
import { cloneDeep } from 'lodash';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MultiSelectModule,
    DropdownModule,
    StyleFormModule,
    DataTypePipesModule,
    ArtifactAttributeFormFieldLabelWithIconComponent,
    ArtifactAttributeFormFieldBooleanComponent,
    ArtifactAttributeFormFieldDateComponent,
    ArtifactAttributeFormFieldDateBoundedComponent,
    ArtifactAttributeFormFieldEnumeratedComponent,
    ArtifactAttributeFormFieldHyperlinkComponent,
    ArtifactAttributeFormFieldNumericComponent,
    ArtifactAttributeFormFieldNumericBoundedComponent,
    ArtifactAttributeFormFieldTextComponent,
    ArtifactAttributeFormFieldUserComponent,
  ],
  selector: 'app-artifact-attribute-form-field',
  templateUrl: './artifact-attribute-form-field.component.html',
  styleUrls: ['./artifact-attribute-form-field.component.scss'],
  providers: [{ provide: AbstractAttributeValueChange, useExisting: ArtifactAttributeFormFieldComponent }],
})
export class ArtifactAttributeFormFieldComponent
  extends AbstractAttributeValueChange
  implements AttributeValueSettable, AttributeValueModifiable, AttributeMandatorySettable, AttributeOptionValuesSettable, AttributeVisibleSettable
{
  @Input() artifactDto: ArtifactResponseDto;
  @Input() clientAttribute: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() users: ListContainer<ArtifactLinkResponseDto>;
  @Input() index: number;
  @Input() onChangeCb: () => void;
  @Input() onBlurCb: () => void;
  @Input() changeFormFocus: () => void;
  @Input() label: string;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() placeholder = '';
  @Input() folderId: string;
  @Input() widgetId?: string;

  @Input() set previewAndFormatSettings(settings: PreviewFormatSettings) {
    settings.formatSettings && (this._formatSettings = settings.formatSettings);
    this.visible = settings.isPreviewMode ? !this._formatSettings.hideOnPageLoad : true;
  }

  @Input() set dataType(dataType: NewDataType) {
    this._dataType = dataType;
    if (dataType?.kind === DataTypeKind.enumerated) {
      this.options = dataType.values || [];
    }
  }

  options: DataTypeValueResponseDto[];
  visible = true;
  private _dataType: NewDataType;
  private _formatSettings: AttributeFormatSettings;

  constructor(
    public readonly h: DataTypeHelper,
    private readonly formFieldCommunicator: FormFieldCommunicatorService,
  ) {
    super();

    effect(() => {
      const visibilityEvent = this.formFieldCommunicator.formFieldVisibilitySignal();
      if (visibilityEvent.widgetId === this.widgetId) {
        this.visible = visibilityEvent.attributes[this.attribute.id];
      }
    });
  }

  get dataType(): NewDataType {
    return this._dataType;
  }

  get formatSettings(): AttributeFormatSettings {
    return this._formatSettings;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onValueChange(newValue: any): void {
    this.onChangeCb && this.onChangeCb();
  }

  setAttributeValue(newValue: any): void {
    this.clientAttribute.value = cloneDeep(newValue);
    this.onValueChange(newValue);
  }

  async modifyAttributeValue(value: any, operation: ModifyAttributeValueOperation): Promise<void> {
    switch (operation) {
      case ModifyAttributeValueOperation.add:
        await this.addValues(value);
        break;
      case ModifyAttributeValueOperation.remove:
        this.removeValues(value);
        break;
      case ModifyAttributeValueOperation.replace:
        await this.replaceValue(value);
        break;
    }

    this.onValueChange(this.clientAttribute.value);
  }

  setAttributeMandatory(mandatory: boolean): void {
    this.clientAttribute.isMandatory = mandatory;
  }

  setAttributeVisible(visible: boolean): void {
    this.visible = visible;
  }

  async onChange(newValue: any): Promise<void> {
    this.onValueChange(newValue);
  }

  onBlur(): void {
    this.onBlurCb?.();
  }

  onFocus(): void {
    this.changeFormFocus?.();
  }

  setAttributeOptionValues(newOptions: DataTypeValueResponseDto[] = this.dataType.values as DataTypeValueResponseDto[]): void {
    // TODO this better when having more time
    if (newOptions && this.options !== newOptions) {
      // setTimeout(() => {
      this.options = [...newOptions];
      // }, 0);
    }
  }

  private async addValues(valueToAdd: any): Promise<void> {
    if (!this.clientAttribute.value?.length) {
      return await this.replaceValue(valueToAdd);
    }

    Array.isArray(valueToAdd) ? valueToAdd.forEach(valueToAdd => this.addValue(valueToAdd)) : this.addValue(valueToAdd);
  }

  private addValue(valueToAdd: any): void {
    if (!valueToAdd) return;

    if (!this.clientAttribute.value.find((attrVal: SelectOption<string, any> | DataTypeValueResponseDto) => attrVal.value === valueToAdd.value)) {
      this.clientAttribute.value = [...this.clientAttribute.value, valueToAdd];
    }
  }

  private async replaceValue(newValue: any): Promise<void> {
    if (!newValue) {
      this.clientAttribute.value = this.attribute.multipleValues ? [] : cloneDeep(newValue);
    } else if (this.attribute.multipleValues) {
      this.clientAttribute.value = Array.isArray(newValue) ? cloneDeep(newValue) : [newValue];
    } else {
      this.clientAttribute.value = this.clientAttribute.value = cloneDeep(newValue);
    }
  }

  private removeValues(valueToRemove: any): void {
    if (!this.clientAttribute.value || !this.clientAttribute.value.length) {
      return;
    }

    if (Array.isArray(valueToRemove)) {
      this.clientAttribute.value = this.clientAttribute.value.filter(
        (attrVal: SelectOption<string, any> | DataTypeValueResponseDto) => !valueToRemove.some(valueToRemove => attrVal.value === valueToRemove.value),
      );
    } else {
      this.removeValue(valueToRemove);
    }
  }

  private removeValue(valueToRemove: any): void {
    const valueIndex = this.clientAttribute.value.findIndex(
      (attrVal: SelectOption<string, any> | DataTypeValueResponseDto) => attrVal.value === valueToRemove.value,
    );
    if (valueIndex !== -1) {
      this.clientAttribute.value.splice(valueIndex, 1);
    }
  }
}
