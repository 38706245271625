import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOption } from '@shared/types/shared.types';
import { ImapFilter, ImapFilterDataType, ImapFilterKeyEnum, ImapFilterKeyWithDataType } from '@workflows/shared/types/imap-filters.types';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-imap-filter',
  standalone: true,
  imports: [CheckboxModule, FormsModule, InputNumberModule, InputTextModule, CalendarModule, DropdownModule, TranslateModule],
  templateUrl: './imap-filter.component.html',
  styleUrls: ['./imap-filter.component.scss', '../../../../../workflow-common.scss'],
})
export class ImapFilterComponent {
  @Input() filter: ImapFilter;
  @Input() filterKeyOptions: SelectOption<string, ImapFilterKeyEnum>[];
  @Input() isDynamic: boolean;
  ImapFilterDataType = ImapFilterDataType;

  onFilterKeyChange(key: ImapFilterKeyEnum) {
    this.filter.dataType = ImapFilterKeyWithDataType[key];
    if (!this.isDynamic) {
      this.filter.value = null;
    }
  }
}
