import { Injectable } from '@angular/core';
import { ApiConfiguration } from '@api/api-configuration';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GlobalConstants } from '@shared/constants/global.constants';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArtifactFormatFileDataResponseDto, ArtifactLinkResponseDto } from '../../../api/models';
import { AuthorizationService } from '../authorization/authorization.service';

@Injectable({ providedIn: 'root' })
export class UserProfilePictureService {
  constructor(
    private readonly apiConfiguration: ApiConfiguration,
    private readonly authorizationService: AuthorizationService,
    private readonly cache: NewCacheService,
  ) {}

  async getUserImageUrl(profileArtifactId?: string): Promise<string | undefined> {
    if (!profileArtifactId) return;

    const artifact: ArtifactResponseDto | undefined = await this.cache.data.artifacts.getAsync(profileArtifactId).catch(() => undefined);
    if (!artifact) return;

    return this.getUserImageUrlFromUserProfileDto(artifact);
  }

  async getUserImageUrlFromUserProfileDto(userProfileDto: ArtifactResponseDto): Promise<string | undefined> {
    const profilePictureId = userProfileDto?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.profilePictureAttributeId)]?.value as string;
    return profilePictureId && this.getUserImageUrlFromPictureId(profilePictureId);
  }

  async getUserImageFileObject$(userProfileDto: ArtifactLinkResponseDto): Promise<ArtifactLinkResponseDto | undefined> {
    const profilePictureId = userProfileDto?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.profilePictureAttributeId)]?.value as string;
    return profilePictureId ? this.cache.data.artifacts.getAsync(profilePictureId).catch(() => undefined) : undefined;
  }

  getUserImageUrlFromPictureId(pictureId: string): string {
    return `${this.apiConfiguration.rootUrl}/api/tenant/artifact/${pictureId}/download?authorization=Bearer%20${this.authorizationService.getToken}`;
  }

  getUserImage$(userProfileDto: ArtifactResponseDto): Observable<string | undefined> {
    return from(this.getUserImageFileObject$(userProfileDto)).pipe(
      map(profileFileObject => {
        if (profileFileObject) {
          const pictureId = (profileFileObject?.formatData as ArtifactFormatFileDataResponseDto).thumbnails?.small || profileFileObject?.id;
          return this.getUserImageUrlFromPictureId(pictureId);
        }
        return undefined;
      }),
    );
  }
}
