import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { RecordDto } from '@api/models/record-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TeamResponseDto } from '@api/models/team-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { UNRESOLVED_VALUE } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { ConvertToClientDate } from '@shared/methods/date.methods';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { RuntimeArtifact, RuntimeArtifactKeys } from '@widgets/shared/types/runtime-variables/runtime-artifact.types';
import { RuntimeVariablesOptions } from '@widgets/shared/types/runtime-variables/runtime-variables-options.types';

export enum RuntimeUserKeys {
  id = 'id',
  email = 'email',
  teams = 'teams',
  consecutiveFailedLoginAttempts = 'consecutive-failed-login-attempts',
  failedLoginAttempts = 'failed-login-attempts',
  lastFailedLoginDate = 'last-failed-login-date',
  createdOn = 'created-on',
  createdBy = 'created-by',
  updatedOn = 'updated-on',
  updatedBy = 'updated-by',
}

export class RuntimeUser {
  [RuntimeUserKeys.id]: string;
  [RuntimeUserKeys.email]: string;
  [RuntimeUserKeys.teams]: string;
  [RuntimeUserKeys.consecutiveFailedLoginAttempts]: number;
  [RuntimeUserKeys.failedLoginAttempts]: number;
  [RuntimeUserKeys.lastFailedLoginDate]: string;
  [RuntimeUserKeys.createdOn]: string;
  [RuntimeUserKeys.createdBy]: string;
  [RuntimeUserKeys.updatedOn]: string;
  [RuntimeUserKeys.updatedBy]: string;

  private cache: NewCacheService;

  constructor(user: SelfUserResponseDto, options: RuntimeVariablesOptions, cache: NewCacheService) {
    this.cache = cache;
    const { id, email, consecutiveFailedLoginAttempts, failedLoginAttempts, lastFailedLoginDate, created, updated } = user;
    const teamIds = user.tenant?.teamIds;

    this.id = id;
    this.email = email;
    this[RuntimeUserKeys.consecutiveFailedLoginAttempts] = consecutiveFailedLoginAttempts;
    this[RuntimeUserKeys.failedLoginAttempts] = failedLoginAttempts;
    this[RuntimeUserKeys.lastFailedLoginDate] = lastFailedLoginDate || '-';

    this.initTeams(teamIds);
    this.initCreatedAndUpdated(created, updated, options);
    Object.assign(this, this.getProfileCustomAttributes(this.cache.userProfile.value as ArtifactLinkResponseDto, options));
  }

  private async initTeams(teamIds?: string[]): Promise<void> {
    if (!teamIds) {
      this.teams = '';
      return;
    }

    const teamsMap = (this.cache.data.teams.getValue() as TeamResponseDto[]).reduce<Record<string, string>>((res, team) => {
      res[team.id] = team.name;
      return res;
    }, {});

    this.teams = teamIds.map(id => teamsMap[id]).join(', ');
  }

  private async initCreatedAndUpdated(created: RecordDto, updated: RecordDto, options: RuntimeVariablesOptions): Promise<void> {
    this[RuntimeUserKeys.createdOn] = ConvertToClientDate(new Date(created.on));
    this[RuntimeUserKeys.createdBy] =
      (options.users.listMap[created.by]?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) || UNRESOLVED_VALUE;

    this[RuntimeUserKeys.updatedOn] = ConvertToClientDate(new Date(updated.on));
    this[RuntimeUserKeys.updatedBy] =
      (options.users.listMap[created.by]?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) || UNRESOLVED_VALUE;
  }

  private getProfileCustomAttributes(userProfile: ArtifactResponseDto, options: RuntimeVariablesOptions): Record<string, string> {
    const customAttributes = new RuntimeArtifact(userProfile, options);
    Object.values(RuntimeArtifactKeys).forEach(key => delete customAttributes[key]);
    return customAttributes as any as Record<string, string>;
  }
}
