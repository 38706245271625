import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';
import { AUTOCOMPLETE_CONTAINER_CLASS } from '@shared/constants/constants';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { WorkflowActionCalculateAttributeValues } from '@workflows/types';
import { delay, fromEvent, merge, Observable, Subject, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-rule-action-calculate-expression',
  templateUrl: 'action-calculate-expression.component.html',
  styleUrls: ['./action-calculate-expression.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCalculateExpressionComponent implements OnDestroy {
  static readonly MEXP_ERROR_PREFIX = 'Error: ';
  static readonly ERROR_VARIABLE_NAMES_KEY = 'WORKFLOWS.ACTION.ERROR_VARIABLE_INCONSISTENCY';
  private readonly WAIT_TIME_BEFORE_INPUT_CHANGES = 150;

  @Input() action: WorkflowActionCalculateAttributeValues;
  @Input() dataTypes: ListContainer<NewDataType>;
  @Input() selectedAttributes: NewAttribute[];
  @Input() editable: boolean;

  @Output() expressionValid: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('calculateInput') set calculateInput(content: ElementRef) {
    if (content) {
      const nativeElement = content.nativeElement;
      this.zone.runOutsideAngular(() => {
        const focus$ = fromEvent(nativeElement, 'focus').pipe(tap(() => this.cdr.detach()));

        const blur$ = fromEvent(nativeElement, 'blur').pipe(
          delay(10),
          tap(() =>
            this.zone.run(() => {
              const activeElement = document.activeElement;
              if (activeElement && !activeElement.closest('.' + AUTOCOMPLETE_CONTAINER_CLASS)) {
                this.cdr.reattach();
                this.checkValidityOfExpression(nativeElement.value);
              }
            }),
          ),
        );
        this.inputSubscription = merge(focus$, blur$).subscribe();
      });
    }
  }

  errorMessage$: Observable<string | undefined>;
  actionInputOwner: string;

  private inputSubscription: Subscription;
  private errorMessageSubject: Subject<string | undefined>;

  constructor(
    private cdr: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    this.errorMessageSubject = new Subject();
    this.errorMessage$ = this.errorMessageSubject.asObservable();
    this.actionInputOwner = ElvisUtil.makeHash(10);
  }

  ngOnDestroy(): void {
    this.inputSubscription?.unsubscribe();
  }

  onCalculateChange(event: any) {
    setTimeout(() => (this.action.expression = event.target.value), this.WAIT_TIME_BEFORE_INPUT_CHANGES);
  }

  private checkValidityOfExpression(expressionValue: string) {
    if (expressionValue.length) {
      try {
        const variableNames = WorkflowActionCalculateAttributeValues.extractExpressionVariables(expressionValue);
        const variableNamesValid = this.areVariableNamesValid(variableNames);
        if (!variableNamesValid) {
          this.handleInvalidExpression(ActionCalculateExpressionComponent.ERROR_VARIABLE_NAMES_KEY);
          return;
        }
        const isValid = WorkflowActionCalculateAttributeValues.isExpressionValid(expressionValue, variableNames) || false;
        if (isValid) {
          this.expressionValid.emit(true);
        }
        this.errorMessageSubject.next(undefined);
      } catch (error: any) {
        const errMsg = error.toString().substring(ActionCalculateExpressionComponent.MEXP_ERROR_PREFIX.length);
        this.handleInvalidExpression(errMsg);
      }
    }
  }

  private areVariableNamesValid(variableNames: string[]): boolean {
    for (let i = 0; i < variableNames.length; i++) {
      const name = variableNames[i];
      const matchedAttribute = this.selectedAttributes.find(attr => attr.alias === name);
      if (!matchedAttribute) {
        return false;
      }
    }
    return true;
  }

  private handleInvalidExpression(errMsg: string) {
    this.expressionValid.emit(false);
    this.errorMessageSubject.next(errMsg);
  }
}
