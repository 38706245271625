<div class="grid widget-settings">
  <div class="col-6">
    <div class="dynamic-label">
      <p-dropdown
        (onChange)="onArtifactSourceChange()"
        [(ngModel)]="m.settings.dataSource.artifactSource"
        [options]="m.options.artifactSourcesOptions"
        [autoDisplayFirst]="false"
        appendTo="body"
        inputId="avrArtifactSource"
      >
        <ng-template let-option pTemplate="item">
          <ng-container *ngTemplateOutlet="artifactSourceOption; context: { $implicit: option }"></ng-container>
        </ng-template>
        <ng-template let-selected pTemplate="selectedItem">
          <ng-container *ngTemplateOutlet="artifactSourceOption; context: { $implicit: selected }"></ng-container>
        </ng-template>

        <ng-template #artifactSourceOption let-option>
          {{ option.label | translate }}
        </ng-template>
      </p-dropdown>
      <label [for]="'avrArtifactSource'" class="dynamic-label-text">{{ 'Artifact source' | translate }}</label>
    </div>
  </div>

  <ng-container *ngIf="isSettingStep(1)">
    <div class="col-6">
      <div class="dynamic-label">
        <p-dropdown
          (onChange)="onArtifactTypeChange()"
          [(ngModel)]="m.settings.dataSource.artifactTypeId"
          [options]="m.options.artifactTypesOptions"
          [autoDisplayFirst]="false"
          [filter]="true"
          appendTo="body"
          inputId="avrWidgetArtifactType"
          optionValue="value.id"
          optionLabel="label"
        >
          <ng-template let-option pTemplate="item">
            <ng-container *ngTemplateOutlet="artifactTypeOption; context: { $implicit: option }"></ng-container>
          </ng-template>
          <ng-template let-selected pTemplate="selectedItem">
            <ng-container *ngTemplateOutlet="artifactTypeOption; context: { $implicit: selected }"></ng-container>
          </ng-template>

          <ng-template #artifactTypeOption let-option>
            {{ option | getAttributeLabelWithApplication : m.options.applications }}
          </ng-template>
        </p-dropdown>
        <label [for]="'avrWidgetArtifactType'" class="dynamic-label-text">{{ 'Artifact type' | translate }}</label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isArtifactSource(AvrArtifactSources.staticArtifact) && isSettingStep(2)">
    <div class="col-6">
      <div class="dynamic-label">
        <p-dropdown
          (onChange)="onSelectedArtifactChange()"
          [options]="m.options.artifactOptions"
          [(ngModel)]="m.settings.dataSource.staticArtifactId"
          [autoDisplayFirst]="false"
          [filter]="true"
          [virtualScroll]="true"
          [virtualScrollItemSize]="30"
          appendTo="body"
          inputId="avrWidgetStaticArtifact"
          optionValue="value.id"
        >
          <ng-template let-item pTemplate="item">
            <ng-container *ngTemplateOutlet="artifactOptionItem; context: { $implicit: item }"></ng-container>
          </ng-template>
          <ng-template let-item pTemplate="selectedItem">
            <ng-container *ngTemplateOutlet="artifactOptionItem; context: { $implicit: item }"></ng-container>
          </ng-template>

          <ng-template #artifactOptionItem let-item>
            {{ item.label }}
          </ng-template>
        </p-dropdown>
        <label [for]="'avrWidgetStaticArtifact'" class="dynamic-label-text">{{ 'Artifact' | translate }}</label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isArtifactSource(AvrArtifactSources.dynamicArtifact) && isSettingStep(1)">
    <div class="col-6">
      <div class="dynamic-label">
        <input
          (input)="onArtifactListeningKeyChange()"
          [(ngModel)]="m.settings.dataSource.artifactListeningKey"
          id="avrWidgetArtifactListeningKey"
          pInputText
          type="text"
          ngClass="full-width-container"
        />
        <label [for]="'avrWidgetArtifactListeningKey'" class="dynamic-label-text">{{ 'URL parameter key for artifact id' | translate }}</label>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isSettingStep(2)">
    <div class="col-12 p-0">
      <hr />
    </div>
    <div class="col-12 p-0 d-flex flex-wrap">
      <div class="col-6">
        <div class="dynamic-label">
          <p-dropdown
            (onChange)="onAvrTypeChange()"
            [(ngModel)]="m.settings.response.avrType"
            [options]="m.options.avrTypesOptions"
            [autoDisplayFirst]="false"
            appendTo="body"
            inputId="avrAvrType"
          >
            <ng-template let-option pTemplate="item">
              <ng-container *ngTemplateOutlet="artifactSourceOption; context: { $implicit: option }"></ng-container>
            </ng-template>
            <ng-template let-selected pTemplate="selectedItem">
              <ng-container *ngTemplateOutlet="artifactSourceOption; context: { $implicit: selected }"></ng-container>
            </ng-template>

            <ng-template #artifactSourceOption let-option>
              {{ option.label }}
            </ng-template>
          </p-dropdown>
          <label [for]="'avrAvrType'" class="dynamic-label-text">{{ 'AVR type' | translate }}</label>
        </div>
      </div>
      <div class="col-6">
        <div class="dynamic-label">
          <input id="avrFileType" [value]="m.currentAvrFileType || ''" [disabled]="true" pInputText ngClass="full-width-container" type="text" />
          <label [for]="'avrFileType'" class="dynamic-label-text">{{ 'File type' | translate }}</label>
        </div>
      </div>
      <div class="col-6">
        <div class="dynamic-label">
          <p-dropdown
            [(ngModel)]="m.settings.response.actionType"
            [options]="m.options.avrActionTypesOptions"
            [autoDisplayFirst]="false"
            appendTo="body"
            inputId="avrActionType"
          >
            <ng-template let-option pTemplate="item">
              <ng-container *ngTemplateOutlet="artifactSourceOption; context: { $implicit: option }"></ng-container>
            </ng-template>
            <ng-template let-selected pTemplate="selectedItem">
              <ng-container *ngTemplateOutlet="artifactSourceOption; context: { $implicit: selected }"></ng-container>
            </ng-template>

            <ng-template #artifactSourceOption let-option>
              {{ option.label }}
            </ng-template>
          </p-dropdown>
          <label [for]="'avrActionType'" class="dynamic-label-text">{{ 'Action type' | translate }}</label>
        </div>
      </div>
    </div>

    <ng-container *ngIf="(s.init$ | async) && settingAvrTypes && settingAvrTypes.includes(m.settings.response.avrType)">
      <div class="col-12 p-0">
        <hr />
        <div class="col-12 pt-0">
          <b>{{ "AVR '\{\{avrType\}\}' settings" | translate : { avrType: avrServices[m.settings.response.avrType].readableName } }}</b>
        </div>
      </div>

      <ng-container *ngComponentOutlet="avrSettingFields[m.settings.response.avrType]; injector: injectorForAvrSettingFields"></ng-container>
    </ng-container>

    <div class="col-12 p-0">
      <hr />
    </div>

    <div class="col-12 p-0 d-flex flex-wrap">
      <div class="col-6">
        <div class="dynamic-label">
          <input [(ngModel)]="m.settings.button.label" id="avrWidgetButtonLabel" pInputText ngClass="full-width-container" type="text" />
          <label [for]="'avrWidgetButtonLabel'" class="dynamic-label-text">{{ 'Button label' | translate }}</label>
        </div>
      </div>
    </div>
  </ng-container>
</div>
