import { AuthWidgetType } from './auth-widget.types';
import { FontStyles, IconStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { UserIconVariantType } from '@widgets/auth-widget/types/auth-widget-options.types';
import { AuthWidgetStyles } from '@widgets/auth-widget/types/auth-widget-styles.types';
import { FlexDirection, FontWeightEnum, TextHorizontalAlignEnum } from '@widgets/shared/types/style.types';

export class AuthWidgetSettings {
  widgetType: AuthWidgetType = AuthWidgetType.plain;
  userDisplayVariant = UserIconVariantType.photo;
  userAltDisplayVariant = UserIconVariantType.icon;
  showIcon = true;
  showEmail = true;
  showName = true;
  showLogoutButton = true;
  showLoginButton = true;
  userIcon = 'bi bi-person-circle';
  userIconStyle = new IconStyles({ order: '0', fontSize: '48px' });
  userletterStyle = new FontStyles({ color: '#ffffff' });
  logoutIcon = 'bi bi-door-open';
  logoutIconStyle = new IconStyles({ fontSize: '24px' });
  loginIcon = 'bi bi-box-arrow-left';
  loginIconStyle = new IconStyles();
  isLogoutIconRound: boolean;
  isLoginIconRound: boolean;
  userNameStyle = new FontStyles({ fontWeight: FontWeightEnum.bold, fontSize: '18px', marginBottom: '5px', textAlign: TextHorizontalAlignEnum.left });
  userEmailStyle = new FontStyles({ textAlign: TextHorizontalAlignEnum.left });
  userDataStyles = new AuthWidgetStyles({ flexDirection: FlexDirection.column, marginLeft: '5px', marginRight: '5px' });

  constructor(settings?: Partial<AuthWidgetSettings>) {
    settings && Object.assign(this, settings);
  }

  get imageStyle(): any {
    return { width: this.userIconStyle.fontSize };
  }

  get letterStyle(): any {
    const { fontSize, color } = this.userIconStyle;
    const styles = {
      ['min-width']: fontSize,
      ['min-height']: fontSize,
      ['line-height']: fontSize,
      width: fontSize,
      height: fontSize,
      backgroundColor: color,
    };

    Object.assign(styles, this.userletterStyle);
    styles.backgroundColor = color;

    return styles;
  }
}
