<div class="layout-topbar">
  <div class="topbar-left">
    <a (click)="app.onMenuButtonClick($event)" class="menu-button" tabindex="0">
      <i class="pi pi-chevron-left"></i>
    </a>
    <a (click)="togglePageBuilderHeader()" class="header-btn">
      <i class="pi" [ngClass]="headerIsVisible ? 'pi-chevron-up': 'pi-chevron-down'"></i>
    </a>
    <span class="topbar-separator"></span>
    <ng-template [ngForOf]="m.breadcrumbs" let-item ngFor>
      <span class="viewname" style="text-transform: uppercase">{{ item.label | translate }}</span>
    </ng-template>
    <img alt="diamond-layout" class="mobile-logo" id="logo-mobile"
         src="../../../../assets/layout/images/logo-dark.svg" />
  </div>

  <div class="topbar-right">
    <ul class="topbar-menu">
      <li [ngClass]="{'active-menuitem fadeInDown': app.topBarUserMenuActive}" class="profile-item">
        <a (click)="app.onTopBarUserMenuButtonClick($event)" href="#">
          <img alt="diamond-layout" class="profile-image"
               [src]="m.userPicture || '../../../../assets/images/default-avatar.jpg'" />
          <span>{{ m.userName }}</span>
        </a>
        <ul class="profile-menu fade-in-up">
          <li>
            <a [routerLink]="[appInterface === appInterfaceEnum.tenant ? '/admin' : '/system', 'profile']">
              <i class="pi pi-user"></i>
              <span translate>Profile</span>
            </a>
          </li>
          <li>
            <a (click)="logout()">
              <i class="pi pi-power-off"></i>
              <span translate>Logout</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
