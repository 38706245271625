import { Injectable } from '@angular/core';
import { CustomEventSettings } from '../types/custom-event';
import { RuntimeStateNotificationService } from './runtime-state-notification.service';
import { RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';

@Injectable({
	providedIn: 'root',
})
export class CustomClickService {
	constructor(private readonly runtimeStateNotificationService: RuntimeStateNotificationService) {
	}

	onClick(customEvents: CustomEventSettings) {
		if (!customEvents.enable) return;

		customEvents.types.forEach(type => {
			this.runtimeStateNotificationService.notify(RuntimeStateNotificationEnum.customEvent, { type, data: customEvents.payload });
		});

	}

}
