<div *ngIf="section.styles" class="style-block">
  <p-accordion [multiple]="true">
    <p-accordionTab [header]="'Outer section container' | translate" [selected]="true">
      <app-page-element-styler
        [element]="outerContainer"
        [isContainerClassEditable]="true"
        [styles]="section.styles.outerContainer"
        [applicationId]="applicationId"
      >
      </app-page-element-styler>

      <app-sticky-positioning [settings]="section.styles.outerContainer.stickyPositioning"></app-sticky-positioning>
    </p-accordionTab>

    <p-accordionTab [header]="'Inner section container' | translate">
      <app-page-element-styler
        [element]="innerContainer"
        [isContainerClassEditable]="true"
        [styles]="section.styles.innerContainer"
        [applicationId]="applicationId"
      >
      </app-page-element-styler>
    </p-accordionTab>

    <p-accordionTab [header]="'Responsive design' | translate">
      <app-responsive-design-settings *ngIf="section.sectionHide" [hidingSettings]="section.sectionHide"></app-responsive-design-settings>
    </p-accordionTab>    
  </p-accordion>
</div>
