import { inject } from '@angular/core';
import { SelfUserTenantApplicationResponseDto } from '@api/models';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TeamResponseDto } from '@api/models/team-response-dto';
import { TemplateResponseDto } from '@api/models/template-response-dto';
import {
  TenantApplicationService,
  TenantArtifactService,
  TenantArtifactTypeService,
  TenantAttributeService,
  TenantDataTypeService,
  TenantLinkTypeService,
  TenantPageService,
  TenantTeamService,
  TenantTemplateService,
  TenantUserService,
  TenantWidgetService,
} from '@api/services';
import { Constants } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { StateKey } from '@shared/types/local-storage.types';
import { WidgetResponseDto } from '@shared/types/widget.types';
import { lastValueFrom, map, Observable } from 'rxjs';
import { CachedUserMeta, CacheListResponseDto } from '../types/new-cached-subject.types';
import { DbEntityCachedData } from '../utils/db-entity-cached-data.subject';
import { NewCachedSubject } from '../utils/new-cached-subject';

export class NewAppCache {
  loaded = false;
  user: NewCachedSubject<SelfUserResponseDto>;
  userProfile: NewCachedSubject<ArtifactLinkResponseDto>;
  userMeta: CachedUserMeta = new CachedUserMeta();
  data: NewAppCacheData;

  private readonly _onLoaded?: () => void;
  private _tenantUserService = inject(TenantUserService);
  private _localStorageService = inject(LocalStorageService);
  private _tenantArtifactService = inject(TenantArtifactService);

  constructor(onLoaded?: () => void) {
    this._onLoaded = onLoaded;
    this.data = new NewAppCacheData();
  }

  initCache(): void {
    this.initUserWithMeta();
  }

  private initUserWithMeta(): void {
    this.user = new NewCachedSubject<SelfUserResponseDto>({
      updateFn: () => this._tenantUserService.userControllerGetInfo(),
      onUpdate: entity => {
        this.updateUserMeta(entity as SelfUserResponseDto);
        this.initUserProfile((entity as SelfUserResponseDto).tenant?.profileArtifactId!);
        this.initGlobalConstants(entity as SelfUserResponseDto);
        this.data.initTenantData(() => this.raceResolver(), entity as SelfUserResponseDto);
      },
      callUpdateOnInit: true,
      localStorageMeta: {
        localStorageService: this._localStorageService,
        key: Constants.user,
        stateKey: StateKey.session,
        storeOnlyValue: true,
        skipSync: value => value?.isGuest,
      },
    });
  }

  private updateUserMeta(user: SelfUserResponseDto): void {
    if (!user) return;

    this.userMeta.isSystemAdmin = user.isSystemAdmin;
    this.userMeta.isTenantAdmin = Boolean(user.tenant?.isAdmin);
    this.userMeta.isApplicationAdmin = user.tenant
      ? user.tenant.applications.some((application: SelfUserTenantApplicationResponseDto) => application.isAdmin)
      : false;
  }

  private initUserProfile(id: string): void {
    this.userProfile = new NewCachedSubject({
      updateFn: () => this._tenantArtifactService.artifactControllerGet({ id }),
      onUpdate: () => this.raceResolver(),
      callUpdateOnInit: true,
    });
  }

  private initGlobalConstants(user: SelfUserResponseDto): void {
    GlobalConstants.setValue(GlobalConstantsEnum.profileArtifactTypeId, user.tenant?.profileArtifactTypeId!);
    GlobalConstants.setValue(GlobalConstantsEnum.fileArtifactTypeId, user.tenant?.fileArtifactTypeId!);
    GlobalConstants.setValue(GlobalConstantsEnum.emailAttributeId, user.tenant!.systemAttributes![GlobalConstantsEnum.emailAttributeId]!);
    GlobalConstants.setValue(GlobalConstantsEnum.expirationDateAttributeId, user.tenant!.systemAttributes![GlobalConstantsEnum.expirationDateAttributeId]!);
    GlobalConstants.setValue(GlobalConstantsEnum.nameAttributeId, user.tenant!.systemAttributes![GlobalConstantsEnum.nameAttributeId]!);
    GlobalConstants.setValue(GlobalConstantsEnum.ownerAttributeId, user.tenant!.systemAttributes![GlobalConstantsEnum.ownerAttributeId]!);
    GlobalConstants.setValue(GlobalConstantsEnum.primaryTextAttributeId, user.tenant!.systemAttributes![GlobalConstantsEnum.primaryTextAttributeId]!);
    GlobalConstants.setValue(GlobalConstantsEnum.profilePictureAttributeId, user.tenant!.systemAttributes![GlobalConstantsEnum.profilePictureAttributeId]!);
    GlobalConstants.setValue(GlobalConstantsEnum.seenAttributeId, user.tenant!.systemAttributes![GlobalConstantsEnum.seenAttributeId]!);
    GlobalConstants.setValue(GlobalConstantsEnum.sequenceAttributeId, user.tenant!.systemAttributes![GlobalConstantsEnum.sequenceAttributeId]!);
    GlobalConstants.setValue(GlobalConstantsEnum.currentUserPrivateFolderId, user.tenant?.userPrivateFolderId!);
    GlobalConstants.setValue(GlobalConstantsEnum.usersFolderId, user.tenant?.usersFolderId!);
    GlobalConstants.setValue(GlobalConstantsEnum.teamsFolderId, user.tenant?.teamsFolderId!);
    GlobalConstants.setValue(GlobalConstantsEnum.currentUserDefaultApplicationId, user.tenant?.defaultApplicationId!);
    GlobalConstants.setValue(GlobalConstantsEnum.currentUserDefaultTeamId, user.tenant?.defaultTeamId!);
    GlobalConstants.setValue(GlobalConstantsEnum.everyoneTeamId, user.tenant?.everyoneTeamId!);
  }

  private raceResolver(): void {
    if (
      this.user?.loaded &&
      this.user?.value &&
      this.userProfile?.loaded &&
      this.userProfile?.value &&
      this.data?.users?.loaded &&
      this.data?.teams?.loaded &&
      this.data?.applications?.loaded &&
      this.data?.artifactTypes?.loaded &&
      this.data?.linkTypes?.loaded &&
      this.data?.attributes?.loaded &&
      this.data?.dataTypes?.loaded &&
      this.data?.pages?.loaded &&
      this.data?.artifacts?.loaded &&
      this.data?.templates?.loaded
    ) {
      this._onLoaded?.();
    }
  }
}

export class NewAppCacheData {
  users: DbEntityCachedData<ArtifactLinkResponseDto>;
  teams: DbEntityCachedData<TeamResponseDto>;
  applications: DbEntityCachedData<ApplicationResponseDto>;
  artifactTypes: DbEntityCachedData<ArtifactTypeResponseDto>;
  linkTypes: DbEntityCachedData<LinkTypeResponseDto>;
  attributes: DbEntityCachedData<AttributeResponseDto>;
  dataTypes: DbEntityCachedData<DataTypeResponseDto>;
  pages: DbEntityCachedData<PageResponseDto>;
  artifacts: DbEntityCachedData<ArtifactLinkResponseDto>;
  templates: DbEntityCachedData<TemplateResponseDto>;
  widgets: DbEntityCachedData<WidgetResponseDto>;

  private _tenantUserService = inject(TenantUserService);
  private _tenantTeamService = inject(TenantTeamService);
  private _tenantApplicationService = inject(TenantApplicationService);
  private _tenantArtifactTypeService = inject(TenantArtifactTypeService);
  private _tenantLinkTypeService = inject(TenantLinkTypeService);
  private _tenantAttributeService = inject(TenantAttributeService);
  private _tenantDataTypeService = inject(TenantDataTypeService);
  private _tenantPageService = inject(TenantPageService);
  private _tenantArtifactService = inject(TenantArtifactService);
  private _tenantTemplateService = inject(TenantTemplateService);
  private _tenantWidgetService = inject(TenantWidgetService);

  async initTenantData(raceResolver: () => void, loggedInUser: SelfUserResponseDto): Promise<void> {
    await Promise.all([
      this.initUsers(raceResolver, loggedInUser),
      this.initTeams(raceResolver),
      this.initApplications(raceResolver),
      this.initArtifactTypes(raceResolver),
      this.initLinkTypes(raceResolver),
      this.initAttributes(raceResolver),
      this.initDataTypes(raceResolver),
      this.initPages(raceResolver),
      this.initTemplates(raceResolver),
    ]);

    this.initArtifacts(raceResolver);
    this.initWidgets(raceResolver);
  }

  private async initUsers(raceResolver: () => void, loggedInUser: SelfUserResponseDto): Promise<void> {
    const artifactTypeFilter = { artifactTypeId: { $oid: loggedInUser.tenant?.profileArtifactTypeId } };
    const modifyFilter = (filter: string | undefined): string => {
      let parsed: Record<string, any>;

      try {
        parsed = JSON.parse(filter || '');
      } catch (e) {
        parsed = {};
      }

      if (parsed.$and) parsed.$and.push(artifactTypeFilter);
      else parsed = { $and: [artifactTypeFilter] };

      return JSON.stringify(parsed);
    };

    const initialValue = (
      await lastValueFrom(this._tenantArtifactService.artifactControllerList({ body: { filter: JSON.stringify({ $and: [artifactTypeFilter] }) } }))
    ).data;

    this.users = new DbEntityCachedData<ArtifactLinkResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantArtifactService.artifactControllerGet({ id }),
        list: filter => this._tenantArtifactService.artifactControllerList({ body: { filter: modifyFilter(filter) } }).pipe(map(res => res.data)),
      },
      onUpdate: () => raceResolver(),
      callUpdateOnInit: true,
      initialValue,
    });
  }

  private async initTeams(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantTeamService.teamControllerList())).data;
    this.teams = new DbEntityCachedData<TeamResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantTeamService.teamControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantTeamService.teamControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initApplications(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantApplicationService.applicationControllerList())).data;
    this.applications = new DbEntityCachedData<ApplicationResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantApplicationService.applicationControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantApplicationService.applicationControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initArtifactTypes(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantArtifactTypeService.artifactTypeControllerList())).data;
    this.artifactTypes = new DbEntityCachedData<ArtifactTypeResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantArtifactTypeService.artifactTypeControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantArtifactTypeService.artifactTypeControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initLinkTypes(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantLinkTypeService.linkTypeControllerList())).data;
    this.linkTypes = new DbEntityCachedData<LinkTypeResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantLinkTypeService.linkTypeControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantLinkTypeService.linkTypeControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initAttributes(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantAttributeService.attributeControllerList())).data;
    this.attributes = new DbEntityCachedData<AttributeResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantAttributeService.attributeControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantAttributeService.attributeControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initDataTypes(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantDataTypeService.dataTypeControllerList())).data;
    this.dataTypes = new DbEntityCachedData<DataTypeResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantDataTypeService.dataTypeControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantDataTypeService.dataTypeControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private async initPages(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantPageService.pageControllerList())).data;
    this.pages = new DbEntityCachedData<PageResponseDto>({
      updateFns: {
        get: (idAlias: string) => this._tenantPageService.pageControllerGet({ idAlias }),
        list: filter => this.processListRequest(this._tenantPageService.pageControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
      additionalKeysForIndexing: ['alias'],
    });
  }

  private initArtifacts(raceResolver: () => void): void {
    this.artifacts = new DbEntityCachedData<ArtifactLinkResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantArtifactService.artifactControllerGet({ id }),
        list: filter => this._tenantArtifactService.artifactControllerList({ body: { filter } }).pipe(map(res => res.data)),
      },
      onUpdate: () => raceResolver(),
    });
  }

  private async initTemplates(raceResolver: () => void): Promise<void> {
    const initialValue = (await lastValueFrom(this._tenantTemplateService.templateControllerList())).data;
    this.templates = new DbEntityCachedData<TemplateResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantTemplateService.templateControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantTemplateService.templateControllerList({ filter })),
      },
      initialValue,
      callUpdateOnInit: true,
      onUpdate: () => raceResolver(),
    });
  }

  private initWidgets(raceResolver: () => void): void {
    this.widgets = new DbEntityCachedData<WidgetResponseDto>({
      updateFns: {
        get: (id: string) => this._tenantWidgetService.widgetControllerGet({ id }),
        list: filter => this.processListRequest(this._tenantWidgetService.widgetControllerList({ filter })),
      },
      onUpdate: () => raceResolver(),
    });
  }

  private processListRequest<T>(os$: Observable<CacheListResponseDto<T>>): Observable<Array<T>> {
    return os$.pipe(map(res => res.data));
  }
}
