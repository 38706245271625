import { SelectOption } from '@shared/types/shared.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { ApexUserDisplay } from '@widgets/apex-chart/types/apex-chart-widget.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { ArtifactFilter, ArtifactFilterDto } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ApexChartWidgetOptions, ApexGridPosition } from '@widgets/apex-chart/types/apex-chart-widget-options.types';
import { EMPTY_GROUP_VALUE, URL_KEY_VALUE_SAVE_TO_FOLDER_ID } from '@shared/constants/constants';
import { DateFormatEnum } from '@widgets/shared/types/date-format.types';
import { ChartConstants } from '@widgets/chart-widget/constants/constants';
import { ApexAxis, ApexChartAxisDto } from '@widgets/apex-chart/types/axis.types';
import { ApexChartDto, ApexChartType } from '@widgets/apex-chart/types/chart.types';
import { FontStyles } from '@widgets/menu-widget/types/menu-widget-styles.types';
import { ApexGrid } from 'ng-apexcharts';
import { ApexNoDataDto, ApexNoDataTypes } from '@widgets/apex-chart/types/apex-no-data.types';

export class ApexChartWidgetSettings {
  artifactTypes: NewArtifactType[] = [];
  columnAttribute: SelectOption<string, NewAttribute> | null;
  attributesFilter: ArtifactFilter[] = [];
  title = '';
  subTitle = '';
  axes: ApexAxis[] = [new ApexAxis()];
  charts: ApexChartType[] = [new ApexChartType()];
  noData: ApexNoDataTypes = new ApexNoDataTypes();
  showUrlChartChange = false;
  urlChartChangeKey: string | null;
  showDataLabels = true;
  dataLabelsColor = ChartConstants.defaultDataLabelsColor;
  userDisplayType: ApexUserDisplay = ApexUserDisplay.email;
  dateFormat: SelectOption<string, DateFormatEnum> | null = new SelectOption(DateFormatEnum.day);
  titleStyles = new FontStyles();
  subTitleStyles = new FontStyles();
  labelStyles = new FontStyles();
  roundAxisY = false;
  logarithmic = false;
  logBase: number;
  emptyLabel = EMPTY_GROUP_VALUE;
  listenUrlFolderId = false;
  folderKey = URL_KEY_VALUE_SAVE_TO_FOLDER_ID;

  areaMode = false;
  borderRadius: string;
  grid: ApexGrid = {};
  gridPosition: SelectOption<string, string> = new SelectOption(ApexGridPosition.back);
  showToolbar = true;
  autoScaleYaxis = false;

  // runtime
  selectedTitle: string;
  selectedStyle: FontStyles;
  prevUrlChartNames: string;
  filteredCharts: ApexChartType[] = [];

  dto: ApexChartSettingsDto | null;

  constructor(dto?: ApexChartSettingsDto) {
    dto && (this.dto = dto);
    this.grid.show = true;
    this.grid.xaxis = { lines: { show: false } };
    this.grid.yaxis = { lines: { show: true } };
  }

  toServer(): ApexChartSettingsDto {
    const dto: ApexChartSettingsDto = {
      artifactTypeIds: this.artifactTypes.map(at => at.id),
      columnAttributeId: this.columnAttribute?.value.id || '',
      attributesFilter: this.attributesFilter.filter(filter => filter.attribute && (filter.dataType || filter.type)).map(filter => filter.toServer()),
      title: this.title,
      subTitle: this.subTitle,
      axes: this.axes.map(axis => axis.toServer()),
      charts: this.charts.map(chart => chart.toServer()),
      showUrlChartChange: this.showUrlChartChange,
      urlChartChangeKey: this.urlChartChangeKey,
      showDataLabels: this.showDataLabels,
      userDisplayType: this.userDisplayType,
      titleStyles: this.titleStyles,
      subTitleStyles: this.subTitleStyles,
      labelStyles: this.labelStyles,
      roundAxisY: this.roundAxisY,
      logarithmic: this.logarithmic,
      listenUrlFolderId: this.listenUrlFolderId,
      folderKey: this.folderKey,
      areaMode: this.areaMode,
      grid: this.grid,
      gridPosition: this.gridPosition.value,
      showToolbar: this.showToolbar,
      autoScaleYaxis: this.autoScaleYaxis,
    };

    this.noData.isDefined() && (dto.noData = this.noData.toServer());
    this.dateFormat?.value && (dto.dateFormat = this.dateFormat?.value);
    this.dataLabelsColor && (dto.dataLabelsColor = this.dataLabelsColor);
    this.logBase && (dto.logBase = this.logBase);
    this.borderRadius && (dto.borderRadius = this.borderRadius);
    return dto;
  }

  fromDto(options: ApexChartWidgetOptions): void {
    if (!this.dto) return;

    const {
      artifactTypeIds,
      columnAttributeId,
      title,
      subTitle,
      axes,
      charts,
      showUrlChartChange,
      urlChartChangeKey,
      showDataLabels,
      userDisplayType,
      titleStyles,
      subTitleStyles,
      labelStyles,
      dateFormat,
      dataLabelsColor,
      roundAxisY,
      logarithmic,
      logBase,
      listenUrlFolderId,
      folderKey,
      areaMode,
      borderRadius,
      grid,
      gridPosition,
      noData,
      showToolbar,
      autoScaleYaxis,
    } = this.dto;
    Object.assign(this, { title, subTitle });
    this.checkUndefinedAndApply(
      {
        dataLabelsColor,
        userDisplayType,
        showUrlChartChange,
        urlChartChangeKey,
        titleStyles,
        subTitleStyles,
        labelStyles,
        roundAxisY,
        logarithmic,
        logBase,
        listenUrlFolderId,
        folderKey,
        areaMode,
        borderRadius,
        grid,
        showToolbar,
        autoScaleYaxis,
      },
      this,
    );

    dateFormat && (this.dateFormat = new SelectOption(dateFormat, dateFormat as DateFormatEnum));
    showDataLabels !== undefined && (this.showDataLabels = showDataLabels);
    gridPosition && (this.gridPosition = new SelectOption(gridPosition));

    if (artifactTypeIds?.length) {
      this.artifactTypes = artifactTypeIds.map(id => options.artifactTypes.listMap[id]).filter(at => !!at);
    }

    if (columnAttributeId) {
      const attr = [...options.attributes, ...options.systemAttributes].find(el => el.id === columnAttributeId);
      attr && (this.columnAttribute = new SelectOption<string, NewAttribute>(attr.name, attr));
    }

    if (noData) {
      this.noData = new ApexNoDataTypes(noData);
    }

    if (!this.attributesFilter.length && this.dto.attributesFilter?.length) {
      this.attributesFilter = this.dto.attributesFilter.map(filter =>
        ArtifactFilter.fromDtoAndOptions(filter, {
          attributes: options.attributes,
          dataTypes: options.dataTypes.list,
          linkTypes: options.linkTypes.list,
        }),
      );
    }

    axes?.length && (this.axes = axes.map(dto => new ApexAxis(dto)));
    charts?.length && (this.charts = charts.map(dto => new ApexChartType(dto, options)));

    this.dto = null;
  }

  private checkUndefinedAndApply(fields: Record<string, any>, dest: any): void {
    Object.keys(fields).forEach(key => {
      fields[key] !== undefined && (dest[key] = fields[key]);
    });
  }
}

export class ApexChartSettingsDto {
  artifactTypeIds: string[];
  columnAttributeId: string;
  attributesFilter: ArtifactFilterDto[];
  title: string;
  subTitle: string;
  axes: ApexChartAxisDto[];
  charts: ApexChartDto[];
  noData?: ApexNoDataDto;
  showUrlChartChange?: boolean;
  urlChartChangeKey?: string | null;
  showDataLabels: boolean;
  dataLabelsColor?: string;
  userDisplayType?: ApexUserDisplay;
  dateFormat?: string | null;
  titleStyles?: FontStyles;
  subTitleStyles?: FontStyles;
  labelStyles?: FontStyles;
  roundAxisY?: boolean;
  logarithmic?: boolean;
  logBase?: number;
  listenUrlFolderId?: boolean;
  folderKey?: string;
  areaMode?: boolean;
  borderRadius?: string;
  grid?: ApexGrid;
  gridPosition?: string;
  showToolbar?: boolean;
  autoScaleYaxis?: boolean;
}
