<app-loader [loading]="m.inProgress"></app-loader>

<div class="grid">
  <div class="col-12 top_box">
    <div>
      @if (m.sessionFlow && m.sessionFlow.id) {
        <div>
          <div class="p-inputgroup">
            <span class="dynamic-label dynamic-checkbox">
              <span class="dynamic-checkbox-box">
                <p-checkbox binary="true" inputId="isDeleted" id="isDeleted" [ngModel]="m.sessionFlow.deleted" [disabled]="true"></p-checkbox>
                <label for="isDeleted" translate>{{ IS_DELETED_LABEL | translate }}</label>
              </span>
            </span>
          </div>
        </div>
      }
    </div>
    <div>
      @if (m.sessionFlow && m.sessionFlow.id && !m.sessionFlow.deleted) {
        <button [label]="'Delete' | translate" (click)="s.deleteWithConfirmation(m.sessionFlow)" class="p-button p-button-danger" pButton></button>
      }
      <button pButton pRipple type="button" label="{{ 'Cancel' | translate }}" class="p-button p-button-warning" (click)="s.cancel()"></button>
      <button pButton pRipple type="button" label="{{ 'Save' | translate }}" class="p-button p-button-success" (click)="s.save()"></button>
    </div>
  </div>

  <div class="col-12 link-type-form">
    @if (m.sessionFlow) {
      <app-session-flow-form [sessionFlow]="m.sessionFlow"></app-session-flow-form>
    }
  </div>
</div>
