import { Params } from '@angular/router';
import { EnumeratedOption } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import {
  BLANK_FILTER_OPTION_LABEL,
  CURRENT_USER_OPTION_LABEL,
  CURRENT_USER_TEAMS_LABEL,
  DYNAMIC_ATTRIBUTE_NOTATION_REGEX,
  EMPTY_FILTER_VALUE_MULTIPLE,
  EMPTY_FILTER_VALUE_SINGLE,
  IS_NOT_EMPTY_OPTION_FILTER_VALUE,
  MONGO_ID_REGEX,
} from '@shared/constants/constants';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { get } from 'lodash';

/**
 * @param path {string | string[]} // can be a string in which the keys to the value are written trough a dot or an array of strings in which  each next value is the key of child object;
 * @param obj {Record<string, any>} // target object;
 * @example getValueFromPath('system-user.age', {system-user: {age: 26}}) : 26
 * @example getValueFromPath(['system-user', 'age'], {system-user: {age: 26}}) : 26
 * @example getValueFromPath(['system-user', 'age'], {}) : undefined
 */
const getValueFromPath = (path: string | string[], obj: Record<string, any>): any => {
  if (!path?.length) return;

  path = Array.isArray(path) ? path.join('.') : path;

  return get(obj, path);
};

/**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(4) : 95ca-361a-f8a1-1e73
 */
const getUniqueId = (parts = 8): string => {
  const stringArr = [];
  for (let i = 0; i < parts; i++) {
    const s4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(s4);
  }
  return stringArr.join('-');
};

/**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(4) : 95ca-361a-f8a1-1e73
 */
const findDistinct = <T = any>(sourceItems: T[], items: T[], comparisonKey?: string): T[] => {
  return sourceItems.filter((sourceItem: T) => {
    return !items.some((item: T) => {
      if (comparisonKey) {
        return (sourceItem as Record<string, any>)[comparisonKey] === (item as Record<string, any>)[comparisonKey];
      }
      return sourceItem === item;
    });
  });
};

const getComputedStylePropertyWithoutUnit = (element: HTMLElement, property: string, unit = 'px'): string => {
  return getComputedStyle(element).getPropertyValue(property).replace(unit, '');
};

// TODO remake using like SharedMethods.getSelectOptionsFromEnum
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const GetSelectOptionsFromEnum = <T>(sourceEnum: typeof T): SelectOption<T, T>[] => {
  return Object.keys(sourceEnum).map(key => new SelectOption<T, T>(sourceEnum[key], sourceEnum[key]));
};

// TODO remake using like SharedMethods.withEmptySelectOption
export const WithEmptySelectOption = <Value = unknown>(source: SelectOption<string, Value>[], multipleOptions: boolean): SelectOption<string, Value>[] => {
  const emptyValue = multipleOptions ? EMPTY_FILTER_VALUE_MULTIPLE : EMPTY_FILTER_VALUE_SINGLE;
  return [
    ...source,
    new SelectOption<string, Value>(BLANK_FILTER_OPTION_LABEL, emptyValue as any),
    new SelectOption<string, Value>(IS_NOT_EMPTY_OPTION_FILTER_VALUE, IS_NOT_EMPTY_OPTION_FILTER_VALUE as any),
  ];
};

// TODO remake using like SharedMethods.withEmptyEnumOption
export const WithEmptyEnumOption = (source: EnumeratedOption[], multipleOptions: boolean): EnumeratedOption[] => {
  const emptyValue = multipleOptions ? EMPTY_FILTER_VALUE_MULTIPLE : EMPTY_FILTER_VALUE_SINGLE;
  return [...source, new EnumeratedOption(BLANK_FILTER_OPTION_LABEL, emptyValue)];
};

/**
 *
 * @param source source collections of users.
 * @returns collection of users enhanced about current user option
 */
const withCurrentUserOption = (source: SelectOption<string, string>[]): SelectOption<string, string>[] => {
  return [...source, new SelectOption(CURRENT_USER_OPTION_LABEL, NonAttributeKeys.CURRENT_USER_ID)];
};

const withCurrentUserTeamsOption = (source: SelectOption<string, string>[]): SelectOption<string, string>[] => {
  return [...source, new SelectOption(CURRENT_USER_TEAMS_LABEL, NonAttributeKeys.CURRENT_USER_TEAMS_ID)];
};

const computeStickyOffset = (): number => {
  let offset = 0;
  const stickySections = document.getElementsByClassName('container-fluid sticky--top');
  if (stickySections.length) {
    offset = stickySections[stickySections.length - 1].getBoundingClientRect().bottom;
  }
  return -offset;
};

const scrollToHtmlElement = (id: string, offset = 0, behavior: ScrollBehavior = 'auto'): void => {
  const scrollToDiv = document.getElementById(id);
  if (!scrollToDiv) return;

  if (offset) {
    const top = scrollToDiv.getBoundingClientRect().top + window.scrollY + offset;
    window.scrollTo({ top, behavior });
    return;
  }

  scrollToDiv.scrollIntoView({ behavior });
};

/**
 * Transform boolean and null query parameter into corresponding javascript value
 */
const parseQueryParam = (value: string): any => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  if (value === 'null') return null;
  return value;
};

const isQueryParamPresent = (param: string, queryParams: Params): boolean => {
  return param in queryParams;
};

const getMongoIdFromString = (string: string): string[] | null => {
  return string.match(MONGO_ID_REGEX);
};

const isDynamicAttributeNotation = (string: string): boolean => {
  return !!string.match(DYNAMIC_ATTRIBUTE_NOTATION_REGEX);
};

const isInPageBuilder = (): boolean => window.location.href.includes('/page-builder/');

const getRedirectUrlQuery = (path: string): string => `/login` + (path.includes('notfound') ? ``: `?redirectUrl=${path}`);

const isValueInEnum = (value: any, enumeration: any): boolean => {
  return Object.values(enumeration).includes(value);
};

export const SharedMethods = Object.freeze({
  getValueFromPath,
  getUniqueId,
  findDistinct,
  getComputedStylePropertyWithoutUnit,
  withCurrentUserOption,
  withCurrentUserTeamsOption,
  computeStickyOffset,
  scrollToHtmlElement,
  parseQueryParam,
  isQueryParamPresent,
  isInPageBuilder,
  getRedirectUrlQuery,
  getMongoIdFromString,
  isDynamicAttributeNotation,
  isValueInEnum,
});
