import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactHistoryResponseDto } from '@api/models/artifact-history-response-dto';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ArtifactHistory, ArtifactHistoryAttribute, ArtifactHistoryOperationType } from '@shared/components/artifact-history/types/artifact-history.types';
import { AttributeValueToText } from '@shared/methods/client-attribute.methods';
import { ConvertToClientDatetime } from '@shared/methods/date.methods';
import { AttributeToClientParams } from '@shared/types/attribute-convert.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';

@Pipe({ name: 'formatHistoryMessageToClient' })
export class FormatHistoryMessageToClientPipe implements PipeTransform {
  transform(
    historyRecord: ArtifactHistoryResponseDto,
    attributes: ListContainer<NewAttribute>,
    dataTypes: ListContainer<NewDataType>,
    users: ListContainer<ArtifactLinkResponseDto>,
  ): ArtifactHistory {
    const user = users.listMap[historyRecord.created.by];
    const date = ConvertToClientDatetime(new Date(historyRecord.created.on));
    const operation = historyRecord.type as ArtifactHistoryOperationType;
    const artifactHistoryAttributes = this.getAttributes(historyRecord, attributes, dataTypes, users);

    return new ArtifactHistory({ user, date, operation, attributes: artifactHistoryAttributes });
  }

  private getAttributes(
    historyRecord: ArtifactHistoryResponseDto,
    attributes: ListContainer<NewAttribute>,
    dataTypes: ListContainer<NewDataType>,
    users: ListContainer<ArtifactLinkResponseDto>,
  ): ArtifactHistoryAttribute[] {
    const artifactHistoryAttributes: ArtifactHistoryAttribute[] = [];
    const historyAttributes: Record<string, any> | undefined = historyRecord.attributes;
    if (!historyAttributes) return artifactHistoryAttributes;

    Object.keys(historyAttributes).forEach(attributeId => {
      const name = attributes.listMap[attributeId]?.name;

      const oldValue = AttributeValueToText(
        new AttributeToClientParams({
          dataTypes,
          attributes,
          users: users.list,
          clientAttribute: { value: historyAttributes[attributeId]?.old?.value, id: attributeId, isMandatory: false },
          value: historyAttributes[attributeId]?.old?.value,
        }),
      );

      const newValue = AttributeValueToText(
        new AttributeToClientParams({
          dataTypes,
          attributes,
          users: users.list,
          clientAttribute: { value: historyAttributes[attributeId]?.new?.value, id: attributeId, isMandatory: false },
          value: historyAttributes[attributeId]?.new?.value,
        }),
      );

      const attr = new ArtifactHistoryAttribute({ name, newValue, oldValue });
      artifactHistoryAttributes.push(attr);
    });
    return artifactHistoryAttributes;
  }
}
