<div class="grid">
  <div class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="formatSettings.hideOnPageLoad" binary="true" id="hideOnPageLoad"></p-checkbox>
        <label for="hideOnPageLoad">{{ 'Hide on page load' | translate }}</label>
      </span>
    </span>
  </div>

  <div class="col-6 mb-0">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox [(ngModel)]="formatSettings.reserveSpaceWhenHidden" binary="true" id="ReserveSpaceWhenHidden"></p-checkbox>
        <label for="hideOnPageLoad">{{ 'Reserve space when hidden' | translate }}</label>
      </span>
    </span>
  </div>

  <div class="col-12 pb-0">
    <h6 style="margin: 0"> Inner area </h6>
  </div>
  <div class="col-4 mb-0">
    <button class="w-100" (click)="innerPaddingOp.toggle($event)" pButton label="{{'Paddings' | translate }}"
            icon="pi pi-cog" type="button"></button>
  </div>

  <div class="col-4 mb-0">
    <button class="w-100" (click)="borderOp.toggle($event)" pButton label="{{'Borders' | translate }}"
            icon="bi bi-border-style" type="button"></button>
  </div>

  <div class="col-4 mb-0">
    <button class="w-100" (click)="colorOp.toggle($event)" pButton label="{{'Colors' | translate }}"
            icon="pi pi-palette" type="button"></button>
  </div>

  <hr>

  <div class="col-12 pb-0">
    <h6 style="margin: 0"> Outer area </h6>
  </div>
  <div class="col-4 mb-0">
    <button class="w-100" (click)="outerPaddingOp.toggle($event)" pButton label="{{'Paddings' | translate }}"
            icon="pi pi-cog" type="button"></button>
  </div>

  <p-overlayPanel #innerPaddingOp appendTo="body" [style]="{'max-width':'380px'}">
    <ng-template pTemplate>
      <app-padding-form [paddingStyles]="formatSettings.innerArea"></app-padding-form>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #colorOp appendTo="body" [style]="{'max-width':'380px'}">
    <ng-template pTemplate>
      <app-color-picker [(color)]="formatSettings.innerArea.backgroundColor" label="Background"></app-color-picker>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #borderOp appendTo="body" [style]="{'max-width':'420px'}">
    <ng-template pTemplate>
      <app-border-form [styles]="formatSettings.innerArea"></app-border-form>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #outerPaddingOp appendTo="body" [style]="{'max-width':'380px'}">
    <ng-template pTemplate>
      <app-padding-form [paddingStyles]="formatSettings.outerArea"></app-padding-form>
    </ng-template>
  </p-overlayPanel>
</div>
