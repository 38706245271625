<span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>

@if (formatSettings.value.displayMetadata?.selectedVariantCode !== 'ADD_TO_LIST') {
  <app-user-autocomplete [attribute]="attribute"
                         [formatSettings]="formatSettings"
                         [(value)]="attr.value"
                         (valueChange)="onChange($event)"
                         (onBlur)="onBlur()"
                         (onHide)="onBlur()"
                         [placeholder]="placeholder || formatSettings?.value?.placeholder"
                         [excludePublicUser]="true"
  ></app-user-autocomplete>
}

@if (attribute.multipleValues && formatSettings.value.displayMetadata?.selectedVariantCode === 'ADD_TO_LIST') {
  <div>
    <p-inputSwitch [ngModel]="currentUserSelected$ | async"
                   (onChange)="onSubscribeMeToggle($event.checked)"
    ></p-inputSwitch>
  </div>
}

<app-artifact-attribute-form-field-label-with-icon [attribute]="attribute"
                                                   [formatSettings]="formatSettings"
                                                   [label]="label"
                                                   [fieldHtmlId]="'userField' + index"
                                                   [canContainPlaceholder]="true"
                                                   [labelBehaviour]="labelBehaviour"
                                                   [placeholder]="placeholder"
></app-artifact-attribute-form-field-label-with-icon>
