@for (data of inputData; let i = $index; track i) {
  <div class="input-data-container">
    <div class="input-data-container-key">
      {{ data.key }}
    </div>
    <div>
      <p-checkbox [(ngModel)]="data.isNull" [id]="'isNull' + i" binary="true" class="mr-2 pt-2"></p-checkbox>
      {{ 'null' | translate }}
    </div>
    <div>
      <ng-container *ngIf="!data.isNull">
        @if (!data.isArray) {
          <input [id]="'value' + i" [(ngModel)]="data.value" pInputText type="text" placeholder="Enter value" class="w-100" />
        } @else {
          @for (_ of data.value; let j = $index; track j) {
            <div class="flex">
              <input [id]="'value' + j" [(ngModel)]="data.value![j]" pInputText type="text" placeholder="Enter value {{ j + 1 }}" class="w-100" />
              <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddInputData(i, j)"></button>
              <button
                class="p-button p-button-text p-button-danger"
                pButton
                type="button"
                icon="pi pi-minus"
                (click)="onRemoveInputData(i, j)"
                [ngClass]="{ 'visibility-hidden': data.value!.length <= 1 }"
              ></button>
            </div>
          }
        }
      </ng-container>
    </div>
  </div>
}
<div class="overlay-bottom">
  <p-calendar [(ngModel)]="scheduleDateTime" [showTime]="true" placeholder="Schedule time"></p-calendar>
  <div>
    <p-checkbox [(ngModel)]="waitUntilFinished" binary="true" class="mr-2 pt-2"></p-checkbox>
    {{ 'wait until finished' | translate }}
  </div>
  <button pButton label="{{ 'Run this workflow' | translate }}" type="button" class="p-button p-button-primary" (click)="runWorkflow()"></button>
</div>
