<div *ngIf="dataType">
  <!--Integer and decimal fields (SIMPLE)-->
  <span
    *ngIf="((dataType.baseDataType | isInteger) || (dataType.baseDataType | isDecimal)) && (dataType.kind | isSimple) && !multipleValues"
    class="dynamic-label"
  >
    <p-inputNumber
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event + '')"
      [useGrouping]="false"
      [minFractionDigits]="(dataType.baseDataType | isDecimal) ? 2 : 0"
      [maxFractionDigits]="20"
    >
    </p-inputNumber>
  </span>

  <span
    *ngIf="((dataType.baseDataType | isInteger) || (dataType.baseDataType | isDecimal)) && (dataType.kind | isSimple) && multipleValues"
    class="dynamic-label"
  >
    <p-chips
      [(ngModel)]="value"
      (ngModelChange)="checkNumFormatMultiple()"
      [disabled]="disabled"
      [inputId]="'intFieldM' + index"
      [ngClass]="{ 'p-chips-filled': value?.length }"
    >
    </p-chips>
  </span>

  <!--String fields (SIMPLE)-->
  <span *ngIf="(dataType.baseDataType | isText) && (dataType.kind | isSimple) && !multipleValues" class="dynamic-label flex">
    <textarea
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event)"
      [disabled]="disabled"
      [id]="'strField' + index"
      autoResize="autoResize"
      pInputTextarea
      rows="1"
    >
    </textarea>
  </span>

  <span *ngIf="(dataType.baseDataType | isHtml) && (dataType.kind | isSimple) && !multipleValues" class="dynamic-label">
    <editor
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event)"
      [disabled]="disabled"
      [id]="'htmlField' + uniqueId"
      [init]="settings"
      [ngClass]="{ 'editor-enabled': !disabled }"
      apiKey="y0en5l9qmetfdo5lld6p5xc7xs39weexnhrzvdtoxzs1a7kr"
      outputFormat="html"
    >
    </editor>
  </span>

  <span
    *ngIf="((dataType.baseDataType | isText) || (dataType.baseDataType | isHtml)) && (dataType.kind | isSimple) && multipleValues"
    class="dynamic-label">
    <p-chips
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event)"
      [disabled]="disabled"
      [inputId]="'intFieldM' + index"
      [ngClass]="{ 'p-chips-filled': value?.length }"
    >
    </p-chips>
  </span>

  <!--Date & Time fields (SIMPLE)-->
  <span *ngIf="(dataType.kind | isSimple) && (dataType.baseDataType | isDateOrTime)" class="dynamic-label">
    <p-calendar
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event)"
      [dateFormat]="dateFormat"
      [firstDayOfWeek]="uiConfig?.firstDayOfWeek"
      [inputId]="'dateField' + index"
      [selectionMode]="multipleValues ? 'multiple' : 'single'"
      [showTime]="dataType.baseDataType | isDateTime"
      [timeOnly]="dataType.baseDataType | isTime"
      [showButtonBar]="true"
      appendTo="body"
    >
    </p-calendar>
  </span>

  <!-- Boolean field -->
  <span *ngIf="(dataType.kind | isSimple) && (dataType.baseDataType | isBoolean)" class="dynamic-label">
    <p-triStateCheckbox
      (ngModelChange)="onChangeCb($event)"
      [(ngModel)]="value"
      [inputId]="'booleanField' + index"
    >
    </p-triStateCheckbox>
  </span>

  <!--Enumerated fields-->
  <span *ngIf="(dataType.kind | isEnumerated) && multipleValues" class="dynamic-label">
    <p-multiSelect
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event)"
      [disabled]="disabled"
      [filter]="true"
      [inputId]="'enumFieldM' + index"
      [maxSelectedLabels]="5"
      [options]="dataType.values"
      appendTo="body"
      optionLabel="label"
    >
      <ng-template pTemplate="selectedItems">
        <span *ngFor="let option of value" class="option-with-icon">
          <span [class]="option.icon"></span>
          <span [ngClass]="{ 'full-indent': !option.icon, 'half-indent': option.icon }">{{ option.label }}</span>
        </span>
        <div *ngIf="!value?.length">Select</div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <span [class]="option.icon"></span>
        <span [ngClass]="{ 'full-indent': !option.icon, 'half-indent': option.icon }">{{ option.label }}</span>
      </ng-template>
    </p-multiSelect>
  </span>

  <span *ngIf="(dataType.kind | isEnumerated) && !multipleValues" class="dynamic-label">
    <p-dropdown
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event)"
      [autoDisplayFirst]="false"
      [disabled]="disabled"
      [filter]="true"
      [inputId]="'enumField' + index"
      [options]="dataType.values"
      [showClear]="true"
      appendTo="body"
      optionLabel="label"
    >
      <ng-template let-option pTemplate="item">
        <span [class]="option.icon"></span>
        {{ option.label }}
      </ng-template>
      <ng-template let-option pTemplate="selectedItem">
        <span [class]="option.icon"></span>
        {{ option.label }}
      </ng-template>
    </p-dropdown>
  </span>

  <!--User-->
  <span *ngIf="(dataType.baseDataType | isUser) && !multipleValues" class="dynamic-label">
    <p-dropdown
      [(ngModel)]="singleUserModel"
      (onChange)="onSingleUserChange()"
      [autoDisplayFirst]="false"
      [filter]="true"
      [disabled]="disabled"
      [inputId]="'userField' + index"
      [options]="userOptions"
      [showClear]="true"
      appendTo="body"
      optionLabel="label"
    >
        <ng-template let-option pTemplate="item">
          <span [class]="option.icon"></span>
          {{ option.label }}
        </ng-template>
        <ng-template let-option pTemplate="selectedItem">
          <span [class]="option.icon"></span>
          {{ option.label }}
        </ng-template>
    </p-dropdown>
  </span>
  <span *ngIf="(dataType.baseDataType | isUser) && multipleValues" class="dynamic-label">
    <p-multiSelect
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event)"
      [disabled]="disabled"
      [filter]="true"
      [inputId]="'userField' + index"
      [maxSelectedLabels]="5"
      [options]="userOptions"
      [selectionLimit]="multipleValues ? null : 1"
      appendTo="body"
      optionLabel="label"
    >
    </p-multiSelect>
  </span>

  <!--Hyperlink-->
  <span *ngIf="dataType.baseDataType | isHyperlink" class="dynamic-label flex">
    <ng-container *ngIf="!multipleValues; else multipleHyperlinks">
      <textarea [(ngModel)]="value" (ngModelChange)="onChangeCb($event)" [id]="'strField' + index"
                autoResize="autoResize" pInputTextarea rows="1"></textarea>
    </ng-container>

    <ng-template #multipleHyperlinks>
      <p-chips
        [(ngModel)]="value"
        (ngModelChange)="onChangeCb($event)"
        [inputId]="'intFieldM' + index"
        [ngClass]="{ 'p-chips-filled': value?.length }"
        (onChipClick)="onHyperlinkChipClickEvent()"
      >
        <ng-template let-item pTemplate="item">
          {{ item }}
        </ng-template>
      </p-chips>
    </ng-template>
  </span>

  <!--Bounded range fields-->
  <span
    *ngIf="(dataType.kind | isBounded) && ((dataType.baseDataType | isInteger) || (dataType.baseDataType | isDecimal))"
    class="dynamic-label">
    <span class="dynamic-slider">
      <span *ngIf="multipleValues" style="display: block">[{{ value[0] + ' - ' + value[1] }}]</span>
      <span *ngIf="!multipleValues" style="display: block">{{ value }}</span>
      <p-slider
        [(ngModel)]="value"
        (ngModelChange)="onChangeCb($event)"
        [disabled]="disabled"
        [id]="'rangeField' + index"
        [max]="+dataType.maximum"
        [min]="+dataType.minimum"
        [range]="multipleValues"
        [step]="(dataType.baseDataType | isInteger) ? 1 : 0.1"
        class="horizontal-slider"
      >
      </p-slider>
    </span>
  </span>

  <span *ngIf="(dataType.kind | isBounded) && (dataType.baseDataType | isDateOrTime)" class="dynamic-label">
    <p-calendar
      [(ngModel)]="value"
      (ngModelChange)="onChangeCb($event)"
      [dateFormat]="dateFormat"
      [firstDayOfWeek]="uiConfig?.firstDayOfWeek"
      [inputId]="'dateField' + index"
      [maxDate]="dataType.maximum | toDate"
      [minDate]="dataType.minimum | toDate"
      [selectionMode]="multipleValues ? 'range' : 'single'"
      [showTime]="dataType.baseDataType | isDateTime"
      [timeOnly]="dataType.baseDataType | isTime"
      [readonlyInput]="true"
      [showButtonBar]="true"
      appendTo="body"
    >
    </p-calendar>
    <span *ngIf="dataType.baseDataType | isTime" class="time-range-indicator"> ( {{ dataType.minimum | toTime }}
      - {{ dataType.maximum | toTime }} ) </span>
  </span>
</div>
