<p-dialog
  (onShow)="onDialogShow()"
  (onHide)="onDialogHide()"
  [(visible)]="visible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [responsive]="true"
  [style]="{ width: '60vw' }"
  appendTo="body"
  header="{{ header | translate }}"
>
  <div class="pt-4">
    <p-floatLabel style="display: block; margin-bottom: 20px;">
      <p-dropdown
        [(ngModel)]="meta.applicationId"
        [autoDisplayFirst]="false"
        [filter]="true"
        [options]="applicationOptions.list | transformArrayToSelectOptions : 'name' : 'id'"
        id="application"
      >
      </p-dropdown>
      <label for="application" translate>Application</label>
    </p-floatLabel>

    <p-floatLabel>
      <input [(ngModel)]="meta.name" appAutofocus class="p-inputgroup" id="templateName" pInputText type="text" />
      <label for="templateName">{{ nameLabel | translate }}</label>
    </p-floatLabel>

    <p-floatLabel>
      <textarea [(ngModel)]="meta.description" class="p-inputgroup description" id="templateDescription" pInputTextarea
                rows="3"></textarea>
      <label for="templateDescription">{{ 'Description' | translate }}</label>
    </p-floatLabel>

    <p-floatLabel style="display: block; margin-bottom: 20px;">
      <p-autoComplete
        [(ngModel)]="selectedCategoryOptions"
        [suggestions]="filteredCategoryOptions"
        (completeMethod)="filterCategories($event)"
        (onSelect)="filterCategories({ query: '' })"
        (onKeyUp)="addCategory($event)"
        [style]="{display: 'block', width: '100%'}"
        id="templateCategories"
        field="value"
        completeOnFocus="true"
        multiple="true"
        appendTo="body"
      ></p-autoComplete>
      <label for="templateCategories">{{ 'Categories' | translate }}</label>
    </p-floatLabel>

    <div class="mb-4 file-upload-wrapper">
      <div>
        <p-button
          *ngIf="newArtifactFile || thumbnailArtifact"
          (onClick)="clearThumbnail()"
          [label]="'Remove thumbnail' | translate"
          icon="pi pi-times"
          styleClass="p-button-secondary"
        ></p-button>
        <p-fileUpload
          *ngIf="!newArtifactFile && !thumbnailArtifact"
          #templateThumbnailFileArtifactUpload
          class="file-upload-wrapper"
          name="templateThumbnailFileArtifactUpload[]"
          mode="basic"
          accept="image/*"
          (onSelect)="onSelect($event)"
          [chooseLabel]="'Choose thumbnail' | translate"
          [customUpload]="true"
          [auto]="true"
          [multiple]="false"
          (uploadHandler)="onSelectFile($event, templateThumbnailFileArtifactUpload)"
        >
        </p-fileUpload>
      </div>
      <div>{{ newArtifactFile || thumbnailArtifact?.formatData || null | fileArtifactDescription }}</div>
    </div>
  </div>

  <div class="footer">
    <p-floatLabel class="icons-dropdown">
      <p-dropdown
        [(ngModel)]="meta.icon"
        [autoDisplayFirst]="false"
        [filter]="true"
        [virtualScrollItemSize]="20"
        [options]="iconOptions"
        [showClear]="true"
        [style]="{ width: '100%' }"
        [virtualScroll]="true"
        appendTo="body"
        id="templateIcon"
      >
        <ng-template let-selectedIcon pTemplate="selectedItem">
          <div *ngIf="meta.icon" class="p-dropdown-item p-text-left">
            <i [class]="selectedIcon.value"></i>
            <label class="ml-2">{{ selectedIcon.label }}</label>
          </div>
        </ng-template>
        <ng-template let-icon pTemplate="item">
          <div class="p-dropdown-items p-text-left">
            <i [class]="icon.value"></i>
            <label class="ml-2">{{ icon.label }}</label>
          </div>
        </ng-template>
      </p-dropdown>
      <label for="templateIcon">{{ 'Choose icon' | translate }}</label>
    </p-floatLabel>

    <button (click)="save()" [disabled]="processing" class="p-button p-button-success ml-auto"
            label="{{ 'Save' | translate }}" pButton type="button"></button>
  </div>
</p-dialog>
