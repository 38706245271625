import { Injectable } from '@angular/core';
import { SessionFlowListComponent } from '@private/pages/current-tenant/session-flow-list/session-flow-list.component';
import { SessionFlowListModel } from '@private/pages/current-tenant/session-flow-list/types/session-flow-list.types';
import { CoreService } from '@shared/core/services/core.service';
import { TableColumn } from '@shared/types/table.types';
import { Table } from 'primeng/table';

export const CONST_SESSION_TYPE_KEY = 'type';
export const CONST_SESSION_TYPE_LABEL = 'Type';
export const CONST_SESSION_SCOPES_KEY = 'typeData.scopes';
export const CONST_SESSION_SCOPES_LABEL = 'Scopes';

@Injectable()
export class SessionFlowListService extends CoreService<SessionFlowListComponent, SessionFlowListModel> {
  init(context: SessionFlowListComponent, model: SessionFlowListModel): void {
    super.init(context, model);

    this.m.columns = [new TableColumn(CONST_SESSION_TYPE_LABEL, CONST_SESSION_TYPE_KEY), new TableColumn(CONST_SESSION_SCOPES_LABEL, CONST_SESSION_SCOPES_KEY)];
  }

  doFilter(table: Table): void {
    table && table.filter(null, '', '');
  }
}
