import { Injectable } from '@angular/core';
import { LimitedMainComponent } from '@limited/pages/limited-main/limited-main.component';
import { LimitedMainModel } from '@limited/pages/limited-main/types/limited-main.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Constants } from '@shared/constants/constants';
import { CoreService } from '@shared/core/services/core.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';
import { PushNotificationService } from '@shared/services/push-notification.service';
import { StateKey } from '@shared/types/local-storage.types';
import { RuleDataHolderService, RuleEngineService } from '@workflows/services';
import { take } from 'rxjs/operators';

@Injectable()
export class LimitedMainService extends CoreService<LimitedMainComponent, LimitedMainModel> {
  isLoad: boolean = false;

  constructor(
    public readonly cache: NewCacheService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly ruleEngineService: RuleEngineService,
    private readonly ruleDataHolderService: RuleDataHolderService,
    private readonly localStorageService: LocalStorageService,
    private readonly authorizationService: AuthorizationService,
  ) {
    super();
  }

  async init(context: LimitedMainComponent, model: LimitedMainModel): Promise<void> {
    super.init(context, model);

    const anonymousUserToken: string = this.localStorageService.getFromState(StateKey.master, Constants.token);

    if (!anonymousUserToken) {
      await this.authorizationService.anonymousLogin();
    }

    if (this.isLoad) {
      this.cache.initCache();

      if (this.cache.isLoaded) {
        await this.initRules();
        await this.pushNotificationService.init();
      } else {
        this.cache.isLoaded$.pipe(take(1)).subscribe({
          next: async () => {
            this.initRules();
            await this.pushNotificationService.init();
          },
        });
      }
    } else {
      this.cache.refreshCache();

      setTimeout(() => {
        this.isLoad = true;
      }, 300);
    }
  }

  private initRules(): void {
    this.ruleEngineService.enableRuleEngine(true);
    this.ruleDataHolderService.initRules();
  }
}
