import { Directive } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, TenantUserService } from '@api/services';
import { PageHelper } from '@shared/helpers/page-helper';
import { AnnouncementService } from '@shared/services/announcement.service';
import { AuthorizationHelperService } from '@shared/services/authorization/authorization-helper.service';
import { AuthorizationService } from '@shared/services/authorization/authorization.service';
import { LocalStorageService } from '@shared/services/local-storage.service';

@Directive()
export abstract class BaseSsoService {
  additionalClasses: string[] = [];
  abstract tooltip: string;
  abstract content: string;

  constructor(
    protected readonly authService: AuthService,
    protected readonly authorizationService: AuthorizationService,
    protected readonly authorizationHelperService: AuthorizationHelperService,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    protected readonly tenantUserService: TenantUserService,
    protected readonly announcement: AnnouncementService,
    protected readonly pageHelper: PageHelper,
    private readonly localStorageService: LocalStorageService,
  ) {}

  setSsoLogin(value: boolean): void {
    this.localStorageService.set('runningSsoLogin', value);
  }

  getSsoLogin(): boolean {
    return Boolean(this.localStorageService.get('runningSsoLogin'));
  }

  abstract onInit(redirectIfLogged?: () => void): Promise<void>;

  abstract onClickHandler(): Promise<void>;
}
