import { InsertAclActionWorkflowSettingsDto } from '@api/models/insert-acl-action-workflow-settings-dto';
import { WorkflowActionDto } from '@api/models/workflow-action-dto';
import { ConvertToServerDatetime } from '@shared/methods/date.methods';
import { AclItemType } from '@widgets/shared/components/acl/types/acl.types';
import { RuleConditionValueConverterService } from '../../shared/services/rule-condition-value-converter.service';
import { WorkflowActionType } from '../action.types';
import { AbstractWorkflowAction } from './abstract/abstract.action';

export interface InsertAclEntity {
  id: string;
  type: AclItemType;
  expirationDate: Date | null;
}

export enum InsertAclKeys {
  id = 'id',
  aclType = 'aclType',
  forEachPath = 'forEachPath',
  entities = 'entities',
}

export enum WorkflowInsertAclTypes {
  view = 'view',
  modify = 'modify',
  delete = 'delete',
  permissions = 'permissions',
}

export class WorkflowActionInsertAclDto implements InsertAclActionWorkflowSettingsDto {
  id: InsertAclActionWorkflowSettingsDto[InsertAclKeys.id] = { value: '', isDynamic: false };
  aclType: InsertAclActionWorkflowSettingsDto[InsertAclKeys.aclType] = { value: WorkflowInsertAclTypes.view, isDynamic: false };
  forEachPath: InsertAclActionWorkflowSettingsDto[InsertAclKeys.forEachPath] = { value: '', isDynamic: false };
  entities: InsertAclActionWorkflowSettingsDto[InsertAclKeys.entities] = { value: {}, isDynamic: false };

  constructor(action?: WorkflowActionInsertAclDto) {
    action && Object.assign(this, action);
  }
}

export class WorkflowActionInsertAcl extends AbstractWorkflowAction<WorkflowActionInsertAclDto> {
  entitiesArray: InsertAclEntity[] = [];

  constructor(conditionConverter: RuleConditionValueConverterService, dto?: WorkflowActionDto) {
    super({ actionSettingDto: WorkflowActionInsertAclDto, conditionConverter, type: WorkflowActionType.INSERT_ACL, dto });
    dto && this.fromDto(dto);
  }

  canBeExecuted(): boolean {
    return true;
  }

  execute(): void {
    return;
  }

  isValid(): boolean {
    return true;
  }

  fromDto(dto: WorkflowActionDto) {
    this.entitiesArray = Object.values((dto.actionSettings as InsertAclActionWorkflowSettingsDto).entities.value).reduce((entities, entity) => {
      return [...entities, { id: entity[0], type: entity[1], expirationDate: entity?.[2] ? new Date(entity[2]) : null } as InsertAclEntity];
    }, [] as InsertAclEntity[]);
  }

  toServer(): WorkflowActionDto {
    const aclTypeSettings = { value: this.actionSettings.aclType.value, isDynamic: false };
    const forEachPatchSettings = { value: this.actionSettings.forEachPath.value || null, isDynamic: false };
    const entitiesValue = this.entitiesArray.reduce((entities, entity, index) => {
      return {
        ...entities,
        [index]: [entity.id, entity.type, entity.expirationDate ? ConvertToServerDatetime(entity.expirationDate) : []].flat(),
      };
    }, {});

    return {
      ...super.toServer(),
      actionSettings: {
        ...this.actionSettings,
        [InsertAclKeys.aclType]: aclTypeSettings,
        [InsertAclKeys.forEachPath]: forEachPatchSettings,
        [InsertAclKeys.entities]: {
          isDynamic: this.actionSettings.entities.isDynamic,
          value: entitiesValue,
        },
      },
    };
  }
}
