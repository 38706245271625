<div class="grid m-2 pb-3">
  <div class="col-11 mb-3 px-0 py-0 picker-header" style="text-transform: capitalize;">
    {{ label }}
  </div>

  <div class="col-1 mb-3 px-0 py-0">
    <span class="pi pi-times-circle icon close" (click)="closePicker()"></span>
  </div>

  <div class="col-4 mb-3 px-0 py-0">
    <p-dropdown [options]="memberTypesList" [(ngModel)]="memberType"></p-dropdown>
  </div>

  <div class="col-3 px-3 py-0">
    <button *ngIf="userIds?.length || teamIds?.length"
            (click)="addMembers()"
            [label]="'Add' | translate"
            class="p-button"
            pButton
            type="button"
    ></button>
  </div>

  <div class="col-5 px-3 py-0" *ngIf="userIds?.length || teamIds?.length">
    <p-calendar
      [(ngModel)]="expiration"
      [dateFormat]="dateFormat"
      [showTime]="false"
      [firstDayOfWeek]="userData?.uiConfig?.firstDayOfWeek"
      [placeholder]="'no expiration' | translate"
      [inputStyle]="{ border: 'none', fontSize: '12px', padding: 0 }"
      [inputStyleClass]="'cursor-pointer'"
      appendTo="body"
    >
    </p-calendar>
  </div>

  <div class="col-12">
    <app-user-autocomplete *ngIf="memberType === AclMemberTypes.users"
                           [(value)]="userIds"
                           [multipleValues]="true"
                           [appendTo]="null"
                           [excludePublicUser]="true"
    ></app-user-autocomplete>

    <p-multiSelect *ngIf="memberType === AclMemberTypes.teams"
                   [options]="teams.list"
                   [optionLabel]="'name'"
                   [optionValue]="'id'"
                   [filter]="true"
                   [(ngModel)]="teamIds"
                   style="width: 100%"
                   appendTo="body"
    ></p-multiSelect>
  </div>
</div>
