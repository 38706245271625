import { AfterViewInit, Component, effect, Inject, inject, Input, OnInit } from '@angular/core';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { IsBoolean, IsEnumerated, IsUser } from '@shared/methods/data-type.methods';
import { FormFieldCommunicatorService } from '@shared/services/form-field-communicator.service';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { DATBooleanLayoutVariant, DATEnumLayoutVariant, HyperlinkDisplayVariant } from '@shared/types/display-at-types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetHelper } from '@widgets/artifact-widget/helpers/artifact-widget.helper';
import { ArtifactWidgetFormItem } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { ArtifactWidgetModel, ArtifactWidgetSelectedEntities } from '@widgets/artifact-widget/types/artifact-widget.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { AbstractAttributeValueChange, AttributeValueSettable, ModifyAttributeValueOperation } from '@workflows/shared';
import { AttributeMandatorySettable } from '@workflows/shared/types/attribute-mandatory-settable';
import { AttributeValueModifiable } from '@workflows/shared/types/attribute-value-modifiable';
import { AttributeVisibleSettable } from '@workflows/shared/types/attribute-visible-settable';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-artifact-widget-readonly-field',
  templateUrl: './artifact-widget-readonly-field.component.html',
  styleUrls: ['./artifact-widget-readonly-field.component.scss'],
  providers: [{ provide: AbstractAttributeValueChange, useExisting: ArtifactWidgetReadonlyFieldComponent }],
})
export class ArtifactWidgetReadonlyFieldComponent
  extends AbstractAttributeValueChange
  implements AttributeValueSettable, AttributeValueModifiable, AttributeVisibleSettable, AttributeMandatorySettable, OnInit, AfterViewInit
{
  @Input() ownerId: string;
  @Input() artifactDto: ArtifactResponseDto;
  @Input() item: ArtifactWidgetFormItem;
  @Input() attr: NewClientAttribute;
  @Input() attribute: NewAttribute;
  @Input() attributes: ListContainer<NewAttribute>;
  @Input() dataType: NewDataType;
  @Input() dataTypes: ListContainer<NewDataType>;
  @Input() model: ArtifactWidgetModel;
  @Input() selected: ArtifactWidgetSelectedEntities;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() widgetId: string;

  @Input() set formatSettings(settings: AttributeFormatSettings) {
    this._formatSettings = settings;
    this.visible = this.isPreviewMode ? !settings.hideOnPageLoad : true;
  }

  displayVariant = HyperlinkDisplayVariant;
  labelBehaviourEnum = LabelBehaviourEnum;
  value: boolean | null = null;
  visible = true;
  private _formatSettings: AttributeFormatSettings;
  private readonly artifactWidgetHelper = inject(ArtifactWidgetHelper);

  constructor(
    @Inject(IS_PREVIEW_MODE) private readonly isPreviewMode: boolean,
    private readonly formFieldCommunicator: FormFieldCommunicatorService,
  ) {
    super();

    effect(() => {
      const visibilityEvent = this.formFieldCommunicator.formFieldVisibilitySignal();
      if (visibilityEvent.widgetId === this.widgetId) {
        this.visible = visibilityEvent.attributes[this.attribute.id];
      }
    });
  }

  get isRedirectIcon(): boolean {
    return this.dataType.isHyperlink && this.formatSettings.value?.displayMetadata?.selectedVariantCode === this.displayVariant.REDIRECT_ICON;
  }

  get isEnum(): boolean {
    return this.dataType?.isEnum || this.isBooleanEnum();
  }

  get isBoolean(): boolean {
    return this.dataType?.isBoolean && !this.isBooleanEnum();
  }

  get formatSettings(): AttributeFormatSettings {
    return this._formatSettings;
  }

  ngAfterViewInit() {
    if (this.isBoolean && this.selected?.artifact?.attributes && this.selected?.artifact?.attributes[this.attribute.id]?.value) {
      const value = this.selected.artifact.attributes[this.attribute.id].value;
      this.selected.artifact.attributes[this.attribute.id].value = value === 'true' ? true : value === 'false' ? false : value === 'null' ? null : value;
    }
  }

  ngOnInit(): void {
    if (this.dataType?.isBoolean && this.item.attribute) {
      const { value } = this.item.attribute.value;
      this.artifactDto && (this.artifactDto.attributes[this.attribute.id].value = value === '' ? null : JSON.parse(value));
    }

    if (this.isEnum && !this.formatSettings.value.displayMetadata) {
      this.artifactWidgetHelper.setDisplayVariantsMetaData({
        formatSettings: this.formatSettings,
        dataType: this.dataType,
        attributes: this.attributes,
        attributeId: this.attribute.id,
        isDate: this.dataType?.isDate,
        isDateTime: this.dataType.isDateTime,
      });
    }
  }

  setAttributeValue(newValue: any): void {
    if (this.item.attribute) {
      this.item.attribute.value.value = cloneDeep(newValue);
      const updatedValue = new NewClientAttribute(this.item.attribute.value);
      this.item.attribute.value = updatedValue;
      this.onValueChange(updatedValue);
      this.updateSelected(newValue);
    }
  }

  setAttributeVisible(visible: boolean) {
    this.visible = visible;
  }

  setAttributeMandatory(mandatory: boolean) {
    this.attr.isMandatory = mandatory;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onValueChange(newValue: any): void {
    // Do not delete this function, it makes sense although it is empty
  }

  modifyAttributeValue(value: any, operation: ModifyAttributeValueOperation): void {
    if (!this.item.attribute) return;

    switch (operation) {
      case ModifyAttributeValueOperation.add:
        this.addValues(value);
        break;
      case ModifyAttributeValueOperation.remove:
        this.removeValues(value);
        break;
      case ModifyAttributeValueOperation.replace:
        this.replaceValue(value);
        break;
    }
  }

  private isBooleanEnum(): boolean {
    return (
      this.dataType?.isBoolean &&
      Boolean(this.formatSettings.value?.displayMetadata?.selectedVariantCode) &&
      this.formatSettings.value?.displayMetadata?.selectedVariantCode !== DATBooleanLayoutVariant.DEFAULT &&
      Boolean(DATEnumLayoutVariant[this.formatSettings.value?.displayMetadata?.selectedVariantCode as any as keyof typeof DATEnumLayoutVariant])
    );
  }

  private addValues(valueToAdd: any): void {
    if (!this.item.attribute!.value.value?.length) {
      return this.replaceValue(valueToAdd);
    }

    Array.isArray(valueToAdd) ? valueToAdd.forEach(valueToAdd => this.addValue(valueToAdd)) : this.addValue(valueToAdd);
  }

  private addValue(valueToAdd: any): void {
    if (!valueToAdd) return;

    if (!this.item.attribute!.value.value.find((attrVal: SelectOption<string, any> | DataTypeValueResponseDto) => attrVal.value === valueToAdd.value)) {
      this.setAttributeValue([...this.item.attribute!.value.value, valueToAdd]);
    }
  }

  private replaceValue(newValue: any): void {
    if (!newValue) {
      this.setAttributeValue(this.attribute.multipleValues ? [] : newValue);
    } else if (this.attribute.multipleValues) {
      this.setAttributeValue(Array.isArray(newValue) ? newValue : [newValue]);
    } else {
      this.setAttributeValue(newValue);
    }
  }

  private removeValues(valueToRemove: any): void {
    if (!this.item.attribute!.value.value || !this.item.attribute!.value.value.length) {
      return;
    }

    if (Array.isArray(valueToRemove)) {
      const newValue = this.item.attribute!.value.value.filter(
        (attrVal: SelectOption<string, any> | DataTypeValueResponseDto) => !valueToRemove.some(valueToRemove => attrVal.value === valueToRemove.value),
      );
      this.setAttributeValue(newValue);
    } else {
      this.removeValue(valueToRemove);
    }
  }

  private removeValue(valueToRemove: any): void {
    const valueIndex = this.item.attribute!.value.value.findIndex(
      (attrVal: SelectOption<string, any> | DataTypeValueResponseDto) => attrVal.value === valueToRemove.value,
    );
    if (valueIndex !== -1) {
      this.setAttributeValue(this.item.attribute!.value.value.splice(valueIndex, 1));
    }
  }

  private updateSelected(newValue: any): void {
    if (!this.selected.artifact) return;

    this.selected.artifact.attributes[this.attribute.id] ??= { id: this.attribute.id, isMandatory: false, value: null };

    if (IsUser(this.dataType.baseDataType!)) {
      this.selected.artifact.attributes[this.attribute.id].value = newValue ? newValue.map((val: SelectOption<string, string>) => val?.value) : [];
    }

    if (IsEnumerated(this.dataType.kind)) {
      this.selected.artifact.attributes[this.attribute.id].value = this.attribute.multipleValues
        ? newValue?.map((val: SelectOption<string, any>) => val?.value)
        : newValue?.value;
    }

    if (IsBoolean(this.dataType.baseDataType!)) {
      this.selected.artifact.attributes[this.attribute.id].value = newValue;
    }

    this.selected.artifact = cloneDeep(this.selected.artifact);
  }
}
