import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { TransformArrayToSelectOptionsPipe } from '@shared/pipes/common/transform-array-to-select-options.pipe';
import { ArtifactFiltersModule } from '@widgets/shared/components/artifact-filters/artifact-filters.module';
import { ListItemClickActionSettingsFormModule } from '@widgets/shared/components/list-item-click-action-settings-form/list-item-click-action-settings-form.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NumberWidgetContentSettingsComponent } from './components/number-widget-content-settings/number-widget-content-settings.component';
import { NumberWidgetSettingsComponent } from './components/number-widget-settings/number-widget-settings.component';
import { NumberWidgetComponent } from './number-widget.component';
import { ArtifactSortsModule } from '@widgets/shared/components/artifact-sorts/artifact-sorts.module';
import { CustomEventSettingsFormModule } from '@widgets/shared/components/custom-event-settings-form/custom-event-settings-form.module';

@NgModule({
  declarations: [NumberWidgetComponent, NumberWidgetSettingsComponent, NumberWidgetContentSettingsComponent],
	imports: [
		CommonModule,
		DropdownModule,
		FormsModule,
		MultiSelectModule,
		CommonPipesModule,
		ButtonModule,
		ReactiveFormsModule,
		InputNumberModule,
		InputTextModule,
		AccordionModule,
		TranslateModule,
		ElvisSharedModule,
		OverlayPanelModule,
		StyleFormModule,
		CheckboxModule,
		ArtifactFiltersModule,
		BadgeModule,
		ListItemClickActionSettingsFormModule,
		ArtifactSortsModule,
		CustomEventSettingsFormModule,
	],
  exports: [NumberWidgetComponent],
  providers: [TransformArrayToSelectOptionsPipe],
})
export class NumberWidgetModule {}
