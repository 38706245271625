import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { RuleCondition } from '@workflows/types/conditions/rule-condition';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'app-workflow-rule-date-condition',
  standalone: true,
  imports: [CalendarModule, InputNumberModule, ElvisSharedModule, NgIf, ElvisSharedModule, FormsModule, TranslateModule],
  templateUrl: './workflow-rule-date-condition.component.html',
  styleUrl: './workflow-rule-date-condition.component.scss',
})
export class WorkflowRuleDateConditionComponent {
  @Input() condition: RuleCondition;

  constructor() {}
}
