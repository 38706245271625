import { Injectable } from '@angular/core';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { TenantApplicationService } from '@api/services/tenant-application.service';
import { TenantPageService } from '@api/services/tenant-page.service';
import { PageBuilderHelper } from '@private/helpers/page-builder.helper';
import { ApplicationModel } from '@private/pages/application-management/application/types/application.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ApplicationSwitcherService } from '@shared/components/application-switcher/services/application-switcher.service';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { NewApplication } from '@shared/types/application.types';
import { SelectOption } from '@shared/types/shared.types';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ApplicationService extends CoreService<any, ApplicationModel> {
  constructor(
    public readonly tenantApplicationService: TenantApplicationService,
    public readonly blockUiService: BlockUiService,
    private readonly tenantPageService: TenantPageService,
    private readonly pageBuilderHelper: PageBuilderHelper,
    public readonly s: ApplicationSwitcherService,
    public readonly cache: NewCacheService,
  ) {
    super();
  }

  async init(context: any, model: ApplicationModel): Promise<void> {
    super.init(context, model);

    try {
      this.m.inProgress = true;
      if (this.c.urlParams.id) {
        const dto = await lastValueFrom(this.tenantApplicationService.applicationControllerGet({ id: this.c.urlParams.id }));
        this.m.application = dto ? new NewApplication(dto) : new NewApplication();
        this.setOriginalObject<NewApplication>(this.m.application);
      }
    } finally {
      this.m.inProgress = false;
    }

    this.m.application && this.m.application.id && (await this.getPages());
  }

  async getPages(): Promise<void> {
    const meta = { filter: JSON.stringify({ applicationId: { $oid: this.m.application.id } }) };
    this.m.pageOptions = (await lastValueFrom(this.tenantPageService.pageControllerList(meta))).data.map(dto => new SelectOption(dto.name, dto.id));
  }

  async save(): Promise<void> {
    this.m.inProgress = true;
    this.blockUiService.blockUi();

    try {
      this.m.application.name = this.m.application.name.trim();
      const dto = this.m.application.id ? await this.update() : await this.create();
      if (dto) {
        this.cache.data.applications.setItem(dto);
        const application = new NewApplication(dto);
        !this.m.application.id && (await this.assignHomePage(application));
        await this.cancel();
      }
    } finally {
      this.m.inProgress = false;
      this.blockUiService.unblockUi();
    }
  }

  private async create(): Promise<ApplicationResponseDto> {
    const dto = await lastValueFrom(this.tenantApplicationService.applicationControllerCreate({ body: { name: this.m.application.name } }));
    const application = new NewApplication(dto);
    this.s.applications.setList([...this.s.applications.list, application]);
    this.s.changeSelectedApplication(application);
    return dto;
  }

  private async update(): Promise<ApplicationResponseDto> {
    return await lastValueFrom(
      this.tenantApplicationService.applicationControllerUpdate({
        body: { id: this.m.application.id, ...this.getChangedDataFromOriginalObject<NewApplication>(this.m.application) },
      }),
    );
  }

  private async assignHomePage(application: NewApplication): Promise<void> {
    const homePage = await this.pageBuilderHelper.createHomePage(application);

    this.cache.data.pages.setItem(homePage);

    await lastValueFrom(
      this.tenantApplicationService.applicationControllerUpdate({
        body: {
          id: application.id,
          defaultPageId: homePage.id,
        },
      }),
    ).then(dto => this.cache.data.applications.setItem(dto));
  }

  private async cancel(): Promise<void> {
    await this.c.router.navigateByUrl('/admin/application-list');
  }
}
