import { Injectable } from '@angular/core';
import { RegularUserResponseDto } from '@api/models';
import {
  BLANK_FILTER_OPTION_LABEL,
  BLANK_OPTION_FILTER_URL_VALUE,
  CREATED_BY_KEY,
  CURRENT_USER_OPTION_LABEL,
  CURRENT_USER_URL_FILTER_VALUE,
  IS_NOT_EMPTY_OPTION_FILTER_VALUE,
  UNRESOLVED_VALUE,
} from '@shared/constants/constants';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ArtifactFilterHelper } from '@widgets/shared/components/artifact-filters/services/artifact-filter.helper';
import { ArtifactFilter, ArtifactFilterType } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Injectable({
  providedIn: 'root',
})
export class UserFilterService {
  constructor(
    private readonly helper: ArtifactFilterHelper,
    private readonly elvisUtil: ElvisUtil,
  ) {}

  getQuery(filter: ArtifactFilter): any {
    if (!filter.value?.length) {
      return null;
    }

    return filter.type === ArtifactFilterType.system ? this.getQueryForRecordAuthor(filter) : this.getQueryForNonSystemAttribute(filter);
  }

  getFilterValueFromString(str: string, users: RegularUserResponseDto[]): SelectOption<string, string>[] | null {
    const usersMap: Record<string, string> = {};
    users.forEach(user => {
      usersMap[user.id] = user.email;
    });
    const ids = str.split(',');

    return ids.map(id => new SelectOption(usersMap[id] || UNRESOLVED_VALUE, id));
  }

  private getQueryForRecordAuthor(filter: ArtifactFilter): any {
    return {
      [this.getRecordAuthorKey(filter.name)]: {
        $in: filter.value.map(({ value }: SelectOption<string, string>) => {
          return {
            $oid: value === NonAttributeKeys.CURRENT_USER_ID || value === CURRENT_USER_URL_FILTER_VALUE ? this.elvisUtil.getCurrentUser()?.id : value,
          };
        }),
      },
    };
  }

  private getQueryForNonSystemAttribute(filter: ArtifactFilter): any {
    return {
      $or: [
        ...filter.value.map((option: SelectOption<string, string>) => {
          const key = `attributes.${filter.attributeId}.value`;
          const isIsNotEmpty = option.label === IS_NOT_EMPTY_OPTION_FILTER_VALUE;
          const value = this.isCurrentUser(option) ? this.elvisUtil.getCurrentUser()?.id : this.isBlank(option) ? '' : option.value;

          return isIsNotEmpty ? { $and: [{ [key]: { $ne: null } }, { [key]: { $ne: '' } }] } : { [key]: value };
        }),
      ],
    };
  }

  private isCurrentUser(option: SelectOption<string, string>): boolean {
    return option.label === CURRENT_USER_OPTION_LABEL || option.value === CURRENT_USER_URL_FILTER_VALUE;
  }

  private isBlank(option: SelectOption<string, string>): boolean {
    return option.label === BLANK_FILTER_OPTION_LABEL || option.value === BLANK_OPTION_FILTER_URL_VALUE;
  }

  private getRecordAuthorKey(value: string): string {
    return this.helper.getKeyByLabel(value) || CREATED_BY_KEY;
  }
}
