import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { GridTrackType } from '@shared/components/grid-layout-generator/types/grid-track-type';
import { SectionGenerationFormComponent } from '@shared/components/templates/components/section-generation-form/section-generation-form.component';
import { TemplatesCloseResult, TemplatesCloseResultRole } from '@shared/components/templates/types/templates.types';
import { Constants, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { AnnouncementService } from '@shared/services/announcement.service';
import { TemplateService } from '@shared/services/page-management/template.service';
import { WidgetService } from '@shared/services/page-management/widget.service';
import { WindowResizeService } from '@shared/services/window-resize.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { CardWidgetMode } from '@widgets/card-widget/types/card-widget-mode';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { ListMatrixWidgetService } from '@widgets/list-matrix-widget/services/list-matrix-widget.service';
import { ListMatrixWidgetModel } from '@widgets/list-matrix-widget/types/list-matrix-widget-model';
import { ListMatrixWidgetOptions } from '@widgets/list-matrix-widget/types/list-matrix-widget-options';
import { ListOrientation } from '@widgets/list-matrix-widget/types/list-orientation';
import { ArtifactClickHandlerService } from '@widgets/shared/services/artifact-click-handler.service';
import { ClickActionSettingsOptions } from '@widgets/shared/types/click-action-settings-options';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { APPLICATION_ID, HASH, IS_LAYOUT_MODE, IS_PREVIEW_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { AccordionTab } from 'primeng/accordion';
import { Observable, startWith } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-list-matrix-widget',
  templateUrl: './list-matrix-widget.component.html',
  styleUrls: ['./list-matrix-widget.component.scss'],
  providers: [ListMatrixWidgetService],
})
export class ListMatrixWidgetComponent
  extends WidgetsCoreComponent<{
    model: ListMatrixWidgetModel;
  }>
  implements OnInit
{
  @ViewChild(SectionGenerationFormComponent) generationForm: SectionGenerationFormComponent;
  @ViewChild('cardContentTab') cardContentTab: AccordionTab;

  m: ListMatrixWidgetModel;
  sseEvents = 0;
  readonly trackType: typeof GridTrackType = GridTrackType;
  canRender = true;
  clickActionSettingsOptions: ClickActionSettingsOptions;

  NAME_VALUE = NAME_KEY;
  ID_VALUE = ID_KEY;

  columnCount$: Observable<number>;
  gridTemplateColumns$: Observable<string>;
  loading$: Observable<boolean>;

  constructor(
    @Inject(APPLICATION_ID) public applicationId: string,
    @Inject(WIDGET) public widget: BlockPartWidget<{ model: ListMatrixWidgetModel }>,
    @Inject(LABEL) public label: string,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    @Inject(IS_PREVIEW_MODE) public isPreviewMode: boolean,
    @Inject(HASH) public hash: string,
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    private readonly s: ListMatrixWidgetService,
    private readonly templateService: TemplateService,
    private readonly cache: NewCacheService,
    private readonly widgetService: WidgetService,
    private readonly artifactClickHandlerService: ArtifactClickHandlerService,
    private readonly windowResizeService: WindowResizeService,
  ) {
    super(route, router, announcement, elRef);
  }

  get options(): ListMatrixWidgetOptions {
    return this.m.options;
  }

  get artifactType(): NewArtifactType | null {
    return this.options.artifactTypes.listMap[this.m.settings.list.artifactTypeId] || null;
  }

  get isArtifactTypeSelected(): boolean {
    return !!this.m.settings.list.artifactTypeId;
  }

  get isTemplateSelected(): boolean {
    return !!this.m.cardWidget;
  }

  get isPaginatorVisible(): boolean {
    const { listResponseMeta } = this.m;

    return !!listResponseMeta && this.m.settings.list.cardsPerPage < listResponseMeta.totalCount;
  }

  get isOrientationHorizontal(): boolean {
    return this.m.settings.list.orientation === ListOrientation.horizontal;
  }

  get isOrientationVertical(): boolean {
    return this.m.settings.list.orientation === ListOrientation.vertical;
  }

  get cardMarginBottom(): string | null {
    return this.isOrientationVertical ? this.m.settings.list.styles.rowGap : null;
  }

  private get columnCount(): number {
    const { container } = this;
    const columnsCount = this.m.settings.list.columnsCount;

    if (container && columnsCount !== 1) {
      if (container.offsetWidth <= Constants.smBreakpoint) {
        return 1;
      }

      if (container.offsetWidth <= Constants.mdBreakpoint) {
        return 2;
      }
    }

    return columnsCount;
  }

  async ngOnInit(): Promise<void> {
    await this.s.init(this);

    this.loading$ = this.m.loading$;

    this.clickActionSettingsOptions = {
      attributes: this.options.combinedAttributes,
      dataTypes: this.options.dataTypes.list,
      pages: this.options.pages.toSelectOptions('name', ID_KEY),
      users: this.options.users,
    };

    const id = this.m.settings.card?.templateId;

    if (id) {
      const template = await this.cache.data.templates.getAsync(id);
      await this.loadCardWidget({ role: TemplatesCloseResultRole.reuseTemplate, templates: [template] });
    }

    this.initResponsiveness();
  }

  async onReloadDataClick(): Promise<void> {
    await this.s.loadArtifactsAndSaveParams();
  }

  async onCardClick(artifact: ArtifactResponseDto): Promise<void> {
    await this.artifactClickHandlerService.handleArtifactClickIfNeeded(artifact, this.m.settings.listItemClickAction, this.queryParams);
  }

  async onArtifactTypeChange(): Promise<void> {
    this.options.combinedAttributeOptions = [...this.options.combinedAttributeOptions];
    this.options.combinedAttributeAndLinkOptions = [...this.options.combinedAttributeAndLinkOptions];
    this.m.filters = [];
    this.s.setCurrentArtifactTypeAttributes();

    if (this.isTemplateSelected) {
      this.options.artifacts.setList([]);
      await this.s.loadArtifactsAndSaveParams();
    }
  }

  async onTemplateSelection(): Promise<void> {
    if (this.isArtifactTypeSelected) {
      this.options.artifacts.setList([]);
      await this.s.loadArtifactsAndSaveParams();
    }
  }

  onTemplateUnselection(): void {
    this.m.cardWidget = null;
    this.m.settings.card = {
      templateId: null,
      widgetId: null,
    };
  }

  async onPageChange($event: any): Promise<void> {
    this.m.paginatorEvent = $event;
    await this.s.loadArtifactsAndSaveParams();
  }

  async onChooseCardClick(): Promise<void> {
    const result = await this.templateService.pickTemplate();

    if (!result.templates) {
      return;
    }

    await this.loadCardWidget(result);
  }

  async onFiltersChange(): Promise<void> {
    this.m.paginatorEvent && (this.m.paginatorEvent.page = 0);
    await this.s.loadArtifactsAndSaveParams();
  }

  async onSortsChange(): Promise<void> {
    await this.s.loadArtifactsAndSaveParams();
  }

  showLoader(): void {
    this.m.showLoader();
  }

  hideLoader(): void {
    this.m.hideLoader();
  }

  private async loadCardWidget({ role, templates }: TemplatesCloseResult): Promise<void> {
    if (templates!.length > 1) {
      await this.announcement.error('Unable to select external template with multiple widget templates inside.');
    }
    const template = templates![0];
    this.m.settings.card.templateId = null;
    const { widgetId } = template!.template as any;

    if (!widgetId) {
      await this.announcement.error('List matrix widget: there is no Card widget ID in the template.');

      return;
    }

    const cardWidgetDto = await this.s.getCardWidgetDto(widgetId);
    const widget = new BlockPartWidget(cardWidgetDto);

    // TODO: it should create a new widget???
    if (role === TemplatesCloseResultRole.copyTemplate) {
      widget.removeIdsForReuse();
      this.m.settings.card.widgetId = widgetId;
    }

    // this line needs to support old not updated widgets from DB
    const cardWidgetModelDto =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cardWidgetDto.settings && !widget.value.model.settings
        ? {
            ...widget.value.model,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            settings: cardWidgetDto.settings,
          }
        : { ...widget.value.model };

    if (cardWidgetModelDto.settings?.widgetMode !== CardWidgetMode.listItem) {
      await this.announcement.error('It is not a "list item" card');
      return;
    }

    const model = CardWidgetModel.fromDtoAndOptions(cardWidgetModelDto, this.options);
    widget.value = { model };
    widget.templateName = template!.name;
    this.m.cardWidget = widget;
    this.m.settings.card.templateId = template!.id;
    this.m.settings.card.widgetId = widget.id;

    model.options.attributes.setList(this.options.attributes.list, ID_KEY);
    model.options.systemAttributes.setList(this.options.systemAttributes.list, ID_KEY);
    model.options.dataTypes.setList(this.options.dataTypes.list, ID_KEY);
    model.options.artifactTypes.setList(this.options.artifactTypes.list, ID_KEY);
    model.options.linkTypes.setList(this.options.linkTypes.list, ID_KEY);
    model.options.applications.setList(this.options.applications.list, ID_KEY);
    model.options.users.setList(this.options.users.list, ID_KEY);
    model.options.pages.setList(this.options.pages.list, ID_KEY);

    await this.widgetService.loadCardInnerWidgets(model);

    await this.onTemplateSelection();
  }

  private initResponsiveness(): void {
    this.columnCount$ = this.windowResizeService.resize$.pipe(
      startWith(null),
      map(() => this.columnCount),
    );
    this.gridTemplateColumns$ = this.columnCount$.pipe(map((columnCount: number) => `repeat(${columnCount}, 1fr)`));
  }
}
