import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SetAlignmentClassToChildrenPipe } from '@shared/pipes/common/set-alignment-class-to-children.pipe';
import { DisplayAtDatetimeComponent } from './display-at-datetime.component';

@NgModule({
  declarations: [DisplayAtDatetimeComponent],
  imports: [CommonModule, SetAlignmentClassToChildrenPipe],
  exports: [DisplayAtDatetimeComponent],
})
export class DisplayAtDatetimeModule {}
