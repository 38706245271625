<div class="row mt-1">
  <div class="col-4 text-bold">
    {{ 'Scheme' | translate }}
  </div>
  <div class="col-8">
    <div class="w-100">
      <p-dropdown [(ngModel)]="action.actionSettings.scheme.value" [options]="requestSchemes" appendTo="body"></p-dropdown>
    </div>
  </div>
</div>

<div class="row mt-1">
  <div class="col-4 text-bold">
    {{ 'Mailbox name' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings.mailboxName.value"
      [placeholder]="'Enter mailbox name' | translate"
      pInputText
      class="w-100"
      id="mailboxNameInput"
    />
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Download attachments' | translate }}
  </div>
  <div class="col-8">
    <p-checkbox [(ngModel)]="action.downloadAttachments" id="hardDelete" binary="true" class="mr-2 pt-2"></p-checkbox>
  </div>
</div>

<div class="row">
  <div class="col-4 text-bold">
    {{ 'Store results path' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings.storeResultsPath.value"
      [placeholder]="'Enter store results path' | translate"
      pInputText
      class="w-100"
      id="scopeInput"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Host' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      [(ngModel)]="action.actionSettings.host.isDynamic"
      id="isDynamicHost"
      binary="true"
      class="mr-2"
      (ngModelChange)="onIsDynamicHostChange()"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <input
      [(ngModel)]="action.actionSettings.host.value"
      [placeholder]="action.actionSettings.host.isDynamic ? 'Enter dynamic host' : 'Enter URL and optionally enter PORT' | translate"
      pInputText
      class="w-100"
      id="hostInput"
    />
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Auth' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      [(ngModel)]="action.actionSettings.auth.isDynamic"
      id="isDynamicAuth"
      binary="true"
      class="mr-2"
      (ngModelChange)="onIsDynamicAuthChange()"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <div class="auth-section">
      <input
        [(ngModel)]="action.actionSettings.auth.value.plain_user"
        [placeholder]="action.actionSettings.auth.isDynamic ? 'Enter dynamic user' : 'Enter user' | translate"
        pInputText
        class="w-100"
        id="authUserInput"
      />
      @if (action.actionSettings.auth.isDynamic) {
        <input
          [(ngModel)]="action.actionSettings.auth.value.plain_pass"
          [placeholder]="action.actionSettings.auth.isDynamic ? 'Enter dynamic password' : 'Enter password' | translate"
          pInputText
          class="w-100"
          id="authPasswordInputDynamic"
        />
      } @else {
        <div class="w-100">
          <form autocomplete="off">
            <p-password
              id="password1" type="password" class="w-full" [feedback]="false" placeholder="Password"
              name="password"
              styleClass="p-password p-component p-inputwrapper p-input-icon-right"
              [style]="{'width':'100%'}"
              [inputStyle]="{'width':'100%'}"
              [(ngModel)]="action.actionSettings.auth.value.plain_pass">
            </p-password>
          </form>
        </div>
      }
    </div>
  </div>

  <div class="row">
    <div class="col-2 text-bold">
      {{ 'Filters' | translate }}
    </div>
    <div class="col-2">
      <p-checkbox
        [(ngModel)]="action.actionSettings.filters.isDynamic"
        id="isFilterDynamic"
        binary="true"
        class="mr-2"
        (ngModelChange)="onIsDynamicFiltersChange()"
      ></p-checkbox>
      {{ 'dynamic' | translate }}
    </div>
    <div class="col-8">
      @for (filter of action.imapFilters; track filter.key + idx; let idx = $index) {
        <div class="filters-container">
          <app-imap-filter
            [filter]="filter"
            [filterKeyOptions]="filterKeyOptions"
            [isDynamic]="action.actionSettings.filters.isDynamic"
          ></app-imap-filter>
          @if (action.imapFilters.length) {
            <button class="p-button p-button-text p-button-danger" pButton type="button" icon="pi pi-minus" (click)="onRemoveFilter(idx)"></button>
          }
        </div>
      }
      <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddFilter()"></button>
    </div>
  </div>
</div>
