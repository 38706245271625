import { Component, Input } from '@angular/core';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { MenuItemUiType } from '@widgets/menu-widget/types/tree-types';
import { StyleCopyPastingService } from '../../../../services/style-copy-pasting.service';
import { MenuWidgetModel } from '../../../../types/menu-widget.types';

@Component({
  selector: 'app-menu-item-settings-form',
  templateUrl: './menu-item-settings-form.component.html',
  styleUrls: ['./menu-item-settings-form.component.scss'],
})
export class MenuItemSettingsFormComponent {
  @Input() m: MenuWidgetModel;
  @Input() s: MenuWidgetService;
  menuItemUiType = MenuItemUiType;

  constructor(public readonly styleCopyService: StyleCopyPastingService) {}
}
