import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ImportDto } from '@api/models/import-dto';
import { RecordDto } from '@api/models/record-dto';

export class NewApplication implements ApplicationResponseDto {
  id: string;
  name: string;
  isProtected: boolean;
  defaultPageId: string | null = null;
  defaultFolderId: string;
  defaultTeamId: string;
  imported?: ImportDto;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null = null;

  constructor(application?: Partial<ApplicationResponseDto>) {
    application && Object.assign(this, application);
  }
}
