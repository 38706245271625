import { Injectable } from '@angular/core';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { WorkflowConditionGroupDto } from '@api/models/workflow-condition-group-dto';
import { BaseDataType } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { INVALID_USER_LABEL } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { IsBounded } from '@shared/methods/data-type.methods';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { SelectOption } from '@shared/types/shared.types';
import { DYNAMIC_ATTRIBUTE_PREFIX, DYNAMIC_ATTRIBUTE_SUFFIX } from '@workflows/types';
import { RuleCondition } from '@workflows/types/conditions/rule-condition';
import { RuleConditionGroup } from '../../types/conditions/rule-condition-group';

@Injectable({ providedIn: 'root' })
export class RuleConditionValueConverterService {
  constructor(private readonly cache: NewCacheService) {}

  valuesToClient(dto: RuleConditionGroup | null): RuleConditionGroup | null {
    dto?.conditions?.forEach((condition: RuleCondition) => {
      if (condition.source.value.includes(DYNAMIC_ATTRIBUTE_PREFIX)) {
        condition.source.value = this.dynamicValueToClient(condition.source.value);
      }

      if (!condition.destination?.value) return;

      const attribute = this.cache.data.attributes.get(condition.source.value);
      if (!attribute) return;

      const dataType = this.cache.data.dataTypes.get(attribute.dataTypeId);
      if (!dataType) return;

      return this.conditionToClient(condition, new NewDataType(dataType));
    });

    dto?.groups.forEach((group: RuleConditionGroup) => {
      group = new RuleConditionGroup(this, group);
      this.valuesToClient(group);
    });

    return dto;
  }

  valuesToServer(rule: RuleConditionGroup | null): WorkflowConditionGroupDto | null {
    rule?.conditions?.forEach((condition: RuleCondition) => {
      const attributeId = condition.source.value;

      if (!condition.source.value.includes(DYNAMIC_ATTRIBUTE_PREFIX) && !condition.source.manual) {
        condition.source.value = this.dynamicValueToServer(condition.source.value);
      }

      const attribute = this.cache.data.attributes.get(attributeId);
      if (!attribute) return;

      const dataType = this.cache.data.dataTypes.get(attribute.dataTypeId);
      if (!dataType) return;

      if (
        condition.destination.isDynamic ||
        (!condition.destination.value && ![BaseDataType.boolean, BaseDataType.integer, BaseDataType.decimal].includes(dataType.baseDataType as BaseDataType))
      ) {
        return;
      }

      return this.conditionToServer(condition, new NewDataType(dataType));
    });

    rule?.groups.forEach((group: RuleConditionGroup) => {
      this.valuesToServer(group);
    });

    return rule;
  }

  private conditionToClient(condition: RuleCondition, dataType: NewDataType): RuleCondition {
    if (!condition.destination.value || condition.destination.isDynamic) {
      return condition;
    }

    if (dataType.isUser) {
      condition.destination.value = this.userToClient(condition.destination.value);
    }

    if (dataType.isEnum) {
      condition.destination.value = this.enumToClient(condition.destination.value, dataType);
    }

    if (dataType.isDateTime || dataType.isDate || dataType.isTime) {
      condition.destination.value = this.dateToClient(condition.destination.value);
    }

    if (dataType.isBoolean) {
      condition.destination.value = this.booleanToClient(condition.destination.value);
    }

    if (IsBounded(dataType.kind)) {
      condition.destination.value = this.boundedNumberToClient(condition.destination.value);
    }

    return condition;
  }

  private userToClient(userId: string): SelectOption<string, string>[] {
    const label =
      userId === NonAttributeKeys.CURRENT_USER_ID
        ? userId
        : (this.cache.data.users.get(userId)?.attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string);
    return [new SelectOption(label || INVALID_USER_LABEL, userId)];
  }

  private enumToClient(value: string, dataType: NewDataType): DataTypeValueResponseDto {
    const dataTypeValues = this.cache.data.dataTypes.get(dataType.id)?.values;
    return dataTypeValues?.find(dataTypeValue => dataTypeValue.value === value) as DataTypeValueResponseDto;
  }

  private dateToClient(value: string): Date {
    return new Date(value);
  }

  private booleanToClient(value: string): boolean | null {
    return value === 'true' ? true : value === 'false' ? false : null;
  }

  private boundedNumberToClient(value: string): number | undefined {
    return value ? +value : undefined;
  }

  private conditionToServer(dto: RuleCondition, dataType: NewDataType): RuleCondition {
    if (dto.destination.isDynamic) {
      return dto;
    }

    if (dataType.isUser) {
      dto.destination.value = this.userToServer(dto.destination.value);
    }

    if (dataType.isEnum) {
      if (!dto.destination.isDynamic) dto.destination.value = this.enumToServer(dto.destination.value);
    }

    if (dataType.isBoolean && !dto.destination.isDynamic) {
      dto.destination.value = dto.destination.value === true ? 'true' : dto.destination.value === false ? 'false' : 'null';
    }

    if (dataType.isInteger || dataType.isDecimal) {
      if (!dto.destination.value) {
        dto.destination.value = '';
      }

      if (IsBounded(dataType.kind)) {
        dto.destination.value = String(dto.destination.value);
      }
    }

    return dto;
  }

  private userToServer(value: SelectOption<string, string>[]): string {
    return value[0].value;
  }

  private enumToServer(value: DataTypeValueResponseDto): string {
    return value.value;
  }

  private dynamicValueToClient(value: string): string {
    return value.replace(/^{attributes\["|"].value}/g, '');
  }

  private dynamicValueToServer(value: string): string {
    return `${DYNAMIC_ATTRIBUTE_PREFIX}${value}${DYNAMIC_ATTRIBUTE_SUFFIX}`;
  }
}
