import { Injectable } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { ArtifactListWidgetTableComponent } from '../artifact-list-widget-table.component';
import { ArtifactListTableModel } from '../types/artifact-list-widget-table.types';
import { ArtifactListWidgetTableLoadHelper } from './artifact-list-widget-table-load-helper.service';

@Injectable()
export class ArtifactListWidgetTableService {
  c: ArtifactListWidgetTableComponent;
  m: ArtifactListTableModel;

  private runtimeStateNotificationHandlers: Record<string, (event: RuntimeStateNotification) => void>;

  constructor(
    public readonly cache: NewCacheService,
    private readonly artifactListWidgetTableLoadHelper: ArtifactListWidgetTableLoadHelper,
  ) {
    this.initRuntimeStateNotificationHandlers();
  }

  init(context: ArtifactListWidgetTableComponent, model: ArtifactListTableModel): void {
    this.c = context;
    this.m = model;
    this.initUser();
  }

  private initRuntimeStateNotificationHandlers(): void {
    this.runtimeStateNotificationHandlers = {};

    this.runtimeStateNotificationHandlers[RuntimeStateNotificationEnum.createLink] = (event: RuntimeStateNotification<string[]>) => {
      if (this.c.selected.linkTypes.find(linkType => linkType.value.id === event.extras))
        this.artifactListWidgetTableLoadHelper.updateLinksWithLinkedArtifacts(event.extras, event.data);
    };

    this.runtimeStateNotificationHandlers[RuntimeStateNotificationEnum.updateLinks] = (event: RuntimeStateNotification<string[]>) => {
      if (this.c.selected.linkTypes.find(linkType => linkType.value.id === event.extras))
        this.artifactListWidgetTableLoadHelper.updateLinksWithLinkedArtifacts(event.extras, event.data);
    };

    this.runtimeStateNotificationHandlers[RuntimeStateNotificationEnum.deleteLink] = (event: RuntimeStateNotification<string[]>) => {
      if (this.c.selected.linkTypes.find(linkType => linkType.value.id === event.extras))
        this.artifactListWidgetTableLoadHelper.updateLinksWithLinkedArtifacts(event.extras, event.data);
    };
  }

  private initUser(): void {
    this.m.currentUser = this.cache.userProfile.value as ArtifactLinkResponseDto;
  }
}
