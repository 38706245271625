import { Params } from '@angular/router';
import { SharedMethods } from '@shared/methods/shared.methods';
import { MenuItemAction } from '@widgets/menu-widget/types/menu-action-types';

export class TreeItem {
  showEmptyParamsAsActive = false;
  hash: string = SharedMethods.getUniqueId();
  imageUrl?: string;
  menuIcon: string;
  label: string;
  subLabel: string;
  chip: string;
  target?: string;
  command?: (event?: any) => void;
  icon?: string;
  draggable? = true;
  droppable? = true;
  selectable? = true;
  expandedIcon?: any;
  collapsedIcon?: any;
  leaf?: boolean;
  expanded: boolean;
  type?: string;
  partialSelected?: boolean;
  styleClass?: string;
  children: TreeItem[] = [];
  parent?: TreeItem | null = null;
  uiType = MenuItemUiType.button;
  actions: MenuItemAction[] = [new MenuItemAction()];
  usesDefaultStyle = true;

  constructor(item?: Partial<TreeItem>) {
    if (item) {
      Object.assign(this, item);
      item.children?.length && (this.children = item.children.map(item => new TreeItem({ ...item })));
    }
  }

  toServer(): TreeItem {
    return this;
  }

  get isTextOnly(): boolean {
    return this.children.length > 0;
  }
}

export enum MenuItemUiType {
  button = 'Button',
  divider = 'Divider',
}

export enum MenuItemCurrentPageBehaviorEnum {
  leaveCurrentPage = 'leaveCurrentPage',
  stayOnCurrentPage = 'stayOnCurrentPage',
}

export class LinkQueryParamMap {
  parametersToAdd: Params = {};
  parameterKeysToRemove: string[] = [];

  constructor(params?: Partial<LinkQueryParamMap>) {
    params && Object.assign(this, params);
  }
}

export interface LinkQueryParam {
  key: string;
  value?: string;
  action: LinkQueryParamActionEnum;
}

export enum LinkQueryParamActionEnum {
  addNew = 'addNew',
  removeExisting = 'removeExisting',
}
