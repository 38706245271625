import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ArtifactLinkResponseDto, LinkRequestDto, SelfUserResponseDto } from '@api/models';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { Environment } from '@environments/environment';
import { Link, LinkDirection, NewLinkBoilerplate } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { Constants, CREATED_ON_KEY, ID_KEY, ID_LABEL, UPDATED_ON_KEY } from '@shared/constants/constants';
import { CoreListComponent } from '@shared/core/components/core-list.component';
import { ListMetaData } from '@shared/core/types/core.types';
import { GetDataTypeFromClientAttribute } from '@shared/methods/artifact.methods';
import { LinkMethods } from '@shared/methods/link.methods';
import { ArtifactLinkService } from '@shared/services/links/artifact-link.service';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ArtifactTypeLinkRestriction, NewLink } from '@shared/types/link.types';
import { ListContainer } from '@shared/types/list-container.types';
import { ClientData } from '@shared/types/local-storage.types';
import { SelectOption } from '@shared/types/shared.types';
import { TableColumn } from '@shared/types/table.types';
import { DateUtil } from '@shared/utils/date.util';
import { LazyLoadEvent } from 'primeng/api';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { RuntimeStateNotificationService } from '../../services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '../../types/runtime-state-notification.types';

@Component({
  selector: 'app-artifact-link-dialog',
  templateUrl: './artifact-link-dialog.component.html',
  styleUrls: ['./artifact-link-dialog.component.scss'],
})
export class ArtifactLinkDialogComponent extends CoreListComponent<any> {
  @Input() linkType: TableColumn;
  @Input() linkTypeOptions: SelectOption<string, LinkType, LinkDirection>[];
  @Input() originArtifactTypes: SelectOption<string, NewArtifactType>[];
  @Input() originArtifact: NewArtifact | null;
  @Input() usersOptions: SelectOption<string, string>[];

  @Input() dataTypes: ListContainer<NewDataType>;
  @Input() attributes: ListContainer<NewAttribute>;
  @Input() artifactTypes: ListContainer<NewArtifactType>;

  @Input() artifactLinksMap: Record<string, Link[]>;
  @Input() newLinksMap: Record<string, Record<LinkDirection, NewLinkBoilerplate[]>> = {};

  @Input() users: ListContainer<ArtifactLinkResponseDto>;
  @Input() parentData: any[];
  @Input() isTable: boolean;
  @Input() successCb: () => void | Promise<void>;
  @Input() applicationId: string;
  @Input() linkTypeIds: Set<string> | null = null;
  @Input() hash: string;
  @Output() onLinkAdd = new EventEmitter<null>();
  @Output() onSingleLinkAdded = new EventEmitter<NewArtifact>();
  @ViewChild('table') table: Table;

  columnFilterTypes: Record<string, string> = {
    [BaseDataType.date]: 'date',
    [BaseDataType.dateTime]: 'datetime',
    [BaseDataType.time]: 'time',
    [BaseDataType.boolean]: 'boolean',
  };

  linkTypeName = '';

  attributeColumns: TableColumn[] = [];
  artifactTypeOptions: SelectOption<string, NewArtifactType>[] = [];

  displayModal = false;
  dateFormat = Environment.calendarConfig.clientDateFormat;

  selectedLinkType: SelectOption<string, LinkType, LinkDirection> | null = null;
  selectedArtifactType: NewArtifactType | null = null;

  columns: TableColumn[] = [];

  userAttributesMap: Record<string, boolean> = {};

  sortableColumnKeys: string[] = [];
  editableColumnKeys: string[] = [];
  enumeratedFilterOptions: Record<string, any> = {};
  enumeratedMultipleValues: Record<string, any> = {};

  attributeIds: string[] = [];

  targetArtifact: any = null;
  restriction: ArtifactTypeLinkRestriction | undefined;
  relevantRestrictions: ArtifactTypeLinkRestriction[] | undefined;
  disableSaveDueToSingleRestrictions = false;
  artifactRowRelevantLinks: Link[] | undefined;
  linkedArtifactsMap: Record<string, NewArtifact>;
  artifactIdsToCheck: Set<string> | undefined;
  artifactRowRelevantNewLinks: NewLink[] | undefined;
  firstDayOfWeek: number;

  constructor(
    dateUtil: DateUtil,
    private readonly cache: NewCacheService,
    private readonly artifactLinkService: ArtifactLinkService,
    public readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly tenantLinkService: TenantLinkService,
  ) {
    super(dateUtil);
  }

  get isMultipleSelectionMode(): boolean {
    const canMulti = (target: boolean, destination: boolean): boolean => {
      return (!target && !destination) || (!target && destination);
    };

    if (this.restriction) {
      const { singleSource, singleDestination } = this.restriction;
      return LinkMethods.isOutgoing(this.linkType.meta) ? canMulti(singleSource, singleDestination) : canMulti(singleDestination, singleSource);
    }

    return false;
  }

  ngOnInit(): void {
    this.firstDayOfWeek = ((this.cache.user.value as SelfUserResponseDto).clientData as ClientData)?.uiConfig?.firstDayOfWeek;
  }

  async onLazyLoad(event: LazyLoadEvent | null = null): Promise<void> {
    await this.sleep();
    this.loading = true;
    try {
      if (event) {
        const { rows, first, filters } = event;
        const { sortField, sortOrder } = event;
        this.addExcludeLinkedArtifactFilter(filters as Record<string, FilterMetadata[]>);

        this.meta = new ListMetaData(
          rows,
          first,
          this.processFilters(filters as Record<string, FilterMetadata[]>),
          this.transformSorting(sortField, sortOrder),
          this.meta.totalCount,
        );
        const { meta, data } = await this.loadDataMethod(this.meta.toQuery());
        this.data = data;
        this.meta.totalCount = meta.totalCount;

        const attributeIds = new Set<string>();
        Object.keys(this.selectedArtifactType?.attributes || []).forEach(attributeId => attributeIds.add(attributeId));
        this.attributeIds = [...attributeIds];

        this.data = data.map(
          artifact =>
            new NewArtifact({
              dto: artifact,
              artifactTypesMap: this.artifactTypes.listMap,
            }),
        );

        this.data.forEach(artifact => {
          artifact.created.by = this.users.listMap[artifact.created.by];
          artifact.updated.by = this.users.listMap[artifact.updated.by];
        });

        this.initColumns();
        this.initColumnFilterOptions();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setTimeout(() => (this.loading = false));
    }
  }

  open(): void {
    this.selectedLinkType = this.linkTypeOptions.find(option => option.value.id === this.linkType.key && option.meta === this.linkType.meta) || null;
    this.linkTypeName =
      this.selectedLinkType?.meta === 'OUTGOING'
        ? this.selectedLinkType.value.outgoingName
        : this.selectedLinkType?.meta === 'INCOMING'
          ? this.selectedLinkType.value.incomingName
          : '';

    if (this.artifactTypeOptions?.length === 1) {
      this.selectedArtifactType = this.artifactTypeOptions[0].value;
    }

    this.displayModal = true;
  }

  close(): void {
    this.displayModal = false;
    this.disableSaveDueToSingleRestrictions = false;
  }

  onDialogHide(): void {
    this.selectedArtifactType = null;
    this.targetArtifact = null;
    this.disableSaveDueToSingleRestrictions = false;
  }

  initDateFilters(): void {
    const keys = Object.keys(this.table.filters).filter(key => key.includes('date') || key.includes('time'));
    keys.forEach(key => {
      (this.table.filters[key] as any[]).forEach(filter => filter.value && (filter.value = new Date(filter.value)));
    });
  }

  initColumnFilterOptions(): void {
    this.enumeratedFilterOptions = {};
    this.enumeratedMultipleValues = {};

    this.data.forEach(item => {
      this.editableColumnKeys.forEach(key => {
        const clientAttribute = this.getAttribute(item, key);

        if (clientAttribute) {
          const attribute = this.attributes.listMap[clientAttribute.id];
          const dataType = this.dataTypes.listMap[attribute.dataTypeId];

          if (dataType?.kind === DataTypeKind.enumerated && !this.enumeratedFilterOptions[key]) {
            this.enumeratedFilterOptions[key] = dataType?.values;
            this.enumeratedMultipleValues[key] = attribute.multipleValues;
          }
        }
      });
    });
  }

  getAttribute(row: any, key: string): NewClientAttribute {
    return row.attributes && row.attributes[key];
  }

  isDateFilter(filterType: string): boolean {
    return filterType.toUpperCase() === BaseDataType.date;
  }

  isDateTimeFilter(filterType: string): boolean {
    return filterType.toUpperCase() === BaseDataType.dateTime;
  }

  isBooleanFilter(filterType: string): boolean {
    return filterType.toUpperCase() === BaseDataType.boolean;
  }

  getFilterKey(column: TableColumn): string {
    return column.filterType ? 'attributes.' + column.key + '.value_' + column.filterType : 'attributes.' + column.key + '.value';
  }

  async sleep(timeout = 0): Promise<void> {
    return new Promise(resolve => {
      setTimeout(resolve, timeout);
    });
  }

  async onArtifactTypeChange(event: any): Promise<void> {
    this.setSelectedArtifactType(null);
    this.loading = true;
    await this.sleep();
    this.setSelectedArtifactType(event.value);
    await this.sleep();
    this.initDateFilters();
  }

  setSelectedArtifactType(artifactType: NewArtifactType | null): void {
    this.targetArtifact = null;
    if (this.relevantRestrictions?.length && artifactType) {
      this.restriction = this.relevantRestrictions.find(restriction => {
        const restrictionArtifactTypesIds =
          this.linkType.meta === LinkDirection.outgoing ? restriction.destinationArtifactTypeIds : restriction.sourceArtifactTypeIds;
        return restrictionArtifactTypesIds.find(id => id === artifactType?.id);
      });
      this.disableSaveDueToSingleRestrictions = false;
    }
    this.selectedArtifactType = artifactType;
  }

  async create(): Promise<void> {
    try {
      const linkTypeId = this.linkType.key;

      console.log('this.originArtifact => ', this.originArtifact);
      console.log('this.targetArtifact => ', this.targetArtifact);

      if (!this.originArtifact?.id || (!this.targetArtifact?.id && !this.isMultipleSelectionMode)) {
        if (this.isMultipleSelectionMode) {
          for (const artifact of this.targetArtifact as NewArtifact[]) {
            const newLinkBoilerplate = this.createNewLinkBoilerplate(artifact);
            this.addNewLinkToMap(newLinkBoilerplate);
          }
        } else {
          const newLinkBoilerplate = this.createNewLinkBoilerplate(this.targetArtifact);
          this.addNewLinkToMap(newLinkBoilerplate);
        }
        this.onLinkAdd.emit();
        return;
      }

      if (this.isMultipleSelectionMode) {
        for await (const artifact of this.targetArtifact) {
          const destinationArtifactId = LinkMethods.isOutgoing(this.linkType.meta) ? artifact.id : this.originArtifact?.id;
          const sourceArtifactId = LinkMethods.isOutgoing(this.linkType.meta) ? this.originArtifact?.id : artifact.id;
          await this.createLink(linkTypeId, destinationArtifactId, sourceArtifactId);
          this.onSingleLinkAdded.emit(artifact);
        }
      } else {
        const destinationArtifactId = LinkMethods.isOutgoing(this.linkType.meta) ? this.targetArtifact.id : this.originArtifact?.id;
        const sourceArtifactId = LinkMethods.isOutgoing(this.linkType.meta) ? this.originArtifact?.id : this.targetArtifact.id;
        await this.createLink(linkTypeId, destinationArtifactId, sourceArtifactId);
      }

      this.successCb && (await this.successCb());
    } catch (e) {
      console.error(e);
    } finally {
      this.close();
    }
  }

  createNewLinkBoilerplate(artifact: NewArtifact): NewLinkBoilerplate {
    const destinationArtifactId = LinkMethods.isOutgoing(this.linkType.meta) ? artifact.id : this.originArtifact?.id;
    const destinationArtifactTypeId = LinkMethods.isOutgoing(this.linkType.meta) ? artifact.artifactTypeId : this.originArtifact?.artifactTypeId;
    const sourceArtifactId = LinkMethods.isOutgoing(this.linkType.meta) ? this.originArtifact?.id : artifact.id;
    const sourceArtifactTypeId = LinkMethods.isOutgoing(this.linkType.meta) ? this.originArtifact?.artifactTypeId : artifact.artifactTypeId;
    const newLinkBoilerplate = new NewLinkBoilerplate();
    newLinkBoilerplate.direction = this.linkType.meta;
    newLinkBoilerplate.linkTypeId = this.linkType.key;
    newLinkBoilerplate.destinationId = destinationArtifactId;
    newLinkBoilerplate.destinationTypeId = destinationArtifactTypeId;
    newLinkBoilerplate.sourceId = sourceArtifactId;
    newLinkBoilerplate.sourceTypeId = sourceArtifactTypeId;

    if (newLinkBoilerplate.destinationTypeId) {
      const { primaryAttributes, delimiter } = this.artifactTypes.listMap[newLinkBoilerplate.destinationTypeId];
      newLinkBoilerplate.linkName =
        primaryAttributes
          .map(item => {
            if (artifact?.attributes[item] && this.isEnumerated(artifact?.attributes[item], this.dataTypes.listMap, this.attributes.listMap)) {
              const dataType = GetDataTypeFromClientAttribute(artifact?.attributes[item], this.attributes.listMap, this.dataTypes.listMap);
              const enumValue = dataType?.values?.filter(enumVal => enumVal.value === artifact?.attributes[item]?.value)[0];
              return enumValue?.label;
            } else if (item === Constants.primaryAttributesDefaultId) {
              return artifact?.id;
            } else if (item === Constants.primaryAttributesArtifactTypeId) {
              return (artifact?.artifactTypeId && this.artifactTypes.listMap[artifact?.artifactTypeId].name) || '';
            } else {
              return artifact?.attributes[item]?.value;
            }
          })
          .join(delimiter ? `${delimiter} ` : ' ') || '';
    } else {
      let linkName = '';
      const primaryAttributes = this.artifactTypeOptions.find(option => option.value.id === artifact.artifactTypeId)?.value.primaryAttributes;
      if (!primaryAttributes) {
        linkName = artifact.id;
      } else {
        if (primaryAttributes.find(attribute => attribute === Constants.primaryAttributesDefaultId)) {
          linkName = artifact.id;
        }
        this.attributeIds.map(id => {
          if (artifact.attributes[id] && primaryAttributes.find(attribute => attribute === artifact.attributes[id].id)) {
            const divider = linkName.length > 0 ? ', ' : '';
            linkName = linkName.concat(divider, artifact.attributes[id].value);
          }
        });
      }
      newLinkBoilerplate.linkName = linkName;
    }
    return newLinkBoilerplate;
  }

  isEnumerated(clientAttribute: NewClientAttribute, dataTypesMap: Record<string, NewDataType>, attributesMap: Record<string, NewAttribute>): boolean {
    if (!clientAttribute) {
      return false;
    }
    const dataType = GetDataTypeFromClientAttribute(clientAttribute, attributesMap, dataTypesMap);
    return dataType?.kind === DataTypeKind.enumerated;
  }

  addNewLinkToMap(newLinkBoilerplate: NewLinkBoilerplate): void {
    if (newLinkBoilerplate.direction) {
      if (!this.newLinksMap[newLinkBoilerplate.linkTypeId]) {
        this.newLinksMap[newLinkBoilerplate.linkTypeId] = {} as Record<LinkDirection, NewLinkBoilerplate[]>;
        this.newLinksMap[newLinkBoilerplate.linkTypeId][newLinkBoilerplate.direction] = [];
      } else if (!this.newLinksMap[newLinkBoilerplate.linkTypeId][newLinkBoilerplate.direction]) {
        this.newLinksMap[newLinkBoilerplate.linkTypeId][newLinkBoilerplate.direction] = [];
      }
      this.newLinksMap[newLinkBoilerplate.linkTypeId][newLinkBoilerplate.direction].push(newLinkBoilerplate);
    }
  }

  async createLink(linkTypeId: string, destinationArtifactId: string, sourceArtifactId: string): Promise<void> {
    const body: LinkRequestDto = { linkTypeId, destinationArtifactId, sourceArtifactId };

    const newLinkRes = await lastValueFrom(this.tenantLinkService.linkControllerCreate({ body: { links: [body] }, notify: false }));
    if (newLinkRes.meta.errors) throw new Error(newLinkRes.meta.errors[0].errorMessage);
    const newLinkDto = newLinkRes.data[0];

    if (newLinkDto && this.isTable) {
      const affectedRows = this.parentData.filter(artifact => [destinationArtifactId, sourceArtifactId].includes(artifact.id));
      affectedRows.forEach(artifact => {
        const newLink = this.artifactLinkService.dtoToLink(newLinkDto);
        if (newLink.destination) {
          newLink.destination && (newLink.destination.artifact = LinkMethods.isOutgoing(this.linkType.meta) ? this.targetArtifact : this.originArtifact);
          newLink.destination.artifactType = LinkMethods.isOutgoing(this.linkType.meta) ? this.selectedArtifactType : this.originArtifactTypes[0].value;
        }
        if (newLink.source) {
          newLink.source.artifact = LinkMethods.isIncoming(this.linkType.meta) ? this.targetArtifact : this.originArtifact;
          newLink.source.artifactType = LinkMethods.isIncoming(this.linkType.meta) ? this.selectedArtifactType : this.originArtifactTypes[0].value;
        }
        this.artifactLinksMap[artifact.id].push(newLink);
      });
      this.linkTypeIds && this.linkTypeIds.add(newLinkDto.linkTypeId);
    }

    if (newLinkDto) {
      this.runtimeStateNotificationService.events$.next(
        new RuntimeStateNotification<string[]>(RuntimeStateNotificationEnum.createLink, [destinationArtifactId, sourceArtifactId], this.hash, linkTypeId),
      );
    }
  }

  private addExcludeLinkedArtifactFilter(filters: Record<string, FilterMetadata[]>): void {
    let artifactIdsToExclude: (string | undefined | null)[] | undefined;
    const artifactTypeIdsToExclude: Set<string> = new Set<string>();
    if (this.artifactRowRelevantLinks) {
      artifactIdsToExclude = this.artifactRowRelevantLinks.map(link => {
        const artifactTypeId = LinkMethods.isOutgoing(this.linkType.meta) ? link.target?.artifactTypeId : link.source?.artifact?.artifactTypeId;
        if (artifactTypeId && !artifactTypeIdsToExclude.has(artifactTypeId)) {
          artifactTypeIdsToExclude.add(artifactTypeId);
        }
        return LinkMethods.isOutgoing(this.linkType.meta) ? link.target?.id : link.source?.artifact?.id;
      });
    } else if (this.artifactRowRelevantNewLinks) {
      artifactIdsToExclude = [];
      this.artifactRowRelevantNewLinks.forEach(link => {
        const artifactId = LinkMethods.isOutgoing(this.linkType.meta) ? link.destinationArtifactId : link.sourceArtifactId;
        if (this.linkedArtifactsMap[artifactId]) {
          const artifactTypeId = this.linkedArtifactsMap[artifactId].artifactTypeId;
          if (artifactTypeId && !artifactTypeIdsToExclude.has(artifactTypeId)) {
            artifactIdsToExclude?.push(artifactId);
            artifactTypeIdsToExclude.add(artifactTypeId);
          }
        }
      });
    }
    // exclude linkBoilerplate's
    if (this.newLinksMap && this.newLinksMap[this.linkType.key] && this.newLinksMap[this.linkType.key][this.linkType.meta as LinkDirection]) {
      const moreArtifactIdsToExclude = this.newLinksMap[this.linkType.key][this.linkType.meta as LinkDirection].map(link => {
        const artifactTypeId = LinkMethods.isOutgoing(this.linkType.meta) ? link.destinationTypeId : link.sourceTypeId;
        if (artifactTypeId && !artifactTypeIdsToExclude.has(artifactTypeId)) {
          artifactTypeIdsToExclude.add(artifactTypeId);
        }
        return LinkMethods.isOutgoing(this.linkType.meta) ? link.destinationId : link.sourceId;
      });
      if (moreArtifactIdsToExclude?.length) {
        artifactIdsToExclude = artifactIdsToExclude ? artifactIdsToExclude.concat(moreArtifactIdsToExclude) : moreArtifactIdsToExclude;
      }
    }
    // exclude artifacts already linked from another artifacts of selected type
    if (this.artifactIdsToCheck?.size) {
      artifactIdsToExclude = artifactIdsToExclude ? artifactIdsToExclude.concat([...this.artifactIdsToCheck]) : [...this.artifactIdsToCheck];
    }
    if (artifactIdsToExclude) {
      const selectedArtifactTypeId = this.selectedArtifactType?.id;
      if (!this.isMultipleSelectionMode && selectedArtifactTypeId && artifactTypeIdsToExclude.has(selectedArtifactTypeId)) {
        this.disableSaveDueToSingleRestrictions = true;
      }
      filters._id = [{ value: artifactIdsToExclude, matchMode: 'notEquals', operator: 'and' }];
    }
  }

  private initColumns(): void {
    this.attributeColumns = this.attributeIds.map(attributeId => {
      const { id, name, dataTypeId } = this.attributes.listMap[attributeId];
      const dataType = this.dataTypes.listMap[dataTypeId];

      const baseDataType = dataType?.baseDataType;

      if (baseDataType === BaseDataType.user) this.userAttributesMap[id] = true;
      if (baseDataType && dataType?.kind === DataTypeKind.simple && [BaseDataType.integer, BaseDataType.decimal, BaseDataType.text].includes(baseDataType)) {
        const filterType = baseDataType === BaseDataType.text ? 'text' : 'numeric';
        return new TableColumn(name, id, filterType);
      }
      if (baseDataType && this.columnFilterTypes[baseDataType]) return new TableColumn(name, id, this.columnFilterTypes[baseDataType]);
      return new TableColumn(name, id);
    });
    this.editableColumnKeys = [...this.attributeColumns.map(col => col.key)];
    this.sortableColumnKeys = [CREATED_ON_KEY, UPDATED_ON_KEY];

    this.columns = [new TableColumn(ID_LABEL, ID_KEY), ...this.attributeColumns];
  }
}
