import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { RegularUserResponseDto } from '@api/models';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewApplication } from '@shared/types/application.types';
import { NewArtifactType } from '@shared/types/artifact-type.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { NewUser } from '@shared/types/user.types';
import { RuleAdministrationType } from '@workflows/shared';
import { Subscription } from 'rxjs';
import { RuleDataHolderService, WorkflowTriggerFactory } from '../../../services';
import { Rule, WorkflowOwnerType, WorkflowRule, WorkflowType } from '../../../types';

@Component({
  selector: 'app-workflows-page-admin',
  templateUrl: './workflow-page-administration.component.html',
  styleUrls: ['./workflow-page-administration.component.scss', '../../workflow-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowsPageAdministrationComponent implements OnInit, OnDestroy, OnChanges {
  @Input() pageId: string;
  @Input() selectedRule?: Rule;
  @Input() pageWidgets: BlockPartWidget[];
  @Input() visible: boolean;

  activeIndex = 0;
  canBeAddedRule = true;
  rule: WorkflowRule;
  rules: WorkflowRule[] = [];
  artifactTypes: ListContainer<NewArtifactType> = new ListContainer();
  attributes: ListContainer<NewAttribute> = new ListContainer();
  dataTypes: ListContainer<NewDataType> = new ListContainer();
  linkTypes: ListContainer<LinkType> = new ListContainer();
  applications: ListContainer<NewApplication> = new ListContainer();
  users: ListContainer<NewUser> = new ListContainer();
  pageRules: Rule[];
  globalRules: Rule[];

  protected readonly RuleAdministrationType = RuleAdministrationType;

  private ownerId: string;
  private subscriptions: Subscription[];

  constructor(
    private readonly cache: NewCacheService,
    private triggerFactory: WorkflowTriggerFactory,
    private ruleDataHolder: RuleDataHolderService,
    private announcement: AnnouncementService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    const data = this.cache.data;
    this.subscriptions = [
      data.artifactTypes.subscribe(
        artifactTypes =>
          artifactTypes &&
          this.artifactTypes.setList(
            (artifactTypes as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
            'id',
          ),
      ),
      data.attributes.subscribe(
        attributes =>
          attributes &&
          this.attributes.setList(
            (attributes as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
            'id',
          ),
      ),
      data.dataTypes.subscribe(
        dataTypes =>
          dataTypes &&
          this.dataTypes.setList(
            (dataTypes as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
            'id',
          ),
      ),
      data.linkTypes.subscribe(
        linkTypes =>
          linkTypes &&
          this.linkTypes.setList(
            (linkTypes as LinkTypeResponseDto[]).map(dto => new LinkType(dto)),
            'id',
          ),
      ),
      data.applications.subscribe(applications => applications && this.applications.setList(applications as ApplicationResponseDto[], 'id')),
      data.users.subscribe(users => users && this.users.setList(users as RegularUserResponseDto[], 'id')),
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visible) {
      if (this.visible) {
        this.activeIndex = 0;
        this.ownerId = this.pageId;
        this.pageRules = this.ruleDataHolder.getRulesForPage(this.pageId) || [];
        this.globalRules = this.ruleDataHolder.getAvailableGlobalRulesForPage(this.pageId);
        if (!this.selectedRule) {
          this.addNewWorkflowRule();
        }
      } else {
        this.selectedRule = undefined;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription && subscription.unsubscribe());
  }

  addNewWorkflowRule() {
    this.canBeAddedRule = false;
    this.rule = new WorkflowRule();
    this.rule.ownerId = this.pageId;
    this.rule.usedIn = [this.pageId];
    this.rule.triggers = [this.triggerFactory.getBaseWorkflowTrigger()];
    this.rule.type = WorkflowType.fe;
    this.rule.ownerType = WorkflowOwnerType.PAGE;
    this.selectedRule = this.rule;
  }

  doSaveRule(rule: WorkflowRule) {
    const isNew = rule === this.rule;
    if (isNew) {
      this.ruleDataHolder.saveRule$(rule).subscribe(serverRule => {
        this.selectedRule = serverRule;
        this.reloadPageRules();
        this.announcement.success('Workflow rule successfully saved...');
      });
      return;
    }
    this.ruleDataHolder.updateRule$(rule).subscribe(serverRule => {
      this.selectedRule = serverRule;
      this.reloadPageRules();
      this.announcement.success('Workflow rule successfully updated...');
    });
  }

  doUseRule(rule: WorkflowRule) {
    this.ruleDataHolder.useRule$(rule, this.ownerId).subscribe(serverRule => this.doUpdateOnUseChange(serverRule, true));
  }

  doUnuseRule(rule: WorkflowRule) {
    this.ruleDataHolder.unuseRule$(rule, this.ownerId).subscribe(serverRule => this.doUpdateOnUseChange(serverRule, false));
  }

  doDeleteRule(rule: WorkflowRule) {
    this.ruleDataHolder.deleteRule$(rule).subscribe(() => {
      this.reloadPageRules();
      this.announcement.success('Workflow rule successfully deleted...');
    });
  }

  selectRule(rule: WorkflowRule) {
    this.selectedRule = new WorkflowRule(rule);
  }

  onTabChange(index: number) {
    this.activeIndex = index;
    this.selectedRule = this.isGlobalRulesTabActive() ? this.globalRules[0] : this.rule;
  }

  trackByRuleFn(index: number, item: WorkflowRule): string {
    return item.id;
  }

  private doUpdateOnUseChange(serverRule: WorkflowRule, using: boolean) {
    this.selectedRule = serverRule;
    if (using) {
      this.pageRules = [...this.pageRules, serverRule];
      this.globalRules = this.globalRules.filter(item => item.id !== serverRule.id);
      this.announcement.success('Workflow rule successfully assigned to this page...');
    } else {
      this.pageRules = this.pageRules.filter(item => item.id !== serverRule.id);
      this.globalRules = [...this.globalRules, serverRule];
      this.announcement.success('Workflow rule successfully removed from this page...');
    }
    this.selectedRule = this.activeIndex === 0 ? this.pageRules[0] : this.globalRules[0];
    this.cdr.markForCheck();
  }

  private isGlobalRulesTabActive(): boolean {
    return this.activeIndex === 1;
  }

  private reloadPageRules() {
    this.pageRules = this.ruleDataHolder.getRulesForPage(this.pageId) || [];
    this.cdr.markForCheck();
  }
}
