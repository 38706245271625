import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SelectOption } from '@shared/types/shared.types';
import { ImapFilterComponent } from '@workflows/components/rule/action/backend/fetch-data-imap/imap-filter/imap-filter.component';
import { ImapFilter, ImapFilterKeyEnum } from '@workflows/shared/types/imap-filters.types';
import { FetchDataImapKeys, ImapRequestSchemesEnum, WorkflowActionFetchDataImap } from '@workflows/types/actions/action-fetch-data-imap';
import { ButtonDirective } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PasswordModule } from 'primeng/password';

@Component({
  selector: 'app-action-fetch-data-imap',
  standalone: true,
  imports: [
    ButtonDirective,
    CheckboxModule,
    DropdownModule,
    InputTextModule,
    InputTextareaModule,
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    PasswordModule,
    ImapFilterComponent,
  ],
  templateUrl: './action-fetch-data-imap.component.html',
  styleUrl: './action-fetch-data-imap.component.scss',
})
export class ActionFetchDataImapComponent {
  @Input() action: WorkflowActionFetchDataImap;

  RuleKeys = FetchDataImapKeys;
  requestSchemes: ImapRequestSchemesEnum[];
  filterKeyOptions: SelectOption<string, ImapFilterKeyEnum>[] = [];

  constructor() {
    this.requestSchemes = Object.values(ImapRequestSchemesEnum);
    this.filterKeyOptions = Object.entries(ImapFilterKeyEnum).map(([filterKey, filterValue]) => new SelectOption(filterKey, filterValue));
  }

  onIsDynamicHostChange() {
    this.action.actionSettings.host.value = '';
  }

  onIsDynamicAuthChange() {
    this.action.actionSettings.auth.value = { session_id: null, plain_user: '', plain_pass: '' };
  }

  onIsDynamicFiltersChange() {
    this.action.imapFilters.forEach(filter => (filter.value = null));
  }

  onAddFilter() {
    this.action.imapFilters.push(new ImapFilter());
  }

  onRemoveFilter(index: number) {
    this.action.imapFilters.splice(index, 1);
  }
}
