import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CurrentTenantService } from '@private/pages/current-tenant/tenant/services/current-tenant.service';
import { CurrentTenantModel } from '@private/pages/current-tenant/tenant/types/current-tenant.types';
import { BreadcrumbService } from '@private/services/app.breadcrumb.service';
import { TENANT_LABEL } from '@shared/constants/constants';
import { CoreComponent } from '@shared/core/components/core.component';
import { AnnouncementService } from '@shared/services/announcement.service';
import { DoSomethingWithConfirmationParams } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-current-tenant',
  templateUrl: './current-tenant.component.html',
  styleUrls: ['./current-tenant.component.scss'],
  providers: [CurrentTenantService],
})
export class CurrentTenantComponent extends CoreComponent<CurrentTenantService, CurrentTenantModel> {
  constructor(
    public service: CurrentTenantService,
    public route: ActivatedRoute,
    public router: Router,
    public readonly translate: TranslateService,
    public readonly announcement: AnnouncementService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly elvisUtil: ElvisUtil,
    private readonly confirmationService: ConfirmationService,
  ) {
    super(route, router, translate, new CurrentTenantModel(), service, announcement);
  }

  async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.breadcrumbService.setItems([{ label: TENANT_LABEL }]);
    await this.s.setCurrentTenant();
  }

  saveTenant(): void {
    this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams('Update', 'Are you sure, you want to update current tenant ?', 'Yes', 'No'),
      this.s.updateCurrentTenant.bind(this.s),
    );
  }
}
