import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { UserIconSizeEnum } from '@shared/types/display-at-types';
import { ArtifactWidgetFormItem } from '@widgets/artifact-widget/types/artifact-widget-form.types';
import { ArtifactWidgetModel, ArtifactWidgetSelectedEntities } from '@widgets/artifact-widget/types/artifact-widget.types';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { IS_PREVIEW_MODE } from '@widgets/widgets-core/constants/widgets-core.constants';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-artifact-widget-custom-field',
  templateUrl: './artifact-widget-custom-field.component.html',
  styleUrls: ['./artifact-widget-custom-field.component.scss'],
})
export class ArtifactWidgetCustomFieldComponent {
  @Input() item: ArtifactWidgetFormItem;
  @Input() ownerId: string;
  @Input() labelBehaviour: LabelBehaviourEnum;
  @Input() artifactId: string;
  @Input() dataType: NewClientAttribute;
  @Input() selected: ArtifactWidgetSelectedEntities;
  @Input() model: ArtifactWidgetModel;

  @Input() set formatSettings(settings: AttributeFormatSettings) {
    this._formatSettings = settings;
    this.visible = this.isPreviewMode ? !settings.hideOnPageLoad : true;
  }

  @Output() openFolderPicker = new EventEmitter<FolderWidgetSettings | null>();
  labelBehaviourEnum = LabelBehaviourEnum;
  userIconSize: UserIconSizeEnum = UserIconSizeEnum.MEDIUM;
  visible = true;
  private _formatSettings: AttributeFormatSettings;

  constructor(@Inject(IS_PREVIEW_MODE) private readonly isPreviewMode: boolean) {}

  get formatSettings(): AttributeFormatSettings {
    return this._formatSettings;
  }

  showPopupFolder(): void {
    if (!this.model.artifactId || !this.model.settings.initiateEditWithEditButton || this.model.isEditInProgress) {
      this.item.value.label === NonAttributeKeys.FOLDER_PATH_LABEL && this.canBeEdit() && this.openFolderPicker.emit(this.item.folderPickerSettings);
    }
  }

  get hasCustomInnerHtml(): boolean {
    return Boolean(this.artifactId || this.isFolder || this.model.newArtifactFile !== null);
  }

  get isArtifactFile(): boolean {
    return this.dataType?.id === NonAttributeKeys.FILE_ARTIFACT_PATH_ID;
  }

  get isFolder(): boolean {
    return this.dataType?.id === NonAttributeKeys.FOLDER_PATH_ID;
  }

  get isSystemDate(): boolean {
    return NonAttributeKeys.isDateSpecificAttributeId(this.dataType?.id);
  }

  private canBeEdit(): boolean {
    const id = this.item.attribute?.value.id || '';
    return this.model.formatsMap.attribute[id]?.editable;
  }

  onSelectFile($event: { files: File[] }, fileUploadComponent: FileUpload): void {
    if ($event.files.length === 0) return;
    const file = $event.files[0];
    this.model.newArtifactFile = file as Blob;
    fileUploadComponent.clear();
  }

  onRemoveFile(): void {
    this.model.newArtifactFile = null;
  }
}
