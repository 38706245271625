/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { sessionFlowControllerCreate } from '../fn/tenant-session-flow/session-flow-controller-create';
import { SessionFlowControllerCreate$Params } from '../fn/tenant-session-flow/session-flow-controller-create';
import { sessionFlowControllerDelete } from '../fn/tenant-session-flow/session-flow-controller-delete';
import { SessionFlowControllerDelete$Params } from '../fn/tenant-session-flow/session-flow-controller-delete';
import { sessionFlowControllerGet } from '../fn/tenant-session-flow/session-flow-controller-get';
import { SessionFlowControllerGet$Params } from '../fn/tenant-session-flow/session-flow-controller-get';
import { sessionFlowControllerList } from '../fn/tenant-session-flow/session-flow-controller-list';
import { SessionFlowControllerList$Params } from '../fn/tenant-session-flow/session-flow-controller-list';
import { sessionFlowControllerUpdate } from '../fn/tenant-session-flow/session-flow-controller-update';
import { SessionFlowControllerUpdate$Params } from '../fn/tenant-session-flow/session-flow-controller-update';
import { SessionFlowListResponseDto } from '../models/session-flow-list-response-dto';
import { TenantAdminSessionFlowResponseDto } from '../models/tenant-admin-session-flow-response-dto';

@Injectable({ providedIn: 'root' })
export class TenantSessionFlowService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sessionFlowControllerGet()` */
  static readonly SessionFlowControllerGetPath = '/api/tenant/session-flow/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionFlowControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionFlowControllerGet$Response(params: SessionFlowControllerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return sessionFlowControllerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionFlowControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionFlowControllerGet(params: SessionFlowControllerGet$Params, context?: HttpContext): Observable<{
}> {
    return this.sessionFlowControllerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `sessionFlowControllerDelete()` */
  static readonly SessionFlowControllerDeletePath = '/api/tenant/session-flow/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionFlowControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionFlowControllerDelete$Response(params: SessionFlowControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantAdminSessionFlowResponseDto>> {
    return sessionFlowControllerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionFlowControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionFlowControllerDelete(params: SessionFlowControllerDelete$Params, context?: HttpContext): Observable<TenantAdminSessionFlowResponseDto> {
    return this.sessionFlowControllerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantAdminSessionFlowResponseDto>): TenantAdminSessionFlowResponseDto => r.body)
    );
  }

  /** Path part for operation `sessionFlowControllerList()` */
  static readonly SessionFlowControllerListPath = '/api/tenant/session-flow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionFlowControllerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionFlowControllerList$Response(params?: SessionFlowControllerList$Params, context?: HttpContext): Observable<StrictHttpResponse<SessionFlowListResponseDto>> {
    return sessionFlowControllerList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionFlowControllerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sessionFlowControllerList(params?: SessionFlowControllerList$Params, context?: HttpContext): Observable<SessionFlowListResponseDto> {
    return this.sessionFlowControllerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<SessionFlowListResponseDto>): SessionFlowListResponseDto => r.body)
    );
  }

  /** Path part for operation `sessionFlowControllerUpdate()` */
  static readonly SessionFlowControllerUpdatePath = '/api/tenant/session-flow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionFlowControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sessionFlowControllerUpdate$Response(params: SessionFlowControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantAdminSessionFlowResponseDto>> {
    return sessionFlowControllerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionFlowControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sessionFlowControllerUpdate(params: SessionFlowControllerUpdate$Params, context?: HttpContext): Observable<TenantAdminSessionFlowResponseDto> {
    return this.sessionFlowControllerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantAdminSessionFlowResponseDto>): TenantAdminSessionFlowResponseDto => r.body)
    );
  }

  /** Path part for operation `sessionFlowControllerCreate()` */
  static readonly SessionFlowControllerCreatePath = '/api/tenant/session-flow';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sessionFlowControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sessionFlowControllerCreate$Response(params: SessionFlowControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TenantAdminSessionFlowResponseDto>> {
    return sessionFlowControllerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sessionFlowControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sessionFlowControllerCreate(params: SessionFlowControllerCreate$Params, context?: HttpContext): Observable<TenantAdminSessionFlowResponseDto> {
    return this.sessionFlowControllerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TenantAdminSessionFlowResponseDto>): TenantAdminSessionFlowResponseDto => r.body)
    );
  }

}
