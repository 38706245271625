<ng-container *ngIf="attribute.multipleValues && !isCheckbox && !isRadiobutton">
  <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
  <p-multiSelect
    [(ngModel)]="attr.value"
    [filter]="true"
    [inputId]="'enumFieldM' + index"
    [maxSelectedLabels]="5"
    [options]="options || dataType.values"
    [placeholder]="placeholder"
    appendTo="body"
    optionLabel="label"
    (onChange)="valueChange.emit($event.value?.value || $event.value)"
    (onPanelHide)="fieldBlur.emit()"
  >
    <ng-template pTemplate="selectedItems">
      <div *ngIf="attr.value?.length; else multiSelectPlaceholder">
        <span *ngFor="let option of attr.value" class="option-with-icon">
          <span [class]="option.icon"></span>
          <span [ngClass]="{ 'full-indent': !option.icon, 'half-indent': option.icon }">{{ option.label }}</span>
        </span>
      </div>
      <ng-template #multiSelectPlaceholder>
        <div *ngIf="placeholder; else defaultPlaceholder">{{ placeholder }}</div>
        <ng-template #defaultPlaceholder> {{ attribute.name }}</ng-template>
      </ng-template>
    </ng-template>
    <ng-template let-option pTemplate="item">
      <span [class]="option.icon"></span>
      <span [ngClass]="{ 'full-indent': !option.icon, 'half-indent': option.icon }">{{ option.label }}</span>
    </ng-template>
  </p-multiSelect>
  <app-artifact-attribute-form-field-label-with-icon
    [attribute]="attribute"
    [formatSettings]="formatSettings"
    [label]="label"
    [fieldHtmlId]="'enumFieldM' + index"
    [canContainPlaceholder]="true"
    [labelBehaviour]="labelBehaviour"
    [placeholder]="placeholder"
  >
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>

<ng-container *ngIf="!attribute.multipleValues && !isCheckbox && !isRadiobutton">
  <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
  <p-dropdown
    [(ngModel)]="attr.value"
    [autoDisplayFirst]="false"
    [filter]="true"
    [inputId]="'enumField' + index"
    [options]="options || dataType.values"
    [placeholder]="placeholder"
    [showClear]="formatSettings.value.displayMetadata?.showClear"
    appendTo="body"
    optionLabel="label"
    (onHide)="fieldBlur.emit()"
    (onChange)="valueChange.emit($event.value?.value)"
  >
    <ng-template let-option pTemplate="item">
      <span [class]="option.icon"></span>
      {{ option.label }}
    </ng-template>
    <ng-template let-option pTemplate="selectedItem">
      <span [class]="option.icon"></span>
      {{ option.label }}
    </ng-template>
  </p-dropdown>
  <app-artifact-attribute-form-field-label-with-icon
    [attribute]="attribute"
    [formatSettings]="formatSettings"
    [label]="label"
    [fieldHtmlId]="'enumField' + index"
    [canContainPlaceholder]="true"
    [labelBehaviour]="labelBehaviour"
    [placeholder]="placeholder"
  >
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>

<ng-container *ngIf="isCheckbox">
  <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
  <div class="dynamic-checkbox-box grid m-0" [ngStyle]="alignStyle">
    <div class="flex flex-wrap" [ngClass]="directionClass">
      <div *ngFor="let option of options || dataType.values" class="field-checkbox">
        <p-checkbox
                [(ngModel)]="attr.value"
                [inputId]="uuid + option.label"
                (onChange)="valueChange.emit($event.value?.value || $event.value)"
                name="group"
                [value]="option"></p-checkbox>
        <label [for]="uuid + option.label" class="ml-2 mt-1 mr-2 label-radio" translate>{{ option.label }}</label>
      </div>
    </div>
  </div>

  <app-artifact-attribute-form-field-label-with-icon
          [attribute]="attribute"
          [formatSettings]="formatSettings"
          [label]="label"
          [fieldHtmlId]="'enumFieldM' + index"
          [canContainPlaceholder]="true"
          [labelBehaviour]="labelBehaviour"
          [placeholder]="placeholder"
  >
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>

<ng-container *ngIf="isRadiobutton">
  <span *ngIf="attr.isMandatory" class="form-mandatory-field"></span>
  <div class="dynamic-checkbox-box grid m-0" [ngStyle]="alignStyle">
    <div class="flex flex-wrap" [ngClass]="directionClass">
      <div *ngFor="let option of options" class="field-checkbox">
        <p-radioButton
          [inputId]="uuid + option.label"
          (onClick)="valueChange.emit($event.value)"
          name="option"
          [value]="option"
          [(ngModel)]="attr.value"></p-radioButton>
        <label [for]="uuid + option.label" class="ml-2 mt-1 mr-2 label-radio" translate>{{ option.label }}</label>
      </div>
      <div *ngIf="formatSettings.value.displayMetadata?.showClear" class="field-checkbox">
        <p-radioButton
          [inputId]="uuid + emptyLabel"
          (onClick)="valueChange.emit($event.value)"
          name="option"
          [(ngModel)]="attr.value"></p-radioButton>
        <label [for]="uuid + emptyLabel" class="ml-2 mt-1 mr-2 label-radio" translate>{{ emptyLabel }}</label>
      </div>
    </div>
  </div>

  <app-artifact-attribute-form-field-label-with-icon
          [attribute]="attribute"
          [formatSettings]="formatSettings"
          [label]="label"
          [fieldHtmlId]="'enumFieldM' + index"
          [canContainPlaceholder]="true"
          [labelBehaviour]="labelBehaviour"
          [placeholder]="placeholder"
  >
  </app-artifact-attribute-form-field-label-with-icon>
</ng-container>
