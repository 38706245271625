<span class="dynamic-checkbox-box grid m-0" [ngStyle]="alignStyle">
  <span *ngIf="attr?.isMandatory" class="form-mandatory-field"></span>

  <p-triStateCheckbox
    *ngIf="isTriState || isDefault"
    [(ngModel)]="attr.value"
    [inputId]="uuid + 'booleanField' + index"
    (onChange)="onChange?.()">
  </p-triStateCheckbox>

  <p-checkbox
    *ngIf="is2State"
    binary="true"
    [(ngModel)]="attr.value"
    (onChange)="onChange?.()">
  ></p-checkbox>

  <ng-container *ngIf="isRadiobutton">
    <div class="flex flex-wrap" [ngClass]="directionClass">
      <div class="flex flex-row mb-3 mr-2">
        <p-radioButton
          [inputId]="uuid + 'true'"
          (onClick)="onChange?.()"
          name="option"
          [value]="true"
          [(ngModel)]="attr.value"></p-radioButton>
        <label [for]="uuid + 'true'" class="ml-2 mt-1 label-boolean" translate>true</label>
      </div>

      <div class="flex flex-row mb-3 mr-2">
        <p-radioButton
          [inputId]="uuid + 'false'"
          (onClick)="onChange?.()"
          name="option"
          [value]="false"
          [(ngModel)]="attr.value"></p-radioButton>
        <label [for]="uuid + 'false'" class="ml-2 mt-1 label-boolean" translate>false</label>
      </div>

      <div class="flex flex-row mb-3 mr-2">
        <p-radioButton
          [inputId]="uuid + emptyLabel"
          (onClick)="onChange?.()"
          name="option"
          [value]="null"
          [(ngModel)]="attr.value"></p-radioButton>
        <label [for]="uuid + emptyLabel" class="ml-2 mt-1 label-boolean" translate>{{ emptyLabel }}</label>
      </div>
  </div>


  </ng-container>

  <app-artifact-attribute-form-field-label-with-icon
    [attribute]="attribute"
    [formatSettings]="formatSettings"
    [label]="label"
    [fieldHtmlId]="'booleanField' + index"
    [canContainPlaceholder]="false">
  </app-artifact-attribute-form-field-label-with-icon>
</span>
