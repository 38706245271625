import { Injectable } from '@angular/core';
import { SystemAdminUserResponseDto } from '@api/models';
import { TenantUserService } from '@api/services/tenant-user.service';
import { TranslateService } from '@ngx-translate/core';
import { UiConfig } from '@private/pages/profile/types/user.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { LanguageSwitcherService } from '@shared/components/language-switcher/services/language-switcher.service';
import { LanguageEnum } from '@shared/components/language-switcher/types/language-switcher.types';
import { ClientData, StateKey } from '@shared/types/local-storage.types';
import { lastValueFrom } from 'rxjs';
import { Constants } from '../../constants/constants';
import { NewSystemUser } from '../../types/user.types';
import { AnnouncementService } from '../announcement.service';
import { LocalStorageService } from '../local-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationHelperService {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly announcement: AnnouncementService,
    private readonly tenantUserService: TenantUserService,
    private readonly languageSwitcherService: LanguageSwitcherService,
    private readonly translate: TranslateService,
    private readonly cache: NewCacheService,
  ) {}

  async setToken(token: string, state: StateKey): Promise<boolean> {
    if (!token) {
      await this.announcement.error('Token not received');
      return false;
    } else {
      this.localStorageService.setToState(state, Constants.token, token);
      return true;
    }
  }

  async setUser(user: SystemAdminUserResponseDto, state: StateKey): Promise<boolean> {
    if (!user) {
      await this.announcement.error('User not received');
      this.localStorageService.removeFromState(state, Constants.token);
      return false;
    } else {
      this.localStorageService.setToState(state, Constants.user, new NewSystemUser(user));
      return true;
    }
  }

  saveClientData(clientData: ClientData): void {
    if (!clientData || !clientData[Constants.language as keyof ClientData] || !clientData[Constants.uiConfig as keyof ClientData]) {
      const sessionState = this.localStorageService.get(StateKey.session);

      lastValueFrom(this.tenantUserService.userControllerUpdateClientData({ body: { ...sessionState[Constants.clientData] } })).catch(() =>
        this.announcement.error('Client data could not be saved'),
      );
    }
  }

  setClientDataToSessionState(clientData: ClientData): void {
    if (clientData) {
      this.localStorageService.setToState(
        StateKey.session,
        Constants.clientData,
        new ClientData(
          (clientData[Constants.language as keyof ClientData] as LanguageEnum) || LanguageEnum.EN,
          new UiConfig(clientData[Constants.uiConfig as keyof ClientData] as UiConfig),
        ),
      );
    }
  }

  initTranslation(): void {
    const sessionState = this.localStorageService.get(StateKey.session);
    this.languageSwitcherService.selectedLanguage = sessionState[Constants.clientData][Constants.language];
    this.translate.use(sessionState[Constants.clientData][Constants.language]);
  }
}
