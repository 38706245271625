import { SystemAdminUserTenantApplicationResponseDto } from '@api/models';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';

export class PageModel {
  page: Page = new Page();
  loading = false;
  pageId?: string;
  user: SelfUserResponseDto | null = null;
  tenantApplication?: SystemAdminUserTenantApplicationResponseDto;
  canUserAccessEdit = false;

  constructor(model?: Partial<PageModel>) {
    model && Object.assign(this, model);
  }
}
