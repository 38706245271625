import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MultiSelectModule } from 'primeng/multiselect';

import { BaseSessionFlowFormComponent } from '@private/pages/current-tenant/session-flow/components/session-flow-form/components/base.session-flow-form.component';
import { SessionType } from '@shared/types/session-flow.types';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    // PRIMENG
    MultiSelectModule,
  ],
  selector: 'app-internal-session-flow-form',
  templateUrl: './internal.session-flow-form.component.html',
  styleUrls: ['./internal.session-flow-form.component.scss'],
})
export class InternalSessionFlowFormComponent extends BaseSessionFlowFormComponent<SessionType.internal> {}
