import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RegularUserResponseDto } from '@api/models';
import { EMAIL_KEY, ID_KEY } from '@shared/constants/constants';
import { SharedMethods, WithEmptySelectOption } from '@shared/methods/shared.methods';
import { NewAttribute } from '@shared/types/attribute.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss'],
})
export class UserFilterComponent {
  @Input() m: ArtifactFilter;
  @Input() multipleValues: boolean;
  @Output() onFilterChange: EventEmitter<void> = new EventEmitter<void>();
  options: SelectOption<string, string>[] = [];

  @Input() set users(users: ListContainer<RegularUserResponseDto>) {
    if (!users) {
      return;
    }

    const allUsersWithCurrentOne = SharedMethods.withCurrentUserOption(users.toSelectOptions(EMAIL_KEY, ID_KEY));

    this.options = this.m.isRecordAuthorFilter
      ? allUsersWithCurrentOne
      : WithEmptySelectOption(allUsersWithCurrentOne, (this.m?.attribute as NewAttribute)?.multipleValues);

    this.options.map(option => {
      delete option.meta;
      return option;
    });
  }

  onChange(): void {
    this.onFilterChange.emit();
  }
}
