import { BaseAvrInputMapperDto } from '@shared/services/artifact-visual-representation/base.avr.input-mapper.dto';
import { AvrInputMappersType, AvrTypesType } from '@shared/services/artifact-visual-representation/base.avr.service';
import { DocumentGeneationAvrTemplates } from '@shared/services/artifact-visual-representation/document-generation/dto/document-geneation-avr-templates.dto';

export type DocumentGenerationAvrType = Extract<AvrTypesType, 'document-generation'>;

export enum DocumentGenerationOutputType {
  pdf = 'PDF',
  docx = 'DOCX',
  pptx = 'PPTX',
}
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const outputTypeCheck: [AvrInputMappersType[DocumentGenerationAvrType]['outputType']] extends [`${DocumentGenerationOutputType}`]
  ? `${DocumentGenerationOutputType}` extends AvrInputMappersType[DocumentGenerationAvrType]['outputType']
    ? true
    : false
  : false = true;

export enum DocumentGenerationOutputName {
  id = 'ID',
  nameAttribute = 'NAME_ATTRIBUTE',
}
// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const outputNameCheck: [AvrInputMappersType[DocumentGenerationAvrType]['outputName']] extends [`${DocumentGenerationOutputName}`]
  ? `${DocumentGenerationOutputName}` extends AvrInputMappersType[DocumentGenerationAvrType]['outputName']
    ? true
    : false
  : false = true;

export class DocumentGenerationAvrInputMapperDto extends BaseAvrInputMapperDto<DocumentGenerationAvrType> {
  constructor() {
    super(
      {},
      {
        ['fileArtifactTypeId']: {
          isOptional: false,
          value: '',
          readableName: 'File Artifact type',
          description: 'Artifact type of format FILE, templates will be uploaded as',
        },
        ['everythingWithinArtifactType']: {
          isOptional: false,
          value: true,
          readableName: 'All files within selected Artifact type',
          description: 'To use all files, that fit "outputType" criterium within selected Artifact type',
        },
        ['outputType']: {
          isOptional: false,
          value: DocumentGenerationOutputType.docx,
          readableName: 'Output type',
          description: 'Select from supported output types',
        },
        ['outputName']: {
          isOptional: false,
          value: DocumentGenerationOutputName.nameAttribute,
          readableName: 'Output name',
          description: 'Select option for output document name',
        },
        ['templates']: {
          isOptional: false,
          value: [new DocumentGeneationAvrTemplates({ fileArtifactId: '' })],
          readableName: 'Template',
          description: 'Select from uploaded file artifacts, based on output type (PDF output type can mix PPTX & DOCX templates)',
        },
      },
    );
  }
}
