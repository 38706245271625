import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
// PrimeNG
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PasswordModule } from 'primeng/password';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
// Components
import { SessionFlowListTableComponent } from './session-flow-list/components/session-flow-list-table/session-flow-list-table.component';
import { SessionFlowListComponent } from './session-flow-list/session-flow-list.component';
import { SessionFlowFormComponent } from './session-flow/components/session-flow-form/session-flow-form.component';
import { SessionFlowComponent } from './session-flow/session-flow.component';
import { TenantOslcRootServicesComponent } from './tenant/components/tenant-oslc-root-services/tenant-oslc-root-services.component';
import { TenantSettingsComponent } from './tenant/components/tenant-settings/tenant-settings.component';
import { CurrentTenantComponent } from './tenant/current-tenant.component';

const declarations = [CurrentTenantComponent, TenantOslcRootServicesComponent, TenantSettingsComponent, SessionFlowComponent, SessionFlowListComponent];

@NgModule({
  declarations: [...declarations],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,

    ElvisSharedModule,
    LoaderModule,

    TranslateModule,
    InputTextModule,
    ButtonModule,
    CardModule,
    AccordionModule,
    OverlayPanelModule,
    DividerModule,
    PasswordModule,
    RadioButtonModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    TooltipModule,
    CheckboxModule,

    // Standalone
    SessionFlowFormComponent,
    SessionFlowListTableComponent,
  ],
  exports: [...declarations],
})
export class CurrentTenantModule {}
