import { Injectable } from '@angular/core';
import { ArtifactFormatFileDataResponseDto } from '@api/models/artifact-format-file-data-response-dto';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { BaseDataType, DataTypeKind } from '@private/pages/artifact-type-management/data-type/components/data-type-form/types/data-type-form.types';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { IsBoolean, IsDateOrDateTime, IsDecimal, IsEnumerated, IsFile, IsInteger, IsTime, IsUser } from '@shared/methods/data-type.methods';
import { ConvertStringOrDateToDate, GetStartOfTheDay, GetStartOfTheMinute } from '@shared/methods/date.methods';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import { SelectOption } from '@shared/types/shared.types';
import { arrayOfStringOrNumber, stringOrNumber } from '@workflows/types/conditions/includes.condition';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class RuleConditionsUtilService {
  constructor(private readonly cache: NewCacheService) {}

  formatValue(value: any, attributeId: string): any {
    if (value?.extra) return value;

    const attribute = this.cache.data.attributes.get(attributeId);
    const dataType = this.cache.data.dataTypes.get(attribute!.dataTypeId);

    const baseDataType = dataType!.baseDataType as BaseDataType;
    const kind = dataType!.kind;

    if (IsEnumerated(kind as DataTypeKind)) {
      return this.formatEnum(value);
    }

    if (IsUser(baseDataType)) {
      return this.formatUser(value);
    }

    if (IsBoolean(baseDataType)) {
      return this.formatBoolean(value);
    }

    if (IsDateOrDateTime(baseDataType)) {
      return value ? this.formatDate(value, baseDataType) : '';
    }

    if (IsTime(baseDataType)) {
      return this.formatTime(value);
    }

    if (IsInteger(baseDataType) || IsDecimal(baseDataType)) {
      return this.formatNumber(value);
    }

    if (IsFile(baseDataType)) {
      return this.formatFile(value);
    }

    return value;
  }

  private formatUser(users: SelectOption<string, string>[] | null): string[] {
    if (!users?.length) return [];
    return users.map(user => (user.value === NonAttributeKeys.CURRENT_USER_ID ? (this.cache.userProfile.value as ArtifactLinkResponseDto).id : user.value));
  }

  private formatEnum(value: DataTypeValueResponseDto | DataTypeValueResponseDto[] | null): string | string[] {
    if (!value) return '';
    return Array.isArray(value) ? value.map(val => val.value) : value.value;
  }

  private formatBoolean(value: boolean | string | null): boolean | null {
    if (value === '') {
      return null;
    }

    if (typeof value === 'string') {
      return value === 'true';
    }

    return value;
  }

  private formatDate(value: Date | string, baseDataType: BaseDataType): Date {
    if (baseDataType === BaseDataType.date) {
      return GetStartOfTheDay(ConvertStringOrDateToDate(value));
    }

    return GetStartOfTheMinute(ConvertStringOrDateToDate(value));
  }

  private formatTime(value: Date | string): string {
    return moment(ConvertStringOrDateToDate(value)).format('HH:mm');
  }

  private formatNumber(value: stringOrNumber | arrayOfStringOrNumber): number | number[] {
    if (Array.isArray(value)) {
      return value.map(value => Number(value));
    }
    return Number(value);
  }

  private formatFile(value: File | File[] | ArtifactResponseDto | ArtifactResponseDto[] | string | string[] | null): string | string[] {
    if (Array.isArray(value)) {
      return value.map(
        value =>
          (value as File)?.name || (((value as ArtifactResponseDto)?.formatData as ArtifactFormatFileDataResponseDto)?.filename as string) || (value as string),
      );
    }

    if (!value) return '';

    return (
      (value as File)?.name || (((value as ArtifactResponseDto)?.formatData as ArtifactFormatFileDataResponseDto)?.filename as string) || (value as string)
    );
  }
}
