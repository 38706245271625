import { Injectable, NgZone } from '@angular/core';
import { Params } from '@angular/router';
import { ArtifactResponseDto } from '@api/models/artifact-response-dto';
import { LinkResponseDto } from '@api/models/link-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantWidgetService } from '@api/services/tenant-widget.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { APPLICATION_ID_KEY, FOLDER_FILTER_KEY, ID_KEY } from '@shared/constants/constants';
import { OptimalArtifactService } from '@shared/services/artifact-list/optimal-artifact.service';
import { OptimalLinkService } from '@shared/services/artifact-list/optimal-link.service';
import { FilterUrlControlService } from '@shared/services/filter/filter-url-control.service';
import { ServerSideEventService } from '@shared/services/server-side-event.service';
import { SortMainControlService } from '@shared/services/sort/sort-main-control.service';
import { NewAttribute, NewClientAttribute, NonAttributeKeys } from '@shared/types/attribute.types';
import { SortTypeEnum } from '@shared/types/sort.types';
import { WidgetResponseDto } from '@shared/types/widget.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { ListMatrixWidgetComponent } from '@widgets/list-matrix-widget/list-matrix-widget.component';
import { ListMatrixWidgetOptionsService } from '@widgets/list-matrix-widget/services/list-matrix-widget-options.service';
import { ListMatrixWidgetModel } from '@widgets/list-matrix-widget/types/list-matrix-widget-model';
import { INITIAL_SETTINGS } from '@widgets/list-matrix-widget/types/list-matrix-widget-settings';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { ArtifactSortsService } from '@widgets/shared/components/artifact-sorts/services/artifact-sorts.service';
import { ArtifactSort } from '@widgets/shared/components/artifact-sorts/types/artifact-sort';
import { SortOrder } from '@widgets/shared/components/artifact-sorts/types/sort-order';
import { RuntimeStateNotificationService } from '@widgets/shared/services/runtime-state-notification.service';
import { RuntimeStateNotification, RuntimeStateNotificationEnum } from '@widgets/shared/types/runtime-state-notification.types';
import { cloneDeep } from 'lodash';
import { lastValueFrom } from 'rxjs';
import { filter, skip, tap } from 'rxjs/operators';

@Injectable()
export class ListMatrixWidgetService extends ListMatrixWidgetOptionsService {
  private lastParams: Params;
  private currentArtifactTypeAttributes: Record<string, NewAttribute> = {};

  constructor(
    cache: NewCacheService,
    private readonly zone: NgZone,
    private readonly runtimeStateNotificationService: RuntimeStateNotificationService,
    private readonly sseService: ServerSideEventService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly tenantWidgetService: TenantWidgetService,
    private readonly artifactFiltersService: ArtifactFiltersService,
    private readonly artifactSortsService: ArtifactSortsService,
    private readonly attributeUtil: AttributeUtil,
    private readonly filterUrlControlService: FilterUrlControlService,
    private readonly sortMainControlService: SortMainControlService,
    private readonly optimalArtifactService: OptimalArtifactService,
    private readonly optimalLinkService: OptimalLinkService,
    private readonly filterService: ArtifactFiltersService,
  ) {
    super(cache);
  }

  async init(context: ListMatrixWidgetComponent): Promise<void> {
    this.c = context;
    await this.loadOptions();
    this.setSelectOptionsFromAttributesAndLinkTypes();
    this.initModel(context);
    this.setModelAndOptionsState();
    this.setCurrentArtifactTypeAttributes();

    this.c.registerSubscriptions([
      this.c.route.queryParams
        .pipe(
          skip(1),
          filter((params: Params) => this.shouldReactToUrlChange(params)),
          tap(() => this.loadArtifactsAndSaveParams(true)),
        )
        .subscribe(),

      this.runtimeStateNotificationService.events$
        .pipe(
          filter(({ hash }: RuntimeStateNotification) => hash !== this.c.hash || hash === this.sseService.runtimeKey),
          tap((notification: RuntimeStateNotification) => {
            this.zone.run(() => {
              const notificationHandlers: {
                [notificationType: string]: () => void;
              } = {
                [RuntimeStateNotificationEnum.createArtifact]: () => this.onRsnArtifactCreate(notification),
                [RuntimeStateNotificationEnum.updateArtifact]: () => this.onRsnArtifactUpdate(notification),
                [RuntimeStateNotificationEnum.deleteArtifact]: () => this.onRsnArtifactDelete(notification),
                [RuntimeStateNotificationEnum.createLink]: () => this.onRsnLinkCreate(),
                [RuntimeStateNotificationEnum.updateLinks]: () => this.onRsnLinkUpdate(),
                [RuntimeStateNotificationEnum.deleteLink]: () => this.onRsnLinkDelete(notification),
              };
              const handler = notificationHandlers[notification.type];

              if (handler) {
                handler();
              }
            });
          }),
        )
        .subscribe(),
    ]);
  }

  async getCardWidgetDto(id: string): Promise<WidgetResponseDto> {
    return await lastValueFrom(this.tenantWidgetService.widgetControllerGet({ id }));
  }

  async loadArtifactsAndSaveParams(invokedByUrlChange = false): Promise<void> {
    this.c.showLoader();
    const params = this.c.route.snapshot.queryParams;

    let urlFilters: any[] = [];
    let artifactSorts: ArtifactSort[] | undefined = undefined;

    if (params) {
      if (this.m.settings.list.doFilterOnUrlChange) {
        urlFilters = Object.keys(params)
          .filter(
            key =>
              this.currentArtifactTypeAttributes[key] ||
              this.filterUrlControlService.isFolderFilterAttribute(key) ||
              NonAttributeKeys.isUserSpecificAttributeKeyForUrlFilter(key) ||
              NonAttributeKeys.isDateSpecificAttributeKeyForUrlFilter(key),
          )
          .map(key => {
            const dataType = this.c.options.dataTypes.listMap[this.currentArtifactTypeAttributes[key]?.dataTypeId] || null;
            return this.filterUrlControlService.getQueryFromRawText(
              this.currentArtifactTypeAttributes[key]?.id || key?.replace('-', '.'),
              dataType,
              params[key],
              ',',
            );
          })
          .filter(queryAddon => !!queryAddon);
      }

      if (this.m.settings.list.doSortOnUrlChange) {
        const sortAttributeObject = this.sortMainControlService.getSortAttributeObject(params);
        if (sortAttributeObject) {
          const { attributeName, sortType } = sortAttributeObject;
          if (this.currentArtifactTypeAttributes[attributeName]) {
            const sortMeta = this.sortMainControlService.getSortMeta(this.currentArtifactTypeAttributes[attributeName]?.id, sortType as SortTypeEnum);
            artifactSorts = [new ArtifactSort(sortMeta.order, this.currentArtifactTypeAttributes[attributeName])];
          }

          const nonAttributeKey =
            NonAttributeKeys.isDateSpecificAttributeKeyForUrlFilter(attributeName) || NonAttributeKeys.isUserSpecificAttributeKeyForUrlFilter(attributeName);
          if (nonAttributeKey) {
            const sortMeta = this.sortMainControlService.getSortMeta(this.currentArtifactTypeAttributes[attributeName]?.id, sortType as SortTypeEnum);
            const attributeId = this.getAttributeIdByUrlFilter(attributeName);
            const attribute = this.options.combinedAttributes.find(({ id }: NewAttribute | NewClientAttribute) => id === attributeId) || null;
            artifactSorts = [new ArtifactSort(sortMeta.order, attribute)];
          }
        }
      }
    }

    const skip = invokedByUrlChange ? 0 : Number(this.m.paginatorEvent?.rows) * Number(this.m.paginatorEvent?.page) || 0;
    const limit = this.m.settings.list.cardsPerPage;
    const filter = await this.getArtifactsQueryFilter(urlFilters);
    const sort = this.artifactSortsService.getQuerySort(artifactSorts || this.m.sorts);

    const artifacts$ = this.tenantArtifactService.artifactControllerList({ body: { skip, limit, filter, sort } });
    const { data: artifacts, meta } = await lastValueFrom(artifacts$);

    const options = {
      attributes: this.options.attributes.list,
      dataTypes: this.options.dataTypes.list,
      artifactType: this.options.artifactTypes.listMap[this.m.settings.list.artifactTypeId]!,
    };
    let links: LinkResponseDto[] = [];

    const [linkedArtifacts, files] = await Promise.all([
      this.optimalLinkService.getLinksByArtifacts(artifacts).then((response: LinkResponseDto[]) => {
        links = response;
        return this.optimalArtifactService.getLinkedArtifacts(response);
      }),
      this.optimalArtifactService.getFiles(options, artifacts),
    ]);
    this.m.cardArtifactAdditionalData = { links, linkedArtifacts, files };

    this.m.listResponseMeta = meta;
    this.options.artifacts.setList(artifacts);
    this.lastParams = this.c.route.snapshot.queryParams;
    this.c.sseEvents = 0;
    this.c.hideLoader();

    this.c.canRender = false;
    setTimeout(() => (this.c.canRender = true));
  }

  setCurrentArtifactTypeAttributes(): void {
    const artifactType = this.options.artifactTypes.listMap[this.m.settings.list.artifactTypeId];
    this.currentArtifactTypeAttributes = this.attributeUtil.getUrlQueryAttributesForArtifactType(artifactType, this.options.attributes);
  }

  private async onRsnArtifactCreate(notification: RuntimeStateNotification<ArtifactResponseDto>): Promise<void> {
    const createdArtifact = notification.data;

    if (this.isArtifactIrrelevant(createdArtifact)) {
      return;
    }

    if (this.isSortByUpdatedON()) {
      let artifacts = [...this.m.options.artifacts.list];
      this.m.settings.list.cardsPerPage === this.m.options.artifacts.list.length && artifacts.pop();
      artifacts = [createdArtifact, ...artifacts];
      this.m.options.artifacts.setList(artifacts);
    } else {
      this.c.sseEvents++;
    }
  }

  private onRsnArtifactUpdate(notification: RuntimeStateNotification<ArtifactResponseDto>): void {
    const updatedArtifact = notification.data;

    if (this.isArtifactIrrelevant(updatedArtifact)) {
      return;
    }
    let isUpdated = false;

    let artifacts = this.m.options.artifacts.list.map((artifact: ArtifactResponseDto) => {
      artifact.id === updatedArtifact.id && (isUpdated = true);
      return artifact.id === updatedArtifact.id ? updatedArtifact : artifact;
    });

    if (this.isSortByUpdatedON()) {
      if (!isUpdated) {
        artifacts.pop();
        artifacts = [updatedArtifact, ...artifacts];
      } else {
        artifacts.sort((a, b) => {
          const prev = new Date(a.updated.on);
          const current = new Date(b.updated.on);
          return prev === current ? 0 : prev > current ? -1 : 1;
        });
      }
    }

    this.m.options.artifacts.setList(artifacts);
  }

  private isSortByUpdatedON(): boolean {
    const sort = this.m.sorts[0];
    return sort?.attributeId === NonAttributeKeys.UPDATED_ON_ID && sort?.order === SortOrder.descending;
  }

  private async onRsnArtifactDelete(notification: RuntimeStateNotification<ArtifactResponseDto>): Promise<void> {
    const deletedArtifact = notification.data;

    if (this.isArtifactIrrelevant(deletedArtifact)) {
      return;
    }

    await this.deleteArtifactManuallyOrReloadList(deletedArtifact.id);
  }

  private async onRsnLinkCreate(): Promise<void> {
    this.c.sseEvents++;
  }

  private async onRsnLinkUpdate(): Promise<void> {
    await this.onRsnLinkCreate();
  }

  private async onRsnLinkDelete(notification: RuntimeStateNotification): Promise<void> {
    const linkTypeId = notification.extras;
    const [sourceArtifactId, destinationArtifactId] = notification.data;
    const linkFilter = this.m.filters.find(({ attributeId }: ArtifactFilter) => attributeId === linkTypeId);
    const artifactIdFromUrl = linkFilter ? this.lastParams[linkFilter.value.name] : '';
    const deletedArtifactId = [sourceArtifactId, destinationArtifactId].find((id: string) => id !== artifactIdFromUrl);

    if (![sourceArtifactId, destinationArtifactId].includes(artifactIdFromUrl)) {
      return;
    }

    await this.deleteArtifactManuallyOrReloadList(deletedArtifactId);
  }

  private async deleteArtifactManuallyOrReloadList(deletedArtifactId: string): Promise<void> {
    const isDeletedArtifactVisible = this.m.options.artifacts.list.some(({ id }: ArtifactResponseDto) => id === deletedArtifactId);

    if (!isDeletedArtifactVisible) {
      return;
    }

    const artifactTotalCount = Number(this.m.listResponseMeta?.totalCount);
    const skip = Number(this.m.listResponseMeta?.skip);
    const deletedArtifactReplacementExist = artifactTotalCount > 0 ? artifactTotalCount > skip + this.m.options.artifacts.list.length : false;

    if (deletedArtifactReplacementExist) {
      await this.loadArtifactsAndSaveParams();
    } else {
      const artifacts = this.m.options.artifacts.list.filter((artifact: ArtifactResponseDto) => artifact.id !== deletedArtifactId);
      this.m.options.artifacts.setList(artifacts);
    }
  }

  private isArtifactIrrelevant({ artifactTypeId }: ArtifactResponseDto): boolean {
    return artifactTypeId !== this.m.settings.list.artifactTypeId;
  }

  private async getArtifactsQueryFilter(urlFilters: any[] = []): Promise<string> {
    const filtersFromSettings = await this.artifactFiltersService.getAttributesAndLinksQuery(this.m.filters);
    const $and = [...filtersFromSettings, ...urlFilters];

    let filters = {
      artifactTypeId: { $oid: this.m.settings.list.artifactTypeId },
      deleted: { $eq: null },
    };

    if ($and.length) {
      filters = { ...filters, $and } as any;
    }

    return JSON.stringify(filters);
  }

  private shouldReactToUrlChange(newParams: Params): boolean {
    const listenedUrlParamKeys = this.m.filters.filter(({ isLinkFilter }: ArtifactFilter) => isLinkFilter).map((filter: ArtifactFilter) => filter.value.name);
    const hasSomeListenedUrlParamKeys = listenedUrlParamKeys.some((key: string) => this.lastParams[key] !== newParams[key]);

    if (!this.m.settings.list.doFilterOnUrlChange && !this.m.settings.list.doSortOnUrlChange) {
      return hasSomeListenedUrlParamKeys;
    }

    const relevantAttributeKeys = [...Object.keys(this.currentArtifactTypeAttributes), FOLDER_FILTER_KEY];
    const urlFilterChanged =
      this.m.settings.list.doFilterOnUrlChange &&
      (relevantAttributeKeys.some((key: string) => this.lastParams[key] || newParams[key]) ||
        Object.keys({ ...this.lastParams, ...newParams }).some(
          key => NonAttributeKeys.isUserSpecificAttributeKeyForUrlFilter(key) || NonAttributeKeys.isDateSpecificAttributeKeyForUrlFilter(key),
        ));
    const urlSortChanged =
      this.m.settings.list.doSortOnUrlChange &&
      this.sortMainControlService.getRawSortParamValue(this.lastParams) !== this.sortMainControlService.getRawSortParamValue(newParams);

    return hasSomeListenedUrlParamKeys || !!urlFilterChanged || !!urlSortChanged;
  }

  private initModel(context: ListMatrixWidgetComponent): void {
    if (context.isLayoutMode) {
      const settings = cloneDeep(context.widget.value?.model?.settings || INITIAL_SETTINGS);
      const dto = {
        applicationId: context.widget.value?.model?.applicationId,
        filters: context.widget.value?.model?.filters,
        sorts: context.widget.value?.model?.sorts,
        settings,
      };

      console.log(structuredClone(dto));

      const isEmptyWidget = !context.widget.value;
      this.options.systemAttributes.setList(this.filterService.getSystemAttributes());

      const model: ListMatrixWidgetModel = isEmptyWidget ? ListMatrixWidgetModel.initial() : ListMatrixWidgetModel.fromDto(dto, this.options);

      context.widget.value = { model };
    }

    context.m = context.widget.value.model;

    this.m = context.m;
    context.m.options = this.options;
  }

  private setModelAndOptionsState(): void {
    this.setModelApplication();
    this.setOptionsArtifactTypesByApplication();
  }

  private setModelApplication(): void {
    this.m.application = this.options.applications.listMap[this.c.applicationId];
  }

  private setOptionsArtifactTypesByApplication(): void {
    const application = this.m.application;

    if (application) {
      const artifactTypesByApplication = this.options.artifactTypes.filterByKey(APPLICATION_ID_KEY, application.id);
      this.options.artifactTypesByApplication.setList(artifactTypesByApplication, ID_KEY);
    } else {
      this.options.artifactTypesByApplication.setList([], ID_KEY);
    }
  }

  private getAttributeIdByUrlFilter(urlFilter: string): string {
    switch (urlFilter) {
      case NonAttributeKeys.CREATED_ON_URL_FILTER:
        return NonAttributeKeys.CREATED_ON_ID;
      case NonAttributeKeys.UPDATED_ON_URL_FILTER:
        return NonAttributeKeys.UPDATED_ON_ID;
      case NonAttributeKeys.CREATED_BY_URL_FILTER:
        return NonAttributeKeys.CREATED_BY_ID;
      case NonAttributeKeys.UPDATED_BY_URL_FILTER:
        return NonAttributeKeys.UPDATED_BY_ID;
      default:
        return '';
    }
  }
}
