<div class="grid">
  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <p-multiSelect [options]="scopesOptions" [(ngModel)]="sessionFlow.typeData.scopes" [disabled]="sessionFlow.id ? true : false" id="scopes"></p-multiSelect>
      <label class="dynamic-label-text" for="scopes" translate>Scopes</label>
    </span>
  </div>

  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <input [(ngModel)]="sessionFlow.typeData.clientId" id="type-clientId" pInputText type="text" />
      <label class="dynamic-label-text" for="type-clientId" translate>Client Id</label>
    </span>
  </div>

  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <input [(ngModel)]="sessionFlow.typeData.clientSecret" id="type-clientSecret" pInputText type="text" />
      <label class="dynamic-label-text" for="type-clientSecret" translate>Client Secret</label>
    </span>
  </div>

  <div class="col-12 md:col-6">
    <span class="dynamic-label">
      <input [(ngModel)]="sessionFlow.typeData.tenant" id="type-tenant" pInputText type="text" />
      <label class="dynamic-label-text" for="type-tenant" translate>Tenant</label>
    </span>
  </div>
</div>
