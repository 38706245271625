import { Pipe, PipeTransform } from '@angular/core';
import { NewClientAttribute } from '@shared/types/attribute.types';
import { LinkType } from '@shared/types/link-type.types';

@Pipe({ name: 'isLink' })
export class IsLinkPipe implements PipeTransform {
	transform(selectedAttribute: NewClientAttribute | LinkType | null): boolean {
		return selectedAttribute instanceof LinkType;
	}
}
