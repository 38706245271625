import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ArtifactResponseDto } from '@api/models';
import { TranslateModule } from '@ngx-translate/core';
import { PreviewFormatSettings } from '@shared/components/artifact-attribute-form-field/types/artifact-format-settings.interface';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { NewArtifact } from '@shared/types/artifact.types';
import { NewAttribute } from '@shared/types/attribute.types';
import { EditorModule } from '@tinymce/tinymce-angular';
import { UploadProgressModule } from '@widgets/shared/components/upload-progress/upload-progress.component';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { RuleTriggerEventHandlerService } from '@workflows/services';
import { AttributeEditableSettable } from '@workflows/shared/types/attribute-editable-settable';
import { AttributeVisibleSettable } from '@workflows/shared/types/attribute-visible-settable';
import { WorkflowTriggerAttributeValueChange, WorkflowTriggerEvent } from '@workflows/types';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { ProgressBarModule } from 'primeng/progressbar';
import { SliderModule } from 'primeng/slider';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'app-file-attribute-value-edit-field',
  templateUrl: './file-attribute-value-edit-field.component.html',
  styleUrls: ['./file-attribute-value-edit-field.component.scss'],
})
export class FileAttributeValueEditFieldComponent implements AttributeVisibleSettable, AttributeEditableSettable {
  @Input() attribute: NewAttribute;
  @Input() isEditMode: boolean;
  @Input() truncate: boolean;
  @Input() label: string;
  @Input() index: number;
  @Input() onChangeCb: (isShowSaveButton?: boolean) => void;
  @Input() isMandatory: boolean;
  @Input() isEnabled = true;
  @Input() fileValue: any;
  @Input() isInSavingProcess: boolean;

  @Input() widgetId?: string;
  @Input() pageId?: string;
  @Input() artifact?: NewArtifact;
  @Input() artifactDto?: ArtifactResponseDto;
  @Input() artifactTypeId?: string;
  @Input() attributeId?: string;
  @Input() getAttributeValueFn?: (attributeId: string) => any;

  @Input() set previewAndFormatSettings(settings: PreviewFormatSettings) {
    settings.formatSettings && (this._formatSettings = settings.formatSettings);
    this.visible = settings.isPreviewMode ? !this._formatSettings.hideOnPageLoad : true;
  }

  @Output() fileValueChange = new EventEmitter();

  @ViewChild('fileUpload') fileUpload: FileUpload;

  visible = true;
  private _formatSettings: AttributeFormatSettings;

  constructor(
    private readonly fileService: FileService,
    private readonly ruleTriggerEventHandler: RuleTriggerEventHandlerService,
  ) {}

  get formatSettings(): AttributeFormatSettings {
    return this._formatSettings;
  }

  get sizeLimit (): number {
    // 100 default limit
    return (this._formatSettings?.sizeLimit || 100) * 1024 * 1024;
  }

  onFilesAdd(event: { files: File[] }): void {
    setTimeout(() => {
      if (!event.files.length) return;
      if (this.fileUpload.multiple) {
        !this.fileValue && (this.fileValue = []);
        event.files.forEach(file => this.fileValue.push(file));
      } else {
        const file = event.files[0];
        if (file.size > this.sizeLimit) {
          this.fileUpload.clear();
          this.fileValue = null;
          return;
        }
        this.fileValue = file;
      }
      this.fileUpload.clear();
      this.onChange();
      this.fileValueChange.emit(this.fileValue);
    });
  }

  onFileRemove(index = null): void {
    if (this.fileUpload.multiple) {
      this.fileValue.splice(index, 1);
    } else {
      this.fileValue = '';
    }
    this.onChange();
    this.fileValueChange.emit(this.fileValue);
  }

  onFileDownload(fileArtifact: ArtifactResponseDto): any {
    if (fileArtifact.id) return this.fileService.downloadFileArtifact(fileArtifact);
  }

  setAttributeVisible(visible: boolean) {
    this.visible = visible;
  }

  setAttributeEditable(editable: boolean): void {
    this.formatSettings.editable = editable;
  }

  private onChange(): void {
    this.onChangeCb && this.onChangeCb(true);
    this.notifyAttributeValueChange();
  }

  private notifyAttributeValueChange(): void {
    if (!this.widgetId || !this.pageId || !this.artifactTypeId || !this.attributeId) return;

    const ruleTriggerEvent: WorkflowTriggerEvent = {
      pageId: this.pageId,
      definition: new WorkflowTriggerAttributeValueChange(this.artifactTypeId, this.attributeId),
      payload: {
        artifact: this.artifact,
        artifactDto: this.artifactDto,
        newValue: this.fileValue,
        getAttributeValueFn: this.getAttributeValueFn,
      },
      widgetId: this.widgetId,
    };
    this.ruleTriggerEventHandler.notifyTriggerEvent(ruleTriggerEvent);
  }
}

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ChipsModule,
    MultiSelectModule,
    SliderModule,
    DropdownModule,
    InputTextareaModule,
    CalendarModule,
    CheckboxModule,
    ProgressBarModule,
    EditorModule,
    FileUploadModule,
    TranslateModule,
    ElvisSharedModule,
    UploadProgressModule,
  ],
  exports: [FileAttributeValueEditFieldComponent],
  declarations: [FileAttributeValueEditFieldComponent],
})
export class FileAttributeValueEditFieldModule {}
