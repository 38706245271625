import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockPartWidget } from '@private/pages/page-management/page-builder-graphical/types/block-part-widget';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { NewArtifact } from '@shared/types/artifact.types';
import { DoSomethingWithConfirmationParams } from '@shared/types/shared.types';
import { ElvisUtil } from '@shared/utils/elvis.util';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { FolderWidgetHelper } from '@widgets/folder-widget/helpers/folder-widget.helper';
import { ArtifactMovementService } from '@widgets/folder-widget/services/artifact-movement.service';
import { FolderWidgetService } from '@widgets/folder-widget/services/folder-widget.service';
import { FolderWidgetSettings } from '@widgets/folder-widget/types/folder-widget-settings.types';
import { FolderTreeNode, FolderWidgetModel, FolderWidgetModelDto, FolderWidgetValue } from '@widgets/folder-widget/types/folder-widget.types';
import { ArtifactSelection } from '@widgets/shared/components/artifact-list-table/artifact-list-widget-table.component';
import { WidgetsCoreComponent } from '@widgets/widgets-core/components/widgets-core.component';
import { CURRENT_PAGE, HASH, IS_LAYOUT_MODE, LABEL, WIDGET } from '@widgets/widgets-core/constants/widgets-core.constants';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-folder-widget',
  templateUrl: './folder-widget.component.html',
  styleUrls: ['./folder-widget.component.scss'],
  providers: [FolderWidgetService],
})
export class FolderWidgetComponent extends WidgetsCoreComponent<FolderWidgetValue> implements OnInit {
  @Input() artifact: NewArtifact;
  @Input() isClone: boolean;
  @Input() isShowUserFolder: boolean;
  @Input() isShowUserDefaultTeamFolder: boolean;
  @Input() isShowTenant: boolean;
  @Input() multipleSelection = true;
  @Input() settings: FolderWidgetSettings;
  @ViewChild('op') public overlayPanel: any;
  @ViewChild('details') public overlayDetails: any;
  @ViewChild('colorOverlay') public overlayColor: any;
  @ViewChild('accessOverlay') public overlayAccess: any;
  @Output() updateId = new EventEmitter<FolderTreeNode>();
  advancedWidget: BlockPartWidget | null;
  m: FolderWidgetModel;

  constructor(
    route: ActivatedRoute,
    router: Router,
    announcement: AnnouncementService,
    elRef: ElementRef,
    @Inject(WIDGET) public widget: BlockPartWidget<FolderWidgetValue>,
    @Inject(LABEL) public label: string,
    @Optional() @Inject(CURRENT_PAGE) public page: Page,
    @Inject(IS_LAYOUT_MODE) public isLayoutMode: boolean,
    @Inject(HASH) public hash: string,
    public readonly s: FolderWidgetService,
    public helper: FolderWidgetHelper,
    private readonly confirmationService: ConfirmationService,
    private readonly announcementService: AnnouncementService,
    private readonly artifactMovementService: ArtifactMovementService,
    private readonly elvisUtil: ElvisUtil,
    private readonly translate: TranslateUtil,
    private readonly cache: NewCacheService,
  ) {
    super(route, router, announcement, elRef);
  }

  @Input() set setLayoutMode(isLayoutMode: boolean) {
    this.isLayoutMode = isLayoutMode;
  }

  ngOnInit(): void {
    this.isClone && Object.assign(this, { widget: { value: { model: new FolderWidgetModel() } } });
    this.s.init(this, this.isLayoutMode && this.widget.value?.model ? (this.widget.value.model as any as FolderWidgetModelDto) : null);
    setTimeout(() => {
      this.overlayPanel && (this.m.op = this.overlayPanel);
      this.overlayDetails && (this.m.details = this.overlayDetails);
      this.overlayColor && (this.m.overlayColor = this.overlayColor);
      this.overlayAccess && (this.m.overlayAccess = this.overlayAccess);
    });
  }

  ngOnDestroy(): void {
    this.s.deleteSubscriptions();
  }

  nodeToggle(): void {
    setTimeout(() => {
      if (!this.artifact) {
        const folderIds: string[] = this.m.selectedFile.map(item => item.id);
        const moduleIds: string[] = this.m.selectedFile.map(item => item.moduleId || '').filter(id => !!id);
        this.s.updateUrlParams(folderIds, moduleIds, this.m.settings);
      }
      this.s.updateContextMenu();
    })
  }

  updateFolderId(): void {
    const data = Array.isArray(this.m.selectedFile) ? this.m.selectedFile[0] : this.m.selectedFile;
    this.artifact && this.updateId.emit(data);
  }

  onUpdateFolderIcons(): void {
    this.s.updateFolderIcons(this.m.folders);
  }

  onClear(): void {
    this.m.searchFolder = '';
    this.s.fetchData();
  }

  async onCdkFolderDropped(data: ArtifactSelection, folder: FolderTreeNode): Promise<void> {
    if (!data) {
      return;
    }

    const bulkOperation = Array.isArray(data) && data.length > 1;
    const header = `Move artifact${bulkOperation ? 's' : ''}`;
    const message = bulkOperation
      ? 'Do you want to move {{numberOfArtifacts}}} selected artifacts to {{folder}}?'
      : 'Do you want to move this artifact to {{folder}}?';
    const interpolateParams = {
      ...(bulkOperation && { numberOfArtifacts: String(data.length) }),
      folder: folder.label!,
    };

    await this.elvisUtil.doSomethingWithConfirmation(
      this.confirmationService,
      new DoSomethingWithConfirmationParams(header, message, 'Yes', 'No', interpolateParams),
      async () => await this.onArtifactsMoveConfirm(data, folder),
    );
  }

  private async onArtifactsMoveConfirm(data: ArtifactSelection, folder: FolderTreeNode): Promise<void> {
    if (data instanceof NewArtifact) {
      await this.moveArtifact(data, folder);
    } else {
      await this.moveArtifacts(data!, folder);
    }
  }

  private async moveArtifact(artifact: NewArtifact, folder: FolderTreeNode): Promise<void> {
    try {
      await this.artifactMovementService.moveArtifact(artifact, folder.id).then(dto => this.cache.data.artifacts.setItem(dto));
      await this.announcementService.success('Artifact was moved');
    } catch (error) {
      console.log(error);
      await this.announcementService.error('Error moving artifact');
    }
  }

  private async moveArtifacts(artifacts: NewArtifact[], folder: FolderTreeNode): Promise<void> {
    try {
      const { meta, data } = await this.artifactMovementService.moveArtifacts(artifacts, folder.id);
      const failure = String(meta.errors?.length || 0);
      const success = String(data.length);
      const skip = String(artifacts.length - +failure - +success);

      await this.announcement.info(this.translate.instant('Success: {{success}}. Failure: {{failure}}. Skip: {{skip}}.', { success, failure, skip }));
      data.forEach(dto => this.cache.data.artifacts.setItem(dto));
    } catch (error) {
      console.log(error);
      await this.announcementService.error('Error moving artifacts');
    }
  }
}
