<div class="row mt-1">
  <div class="col-2 text-bold">
    {{ 'To' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox
      [(ngModel)]="action.actionSettings[RuleKeys.emailsTo].isDynamic"
      id="isDynamicEmail"
      binary="true"
      class="mr-2"
      (ngModelChange)="onIsDynamicRecipientChange($event)"
    ></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>

  <div class="col-8">
    <div *ngIf="action.actionSettings[RuleKeys.emailsTo].isDynamic; else nonDynamicReceivers">
      <input id="emailInput" [(ngModel)]="action.actionSettings[RuleKeys.emailsTo].value" pInputText type="text" class="w-100" placeholder="Enter email" />
    </div>

    <ng-template #nonDynamicReceivers>
      <div *ngFor="let email of action.actionSettings[RuleKeys.emailsTo].value; let index = index; trackBy: emailsToTrackByFn" class="mb-1 d-flex">
        <input
          id="dynamicEmailInput"
          [(ngModel)]="action.actionSettings[RuleKeys.emailsTo].value![index]"
          pInputText
          type="text"
          class="w-100"
          [placeholder]="'Enter email ' + (index + 1)"
        />
        <button class="p-button p-button-text" pButton type="button" icon="pi pi-plus" (click)="onAddRecipient(index)"></button>
        <button
          class="p-button p-button-text p-button-danger"
          pButton
          type="button"
          icon="pi pi-minus"
          (click)="onRemoveRecipient(index)"
          [ngClass]="{ 'visibility-hidden': action.actionSettings[RuleKeys.emailsTo].value.length <= 1 }"
        ></button>
      </div>
    </ng-template>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Subject' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.subject].isDynamic" id="isDynamicWorkflow" binary="true" class="mr-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <ng-container *ngIf="action.actionSettings[RuleKeys.subject].isDynamic">
      <input
        id="dynamicSubjectInput"
        [(ngModel)]="action.actionSettings[RuleKeys.subject].value"
        pInputText
        type="text"
        class="w-100"
        placeholder="Enter dynamic subject"
      />
    </ng-container>
    <ng-container *ngIf="!action.actionSettings[RuleKeys.subject].isDynamic">
      <input id="subjectInput" [(ngModel)]="action.actionSettings[RuleKeys.subject].value" pInputText type="text" class="w-100" placeholder="Enter subject" />
    </ng-container>
  </div>
</div>

<div class="row">
  <div class="col-2 text-bold">
    {{ 'Body' | translate }}
  </div>
  <div class="col-2">
    <p-checkbox [(ngModel)]="action.actionSettings[RuleKeys.body].isDynamic" id="isDynamicBody" binary="true" class="mr-2"></p-checkbox>
    {{ 'dynamic' | translate }}
  </div>
  <div class="col-8">
    <ng-container *ngIf="action.actionSettings[RuleKeys.body].isDynamic">
      <textarea
        [(ngModel)]="action.actionSettings[RuleKeys.body].value"
        [placeholder]="'Enter dynamic body' | translate"
        rows="5"
        pInputTextarea
        class="w-100"
        id="bodyInput"
      ></textarea>
    </ng-container>
    <ng-container *ngIf="!action.actionSettings[RuleKeys.body].isDynamic">
      <span class="dynamic-label">
        <editor
          class="editor-enabled"
          [(ngModel)]="action.actionSettings[RuleKeys.body].value"
          [init]="settings"
          [apiKey]="editorKey"
          outputFormat="html"
        >
        </editor>
      </span>
    </ng-container>
  </div>
</div>
