import { SelectOption } from "@shared/types/shared.types";

export enum BackgroundRepeatEnum {
  noRepeat = 'no-repeat',
  repeatAll = 'repeat',
  repeatX = 'repeat-x',
  repeatY = 'repeat-y',
}

export enum BackgroundSizeEnum {
  auto = 'auto',
  cover = 'cover',
  contain = 'contain',
}

export enum BackgroundPositionEnum {
  leftTop = 'left top',
  leftCenter = 'left center',
  leftBottom = 'left bottom',
  centerTop = 'center top',
  centerCenter = 'center center',
  centerBottom = 'center bottom',
  rightTop = 'right top',
  rightCenter = 'right center',
  rightBottom = 'right bottom',
}

export enum BackgroundAttachmentEnum {
  scroll = 'scroll',
  fixed = 'fixed',
}

export const BACKGROUND_REPEAT: SelectOption<string, BackgroundRepeatEnum>[] = [
  new SelectOption('No Repeat', BackgroundRepeatEnum.noRepeat),
  new SelectOption('Repeat All', BackgroundRepeatEnum.repeatAll),
  new SelectOption('Repeat Horizontally', BackgroundRepeatEnum.repeatX),
  new SelectOption('Repeat Vertically', BackgroundRepeatEnum.repeatY),
];

export const BACKGROUND_SIZE: SelectOption<string, BackgroundSizeEnum>[] = [
  new SelectOption('Auto', BackgroundSizeEnum.auto),
  new SelectOption('Cover', BackgroundSizeEnum.cover),
  new SelectOption('Contain', BackgroundSizeEnum.contain),
];

export const BACKGROUND_POSITION: SelectOption<string, BackgroundPositionEnum>[] = [
  new SelectOption('left top', BackgroundPositionEnum.leftTop),
  new SelectOption('left center', BackgroundPositionEnum.leftCenter),
  new SelectOption('left bottom', BackgroundPositionEnum.leftBottom),
  new SelectOption('center top', BackgroundPositionEnum.centerTop),
  new SelectOption('center center', BackgroundPositionEnum.centerCenter),
  new SelectOption('center bottom', BackgroundPositionEnum.centerBottom),
  new SelectOption('right top', BackgroundPositionEnum.rightTop),
  new SelectOption('right center', BackgroundPositionEnum.rightCenter),
  new SelectOption('right bottom', BackgroundPositionEnum.rightBottom),
];

export const BACKGROUND_ATTACHMENT: SelectOption<string, BackgroundAttachmentEnum>[] = [
  new SelectOption('Scroll', BackgroundAttachmentEnum.scroll),
  new SelectOption('Fixed', BackgroundAttachmentEnum.fixed),
];
