<div class="filter-container">
  <p-dropdown class="workflow-element-dropdown"
              styleClass="dropdown-no-border dropdown-label-as-active-link dropdown-no-caret"
              [(ngModel)]="filter.key" [options]="filterKeyOptions" [filter]="true" appendTo="body" (onChange)="onFilterKeyChange($event.value)"></p-dropdown>
  <div class="filter-value-container">
    @if (isDynamic) {
      <input type="text" [placeholder]="'Enter value' | translate" pInputText [(ngModel)]="filter.value" />
    } @else {
      @switch (filter.dataType) {
        @case (ImapFilterDataType.string) {
          <input type="text" [placeholder]="'Enter value' | translate" pInputText [(ngModel)]="filter.value" />
        }
        @case (ImapFilterDataType.boolean) {
          <p-checkbox [(ngModel)]="filter.value" binary="true"></p-checkbox>
        }
        @case (ImapFilterDataType.number) {
          <p-inputNumber [(ngModel)]="filter.value" placeholder="0"></p-inputNumber>
        }
        @case (ImapFilterDataType.date) {
          <p-calendar [(ngModel)]="filter.value" showTime="true" [placeholder]="'Choose date' | translate"></p-calendar>
        }
      }
    }
  </div>
</div>
