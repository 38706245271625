import { Pipe, PipeTransform } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Constants } from '@shared/constants/constants';
import { MenuItem } from '@widgets/menu-widget/types/menu-widget.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';

@Pipe({ name: 'getHrefForMenuItem' })
export class GetHrefForMenuItemPipe implements PipeTransform {
  constructor(private readonly router: Router) {}

  // todo do we really need href on menu item and which logic of choise url for.
  // todo now last of (actions with url) will define url to href
  transform(item: MenuItem, pages: Record<string,Page>): string {
    let url = ''
    item.actions.forEach(action => {
      if (action.url) {
        if (!action.isPageSelection) {
          url = action.url;
        } else {
          const alias = pages[action.url]?.alias || '';

          const urlTreeRoute = action.useAlias ? [alias] : [action.url];
          const navigationExtra: NavigationExtras = { queryParams: action.queryParamsListToMap().parametersToAdd };
          action.fragmentParam && (navigationExtra.fragment = action.fragmentParam);

          url = this.router.createUrlTree(urlTreeRoute, navigationExtra).toString();
        }
      }

    });

    return url;
  }
}
