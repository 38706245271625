import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModalWidgetModule } from '@widgets/sidebar-modal-widget/sidebar-modal-widget.module';
import { WorkflowsModule } from '@workflows/workflows.module';
import { ButtonModule } from 'primeng/button';
import { LimitedModule } from '../../../limited/limited.module';
import { ListWidgetModule } from '../../../widgets/list-widget-new/list-widget.module';
import { SidebarWidgetModule } from '../../../widgets/sidebar-widget/sidebar-widget.module';
import { WidgetsModule } from '../../../widgets/widgets.module';
import { ElvisSharedModule } from '../../elvis-shared.module';
import { LoaderModule } from '../loader/loader.module';
import { WidgetContainerModule } from '../widget-container/widget-container.module';
import { StickyBlockPartDirective } from './directives/sticky-block-part.directive';
import { StickySectionDirective } from './directives/sticky-section.directive';
import { StickyRowDirective } from './directives/sticky-row.directive';
import { PageComponent } from './page.component';
import { CommonPipesModule } from '@shared/pipes/common/common-pipes.module';
import { ImagePipesModule } from '@shared/pipes/internal-image/image-pipes.module';

@NgModule({
  declarations: [PageComponent, StickySectionDirective, StickyRowDirective, StickyBlockPartDirective],
  imports: [
    CommonModule,
    WidgetsModule,
    SidebarWidgetModule,
    SidebarModalWidgetModule,
    LoaderModule,
    RouterModule,
    ButtonModule,
    TranslateModule,
    ListWidgetModule,
    ElvisSharedModule,
    WidgetContainerModule,
    WorkflowsModule,
    LimitedModule,
    CommonPipesModule,
    ImagePipesModule,
  ],
  exports: [PageComponent],
})
export class PageModule {}
