import { PageSectionRowRequestDto } from '@api/models/page-section-row-request-dto';
import { PageSectionRowResponseDto } from '@api/models/page-section-row-response-dto';
import { PageBlockPart } from '@private/pages/page-management/page-builder-graphical/types/page-block-part';
import { PartLocation } from '@private/pages/page-management/page-builder-graphical/types/part-location';
import { Styles } from '@private/pages/page-management/page-builder-graphical/types/styles';
import { PageBlock } from './page-block';
import { PageElementTypes } from '@private/pages/page-management/page-builder-graphical/types/page-element-types';

export class PageRow {
  pageElementType: PageElementTypes = PageElementTypes.row;

  constructor(
    public blocks: PageBlock[] = [],
    public layout: string[] = ['12'],
    public templateId: string | null = null,
    public styles: Styles = new Styles({
      paddingTop: '0px',
      paddingRight: '0.75rem',
      paddingBottom: '0px',
      paddingLeft: '0.75rem',
    }),
  ) {}

  get requestDto(): PageSectionRowRequestDto {
    return {
      templateId: null,
      blocks: this.blocks.map((block: PageBlock) => block.requestDto),
      layout: this.layout,
      styles: this.styles,
    };
  }

  get parts(): PageBlockPart[] {
    return this.blocks.reduce((parts: PageBlockPart[], block: PageBlock) => {
      return [...parts, ...block.parts];
    }, []);
  }

  static fromDto(dto: PageSectionRowResponseDto): PageRow {
    const blocks = dto.blocks?.map(PageBlock.fromDto);
    const styles = dto.styles ? new Styles(dto.styles) : undefined;

    return new PageRow(blocks, dto.layout || undefined, dto.templateId, styles);
  }

  removeIdsForReuse(removeHtmlIds = false): void {
    this.blocks.forEach((block: PageBlock) => block.removeIdsForReuse(removeHtmlIds));
  }

  deleteBlockPart({ blockIndex, partIndex }: Pick<PartLocation, 'blockIndex' | 'partIndex'>): void {
    const block = this.blocks[blockIndex];
    block.deletePart({ partIndex });
    this.deleteBlockIfEmpty({ blockIndex });
  }

  private deleteBlockIfEmpty({ blockIndex }: Pick<PartLocation, 'blockIndex'>): void {
    const block = this.blocks[blockIndex];

    if (!block.parts.length) {
      this.deleteBlock({ blockIndex });
    }
  }

  private deleteBlock({ blockIndex }: Pick<PartLocation, 'blockIndex'>): void {
    this.blocks.splice(blockIndex, 1);
    this.fixLayout({ blockIndex });
  }

  private fixLayout({ blockIndex }: Pick<PartLocation, 'blockIndex'>): void {
    const { layout, blocks } = this;

    if (layout[blockIndex] === '12' && blocks!.length) {
      layout?.splice(blockIndex, 1);

      return;
    }

    const adjacentBlockIndex = blockIndex ? blockIndex - 1 : blockIndex + 1;
    layout[adjacentBlockIndex] = String(Number(layout[adjacentBlockIndex]) + Number(layout[blockIndex]));
    layout!.splice(blockIndex, 1);
  }
}
