<p-accordion *ngIf="!m.selected.showBadge">
    <p-accordionTab [header]="'Counter' | translate" [selected]="true">
        <div class="row">
            <div class="col-6">
                Align counter
            </div>
            <div class="col-6" style="font-size: 16px">
                <p-dropdown
                        (onChange)="isChangeCounter($event)"
                        [(ngModel)]="counterAlign"
                        [options]="m.options.align"
                        placeholder="Align"
                        optionLabel="label"
                >
                </p-dropdown>
            </div>
            <div class="col-6 mb-0">
                <button (click)="counterStyleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Counter styles" pButton></button>
            </div>
            <div class="col-6">
                <span class="dynamic-checkbox-box">
                    <p-checkbox
                            id="showLabel"
                            (onChange)="s.updateValue()"
                            [(ngModel)]="m.settings.showRoundLabel"
                            binary="true"></p-checkbox>
                    <label for="showLabel" translate style="margin-left: 10px">Show round value</label>
                  </span>

            </div>

            <div class="col-6 mb-0">
                <h6> {{ 'Prefix' | translate }} </h6>
                <span class="dynamic-label">
                    <input [(ngModel)]="m.settings.prefix" id="prefix" pInputText type="text" />
                </span>
            </div>
            <div class="col-6 mb-0">
                <h6> {{ 'Suffix' | translate }} </h6>
                <span class="dynamic-label">
                    <input [(ngModel)]="m.settings.postfix" id="postfix" pInputText type="text" />
                </span>
            </div>

            <div class="col-6 mb-0">
                <h6> {{ 'Minimum decimal places' | translate }} </h6>
                <span class="dynamic-label">
                    <p-inputNumber [(ngModel)]="m.settings.minDecimalPlaces" id="minDecimalPlaces" />
                </span>
            </div>
            <div class="col-6 mb-0">
                <h6> {{ 'Maximum decimal places' | translate }} </h6>
                <span class="dynamic-label">
                    <p-inputNumber [(ngModel)]="m.settings.maxDecimalPlaces" id="maxDecimalPlaces" />
                </span>
            </div>
        </div>
    </p-accordionTab>
    <p-accordionTab [header]="'Label' | translate">
        <div class="row">
            <div class="col-6">
                Label position
            </div>
            <div class="col-6">
                <p-dropdown
                        [(ngModel)]="m.settings.labelPosition"
                        [options]="m.options.labelPosition"
                        placeholder="Label position"
                        optionLabel="label"
                >
                </p-dropdown>
            </div>
            <div class="col-6">
                Align label
            </div>
            <div class="col-6" style="font-size: 16px">
                <p-dropdown
                        (onChange)="isChangeLabel($event)"
                        [(ngModel)]="labelAlign"
                        [options]="m.options.align"
                        placeholder="Align"
                        optionLabel="label"
                >
                </p-dropdown>
            </div>
            <div class="col-3 mb-0">
                <button (click)="labelStyleOp.toggle($event)" class="w-100" icon="pi pi-cog" label="Styles" pButton></button>
            </div>
            <div class="col-3">
                <button (click)="styles=m.settings.labelStyles; marginOverlayPanel.toggle($event)"
                        icon="pi pi-cog"
                        label="{{'Margin' | translate}}"
                        pButton></button>
            </div>
            <div class="col-6">
                <span class="dynamic-label p-float-label">
                    <input [(ngModel)]="m.settings.label" id="counter-label" pInputText type="text" />
                    <label class="dynamic-label-text" for="counter-label" translate>Counter Label</label>
                </span>
            </div>
        </div>
    </p-accordionTab>
    <p-accordionTab [header]="'Icon' | translate">
        <div class="row" style="padding-bottom: 150px">
            <div class="col-6">
                <button class="w-100" (click)="iconStyleOp.toggle($event)"
                        icon="pi pi-cog" label="Icon Styles" pButton></button>
            </div>
            <div class="col-6">
                <span class="dynamic-label p-float-label icon-field">
                    <p-dropdown
                        [(ngModel)]="m.settings.iconPosition"
                        [options]="m.options.labelPosition"
                        placeholder="Icon position"
                        optionLabel="label"
                        id="icon-position"
                    >
                    </p-dropdown>
                    <label class="dynamic-label-text" for="icon-position" translate>Icon position</label>
                </span>
            </div>

            <div class="col-6">
                <button (click)="styles=m.settings.iconStyles; marginOverlayPanel.toggle($event)"
                        icon="pi pi-cog"
                        label="{{'Icon Margin' | translate}}"
                        pButton></button>
            </div>
            <div class="col-6" style="font-size: 16px">
                <span class="dynamic-label p-float-label icon-field">
                    <p-dropdown
                        (onChange)="isChangeIcon($event)"
                        [(ngModel)]="iconAlign"
                        [options]="m.options.align"
                        [disabled]="!helper.isAllowSetIconAlign(m)"
                        placeholder="Align"
                        optionLabel="label"
                        id="icon-align"
                    >
                    </p-dropdown>
                    <label class="dynamic-label-text" for="icon-align" translate>Icon align</label>
                </span>
            </div>
            <div class="col-6">
                Select icon
            </div>
            <div class="col-6">
                <span class="dynamic-label icon-field">
                    <app-icon-picker [icon]="m.selected.icon" (onChangeIcon)="m.selected.icon = $event"></app-icon-picker>
                </span>
            </div>
        </div>
    </p-accordionTab>

</p-accordion>
<div *ngIf="m.selected.showBadge" class="row" style="padding-bottom: 100px">
    <div class="col-6">
        Align badge
    </div>
    <div class="col-6" style="font-size: 16px">
        <p-dropdown
                (onChange)="isChangeCounter($event)"
                [(ngModel)]="counterAlign"
                [options]="m.options.align"
                placeholder="Align"
                optionLabel="label"
        >
        </p-dropdown>
    </div>
    <div class="col-6">
        Badge icon
    </div>
    <div class="col-6">
        <span class="dynamic-label icon-field">
            <app-icon-picker [icon]="m.selected.icon" (onChangeIcon)="m.selected.icon = $event"></app-icon-picker>
        </span>
    </div>
    <div class="col-6">
        <button class="w-100" (click)="iconStyleOp.toggle($event)"
                icon="pi pi-cog" label="Icon Styles" pButton></button>
    </div>
    <div class="col-6">
        <button class="w-100" (click)="badgeStyleOp.toggle($event)"
                icon="pi pi-cog" label="Badge Styles" pButton></button>
    </div>
    <!--<div class="col-6">
        <span class="dynamic-label p-float-label icon-field">
            <p-dropdown
                    [(ngModel)]="m.settings.iconPosition"
                    [options]="m.options.labelPosition"
                    placeholder="Icon position"
                    optionLabel="label"
                    id="icon-position2"
            >
            </p-dropdown>
            <label class="dynamic-label-text" for="icon-position2" translate>Icon position</label>
        </span>
    </div>-->
</div>

<p-overlayPanel #counterStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
    <ng-template pTemplate>
        <h5>{{'Counter styles'| translate}}</h5>
        <app-font-style-form
                [fontStyles]="m.settings.counterStyles"
                [hideFontFamilySettings]="true"
                [hideHorizontalAlignSettings]="true"
        ></app-font-style-form>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #labelStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
    <ng-template pTemplate>
        <h5>{{'Label styles'| translate}}</h5>
        <app-font-style-form
                [fontStyles]="m.settings.labelStyles"
                [hideFontFamilySettings]="true"
                [hideHorizontalAlignSettings]="true"
        ></app-font-style-form>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #iconStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
    <ng-template pTemplate>
        <h5>{{'Icon styles'| translate}}</h5>
        <app-font-style-form
                [fontStyles]="m.settings.iconStyles"
                [hideFontFamilySettings]="true"
                [hideHorizontalAlignSettings]="true"
        ></app-font-style-form>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #badgeStyleOp [dismissable]="false" [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
    <ng-template pTemplate>
        <h5>{{'Badge styles'| translate}}</h5>
        <app-font-style-form
                [fontStyles]="m.settings.badgeStyles"
                [hideFontFamilySettings]="true"
                [hideBackgroundColorSetting]="false"
                [hideHorizontalAlignSettings]="true"
                (onChange)="s.updateBadgeStyles()"
        ></app-font-style-form>
    </ng-template>
</p-overlayPanel>

<p-overlayPanel #marginOverlayPanel [style]="{ 'max-width': '380px' }" appendTo="body">
    <ng-template pTemplate>
        <app-margin-form [marginStyles]="styles"></app-margin-form>
    </ng-template>
</p-overlayPanel>
