import { GlobalConstantsEnum } from '../types/global-constants.enum';

export class GlobalConstants {
  private static constants: Map<GlobalConstantsEnum, string> = new Map();

  static getValue(key: GlobalConstantsEnum): string {
    return GlobalConstants.constants.get(key) as string;
  }

  static setValue(key: GlobalConstantsEnum, value: string): void {
    GlobalConstants.constants.set(key, value);
  }
}
