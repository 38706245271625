import { Injectable } from '@angular/core';
import { RegularUserResponseDto } from '@api/models';
import { AclRecordDto } from '@api/models/acl-record-dto';
import { AclRecordIdExpirationsDto } from '@api/models/acl-record-id-expirations-dto';
import { ArtifactAclResponseDto } from '@api/models/artifact-acl-response-dto';
import { AttributeAclResponseDto } from '@api/models/attribute-acl-response-dto';
import { FolderAclRequestDto } from '@api/models/folder-acl-request-dto';
import { FolderAclResponseDto } from '@api/models/folder-acl-response-dto';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantAttributeService } from '@api/services/tenant-attribute.service';
import { TenantFolderService } from '@api/services/tenant-folder.service';
import { TenantTeamService } from '@api/services/tenant-team.service';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ConvertToServerDate } from '@shared/methods/date.methods';
import { ClientData } from '@shared/types/local-storage.types';
import { NewTeam } from '@shared/types/team.types';
import {
  AclDtoType,
  AclExpiration,
  AclItem,
  AclItemType,
  AclMemberTypes,
  AclTypeModel,
  AclTypes,
  expirationDateType,
} from '@widgets/shared/components/acl/types/acl.types';
import { lastValueFrom } from 'rxjs';
import { AclComponent } from '../acl.component';

@Injectable()
export class AclComponentService {
  c: AclComponent;

  oldValue: FolderAclResponseDto | ArtifactAclResponseDto | AttributeAclResponseDto;
  aclDto: AclDtoType;

  constructor(
    private readonly tenantTeamService: TenantTeamService,
    private readonly cache: NewCacheService,
    private readonly tenantFolderService: TenantFolderService,
    private readonly tenantArtifactService: TenantArtifactService,
    private readonly tenantAttributeService: TenantAttributeService,
  ) {}

  async init(context: AclComponent): Promise<void> {
    this.c = context;

    this.c.memberTypesList = Object.values(AclMemberTypes);

    this.c.folderId &&
      this.tenantFolderService.folderControllerGetAcl({ id: this.c.folderId }).subscribe(res => {
        this.aclDto = res;
        this.oldValue = Object.assign({}, res);
        this.raceResolver();
      });

    this.c.artifactId &&
      this.tenantArtifactService.artifactControllerGetAcl({ id: this.c.artifactId }).subscribe(res => {
        this.aclDto = res;
        this.oldValue = Object.assign({}, res);
        this.raceResolver();
      });

    this.c.attributeId &&
      this.tenantAttributeService.attributeControllerGetAcl({ id: this.c.attributeId }).subscribe(res => {
        this.aclDto = res;
        this.oldValue = Object.assign({}, res);
        this.raceResolver();
      });

    const handler = this.cache.data.users.subscribe(users => {
      this.c.users = users as RegularUserResponseDto[];
      this.raceResolver();
      setTimeout(() => {
        handler.unsubscribe();
      });
    });
    const handlerUser = this.cache.user.subscribe(user => {
      this.c.userData = (user as SelfUserResponseDto).clientData as ClientData;
      setTimeout(() => {
        handlerUser.unsubscribe();
      });
    });

    const res = await lastValueFrom(this.tenantTeamService.teamControllerList({ filter: JSON.stringify({ deleted: { $eq: null } }) }));
    this.c.teams = res.data.map(t => new NewTeam(t));
    this.raceResolver();
  }

  raceResolver(): void {
    if (!this.c.users || !this.c.teams) {
      return;
    }

    let keys: AclTypes[] = [AclTypes.view, AclTypes.modify, AclTypes.permissions];
    this.c.folderId && (keys = keys.concat([AclTypes.modifyItems, AclTypes.delete, AclTypes.deleteItems]));
    this.c.artifactId && (keys = keys.concat([AclTypes.delete]));
    this.c.aclTypeList = keys;

    this.c.model = new AclTypeModel(this, this.aclDto);
  }

  isOpenPickerByKey(key: string): boolean {
    return this.c.showUserPicker && this.c.currentAclType === (this.c.aclTypes as any)[key];
  }

  getAclItemByIdAndType(id: string, expirationMap: AclExpiration, isUser?: boolean): AclItem | null {
    const expirationDate: expirationDateType = expirationMap && expirationMap[id] ? new Date(expirationMap[id]) : null;

    if (isUser) {
      const user = this.c.users.find(item => item.id === id);
      return user ? { id: user.id, name: user.email, type: AclItemType.user, expirationDate } : null;
    } else {
      const team = this.c.teams.find(item => item.id === id);
      return team ? { id: team.id, name: team.name, type: AclItemType.team, expirationDate } : null;
    }
  }

  updateList(): void {
    this.c.selectedItem = [];
    this.c.itemList = [];
    const userList: AclItem[] = this.c.users.map(u => ({ id: u.id, name: u.email, type: AclItemType.user }));
    const teamList: AclItem[] = this.c.teams.map(t => ({ id: t.id, name: t.name, type: AclItemType.team }));

    if (this.c.memberType === AclMemberTypes.all) {
      this.c.itemList = userList.concat(...teamList);
    } else {
      this.c.memberType === AclMemberTypes.users && (this.c.itemList = userList);
      this.c.memberType === AclMemberTypes.teams && (this.c.itemList = teamList);
    }

    const ids = this.getUserIdsByCurrentAclType();
    this.c.itemList = this.c.itemList.filter(item => !ids.includes(item.id));
  }

  addMembers(selectedItem: any): void {
    this.c.showUserPicker = !this.c.showUserPicker;
    this.c.selectedItem = selectedItem;
    if (!this.c.selectedItem) return;

    this.c.model[this.c.currentAclType] = this.c.model[this.c.currentAclType].concat(this.c.selectedItem);
    this.c.isChanged = true;
    this.updateList();
  }

  remove(type: AclTypes, item: AclItem): void {
    this.c.isChanged = true;
    this.c.model[this.c.currentAclType] = this.c.model[this.c.currentAclType].filter(it => it.id !== item.id);
  }

  closeAcl(): void {
    this.c.updateAcl.emit();
  }

  async update(): Promise<void> {
    if (!this.c.folderId && !this.c.artifactId && !this.c.attributeId) {
      this.c.updateAcl.emit();
      return;
    }

    const aclToRemove: any[] = [];
    const aclToInsert: any[] = [];
    const isFolder = !!this.c.folderId;
    const id = isFolder ? this.c.folderId : this.c.artifactId || this.c.attributeId;

    this.c.aclTypeList.forEach(key => {
      const currentAcl: AclItem[] = this.c.model[key];
      const oldAcl: AclRecordDto | null = this.oldValue ? (this.oldValue as any)[key] : null;
      const expiration: AclExpiration = this.c.model.expirationMaps[key] || {};
      const ids = currentAcl.map(item => item.id);

      const usersToRemove = oldAcl?.userIds.filter(id => !ids.includes(id)).map(id => ({ id })) || [];
      const teamsToRemove = oldAcl?.teamIds.filter(id => !ids.includes(id)).map(id => ({ id })) || [];

      if (usersToRemove.length > 0 || teamsToRemove.length > 0) {
        const aclRequest = this.getAclRequest(id, key, isFolder);
        usersToRemove.length && (aclRequest.users = usersToRemove);
        teamsToRemove.length && (aclRequest.teams = teamsToRemove);
        aclToRemove.push(aclRequest);
      }

      const oldUserIds = oldAcl?.userIds || [];
      const oldTeamIds = oldAcl?.teamIds || [];
      const oldIds = [...oldUserIds, ...oldTeamIds];

      const filteredAcl = currentAcl.filter(item => !oldIds.includes(item.id) || this.isExpirationModify(expiration, item)) || [];

      const userToInsert: AclRecordIdExpirationsDto[] = filteredAcl.filter(item => item.type === AclItemType.user).map(item => this.getAclRecord(item));
      const teamToInsert: AclRecordIdExpirationsDto[] = filteredAcl.filter(item => item.type === AclItemType.team).map(item => this.getAclRecord(item));

      if (userToInsert.length > 0 || teamToInsert.length > 0) {
        const aclRequest = this.getAclRequest(id, key, isFolder);

        userToInsert.length && (aclRequest.users = userToInsert);
        teamToInsert.length && (aclRequest.teams = teamToInsert);
        aclToInsert.push(aclRequest);
      }
    });

    if (isFolder) {
      await Promise.all(aclToRemove.map(acl => lastValueFrom(this.tenantFolderService.folderControllerAclRemove({ body: acl }))));
      await Promise.all(aclToInsert.map(acl => lastValueFrom(this.tenantFolderService.folderControllerAclInsert({ body: acl }))));
    } else if (this.c.attributeId) {
      await Promise.all(aclToRemove.map(acl => lastValueFrom(this.tenantAttributeService.attributeControllerAclRemove({ body: acl }))));
      await Promise.all(aclToInsert.map(acl => lastValueFrom(this.tenantAttributeService.attributeControllerAclInsert({ body: acl }))));
    } else {
      await Promise.all(aclToRemove.map(acl => lastValueFrom(this.tenantArtifactService.artifactControllerAclRemove({ body: acl }))));
      await Promise.all(aclToInsert.map(acl => lastValueFrom(this.tenantArtifactService.artifactControllerAclInsert({ body: acl }))));
    }

    this.c.updateAcl.emit();
  }

  private getAclRequest(id: string, aclType: string, isFolder: boolean): FolderAclRequestDto {
    const acl: FolderAclRequestDto = { id, aclType } as FolderAclRequestDto;

    if (isFolder) {
      acl.setFolderItemsAcl = this.c.setFolderItemsAcl;
      acl.expirateFolderItems = this.c.setFolderItemsAcl;
    }
    return acl;
  }

  private isExpirationModify(expiration: AclExpiration, item: AclItem): boolean {
    const oldExpiration = (expiration[item.id] && new Date(expiration[item.id]).toString()) || null;
    const currentExpiration = item?.expirationDate && new Date(item.expirationDate).toString();
    return oldExpiration !== currentExpiration;
  }

  private getAclRecord(item: AclItem): AclRecordIdExpirationsDto {
    const expirationDate = item.expirationDate ? ConvertToServerDate(item.expirationDate) : null;
    return { id: item.id, expirationDate };
  }

  private getUserIdsByCurrentAclType(): string[] {
    return this.c.model[this.c.currentAclType].map(u => u.id);
  }
}
