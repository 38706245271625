import { Pipe, PipeTransform } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { WorkflowResponseDto } from '@api/models/workflow-response-dto';
import { CREATED_BY_KEY, CREATED_ON_KEY } from '@shared/constants/constants';
import { GlobalConstants } from '@shared/constants/global.constants';
import { ConvertToClientDatetime } from '@shared/methods/date.methods';
import { GlobalConstantsEnum } from '@shared/types/global-constants.enum';
import { ListContainer } from '@shared/types/list-container.types';
import { get } from 'lodash';

@Pipe({ name: 'workflowToTable' })
export class WorkflowToTablePipe implements PipeTransform {
  transform(workflow: WorkflowResponseDto, key: string, users: ListContainer<ArtifactLinkResponseDto>): string {
    if (key === CREATED_ON_KEY) return ConvertToClientDatetime(new Date(get(workflow, key)));
    if (key === CREATED_BY_KEY) {
      const userId: string = get(workflow, key);
      return userId ? (users.listMap[userId].attributes?.[GlobalConstants.getValue(GlobalConstantsEnum.nameAttributeId)]?.value as string) : '';
    }
    return get(workflow, key);
  }
}
