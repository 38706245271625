<div class="grid">
  <div class="col-12 mb-0 pb-0">
    <h4 *ngIf="m.selected?.treeItem?.usesDefaultStyle && (m.items.menu | isItemInFirstLevel : m.selected.menuItem)" class="my-0">
      Using default top level menu item style
    </h4>
    <h4 *ngIf="m.selected?.treeItem?.usesDefaultStyle && !(m.items.menu | isItemInFirstLevel : m.selected.menuItem)" class="my-0">
      Using default sub menu item style
    </h4>
    <h4 class="my-0" *ngIf="!m.selected?.treeItem?.usesDefaultStyle">Using own item style</h4>
  </div>

  <div class="col-12 mb-0" *ngIf="m.selected.treeItem">
    <span class="dynamic-label dynamic-checkbox">
      <span class="dynamic-checkbox-box">
        <p-checkbox (onChange)="s.onDefaultStylesClick()" [(ngModel)]="m.selected?.treeItem.usesDefaultStyle" binary="true" id="defaultStyle"></p-checkbox>
        <label for="defaultStyle">{{ 'Use default styles' | translate }}</label>
      </span>
    </span>
  </div>

  <div class="col-12" *ngIf="m.selected.treeItem">
    <h6 class="mb-1">UI type</h6>
    <div class="col-12">
      <p-dropdown
        (onChange)="s.onDefaultStylesClick()"
        [(ngModel)]="m.selected.treeItem.uiType"
        [autoDisplayFirst]="false"
        [options]="m.options.menuItemUiType"
        id="e-type"
      >
      </p-dropdown>
    </div>

    <div cdkDropList (cdkDropListDropped)="dropItem($event)" *ngIf="m.selected.treeItem.uiType === menuItemUiType.button">
      <div *ngFor="let action of m.selected.treeItem.actions; let i = index" class="grid mt-2 action-wrapper" cdkDrag>
        <div class="col-1 p-1">
          <i class="bi bi-grip-vertical" cdkDragHandle pTooltip="{{'Move' | translate}}" tooltipPosition="top" pRipple></i>
        </div>
        <div class="col-9 p-1">
          {{ action.type | translate }}
        </div>
        <div class="col-1 p-1 pt-2 center cursor-pointer">
          <i class="pi" [ngClass]="action.isEdit ? 'pi-check': 'pi-pencil'" (click)="toggleEdit(action, i)"></i>
        </div>
        <div class="col-1 p-1 right">
          <a *ngIf="m.selected.treeItem.actions.length > 1" (click)="remove(i)" class="p-1 pi pi-trash p-button p-button-rounded p-button-text p-button-danger" pRipple> </a>
        </div>

        <ng-container *ngIf="action.isEdit">
          <div class="col-12">
            <p-treeSelect
              [(ngModel)]="action.selectedType"
              [options]="m.options.elvisActions"
              (onNodeSelect)="onNodeSelect($event, i)"
              [placeholder]="'Select action' | translate"
              [filter]="true"
              [filterInputAutoFocus]="true"
              class="w-full"
              containerStyleClass="w-full"
            >
              <ng-template let-expanded pTemplate="itemtogglericon">
                <i [class]="expanded ? 'pi pi-minus-circle' : 'pi pi-plus-circle'"></i>
              </ng-template>
            </p-treeSelect>
          </div>

          <div class="col-12" *ngIf="action.type === defaultElvisActions.customEvent">
            <p-floatLabel>
              <input pInputText id="custom-event" class="full-width-container" [(ngModel)]="action.model.name"/>
              <label for="custom-event" translate>Event name</label>
            </p-floatLabel>
          </div>

          <ng-container *ngIf="action.type === currentPageBehavior.leaveCurrentPage">
            <div class="col-4 mb-0">
              <span class="dynamic-label dynamic-checkbox">
                <span class="dynamic-checkbox-box">
                  <p-checkbox [(ngModel)]="action.isPageSelection" (ngModelChange)="s.setTreeItemsToMenu()" binary="true" id="typeUrl"></p-checkbox>
                  <label for="typeUrl">{{ action.isPageSelection ? ('Page' | translate) : ('Url' | translate) }}</label>
                </span>
              </span>

              <span class="dynamic-label dynamic-checkbox" *ngIf="action.isPageSelection">
                <span class="dynamic-checkbox-box">
                  <p-checkbox [(ngModel)]="action.useAlias" (ngModelChange)="s.setTreeItemsToMenu()" binary="true" id="useAlias"></p-checkbox>
                  <label for="useAlias">{{ 'Use page alias' | translate }}</label>
                </span>
              </span>
            </div>

            <div class="col-8 my-0">
              <span *ngIf="!action.isPageSelection" class="dynamic-label">
                <input
                        #urlInput="ngModel"
                        [(ngModel)]="action.url"
                        (ngModelChange)="s.setTreeItemsToMenu()"
                        [pattern]="http_https_validation_regex"
                        id="url"
                        pInputText
                        type="text"
                />
                <label class="dynamic-label-text" for="url" translate>{{ 'Url' | translate }}</label>
                <div *ngIf="urlInput.errors?.pattern" class="mt-3">
                  <p class="text-red">{{ 'Please include' | translate }} <span class="text-bold"> http:// </span> or <span class="text-bold"> https:// </span></p>
                </div>
              </span>

              <span *ngIf="action.isPageSelection" class="dynamic-label">
                <p-dropdown
                        [(ngModel)]="action.pageId"
                        (onChange)="s.onPageSelect(action)"
                        [options]="m.options.pages.list | transformArrayToSelectOptions : NAME_VALUE : ID_VALUE"
                        [autoDisplayFirst]="false"
                        [filter]="true"
                        id="page"
                        appendTo="body"
                        [virtualScroll]="false"
                        [virtualScrollItemSize]="10"
                        appNotifyIfSelectedPageDeletedDirective
                        [selectedPageId]="action.pageId"
                >
                </p-dropdown>
                <label class="dynamic-label-text" for="page">{{ 'Select Page' | translate }}</label>
              </span>
            </div>
          </ng-container>

          <div *ngIf="action.type === currentPageBehavior.stayOnCurrentPage" class="col-12 mb-0">
            <span class="dynamic-label dynamic-checkbox">
              <span class="dynamic-checkbox-box">
                <p-checkbox
                  [(ngModel)]="m.selected?.treeItem.showEmptyParamsAsActive"
                  (ngModelChange)="s.setTreeItemsToMenu()"
                  binary="true"
                  id="showNoParamsAsActive"
                >
                </p-checkbox>
                <label for="showNoParamsAsActive">{{ 'Show item with no parameters as active' | translate }}</label>
              </span>
            </span>
          </div>

          <ng-container *ngIf="action.type === currentPageBehavior.leaveCurrentPage || action.type === currentPageBehavior.stayOnCurrentPage">
            <ng-container *ngIf="action.isPageSelection">
              <div class="col-6 mb-0 text-center">
                <button
                        (click)="selectedAction = action; queryParamsOp.toggle($event)"
                        label="{{ 'Query parameters' | translate }}"
                        class="w-100"
                        icon="bi bi-question-lg"
                        pButton
                        type="button"
                ></button>
              </div>
              <div class="col-6 mb-0 text-center">
                <button
                        (click)="selectedAction = action; fragmentParamOp.toggle($event)"
                        label="{{ 'Scroll to div parameter' | translate }}"
                        class="w-100"
                        icon="bi bi-hash"
                        pButton
                        type="button"
                ></button>
              </div>
            </ng-container>

          </ng-container>
        </ng-container>
      </div>
      <div class="col-12 center p-0 pt-2">
        <i class="bi bi-plus-circle-fill cursor-pointer pl-4 pr-4" (click)="add()"></i>
      </div>
      <div class="col-12 center p-0">
        <button class="p-button p-button-text p-0" pButton type="button" (click)="add()">Add action</button>
      </div>
    </div>

    <div class="col-6">
      <span class="dynamic-label">
        <p-dropdown
            [(ngModel)]="m.settings.menu.innerFlexDirection"
            [options]="m.options.flexDirection"
            appendTo="body"
            id="direction">
        </p-dropdown>
        <label class="dynamic-label-text" for="direction" translate> Direction </label>
      </span>
    </div>
  </div>
</div>

<p-overlayPanel #queryParamsOp [style]="{ 'min-width': '800px' }" appendTo="body">
  <div *ngIf="selectedAction" class="p-grid query-params-overlay">
    <div *ngFor="let param of selectedAction.queryParams; let i = index" class="row">
      <div class="col-3">
        <p-dropdown [(ngModel)]="param.action" [options]="m.options.linkQueryParamAction" [autoDisplayFirst]="false" appendTo="body">
          <ng-template pTemplate="selectedItem" let-selected> {{ selected.label | translate }}</ng-template>
          <ng-template let-option pTemplate="item"> {{ option.label | translate }}</ng-template>
        </p-dropdown>
      </div>
      <div [ngClass]="{ 'col-4': param.action === queryParamActions.addNew, 'col-8': param.action === queryParamActions.removeExisting }">
        <input type="text" [id]="'queryParamKey' + i" pInputText [(ngModel)]="param.key" placeholder="Enter key" class="w-100" />
      </div>
      <div *ngIf="param.action === queryParamActions.addNew" class="col-4">
        <input type="text" [id]="'queryParamVal' + i" pInputText [(ngModel)]="param.value" placeholder="Enter value" class="w-100" />
      </div>
      <div class="col-1 text-center">
        <button
          pButton
          class="p-button p-button-rounded p-button-danger p-button-outlined"
          type="button"
          pTooltip="{{ 'Delete' | translate }}"
          icon="pi pi-trash"
          iconPos="left"
          (click)="onDeleteParamClick(i)"
        ></button>
      </div>
    </div>
    <button (click)="onAddQueryParamClick()" label="{{ 'Add' | translate }}" icon="pi pi-plus" pButton type="button"></button>
  </div>
</p-overlayPanel>

<p-overlayPanel #fragmentParamOp [style]="{ 'max-width': '380px' }" appendTo="body">
  <div *ngIf="selectedAction">
    <input
      type="text"
      pInputText
      [(ngModel)]="selectedAction.fragmentParam"
      (ngModelChange)="s.setTreeItemsToMenu()"
      placeholder="Enter div ID to scroll into"
    />
  </div>
</p-overlayPanel>
