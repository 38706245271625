import { Component, Input } from '@angular/core';
import { HTTP_HTTPS_VALIDATION_REGEX, ID_KEY, NAME_KEY } from '@shared/constants/constants';
import { MenuWidgetService } from '@widgets/menu-widget/services/menu-widget.service';
import { MenuWidgetModel } from '@widgets/menu-widget/types/menu-widget.types';
import {
  LinkQueryParamActionEnum,
  MenuItemCurrentPageBehaviorEnum,
  MenuItemUiType
} from '@widgets/menu-widget/types/tree-types';
import { MenuRepresentation } from '@widgets/menu-widget/types/menu-representation-types';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DefaultElvisActions } from '@shared/types/actions.types';
import { MenuItemAction } from '@widgets/menu-widget/types/menu-action-types';
import { TreeNode } from 'primeng/api/treenode';

@Component({
  selector: 'app-menu-item-type-form',
  templateUrl: './menu-item-type-form.component.html',
  styleUrls: ['./menu-item-type-form.component.scss'],
})
export class MenuItemTypeFormComponent {
  @Input() m: MenuWidgetModel;
  @Input() s: MenuWidgetService;
  defaultElvisActions = DefaultElvisActions;
  currentPageBehavior = MenuItemCurrentPageBehaviorEnum;
  queryParamActions = LinkQueryParamActionEnum;
  http_https_validation_regex = HTTP_HTTPS_VALIDATION_REGEX;
  menuRepresentation = MenuRepresentation;
  menuItemUiType = MenuItemUiType;

  selectedAction: MenuItemAction;

  ID_VALUE = ID_KEY;
  NAME_VALUE = NAME_KEY;

  onAddQueryParamClick(): void {
    if (this.selectedAction) this.selectedAction.queryParams.push({ key: '', value: '', action: LinkQueryParamActionEnum.addNew });
  }

  onDeleteParamClick(index: number): void {
    if (this.selectedAction) this.selectedAction.queryParams.splice(index, 1);
  }

  add(): void {
    this.m.selected.treeItem?.actions && this.m.selected.treeItem.actions.push(new MenuItemAction());
  }

  remove(index: number): void {
    this.m.selected.treeItem?.actions && this.m.selected.treeItem?.actions.splice(index, 1);
  }

  dropItem(event: CdkDragDrop<any[]>): void {
    this.m.selected.treeItem?.actions && moveItemInArray(this.m.selected.treeItem.actions, event.previousIndex, event.currentIndex);
  }

  toggleEdit(action: MenuItemAction, index: number): void {
    action.isEdit = !action.isEdit;
    action.isEdit && this.m.selected.treeItem && this.m.selected.treeItem.actions.forEach((action, i) => {
      i !== index && (action.isEdit = false);
    })
  }

  onNodeSelect({ node }: { node: TreeNode }, i: number): void {
    const action = this.m.selected.treeItem?.actions[i];
    if (action) {
      action.type = node.data;
    }

    this.s.setTreeItemsToMenu(action);
  }
}
