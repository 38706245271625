import { inject } from '@angular/core';
import { SelfUserResponseDto } from '@api/models/self-user-response-dto';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { AnnouncementService } from '@shared/services/announcement.service';
import { RuntimeUser } from '@widgets/shared/types/runtime-variables/runtime-user.types';
import { RuntimeVariablesOptions } from '@widgets/shared/types/runtime-variables/runtime-variables-options.types';

export enum RuntimeVariableKey {
  currentUser = 'current-user',
}

export class RuntimeVariables {
  [RuntimeVariableKey.currentUser]: RuntimeUser;
  private isLoaded = false;

  private cache: NewCacheService = inject(NewCacheService);
  private announcementService: AnnouncementService = inject(AnnouncementService);

  get loaded(): boolean {
    return this.isLoaded;
  }

  set loaded(loaded: boolean) {
    this.isLoaded = loaded;
  }

  async init(options: RuntimeVariablesOptions): Promise<void> {
    try {
      await this.initCurrentUser(options);
      this.isLoaded = true;
    } catch (e) {
      console.error(e);
      await this.announcementService.error('Something went wrong while initializing "RuntimeVariables"');
    }
  }

  private async initCurrentUser(options: RuntimeVariablesOptions): Promise<void> {
    const user: SelfUserResponseDto = await new Promise((resolve, reject) => {
      this.cache.user.subscribe({
        next: value => {
          if (value) resolve(value as SelfUserResponseDto);
        },
        error: reject,
      });
    });

    this[RuntimeVariableKey.currentUser] = new RuntimeUser(user, options, this.cache);
  }
}
