import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { ArtifactLinkResponseDto } from '@api/models/artifact-link-response-dto';
import { DataTypeValueResponseDto } from '@api/models/data-type-value-response-dto';
import { TranslateModule } from '@ngx-translate/core';
import { ArtifactAttributeFormFieldComponent } from '@shared/components/artifact-attribute-form-field/artifact-attribute-form-field.component';
import { BulkEditingAttribute } from '@shared/components/bulk-artifact-edit-popup/types/bulk-editing-attribute';
import { ConstructPreviewFormatSettingsPipe } from '@shared/pipes/attribute-pipes/construct-preview-format-settings.pipe';
import { ListContainer } from '@shared/types/list-container.types';
import { AttributeFormatSettings } from '@widgets/shared/types/attribute-format-settings.types';
import { LabelBehaviourEnum } from '@widgets/shared/types/style.types';
import { cloneDeep } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TooltipModule } from 'primeng/tooltip';
import { BulkArtifactEditPopupOptions } from '../../types/bulk-artifact-edit-popup-options';

enum AttributeValueDescription {
  differentValues = 'Different values',
  blankValue = 'Blank value',
}

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, ButtonModule, TooltipModule, ArtifactAttributeFormFieldComponent, ConstructPreviewFormatSettingsPipe],
  selector: 'app-attribute-field',
  templateUrl: './attribute-field.component.html',
  styleUrls: ['./attribute-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributeFieldComponent implements OnInit, OnDestroy {
  @Input() attribute: BulkEditingAttribute;
  @Input() fieldIndex: number;
  @Input() formatSettings: AttributeFormatSettings = new AttributeFormatSettings();
  @Input() labelBehavior: LabelBehaviourEnum = LabelBehaviourEnum.static;

  valueDescription: AttributeValueDescription | '' = '';
  users: ListContainer<ArtifactLinkResponseDto> = inject(DynamicDialogConfig<BulkArtifactEditPopupOptions>).data.users;

  private initialValue: string | (string | DataTypeValueResponseDto)[];
  private initialValueDescription: AttributeValueDescription | '' = '';

  ngOnInit(): void {
    this.initialValue = cloneDeep(this.attribute.clientAttribute.value);

    if (!this.attribute.sameAttributeValue) {
      this.valueDescription = AttributeValueDescription.differentValues;
    } else {
      if (Array.isArray(this.initialValue)) {
        this.valueDescription = this.initialValue.length ? '' : AttributeValueDescription.blankValue;
      } else {
        this.valueDescription = this.initialValue === '' ? AttributeValueDescription.blankValue : '';
      }
    }

    this.initialValueDescription = this.valueDescription;
  }

  ngOnDestroy(): void {
    this.onRestore();
  }

  onChange = (): void => {
    if (Array.isArray(this.attribute.clientAttribute.value)) {
      this.valueDescription = this.attribute.clientAttribute.value.length ? '' : AttributeValueDescription.blankValue;
    } else {
      this.valueDescription = this.attribute.clientAttribute.value === '' ? AttributeValueDescription.blankValue : '';
    }
  };

  onClear(): void {
    this.attribute.clientAttribute.value = this.attribute.attribute.multipleValues ? [] : '';
    this.attribute.clientAttribute = cloneDeep(this.attribute.clientAttribute);
    this.valueDescription = AttributeValueDescription.blankValue;
  }

  onRestore(): void {
    this.attribute.clientAttribute.value = cloneDeep(this.initialValue);
    this.attribute.clientAttribute = cloneDeep(this.attribute.clientAttribute);
    this.valueDescription = this.initialValueDescription;
  }
}
