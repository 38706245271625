import { Directive, ElementRef, NgZone } from '@angular/core';
import { AutocompleteEventType, AutocompleteSelectionEvent } from '@shared/components/context-autocomplete/types/base/autocomplete.types';
import { FormulaAutocompleteSelectionEvent } from '@shared/components/context-autocomplete/types/formula-autocomplete.types';
import { FormulaJsFunction } from '@shared/types/formula-js.types';
import { AutocompleteService } from '../services';
import { AbstractAutocompleteDirective, SupportAutoCompleteType } from './abstract/abstract-autocomplete.directive';

@Directive({
  selector: '[appFormulaAutocomplete]',
  standalone: true,
})
export class FormulaAutocompleteDirective extends AbstractAutocompleteDirective<FormulaJsFunction> {
  formulaFunctions: any[];
  variablePrefix = '';
  variableSuffix = '';

  constructor(elementRef: ElementRef, zone: NgZone, contextVariableAutocompleteService: AutocompleteService<FormulaJsFunction>) {
    super(elementRef, zone, contextVariableAutocompleteService);

    this.formulaFunctions = Object.values(FormulaJsFunction);
  }

  protected doOpenOverlay(variableName: string, doFocusFirstItem?: boolean): void {
    this.initEvent = {
      options: this.formulaFunctions,
      ownerId: this.ownerId,
      variableNamePart: variableName,
      event: this.event,
      doFocusFirstItem,
      caretPositionInQuery: this.caretPosition,
      type: AutocompleteEventType.formula,
    };
    
    this.contextVariableAutocompleteService.notifyAutocompleteInit(this.initEvent);
  }

  protected onVariableSelection(selectionEvent: FormulaAutocompleteSelectionEvent): void {
    const nativeElement = this.elementRef.nativeElement as SupportAutoCompleteType;
    const query = nativeElement.value;
    const caretPosition = selectionEvent.initEvent.caretPositionInQuery;
    const variableNamePart = selectionEvent.initEvent.variableNamePart;
    const variableIndex = caretPosition - variableNamePart.length;
    const prefix = query.substring(0, variableIndex);
    const suffix = query.substring(variableIndex + variableNamePart.length);
    const variableName = selectionEvent.selectedOption;
    const result = prefix + variableName + '()' + suffix;
    const updatedCaretPosition = result.length - suffix.length - 1;
    nativeElement.value = result;
    nativeElement.focus();
    nativeElement.setSelectionRange(updatedCaretPosition, updatedCaretPosition);
  }

  protected isFocusInPotentialVariable(query: string, caretPosition: number): boolean {
    const queryToCaret = query.substring(0, caretPosition);
    return /[A-Z]+$/.test(queryToCaret);
  }

  protected getVariableName(query: string, caretPosition: number): string {
    return query.substring(0, caretPosition).match(/[A-Z]+$/)?.[0] || query;
  }

  protected shouldReactToEvent(event: AutocompleteSelectionEvent<FormulaJsFunction>): boolean {
    return event.type === AutocompleteEventType.formula;
  }
}
