import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MenuItem } from '@widgets/menu-widget/types/menu-widget.types';
import { LinkQueryParamActionEnum } from '@widgets/menu-widget/types/tree-types';
import { cloneDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { DefaultElvisActions } from '../types/actions.types';
import { AuthorizationService } from './authorization/authorization.service';

@Injectable({ providedIn: 'root' })
export class ElvisActionService {
  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly authorizationService: AuthorizationService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  async fireElvisAction(actionType: DefaultElvisActions, menuItems: MenuItem[], queryParams: Params): Promise<void> {
    switch (actionType) {
      case DefaultElvisActions.goToProfile:
        return this.goToProfile();
      case DefaultElvisActions.logout:
        return this.logout();
      case DefaultElvisActions.removeAllQueryParams:
        return this.removeAllQueryParams();
      case DefaultElvisActions.removeQueryParamsFromThisMenuWidget:
        return this.removeQueryParamsFromThisMenuWidget(menuItems, queryParams);
    }
  }

  private async logout(): Promise<void> {
    await this.authorizationService.logoutWithConfirmation(this.confirmationService);
  }

  private async goToProfile(): Promise<void> {
    await this.router.navigateByUrl('/profile');
  }

  private async removeAllQueryParams(): Promise<void> {
    const queryParams = {};
    await this.router.navigate([], { queryParams, relativeTo: this.route, replaceUrl: true });
  }

  private async removeQueryParamsFromThisMenuWidget(menuItems: MenuItem[], queryParams: Params): Promise<void> {
    const params = cloneDeep(queryParams);
    this.removeQueryParamsFromMenuItems(menuItems, params);
    await this.router.navigate([], { queryParams: params, relativeTo: this.route, replaceUrl: true });
  }

  private removeQueryParamsFromMenuItems(menuItems: MenuItem[], params: Params): void {
    menuItems.forEach(menuItem => {
      menuItem.actions.forEach(action => {
        action.queryParams.forEach(param => param.action === LinkQueryParamActionEnum.addNew && delete params[param.key]);
      })
      menuItem.children.length && this.removeQueryParamsFromMenuItems(menuItem.children, params);
    });
  }
}
