import { AdminUserTenantApplicationResponseDto, TenantAdminUserTenantApplicationRequestDto } from '@api/models';
import { RecordDto } from '@api/models/record-dto';
import { NewApplication } from '@shared/types/application.types';
import { ListContainer } from '@shared/types/list-container.types';
import { CachedUserMeta } from '@shared/types/new-cached-subject.types';
import { SelectOption } from '@shared/types/shared.types';
import { NewSystemUser } from '@shared/types/user.types';

export class UserPageModel {
  loading = false;
  inSavingProgress = false;
  user = new UserPageUserModel();
  userProfileUrl: null | { routerLink: string; queryParams: any } = null;
  loggedUser: NewSystemUser;
  loggedUserMeta: CachedUserMeta | undefined = undefined;
  applications = new ListContainer<NewApplication>();
  userTenantApplications: TenantAdminUserTenantApplicationRequestDto[] = [];
  generatePassword: boolean;
  teams: SelectOption<string, string>[] = [];
  defaultTeamId: string | null = null;
  defaultApplicationId: string | null = null;

  constructor(model?: Partial<UserPageModel>) {
    model && Object.assign(this, model);
  }
}

export class UserPageUserModel {
  id: string;
  email: string;
  isLoginDisabled = false;
  isTenantAdmin = false;
  application: AdminUserTenantApplicationResponseDto | null;
  password: string;
  confirmPassword: string;
  applications: AdminUserTenantApplicationResponseDto[] = [];
  deleted: RecordDto | null;
  profileArtifactId: string | null;
  teamIds: string[];
  defaultTeamId: string;

  constructor(model?: Partial<UserPageUserModel>) {
    model && Object.assign(this, model);
  }
}

export enum UserConfirmationTypeEnum {
  addAdminRole = 'addAdminRole',
  removeAdminRole = 'removeAdminRole',
  addAppAccess = 'addAppAccess',
  removeAppAccess = 'removeAppAccess',
}
