import { Injectable } from '@angular/core';
import { TenantArtifactService } from '@api/services/tenant-artifact.service';
import { TenantLinkService } from '@api/services/tenant-link.service';
import { ID_KEY } from '@shared/constants/constants';
import { lastValueFrom } from 'rxjs';
import { AbstractCardWidgetModeStrategy } from './abstract-card-widget-mode-strategy';

@Injectable()
export class StaticArtifactService extends AbstractCardWidgetModeStrategy {
  constructor(tenantArtifactService: TenantArtifactService, tenantLinkService: TenantLinkService) {
    super(tenantArtifactService, tenantLinkService);
  }

  async onModeChange(): Promise<void> {
    this.model.isReady = false;

    if (this.settings.isArtifactTypeSelected) {
      await this.loadArtifactOptions();
    }

    this.model.isReady = true;
  }

  async onArtifactTypeChange(): Promise<void> {
    this.settings.selectedArtifactId = '';
    await this.loadArtifactOptions();
  }

  private async loadArtifactOptions(): Promise<void> {
    const filter = JSON.stringify({
      artifactTypeId: { $oid: this.settings.artifactTypeId },
      $and: [{ deleted: { $eq: null } }],
    });
    const { data } = await lastValueFrom(this.tenantArtifactService.artifactControllerList({ body: { filter } }));

    this.options.artifactOptions.setList(data, ID_KEY);
  }
}
