import { SelectOption } from '@shared/types/shared.types';
import {
  BACKGROUND_REPEAT,
  BACKGROUND_SIZE,
  BACKGROUND_POSITION,
  BACKGROUND_ATTACHMENT,
  BackgroundRepeatEnum,
  BackgroundSizeEnum,
  BackgroundPositionEnum,
  BackgroundAttachmentEnum,
} from './image-pointer.types';

export class ImagePointerOptions {
  backgroundRepeat: SelectOption<string, BackgroundRepeatEnum>[] = BACKGROUND_REPEAT;
  backgroundSize: SelectOption<string, BackgroundSizeEnum>[] = BACKGROUND_SIZE;
  backgroundPosition: SelectOption<string, BackgroundPositionEnum>[] = BACKGROUND_POSITION;
  backgroundAttachment: SelectOption<string, BackgroundAttachmentEnum>[] = BACKGROUND_ATTACHMENT;
};
