import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'useDecimalPlaces' })
export class UseDecimalPlacesPipe implements PipeTransform {
  transform(value: string | number, minDecimals?: number, maxDecimals?: number, allowInteger?: boolean): string {
    if (!value) return '';

    if (this.shouldSkipIntegerNumberTransforming(value, minDecimals, maxDecimals, allowInteger) && this.shouldSkipNumberTransforming(value, minDecimals, maxDecimals)) {
      return value.toString();
    }

    return Intl.NumberFormat('cs', {
      minimumFractionDigits: minDecimals,
      maximumFractionDigits: this.getMaxFractionDigits(value, minDecimals, maxDecimals),
    }).format(+value).replace(/,/g, '.');
  }

  shouldSkipIntegerNumberTransforming(value: string | number, minDecimals?: number, maxDecimals?: number, allowInteger?: boolean): boolean {
    return (
        !allowInteger ||
        isNaN(+value) ||
        (!minDecimals && !maxDecimals) ||
        (!!minDecimals && !!maxDecimals && minDecimals > maxDecimals)
    );
  }

  shouldSkipNumberTransforming(value: string | number, minDecimals?: number, maxDecimals?: number): boolean {
    return (
      isNaN(+value) ||
      Number.isInteger(value) ||
      (minDecimals === undefined && maxDecimals === undefined) ||
      (minDecimals !== undefined && maxDecimals !== undefined && minDecimals > maxDecimals) ||
      !('' + value).includes('.')
    );
  }

  getMaxFractionDigits(value: string | number, minFracDigits: number | undefined, maxFracDigits: number | undefined): number | undefined {
    let calculatedMaximumFractionDigits = maxFracDigits || this.countNumbersAfterDecimalPoint(value);
    if (minFracDigits && minFracDigits > calculatedMaximumFractionDigits) calculatedMaximumFractionDigits = minFracDigits;

    return calculatedMaximumFractionDigits;
  }

  countNumbersAfterDecimalPoint(value: string | number): number {
    if (isNaN(+value)) return 0;
    const strNumber = '' + value;
    return strNumber.split('.')?.[1]?.length || 0;
  }
}
