<div class="col-12">
  <div class="header">
    <h4 translate>Attributes</h4>
    <button pButton label="{{ 'Add attribute' | translate }}" type="button" class="p-button" icon="pi pi-plus"
            iconPos="left" (click)="addAttribute()"></button>
  </div>
  <p-table
    [columns]="m.attributeColumns"
    [value]="m.editableHelpingAttributes.clientAttributes"
    [paginator]="m.editableHelpingAttributes.clientAttributes.length > (m.rowsPerPage || 0)"
    [rows]="m.rowsPerPage"
    [rowsPerPageOptions]="m.rowsPerPageOptions"
    paginatorPosition="bottom"
    sortMode="multiple"
    sortField="id"
    appThirdClickUndoSort
    cdkDropList
    (cdkDropListDropped)="dropItem($event)"
  >
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th class="reorder-header"></th>
        <th *ngFor="let column of columns">
          {{ column.label | translate }}
        </th>
        <th>
          {{ 'Actions' | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-artifactTypeAttribute let-ri="rowIndex">
      <tr cdkDrag>
        <div class="item-placeholder" *cdkDragPlaceholder></div>
        <td>
          <i class="bi bi-arrows-move" cdkDragHandle pTooltip="{{ 'Move' | translate }}" tooltipPosition="top"
             pRipple></i>
        </td>
        <td>
          <app-type-system-element-dropdown
            (onSelect)="onAttributeChange(artifactTypeAttribute, ri)"
            [(ngModel)]="m.editableHelpingAttributes.clientAttributes[ri].id"
            [applications]="m.options.applications.list"
            [disabledElements]="m.editableHelpingAttributes.clientAttributes"
            [disabled]="artifactTypeAttribute.id | isAttributeDisabled : m.currentUser?.tenant?.systemAttributes"
            [placeholder]="'Choose an attribute'"
            [typeSystemElements]="m.options.attributes.list">
          </app-type-system-element-dropdown>
        </td>
        <td>
          <span *ngIf="artifactTypeAttribute.id">
            {{ (artifactTypeAttribute | getDataTypeFromClientAttribute : m.options.attributes : m.options.dataTypes)?.name }}
          </span>
        </td>
        <td>
          <app-attribute-value-edit-field
            *ngIf="
              artifactTypeAttribute.id &&
              !((artifactTypeAttribute | getDataTypeFromClientAttribute : m.options.attributes : m.options.dataTypes)?.baseDataType | isFile) &&
              m.options.attributes.listMap[artifactTypeAttribute.id]
            "
            [(value)]="artifactTypeAttribute.initialValue"
            [attribute]="m.options.attributes.listMap[artifactTypeAttribute.id]"
            [dataType]="m.options.dataTypes.listMap[m.options.attributes.listMap[artifactTypeAttribute.id].dataTypeId]"
            [disabled]="false"
            [index]="ri"
            [multipleValues]="m.options.attributes.listMap[artifactTypeAttribute.id].multipleValues"
          >
          </app-attribute-value-edit-field>

          <app-file-attribute-value-edit-field
            *ngIf="(artifactTypeAttribute | getDataTypeFromClientAttribute : m.options.attributes : m.options.dataTypes)?.baseDataType | isFile"
            [isMandatory]="artifactTypeAttribute.isMandatory"
            [(fileValue)]="artifactTypeAttribute.initialValue"
            [attribute]="artifactTypeAttribute | getAttributeFromClientAttribute : m.options.attributes"
            [isEditMode]="true"
            [previewAndFormatSettings]="null | constructPreviewFormatSettings : false"
          >
          </app-file-attribute-value-edit-field>
          <div *ngIf="(artifactTypeAttribute | getDataTypeFromClientAttribute : m.options.attributes : m.options.dataTypes)?.kind | isCounter">
            <p-checkbox
              [(ngModel)]="initialValue"
              (onChange)="onInitValueChange(artifactTypeAttribute)"
              binary="true"
              id="counterOption"
              inputId="counterOption"
            >
            </p-checkbox>
          </div>
        </td>
        <td>
          <p-checkbox
            *ngIf="artifactTypeAttribute.id"
            [(ngModel)]="artifactTypeAttribute.isMandatory"
            (onChange)="onAttributeChange(artifactTypeAttribute, ri)"
            binary="true"
          ></p-checkbox>
        </td>
        <td>
          <div class="table_buttons">
            <button
              *ngIf="m.editableHelpingAttributes.clientAttributes[ri].id"
              pButton
              [label]="'Help' | translate"
              type="button"
              [class]="m.editableHelpingAttributes.clientAttributes[ri]?.help ? 'p-button-success': 'p-button-warning'"
              (click)="showHelp(artifactTypeAttribute.id)"
            ></button>
          </div>
        </td>
        <td>
          <div class="table_buttons">
            <button
              pButton
              [label]="'Remove' | translate"
              [disabled]="artifactTypeAttribute.id | isAttributeDisabled : m.currentUser?.tenant?.systemAttributes"
              type="button"
              class="p-button-danger"
              (click)="removeAttribute(ri)"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td [colSpan]="m.attributeColumns.length + 2" translate>No records found</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="isShowHelpDialog"
  [modal]="true"
  [baseZIndex]="10000"
  [draggable]="false"
  [resizable]="false"
  [appendTo]="'body'"
  [style]="{ width: '80vw', position: 'absolute', top: '5rem' }"
  header="{{ 'Help text' | translate }}"
>
  <editor
    *ngIf="isShowHelpDialog"
    [(ngModel)]="helpModel"
    [init]="settings"
    [id]="'htmlField' + currentAttributeId"
    [apiKey]="editorKey"
    outputFormat="html"
  >
  </editor>

  <div class="mt-4 table_buttons">
    <button
            pButton
            [label]="'Cancel' | translate"
            type="button"
            class="p-button-danger mr-2"
            (click)="isShowHelpDialog = false"
    ></button>
    <button
            pButton
            [label]="'Apply' | translate"
            type="button"
            class="p-button"
            (click)="apply(); isShowHelpDialog = false"
    ></button>
  </div>
</p-dialog>
