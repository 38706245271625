<div *ngIf="isLayoutMode; else advancedModeTemplate" class="folder-cont">
  <div *ngIf="m.settings.enableSearch" class="grid">
    <div class="col-8">
      <input
        [(ngModel)]="m.searchFolder"
        [placeholder]="'Search for folder' | translate"
        (blur)="s.fetchData()"
        (keypress)="s.onKeypress($event)"
        id="folderIdKey"
        class="full-width-container"
        type="text"
        pInputText
      >
    </div>
    <div class="col-4">
      <button
        [label]="'Clear' | translate"
        [disabled]="!m.searchFolder"
        (click)="onClear()"
        class="p-button p-button-danger"
        type="button"
        pButton
      ></button>
    </div>
  </div>

  <div #tree [id]="m.containerId" style="position: relative"></div>
  <p-tree
    [value]="m.folders"
    (onNodeSelect)="nodeToggle(); s.fetchNodeContent($event); updateFolderId()"
    (onNodeUnselect)="nodeToggle(); updateFolderId()"
    (onNodeExpand)="s.fetchNodeContent($event)"
    [(selection)]="m.selectedFile"
    [selectionMode]="multipleSelection ? 'multiple': 'single'"
    [contextMenu]="multipleSelection ? cm: null"
    [layout]="m.settings.isHorizontal ? 'horizontal': ''"
    [scrollHeight]="m.settings.scrollable ? '200px': ''"
    [metaKeySelection]="true"
    [draggableNodes]="true" [droppableNodes]="true" draggableScope="tree"
    (onNodeDrop)="s.dndHandler($event)"
  >
    <ng-template let-node pTemplate="default">
            <span cdkDropList (cdkDropListDropped)="onCdkFolderDropped($event.item.data, node)" [ngStyle]="node.styles"
                  [id]="node.id + m.containerId" style="display: flex">
              <div *cdkDragPlaceholder class="folder-placeholder"> placeholder ! </div>
              {{node.label}}
            </span>
    </ng-template>
  </p-tree>

  <p-contextMenu #cm [model]="m.options.contextItems" (onShow)="s.updateContextMenu()"
                 [styleClass]="helper.isPageBuilderPage() ? 'context-menu': ''" [appendTo]="'body'"></p-contextMenu>

  <p-overlayPanel #op [showCloseIcon]="true" [appendTo]="'body'">
    <ng-template pTemplate>
      <div [id]="m.currentFolderId"></div>
      <h5 style="text-align: center;">{{m.isCreateFolder ? 'Create' : 'Rename'}} folder</h5>
      <input [(ngModel)]="m.folderName" pInputText type="text">
      <div class="btn-cont">
        <button *ngIf="m.isCreateFolder" pButton type="button" label="Create"
                (click)="s.createFolder(); op.hide()"></button>
        <button *ngIf="!m.isCreateFolder" pButton type="button" label="Update"
                (click)="s.renameFolder(); op.hide()"></button>
        <button pButton type="button" label="Cancel" (click)="op.hide()"></button>
      </div>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #details [showCloseIcon]="true" [appendTo]="'body'">
    <ng-template pTemplate>
      <div [id]="m.currentFolderId"></div>
      <h5 style="text-align: center; min-width: 100px">Details</h5>
      <p>Subfolders: {{m.folderCounter}}</p>
      <p>Files: 0</p>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #colorOverlay [style]="{ 'max-width': '380px' }" [appendTo]="'body'">
    <ng-template pTemplate>
      <div [id]="m.currentFolderId"></div>
      <app-color-picker [(color)]="m.color" label="Color" (colorChange)="s.updateColor()"></app-color-picker>
    </ng-template>
  </p-overlayPanel>

  <p-overlayPanel #accessOverlay [style]="m.aclContextStyles" [appendTo]="'body'">
    <ng-template pTemplate>
      <div [id]="m.currentFolderId"></div>
      <app-acl [folderId]="m.currentFolderId" (updateAcl)="s.updateMembers()"></app-acl>
    </ng-template>
  </p-overlayPanel>
</div>

<ng-template #advancedModeTemplate>
  <app-folder-widget-settings [settings]="m.settings" (onChange)="s.fetchData()" (changeUrlKey)="s.changeUrlKey($event)" (updateFolderIcons)="s.updateFolderIcons(m.folders)"></app-folder-widget-settings>
</ng-template>
