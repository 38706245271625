import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RegularUserResponseDto } from '@api/models';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { ListContainer } from '@shared/types/list-container.types';
import { SelectOption } from '@shared/types/shared.types';
import { DirectionalLinkType } from '@widgets/list-matrix-widget/types/directional-link-type';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';
import { FilterTypeDetectionService } from '@widgets/shared/components/artifact-filters/services/filter-type-detection.service';
import { ArtifactFilter } from '@widgets/shared/components/artifact-filters/types/artifact-filter.types';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-artifact-filters',
  templateUrl: './artifact-filters.component.html',
  styleUrls: ['./artifact-filters.component.scss'],
})
export class ArtifactFiltersComponent {
  @Input() filtersHolder: { [filtersKey: string]: ArtifactFilter[] };
  @Input() filtersKey: string;
  @Input() attributeAndLinkOptions: SelectOption<string, NewAttribute | NewClientAttribute | DirectionalLinkType>[];
  @Input() dataTypes: NewDataType[];
  @Input() users: ListContainer<RegularUserResponseDto>;

  @Output() filtersChange: EventEmitter<void> = new EventEmitter<void>();

  tabsActiveState: boolean[] = [];

  constructor(
    public readonly filterTypeDetectionService: FilterTypeDetectionService,
    private readonly filterService: ArtifactFiltersService,
  ) {}

  get filters(): ArtifactFilter[] {
    return this.filtersHolder[this.filtersKey];
  }

  set filters(filters: ArtifactFilter[]) {
    this.filtersHolder[this.filtersKey] = filters;
  }

  addFilter(): void {
    this.filters = [...this.filters, ArtifactFilter.initial()];
  }

  removeFilter(removedFilter: ArtifactFilter): void {
    this.filters = this.filters.filter((filter: ArtifactFilter) => filter !== removedFilter);
    this.filtersChange.emit();
  }

  onFilterAttributeChange(newAttributeOrLinkType: NewAttribute | NewClientAttribute | DirectionalLinkType, updatedFilter: ArtifactFilter, index: number): void {
    this.filterService.updateFilter(updatedFilter, newAttributeOrLinkType, this.dataTypes);
    this.filters = this.filters.map((filter: ArtifactFilter) => {
      return filter === updatedFilter ? cloneDeep(filter) : filter;
    });
    this.tabsActiveState = this.tabsActiveState.map((state: boolean, stateIndex: number) => stateIndex === index);

    this.filterTypeDetectionService.isLink(updatedFilter) && this.filtersChange.emit();
  }

  onFilterChange(): void {
    this.filtersChange.emit();
  }
}
