import { Pipe, PipeTransform } from '@angular/core';
import { CardWidgetOptions } from '../types/card-widget-options';

@Pipe({
	name: 'isMultipleFileAttribute',
})
export class IsMultipleFileAttributePipe implements PipeTransform {
	transform(attributeId: string, options: CardWidgetOptions): boolean {
		const attribute = options.attributes.listMap[attributeId];
		const dataType = options.dataTypes.listMap[attribute?.dataTypeId];

		return attribute?.multipleValues && dataType?.isFile;
	}
}
