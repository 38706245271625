import { Component, NgZone, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AutocompleteBaseEvent, AutocompleteEventType } from '@shared/components/context-autocomplete/types/base/autocomplete.types';
import { AUTOCOMPLETE_CONTAINER_CLASS } from '@shared/constants/constants';
import { FormulaJsFunction } from '@shared/types/formula-js.types';
import { Listbox, ListboxModule } from 'primeng/listbox';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { AutocompleteService } from '../../services';
import { FormulaAutocompleteInitEvent } from '../../types/formula-autocomplete.types';
import { AbstractAutocompleteComponent } from '../abstract-autocomplete.component';

@Component({
  selector: 'app-formula-autocomplete-overlay',
  standalone: true,
  imports: [ListboxModule, OverlayPanelModule, FormsModule, TranslateModule],
  templateUrl: './formula-autocomplete-overlay.component.html',
  styleUrls: ['./formula-autocomplete-overlay.component.scss', '../autocomplete-common.scss'],
})
export class FormulaAutocompleteOverlayComponent extends AbstractAutocompleteComponent<FormulaJsFunction> {
  @ViewChild(OverlayPanel) panel: OverlayPanel;
  @ViewChild(Listbox) listBox: Listbox;

  styleClass: string = AUTOCOMPLETE_CONTAINER_CLASS;

  constructor(contextVariableAutocompleteService: AutocompleteService<FormulaJsFunction>, zone: NgZone) {
    super(contextVariableAutocompleteService, zone);
  }

  protected onAutocompleteInit(initEvent: FormulaAutocompleteInitEvent): void {
    this.initEvent = initEvent;
    this.ownerId = initEvent.ownerId;
    this.options = initEvent.options.filter(formula => formula.startsWith(initEvent.variableNamePart));
    this.panel.show(initEvent.event, initEvent.target);

    if (initEvent.doFocusFirstItem) {
      this.doFocusFirstItem();
    }
  }

  protected onOptionSelected(formulaFunction: FormulaJsFunction): void {
    this.contextVariableAutocompleteService.notifyAutocompleteSelection(this.ownerId, formulaFunction, this.initEvent);
    this.panel.hide();
  }

  protected shouldReactToEvent(event: AutocompleteBaseEvent): boolean {
    return event.type === AutocompleteEventType.formula;
  }
}
