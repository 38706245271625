import {
  ArtifactTypeAttributeRequestDto,
  ArtifactTypeAttributeResponseDto,
  ArtifactTypeCreateRequestDto,
  ArtifactTypeDefaultWidgetsDto,
  ArtifactTypeResponseDto,
  ArtifactTypeUpdateRequestDto,
  AvrInputMapperDto,
  ImportDto,
  RecordDto,
} from '@api/models';
import { Template } from '@shared/components/templates/types/templates.types';

export class NewArtifactType implements ArtifactTypeResponseDto {
  id = '';
  applicationId = '';
  defaultPageId: string;
  defaultFolderId: string;
  defaultWidgets: ArtifactTypeDefaultWidgetsDto = { artifactWidgetTemplateId: null, cardWidgetTemplateId: null };
  isLoggingDisabled = false;
  name = '';
  icon = '';
  description = '';
  attributes: Record<string, NewArtifactTypeClientAttribute> = {};
  primaryAttributes: string[] = [];
  preferredArtifactTypeIds?: string[] = [];
  isProtected: boolean;
  format = ArtifactTypeFormatEnum.text;
  imported?: ImportDto;
  created: RecordDto;
  updated: RecordDto;
  deleted: RecordDto | null = null;
  delimiter = ' ';
  avrMapper: AvrInputMapperDto = {};

  constructor(dto?: Partial<ArtifactTypeResponseDto>) {
    if (dto) {
      if (dto.attributes && Object.keys(dto.attributes).length) {
        for (const key in dto.attributes) {
          dto.attributes[key] = new NewArtifactTypeClientAttribute({
            id: key,
            initialValue: dto.attributes[key].initialValue,
            isMandatory: dto.attributes[key].isMandatory,
            help: dto.attributes[key].help,
          });
        }
      }

      Object.assign(this, dto);
    }
  }

  toCreateDto({
    applicationId,
    attributes,
  }: {
    applicationId: string;
    attributes: Record<string, ArtifactTypeAttributeRequestDto>;
  }): ArtifactTypeCreateRequestDto {
    return {
      name: this.name,
      icon: this.icon,
      description: this.description,
      defaultWidgets: this.defaultWidgets,
      format: this.format,
      attributes: attributes,
      primaryAttributes: this.primaryAttributes,
      preferredArtifactTypeIds: this.format === ArtifactTypeFormatEnum.module ? this.preferredArtifactTypeIds : undefined,
      applicationId: applicationId,
      defaultPageId: this.defaultPageId || null,
      defaultFolderId: null,
      isLoggingDisabled: this.isLoggingDisabled,
      delimiter: this.delimiter,
      avrMapper: this.avrMapper,
    };
  }

  toUpdateDto(): ArtifactTypeUpdateRequestDto {
    return {
      id: this.id,
      name: this.name,
      icon: this.icon,
      defaultPageId: this.defaultPageId,
      defaultWidgets: this.defaultWidgets,
      description: this.description,
      attributes: this.attributes,
      primaryAttributes: this.primaryAttributes,
      preferredArtifactTypeIds: this.preferredArtifactTypeIds,
      defaultFolderId: undefined,
      isLoggingDisabled: this.isLoggingDisabled,
      delimiter: this.delimiter,
    };
  }

  hasAttribute(attributeId: string): boolean {
    return attributeId in this.attributes;
  }
}

export class NewArtifactTypeClientAttribute implements ArtifactTypeAttributeResponseDto {
  id: string;
  initialValue: any = null;
  isMandatory = false;
  help = '';

  constructor(attribute?: Partial<NewArtifactTypeClientAttribute>) {
    attribute && Object.assign(this, attribute);
  }
}

export class ArtifactTypeDefaultTemplates {
  artifactWidgetTemplate: Template | null;
  cardWidgetTemplate: Template | null;

  constructor(defaultTemplates?: Partial<ArtifactTypeDefaultTemplates>) {
    defaultTemplates && Object.assign(this, defaultTemplates);
  }
}

export enum ArtifactTypeFormatEnum {
  text = 'TEXT',
  file = 'FILE',
  module = 'MODULE',
}
