<p-overlayPanel appendTo="body">
  <ng-template pTemplate>
    @if (options.length) {
      <p-listbox
        [listStyle]="{ height: '200px', width: '250px' }"
        [options]="options"
        [ngModel]="selectedOption"
        styleClass="{{ styleClass }}"
        (onClick)="onOptionSelected($event.option)"
      ></p-listbox>
    } @else {
      <div class="no-results">{{ 'No results' | translate }}</div>
    }
  </ng-template>
</p-overlayPanel>
