import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-responsive-design-settings',
  templateUrl: './responsive-design-settings.component.html',
  styleUrls: ['./responsive-design-settings.component.scss']
})
export class ResponsiveDesignSettingsComponent {
  @Input() hidingSettings: { pc: boolean; tablet: boolean; mobile: boolean };
}
