<div class="grid widget-settings">
  <div class="col-6">
    <app-info [template]="visualTemplate" [headerTemplate]="visualHeaderTemplate">
      <ng-template #visualHeaderTemplate>
        {{'Visual type' | translate}}
      </ng-template>
      <ng-template #visualTemplate>
        <p>You can choose between 2 different types of display</p>
        <p><strong>Plain<br></strong>The data like user icon, name Login / Logout is shown in the UI</p>
        <p><strong>Overlay<br></strong>Only (user) icon is shown and on click user get the rest of the date in the popup</p>
      </ng-template>
    </app-info>
  </div>
  <div class="col-6">
    <p-dropdown [(ngModel)]="m.settings.widgetType"
                [options]="m.options.widgetType"
                appendTo="body"
                id="widgetType">
    </p-dropdown>
  </div>

  <div class="col-6">
    <app-info [template]="alignmentTemplate" [headerTemplate]="alignmentHeaderTemplate">
      <ng-template #alignmentHeaderTemplate>
        {{'Alignment' | translate}}
      </ng-template>
      <ng-template #alignmentTemplate>
        <p>You can align the widget to left right center or use several stretching options</p>
      </ng-template>
    </app-info>
  </div>
  <div class="col-6">
    <p-dropdown [(ngModel)]="m.styles.justifyContent"
                [options]="m.options.horizontalAlign"
                appendTo="body"
                id="horizontalAlign">
    </p-dropdown>
  </div>

  <div class="col-6">
    <app-info [template]="directionTemplate" [headerTemplate]="directionHeaderTemplate">
      <ng-template #directionHeaderTemplate>
        {{'Direction' | translate}}
      </ng-template>
      <ng-template #directionTemplate>
        <p>The widget data (icon name, logout or login) can be set horizontally or vertically.</p>
        <p><strong>Row</strong><br>Data is horizontally aligned (icon, name, logout or login)</p>
        <p><strong>Column</strong><br>Data is vertically aligned (icon, name, logout or login)</p>
        <p><strong>Row-Reverse</strong><br>Data is horizontally aligned (logout or login, name, icon)</p>
        <p><strong>Column</strong><br>Data is vertically aligned (logout or login, name, icon)</p>
      </ng-template>
    </app-info>
  </div>
  <div class="col-6">
    <p-dropdown [(ngModel)]="m.styles.flexDirection"
                [options]="m.options.flexDirection"
                appendTo="body"
                id="direction">
    </p-dropdown>
  </div>

  <p-accordion class="col-12">
    <p-accordionTab [header]="'User icon' | translate">
      <div class="grid">
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showIcon" binary="true" (onChange)="checkRestriction(settingKeys.showIcon)" [(ngModel)]="m.settings.showIcon"></p-checkbox>
              <label for="showIcon">
                <app-info [template]="userIconTemplate" [headerTemplate]="userIconHeaderTemplate">
                  <ng-template #userIconHeaderTemplate>
                    {{'Show user icon' | translate}}
                  </ng-template>
                  <ng-template #userIconTemplate>
                    <p>User can be shown in 3 different ways:</p>
                    <p><strong>Icon</strong><br>Select an icon for the user. This icon is used when user is logged in and user icon setting is enabled</p>
                    <p><strong>Picture</strong><br>Default User Profile icon is used. If you want to change the picture, then you need to change it in the user profile</p>
                    <p><strong>Letters<br></strong><span style="color: rgb(224, 62, 45);"><span style="color: rgb(0, 0, 0);">The initials of the Name are used.&nbsp; Take first letters from first and last user name.</span></span></p>
                    <p><br>Click on the user icon, is also used for user logout&nbsp;</p>
                  </ng-template>
                </app-info>
              </label>
            </span>
          </span>
        </div>
        <div class="col-6 mb-0">
          <span class="dynamic-label icon-field">
            <app-icon-picker [icon]="m.settings.userIcon" [isDisabled]="!m.settings.showIcon" (onChangeIcon)="m.settings.userIcon = $event"></app-icon-picker>
          </span>
        </div>

        <div class="col-6">
          <div class="dynamic-label p-float-label">
            <p-dropdown
              [(ngModel)]="m.settings.userDisplayVariant"
              [options]="m.options.userIconVariant.list | transformArrayToSelectOptions"
              [placeholder]="'User display variant' | translate"
              autoDisplayFirst="false"
              filterBy="label"
              id="userDisplayVariant"
            >
            </p-dropdown>
            <label for="userDisplayVariant" translate> User display variant </label>
          </div>
        </div>
        <div class="col-6">
          <button (click)="userIconStyleOp.toggle($event)"
                  icon="pi pi-cog" label="User Icon Styles" pButton></button>

          <app-info [template]="userIconStylesTemplate" [headerTemplate]="userIconStylesHeaderTemplate">
            <ng-template #userIconStylesHeaderTemplate>
                <i class="pi pi-info-circle cursor-pointer ml-3 mb-2 info-ico"></i>
            </ng-template>
            <ng-template #userIconStylesTemplate>
              <p>With this setting, you can manage user /icon size and color.<br>Color is used in case you use the icon when user is authenticated and always when user is logged out.<br>Color is also used in case of letters option as a background color.</p>
            </ng-template>
          </app-info>
        </div>

        <div class="col-6" *ngIf="!isShowAlternative"></div>
        <div class="col-6" *ngIf="isShowAlternative">
          <div class="dynamic-label p-float-label">
            <p-dropdown
                    [(ngModel)]="m.settings.userAltDisplayVariant"
                    [options]="m.options.userAltIconVariant.list | transformArrayToSelectOptions"
                    [placeholder]="'Alternative display variant' | translate"
                    autoDisplayFirst="false"
                    filterBy="label"
                    id="userAltDisplayVariant"
            >
            </p-dropdown>
            <label for="userAltDisplayVariant" translate> Alternative display variant </label>
          </div>
        </div>

        <div class="col-6">
          <button (click)="styleOp.toggle($event)"
                  icon="pi pi-cog" label="User Letter Styles" pButton></button>

          <app-info [template]="userLetterStylesTemplate" [headerTemplate]="userLetterStylesHeaderTemplate">
            <ng-template #userLetterStylesHeaderTemplate>
              <i class="pi pi-info-circle cursor-pointer ml-3 mb-2 info-ico"></i>
            </ng-template>
            <ng-template #userLetterStylesTemplate>
              <p>This setting is used in case you select to show User Initials only.<br>The initials can have custom size, color, etc</p>
            </ng-template>
          </app-info>
        </div>
      </div>
    </p-accordionTab>

    <p-accordionTab [header]="'User data' | translate">
      <div class="grid">
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showName" binary="true" (onChange)="checkRestriction(settingKeys.showName)" [(ngModel)]="m.settings.showName"></p-checkbox>
              <label for="showName">
                <app-info [template]="userNameTemplate" [headerTemplate]="userNameHeaderTemplate">
                  <ng-template #userNameHeaderTemplate>
                    {{'Show name' | translate}}
                  </ng-template>
                  <ng-template #userNameTemplate>
                    <p>Select the option if the name of the user shall be shown.<br>You can then set up the name area styles and name font styles</p>
                  </ng-template>
                </app-info>
              </label>
            </span>
          </span>
        </div>
        <div class="col-6">
          <button (click)="userNameStyleOp.toggle($event)"
                  icon="pi pi-cog" label="Name Font Styles" pButton></button>
        </div>

        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showEmail" binary="true" (onChange)="checkRestriction(settingKeys.showEmail)" [(ngModel)]="m.settings.showEmail"></p-checkbox>
              <label for="showEmail">
                <app-info [template]="userEmailTemplate" [headerTemplate]="userEmailHeaderTemplate">
                  <ng-template #userEmailHeaderTemplate>
                    {{'Show email' | translate}}
                  </ng-template>
                  <ng-template #userEmailTemplate>
                    <p>Select the option if email of the user shall be shown.<br>You can then set up the email area styles and name font styles</p>
                  </ng-template>
                </app-info>
              </label>
            </span>
          </span>
        </div>
        <div class="col-6">
          <button (click)="userEmailStyleOp.toggle($event)"
                  icon="pi pi-cog" label="Email Font Styles" pButton></button>
        </div>

        <div class="col-6"> {{'Direction' | translate}} </div>
        <div class="col-6">
          <p-dropdown [(ngModel)]="m.settings.userDataStyles.flexDirection"
                      [options]="m.options.flexDirection"
                      appendTo="body"
                      id="direction">
          </p-dropdown>
        </div>
      </div>
      <p-accordion styleClass="accord">

        <p-accordionTab [header]="'Styles' | translate">
          <app-page-element-styler [styles]="m.settings.userDataStyles" class="mb-3"></app-page-element-styler>
        </p-accordionTab>
        <p-accordionTab [header]="'Name Styles' | translate">
          <app-page-element-styler [styles]="m.settings.userNameStyle" class="mb-3"></app-page-element-styler>
        </p-accordionTab>
        <p-accordionTab [header]="'Email Styles' | translate">
          <app-page-element-styler [styles]="m.settings.userEmailStyle" class="mb-3"></app-page-element-styler>
        </p-accordionTab>
      </p-accordion>

    </p-accordionTab>

    <p-accordionTab [header]="'Login/Logout' | translate">
      <div class="grid">
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showLogoutButton" binary="true" (onChange)="checkRestriction(settingKeys.showLogoutButton)" [(ngModel)]="m.settings.showLogoutButton"></p-checkbox>
              <label for="showLogoutButton">
                <app-info [template]="logoutTemplate" [headerTemplate]="logoutHeaderTemplate">
                  <ng-template #logoutHeaderTemplate>
                    {{'Show log out button' | translate}}
                  </ng-template>
                  <ng-template #logoutTemplate>
                    <p>User can be logged out via clicking on logout icon or clicking on user profile Icon:</p>
                    <p>Here you can set up look of the logout icon</p>
                  </ng-template>
                </app-info>
              </label>
            </span>
          </span>
        </div>
        <div class="col-6 mb-0">
          <span class="dynamic-label dynamic-checkbox">
            <span class="dynamic-checkbox-box">
              <p-checkbox id="showLoginButton" binary="true" [(ngModel)]="m.settings.showLoginButton"></p-checkbox>
              <label for="showLoginButton">
                <app-info [template]="loginTemplate" [headerTemplate]="loginHeaderTemplate">
                  <ng-template #loginHeaderTemplate>
                    {{'Show log in button' | translate}}
                  </ng-template>
                  <ng-template #loginTemplate>
                    <p>If you want to show the login icon then here you can setup look and feel of this icon</p>
                    <p>Here you can set up look of the Login icon</p>
                  </ng-template>
                </app-info>
              </label>
            </span>
          </span>
        </div>

        <div class="col-6 mb-0">
          <span class="dynamic-label icon-field">
            <app-icon-picker [icon]="m.settings.logoutIcon" [isDisabled]="!m.settings.showLogoutButton" [label]="'Logout icon' | translate" (onChangeIcon)="m.settings.logoutIcon = $event"></app-icon-picker>
          </span>
        </div>
        <div class="col-6 mb-0">
          <span class="dynamic-label icon-field">
            <app-icon-picker [icon]="m.settings.loginIcon" [label]="'Login icon' | translate" (onChangeIcon)="m.settings.loginIcon = $event"></app-icon-picker>
          </span>
        </div>

        <div class="col-6">
          <button (click)="logoutIconStyleOp.toggle($event)"
                  icon="pi pi-cog" label="Logout Icon Styles" pButton></button>
        </div>
        <div class="col-6">
          <button (click)="loginIconStyleOp.toggle($event)"
                  icon="pi pi-cog" label="Login Icon Styles" pButton></button>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>
</div>

<p-overlayPanel #userIconStyleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Icon styles'| translate}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.userIconStyle"
            [hideFontFamilySettings]="true"
            [hideEmphasisSettings]="true"
            [showBorderSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #logoutIconStyleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Icon styles'| translate}}</h5>
    <app-font-style-form
      [fontStyles]="m.settings.logoutIconStyle"
      [hideFontFamilySettings]="true"
      [hideEmphasisSettings]="true"
      [showBorderSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #loginIconStyleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'Icon styles'| translate}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.loginIconStyle"
            [hideFontFamilySettings]="true"
            [hideEmphasisSettings]="true"
            [showBorderSettings]="true"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #styleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'User Letters Styles'}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.userletterStyle"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #userNameStyleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'User Name Styles'}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.userNameStyle"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel #userEmailStyleOp [showCloseIcon]="true" [style]="{'max-width':'380px'}"
                appendTo="body">
  <ng-template pTemplate>
    <h5>{{'User Email Styles'}}</h5>
    <app-font-style-form
            [fontStyles]="m.settings.userEmailStyle"
    ></app-font-style-form>
  </ng-template>
</p-overlayPanel>
