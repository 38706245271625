import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SystemAdminUserResponseDto, SystemAdminUserTenantApplicationResponseDto } from '@api/models';
import { PageResponseDto } from '@api/models/page-response-dto';
import { AuthService } from '@api/services';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { UrlMethods } from '@shared/methods/url.methods';
import { ClientData, StateKey } from '@shared/types/local-storage.types';
import { CachedUserMeta } from '@shared/types/new-cached-subject.types';
import { NewSystemUser } from '@shared/types/user.types';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { Constants } from '../../constants/constants';
import { TranslateUtil } from '../../utils/translateUtil';
import { AnnouncementService } from '../announcement.service';
import { LocalStorageService } from '../local-storage.service';
import { AuthorizationHelperService } from './authorization-helper.service';

@Injectable()
export class AuthorizationService {
  private token: string | null = null;
  private anonymousToken: string | null = null;
  private user: SystemAdminUserResponseDto | null = null;
  private anonymousUser: SystemAdminUserResponseDto | null = null;
  private userMeta: CachedUserMeta | null = null;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService,
    private readonly translateUtil: TranslateUtil,
    private readonly announcement: AnnouncementService,
    private readonly helper: AuthorizationHelperService,
    private readonly cache: NewCacheService,
  ) {}

  get isLoggedIn(): boolean {
    return Boolean(this.getToken);
  }

  get getToken(): string {
    if (!this.token) {
      this.token = this.localStorageService.get(StateKey.session) ? this.localStorageService.getFromState(StateKey.session, Constants.token) : null;
    }
    return this.token || '';
  }

  get getAnonymousToken(): string {
    if (!this.anonymousToken) {
      this.anonymousToken = this.localStorageService.get(StateKey.master) ? this.localStorageService.getFromState(StateKey.master, Constants.token) : null;
    }
    return this.anonymousToken || '';
  }

  get getLoggedUser(): SystemAdminUserResponseDto | null {
    if (!this.user) {
      this.user = this.localStorageService.get(StateKey.session) ? this.localStorageService.getFromState(StateKey.session, Constants.user) : null;
    }
    return this.user;
  }

  get getAnonymousUser(): SystemAdminUserResponseDto | null {
    if (!this.anonymousUser) {
      this.anonymousUser = this.localStorageService.get(StateKey.master) ? this.localStorageService.getFromState(StateKey.master, Constants.user) : null;
    }
    return this.anonymousUser;
  }

  get getUserMeta(): CachedUserMeta | null {
    if (!this.userMeta) {
      const user = this.getLoggedUser;
      const userMeta = new CachedUserMeta();

      if (user) {
        userMeta.isSystemAdmin = user.isSystemAdmin;
        userMeta.isTenantAdmin = !!user.tenant?.isAdmin;
        userMeta.isApplicationAdmin = user.tenant
          ? user.tenant.applications.some((application: SystemAdminUserTenantApplicationResponseDto) => application.isAdmin)
          : false;
      }

      this.userMeta = userMeta;
    }
    return this.userMeta;
  }

  resetToken(): void {
    this.token = null;
  }

  resetLoggedUserData(): void {
    this.user = null;
    this.userMeta = null;
  }

  resetUserMeta(): void {
    this.userMeta = null;
  }

  async afterLogin(
    user: SystemAdminUserResponseDto,
    token: string,
    redirectionPage?: PageResponseDto,
  ): Promise<{ user: SystemAdminUserResponseDto; redirectionPage: PageResponseDto | undefined } | boolean> {
    if (!this.localStorageService.get(StateKey.session)) this.localStorageService.initialize();
    if (!(await this.helper.setToken(token, StateKey.session))) return false;
    if (!(await this.helper.setUser(user, StateKey.session))) return false;

    this.helper.setClientDataToSessionState(user.clientData as ClientData);
    this.helper.initTranslation();
    this.helper.saveClientData(user.clientData as ClientData);

    this.token = token;

    setTimeout(() => {
      this.cache?.user?.next(user as any);
    });

    await this.announcement.success('User logged in successfully');
    return { user, redirectionPage };
  }

  async login(email: string, password: string): Promise<{ user: SystemAdminUserResponseDto; redirectionPage: PageResponseDto | undefined } | boolean> {
    try {
      const { user, token, redirectionPage } = await lastValueFrom(
        this.authService.authControllerLogin({ origin: window.location.origin, body: { email, password } }),
      );

      if (user.tenant && !user.tenant?.systemPages)
        user.tenant.systemPages = { accessPageId: null, loginPageId: null, notFoundPageId: null, recoverPasswordPageId: null };

      return this.afterLogin(user, token, redirectionPage || undefined);
    } catch (e: any) {
      console.log(e);

      if (e.status === 403) {
        await this.announcement.error('Your account has been locked. Contact your system administrator to fix this.');
        return false;
      }

      await this.announcement.error('Your username or password is invalid');
      return false;
    }
  }

  async anonymousLogin(): Promise<SystemAdminUserResponseDto> {
    try {
      const masterState = this.localStorageService.get(StateKey.master);

      if (!masterState?.token) {
        const { user, token } = await lastValueFrom(this.authService.authControllerAnonymousLogin({ origin: window.location.origin }));

        if (user.tenant && !user.tenant?.systemPages)
          user.tenant.systemPages = { accessPageId: null, loginPageId: null, notFoundPageId: null, recoverPasswordPageId: null };

        if (!this.localStorageService.get(StateKey.master)) this.localStorageService.initialize();
        await this.helper.setToken(token, StateKey.master);
        await this.helper.setUser(user, StateKey.master);

        return user;
      }

      return masterState?.user;
    } catch (e: any) {
      console.log(e);
      if ([401, 403, 404].includes(e?.status)) throw e;

      await this.announcement.error('Something went wrong when Anonymous User login');
      return new NewSystemUser();
    }
  }

  async recoverPassword(email: string): Promise<boolean> {
    try {
      await lastValueFrom(this.authService.authControllerRecoverPassword({ origin: window.location.origin, body: { email } }));
      return true;
    } catch (e) {
      await this.announcement.error('Something went wrong during password recovery');
      return false;
    }
  }

  async tokenPasswordChange(email: string, newPassword: string, token: string): Promise<boolean> {
    try {
      await lastValueFrom(this.authService.authControllerTokenPasswordChange({ body: { email, newPassword, token } }));
      return true;
    } catch (e) {
      await this.announcement.error('Something went wrong during password token change');
      return false;
    }
  }

  async logout(): Promise<void> {
    try {
      await lastValueFrom(this.authService.authControllerLogout());
      this.resetToken();
      this.resetLoggedUserData();
      this.cache.destroy();
      this.localStorageService.clear();
      this.localStorageService.initialize();
      await this.anonymousLogin();
      UrlMethods.windowOpen('/login');
    } catch (e: any) {
      console.log(e);
      await this.announcement.error(e.statusText);
    }
  }

  async logoutWithConfirmation(confirmationService: ConfirmationService): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Logout', 'Are you sure that you want to logout?', 'Yes', 'No']);
    confirmationService.confirm({ header, message, acceptLabel, rejectLabel, accept: () => this.logout() });
  }
}
