import { SessionType } from '@shared/types/session-flow.types';
import { GoogleSessionFlowFormComponent } from './google/google.session-flow-form.component';
import { InternalSessionFlowFormComponent } from './internal/internal.session-flow-form.component';
import { MicrosoftSessionFlowFormComponent } from './microsoft/microsoft.session-flow-form.component';
import { OpenIdSessionFlowFormComponent } from './openid/openid.session-flow-form.component';

export class IndexSessionFlowFormComponents {
  static google = GoogleSessionFlowFormComponent;
  static internal = InternalSessionFlowFormComponent;
  static microsoft = MicrosoftSessionFlowFormComponent;
  static openid = OpenIdSessionFlowFormComponent;
  static components = [GoogleSessionFlowFormComponent, InternalSessionFlowFormComponent, MicrosoftSessionFlowFormComponent, OpenIdSessionFlowFormComponent];
}

// If typescript is throwing error, some enum option from generated DTO is not assigned / is assigned incorrect option
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sessionTypeCheck: Record<SessionType, any> = IndexSessionFlowFormComponents;

// If typescript is throwing error, component is added to common 'components' array
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const indexComponentsCheck: [InstanceType<(typeof IndexSessionFlowFormComponents)[SessionType]>] extends [
  InstanceType<(typeof IndexSessionFlowFormComponents)['components'][number]>,
]
  ? true
  : false = true;
