<span class="dynamic-label dynamic-checkbox">
    <span class="dynamic-checkbox-box">
    <p-checkbox [(ngModel)]="hidingSettings.pc" binary="true" inputId="hidePC"></p-checkbox>
    <label for="hidePC">{{ 'Hide on PC' | translate}}</label>
    </span>
</span>

<span class="dynamic-label dynamic-checkbox">
    <span class="dynamic-checkbox-box">
    <p-checkbox [(ngModel)]="hidingSettings.tablet" binary="true" inputId="hideTablet"></p-checkbox>
    <label for="hideTablet">{{ 'Hide on Tablet' | translate}}</label>
    </span>
</span>

<span class="dynamic-label dynamic-checkbox">
    <span class="dynamic-checkbox-box">
    <p-checkbox [(ngModel)]="hidingSettings.mobile" binary="true" inputId="hideMobile"></p-checkbox>
    <label for="hideMobile">{{ 'Hide on Mobile' | translate}}</label>
    </span>
</span>