import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomEventSettingsFormComponent } from './custom-event-settings-form.component';
import { InputTextModule } from 'primeng/inputtext';
import { TranslateModule } from '@ngx-translate/core';
import { FloatLabelModule } from 'primeng/floatlabel';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';



@NgModule({
  declarations: [CustomEventSettingsFormComponent],
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    FloatLabelModule,
    CheckboxModule,
    ButtonModule,
    AccordionModule,
    TranslateModule,
  ],
  exports: [CustomEventSettingsFormComponent]
})
export class CustomEventSettingsFormModule { }
