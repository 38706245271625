import { Injectable } from '@angular/core';
import { TenantAdminSessionFlowResponseDto } from '@api/models';
import { TenantSessionFlowService } from '@api/services';
import { CoreService } from '@shared/core/services/core.service';
import { BlockUiService } from '@shared/services/block-ui.service';
import { LinkType } from '@shared/types/link-type.types';
import { SessionFlow } from '@shared/types/session-flow.types';
import { TranslateUtil } from '@shared/utils/translateUtil';
import { cloneDeep } from 'lodash';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { SessionFlowModel } from '../types/session-flow.types';

@Injectable()
export class SessionFlowService extends CoreService<any, SessionFlowModel> {
  constructor(
    private readonly tenantSessionFlowService: TenantSessionFlowService,
    private readonly confirmationService: ConfirmationService,
    private readonly translateUtil: TranslateUtil,
    private readonly blockUiService: BlockUiService,
  ) {
    super();
  }

  async init(context: any, model: SessionFlowModel): Promise<void> {
    super.init(context, model);
    this.initSessionFlow();
  }

  async save(): Promise<void> {
    this.blockUiService.blockUi();

    try {
      let sessionFlow;

      if (this.m.sessionFlow.id) {
        sessionFlow = await this.updateSessionFlow();
      } else {
        sessionFlow = await this.createNewSessionFlow();
      }

      if (sessionFlow) {
        await this.cancel();
      }
    } finally {
      this.blockUiService.unblockUi();
    }
  }

  async deleteWithConfirmation(sessionFlow: SessionFlow, callback: (() => void) | null = null): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll(['Delete', 'Are you sure that you want to delete', 'Yes', 'No']);
    this.confirmationService.confirm({
      header,
      message: message + ' ' + sessionFlow.type + '?',
      acceptLabel,
      rejectLabel,
      accept: () =>
        this.delete(sessionFlow.id).then(() => {
          callback ? callback() : this.cancel();
        }),
    });
  }

  async cancel(): Promise<void> {
    await this.c.router.navigateByUrl('/admin/session-flow-list');
  }

  onSessionTypeChange(event: any): void {
    this.m.sessionFlow.typeData = SessionFlow.fromType(event.value).typeData;
  }

  private async delete(id: string): Promise<void> {
    this.m.inProgress = true;
    this.blockUiService.blockUi();

    try {
      const success = await lastValueFrom(this.tenantSessionFlowService.sessionFlowControllerDelete({ id }));
      success && (await this.cancel());
    } finally {
      this.m.inProgress = false;
      this.blockUiService.unblockUi();
    }
  }

  private initSessionFlow(): void {
    if (this.c.urlParams.id) {
      this.initSessionFlowFromUrlId();
    } else {
      this.m.sessionFlow = SessionFlow.fromType(this.m.sessionFlowType);
    }
  }

  private async initSessionFlowFromUrlId(): Promise<void> {
    try {
      const res = await lastValueFrom(this.tenantSessionFlowService.sessionFlowControllerGet({ id: this.c.urlParams.id }));
      if (!(res as TenantAdminSessionFlowResponseDto).typeData) throw new Error('Session flow is defined outside current tenant');
      this.m.sessionFlow = SessionFlow.fromServer(res);
      this.m.sessionFlowType = this.m.sessionFlow.type;
    } catch (error) {
      this.c.announcement.error('Unable to get Session flow', error);
    }
    this.m.originalSessionFlow = cloneDeep(this.m.sessionFlow);
  }

  private async updateSessionFlow(): Promise<TenantAdminSessionFlowResponseDto> {
    return await lastValueFrom(
      this.tenantSessionFlowService.sessionFlowControllerUpdate({ body: SessionFlow.toServerUpdate(this.m.sessionFlow, this.m.originalSessionFlow!) }),
    );
  }

  private async createNewSessionFlow(): Promise<TenantAdminSessionFlowResponseDto> {
    return await lastValueFrom(this.tenantSessionFlowService.sessionFlowControllerCreate({ body: SessionFlow.toServerCreate(this.m.sessionFlow) }));
  }

  private async confirmBeforeLinkTypeChange(accept: () => void, reject: () => void): Promise<void> {
    const [header, message, acceptLabel, rejectLabel] = await this.translateUtil.getAll([
      'Change link type',
      "You have unsaved changes. Are you sure you don't want to save them?",
      'Yes',
      'No',
    ]);
    this.confirmationService.confirm({
      header,
      message,
      acceptLabel,
      rejectLabel,
      accept: () => accept && accept(),
      reject: () => reject && reject(),
    });
  }
}
