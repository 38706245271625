import { ArtifactLinkResponseDto } from '@api/models';
import { ApplicationResponseDto } from '@api/models/application-response-dto';
import { ArtifactTypeResponseDto } from '@api/models/artifact-type-response-dto';
import { AttributeResponseDto } from '@api/models/attribute-response-dto';
import { DataTypeResponseDto } from '@api/models/data-type-response-dto';
import { LinkTypeResponseDto } from '@api/models/link-type-response-dto';
import { PageResponseDto } from '@api/models/page-response-dto';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { Page } from '@private/pages/page-management/page-builder-graphical/types/page';
import { NewCacheService } from '@shared/cache/new-cache.service';
import { ID_KEY } from '@shared/constants/constants';
import { LinkMethods } from '@shared/methods/link.methods';
import { NewArtifactType, NewArtifactTypeClientAttribute } from '@shared/types/artifact-type.types';
import { NewAttribute, NewClientAttribute } from '@shared/types/attribute.types';
import { NewDataType } from '@shared/types/data-type.types';
import { LinkType } from '@shared/types/link-type.types';
import { ArtifactTypeLinkRestriction } from '@shared/types/link.types';
import { SelectOption } from '@shared/types/shared.types';
import { ArtifactWidgetCustomAttributeHelper } from '@widgets/artifact-widget/helpers/artifact-widget-custom-attribute.helper';
import { CardWidgetComponent } from '@widgets/card-widget/card-widget.component';
import { CardWidgetModel } from '@widgets/card-widget/types/card-widget-model';
import { CardWidgetOptions } from '@widgets/card-widget/types/card-widget-options';
import { ArtifactFiltersService } from '@widgets/shared/components/artifact-filters/services/artifact-filters.service';

export abstract class CardWidgetOptionsService {
  m: CardWidgetModel;
  protected c: CardWidgetComponent;
  protected options: CardWidgetOptions = new CardWidgetOptions();

  protected constructor(
    protected readonly cache: NewCacheService,
    private readonly customAttributeHelper: ArtifactWidgetCustomAttributeHelper,
    private readonly filtersService: ArtifactFiltersService,
  ) {
    this.options.systemAttributes.setList(this.filtersService.getSystemAttributes(), ID_KEY);
  }

  protected async initAllPossibleOptions(): Promise<void> {
    this.options.applications.setList(this.cache.data.applications.value as ApplicationResponseDto[], ID_KEY);
    this.options.artifactTypes.setList(
      (this.cache.data.artifactTypes.value as ArtifactTypeResponseDto[]).map(dto => new NewArtifactType(dto)),
      ID_KEY,
    );
    this.options.attributes.setList(
      (this.cache.data.attributes.value as AttributeResponseDto[]).map(dto => new NewAttribute(dto)),
      ID_KEY,
    );
    this.options.dataTypes.setList(
      (this.cache.data.dataTypes.value as DataTypeResponseDto[]).map(dto => new NewDataType(dto)),
      ID_KEY,
    );
    this.options.users.setList(this.cache.data.users.value as ArtifactLinkResponseDto[], ID_KEY);
    this.options.linkTypes.setList(
      (this.cache.data.linkTypes.value as LinkTypeResponseDto[]).map(dto => new LinkType(dto)),
      ID_KEY,
    );
    this.options.pages.setList(
      (this.cache.data.pages.value as PageResponseDto[]).map(dto => new Page(dto)),
      ID_KEY,
    );
  }

  protected async initConditionalOptions(): Promise<void> {
    if (!this.m.settings.isArtifactTypeSelected) {
      return;
    }

    await this.generateAttributeOptions();
  }

  protected async generateAttributeOptions(): Promise<void> {
    this.c.m.options.clientAttributeOptions = [
      ...this.getArtifactAttributeOptions(),
      ...this.customAttributeHelper.getCustomAttributeOptions(),
      ...(await this.getArtifactLinkTypeOptions()),
    ];
  }

  private getArtifactAttributeOptions(): SelectOption<string, NewClientAttribute>[] {
    const { attributes } = this.options.artifactTypes.listMap[this.m.settings.artifactTypeId];

    return Object.values(attributes).map((attribute: NewArtifactTypeClientAttribute) => {
      return new SelectOption(
        this.options.attributes.listMap[attribute.id].name,
        new NewClientAttribute({
          id: attribute.id,
          isMandatory: attribute.isMandatory,
          value: attribute.initialValue,
        }),
      );
    });
  }

  private async getArtifactLinkTypeOptions(): Promise<SelectOption<any, any>[]> {
    const linkRestrictions = this.c.isListMatrixCard
      ? LinkMethods.getLinkRestrictionsForArtifactType(this.m.settings.artifactTypeId, this.options.linkTypes.list)
      : this.getLinkRestrictionsForArtifactType(this.m.settings.artifactTypeId);
    let options: SelectOption<string, LinkType, LinkDirection>[] = [];

    try {
      options = this.getLinkTypeOptionsForLinkRestrictions(linkRestrictions);
    } catch (e) {
      console.error('Failed to load link types', e);
    }

    return options;
  }

  private getLinkRestrictionsForArtifactType(artifactTypeId: string): ArtifactTypeLinkRestriction[] {
    const linkTypes = (this.cache.data.linkTypes.value as LinkTypeResponseDto[]).filter(dto =>
      dto?.restrictions?.some(r => r.sourceArtifactTypeId === artifactTypeId || r.destinationArtifactTypeId === artifactTypeId),
    );

    return LinkMethods.getLinkRestrictionsForArtifactType(artifactTypeId, linkTypes);
  }

  private getLinkTypeOptionsForLinkRestrictions(linkRestrictions: ArtifactTypeLinkRestriction[]): SelectOption<string, LinkType, LinkDirection>[] {
    const relevantLinkTypes = LinkMethods.getLinkTypesFromRestrictions(linkRestrictions);
    const usedLinks = new Set();

    return relevantLinkTypes.reduce(
      (options: SelectOption<string, LinkType, LinkDirection>[], relevantLinkType: SelectOption<string, string, LinkDirection>) => {
        const linkType = relevantLinkType.value ? this.options.linkTypes.listMap[relevantLinkType.value] : null;
        const linkName = relevantLinkType.value + '_' + relevantLinkType.meta;

        if (linkType?.id && !usedLinks.has(linkName)) {
          usedLinks.add(linkName);

          return [...options, new SelectOption(relevantLinkType.label || '', linkType, relevantLinkType.meta)];
        }

        return options;
      },
      [],
    );
  }
}
