import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TypeSystemElementDropdownModule } from '@private/components/type-system-element-dropdown/type-system-element-dropdown.module';
import { AvrNonMappableFieldsModule } from '@private/pages/artifact-type-management/artifact-type/components/artifact-type-avr-form/components/avr-non-mappable-fields.module';
import { AppAttributeValueEditFieldModule } from '@shared/components/attribute-value-edit-field/attribute-value-edit-field.component';
import { FileAttributeModule } from '@shared/components/file-attribute/file-attribute.component';
import { LoaderModule } from '@shared/components/loader/loader.module';
import { ElvisSharedModule } from '@shared/elvis-shared.module';
import { ArtifactPipesModule } from '@shared/pipes/artifact-pipes/artifact-pipes.module';
import { ConstructPreviewFormatSettingsPipe } from '@shared/pipes/attribute-pipes/construct-preview-format-settings.pipe';
import { DataTypePipesModule } from '@shared/pipes/data-type-pipes/data-type-pipes.module';
import { FolderWidgetModule } from '@widgets/folder-widget/folder-widget.module';
import { AclModule } from '@widgets/shared/components/acl/acl.module';
import { StyleFormModule } from '@widgets/shared/components/style-form.module';

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

import { AppArtifactAttributeValueEditFieldModule } from '../../components/artifact-attribute-value-edit-field/artifact-attribute-value-edit-field.component';
import { FileAttributeValueEditFieldModule } from '../../components/file-attribute-value-edit-field/file-attribute-value-edit-field.component';
import { ArtifactTypeListComponent } from './artifact-type-list/artifact-type-list.component';
import { ArtifactTypeListTableComponent } from './artifact-type-list/components/artifact-type-list-table/artifact-type-list-table.component';
import { GetArtifactTypeFilterOptionsPipe } from './artifact-type-list/pipes/get-artifact-type-filter-options.pipe';
import { PrintArtifactTypeToTablePipe } from './artifact-type-list/pipes/print-artifact-type-to-table.pipe';
import { ArtifactTypeComponent } from './artifact-type/artifact-type.component';
import { ArtifactTypeAttributesFormComponent } from './artifact-type/components/artifact-type-attributes-form/artifact-type-attributes-form.component';
import { ArtifactTypeAvrFormComponent } from './artifact-type/components/artifact-type-avr-form/artifact-type-avr-form.component';
import { ArtifactTypeDefaultWidgetsFormComponent } from './artifact-type/components/artifact-type-default-widgets-form/artifact-type-default-widgets-form.component';
import { ArtifactTypeRestrictionsFormComponent } from './artifact-type/components/artifact-type-restrictions-form/artifact-type-restrictions-form.component';
import { IsAttributeDisabledPipe } from './artifact-type/pipes/is-attribute-disabled.pipe';
import { AttributeListComponent } from './attribute-list/attribute-list.component';
import { AttributeListTableComponent } from './attribute-list/components/attribute-list-table/attribute-list-table.component';
import { PrintAttributeToTablePipe } from './attribute-list/pipes/print-attribute-to-table.pipe';
import { AttributeComponent } from './attribute/attribute.component';
import { AttributeFormComponent } from './attribute/components/attribute-form/attribute-form.component';
import { DataTypeListTableComponent } from './data-type-list/components/data-type-list-table/data-type-list-table.component';
import { DataTypeListComponent } from './data-type-list/data-type-list.component';
import { GetDataTypeFilterOptionsPipe } from './data-type-list/pipes/get-data-type-filter-options.pipe';
import { PrintDataTypeToTablePipe } from './data-type-list/pipes/print-data-type-to-table.pipe';
import { BoundedRangeDetailsComponent } from './data-type/components/bounded-range-details/bounded-range-details.component';
import { DataTypeFormComponent } from './data-type/components/data-type-form/data-type-form.component';
import { EnumeratedDetailsComponent } from './data-type/components/enumerated-details/enumerated-details.component';
import { DataTypeComponent } from './data-type/data-type.component';
import { LinkTypeListTableComponent } from './link-type-list/components/link-type-list-table/link-type-list-table.component';
import { LinkTypeListComponent } from './link-type-list/link-type-list.component';
import { PrintLinkTypeToTablePipe } from './link-type-list/pipes/print-link-type-to-table.pipe';
import { LinkTypeFormComponent } from './link-type/components/link-type-form/link-type-form.component';
import { LinkTypeRestrictionsFormComponent } from './link-type/components/link-type-restrictions-form/link-type-restrictions-form.component';
import { LinkTypeComponent } from './link-type/link-type.component';

const declarations = [
  ArtifactTypeComponent,
  ArtifactTypeListComponent,
  ArtifactTypeListTableComponent,
  AttributeComponent,
  AttributeFormComponent,
  DataTypeComponent,
  DataTypeFormComponent,
  BoundedRangeDetailsComponent,
  EnumeratedDetailsComponent,
  LinkTypeComponent,
  LinkTypeFormComponent,
  LinkTypeRestrictionsFormComponent,
  ArtifactTypeRestrictionsFormComponent,
  ArtifactTypeAvrFormComponent,
  LinkTypeListComponent,
  LinkTypeListTableComponent,
  AttributeListComponent,
  AttributeListTableComponent,
  DataTypeListComponent,
  DataTypeListTableComponent,
];

@NgModule({
  declarations: [
    ...declarations,
    PrintArtifactTypeToTablePipe,
    PrintDataTypeToTablePipe,
    PrintAttributeToTablePipe,
    GetDataTypeFilterOptionsPipe,
    PrintLinkTypeToTablePipe,
    ArtifactTypeAttributesFormComponent,
    GetArtifactTypeFilterOptionsPipe,
    ArtifactTypeDefaultWidgetsFormComponent,
    IsAttributeDisabledPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,

    ButtonModule,
    TranslateModule,
    InputTextModule,
    FormsModule,
    DropdownModule,
    InputTextareaModule,
    TableModule,
    CheckboxModule,
    ChipsModule,
    ColorPickerModule,
    MultiSelectModule,
    SliderModule,
    ListboxModule,
    RadioButtonModule,
    KeyFilterModule,
    CalendarModule,
    CardModule,
    RippleModule,
    TooltipModule,
    FileUploadModule,
    InputNumberModule,
    AccordionModule,
    TriStateCheckboxModule,

    AppArtifactAttributeValueEditFieldModule,
    LoaderModule,
    FileAttributeValueEditFieldModule,
    ElvisSharedModule,
    DragDropModule,
    ArtifactPipesModule,
    AppAttributeValueEditFieldModule,
    FileAttributeModule,
    DataTypePipesModule,
    StyleFormModule,
    OverlayPanelModule,
    AclModule,
    FolderWidgetModule,
    AvrNonMappableFieldsModule,
    TypeSystemElementDropdownModule,
    ConstructPreviewFormatSettingsPipe,
  ],
  exports: [...declarations],
})
export class ArtifactTypeManagementModule {}
